
import {CloseButton} from '_views/shared/IconButton';
import React from 'react';

import {toClassStr} from '_utils/UiHelper';

class ImageViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullyClosed: false,
      isFullyOpen: false,
      isInitializing: true,
      isTransitioningIn: false,
      isTransitioningOut: false,
    };

    /*
      this.props:
        addClasses,
        isOpen,
        onClose,
        onCloseRequest,
        shouldTransitionIn,
        shouldTransitionOut,
        url,
    */

    this.transitionInTimeout = null;
    this.postTransitionInTimeout = null;
    this.postTransitionOutTimeout = null;
    this.TRANSITION_IN_DELAY = 100; // allows for mounting of component before commencement of transition
    this.POST_TRANSITION_IN_DELAY = 1000; // should be equal to or greater than the transition time set in the CSS
    this.POST_TRANSITION_OUT_DELAY = 800; // allows for transition before being potentially unmounted
    // Note: No transition out delay is needed because component is already mounted

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    this.clearTimeoutsAndIntervals();

    if (this.props.isOpen && this.props.shouldTransitionIn) {
      this.invokeTransitionIn();
      return;
    }

    if (this.props.isOpen && this.props.shouldTransitionOut) {
      this.invokeTransitionOut();
      return;
    }

    this.setState({
      isInitializing: false
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen && this.props.shouldTransitionIn) {
      this.invokeTransitionIn();
    }

    if (!this.props.isOpen && prevProps.isOpen && this.props.shouldTransitionOut) {
      this.invokeTransitionOut();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this.stopKeyDownMonitor();
    this._isMounted = true;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['image-viewer',  this.getTransitionClass(), this.props.addClasses]);

    return (
      <div className={classes}>
        <CloseButton 
          addClasses="image-viewer-close-button"
          onClick={() => this.handleClose()}
        />
        <img className="full-sized-image" src={this.props.url} onClick={(e) => this.handleImageClick(e)}/>
      </div>
    );
  }

  renderMessage() {
    if (!this.props.message) {
      return null;
    }

    return (
      <div className="loading-message">{this.props.message}</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClose() {
    this.invokeTransitionOut();
  }

  handleImageClick(e) {
    e.stopPropagation();
    this.handleClose();
  }

  handleKeyDown = (e) => {
    // Esc
    if (e.keyCode === 27) {

      e.preventDefault();
      e.stopPropagation();

      this.handleClose();
    }
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  completeTransitionIn() {
    this.postTransitionInTimeout = setTimeout(() => {
      this.setState({
        isTransitioningIn: false,
        isFullyOpen: true
      });
      clearTimeout(this.postTransitionInTimeout);
    }, this.POST_TRANSITION_IN_DELAY);
  }

  completeTransitionOut() {
    this.setState({
      isTransitioningOut: false,
      isFullyClosed: true
    }, () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  }

  clearTimeoutsAndIntervals() {
    clearTimeout(this.transitionInTimeout);
    clearTimeout(this.postTransitionInTimeout);
    clearTimeout(this.postTransitionOutTimeout);
  }

  getTransitionClass() {
    if (this.state.isTransitioningIn) {
      return 'is-transitioning-in';
    }

    if (this.state.isTransitioningOut) {
      return 'is-transitioning-out';
    }

    if (this.state.isFullyClosed) {
      return 'is-fully-closed';
    }

    if (this.state.isFullyOpen) {
      return 'is-fully-open';
    }

    if (this.state.isInitializing) {
      return 'is-initializing';
    }

    return 'is-static';
  }

  invokeTransitionIn() {
    this.transitionInTimeout = setTimeout(() => {
      this.setState({
        isTransitioningIn: true,
        isInitializing: false,
      }, () => {
        this.completeTransitionIn();
        this.startKeyDownMonitor();
      });
      clearTimeout(this.transitionInTimeout);
    }, this.TRANSITION_IN_DELAY);
  }

  invokeTransitionOut() {
    this.setState({
      isTransitioningOut: true,
      isInitializing: false,
    }, () => {
      this.postTransitionOutTimeout = setTimeout(() => {
        this.clearTimeoutsAndIntervals();
        this.completeTransitionOut();
        this.stopKeyDownMonitor();
      }, this.POST_TRANSITION_OUT_DELAY);
    });
  }

  startKeyDownMonitor = () => {
    if (this._isMounted) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  stopKeyDownMonitor = () => {
    if (this._isMounted) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }
}

export default ImageViewer;
