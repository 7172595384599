
import EventManager      from '@brainscape/event-manager';
import FileDropZoneField from '_views/shared/FileDropZoneField';
import Modal             from '_views/shared/Modal';
import PillButton        from '_views/shared/PillButton';
import PropTypes         from 'prop-types';
import React             from 'react';
import SimpleTextButton  from '_views/shared/SimpleTextButton';

import deckCsvImportJob  from '_models/deckCsvImportJob';


class ImportCardsModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {isModalOpen: false, viewProps: null};
    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this.events.addListeners([
      ['import-cards-modal:open',  this.handleOpenRequest],
      ['import-cards-modal:close', this.handleCloseRequest],
    ]);
  }

  componentWillUnmount = () => this.events.disable();


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const viewProps = this.state.viewProps;
    if (!viewProps) { return null; }

    return (
      <ImportCardsModal
        addClasses={viewProps.addClasses}
        isOpen={this.state.isModalOpen}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        pack={viewProps.pack}
        deck={viewProps.deck}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOpenRequest = (data) => {
    this.setState({isModalOpen: true, viewProps: data});
  };

  // invokes overlay closing animation. Overlay is still in DOM
  handleCloseRequest = () => {
    let cb = null;
    if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
      cb = this.state.viewProps.onCloseRequest;
    }

    this.setState({isModalOpen: false}, cb);
  };

  // invoked after overlay close animation is complete. Removes overlay from DOM
  handleClosed = () => {
    let cb = null;
    if (this.state.viewProps && this.state.viewProps.onClosed) {
      cb = this.state.viewProps.onClosed;
    }

    this.setState({viewProps: null}, cb);
  };
}

ImportCardsModalController.propTypes = {};


class ImportCardsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state  = this.baseState();
    this.events = new EventManager();
  }

  baseState = () => {
    return {csvFile: null, errMsg: '', isProcessing: false, inputValid: false};
  };


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentWillUnmount = () => this.events.disable();


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const deck   = this.props.deck;
    const errMsg = this.state.errMsg;

    return (
      <Modal
        addClasses="import-deck-modal"
        isOpen={this.props.isOpen}
        onClosed={this.props.onClosed}
        onCloseRequest={this.props.onCloseRequest}
      >
        <div className="modal-title">Import Cards Deck</div>

        <div className="modal-guidance">
          Upload a CSV file. The cards will be inserted or updated in
          the <span className="pack-name">{deck.name}</span> deck.
        </div>

        <form className="import-deck-form" onSubmit={this.handleFormSubmit}>
          <FileDropZoneField
            addClasses='import-csv-drop-zone'
            accepts={['.csv']}
            onFileSelectionError={this.handleFileSelectionError}
            onFileSelected={this.handleFileSelected}
            label="CSV Source File"
            prompt="Drop a CSV file here or click to browse"
            shouldSuppressErrorDisplay={true}
          />
        </form>

        {errMsg ? <div className="error-message">{errMsg}</div> : null}

        <div className="modal-actions">
          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="Cancel"
            onClick={this.handleCancelButtonClick}
          />

          <PillButton
            addClasses="resolve-modal-button"
            isDisabled={!this.state.csvFile}
            isProcessing={this.state.isProcessing}
            label="Import"
            onClick={this.handleImportRequest}
          />

          {/* <SimpleTextButton */}
          {/*   addClasses="help-text-button" */}
          {/*   label="Help" */}
          {/*   onClick={this.handleHelpButtonClick} */}
          {/* /> */}
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = this.props.onCloseRequest;

  handleImportRequest = () => {
    this.setState({isProcessing: true});

    const packId = this.props.pack.packId;
    const deckId = this.props.deck.deckId;
    const url    = `packs/${packId}/decks/${deckId}/csv/admin`;

    deckCsvImportJob.create(packId, deckId, this.state.csvFile).then(() => {
      this.setState(this.baseState(), this.props.onCloseRequest);
    });
  };


//   handleImportRequest = () => {
//     // TODO: Test this
//     // handleFormSubmit = (e) => {
//     //   e.preventDefault();
//     //   e.stopPropagation();
// 
// 
// 
//     this.setState({isProcessing: true});
// 
//     const packId = this.props.pack.packId;
//     const deckId = this.props.deck.deckId;
//     const url    = `packs/${packId}/decks/${deckId}/csv/admin`;
// 
//     apiPostFile(url, this.state.csvFile, 'text/csv').then((rs) => {
//       this.setState(this.baseState(), this.props.onCloseRequest);
//     }).catch((err) => {
//       const msg = `Could not process the CSV: ${err.message}.`;
//       this.setState({errMsg: msg, inputValid: true});
//     });
//   };

//   handleHelpButtonClick = () => {
//     const msg = `
// This imports a CSV that allows you to update existing cards or create new cards in a existing deck. All the columns in the CSV are required, as such, it is easiest to start by exporting the cards CSV first (even if the deck is empty). Once you have exported CSV, you can edit existing rows or insert new rows.
// 
// The "Card ID" column is read by the import. The following columns are used to update cards:
// 
// * Learning Objective
// * Q. Prompt
// * Q. Body
// * Q. Clarifier
// * Q. Footnote
// * Q. Image Caption
// * A. Prompt
// * A. Body
// * A. Clarifier
// * A. Footnote
// * A. Image Caption
// 
// All other columns must be present, but are ignored.
// `;
// 
//     openAlert('Import Cards CSV', msg, {addClasses: 'text-align-left'});
//   }

  handleFileSelected = (fileData) => {
    if (!fileData) { return; }

    this.setState({csvFile: fileData, errMsg: '', inputValid: false});
  };

  handleFileSelectionError = (error, file) => {
    this.setState({csvFile: null, errMsg: error.message, inputValid: true});
  };

  handleFormSubmit = (e) => {
    // TODO: Can remove?
    e.preventDefault();
    e.stopPropagation();

    this.handleImportRequest();
  };
}
ImportCardsModal.propTypes ={
  addClasses:     PropTypes.string,
  isOpen:         PropTypes.bool.isRequired,
  onClosed:       PropTypes.func.isRequired,
  onCloseRequest: PropTypes.func.isRequired,
  pack:           PropTypes.object.isRequired,
  deck:           PropTypes.object.isRequired,
};


export default ImportCardsModalController;
