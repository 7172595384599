import React                          from 'react';
import {toClassStr}                   from '_utils/UiHelper';

const PackHeaderColumnFilter = ({
  admin,
  selectedClass,
  sortClass, 
  sortDirection,
  sortTitle,
  onSortLearnersRequest
}) => {
  const selected = selectedClass == sortClass;
  const curSort = selected ? sortDirection : 'asc';
  const selectedItem = selected ? 'selected' : '';
  const adminClass = admin ? 'filter' : '';
  const classes = toClassStr([adminClass, selectedItem, curSort]);

  const handleClick = (e) => {
    e.stopPropagation();

    if ( !admin ) { return false; }

    const direction = sortDirection == 'asc' ? 'desc' : 'asc';

    onSortLearnersRequest(sortClass, direction);
  }

  return (
    <div className={classes} onClick={handleClick}>{sortTitle}</div>
  );  

}

export default PackHeaderColumnFilter;