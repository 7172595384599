
import HumanDuration            from '_utils/HumanDuration';
import DynamicTooltipIcon             from '_views/shared/DynamicTooltipIcon';
import PropTypes, { string }    from 'prop-types';
import React                    from 'react';

import { toClassStr }           from '_utils/UiHelper';


const PT = {
  addClasses: PropTypes.string,
  estTimeLeft: PropTypes.number,
  selectedDecks: PropTypes.array,
  timeStudied: PropTypes.number,
  tooltipPosition: PropTypes.string,
};


class EstTimeLeftSnippet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const estTimeLeft = this.props.estimatedTimeLeft;
    const legibleEstTimeLeft = HumanDuration.render({inSeconds: estTimeLeft / 1000});
    const selectedDecks = this.props.selectedDecks; // if context is Checkpoint, then stat is mixDecks (obj)

    if (estTimeLeft == null || !isFinite(estTimeLeft)) {
      return null;
    }

    return (
      <div className={this.props.addClasses}>
        {this.renderEstTimeLeft(selectedDecks, legibleEstTimeLeft)}

        <DynamicTooltipIcon 
          addClasses="est-time-left-dialog"
          actions={null}
          body={this.renderDynamicTooltipIconBody(legibleEstTimeLeft)}
          delay={500}
          hasDismissButton={false}
          position={this.props.tooltipPosition}
          heading="Estimated Time Left"
          isMobileViewportSize={this.props.isMobileViewportSize}
        />
      </div>
    );
  }

  renderDecksSelectedText(count) {
    const decks = this.pluralize('deck', 'decks', count);

    return (
      <>
        {count} {decks}
      </>
    );
  }

  renderEstTimeLeft(selectedDecks, legibleEstTimeLeft) {
    return (
      <span>
        Est. Time Left :&nbsp;
        <strong>{legibleEstTimeLeft}</strong>
        &nbsp;({this.renderDecksSelectedText(selectedDecks.length)})&nbsp;
      </span>
    );
  }

  renderDynamicTooltipIconBody(legibleEstTimeLeft) {
    const selectedDecksLength = this.props.selectedDecks.length;
    const hasSelectedDecks = selectedDecksLength > 0 ? true : false;
    const hasReachedDeckMastery = this.props.selectedDecksMastery >= 0.99 ? true : false;
    const timeStudied = HumanDuration.render({ inSeconds: this.props.timeStudied / 1000 });
    const totalTimeStudied = this.props.timeStudied != null ? timeStudied : '0 minutes';

    if (hasReachedDeckMastery) {
      return (
        <>
          <div className="rich-content-tooltip-sub-heading">Congratulations!</div>
          <p className="body-text">
            You've reached 100% Mastery of the{' '}
            <strong>{this.renderDecksSelectedText(selectedDecksLength)}</strong>{' '}
            {this.props.sentenceEndingText}.
          </p>
        </>
      );
    } else if (hasSelectedDecks) {
      return (
        <>
          <p className="body-text" cyid={"estTimeLeftCumulative"}>
            You have studied for a cumulative of &nbsp;
            <strong>{totalTimeStudied}</strong>
            &nbsp;in this class (excluding your long distracted pauses).
          </p>
          <p className="body-text" cyid={"estTimeLeftSummary"}>
            If you continue to improve your confidence at this rate, our magic
            formula estimates that you will need to study another&nbsp;
            <strong>{legibleEstTimeLeft}</strong> to reach 100% Mastery of the{' '}
            <strong>{this.renderDecksSelectedText(selectedDecksLength)}</strong>{' '}
            {this.props.sentenceEndingText}.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="body-text">
            To see your estimated time left, please check the boxes under the
            Decks tab below, for the decks you aim to master.
          </p>
        </>
      );
    }
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  pluralize(singularWord, pluralWord, count) {
    if (count === 0) {
      return pluralWord;
    }

    return count > 1 ? pluralWord : singularWord;
  }
}

EstTimeLeftSnippet.propTypes = PT;

export default EstTimeLeftSnippet;
