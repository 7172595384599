
import EventManager           from '@brainscape/event-manager';
import Modal                  from '_views/shared/Modal';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import PillButton             from '_views/shared/PillButton';
import React                  from 'react';
import UiHelper               from '_utils/UiHelper';

// models
import packConfidence         from '_models/packConfidence';

import {toClassStr}           from '_utils/UiHelper';


class StudyMixOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpts: {},
      isProcessingOptionAction: false,
      isStudyMixTypeHelpModalOpen: false,
      shouldShowDialog: false,
    };

    /*
      this.props:
        addClasses,
        isCheckpointOptionsButton,
        isContinuousStudyEnabled,
        isDisabled,
        isMixRestricted,
        isMobileViewportSize,
        isUserPro,
        mixType,
        onChangeProgressView,
        onSetStudyMixTypeRequest,
        onToggleContinuousStudy,
        progressView,  // 'thisRound' or 'overall'
        stepCard,
    */

    this.menuOptionHash = {
      previewDeckCards:{id: 'previewDeckCards', tag: 'preview-deck-cards', label: 'Preview Cards in Current Deck', onlyMobile: true},
      thisRound:{id: 'thisRound', tag: 'this-round-progress', label: 'Display "This Round" Progress', onlyMobile: true, persistOnClick: true, onlyIfNotCheckpointButton: true},
      overall:{id: 'overall', tag: 'overall-progress', label: 'Display "Overall" Progress', onlyMobile: true, persistOnClick: true, onlyIfNotCheckpointButton: true},
      continuousStudyByPro: {id: 'continuousStudyByPro', tag: 'continuous-study-by-pro', label: 'Enable Continuous Study', onlyPro: true, onlyIfMixIsRestricted: true, paywall: 'enable_continuous_study', persistOnClick: true},
      continuousStudy: {id: 'continuousStudy', tag: 'continuous-study', label: 'Enable Continuous Study', onlyIfMixIsUnrestricted: true, persistOnClick: true},
      setProgressiveMix: {id: 'setProgressiveMix', tag: 'set-progressive-mix', label: 'Set as Progressive Mix', persistOnClick: true},
      setRandomMix: {id: 'setRandomMix', tag: 'set-random-mix', label: 'Set as Random Mix', persistOnClick: true},
      mixHelp: {id: 'mixHelp', tag: 'mix-help', label: 'About Mix Types'},
      resetStats:{id: 'resetStats', tag: 'reset-stats', label: 'Reset Class Stats (for ALL Cards)', onlyPro: true, onlyIfNotCheckpointButton: true, paywall: 'stats_reset_mix'},
      logOut: {id: 'logOut', tag: 'logout', label: 'Log Out'},

    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillMount() {
    this.updateUserOptions();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isMixRestricted != prevProps.isMixRestricted || this.props.isUserPro != prevProps.isUserPro) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const continuousStudyEnabledClass = this.props.isContinuousStudyEnabled ? 'continuous-study-enabled' : '';
    const mixTypeClass = this.props.mixType == 'random' ? 'random-mix' : 'progressive-mix';
    const progressViewClass = this.props.progressView == 'thisRound' ? 'view-this-round-progress' : 'view-overall-progress';
    let classes = toClassStr(['study-mix-options-button', continuousStudyEnabledClass, mixTypeClass, progressViewClass, this.props.addClasses]);
    const openPosition = this.props.isMobileViewportSize ? "bottomLeft" : "bottomRight";

    return (
      <div className={classes}>
        <OptionsMenuButton
          isDisabled={this.props.isDisabled}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.isUserPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition={openPosition}
          shouldButtonTurn={true}
          title={this.props.title}
          tooltipContent={this.props.tooltipContent}
          tooltipPosition={this.props.tooltipPosition}
        />

        {this.renderStudyMixTypeHelpModal()}
      </div>
    );
  }

  renderStudyMixTypeHelpModal() {
    return (
      <Modal
        addClasses="mix-type-help-modal"
        isOpen={this.state.isStudyMixTypeHelpModalOpen}
        onCloseRequest={() => this.handleHelpModalCloseRequest()}
      >

        <div className="modal-title">About Brainscape Study Mix Types</div>

        <div className="modal-message">You can configure your study session in the following ways:</div>

        <div className="modal-message"><strong>'Progressive'</strong> selects cards from decks in the order they appear. So, you will not see cards from the 2nd deck until you've mostly mastered the 1st deck.  Once you've moved to a later deck, cards from earlier decks can still repeat occasionally.</div>

        <div className="modal-message"><strong>'Random'</strong> will select from a pool of cards across all selected decks.  The repetition priority is still given to the lower-confidence items, but new/un-seen cards may be chosen from any position in any of the selected decks.</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={() => this.handleHelpOkButtonClick()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick(optionId) {
    let requiresPro = this.menuOptionHash[optionId].onlyPro;

    if (requiresPro && !this.props.isUserPro) {
      let desiredAction = this.menuOptionHash[optionId].label || "";
      let paywall = this.menuOptionHash[optionId].paywall || "";
      this.triggerUpgradeModalOpen(desiredAction, paywall);
    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleHelpModalCloseRequest() {
    this.setState({
      isStudyMixTypeHelpModalOpen: false,
    });
  }

  handleHelpOkButtonClick() {
    this.setState({
      isStudyMixTypeHelpModalOpen: false,
    });
  }

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'previewDeckCards':
        this.performPreviewDeckCardsAction();
      break;
      case 'continuousStudy':
        this.performEnableContinuousStudyAction();
      break;
      case 'continuousStudyByPro':
        this.performEnableContinuousStudyAction();
      break;
      case 'resetStats':
        this.performResetStatsAction();
      break;
      case 'thisRound':
        this.props.onChangeProgressView('thisRound');
      break;
      case 'overall':
        this.props.onChangeProgressView('overall');
      break;
      case 'setProgressiveMix':
        this.performSetProgressiveMixAction();
      break;
      case 'setRandomMix':
        this.performSetRandomMixAction();
      break;
      case 'mixHelp':
        this.performShowMixHelpAction();
      break;
      case 'logOut':
        this.performLogOutAction();
      break;
    }
  }


  /*
  ==================================================
   EVENT HANDLER HELPERS
  ==================================================
  */

  performPreviewDeckCardsAction() {
    EventManager.emitEvent('deck-preview-modal:open', {
      deckId:  this.props.stepCard.deckId,
      packId:  this.props.stepCard.packId,
    });
  }

  performEnableContinuousStudyAction() {
    this.props.onToggleContinuousStudy();
  }

  performLogOutAction() {
    UiHelper.navigate('/sign_out');
  }

  performResetStatsAction() {
    this.setState({
      isProcessingOptionAction: true
    });

    this.triggerConfirmModalOpen({
      actionText: 'reset Class Stats. This action will reset your confidences for each card in the class and cannot be undone',
      resolveButtonText: 'Yes, Reset Stats',
      onResolution: () => {
        packConfidence.reset(this.props.stepCard.packId);

        this.setState({
          isProcessingOptionAction: false,
        });
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performSetProgressiveMixAction() {
    this.props.onSetStudyMixTypeRequest('progressive');
  }

  performSetRandomMixAction() {
    this.props.onSetStudyMixTypeRequest('random');
  }

  performShowMixHelpAction() {
    this.setState({
      isStudyMixTypeHelpModalOpen: true,
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerUpgradeModalOpen(desiredAction, paywall) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updateUserOptions() {
    let menuOptionsForUser = Object.keys(this.menuOptionHash).reduce((accumulator, menuOption) => {
      let permitPerMixRestriction = (this.menuOptionHash[menuOption].onlyIfMixIsRestricted) ? this.props.isMixRestricted : true;
      let permitPerMixUnrestriction = (this.menuOptionHash[menuOption].onlyIfMixIsUnrestricted) ? !this.props.isMixRestricted : true;
      let permitPerUseInCheckpoint = (this.menuOptionHash[menuOption].onlyIfNotCheckpointButton) ? !this.props.isCheckpointOptionsButton : true;
      let permitPerDeviceType = (this.menuOptionHash[menuOption].onlyMobile) ? this.props.isMobileViewportSize : true;

      if (permitPerMixRestriction && permitPerMixUnrestriction && permitPerUseInCheckpoint && permitPerDeviceType) {
        accumulator.push(this.menuOptionHash[menuOption]);
      }
      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

export default StudyMixOptionsButton;
