
import EventManager               from '@brainscape/event-manager';
import React, { lazy, Suspense }  from 'react';

const SpacedRepetitionModal = lazy(() => import('_views/modals/SpacedRepetitionModal'));

class SpacedRepetitionModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener(
      'spaced-repetition-modal:open',
      (data) => this.setState({
        isModalOpen: true,
        viewProps: data
      }),
    );

    this.events.addListener(
      'spaced-repetition-modal:close',
      () => {
        this.handleCloseRequest();
      }
    );
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;

    if (!viewProps) { 
      return null; 
    }

    return (
      <>
        <Suspense fallback={'loading'}>
          <SpacedRepetitionModal
            addClasses={viewProps.addClasses}
            confidenceLevel={viewProps.confidenceLevel}
            isOpen={this.state.isModalOpen}
            message={viewProps.message}
            media={viewProps.media}
            onClosed={this.handleClosed}
            onCloseRequest={this.handleCloseRequest}
            onResolution={this.handleResolutionRequest}
            resolveButtonText={viewProps.resolveButtonText}
            title={viewProps.title}
            // categoryId={this.props.packId}
            packId={this.props.packId}
            source={this.props.source}
            subject={this.props.subject}
            returnTo={this.props.returnTo}
          />
        </Suspense>
      </>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  // handleResolutionRequest = () => {
  //   if (this.state.viewProps && this.state.viewProps.onResolution) {
  //     this.state.viewProps.onResolution();
  //   } else {
  //     this.handleCloseRequest();
  //   }
  // }

  handleResolutionRequest = () => {
    // if (this.state.viewProps && this.state.viewProps.onResolution) {
    //   this.state.viewProps.onResolution();
    // } else {
    //   this.handleCloseRequest();
    // }
    console.log('resolution req from controller')
  }
}

export default SpacedRepetitionModalController;
