
import userLocalStore from '_models/userLocalStore';


const userPackTransform = {

  /*
  ==================================================
   LOCAL CRUD METHODS (returns promises)
  ==================================================
  */

  index(userId, userPacks) {
    return new Promise((resolve, reject) => {
      try {
        const filters = this.getLocalFilters();
        const selections = this.getLocalSelections(userId, userPacks);
        const sorters = this.getLocalSorters();
        
        resolve({
          filters: filters,
          selections: selections,
          sorters: sorters,
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getLocalFilters() {
    return {};
  },

  getLocalSelections(userId, userPacks) {
    // read previously selected decks from this pack from local storage and return ids
    const currentUserPackIds = userPacks.map(pack => pack.packId);
    const selectedUserPackIds = userLocalStore.getSelectedUserPackIds(userId);
    let validSelectedUserPackIds = [];

    selectedUserPackIds.forEach(packId => {
      const numericPackId = parseInt(packId);

      if (numericPackId && currentUserPackIds.indexOf(numericPackId) != -1) {
        validSelectedUserPackIds.push(numericPackId);
      }
    });

    userLocalStore.selectUserPacks(userId, validSelectedUserPackIds);

    return {
      ids: validSelectedUserPackIds,
    };
  },

  getLocalSorters() {
    return {
      name: 'asc',
    };
  },
};

export default userPackTransform;
