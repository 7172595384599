
import EventManager             from '@brainscape/event-manager';
import React                    from 'react';
import EarnMoneyModal           from '_views/modals/EarnMoneyModal';

class EarnMoneyModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isProcessing: false,
      modalProps: null,
    };

    /* modalProps (all optional):
      addClasses,
      isOpen,
      onCloseRequest,
      onClosed,
      onOpened,
    */

    this.events = new EventManager();
  }

  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('earn-money-modal:open', this.handleOpenRequest);
    this.events.addListener('earn-money-modal:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.state.modalProps) {
      return null;
    }

    return (
      <EarnMoneyModal
        // currentUser={this.state.modalProps.currentUser}
        // deck={this.state.modalProps.deck}
        isOpen={this.state.isModalOpen}
        // isProcessing={this.state.isProcessing}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        // onFormSubmit={this.handleFormSubmit}
        onOpened={this.handleOpened}
        // packId={this.state.modalProps.packId}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClosed = () => {
    this.setState({
      modalProps: null
    }, () => {
      if (this.state.modalProps?.onClosed) {
        this.state.modalProps.onClosed();
      }
    });
  }

  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
      isProcessing: false,
    }, () => {
      if (this.state.modalProps?.onCloseRequest) {
        this.state.modalProps.onCloseRequest();
      }
    });
  }

  handleOpened = (data) => {
    if (this.state.modalProps?.onOpened) {
      this.state.modalProps.onOpened();
    }
  }

  handleOpenRequest = (data) => {
    this.setState({
      isModalOpen: true,
      isProcessing: false,
      modalProps: data
    }, () => {
      if (this.state.modalProps?.onOpenRequest) {
        this.state.modalProps.onOpenRequest();
      }
    });
  }
}

export default EarnMoneyModalController;
