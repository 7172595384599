
import EventManager           from '@brainscape/event-manager';
import PillButton             from '_views/shared/PillButton';
import PropTypes              from 'prop-types';
import React                  from 'react';
import SmartCardList          from '_views/shared/smart-cards/SmartCardList';

import {toClassStr}           from '_utils/UiHelper';

const PT = {
  addClasses:                 PropTypes.string,
  cardClipboard:              PropTypes.object,
  currentCardId:              PropTypes.node,
  currentDeck:                PropTypes.object,
  currentPack:                PropTypes.object,
  currentUser:                PropTypes.object,
  isMobileViewportSize:       PropTypes.bool,
};

const CARD_LIMIT_COUNT = 15;
const CARD_LIMIT_PERCENTAGE = 40;


class DeckPreviewSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const cards = this.props.currentDeck.cards;
    const classes = toClassStr(['deck-preview-section', this.props.addClasses]);

    return (
      <div className={classes}>
        <div className='multi-cards'>
          <header className="deck-preview-section-header" />

          {this.renderPreviewCardList()}
        </div>
      </div>
    );
  }

  renderPreviewCardList() {
    const cards = this.props.currentDeck.cards;
    const hasCardLimit = this.hasCardLimit();

    if (!(cards && cards.length > 0)) {
      return this.renderNoCardsInfo();
    }

    return (
      <SmartCardList
        cardLimitCount={CARD_LIMIT_COUNT}
        cardLimitPercentage={CARD_LIMIT_PERCENTAGE}
        cardMode="display"
        cards={cards}
        context="preview"
        currentCardId={this.props.currentCardId}
        currentUser={this.props.currentUser}
        deck={this.props.currentDeck}
        format="md"
        hasCardLimit={hasCardLimit}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isShowingAdditionalFields={true}
        pack={this.props.currentPack}
      />
    );
  }

  renderNoCardsInfo() {
    return (
      <div className="deck-no-cards-info">
        <h2 className="card-name">Add Cards to your Deck</h2>
        <p className="card-name-caption"><strong>Your Deck has no Cards</strong>. Add Cards to your Deck to get started.</p>

        <PillButton
          addClasses="pill-button-emphasized add-cards-button"
          isProcessing={this.state.isProcessingDeckCreate}
          label="Add Cards"
          onClick={this.handleAddCardsButtonClick}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddCardsButtonClick = () => {
    this.triggerCurrentTabChangeRequest('edit');
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCurrentTabChangeRequest = (tabId='preview') => {
    EventManager.emitEvent('current-tab:change-request', {
      tabId: tabId,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  hasCardLimit = () => {
    return (this.props.currentDeck.flags.isLocked && !this.props.currentUser.flags.isPro);
  }
}

DeckPreviewSection.propTypes = PT;

export default DeckPreviewSection;
