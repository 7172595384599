
import Modal     from '_views/shared/Modal';
import PillButton   from '_views/shared/PillButton';
import React        from 'react';

import {toClassStr} from '_utils/UiHelper';

class GooglePaymentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    /* 
      this.props: 
        addClasses
        show (boolean),
        onClose,
    */
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    let classes = toClassStr(['google-payment-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.show}
        onCloseRequest={() => this.props.onClose()}
      >

        <div className="modal-title">Google Play Payment Method</div>

        <div className="modal-message">Your billing is currently handled by Google Play. Please review your billing info or make changes directly with Google Play.</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={() => this.props.onClose()}
          />
        </div>
      </Modal>
    );
  }
}

export default GooglePaymentModal;
