
import model from '_core/newModel';
import userLocalStore from '_models/userLocalStore';

const userPref = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index(userId) {
    return new Promise((resolve, reject) => {
      try {
        const userPrefs = userLocalStore.getUserLocalPrefs(userId);
        
        resolve({
          userPrefs: userPrefs,
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  show(userId, prefKey) {
    return new Promise((resolve, reject) => {
      try {
        const value = userLocalStore.getUserLocalPref(userId, prefKey);
        
        resolve({
          prefKey: prefKey,
          value: value,
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  update(userId, prefKey, prefData) {
    return new Promise((resolve, reject) => {
      try {
        userLocalStore.setUserLocalPref(userId, prefKey, prefData);
        const value = userLocalStore.getUserLocalPref(userId, prefKey);

        model.publish('user-pref:updated', {
          prefKey: prefKey,
          value: value,
        });
        
        resolve({
          prefKey: prefKey,
          value: value,
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default userPref;
