
import EventManager           from '@brainscape/event-manager';
import LearnerProgressModal   from '_views/modals/LearnerProgressModal';
import Modal                  from '_views/shared/Modal';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import PillButton             from '_views/shared/PillButton';
import React                  from 'react';
import Tracker                from '_utils/Tracker';
import UiHelper               from '_utils/UiHelper';

import {toClassStr} from '_utils/UiHelper';

class LearnerOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmRemoveLearnerModalOpen   : false,
      isLearnerProgressModalOpen        : false,
      isProcessingOptionAction          : false,
      learnerDeckStats                  : null,
      menuOptions                       : [],
    };

    /*
      this.props:
        addClasses,
        isUserPro,
        learner,
        onRemoveLearnerRequest,
        pack,
    */

    this.events  = new EventManager();

    this.menuOptionHash = {
      showLearnerProfile: {id: 'showLearnerProfile', tag: 'show-learner-profile', label: "Show Learner's Profile"},
      removeLearner: {id: 'removeLearner', tag: 'remove-learner', label: 'Remove From Class', onlyPackAdmin: true},
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillMount() {
    this.updateUserOptions();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUserPro != prevProps.isUserPro) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['learner-options-button', this.props.addClasses]);

    return (
      <div className={classes}>
        <OptionsMenuButton
          iconType={this.props.iconType}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.isUserPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition="bottomLeft"
          shouldButtonTurn={false}
        />
          {this.renderConfirmRemoveLearnerModal()}
          {this.renderLearnerProgressModal()}
        </div>
      );
  }

  renderConfirmRemoveLearnerModal() {
    return (
      <Modal
        addClasses="confirm-remove-learner-modal"
        isOpen={this.state.isConfirmRemoveLearnerModalOpen}
        onCloseRequest={this.handleConfirmRemoveLearnerModalCloseRequest}
      >
        <div className="modal-title">Caution</div>

        <div className="modal-message">You are about to remove this Learner from this Class. This action cannot be undone.</div>

        <div className="modal-aside">(Note: The user will not be removed from Brainscape and may always be re-invited to this Class.)</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Yes, please remove"
            onClick={this.handleConfirmRemoveLearnerButtonClick}
          />
          <PillButton
            addClasses="cancel-modal-button"
            label="Cancel"
            onClick={this.handleConfirmRemoveLearnerModalCloseRequest}
          />
        </div>

      </Modal>
    );
  }

  renderLearnerProgressModal() {
    return (
      <LearnerProgressModal
        isOpen={this.state.isLearnerProgressModalOpen}
        learner={this.props.learner}
        learnerDeckStats={this.state.learnerDeckStats}
        onCloseRequest={() => this.handleLearnerProgressModalCloseRequest()}
        pack={this.props.pack}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleConfirmRemoveLearnerButtonClick = () => {
    this.setState({isProcessingRemoveRequest: true});

    this.props.onRemoveLearnerRequest(this.props.learner, () => {
      this.events.ifEnabled(() => {
        this.setState({
          isProcessingRemoveRequest:       false,
          isConfirmRemoveLearnerModalOpen: false,
        });
      });
    });
  }

  handleConfirmRemoveLearnerModalCloseRequest = () => {
    this.setState({isConfirmRemoveLearnerModalOpen: false});
  }

  handleLearnerProgressModalCloseRequest() {
    this.setState({
      isLearnerProgressModalOpen: false,
    });
  }

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'showLearnerProfile':
        this.performShowLearnerProfileAction();
      break;
      case 'removeLearner':
        this.performRemoveLearnerAction();
      break;
    }
  }

  handleOptionClick(optionId) {
    let requiresPro = this.menuOptionHash[optionId].onlyPro;

    if (requiresPro && !this.props.isUserPro) {
      let desiredAction = this.menuOptionHash[optionId].label || "";
      let paywall = this.menuOptionHash[optionId].paywall || "";
      this.triggerUpgradeModalOpen(desiredAction, paywall);
    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

//   performShowLearnerProgressAction() {
//     if (!this.props.learner.stats) {
//       // If there are no stats, then the user does not have the
//       // permission necessary to see it.
// 
//       Tracker.trackPaywallWarning('stats');
//       EventManager.emitEvent('upgrade-modal:open', {
//         desiredAction: 'Upgrade to see stats on all learners in your classes.',
//         paywall:       'stats',
//       });
// 
//       return;
//     }
// 
//     this.setState({
//       isProcessingOptionAction: true,
//     });
// 
//     this.getLearnerDeckStats(() => {
//       this.setState({
//         isLearnerProgressModalOpen: true,
//         isProcessingOptionAction: false,
//       });
//     })
//   }

  performShowLearnerProfileAction() {
    UiHelper.navigate(this.props.learner.profileUrl);
  }

  performRemoveLearnerAction() {
    this.setState({
      isConfirmRemoveLearnerModalOpen: true,
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerUpgradeModalOpen(desiredAction, paywall) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
    });
  }


  /*
  ==================================================
   ADDITIONAL STATE MANAGEMENT
  ==================================================
  */

//   getLearnerDeckStats(callback) {
//     this.learner.deckStats({
//       packId: this.props.pack.packId,
//       userId: this.props.learner.userId,
// 
//       done: (data) => {
//         this.setState({
//           learnerDeckStats: data.decks,
//         });
// 
//         if (callback) {
//           callback();
//         }
//       },
//       fail: (jqXHR, status) => {
//         console.log('Something went wrong in show learners deck stats. jqXHR, status:', jqXHR, status);
//       }
//     });
//   }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updateUserOptions() {
    let menuOptionsForUser = Object.keys(this.menuOptionHash).reduce((accumulator, menuOption) => {

      const permitPerPackAdminRights = (this.menuOptionHash[menuOption].onlyPackAdmin) ? (this.props.pack.permission == 'admin') : true;

      if (permitPerPackAdminRights) {
        accumulator.push(this.menuOptionHash[menuOption]);
      }

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

export default LearnerOptionsButton;
