
import PillButton         from '_views/shared/PillButton'
import React              from 'react';
import SubscriptionModel  from '_legacy-models/SubscriptionModel'

import {toClassStr} from '_utils/UiHelper';

class StripeCheckoutWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /* 
      this.props:
        addClasses,
        buttonLabel,
        cardSuffix,
        onError,
        onSuccess,
        stripeKey,
        subscriptionBillingId,
        triggerText,
        userEmail
    */
    this.stripeHandler = null;
    this.Subscription = new SubscriptionModel;
  }

  componentDidMount() {
    this.stripeHandler = StripeCheckout.configure({
      description: 'Web and Mobile Study Platform',
      email: this.props.userEmail,
      image: 'https://www.brainscape.com/assets/bsc_icon.png',
      key: this.props.stripeKey,
      panelLabel: this.props.buttonLabel,
      locale: 'auto',
      name: 'Brainscape',
      token: (token) => this.handleSubmitToServer(token),
      zipCode: true,
    });
  }

  componentWillUnmount() {
    this.stripeHandler.close();
  }

  render() {
    let classes = toClassStr(['stripe-checkout-widget', this.props.addClasses]);

    if (this.props.isButton) {
      return (
        <PillButton
          addClasses="pill-button-emphasized pill-button-xsmall manage"
          label={this.props.buttonLabel}
          onClick={() => this.handleTriggerClick()}
        />
      );
    }

    return (
      <div className={classes}>
        <div className="trigger-element" onClick={(e) => this.handleTriggerClick(e)}>
          <span className="trigger-label">{this.props.triggerText}</span>
          <span className="glyphicon glyphicon-pencil" />
        </div>
      </div>
    );
  }

  handleTriggerClick(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.stripeHandler.open();
  }

  handleSubmitToServer(token) {
    this.Subscription.update({
      reqData: {
        authenticity_token: this.props.authenticityToken,
        billing_id: this.props.subscriptionBillingId,
        stripeToken: token.id
      },
      done: (data) => {
        if (this.props.onSuccess) {
          this.props.onSuccess(data);
        }
      },
      fail: (message) => {
        if (this.props.onError) {
          this.props.onError(data);
        }
      }
    });
  }
}

export default StripeCheckoutWidget;
