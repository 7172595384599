
import {CKEditor}    from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EventManager  from '@brainscape/event-manager';
import PropTypes     from 'prop-types';
import React         from 'react';
import TextField     from '_views/shared/TextField';


const PT = {
  id:                         PropTypes.string,
  isBodyField:                PropTypes.bool,
  isNewCard:                  PropTypes.bool,
  isDynamic:                  PropTypes.bool,
  name:                       PropTypes.string,
  onChange:                   PropTypes.func,
  onFocus:                    PropTypes.func,
  onKeyDown:                  PropTypes.func,
  type:                       PropTypes.string,
  value:                      PropTypes.string,
};

const CK_EDITOR_CONFIG = {
  fontSize: {
    options: [
      'small',
      'default',
      'big'
    ]
  },
  alignment: {
    options: [
      { name: 'left' },
      { name: 'center' },
      { name: 'right' },
    ]
  },
  toolbar: [ 'bold', 'italic', 'underline', '|', 'alignment', 'fontSize', '|', 'bulletedList', 'numberedList', '|', 'link', '|','subscript', 'superscript', 'specialCharacters', '|', 'removeFormat']
};


class CkEditorTextField extends React.Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();

    this.state = {
      ckReadyField: null,
      shouldTriggerFocus: false,
    };

    this.events = new EventManager();

    this.ckEditorInstance = null;
    this.alignmentTimeout = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this._isMounted = true;

    this.clearTimeoutsAndIntervals();
    this.imputeCkEditorFontSizeClasses();
    this.manageNewCardAlignment();
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (this.state.ckReadyField === null) {
      return null;
    }

    return (
      <CKEditor
        config={CK_EDITOR_CONFIG}
        data={this.state.ckReadyField}
        editor={ClassicEditor}
        onChange={this.handleCkChange}
        onFocus={this.handleFocus}
        onReady={this.handleCKInit}
        ref={this.input}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCkChange = (e, editor) => {
    if (this.props.onChange) { 
      const eObject = {
        isPseudoEventObject: true,
        target: {
          key: this.props.name,
          value: editor.getData(),
        }
      };

      this.props.onChange(eObject); 
    }
  };

  handleCKInit = (editor) => {
    this.ckEditorInstance = editor;

    if (this.state.shouldTriggerFocus) {
      if (editor && editor.editing && editor.editing.view) {
        if (typeof editor.editing.view.focus !== 'undefined') {
          editor.editing.view.focus();

          if (this.props.onFocus) {
            this.props.onFocus();
          }

          this.setState({
            shouldTriggerFocus: false,
          });
        }
      }
    }

    editor.keystrokes.set('Esc',                  this.handleCkKeyStroke);
    editor.keystrokes.set('Shift+Tab',            this.handleCkKeyStroke);
    editor.keystrokes.set('Tab',                  this.handleCkKeyStroke);
    editor.keystrokes.set('Ctrl+ArrowRight',      this.handleCkKeyStroke);
    editor.keystrokes.set('Ctrl+ArrowLeft',       this.handleCkKeyStroke);

    const opts = {priority: 'highest'};
    editor.editing.view.document.on('enter', this.handleCkEnter, opts);
  };

  handleCkKeyStroke = (data, cancel) => {
    cancel();

    const e = data.domEvent;
    e.targetId = this.props.id;

    this.props.onKeyDown(e);
  };

  handleCkEnter = (evt, data) => {
    const e = data.domEvent;
    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
      // We need to call `stop` on the editor event to stop the
      // keystroke.  The normal event methods are not enough.
      evt.stop();

      data.preventDefault();

      e.targetId = this.props.type;
      this.props.onKeyDown(e);
    }
  };

  handleFocus = (e) => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.alignmentTimeout);
  }

  focus = () => {
    if (this.ckEditorInstance) {
      this.ckEditorInstance.editing.view.focus();
    } else {
      // set flag to trigger focus upon CKEditor init
      this.setState({
        shouldTriggerFocus: true,
      });
    }
  }

  imputeCkEditorFontSizeClasses = (callback) => {
    let fieldHtml = this.props.value;
    let ckReadyField = "";

    if (fieldHtml !== "") {
      let fieldElem = document.createElement('div');
      fieldElem.innerHTML = fieldHtml;

      const sizedTagElems = fieldElem.querySelectorAll('p.large, p.medium, p.small, li.large, li.medium, li.small');

      sizedTagElems.forEach((tagElem) => {
        this.constructCkEditorFontSizeSpans(tagElem);
      });

      ckReadyField = fieldElem.innerHTML;
    }

    this.setState({
      ckReadyField: ckReadyField,
    }, () => {
      if (callback) {
        callback();
      }
    });
  }

  constructCkEditorFontSizeSpans = (elem) => {
    const ckFontSizeClassName = this.getCkFontSizeClassName(elem.className);

    if (!ckFontSizeClassName) {
      return elem;
    }

    let firstChild = elem.firstChild;
    let wrapperSpan;
    let wrapperSpanContents;

    if (firstChild && firstChild.nodeType == Node.ELEMENT_NODE && firstChild.localName == 'span') {
      wrapperSpan = elem.firstChild;
      wrapperSpanContents = elem.firstChild.innerHTML;
    } else {
      wrapperSpan = document.createElement('span');
      wrapperSpanContents = elem.innerHTML;
    }

    wrapperSpan.className = ckFontSizeClassName;
    wrapperSpan.innerHTML = wrapperSpanContents;

    elem.replaceChildren(wrapperSpan);
  }

  getCkFontSizeClassName(bscClassName) {
    switch (bscClassName) {
      case 'small':
        return 'text-small small';
      break;
      case 'medium':
        return 'text-default medium';
      break;
      case 'large':
        return 'text-big large';
      break;
    }

    return null;
  }

  manageNewCardAlignment = () => {
    if (this.props.isBodyField && this.props.isNewCard) {
      // Set default alignment to center when creating new card.
      // TODO: Investigate if there is an event hook from CK Editor we can use to determine when we can better execute the alignment. May also be able to eliminate initial execution of align left. 
      this.alignmentTimeout = setTimeout(() => {
        clearTimeout(this.alignmentTimeout);
        this.ckEditorInstance.execute( 'alignment', { value: 'left' } );
        this.ckEditorInstance.execute( 'alignment', { value: 'center' } );
      }, 100);
    }
  }

  setBscFontSizeClasses = (fieldHtml) => {
    let bscClassesFieldHtml = fieldHtml.replace(/class="text-small"|class="text-small small"/g, 'class="text-small small"');
    bscClassesFieldHtml = bscClassesFieldHtml.replace(/class="text-big"|class="text-big large"/g, 'class="text-big large"');

    return bscClassesFieldHtml;
  }
}


CkEditorTextField.propTypes = PT;

export default CkEditorTextField;
