
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import SimpleTextButton          from '_views/shared/SimpleTextButton';

const PT = {      
  actionText:                    PropTypes.string,
  addClasses:                    PropTypes.string,
  isOpen:                        PropTypes.bool,
  isProcessing:                  PropTypes.bool,
  message:                       PropTypes.string,
  onCancellation:                PropTypes.func,
  onCloseRequest:                PropTypes.func,
  onOpened:                      PropTypes.func,
  onResolution:                  PropTypes.func,
  resolveButtonText:             PropTypes.string,
  title:                         PropTypes.string,
};


class CautionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
    };

    /*
      this.props:
        actionText,
        addClasses,
        isOpen,
        isProcessing,
        message, 
        onCancellation,
        onCloseRequest,
        onOpened,
        onResolution,
        resolveButtonText,
        title,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const resolveButtonText = this.props.resolveButtonText || "Yes, proceed";

    return (
      <Modal
        addClasses="caution-modal"
        isOpen={this.props.isOpen}
        onClosed={this.props.onClosed}
        onCloseRequest={this.props.onCloseRequest}
      >

        {this.renderTitle()}
        {this.renderMessage()}

        <div className="modal-actions">

          <PillButton
            addClasses="cancel-modal-button"
            label="Cancel"
            onClick={this.handleCancelButtonClick}
          />

          <SimpleTextButton
            addClasses="resolve-modal-text-button"
            isProcessing={this.props.isProcessing}
            label={resolveButtonText}
            onClick={this.handleResolveButtonClick}
            spinnerColor='blue'
          />
        </div>
      </Modal>
    );
  }

  renderTitle() {
    if (this.props.title) {
      return (
        <div className="modal-title">{this.props.title}</div>
      );
    }

    return (
      <div className="modal-title">Caution</div>
    );
  }

  renderMessage() {
    if (this.props.message) {
      return (
        <div className="modal-message">{this.props.message}</div>
      );
    }

    if (this.props.actionText) {
      return (
        <div className="modal-message">{'You are about to ' + this.props.actionText + '. Are you sure that you wish to proceed?'}</div>
      );
    }

    return (
      <div className="modal-message">Are you sure that you wish to proceed?</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = () => {
    if (this.props.onCancellation) {
      this.props.onCancellation();
    } else {
      this.props.onCloseRequest();
    }
  }

  handleResolveButtonClick = () => {
    if (this.props.onResolution) {
      this.props.onResolution();
    } else {
      this.props.onCloseRequest();
    }
  }
}

CautionModal.propTypes = PT;

export default CautionModal;
