import assign             from 'object-assign';
import CBRManager         from '_core/CBRManager';
import Model              from '_core/Model';
import TypeHelper         from '_utils/TypeHelper';

import 'url-search-params-polyfill'; // polyfill to support IE and Edge

class LibraryModel extends Model {
  constructor(props) {
    super(props);

    // the remainder of this constructor and inheritance from Model is forward looking to support of possible Library configuration screens
    // this.memberName = 'Library';
    // super.generateNomenclature();
    // this.resourceRoute= '/libraries';

    // this.schema = {
    //   id: {key: 'id', type: 'text', label: 'Id', tag: 'id'},
    //   userName: {key: 'userName', type: 'text', label: 'User NAme', tag: 'user-name'},
    //   userId: {key: 'userId', type: 'text', label: 'User Id', tag: 'user-id'},
    //   packs: {key: 'packs', type: 'list', label: 'Packs', tag: 'packs'},
    //   packDeckSelections: {key: 'packDeckSelections', type: 'map', label: 'Pack Deck Selections', tag: 'pack-deck-selections'},
    //   packStudyConfigs: {key: 'packStudyConfigs', type: 'map', label: 'Pack Study Configs', tag: 'pack-study-configs'}
    // }
    // this.nameKey = "userName";

    this.cbrManager;

    /* localStorage data structures:

      userLibrary-<userId>: {
        localPrefs = {
          <prefKey>: <prefValue>,
          ...
        },

        packDeckSelections = {
          <packId>: {
            <deckId>: true,
            <deckId>: true,
            ...
          },
          <packId>: {
            <deckId>: true,
            <deckId>: true,
            ...
          },
        },

        packLearnerSelections = {
          <packId>: {
            <userId>: true,
            <userId>: true,
            ...
          },
          <packId>: {
            <userId>: true,
            <userId>: true,
            ...
          },
        },

        packStudyConfigs = {
          <packId>: {
            mixType: <mixType>,
            isMixSet: true/false  (the checkboxes have been changed by the user or the UI)
          },
          <packId>: {
            mixType: <mixType>,
            isMixSet: true/false
          },
        },

        messageViews = {
          <messageId>: {
            type: 'flash', 'banner', 'modal'
            data: {},
          }
        },

        flashMessageViews = {
          <messageId>: {
            seen: true,
            closed: false,
          },
          ...
        },

        bannerViews = {
          <bannerId>: {
            seen: true,
            closed: false,
          },
          ...
        },

        modalViews = {
          <modalId>: {
            deckIds:
          },
          ...
        },
      },

      userLibrary-<userId>: {
        ...
      }

    */
  }

  resetAllUserLibraries() {
    localStorage.clear();
  }

  resetUserLibrary(userId) {
    const itemKey = 'userLibrary-' + userId;
    this.createNewLibrary(itemKey);
  }

  getLocalStorageItem(itemKey) {
    let item = null;

    try {
      item = JSON.parse(localStorage.getItem(itemKey));
    }
    catch(err) {
      console.log('Something went wrong reading from local storage. err:', err);
    }

    return item;
  }

  setLocalStorageItem(itemKey, itemValue) {    
    try {
      localStorage.setItem(itemKey, JSON.stringify(itemValue));
    }
    catch(err) {
      console.log('Something went wrong writing to local storage. err:', err);
      return false;
    }

    return true;
  }

  getOrCreateUserLibrary(userId) {
    let userLibrary;
    const itemKey = 'userLibrary-' + userId;

    userLibrary = this.getLocalStorageItem(itemKey);

    if (TypeHelper.isObject(userLibrary)) {
      userLibrary = this.ensureLibraryDefaults(userLibrary, itemKey);
    } else {
      userLibrary = this.createNewLibrary(itemKey);
    }

    return userLibrary;
  }

  ensureLibraryDefaults(library, itemKey) {
    library.packDeckSelections = TypeHelper.isObject(library.packDeckSelections) ? library.packDeckSelections : {};
    library.packLearnerSelections = TypeHelper.isObject(library.packLearnerSelections) ? library.packLearnerSelections : {};
    library.packStudyConfigs = TypeHelper.isObject(library.packStudyConfigs) ? library.packStudyConfigs : {};
    library.localPrefs = TypeHelper.isObject(library.localPrefs) ? library.localPrefs : {};

    this.setLocalStorageItem(itemKey, library);

    return library;
  }

  createNewLibrary(itemKey) {
    let userLibrary = {};
    userLibrary.bannerViews = {};
    userLibrary.flashMessageViews = {};
    userLibrary.localPrefs = {};
    userLibrary.messageViews = {};
    userLibrary.packDeckSelections = {};
    userLibrary.packLearnerSelections = {};
    userLibrary.packStudyConfigs = {};

    this.setLocalStorageItem(itemKey, userLibrary);

    return userLibrary;
  }

  updateUserLibraryPackData(userId, packId, resourceKey, resourceData) {
    let userLibrary = this.getOrCreateUserLibrary(userId);
    const itemKey = 'userLibrary-' + userId;
    userLibrary[resourceKey][packId] = resourceData;

    this.setLocalStorageItem(itemKey, userLibrary);
  }

  updateUserLocalPrefs(userId, prefKey, prefData) {
    let userLibrary = this.getOrCreateUserLibrary(userId);
    const itemKey = 'userLibrary-' + userId;
    userLibrary.localPrefs = userLibrary.localPrefs || {};
    userLibrary.localPrefs[prefKey] = prefData;

    this.setLocalStorageItem(itemKey, userLibrary);
  }

  updateBannerViews(userId, views) {
    let userLibrary = this.getOrCreateUserLibrary(userId);
    const itemKey = 'userLibrary-' + userId;
    userLibrary.bannerViews = userLibrary.bannerViews || {};
    userLibrary.bannerViews = views;

    this.setLocalStorageItem(itemKey, userLibrary);
  }

  updateFlashMessageViews(userId, views) {
    let userLibrary = this.getOrCreateUserLibrary(userId);
    const itemKey = 'userLibrary-' + userId;
    userLibrary.flashMessageViews = userLibrary.flashMessageViews || {};
    userLibrary.flashMessageViews = views;

    this.setLocalStorageItem(itemKey, userLibrary);
  }

  getUserLocalPrefs(userId) {
    const userLibrary = this.getOrCreateUserLibrary(userId);
    let localPrefs = userLibrary.localPrefs;
    localPrefs = TypeHelper.isObject(localPrefs) ? localPrefs : {};

    return localPrefs;
  }

  getUserLocalPref(userId, prefKey) {
    const localPrefs = this.getUserLocalPrefs(userId);
    return localPrefs[prefKey] || null;
  }

  getPackDeckSelections(userId, packId) {
    const userLibrary = this.getOrCreateUserLibrary(userId);
    let pds = userLibrary.packDeckSelections[packId];
    pds = TypeHelper.isObject(pds) ? pds : {};

    return pds;
  }

  getPackLearnerSelections(userId, packId) {
    const userLibrary = this.getOrCreateUserLibrary(userId);
    let pls = userLibrary.packLearnerSelections[packId];
    pls = TypeHelper.isObject(pls) ? pls : {};

    return pls;
  }

  getPackStudyConfigs(userId, packId) {
    const userLibrary = this.getOrCreateUserLibrary(userId);
    let psc = userLibrary.packStudyConfigs[packId];
    psc = TypeHelper.isObject(psc) ? psc : {};

    return psc;
  }

  getSelectedPackDeckIds(userId, packId) {
    const pds = this.getPackDeckSelections(userId, packId);
    return Object.keys(pds);
  }

  getSelectedPackLearners(userId, packId) {
    const pls = this.getPackLearnerSelections(userId, packId);
    return Object.keys(pls);
  }

  getBannerViews(userId) {
    const userLibrary = this.getOrCreateUserLibrary(userId);
    let views = userLibrary.bannerViews;
    views = TypeHelper.isObject(views) ? views : {};

    return views;
  }

  hasSeenBanner(userId, bannerId) {
    let views = this.getBannerViews(userId);
    let bannerView = views[bannerId] || {};
    return !!bannerView.seen; // !! coerces value to boolean
  }

  hasClosedBanner(userId, bannerId) {
    let views = this.getBannerViews(userId);
    let bannerView = views[bannerId] || {};
    return !!bannerView.closed; // !! coerces value to boolean
  }

  markBannerAsSeen(userId, bannerId) {
    let views = this.getBannerViews(userId);
    let bannerView = views[bannerId] || {};
    bannerView.seen = true;

    views[bannerId] = bannerView;

    this.updateBannerViews(userId, views);
  }

  markBannerAsClosed(userId, bannerId) {
    let views = this.getBannerViews(userId);
    let bannerView = views[bannerId] || {};
    bannerView.closed = true;

    views[bannerId] = bannerView;

    this.updateBannerViews(userId, views);
  }

  getFlashMessageViews(userId) {
    const userLibrary = this.getOrCreateUserLibrary(userId);
    let views = userLibrary.flashMessageViews;
    views = TypeHelper.isObject(views) ? views : {};

    return views;
  }

  getMessageViews(userId, messageId) {
    const userLibrary = this.getOrCreateUserLibrary(userId);
    const messageViews = userLibrary.messageViews || {};
    
    return messageViews[messageId] || {};
  }

  hasSeenFlashMessage(userId, messageId) {
    let views = this.getFlashMessageViews(userId);
    let messageView = views[messageId] || {};
    return !!messageView.seen; // !! coerces value to boolean
  }

  hasClosedFlashMessage(userId, messageId) {
    let views = this.getFlashMessageViews(userId);
    let messageView = views[messageId] || {};
    return !!messageView.closed; // !! coerces value to boolean
  }

  markFlashMessageAsSeen(userId, messageId) {
    let views = this.getFlashMessageViews(userId);
    let messageView = views[messageId] || {};
    messageView.seen = true;

    views[messageId] = messageView;

    this.updateFlashMessageViews(userId, views);
  }

  markFlashMessageAsClosed(userId, messageId) {
    let views = this.getFlashMessageViews(userId);
    let messageView = views[messageId] || {};
    messageView.closed = true;

    views[messageId] = messageView;

    this.updateFlashMessageViews(userId, views);
  }

  selectPackDecks(userId, packId, deckIds) {
    let pds = this.getPackDeckSelections(userId, packId);

    deckIds.forEach((deckId) => {
      pds[deckId] = true;
    });

    this.updateUserLibraryPackData(userId, packId, 'packDeckSelections', pds);
    this.markPackMixAsSet(userId, packId);
  }

  selectPackLearners(userId, packId, learnerIds) {
    let pls = this.getPackLearnerSelections(userId, packId);

    learnerIds.forEach((learnerId) => {
      pls[learnerId] = true;
    });

    this.updateUserLibraryPackData(userId, packId, 'packLearnerSelections', pls);
  }

  setMessageView(userId, messageId, messageData) {
    let userLibrary = this.getOrCreateUserLibrary(userId);
    const itemKey = 'userLibrary-' + userId;

    const messageViews = userLibrary.messageViews || {};
    messageViews[messageId] = messageData;
    userLibrary.messageViews = messageViews;

    this.setLocalStorageItem(itemKey, userLibrary);
  }

  unselectPackDecks(userId, packId, deckIds) {
    let pds = this.getPackDeckSelections(userId, packId);

    deckIds.forEach((deckId) => {
      delete pds[deckId];
    });

    this.updateUserLibraryPackData(userId, packId, 'packDeckSelections', pds);
    this.markPackMixAsSet(userId, packId);
  }

  unselectPackLearners(userId, packId, learnerIds) {
    let pls = this.getPackLearnerSelections(userId, packId);

    learnerIds.forEach((learnerId) => {
      delete pls[learnerId];
    });

    this.updateUserLibraryPackData(userId, packId, 'packLearnerSelections', pls);
  }

  calculateCardSetStats(cardsHash, cardIds) {
    let aggregateConfidenceTotal = 0;
    let cardsStudied = 0;
    let mastery = 0;

    if (cardIds.length < 1) {
      return {mastery, cardsStudied};
    }

    cardIds.forEach((cardId, index) => {
      const level = cardsHash[cardId].level;
      aggregateConfidenceTotal = aggregateConfidenceTotal + level;
      cardsStudied = cardsStudied + ((level > 0) ? 1 : 0);
    });

    mastery = aggregateConfidenceTotal / (cardIds.length * 5);

    return {cardsStudied, mastery};
  }

  getPackStudyMixType(userId, userPack, deckIds=null) {
    if (!deckIds) { deckIds = userPack.deckIds; }

    if (deckIds && deckIds.length == 1) {
      // This is a weird rule requested by Andrew: https://trello.com/c/wBeoTvzj
      return 'progressive';
    }

    let psc = this.getPackStudyConfigs(userId, userPack.packId);
    if (psc.mixType && psc.mixType != '') { return psc.mixType; }

    if (userPack.mixType && userPack.mixType != '') { return userPack.mixType; }

    return 'progressive';
  }

  setPackStudyMixType(userId, packId, mixType) {
    let psc = this.getPackStudyConfigs(userId, packId);
    psc.mixType = mixType;

    this.updateUserLibraryPackData(userId, packId, 'packStudyConfigs', psc);
  }

  isPackMixSet(userId, packId) {
    let psc = this.getPackStudyConfigs(userId, packId);
    return psc.isMixSet || false;
  }

  markPackMixAsSet(userId, packId) {
    let psc = this.getPackStudyConfigs(userId, packId);
    psc.isMixSet = true;

    this.updateUserLibraryPackData(userId, packId, 'packStudyConfigs', psc);
  }

  markPackLearnersAsSelected(userId, packId) {
    let psc = this.getPackStudyConfigs(userId, packId);
    psc.isMixSet = true;

    this.updateUserLibraryPackData(userId, packId, 'packStudyConfigs', psc);
  }

  getPackStudyMixUrl(userId, userPack, orderedPackDeckIds) {
    const params  = new URLSearchParams();
    const deckIds = this.getSelectedDeckIdsForPack(
      userId,
      userPack.packId,
      orderedPackDeckIds,
    );

    if (deckIds.length < 1 || deckIds.length == orderedPackDeckIds.length) {
      params.append('classes', userPack.packId);
    } else {
      params.append(
        'classes',
        deckIds.map((deckId) => `${userPack.packId}-${deckId}`).join(','),
      );
    }

    params.append('type', this.getPackStudyMixType(userId, userPack, deckIds));

    return `/l/study?${params.toString()}`;
  }

  getSelectedDeckIdsForPack = (userId, packId, packDeckIds) => {
    const pds     = this.getPackDeckSelections(userId, packId);
    const deckIds = [];

    if (packDeckIds) {
      packDeckIds.forEach((deckId) => {
        if (pds[deckId]) { deckIds.push(deckId); }
      });
    }

    return deckIds;
  }

  getPackDeckStudyMixUrl(userId, userPack, deckId) {
    const mixType = this.getPackStudyMixType(userId, userPack, [deckId]);

    return `/l/study?classes=${userPack.packId}-${deckId}&type=${mixType}`;
  }

  generatePackDeckList(packId, deckIds) {
    // returns url ready string of pack-deck pairings

    const packDeckArray = deckIds.reduce((result, deckId) => {
      result.push(packId + '-' + deckId);
      return result;
    }, []);

    return packDeckArray.toString();
  }

  addPack(pack) {
    console.log('in LibraryModel.addPack. adding pack:', pack);
  }

  removePack(userId, packId) {
    let userLibrary = this.getOrCreateUserLibrary(userId);
    const itemKey = 'userLibrary-' + userId;

    if (userLibrary.packDeckSelections[packId]) {
      delete userLibrary.packDeckSelections[packId];
    }

    if (userLibrary.packLearnerSelections[packId]) {
      delete userLibrary.packLearnerSelections[packId];
    }

    if (userLibrary.packStudyConfigs[packId]) {
      delete userLibrary.packStudyConfigs[packId];
    }

    this.setLocalStorageItem(itemKey, userLibrary);
  }

  syncPackDecks(pack) {
    const userId = pack.userId;
    const packId = pack.packId;
    const localSelectedDeckIds = this.getSelectedPackDeckIds(userId, packId) || {};
    let syncedDeckSettings = {};

    if (pack.deckIds && pack.deckIds.length > 0) {

      for (let i=0; i < localSelectedDeckIds.length; i++) {
        if (pack.deckIds.indexOf(localSelectedDeckIds[i]) != -1) {
          // because we don't store unselected decks locally, we only need to remove local decks that were previously selected (and hence, set in our local Storage) and have recently been removed. The inverse case, decks that recently been added, will simply show up as unselected in the UI.
          syncedDeckSettings[localSelectedDeckIds[i]] = true;
        };
      }

      this.updateUserLibraryPackData(userId, packId, 'packDeckSelections', syncedDeckSettings);

    } else {
      this.updateUserLibraryPackData(userId, packId, 'packDeckSelections', {});
    }
  }

  syncPackLearners(packId, learners) {
    const userId = BSC.userId;
    const packLearnerIds = learners.map((learner, index) => {
      return learner.userId;
    });
    const localSelectedLearners = this.getSelectedPackLearners(userId, packId) || {};
    let syncedLearnerSettings = {};

    if (learners.length > 0) {

      for (let i=0; i < localSelectedLearners.length; i++) {
        if (packLearnerIds.indexOf(localSelectedLearners[i]) != -1) {
          // because we don't store unselected learners locally, we only need to remove local learners that were previously selected (and hence, set in our local Storage) and have recently been removed. The inverse case, learners that recently been added, will simply show up as unselected in the UI.
          syncedLearnerSettings[localSelectedLearners[i]] = true;
        };
      }

      this.updateUserLibraryPackData(userId, packId, 'packLearnerSelections', syncedLearnerSettings);

    } else {
      this.updateUserLibraryPackData(userId, packId, 'packLearnerSelections', {});
    }
  }
}

export default LibraryModel;
