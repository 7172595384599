
import model        from '_core/newModel';
import packDeck     from '_models/packDeck';

const packDeckReordering = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckIds) {    
    return new Promise((resolve, reject) => {
      try {
        const data = {deckIds: deckIds.join(',')};
        const path = `packs/${packId}/decks/reorder`;

        model.set(path, data).then(() => {
          packDeck.index(packId).then(decksData => { 
            model.publish('pack-decks:reordered', {
              packId: packId,
              deckIds: decksData.deckIds,
              decks: decksData.decks,
            });

            resolve(decksData);
          }).catch(err => {
            reject(err);
          });
        }).catch(err => {
          reject(err);
        });
      } catch(err) {
        console.error('something went wrong during packDeck reorder create. err:', err);
        reject(err);
      }
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default packDeckReordering;
