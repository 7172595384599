
import SharePackModalSet            from '_views/modals/SharePackModalSet';
import EventManager                 from '@brainscape/event-manager';
import React                        from 'react';

class SharePackModalSetController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalSetOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener(
      'share-pack-modal-set:open',
      (data) => {
        this.setState({
          isModalSetOpen: true,
          viewProps: data
        });
      }
    );

    this.events.addListener(
      'share-pack-modal-set:close',
      () => {
        this.handleCloseRequest();
      }
    );
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) {
      return null;
    }

    const phase = viewProps.pack?.flags?.isPrivate ? 'email' : viewProps.phase || null;

    return (
      <SharePackModalSet
        addClasses={viewProps.addClasses}
        authenticityToken={viewProps.authenticityToken}
        currentUser={viewProps.currentUser}
        hasBackOption={viewProps.hasBackOption}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isModalSetOpen={this.state.isModalSetOpen}
        isProcessing={viewProps.isProcessing}
        onModalSetClosed={this.handleModalSetClosed}
        pack={viewProps.pack}
        phase={phase}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invoked after all child modal close animations are complete. Removes modals from DOM
  handleModalSetClosed = () => {
    const onModalSetClosed = this.state.viewProps.onModalSetClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onModalSetClosed) {
        onModalSetClosed();
      }
    });
  }
}

export default SharePackModalSetController;