
import toast, { Toaster }           from 'react-hot-toast';
import EventManager                 from '@brainscape/event-manager';
import React                        from 'react';

class ToastController extends React.Component {
  constructor(props) {
    super(props);

    /* viewProps:
      duration,
      message,
      type,  // success, error, or (pass null)
      onCloseRequest,
    */

    this.state  = {
      isToastOpen: true, 
      viewProps: null
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('toast:open',  this.handleOpenRequest);
    this.events.addListener('toast:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.state.isToastOpen) return null;

    const viewProps = this.state.viewProps;
    const position = viewProps?.position || 'top-right';

    return (
      <Toaster 
        position="top-right"
        toastOptions={{
          className: `${viewProps?.addClasses}`,
          duration: 3000,
          success: {
            iconTheme: {
              primary: '#29A5DC',
              secondary: 'white',
            }
          },
        }}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOpenRequest = (data) => {
    this.setState({
      isToastOpen: true, 
      viewProps: data,
    }, () => {
      this.displayToast();
    });
  };

  // invokes toast closing animation. Toast is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isToastOpen: false,
    }, () => {
      toast.dismiss();

      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  };

  // TODO: To be invoked after toast close animation is complete. Removes toast from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  displayToast = () => {
    const viewProps = this.state.viewProps;

    switch (viewProps.type) {
      case 'success':
        toast.success(viewProps.message, {
          duration: viewProps.duration || 1500, 
          position: viewProps.position || 'top-right',
        });
      break;
      case 'error':
        toast.error(viewProps.message, {
          duration: viewProps.duration || 3000, 
          position: viewProps.position || 'top-right',
        });
      break;
      case 'warning':
        toast(viewProps.message, {
          duration: viewProps.duration || 3000, 
          position: viewProps.position || 'top-right',
        });
      break;
      default:
        toast(viewProps.message);
    };
  }


}

export default ToastController;
