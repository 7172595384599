
import AccountPage                      from '_account/AccountPage';
import CautionModalController           from '_controllers/modals/CautionModalController';
import DetailLoadingOverlayController   from '_controllers/DetailLoadingOverlayController';
import EventManager                     from '@brainscape/event-manager';
import MobileAccountScreen              from '_account/MobileAccountScreen';
import PropTypes                        from 'prop-types';
import React                            from 'react';
import TooltipController                from '_controllers/TooltipController';
import UiHelper                         from '_utils/UiHelper';
import userPassword                     from '_legacy-models/userPassword';
import userProfile                      from '_legacy-models/userProfile';
import UpgradeModalController           from '_controllers/modals/UpgradeModalController';
import DialogModalController            from '_controllers/modals/DialogModalController';

import { toClassStr } from '_utils/UiHelper';

const PT = {
  addClasses        : PropTypes.string,
  initialDetailId   : PropTypes.string,
  authenticityToken : PropTypes.string,
  stripeKey         : PropTypes.string,
  userExperiments   : PropTypes.object,
  userFeatures      : PropTypes.object,
  userFlags         : PropTypes.object,
  userId            : PropTypes.node,
};

const BLANK_ACCOUNT_SETTINGS_FIELDS = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
};


class AccountView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDetailId:          props.initialDetailId,
      currentUser:              {},
      editedAccountSettings:    BLANK_ACCOUNT_SETTINGS_FIELDS,
      editedUserProfile:        {},
      isEditingAccountSettings: false,
      isEditingUserProfile:     false,
      isMobileViewportSize:     false,
      isLoadingUserInfo:        true,
      toastContent:             null,
    };

    this.events = new EventManager();

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.manageViewport();
    this.setCurrentUser();
    this.subscribeToEvents();
    this.startWindowResizeMonitor();
  }

  componentWillUnmount() {
    this.stopWindowResizeMonitor();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isMobileModeClass = this.state.isMobileViewportSize ? 'is-mobile-mode' : 'is-desktop-mode';
    const currentUserProfile = this.state.currentUser.profile;
    const isUserPro = currentUserProfile ? (currentUserProfile.isPro ? 'is-pro' : '') : false;
    const classes = toClassStr(['account-view library-account-page', isMobileModeClass, isUserPro]);

    if (this.state.isMobileViewportSize) {
      return (
        <div className={classes}>
          <MobileAccountScreen
            authenticityToken={this.props.authenticityToken}
            currentDetailId={this.state.currentDetailId}
            currentUser={this.state.currentUser}
            editedAccountSettings={this.state.editedAccountSettings}
            editedUserProfile={this.state.editedUserProfile}
            isEditingAccountSettings={this.state.isEditingAccountSettings}
            isEditingUserProfile={this.state.isEditingUserProfile}
            isLoadingUserInfo={this.state.isLoadingUserInfo}
            isMobileViewportSize={this.state.isMobileViewportSize}
            onAccountSettingsFieldChange={this.handleAccountSettingsFieldChange}
            onAccountSettingsFormSubmit={this.handleAccountSettingsFormSubmit}
            onDetailChangeRequest={this.handleDetailChangeRequest}
            onUserProfileFieldChange={this.handleUserProfileFieldChange}
            onUserProfileFormSubmit={this.handleUserProfileFormSubmit}
            onResetAccountSettingsFormRequest={this.handleResetAccountSettingsFormRequest}
            onResetUserProfileFormRequest={this.handleResetUserProfileFormRequest}
            stripeKey={this.props.stripeKey}
          />

          <CautionModalController />
          <UpgradeModalController />
          <DialogModalController />
        </div>
      );
    }

    return (
      <div className={classes}>
        <TooltipController />
        <AccountPage
          authenticityToken={this.props.authenticityToken}
          currentDetailId={this.state.currentDetailId}
          currentUser={this.state.currentUser}
          editedAccountSettings={this.state.editedAccountSettings}
          editedUserProfile={this.state.editedUserProfile}
          isEditingAccountSettings={this.state.isEditingAccountSettings}
          isEditingUserProfile={this.state.isEditingUserProfile}
          isLoadingUserInfo={this.state.isLoadingUserInfo}
          isMobileViewportSize={this.state.isMobileViewportSize}
          onAccountSettingsFieldChange={this.handleAccountSettingsFieldChange}
          onAccountSettingsFormSubmit={this.handleAccountSettingsFormSubmit}
          onDetailChangeRequest={this.handleDetailChangeRequest}
          onUserProfileFieldChange={this.handleUserProfileFieldChange}
          onUserProfileFormSubmit={this.handleUserProfileFormSubmit}
          onResetAccountSettingsFormRequest={this.handleResetAccountSettingsFormRequest}
          onResetUserProfileFormRequest={this.handleResetUserProfileFormRequest}
          stripeKey={this.props.stripeKey}
        />

        <CautionModalController />
        <DetailLoadingOverlayController />
        <UpgradeModalController />
        <DialogModalController />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleDetailChangeRequest = (detailId) => {
    this.setState({
      currentDetailId: detailId,
    }, () => {
      this.updateHistory();
    })
  }

  handleUserProfileFieldChange = (fieldKey, value) => {
    const editedUserProfile = (this.state.isEditingUserProfile) ? {...this.state.editedUserProfile} : {...this.state.currentUser.profile};

    editedUserProfile[fieldKey] = value;

    this.setState({
      editedUserProfile: editedUserProfile,
      isEditingUserProfile: true,
    });
  }

  handleAccountSettingsFieldChange = (fieldKey, value) => {
    const editedAccountSettings = (this.state.isEditingAccountSettings) ? {...this.state.editedAccountSettings} : {...BLANK_ACCOUNT_SETTINGS_FIELDS};

    editedAccountSettings[fieldKey] = value;

    this.setState({
      editedAccountSettings: editedAccountSettings,
      isEditingAccountSettings: true,
    });
  }

  handlePasswordError = (err) => {
    this.triggerToastOpen(err.message, 'error');
  }

  handleProfileError = (err) => {
    this.triggerToastOpen('Either this e-mail already exists or your name entries exceed 50 characters', 'error');
  }

  handleResetAccountSettingsFormRequest = () => {
    this.setState({
      editedAccountSettings: {...BLANK_ACCOUNT_SETTINGS_FIELDS},
      isEditingAccountSettings: false,
      isLoadingUserInfo: false,
    });
  }

  handleResetUserProfileFormRequest = () => {
    this.setState({
      isEditingUserProfile: false,
      isLoadingUserInfo: true,
    }, () => {
      this.getUserProfile();
    });
  }

  handleAccountSettingsFormSubmit = () => {
    this.updateUserAccountSettings();
  }

  handleUserProfileFormSubmit = () => {
    this.updateUserProfile();
  }

  handleUserIdentityDestroyed = (eventData) => {
    const identities = eventData.identities;

    const identityProviders = identities.map(identity => {
      return identity.provider;
    });

    const currentUser = {...this.state.currentUser};
    currentUser.profile.identityProviders = identityProviders;

    this.setState({
      currentUser: currentUser,
    });
  }

  handleWindowResize = (e) => {
    this.manageViewport();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerDetailLoadingOverlayClose = () => {
    EventManager.emitEvent('detail-loading-overlay:close', {});
  }

  triggerDetailLoadingOverlayOpen = (message=null) => {
    EventManager.emitEvent('detail-loading-overlay:open', {message: message})
  }

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type) => {
    EventManager.emitEvent('toast:open', {
      message: message,
      position: 'top-center',
      type: type,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getUserProfile = () => {
    userProfile.show().then(profileData => {
      const currentUser = {...this.state.currentUser};
      currentUser.profile = profileData;
      BSC.userId = profileData.userId; // TODO: refactor so we don't need this global data

      this.setState({
        currentUser: currentUser,
        editedUserProfile: {...currentUser.profile},
        isEditingUserProfile: false,
        isLoadingUserInfo: false,
      });
    }).catch(err => {
      console.error('something went wrong in userProfile.show. err:', err);
    });
  }

  manageViewport() {
    UiHelper.adjustViewportHeight();
    const isMobileViewportSize = UiHelper.detectIfMobileSize();

    this.setState({
      isMobileViewportSize: isMobileViewportSize,
    });
  }

  setCurrentUser = () => {
    this.setState({
      isLoadingUserInfo: true,
    }, () => {
      const currentUser = {...this.state.currentUser};
      currentUser.userId = this.props.userId;
      currentUser.flags = this.props.userFlags;
      currentUser.flags.experiments = this.props.userExperiments;
      currentUser.flags.features = this.props.userFeatures;

      this.setState({
        currentUser: currentUser,
      }, () => {
        this.getUserProfile();
      });
    })
  }

  updateUserProfile = () => {
    const newProfile = {...this.state.editedUserProfile};

    if (newProfile.avatarUrl == this.state.currentUser.profile.avatarUrl) {
      delete newProfile.avatarUrl;
    }

    this.triggerDetailLoadingOverlayOpen('Saving Changes...');

    userProfile.update(newProfile).then(profileData => {
      const currentUser = {...this.state.currentUser};
      currentUser.profile = profileData;

      this.setState({
        currentUser: currentUser,
        editedUserProfile: {...currentUser.profile},
        isEditingUserProfile: false,
      }, () => {
        this.triggerDetailLoadingOverlayClose();
      });
    }).catch(err => {
      console.error(err);
    });
  }

  startWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.addEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  stopWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.removeEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  subscribeToEvents = () => {
    this.events.addListeners([
      ['user-identity:destroyed', this.handleUserIdentityDestroyed],
    ]);
  }

  unsubscribeToEvents = () => {
    this.events.disable();
  }

  updateHistory = () => {
    const currentDetailId = this.state.currentDetailId;
    const path = `/l/account/${currentDetailId}`;

    window.history.pushState(
      {
        currentDetailId: currentDetailId,
      },
      null,
      path,
    );
  }

  updateUserAccountSettings = () => {
    this.triggerDetailLoadingOverlayOpen('Saving Changes...');

    userPassword.update(this.state.editedAccountSettings).then((res) => {
      this.setState({
        editedAccountSettings: BLANK_ACCOUNT_SETTINGS_FIELDS,
        isEditingAccountSettings: false,
      }, () => {
        this.triggerDetailLoadingOverlayClose();
        this.triggerToastOpen('Updates Saved', 'success');
      });
    }).catch(err => {
      this.triggerDetailLoadingOverlayClose();
      this.handlePasswordError(err);
    });
  }

  updateUserProfile = () => {
    this.triggerDetailLoadingOverlayOpen('Saving Changes...');

    const newProfile = this.state.editedUserProfile;

    if (newProfile.avatarUrl == this.state.currentUser.profile.avatarUrl) {
      delete newProfile.avatarUrl;
    }

    userProfile.update(newProfile).then(profileData => {
      const currentUser = {...this.state.currentUser};
      currentUser.profile = profileData;

      this.setState({
        currentUser: currentUser,
        editedUserProfile: {...currentUser.profile},
        isEditingUserProfile: false,
      }, () => {
        this.triggerDetailLoadingOverlayClose();
        this.triggerToastOpen('Updates Saved', 'success');
      });
    }).catch(err => {
      this.triggerDetailLoadingOverlayClose();
      this.handleProfileError(err);
    });
  }
}

AccountView.propTypes = PT;

export default AccountView;
