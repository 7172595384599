
import model from '_core/newModel';

const deckPlacement = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(sourcePackId, deckId, destinationPackId) {
    const path = `packs/${sourcePackId}/decks/${deckId}/placements`;
    
    const duplicateData = {
      destinationPackId: destinationPackId,
    }

    return model.create(path, duplicateData);
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckPlacement;
