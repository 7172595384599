
import EventManager     from '@brainscape/event-manager';
import React            from 'react';
import CreateDeckModal  from '_views/modals/CreateDeckModal';

import packDeck         from '_models/packDeck';

class CreateDeckModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isProcessing: false,
      modalProps: null,
    };

    /* modalProps (all optional):
      addClasses,
      isOpen,
      onCloseRequest,
      onClosed,
      onOpened,
    */

    this.events = new EventManager();
  }

  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('create-deck-modal:open', this.handleOpenRequest);
    this.events.addListener('create-deck-modal:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.state.modalProps) {
      return null;
    }

    return (
      <CreateDeckModal
        currentUser={this.state.modalProps.currentUser}
        isOpen={this.state.isModalOpen}
        isProcessing={this.state.isProcessing}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        onFormSubmit={this.handleFormSubmit}
        onOpened={this.handleOpened}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClosed = () => {
    this.setState({
      modalProps: null
    }, () => {
      if (this.state.modalProps?.onClosed) {
        this.state.modalProps.onClosed();
      }
    });
  }

  handleCloseRequest = () => {
    this.publishDeckCreateDismissed(); // supports FTUE

    this.setState({
      isModalOpen: false,
      isProcessing: false,
    }, () => {
      if (this.state.modalProps?.onCloseRequest) {
        this.state.modalProps.onCloseRequest();
      }
    });
  }

  handleFormSubmit = (deckData) => {
    this.setState({
      isProcessing: true,
    });

    packDeck.create(this.state.modalProps.packId, deckData).then(() => {
      this.setState({
        isModalOpen: false,
        isProcessing: false,
      }, () => {
        if (this.state.modalProps?.onResolution) {
          this.state.modalProps.onResolution();
        }
      });
    }).catch(err => {
      console.log('Something went wrong in CreateDeckModalController.handleFormSubmit. err, deckData:', err, deckData);
    });
  }

  handleOpened = (data) => {
    if (this.state.modalProps?.onOpened) {
      this.state.modalProps.onOpened();
    }
  }

  handleOpenRequest = (data) => {
    this.publishDeckCreateRequested(); // supports FTUE

    this.setState({
      isModalOpen: true,
      isProcessing: false,
      modalProps: data
    }, () => {
      if (this.state.modalProps?.onOpenRequest) {
        this.state.modalProps.onOpenRequest();
      }
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishDeckCreateDismissed = () => {
    EventManager.emitEvent('deck:create-dismissed', {});
  }

  publishDeckCreateRequested = () => {
    EventManager.emitEvent('deck:create-requested', {});
  }
}

export default CreateDeckModalController;
