
import EventBus     from '../utils/EventBus';
import EventManager from '@brainscape/event-manager';

import 'url-search-params-polyfill';

/*
==================================================
 EXPORTED METHODS
==================================================
*/

// Exported: The full power of fetch with a consistent URL pattern.
//
// NOTE: For GET calls, params are expected to be included properly in path param
//
const apiFetch = (path, opts) => {
  return fetch(`/api/${path}`, opts).then(checkJsonResponse).catch(err => {
    console.error(err);
  });
}

// Exported: Standard way to work with API endpoints that are JSON and use
//           the `respond_to_error` error handling.
const apiDelete   = (path, params={}) => apiAjax(path, 'DELETE', params);
const apiGet      = (path, params={}) => apiAjax(path, 'GET', params);
const apiPatch    = (path, params={}) => apiAjax(path, 'PATCH', params);
const apiPost     = (path, params={}) => apiAjax(path, 'POST', params);
const apiPut      = (path, params={}) => apiAjax(path, 'PUT', params);

const apiError = (error) => {
  const opts = {msg: 'Unknown Error (code: 433)'};

  if (error instanceof Error) {
    opts.code = error.code;
    opts.msg  = error.message;
    opts.url  = error.url;
  }

  EventBus.publish('error:msg', opts, true);
  EventManager.emitEvent('error-modal:open', {
    errorMessage: `There was an error contacting the server: ${opts.msg}.`,
  });
};

const checkJsonResponse = (rs) => {
  if (!rs.ok) {
    const ct = rs.headers.get('content-type');

    if (ct.indexOf('json') >= 0) {
      return rs.json().then((data) => { throw new Error(data.error); });
    } else if (ct.indexOf('html') >= 0) {
      throw new Error('Unknown Error (code: 740)');
    } else {
      return rs.text().then((data) => { throw new Error(data); });
    }
  }

  return rs.json();
}


/*
==================================================
 PRIVATE METHODS
==================================================
*/

// Convenience method to reduce duplication.
const apiAjax = (path, method, params) => {
  let   url  = `/api/${path}`;
  const opts = {method: method, headers: {'Content-Type': 'application/json'}};

  if (params) {
    if (method == 'DELETE' || method == 'GET' || method == 'HEAD') {
      const sp = new URLSearchParams();
      Object.keys(params).forEach((key) => sp.append(key, params[key]));
      url += `?${sp.toString()}`;
    } else {
      opts.body = JSON.stringify(params);
    }
  }

  return fetch(url, opts).then(checkJsonResponse).catch(err => {
    console.error(err);
  });
}


export {
  apiDelete,
  apiError,
  apiFetch,
  apiGet,
  apiPatch,
  apiPost,
  apiPut,
  checkJsonResponse,
};
