
import {
  apiError,
  apiPatch,
  cardPath,
} from '_core/Api2';

import EventBus         from '_utils/EventBus';
import EventManager     from '@brainscape/event-manager';
import Modal            from '_views/shared/Modal';
import PillButton       from '_views/shared/PillButton';
import PropTypes        from 'prop-types';
import React            from 'react';
import SimpleTextButton from '_views/shared/SimpleTextButton';
import SmartCard        from '_views/shared/smart-cards/SmartCard';
import SmartCardList    from '_views/shared/smart-cards/SmartCardList';
import StringHelper     from '_utils/StringHelper';
import TextField        from '_views/shared/TextField';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses:                   PropTypes.string,
  card:                         PropTypes.object,
  currentUser:                  PropTypes.object,
  deck:                         PropTypes.object,
  editMode:                     PropTypes.string,
  hasChanges:                   PropTypes.bool,
  isOpen:                       PropTypes.bool,
  isProcessing:                 PropTypes.bool,
  onCardFieldChange:            PropTypes.func,
  onSubmit:                     PropTypes.func,
  onViewLargeImageRequest:      PropTypes.func,
  pack:                         PropTypes.object,
  packId:                       PropTypes.node,
};


class NewEditCardModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.submitButton = null;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['new-edit-card-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={this.props.onCloseRequest}
      >
        <div className='modal-title'>Edit Card</div>

        <div className="smart-card-list edit-context advanced-edit-mode md-format text-input">
          
          <div className="smart-card-row">
            
            <SmartCard
              card={this.props.card}
              context="edit"
              currentUser={this.props.currentUser}
              deck={this.props.deck}
              editMode={this.props.editMode || 'advanced'}
              format="md"
              isMonitoringOutsideClicks={false}
              layout="list"
              onCardFieldChange={this.props.onCardFieldChange}
              onViewLargeImageRequest={this.props.onViewLargeImageRequest}
              onKeyDown={this.handleKeyDown}
              packId={this.props.packId}
            />
          </div>
        </div>

        <div className='modal-actions'>
          <SimpleTextButton
            addClasses='cancel-modal-text-button'
            label='Cancel'
            onClick={this.props.onCloseRequest}
          />

          <PillButton
            addClasses='resolve-modal-button pill-button-emphasized'
            id="edit-card-submit-button"
            isDisabled={!this.props.hasChanges}
            isProcessing={this.props.isProcessing}
            label='Save'
            onClick={this.props.onSubmit}
            onKeyDown={this.handleKeyDown}
            ref={(elem) => this.submitButton = elem}
            tabIndex="-1"
          />
        </div>

      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleKeyDown = (e) => {
    if (e.keyCode == 9 && e.target.id == 'question') { // tab
      e.preventDefault();
      if (this.answerInput) {
        this.answerInput.focusAndSelect();
      }
    }

    if (e.keyCode == 9 && e.target.id == 'answer') { // tab
      e.preventDefault();
      if (this.submitButton) {
        this.submitButton.focus();
      }
    }

    if (e.keyCode == 9 && e.target.id == 'edit-card-submit-button') { // tab
      e.preventDefault();
      if (this.questionInput) {
        this.questionInput.focusAndSelect();
      }
    }

    if (e.shiftKey && e.keyCode == 9 && e.target.id == 'question') { // shift-tab
      e.preventDefault();
      if (this.submitButton) {
        this.submitButton.focus();
      }
    }

    if (e.shiftKey && e.keyCode == 9 && e.target.id == 'answer') { // shift-tab
      e.preventDefault();
      if (this.questionInput) {
        this.questionInput.focusAndSelect();
      }
    }

    if (e.shiftKey && e.keyCode == 9 && e.target.id == 'edit-card-submit-button') { // shift-tab
      e.preventDefault();
      if (this.answerInput) {
        this.answerInput.focusAndSelect();
      }
    }

    if (e.keyCode == 13 && e.target.id == 'edit-card-submit-button') {
      e.preventDefault();
      this.handleSubmit();
    }
  }

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.props.onSubmit();
  }

}

NewEditCardModal.propTypes = PT;

export default NewEditCardModal;
