
import PropTypes                 from 'prop-types';
import React                     from 'react';

import { toClassStr } from '_utils/UiHelper';

const PT = {};

// eventually this would come from a DB
const notifications = [
  {
    id: 1,
    featureName: 'Security update',
    featureDesc: 'A security patch update is an update that is often pushed from a software developer to all the devices that have the software that needs the update.'
  },
  {
    id: 2,
    featureName: 'Update your password',
    featureDesc: 'If you lose or change computers, it is possible for someone else to gain access to your passwords. Regularly updating your passwords means that even if someone finds an old or saved password, it will no longer be useful, and your data will be secure.'
  },
  {
    id: 3,
    featureName: 'Red Hot Chilli Peppers',
    featureDesc: 'The Red Hot Chilli Peppers have been forced to cancel their show in Glasgow on Friday, due to an unspecified illness.'
  },
]

class AccountNotifications extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const listItems = notifications.map(({id, featureName, featureDesc}) => (
      <li 
        key={id} 
        className='list-item'
        // onClick={() => this.props.updateCurrentScreen({alias})} 
        >
        <div className='list-item-name'>
          {featureName}
        </div>
        <div className='list-item-desc'>
          {featureDesc}
        </div>
        <div className='toggle-button'></div>
      </li>
    ));

    return (
      <div className="account-notifications">
        <ul className='list-items'
        >{listItems}</ul>
      </div>
    );
  }
}

AccountNotifications.propTypes = PT;

export default AccountNotifications;
