import assign     from 'object-assign';
import CBRManager from '_core/CBRManager';
import Model      from '_core/Model';
import PackModel  from '_legacy-models/PackModel';

class StudyMixModel extends Model {
  constructor(props) {
    super(props);

    this.memberName = 'StudySession';
    super.generateNomenclature();
    this.resourceRoute= '/api/study/cbr';

    this.schema = {
      id: {key: 'id', type: 'text', label: 'Id', tag: 'id'},
    }
    this.nameKey = "studySession";

    this.cbrManager;

    this.isFtse = props.isFtse;
    this.Pack   = new PackModel;
  }

  create(opts) {
    const url = this.buildUrl(this.resourceRoute, opts.params);

    return $.ajax({
      method:   'GET',
      url:      url,
      dataType: 'json',
      data:     {classes: opts.classes},
      cache:    false,
    })
    .done((data, status) => {
      data = assign({mixType: opts.mixType, isFtse: this.isFtse}, data);
      this.cbrManager = new CBRManager(data);

      opts.done(data);
    })
    .fail(opts.fail);
  }

  start(opts) {
    let nextStepIds = this.getNextStepIds();
    this.advance(null, nextStepIds, opts);
  }

  rateAndAdvance(opts) {
    let prevStepData = this.getCurrentStepIds(opts.cardId);
    prevStepData.level = opts.level;

    this.cbrManager.cardStudied(opts.cardId, opts.level);
    let nextStepIds = this.getNextStepIds();

    this.advance(prevStepData, nextStepIds, opts);
  }

  advance(updData, dlData, opts) {
    const { done: callback, fail: failCallback, prevLevel, timeSpent, ratedOn } = opts;

    let data = {
      'dl_pack_id': dlData.packId,
      'dl_deck_id': dlData.deckId,
      'dl_card_id': dlData.cardId,
      'prevLevel' : prevLevel,
      'timeSpent' : timeSpent,
      'isWebStudy': true,
      'ratedOn': ratedOn,
    };

    if (updData) {
      data['upd_pack_id'] = updData.packId;
      data['upd_deck_id'] = updData.deckId;
      data['upd_card_id'] = updData.cardId;
      data['upd_level']   = updData.level;
      data['isWebStudy']  = true;
    }

    $.ajax({
      url:      '/api/study/cbr',
      dataType: 'json',
      data:     data,
      method:   'POST',
      cache:    false,
    }).done((data) => {

      // normalize data-casing
      data.card.cardId = data.card.cardID;
      data.card.deckId = data.card.deckID;

      // tack on packId and contentType
      data.card.packId = dlData.packId;
      const deck = this.cbrManager.getDeck(data.card.deckID);
      data.card.contentType = deck.contentType || 'text';

      callback(data);
    }).fail((jqXHR) => {
      if (failCallback) {
        failCallback(jqXHR);
      } else {
        console.log('Something went wrong in StudyMixModel.advance. jqXHR:', jqXHR);
      }
    });
  }

  getCurrentStepIds(cardId) {
    const deckId  = this.cbrManager.getCard(cardId).deckID;
    const packId  = this.cbrManager.getDeck(deckId).packID;

    return {
      packId: packId,
      deckId: deckId,
      cardId: cardId
    }
  }

  getNextStepIds() {
    const nextCardId = this.cbrManager.nextCardID();
    const nextDeckId = this.cbrManager.getCard(nextCardId).deckID;
    const nextPackId = this.cbrManager.decks[nextDeckId].packID;

    return {cardId: nextCardId, deckId: nextDeckId, packId: nextPackId};
  }

  getStepData(cardId) {
    let card = this.cbrManager.getCard(cardId);
    let deck = this.cbrManager.getDeck(card.deckID);
    let pack = this.cbrManager.getPack(deck.packID);

    // normalize data-casing
    card.deckId = card.deckID;
    deck.cardIds = deck.cardIDs;
    deck.deckId = card.deckID;
    deck.packId = deck.packID;

    return {
      card: card,
      deck: deck,
      pack: pack
    }
  }

  getMastery() {
    let mastery = this.cbrManager.mastery();
    return mastery;
  }

  getRatingLevelCounts() {
    return this.cbrManager.confidenceLevelData();
  }

  getTotalCardsStudied() {
    return this.cbrManager.getTotalCardsStudied();
  }

  getMixMetadata(callback) {
    // At present, a mix consists of one Pack when created via app. Name, FullName, etc is simply that of the first Pack's. Eventually, when a mix can be comprised of an array of packs (as in a playlist), this routine should return an array of Pack metadata

    const mixPacks        = this.cbrManager.getPacks();
    const mixDecks        = this.cbrManager.getDecks();
    const mixCards        = this.cbrManager.getCards();
    const firstMixPackId  = Object.keys(mixPacks)[0];

    this.Pack.show({
      id: firstMixPackId,
      done: (firstPackData) => {
        let metadata = {};

        metadata.dashboardPath      = firstPackData.dashboardPath || '/';
        metadata.firstMixPackId     = firstMixPackId;
        metadata.isMixRestricted    = firstPackData.isRestricted || false;
        metadata.isUserMixAdmin     = firstPackData.isUserBscAdmin || false;
        metadata.isUserPro          = firstPackData.isUserPro || false;
        metadata.lastUclRatedAt     = new Date(firstPackData.lastUclRatedAt);
        metadata.libraryPath        = firstPackData.showPath || '/';
        metadata.mixDecks           = mixDecks;
        metadata.mixDescription     = firstPackData.desc || null;
        metadata.mixFullname        = firstPackData.fullName || null;
        metadata.mixIconUrl         = firstPackData.iconUrl || null;
        metadata.mixName            = firstPackData.name || null;
        metadata.userId             = firstPackData.userId || null;

        metadata.timeStudied        = firstPackData.timeStudied;
        metadata.totalConfidence    = firstPackData.totalConfidence;
        metadata.mixCardCount       = Object.keys(mixCards).length;
        metadata.mixDeckCount       = Object.keys(mixDecks).length;
        metadata.mixPackCount       = Object.keys(mixPacks).length;

        if (callback) {
          callback(metadata);
        }
      },
      fail: (message) => {
        console.log ('something went wrong in Pack.show. message:', message);
      }
    });
  }
}

export default StudyMixModel;
