
import {LargeCtaButton}         from '_views/shared/PillButton';
import EventManager             from '@brainscape/event-manager';
import MasteryCircle            from '_views/shared/MasteryCircle';
import React                    from 'react';
import CheckpointOptionsButton  from './CheckpointOptionsButton';
import DynamicTooltipIcon       from '_views/shared/DynamicTooltipIcon';
import EstTimeLeftSnippet       from '_views/shared/EstTimeLeftSnippet';
import EstimatedTimeLeftSnippet from '_views/shared/EstimatedTimeLeftSnippet';
import SimpleTextButton         from '_views/shared/SimpleTextButton';
import UiHelper                 from '_utils/UiHelper';
import NumberHelper             from '_utils/NumberHelper'
import TimeHelper               from '_utils/TimeHelper'

import {apiPut}                 from '_core/Api2';
import {toClassStr}             from '_utils/UiHelper';

class Checkpoint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      areTooltipsEnabled: false,
      isQuitting: false,
      isResuming: false,
    };

    /*
      this.props:
        addClasses,
        buildMasteryDuration,
        checkpointType,           // 'sidebar' or 'fullScreen'
        currentUser,
        isContinuousStudyEnabled,
        isFtse,
        isMixRestricted,
        isMobileViewportSize,
        isUserPro,
        mixCardCount,
        mixCardsStudied,
        mixMastery,
        mixRatingLevelCounts,
        mixRoundCount,
        estimatedTimeLeft,
        onCloseStudyMixRequest,
        onDismissSidebarCheckpoint,
        onNextRoundRequest,
        onToggleContinuousStudy,
        sessionMastery,
        sessionRatingLevelCounts,
        sessionRatings,
        shouldBuildMastery,
        shouldPulseBackToDashboardButton,
        shouldPulseNextRoundButton,
        stepCard,
        timeStudied,
    */

    this._isMounted = false;

    this.enableTooltipsDelay = null;

    this.ENABLE_TOOLTIPS_DELAY = 2000; // Suppress any Tooltip display until Checkpoint is fully displayed
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    this.clearTimeoutsAndIntervals();
    this.startKeyboardMonitor();

    this.enableTooltips();
  }

  componentWillUnmount() {
    this.stopKeyboardMonitor();
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['checkpoint', this.props.addClasses]);
    const isMobileViewportSize = this.props.isMobileViewportSize;

    return (
      <div className={classes}>
        <h2 className="checkpoint-title">Checkpoint!</h2>

        <div className="study-mix-metadata">
          <div className="mastery-circles">
            {this.renderCheckpointMasteryCircle()}
          </div>
        </div>

        {this.renderTimeToCompleteMix()}
        {this.renderActionButtons()}
      </div>
    );
  }

  renderCheckpointHelpDynamicTooltipIcon() {
    return (
      <DynamicTooltipIcon
        addClasses="info-icon"
        body={this.renderMasteryDynamicTooltipIconBody()}
        delay={500}
        hasDismissButton={false}
        heading="The Mastery Circle"
        iconType="info"
        position="top"
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    )
  }

  renderCheckpointMasteryCircle() {
    const areTooltipsDisabledClass = !this.state.areTooltipsEnabled
      ? 'is-disabled'
      : '';
    const classes = toClassStr([
      'checkpoint-mastery-tooltip-wrapper',
      areTooltipsDisabledClass,
    ]);
    const isFullScreen = this.props.checkpointType == 'fullScreen';
    const isMobileViewportSize = this.props.isMobileViewportSize;
    const tooltipPlace = this.props.isMobileViewportSize ? 'bottom' : 'top';

    return (
      <MasteryCircle
        addClasses="mix-mastery"
        background="dark"
        animationDuration={this.props.buildMasteryDuration}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isSpectrum={true}
        key="checkpointMastery"
        mastery={this.props.mixMastery}
        ratingLevelCounts={this.props.mixRatingLevelCounts}
        shouldAnimate={true}
        shouldCountUpMastery={false}
        shouldShowMasteryValue={true}
        shouldShowMasteryValueLabel={true}
        tooltipPosition="right"
      />
    );
  }

  renderCheckpointMasteryTooltip() {
    const mixMastery = NumberHelper.percentageStr(this.props.mixMastery);

    return (
      <div className="rich-content-tooltip">
        <p className="body-text">
          Across all of your studies in Brainscape, you have studied{' '}
          {this.props.mixCardsStudied} different cards of this Study Mix.
        </p>
        <p className="body-text">
          The arc of this graph shows your overall mastery of {mixMastery}% for
          the Study Mix.
        </p>
        <p className="body-text">
          The colors of the arc reflect how you rated the cards.
        </p>
      </div>
    );
  }

  renderMasteryDynamicTooltipIconBody() {
    return (
      <>
        <p className="body-text">Your % Mastery corresponds to an average flashcards confidence rating. Rating all cards a 5 (blue) would bring you to 100.</p>
      </>
    );
  }

  renderTimeToCompleteMix() {
    const timeToCompleteMix = TimeHelper.msToNarrativeTime(this.props.estimatedTimeLeft);
    const mixCardCount = NumberHelper.displayNumber(this.props.mixCardCount);
    const areTooltipsDisabledClass = !this.state.areTooltipsEnabled
      ? 'is-disabled'
      : '';
    const classes = toClassStr([
      'time-left-tooltip-wrapper',
      areTooltipsDisabledClass,
    ]);
    const isFullScreen = this.props.checkpointType == 'fullScreen';
    const isMobileViewportSize = this.props.isMobileViewportSize;
    const tooltipPlace = this.props.isMobileViewportSize ? 'bottom' : 'top';

    if (this.props.mixMastery >= 1.0) {
      return (
        <div className="time-left">
          <div className="fullscreen-checkpoint">
            Congratulations! You've reached 100% Mastery!
          </div>
          <div className="sidebar-checkpoint">Congratulations!</div>
        </div>
      );
    }

    if (this.props.estimatedTimeLeft < 0) {
      return (
        <div className="time-left">
          <div className="fullscreen-checkpoint">
            Keep Going! You'll be learning faster before long!
          </div>
          <div className="sidebar-checkpoint">Keep Going!</div>
        </div>
      );
    }

    return (
      <>
        <EstimatedTimeLeftSnippet
          background="dark"
          deckCount={null}
          estimatedTimeLeft={this.props.estimatedTimeLeft}
          isMobileViewportSize={this.props.isMobileViewportSize}
          orientation="vertical"
          selectedDeckCount={this.props.mixDeckCount}
          selectedDecksMastery={this.props.mixMastery}
          tooltipPosition="right"
          timeStudied={this.props.timeStudied}
        />
      </>
    );
  }

  renderActionButtons() {
    return (
      <div className="action-buttons">
        {this.renderBackToDashboardButton()}
        {this.renderNextRoundButton()}
        {this.renderStudyMixOptionsButton()}
        {this.renderKeepGoingMessage()}
      </div>
    );
  }

  renderBackToDashboardButton() {
    if (this.props.checkpointType == 'sidebar') {
      return null;
    }

    const label = 'Dashboard';
    const isFtseClass = this.props.isFtse ? 'is-ftse' : '';
    const pulseClass = this.props.shouldPulseBackToDashboardButton
      ? 'pulse'
      : '';
    const classes = toClassStr([
      'back-to-dashboard-button',
      isFtseClass,
      pulseClass,
    ]);

    return (
      <LargeCtaButton
        addClasses={classes}
        label={label}
        onClick={this.handleBackToDashboardButtonClick}
      />
    );
  }

  renderNextRoundButton() {
    if (this.props.checkpointType == 'sidebar') {
      return null;
    }

    const pulseClass = this.props.shouldPulseNextRoundButton ? 'pulse' : '';
    const classes = toClassStr(['start-new-round', pulseClass]);

    return (
      <LargeCtaButton
        addClasses={classes}
        isProcessing={this.state.isResuming}
        label="10 more Cards"
        onClick={this.handleStartNewRoundClick}
        title="Hit [Space Bar] to resume studying"
      />
    );
  }

  renderStudyMixOptionsButton() {
    if (this.props.checkpointType == 'sidebar') {
      return null;
    }

    return (
      <CheckpointOptionsButton
        isContinuousStudyEnabled={this.props.isContinuousStudyEnabled}
        isMixRestricted={this.props.isMixRestricted}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isUserPro={this.props.isUserPro}
      />
    );
  }

  renderBackToLibraryButton() {
    if (this.props.checkpointType == 'sidebar') {
      return null;
    }

    return (
      <div
        className="back-to-library-link"
        onClick={() => this.handleBackToLibraryClick()}
      >
        Back to My Classes
      </div>
    );
  }

  renderKeepGoingMessage() {
    if (this.props.checkpointType == 'fullScreen') {
      return null;
    }

    return (
      <div className="keep-going-message">
        Keep Studying. Checkpoint will disappear when you flip or rate a card.
      </div>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBackToDashboardButtonClick = () => {
    this.setState(
      {
        isQuitting: true,
      }, () => {
        this.triggerCloseStudySessionRequest();
      },
    );
  };

  handleBackToLibraryClick = () => {
    this.setState(
      {
        isQuitting: true,
      },
      () => {
        this.props.onCloseStudyMixRequest();
      },
    );
  };

  handleContinueStudyingButtonClick = () => {
    this.handleStartNewRoundClick();
  };

  handleDismissSidebarCheckpointClick = () => {
    this.props.onDismissSidebarCheckpoint();
  };

  handleGetMobileAppButtonClick = () => {
    const mobileAppUrl = this.props.isMobileViewportSize
      ? '/get-brainscape'
      : '/get-mobile-app';
    UiHelper.openInNewTab(mobileAppUrl);
  };

  handleGoToDashboardButtonClick = () => {
    this.handleBackToLibraryClick();
  };

  handleKeydown = (e) => {
    e.stopPropagation(); // prevents bubble phase handling altogether (see comment on startKeyboardMonitor)
    const keyCode = e.keyCode;

    switch (keyCode) {

      case 27: // escape
        e.preventDefault();
        this.handleStartNewRoundClick();
      break;

      case 32: // space bar
        e.preventDefault();
        this.handleStartNewRoundClick();

        if (this.props.checkpointType == 'sidebar') {
          this.triggerRevealCardFaceRequest();
        }
      break;
    }
  }

  handleStartNewRoundClick = () => {
    this.setState({  
      isResuming: true,
    }, () => {
        this.triggerStartNewRoundRequest();
    });
  };


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCloseStudySessionRequest() {
    EventManager.emitEvent('study-session:close-requested', {
      endFtse: true,
    });
  }

  triggerRevealCardFaceRequest() {
    EventManager.emitEvent('smart-card:reveal-card-face-requested', {
      face: 'answer',
    });
  }

  triggerStartNewRoundRequest() {
    EventManager.emitEvent('study-session:new-round-requested', {});
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  enableTooltips() {
    clearTimeout(this.enableTooltipsDelay);
    this.enableTooltipsDelay = setTimeout(() => {
      this.setState({
        areTooltipsEnabled: true,
      });
    }, this.ENABLE_TOOLTIPS_DELAY);
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.enableTooltipsDelay);
  }

  startKeyboardMonitor() {
    // NOTE: true flag at end of call designates handler to fire during capture phase, thus firing before standard bubble phase handlers
    document.addEventListener('keydown', this.handleKeydown, true);
  }

  stopKeyboardMonitor() {
    document.removeEventListener('keydown', this.handleKeydown, true);
  }
}

export default Checkpoint;
