
import EditOptionsButton  from '_views/shared/EditOptionsButton';
import EventManager       from '@brainscape/event-manager';
import OptionsButton      from '_views/shared/OptionsButton';
import React              from 'react';

import {toClassStr} from '_utils/UiHelper';

class OptionsMenuButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isListeningToOutsideClicks: false,
      isOpen: this.props.initialOpenState || false,
      isTransitionedIn: false,
      isTransitionedOut: false,
    };

    /* props:
      addButtonClasses,
      addClasses,
      addMenuClasses,
      haslegacyStyles,
      iconImageUrl,
      iconType,
      initialOpenState,
      isDisabled,
      isUserPro,
      menuOptions {id, tag, label, onlyPro, onlyAdmin, onlyEditor},
      onOptionClick,
      openPosition,  // "bottomRight" (default) or "bottonLeft"
      tooltipContent,
      tooltipPosition,
      shouldButtonTurn,
      title,
    */

    this.events = new EventManager();

    this.transitionInDelay = null;
    this.transitionOutDuration = null;

    this.TRANSITION_IN_DELAY = 100;
    this.TRANSITION_OUT_DURATION = 800;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    this.events.addListeners([
      ['dropdown:button-clicked',         this.handleDropdownButtonClicked],
      ['dropdown:close-all-requested',    this.handleDropdownCloseAllRequested],
    ]);
  }

  componentDidUpdate() {
    if (this.state.isOpen && !this.state.isListeningToOutsideClicks) {
      document.addEventListener('click', (e) => this.handleOutsideClick(e));
      if (this._isMounted) {
        this.setState({
          isListeningToOutsideClicks: true
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.clearTimeoutsAndIntervals();
    document.removeEventListener('click', (e) => this.handleOutsideClick(e));
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (this.props.iconType == 'edit-options') {
      return this.renderEditOptionsButton();
    }

    const isDisabledClass = this.props.isDisabled ? 'is-disabled' : '';
    const openPositionClass = (this.props.openPosition == 'bottomLeft') ? 'bottom-left' : 'bottom-right';
    const classes = toClassStr(['options-menu-button', isDisabledClass, openPositionClass, this.state.isOpen ? 'is-open' : '', this.props.isProcessing ? 'is-processing' : '', this.props.addClasses]);

    return (
      <div className={classes} ref={(elem) => { this.elem = elem }}>
        <OptionsButton
          iconImageUrl={this.props.iconImageUrl}
          iconType={this.props.iconType}
          isMenuClosing={this.state.isTransitionedOut}
          isMenuOpening={this.state.isOpen}
          isProcessing={this.props.isProcessing}
          onClick={this.handleOptionsButtonClick}
          shouldButtonTurn={this.props.shouldButtonTurn}
          title={this.props.title}
          tooltipContent={this.props.tooltipContent} 
          tooltipPosition={this.props.tooltipPosition}
        />

        {this.renderOptionsMenu()}
      </div>

    );
  }

  renderEditOptionsButton() {
    const isDisabledClass = this.props.isDisabled ? 'is-disabled' : '';
    const openPositionClass = (this.props.openPosition == 'bottomLeft') ? 'bottom-left' : 'bottom-right';
    const classes = toClassStr(['options-menu-button', isDisabledClass, openPositionClass, this.state.isOpen ? 'is-open' : '', this.props.isProcessing ? 'is-processing' : '', this.props.addClasses]);

    return (
      <div className={classes} ref={(elem) => { this.elem = elem }}>
        <EditOptionsButton 
          addClasses={this.props.addClasses}
          // isDisabled={this.props.isDisabled}
          isProcessing={this.props.isProcessing}
          onClick={this.handleOptionsButtonClick}
          title={this.props.title}
          tooltipContent={this.props.tooltipContent} 
          tooltipPosition={this.props.tooltipPosition}
        />

        {this.renderOptionsMenu()}
      </div>
    );
  }

  renderOptionsMenu() {
    if (!this.state.isOpen || this.props.isDisabled) {
      return null;
    }

    const transitioningInClass = this.state.isTransitioningIn ? 'is-transitioning-in' : '';
    const transitioningOutClass = this.state.isTransitioningOut ? 'is-transitioning-out' : '';
    const transitionedInClass = this.state.isTransitionedIn ? 'is-transitioned-in' : '';
    const transitionedOutClass = this.state.isTransitionedOut ? 'is-transitioned-out' : '';

    let menuClasses = toClassStr(['options-menu', transitioningInClass, transitioningOutClass, transitionedInClass, transitionedOutClass, this.props.addMenuClasses]);

    let menuOptions = this.props.menuOptions.map((menuOption, index) => {
      return this.renderMenuOption(menuOption, index);
    });

    return (
      <ul className={menuClasses}>
        {menuOptions}
      </ul>
    );
  }

  renderMenuOption(menuOption, index) {
    const isDisabledClass = menuOption.isDisabled ? 'is-disabled' : '';
    const proRequiredClass = (!this.props.isUserPro && menuOption.onlyPro) ? 'pro-required' : '';

    const optionClasses = toClassStr(['menu-option', menuOption.tag, proRequiredClass, isDisabledClass]);
    const persistOnClickFlag = menuOption.persistOnClick ? menuOption.persistOnClick : false;

    if (!menuOption.label) {
      // TODO: Better styling.
      return (
        <li
          key={index}
          style={{borderBottom: '1px solid rgba(0, 0, 0, 0.15)'}}
        />
      );
    }

    return (
      <li key={index} className={optionClasses} id={menuOption.id} onClick={(e) => this.handleMenuOptionClick(e, menuOption, persistOnClickFlag)}>
        {menuOption.label}
      </li>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleDropdownButtonClicked = (data) => {
    // this handler is fired whenever *any* pulldown button or options menu button in the app is clicked

    if (!this._isMounted) {
      return false;
    }

    if (this.elem && this.elem.contains(data.eTarget)) {
      // this handler is detecting someone clicking this pulldown instance
      return false;
    }

    // event should be processed like an outside click
    this.closeMenu();
  }

  handleDropdownCloseAllRequested = (data) => {
    if (!this._isMounted) {
      return false;
    }

    // event should be processed like an outside click
    this.closeMenu();
  }

  handleOptionsButtonClick = () => {
    if (this.props.isDisabled) {
      return false;
    }

    this.publishDropdownButtonClick(this.elem);

    if (this.state.isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  handleOutsideClick = (e) => {
    if (e && !e.isPseudoEventObject) {
      e.stopPropagation();
    }

    if (this.props.isDisabled) {
      return false;
    }

    if (this.elem && this.elem.contains(e.target)) {
      return false;
    }

    this.closeMenu();
  }



  handleMenuOptionClick(e, menuOption, persistOnClickFlag) {
    e.stopPropagation();

    if (menuOption.isDisabled) {
      this.closeMenu();
      return false;
    }

    this.props.onOptionClick(menuOption.id);

    // close menu unless clicked option includes persistOnClick flag (set in menuOptionsHash in parent component )
    if (!persistOnClickFlag) {
      this.closeMenu();
    }
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishDropdownButtonClick = (eTarget) => {
    EventManager.emitEvent('dropdown:button-clicked', {eTarget: eTarget});
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  openMenu() {
    if (this._isMounted) {
      this.setState({
        isOpen: true,
        isTransitionedIn: false,
        isTransitionedOut: false,
      }, () => {
        this.invokeTransitionIn();
      });
    }
  }

  closeMenu() {
    if (this._isMounted) {
      this.setState({
        isTransitionedIn: false,
        isTransitionedOut: true,
      }, () => {
        this.completeTransitionOut();
      });
    }
  }

  invokeTransitionIn() {
    clearTimeout(this.transitionInDelay);

    this.transitionInDelay = setTimeout(() => {
      this.setState({
        isTransitionedIn: true,
        isTransitionedOut: false,
      });
    }, this.TRANSITION_IN_DELAY);
  }

  completeTransitionOut() {
    clearTimeout(this.transitionOutDuration);

    this.transitionOutDuration = setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, this.TRANSITION_OUT_DURATION);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.transitionInDelay);
    clearTimeout(this.transitionOutDuration);
  }
}

export default OptionsMenuButton;
