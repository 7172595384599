
import CardFaceHeader     from "_views/shared/smart-cards/animation/CardFaceHeader";
import CardFaceView       from "_views/shared/smart-cards/animation/CardFaceView";
import CardSlider         from "_views/shared/smart-cards/animation/CardSlider";
import CardTransition     from "_views/shared/smart-cards/animation/CardTransition";
import PropTypes          from "prop-types";
import React              from "react";
import useDimensions      from "react-cool-dimensions";

import {toClassStr} from "_utils/UiHelper";


const CardSet = ({ card, deck, pack, fontSizeCalc, revealed, revealMode, style, confidenceLevel, context, currentUser, cardMode, format, baseFontSize, onClick, onViewLargeImageRequest, isAtCheckpoint, isAudioMuted, isLoadingCardDisplayData, viewportStyle, skipDirection, cardCount, cardPosition, packId}) => {
  const [ptRef, ptH, ptW] = useHeightWidth();

  if (!card) return null;

  const hd = <CardFaceHeader card={card} revealed={revealed} />;

  const hi = <CardFaceView
    card={card} 
    cardCount={cardCount} 
    cardMode={cardMode} 
    cardPosition={cardPosition} 
    confidenceLevel={confidenceLevel}
    context={context}
    currentUser={currentUser}
    deck={deck}
    face="question"
    format={format}
    isAtCheckpoint={isAtCheckpoint}
    isAudioMuted={isAudioMuted}
    isLoadingCardDisplayData={isLoadingCardDisplayData}
    key="question-face"
    layout="single-card"
    mode="q" 
    onViewLargeImageRequest={onViewLargeImageRequest}
    pack={pack}
    packId={packId}
    revealed={revealed}
    revealMode={revealMode}
    viewportStyle={viewportStyle}
  />;

  const lo = <CardFaceView 
    card={card} 
    cardCount={cardCount} 
    cardMode={cardMode} 
    cardPosition={cardPosition} 
    confidenceLevel={confidenceLevel}
    context={context}
    currentUser={currentUser}
    deck={deck}
    face="answer"
    format={format}
    isAtCheckpoint={isAtCheckpoint}
    isAudioMuted={isAudioMuted}
    isLoadingCardDisplayData={isLoadingCardDisplayData}
    key="answer-face"
    layout="single-card"
    mode="a" 
    onViewLargeImageRequest={onViewLargeImageRequest}
    pack={pack}
    packId={packId}
    revealed={revealed}
    revealMode={revealMode}
    viewportStyle={viewportStyle}
  />;

  const isRevealedClass = (revealed) ? 'is-revealed' : '';
  const viewportStyleClass = `${viewportStyle}-viewport-style`;
  const skipDirectionClass = `skip-${skipDirection}`;
  const classes = toClassStr([`card-set smart-card ${context}-context single-card-layout md-format`, isRevealedClass, viewportStyleClass, skipDirectionClass]);

  return (
    <div className={classes} ref={ptRef} style={style} onClick={onClick}>
      <CardTransition style={styles.transition}>
        <CardSlider
          baseFontSize={baseFontSize}
          header={hd}
          hi={hi}
          key={`cs${card.cardId}`}
          lo={lo}
          ptH={ptH}
          ptW={ptW}
          revealed={revealed}
          revealMode={revealMode}
          fontSizeCalc={fontSizeCalc}
        />
      </CardTransition>
    </div>
  );
};

CardSet.propTypes = {
  card: PropTypes.object.isRequired,
  fontSizeCalc: PropTypes.string,
  revealed: PropTypes.bool,
  revealMode: PropTypes.object.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const useHeightWidth = () => {
  const { observe, width, height } = useDimensions();
  return [observe, height, width];
};

const styles = {
  transition: {
    inset: 0,
    position: "absolute",
  },
};

export { CardSet as default };
