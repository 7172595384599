
import EventManager           from '@brainscape/event-manager';
import trendingPack           from '_models/trendingPack';


const trendingPacksConcern = {
  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch() {
    return trendingPack.index()
      .then(packsData => {
        const topTenTrendingPacks = packsData.slice(0, 10);
        const trendingPacksData = {
          trendingPacks: topTenTrendingPacks,
        };

        this.publish(trendingPacksData);
        return trendingPacksData;
      })
      .catch(err => {
        console.log('Problem fetching trending pack data. err:', err);
        return Promise.reject(err);
      });
  },

  publish(trendingPacksData) {
    EventManager.emitEvent('trending-packs:retrieved', trendingPacksData);
  },
};

export default trendingPacksConcern;
