
import model from '_core/newModel';

const PATH = 'me/identities';

const KEY_MAP = {}

const userIdentity = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  show() {
    return model.show(PATH);
  },

  destroy(userId, provider) {

    const data = {
      provider: provider,
      userId: userId,
    };

    return model.destroy(PATH, data).then(res => {
      model.publish('user-identity:destroyed', {identities: res});
      // return res;
    }).catch(err => {
      console.error(err);
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default userIdentity;
