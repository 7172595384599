import React from "react";
import PropTypes from "prop-types";

const CardFaceHeader = React.forwardRef((_props, ref) => (
  <div ref={ref} className="card-face-header" />
));
CardFaceHeader.propTypes = {
  card: PropTypes.object.isRequired,
  revealed: PropTypes.bool,
};

export { CardFaceHeader as default };
