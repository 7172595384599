
import { BackButton }           from '_views/shared/IconButton';
import BruceButton              from '_views/shared/BruceButton';
import Checkpoint               from './Checkpoint';
import CheckpointOptionsButton  from './CheckpointOptionsButton';
import EventManager             from '@brainscape/event-manager';
import OverallStatsSection      from './OverallStatsSection';
import PackIcon                 from '_views/shared/PackIcon';
import StudyMixOptionsButton    from './StudyMixOptionsButton';
import TabSwitcher              from '_views/shared/TabSwitcher';
import ThisRoundStatsSection    from './ThisRoundStatsSection';
import TransitionWrapper        from '_views/shared/TransitionWrapper';
import UiHelper                 from '_utils/UiHelper';

import userLocalStore           from '_models/userLocalStore';



import PropTypes              from 'prop-types';
import React                  from 'react';

import {toClassStr}           from '_utils/UiHelper';


const PT = {
  addClasses:                 PropTypes.string,
  currentCard:                PropTypes.object,
  currentCardId:              PropTypes.node,
  currentCardLevel:           PropTypes.number,
  currentDeck:                PropTypes.object,
  currentDeckId:              PropTypes.node,
  currentPack:                PropTypes.object,
  currentPackId:              PropTypes.node,
  currentRoundStats:          PropTypes.object,
  currentSessionStats:        PropTypes.object,
  currentStepIndex:           PropTypes.number,
  currentUser:                PropTypes.object,
  currentUserId:              PropTypes.node,
  isAtCheckpoint:             PropTypes.bool,
  isAudioMuted:               PropTypes.bool,
  isContinuousStudy:          PropTypes.bool,
  isFtse:                     PropTypes.bool,
  isFtue:                     PropTypes.bool,
  isMobileViewportSize:       PropTypes.bool,
  roundLength:                PropTypes.number,
};

const STUDY_SIDEBAR_TABS = {
  thisRound: { id: 'thisRound', label: 'This Round' },
  overall: { id: 'overall', label: 'Overall' },
};

const FALLBACK_PACK_ICON_URL = '/assets/app_icons/ugc-white-bg.svg';


class StudySidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTabId: null,
      isCurrentTabLoading: false,
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    if (this.props.currentUser.userId != null) {
      this.setCurrentTabFromPrefs();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const isContinuousStudy = this.props.checkpointType == 'sidebar' ? 'continuous-study-enabled' : '';
    const classes = toClassStr(['study-sidebar', this.props.addClasses, isContinuousStudy]);

    return (
      <div className={classes}>
      <header className="study-sidebar-header">
          <div className="first-row">
            <BruceButton 
              backArrow={true}
              onClick={(e) => this.handleBackButtonClick(e)}
              tooltipContent="Back to dashboard"
              tooltipPosition="right"
            />

            <div className="study-mix-info"
              onMouseEnter={this.handleStudyMixInfoMouseEnter}
              onMouseLeave={this.handleStudyMixInfoMouseLeave}
            >
              <a className="study-mix-info-link" href={`/l/dashboard/packs/${this.props.currentPack.packId}`}> 
                <PackIcon
                  addClasses="study-mix-icon"
                  backgroundType="dark"
                  isEditable={false}
                  pack={this.props.currentPack}
                />

                <h3 className="study-mix-name">
                  {this.props.currentPack.name}
                </h3>
              </a>
            </div>

            {this.renderStudyMixOptionsButton('right')}
          </div>

          <div className="second-row">
            <TabSwitcher
              addClasses="is-dark"
              currentTabId={this.state.currentTabId}
              isCurrentTabLoading={this.state.isCurrentTabLoading}
              onTabClick={(tabId) => this.handleTabClick(tabId)}
              tabs={STUDY_SIDEBAR_TABS}
            />
          </div>
        </header>

        <div className="mobile-study-sidebar-header">
          <div className="mobile-back-button">
            <BackButton onClick={(e) => this.handleBackButtonClick(e)} />
          </div>
        </div>

        <div className="sidebar-sections">
          {this.renderThisRoundStatsSection()}
          {this.renderOverallStatsSection()}
          {this.renderCheckpoint()}
        </div>

        <div className="mobile-study-sidebar-footer">
          {this.renderMobileTopbarOptionsButton()}
        </div>
      </div>
    );
  }

  renderStudyMixOptionsButton(tooltipPosition='right') {
    return (
      <StudyMixOptionsButton
        currentCard={this.props.currentCard}
        currentDeck={this.props.currentDeck}
        currentPack={this.props.currentPack}
        currentUser={this.props.currentUser}
        deckCount={this.props.currentSessionStats?.deckCount}
        isAtCheckpoint={this.props.isAtCheckpoint}
        isContinuousStudy={this.props.checkpointType == 'sidebar'}
        isDisabled={(this.props.isAtCheckpoint && this.props.checkpointType == 'fullScreen')}
        isMobileViewportSize={this.props.isMobileViewportSize}
        mixType={this.props.currentPack.mixType}
        onChangeProgressView={(viewId) => this.handleChangeProgressView(viewId)}
        progressView={this.state.currentTabId}
        tooltipContent="Study settings, log out"
        tooltipPosition={tooltipPosition}
      />
    );
  }

  renderThisRoundStatsSection() {
    if (this.state.currentTabId != 'thisRound') {
      return null;
    }

    if (this.props.isMobileViewportSize && this.props.isAtCheckpoint) {
      return null;
    }

    const roundStats = this.props.currentRoundStats;

    return (
      <ThisRoundStatsSection
        isAtCheckpoint={this.props.isAtCheckpoint}
        isMobileViewportSize={this.props.isMobileViewportSize}
        mixRoundCount={roundStats.roundNumber}
        roundConfidenceGained={roundStats.confidenceGained}
        roundElapsedTime={roundStats.roundDisplayTime}
        roundEndConfidenceGained={roundStats.confidenceGained}
        roundStepCount={roundStats.roundLength}
        roundStepIndex={roundStats.cardNumber - 1}
        roundStepRatings={roundStats.cardRatings}
        shouldCompressRoundEndConfidence={false}
        shouldDimRoundEndConfidence={false}
        shouldShowRoundEndConfidence={false}
      />
    );
  }

  renderOverallStatsSection() {
    if (this.state.currentTabId != 'overall') {
      return null;
    }

    if (this.props.isMobileViewportSize && this.props.isAtCheckpoint) {
      return null;
    }

    const roundStats = this.props.currentRoundStats;
    const sessionStats = this.props.currentSessionStats;

    return (
      <OverallStatsSection
        isAtCheckpoint={this.props.isAtCheckpoint}
        isMobileViewportSize={this.props.isMobileViewportSize}
        mixCardCount={roundStats.cardCount}
        mixMastery={sessionStats.masteryRatio}
        mixRatingLevelCounts={roundStats.levelCounts}
        roundStepCount={roundStats.roundLength}
        roundStepIndex={roundStats.cardNumber - 1}
      />
    );
  }

  renderCheckpoint() {
    if (!(this.props.checkpointType == 'sidebar' && this.props.isAtCheckpoint)) {
      return null;
    }

    const roundStats = this.props.currentRoundStats;
    const sessionStats = this.props.currentSessionStats;

    return (
      <TransitionWrapper
        addClasses="checkpoint-transition-wrapper"
        shouldTransitionIn={true}
        shouldTransitionOut={this.props.shouldCloseCheckpoint}
        transitionInDelay={100}
        transitionInDuration={500}
        transitionOutDuration={500}
      >

        <Checkpoint
          addClasses="sidebar-checkpoint"
          buildMasteryDuration={250}
          checkpointType={this.props.checkpointType}
          currentUser={this.props.currentUser}
          estimatedTimeLeft={this.props.estimatedTimeLeft}
          isMixRestricted={this.props.isMixRestricted}
          isMobileViewportSize={this.props.isMobileViewportSize}
          isProcessingEstimatedTimeLeft={this.props.estimatedTimeLeft}
          isUserPro={this.props.currentUser.flags.isPro}
          mixCardCount={roundStats.cardCount}
          mixCardsStudied={roundStats.cardRated}
          mixDeckCount={sessionStats.deckCount}
          mixMastery={sessionStats.masteryRatio}
          mixRatingLevelCounts={roundStats.levelCounts}
          mixRoundCount={sessionStats.roundsCompleted}
          roundElapsedTime={roundStats.roundElapsedTime}
          timeStudied={this.props.totalTimeStudied}
        />
      </TransitionWrapper>
    );
  }

  renderMobileTopbarOptionsButton() {
    if (this.props.isAtCheckpoint) {
      return this.renderCheckpointOptionsButton();
    }

    return this.renderStudyMixOptionsButton('left');
  }

  renderCheckpointOptionsButton() {
    const isContinuousStudyEnabled = (this.props.checkpointType == 'sidebar');

    return (
      <CheckpointOptionsButton
        isContinuousStudyEnabled={isContinuousStudyEnabled}
        isMixRestricted={this.props.isMixRestricted}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isUserPro={this.props.isUserPro}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBackButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }

    this.triggerCloseStudySessionRequest();
  }

  handleChangeProgressView(viewId) {
    this.handleTabClick(viewId);
  }

  handleStudyMixInfoMouseEnter = (e) => {
    e.stopPropagation();

    const tooltipElem = e.target;

    this.triggerTooltipOpen({
      content: `View ${this.props.currentPack.name} in Dashboard`,
      elem: tooltipElem,
      position: 'bottom',
    });
  };

  handleStudyMixInfoMouseLeave = () => {
    this.triggerTooltipClose();
  };

  handleTabClick(tabId) {
    this.setState({
      currentTabId: tabId,
    }, () => {
      this.updateStudySidebarTabPref(tabId);

      if (this.props.isAtCheckpoint) {
        this.triggerDismissCheckpointRequest();
      }
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCloseStudySessionRequest = () => {
    EventManager.emitEvent('study-session:close-requested', {});
  };

  triggerDismissCheckpointRequest = () => {
    EventManager.emitEvent('study-session:dismiss-checkpoint-requested', {});
  }

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updateStudySidebarTabPref(tabId) {
    userLocalStore.updateUserLocalPrefs(
      this.props.userId,
      'studySidebarTabPref',
      tabId,
    );
  }

  getStudySidebarTabPref() {
    return userLocalStore.getUserLocalPref(
      this.props.userId,
      'studySidebarTabPref',
    );
  }

  setCurrentTabFromPrefs() {
    this.setState({
      currentTabId: this.getStudySidebarTabPref() || 'thisRound',
    });
  }
}

StudySidebar.propTypes = PT;

export default StudySidebar;
