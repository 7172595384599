
import IconButton         from '_views/shared/IconButton';
import React              from 'react';
import Spinner            from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

class OptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /* 
      this.props: 
        addClasses,
        buttonClass,
        iconImageUrl,
        iconType,
        isMenuClosing,
        isMenuOpening,
        isProcessing,
        onClick,
        shouldAllowClickPropagation,
        shouldButtonTurn,
        title,
        tooltipContent,
        tooltipPosition,
    */

    this._isMounted = false;
  };


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (this.props.iconType == 'image') {
      return this.renderImageButton();
    }
    
    const buttonClass = this.props.iconType || 'gear'; 
    const isMenuOpeningClass = (this.props.shouldButtonTurn && this.props.isMenuOpening) ? 'is-menu-opening' : '';
    const isMenuClosingClass = (this.props.shouldButtonTurn && this.props.isMenuClosing) ? 'is-menu-closing' : '';
    let classes = toClassStr([buttonClass, isMenuOpeningClass, isMenuClosingClass, this.props.addClasses]);

    return (
      <IconButton
        addClasses={classes}
        buttonClass="options-button"
        iconClass={this.getIconClass()}
        isProcessing={this.props.isProcessing}
        onClick={() => this.props.onClick()}
        shouldAllowClickPropagation={this.props.shouldAllowClickPropagation}
        title={this.props.title}
        tooltipContent={this.props.tooltipContent} 
        tooltipPosition={this.props.tooltipPosition}
      />
    );
  }

  renderImageButton() {
    if (this.props.isProcessing) {
      return this.renderSpinner();
    }

    let classes = toClassStr(['options-button image', this.props.addClasses]);

    return (
      <img 
        className={classes} 
        onClick={(e) => this.handleImageButtonClick(e)}
        src={this.props.iconImageUrl}
        title={this.props.title}
      />
    );
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleImageButtonClick(e) {
    if (e && !this.props.shouldAllowClickPropagation) {
      e.stopPropagation();
    }

    this.props.onClick();
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getIconClass() {
    switch (this.props.iconType) {
      case 'horizontal':
        return 'ion-android-more-horizontal';
      break;
      case 'gear':
        return 'ion-ios-gear';
      break;
      case 'vertical':
        return 'ion-android-more-vertical';
      break;
      default:
        return 'ion-ios-gear';
    }
  }
}

export default OptionsButton;
