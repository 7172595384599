
// substrate and utils
import EventManager             from '@brainscape/event-manager';
import GaHelper                 from '_utils/GaHelper';
import NumberHelper             from '_utils/NumberHelper';
import PropTypes                from 'prop-types';
import React                    from 'react';
import StudyHelper              from '_utils/StudyHelper';
import UiHelper                 from '_utils/UiHelper';
import { toClassStr }           from '_utils/UiHelper';

// models
import userLocalStore           from '_models/userLocalStore';

// sub-components
import PackAboutSection         from '_views/pack-detail/desktop/about/PackAboutSection';
import PackDecksSection         from '_views/pack-detail/desktop/decks/PackDecksSection';
import PackDetailHeader         from '_views/pack-detail/desktop/PackDetailHeader';
import PackLeaderboardSection   from '_views/pack-detail/desktop/learners/PackLeaderboardSection';
import PackLearnersSection      from '_views/pack-detail/desktop/learners/PackLearnersSection';

const PT = {
  addClasses:                   PropTypes.string,
  currentDeckId:                PropTypes.node,
  currentPack:                  PropTypes.object,
  currentTabId:                 PropTypes.string,
  currentUser:                  PropTypes.object,
  estimatedTimeLeft:            PropTypes.number,
  isLoadingPack:                PropTypes.bool,
  isLoadingPackDecks:           PropTypes.bool,
  isLoadingPackDetail:          PropTypes.bool,
  isLoadingPackLearners:        PropTypes.bool,
  isLoadingPackMetadata:        PropTypes.bool,
  isLoadingTrendingPacks:       PropTypes.bool,
  isLoadingUserInfo:            PropTypes.bool,
  isMobileViewportSize:         PropTypes.bool,
  isShowingCachedPackLearners:  PropTypes.bool,
  shouldPulseAddCardsButton:    PropTypes.bool,
  shouldPulseCreateDeckButton:  PropTypes.bool,
  shouldPulseStudyButton:       PropTypes.bool,
  totalTimeStudied:             PropTypes.number,
};


class PackDetailBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      packSelectedState: this.getPackSelectedState(),
      shouldShowAddMetadataPrompt: false,
      shouldShowNewLearnerPrompt: false,
    };

    this.events = new EventManager();

    this.tabs = {
      about: { id: 'about', label: 'About' },
      decks: { id: 'decks', label: 'Decks', count: 0 },
      learners: { id: 'learners', label: 'Learners', count: 0},
      leaderboard: { id: 'leaderboard', label: 'Leaderboard', count: 0},
    };

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.props.currentPack) {
      return null;
    }

    const bannerClass = this.state.isBannerOpen ? 'is-banner-open' : '';
    const flashMessageClass = this.state.isFlashMessageOpen
      ? 'is-flash-message-open'
      : '';
    const classes = toClassStr([
      'pack-detail-body',
      bannerClass,
      flashMessageClass,
      this.props.addClasses,
    ]);
    const pack = this.props.currentPack;
    const learnersCount = this.props.currentPack.learners
      ? this.props.currentPack.learners.length || 0
      : 0;

    return (
      <div className={classes}>
        <PackDetailHeader
          currentPack={this.props.currentPack}
          currentTabId={this.props.currentTabId}
          currentUser={this.props.currentUser}
          estimatedTimeLeft={this.props.estimatedTimeLeft}
          isFtue={this.props.isFtue}
          isMobileViewportSize={this.props.isMobileViewportSize}
          learnersCount={learnersCount}
          onPackDeleted={this.handlePackDeleted}
          onPackUpdated={this.handlePackUpdated}
          onRemovePackRequest={this.props.onRemovePackRequest}
          onSetDeckSortability={this.props.onSetDeckSortability}
          onStudyRequest={this.handleStudyRequest}
          onTabClick={this.props.onTabClick}
          selectedDecks={pack.localDeckTransforms?.selections?.ids}
          selectedDecksCardCount={pack.transformedStats?.cardCount}
          selectedDecksCardsStudiedCount={pack.transformedStats?.cardsStudied}
          selectedDecksMastery={pack.transformedStats?.mastery}
          shouldPulseStudyButton={this.props.shouldPulseStudyButton}
          shouldShowAddMetadataPrompt={this.state.shouldShowAddMetadataPrompt}
          shouldShowNewLearnerPrompt={this.state.shouldShowNewLearnerPrompt}
          tabs={this.getTabsAndCounts()}
          totalTimeStudied={this.props.totalTimeStudied}
        />

        <div className="detail-sections" id="detail-scroll-element">
          {this.renderPackAboutSection()}
          {this.renderPackDecksSection()}
          {this.renderPackLearnersSection()}
          {this.renderPackLeaderboardSection()}
        </div>
      </div>
    );
  }

  renderPackAboutSection() {
    if (this.props.currentTabId != 'about') {
      return null;
    }

    return (
      <PackAboutSection
        currentUser={this.props.currentUser}
        isLoadingPackMetadata={this.props.isLoadingPackMetadata}
        isMobileViewportSize={this.props.isMobileViewportSize}
        onPackMetadataUpdated={this.props.onPackMetadataUpdated}
        pack={this.props.currentPack}
        shouldShowAddMetadataPrompt={this.state.shouldShowAddMetadataPrompt}
      />
    );
  }

  renderPackDecksSection() {
    if (this.props.currentTabId != 'decks') {
      return null;
    }

    const currentUser = this.props.currentUser;
    const currentUserFlags = currentUser.flags || {};
    const pack = this.props.currentPack;

    return (
      <PackDecksSection
        currentDeckId={this.props.currentDeckId}
        currentUser={currentUser}
        isFtse={currentUserFlags.isFtse}
        isFtue={currentUserFlags.isFtue}
        isMobileViewportSize={this.props.isMobileViewportSize}
        onDeckCheckboxClick={(deckId) => this.handleDeckCheckboxClick(deckId)}
        onDeckDetailRequest={this.props.onDeckDetailRequest}
        onPackCheckboxClick={() => this.handlePackCheckboxClick()}
        pack={pack}
        packSelectedState={this.state.packSelectedState}
        selectedDecks={pack.localDeckTransforms.selections.ids}
        shouldPulseAddCardsButton={this.props.shouldPulseAddCardsButton}
        shouldPulseCreateDeckButton={this.props.shouldPulseCreateDeckButton}
      />
    );
  }

  renderPackLeaderboardSection() {
    const currentPack = this.props.currentPack;

    if (this.props.currentTabId != 'leaderboard') {
      return null;
    }

    if (currentPack.leaderboard?.length < 1) {
      return null;
    }

    return (
      <PackLeaderboardSection
        currentUser={this.props.currentUser}
        isLoadingPackLeaderboard={this.props.isLoadingPackLeaderboard}
        leaderboardOrder={this.props.currentPack.leaderboardOrder}
        learners={this.props.currentPack.leaderboard}
        pack={this.props.currentPack}
        shouldRenderAsTiles={this.props.isMobileViewportSize}
        userId={this.props.currentUser.userId}
      />
    );
  }

  renderPackLearnersSection() {
    const currentPack = this.props.currentPack;

    if (this.props.currentTabId != 'learners') {
      return null;
    }

    if (currentPack.leaderboard?.length < 1) {
      return null;
    }

    return (
      <PackLearnersSection
        areLearnersFullyPaginated={true}
        areLearnersFullyPopulated={true}
        authenticityToken={this.props.authenticityToken}
        currentUser={this.props.currentUser}
        hasEmptyLearnerSearchResults={false}
        isLoadingPackLearners={this.props.isLoadingPackLearners}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isShowingCachedPackLearners={this.props.isShowingCachedPackLearners}
        isUserPro={this.props.currentUser.flags.isPro}
        learners={this.props.currentPack.learners}
        metadata={this.props.currentPack.metadata}
        onNewLearnerBubbleClosed={this.handleNewLearnerBubbleClosed}
        onPaginateLearnersRequest={() => this.props.onPaginateLearnersRequest()}
        onRemoveLocalLearnerRequest={this.props.onRemoveLocalLearnerRequest}
        onSearchLearnersRequest={this.props.onSearchLearnersRequest}
        onSortLearnersRequest={(column, direction) =>
          this.props.onSortLearnersRequest(column, direction)
        }
        onUpdateLocalLearnerRequest={this.props.onUpdateLocalLearnerRequest}
        pack={this.props.currentPack}
        shouldShowNewLearnerPrompt={this.state.shouldShowNewLearnerPrompt}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBannerOpenRequested = (banner) => {
    if (!this._isMounted) {
      return false;
    }

    if (banner.block == 'page-detail') {
      this.setState({
        isBannerOpen: true,
      });
    }
  };

  handleBannerCloseRequested = (banner) => {
    if (!this._isMounted) {
      return false;
    }

    if (banner.block == 'page-detail') {
      this.setState({
        isBannerOpen: false,
      });
    }
  };

  handleFlashMessageOpenRequested = (flashMessage) => {
    if (!this._isMounted) {
      return false;
    }

    if (flashMessage && flashMessage.block == 'page-detail') {
      this.setState({
        isFlashMessageOpen: true,
      });
    }
  };

  handleFlashMessageCloseRequested = (flashMessage) => {
    if (!this._isMounted) {
      return false;
    }

    if (flashMessage && flashMessage.block == 'page-detail') {
      this.setState({
        isFlashMessageOpen: false,
      });
    }
  };

  handleNewLearnerBubbleClosed = () => {
    this.setState(
      {
        shouldShowNewLearnerPrompt: false,
      },
      () => {
        this.setHasSeenNewLearnerBubblePref(true);
      },
    );
  };

  handlePackCheckboxClick() {
    if (this.state.packSelectedState != 'none') {
      this.unselectAllPackDecks();
    } else {
      this.selectAllStudyablePackDecks();
    }

    this.props.onDeckSelectionChange(() => {
      this.setState({
        packSelectedState: this.getPackSelectedState(),
      });
    });
  }

  handlePackDeleted = (callback) => {
    EventManager.emitEvent('page-loading-overlay:open');

    this.props.onPackDeleted(() => {
      if (callback) {
        callback();
      }

      EventManager.emitEvent('page-loading-overlay:close');
    });
  };

  handlePackUpdated = (callback) => {
    EventManager.emitEvent('page-loading-overlay:open');

    this.props.onPackUpdated(() => {
      if (callback) {
        callback();
      }

      EventManager.emitEvent('page-loading-overlay:close');
    });
  };

  handleStudyRequest = (e) => {
    if (e) {
      e.stopPropagation();
    }

    const pack = this.props.currentPack;
    const studyMixType = this.getPackStudyMixType();
    const selectedDeckIds = pack.localDeckTransforms.selections.ids;

    if (pack.transformedStats.deckCount < 1) {
      this.triggerPackHasNoDecksModalOpen();
      return false;
    }

    if (pack.transformedStats.cardCount < 1) {
      this.triggerPackHasNoCardsModalOpen();
      return false;
    }

    if (selectedDeckIds.length < 1) {
      this.triggerInfoModalOpen({
        message:
          'Study Mix is disabled because there are no decks checked below. Check one or more decks (blue circles) to include them in your Study Mix.',
        resolveButtonText: 'Ok',
        shouldSuppressCancelButton: true,
        title: 'Study Mix Disabled',
      });

      return null;
    }

    const userId = this.props.currentUser.userId;
    const packId = this.props.currentPack.packId;
    const packDeckIds = selectedDeckIds;

    if (this.props.onStudyRequest) {
      this.props.onStudyRequest();
    }

    EventManager.emitEvent('page-loading-overlay:open');

    let studyMixUrl = StudyHelper.getPackStudyMixUrl(
      userId,
      this.props.currentPack,
      packDeckIds,
    );

    // TODO: This is a temporary measure only needed while needing to support two different library experiences: the legacy and the new dashboard. Remove this functionality when we are 100% transitioned to the new dashboard
    studyMixUrl = studyMixUrl + '&origin=dashboard';

    GaHelper.fireGaEvent('Study_Mix', studyMixType, packDeckIds.length);
    GaHelper.followUrlDelayed(studyMixUrl);
  };

  handleUpgradeButtonClick = () => {
    this.triggerUpgradeModalOpen('Unlocking a Private Class', 'private_access');
  };

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerPackHasNoDecksModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message:
        'Before you can study this class, you or the Class creator must add at least one deck with at least one card to the class.',
      resolveButtonText: 'Got it',
      title: 'This Class Has No Decks',
    });
  };

  triggerPackHasNoCardsModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message:
        'Before you can study this class, you or the Class creator must add at least one card to the class.',
      resolveButtonText: 'Got it',
      title: 'This Class Has No Cards',
    });
  };

  triggerUpgradeModalOpen(desiredAction, paywall) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall: paywall,
    });
  }

  /*
  ==================================================
   ADDITIONAL STATE MANAGEMENT
  ==================================================
  */

  getPackSelectedState() {
    const currentPack = this.props.currentPack;

    let deckCount = currentPack?.stats?.deckCount;
    let selectCount = currentPack?.localDeckTransforms?.selections.ids?.length;

    if (selectCount == 0) {
      return 'none';
    }

    if (selectCount == deckCount) {
      return 'all';
    }

    return 'mixed';
  }

  getPackStudyMixType() {
    return userLocalStore.getPackStudyMixType(this.props.currentUser.userId, this.props.currentPack);
  }

  getOrderedPackDeckIds() {
    const packDeckElems = document.querySelectorAll(
      '.deck-list .deck-row:not(.placeholder)',
    );
    let packDeckIds = [];

    for (let i = 0; i < packDeckElems.length; ++i) {
      packDeckIds.push(packDeckElems[i].id);
    }

    return packDeckIds;
  }

  selectAllStudyablePackDecks() {
    const pack = this.props.currentPack;
    const userPackDecks = pack.userPackDecks;
    const studyableDeckIds = pack.deckIds.filter((deckId) => {
      return userPackDecks[deckId].isStudyable;
    });

    userLocalStore.selectPackDecks(
      this.props.userId,
      pack.packId,
      studyableDeckIds,
    );
    this.props.onDeckSelectionChange();
  }

  unselectAllPackDecks() {
    const pack = this.props.currentPack;
    userLocalStore.unselectPackDecks(
      this.props.userId,
      pack.packId,
      pack.deckIds,
    );
    this.props.onDeckSelectionChange();
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getHasSeenNewLearnerBubblePref() {
    return !!userLocalStore.getUserLocalPref(
      this.props.currentPack.userId,
      'hasSeenNewLearnerBubble',
    );
  }

  getTabsAndCounts() {
    if (!this.props.currentPack) {
      return this.tabs;
    }

    let updatedTabs = {...this.tabs};
    const currentPack = this.props.currentPack;

    updatedTabs.decks.count = NumberHelper.displayNumber(
      currentPack.stats.deckCount,
    );

    if (currentPack.flags.isCertified) {
      updatedTabs.leaderboard.count = NumberHelper.displayNumber(this.props.currentPack.stats.subscriberCount);
      delete updatedTabs.learners;

    } else {
      updatedTabs.learners.count = NumberHelper.displayNumber(this.props.currentPack.stats.subscriberCount);
      delete updatedTabs.leaderboard;
    }

    return updatedTabs;
  }

  setHasSeenNewLearnerBubblePref(pref) {
    userLocalStore.updateUserLocalPrefs(
      this.props.currentPack.userId,
      'hasSeenNewLearnerBubble',
      pref,
    );
  }

  setupNewLearnerPrompt() {
    const shouldShowNewLearnerPrompt =
      !this.props.currentPack.isMarket &&
      !!this.props.currentPack.learners &&
      this.props.currentPack.learners.length < 2 &&
      !this.getHasSeenNewLearnerBubblePref();

    this.setState({
      shouldShowNewLearnerPrompt: shouldShowNewLearnerPrompt,
    });
  }

  setupAddMetadataPrompt() {
    let shouldShowAddMetadataPrompt = false;
    const pack = this.props.currentPack;
    const metadata = this.props.currentPack.metadata;

    if (pack.isUgs && Object.keys(metadata).length > 0) {
      if (
        pack.permissionLevel == 'admin' &&
        !(this.hasRoleMetadata() && this.hasPurposeMetadata())
      ) {
        shouldShowAddMetadataPrompt = true;
      }

      if (pack.permissionLevel == 'edit' && !this.hasRoleMetadata()) {
        shouldShowAddMetadataPrompt = true;
      }
    }

    this.setState({
      shouldShowAddMetadataPrompt: shouldShowAddMetadataPrompt,
    });
  }

  // TODO: Move these next two methods to PackMetadata model
  hasPurposeMetadata = () => {
    const purposes = this.props.currentPack.metadata.purposes;
    const purposeKeys = Object.keys(purposes);

    for (let i = 0; i < purposeKeys.length; i++) {
      if (purposes[purposeKeys[i]].value) {
        return true;
      }
    }

    return false;
  };

  hasRoleMetadata = () => {
    const global = this.props.currentPack.metadata.global;

    if (!(global && global.role && global.role.value)) {
      return false;
    }

    return !(global.role.value == 'unspecified');
  };
}

PackDetailBody.propTypes = PT;

export default PackDetailBody;
