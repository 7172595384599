
import {
  EditButton,
}                                     from '_views/shared/IconButton';

import DynamicTooltipIcon                   from '_views/shared/DynamicTooltipIcon';
import PillButton                     from '_views/shared/PillButton';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import ReactMde                       from 'react-mde';
import sanitizeHtml                   from 'sanitize-html/dist/sanitize-html.min';
import showdown                       from 'showdown';
import SimpleTextButton               from '_views/shared/SimpleTextButton';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                      : PropTypes.string,
  isEditingDescription            : PropTypes.bool,
  hasDescriptionResource          : PropTypes.bool,
  isEditingDescription            : PropTypes.bool,
  isEditingResource               : PropTypes.bool,
  isPackMetadataEditable          : PropTypes.bool,
  isPreviewing                    : PropTypes.bool,
  isProcessing                    : PropTypes.bool,
  metadata                        : PropTypes.object,
  onCancelEditDescriptionRequest  : PropTypes.func, 
  onEditDescriptionRequest        : PropTypes.func,
  onUpdateDescriptionRequest      : PropTypes.func,
  segmentId                       : PropTypes.node,         
};


class PackAboutDescriptionSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caption: "",
      charCount: 0,
      descriptionMarkdown: '',
      hasInvalidInput: false,
      isOverCharLimit: false,
      selectedMarkdownTab: 'write',
    }

    this.CHAR_LIMIT = 5000;

    this.converter = new showdown.Converter();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.setResources();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.metadata != this.props.metadata) {
      this.manageResources(prevProps);
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (this.props.isEditingDescription) {
      return this.renderEditableDescription();
    }

    let markdown = this.state.descriptionMarkdown || "No description.";

    if (!this.state.descriptionMarkdown && this.props.isPackMetadataEditable && !this.props.isPreviewing) {
      markdown = markdown + ' Click the Edit button to add a description.'
    }

    const sanitizedHtml = this.convertMarkdownToHtml(markdown);
    
    const isEmptyClass = (!this.props.hasDescriptionResource) ? 'is-empty' : '';
    const isEditingSegmentClass = this.props.isEditingDescription ? 'is-editing-segment' : '';
    const classes = toClassStr(['segment about-tab-segment pack-about-description-segment', isEditingSegmentClass, isEmptyClass]);

    return (
      <div className={classes}>
        <div className="segment-anchor" id={this.props.segmentId}></div>

        {this.renderDescriptionHeader()}
        <div className="description-markdown">
          <div className="sanitized-html" dangerouslySetInnerHTML={{__html: sanitizedHtml}} />
        </div>
      </div>
    );
  }

  renderEditableDescription = () => {
    const isEditingSegmentClass = this.props.isEditingDescription ? 'is-editing-segment' : '';
    const isOverCharLimitClass = this.state.isOverCharLimit ? 'is-over-char-limit' : '';
    const hasInvalidInputClass = this.state.hasInvalidInput ? 'has-invalid-input' : '';
    const classes = toClassStr(['segment about-tab-segment pack-about-description-segment', isEditingSegmentClass, isOverCharLimitClass, hasInvalidInputClass]);

    const saveButtonLabel = (this.props.isMobileViewportSize) ? 'Save' : 'Save changes';
    const markdownPlaceholder = {"placeholder": "Format your description using markdown. (See help above)"};
    
    return (
      <div className={classes}>
        <div className="segment-anchor" id={this.props.segmentId}></div>

        <div className="segment-header about-tab-segment-header description-header">
          <div className="segment-heading about-tab-segment-heading description-heading">Description</div>

          <div className="edit-action-buttons">

            <SimpleTextButton
              addClasses="help-button markdown-help-button"
              label="Markdown Help"
              onClick={this.handleMarkdownHelpButtonClick}
            />

            <SimpleTextButton
              addClasses="cancel-button cancel-edit-description-button"
              label="Cancel"
              onClick={this.handleCancelButtonClick}
            />

            <PillButton
              addClasses="save-button save-description-changes-button"
              isProcessing={this.props.isProcessing}
              label={saveButtonLabel}
              onClick={this.handleSaveChangesButtonClick}
            />
          </div>
        </div>

        <div className="description-markdown">
          <ReactMde
            value={this.state.descriptionMarkdown}
            onChange={this.handleDescriptionChange}
            selectedTab={this.state.selectedMarkdownTab}
            textAreaProps={markdownPlaceholder}
            onTabChange={this.handleMarkdownTabChange}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(this.convertMarkdownToHtml(markdown))
            }
          />
        </div>

        {this.renderFieldCaption()}
      </div>
    );
  }

  renderDescriptionHeader() {
    return (
      <div className="segment-header about-tab-segment-header description-header">
        <div className="segment-heading about-tab-segment-heading description-heading">Description</div>

        <div className="action-buttons">
          {this.renderDescriptionTooltip()}
          {this.renderEditDescriptionButton()}
        </div>
      </div>
    );
  }

  renderDescriptionTooltip() {
    if (!this.props.isPackMetadataEditable || this.props.isPreviewing) {
      return null;
    }

    const body = (
      <>
        <p className="body-text">This is the full description of your class, for other people thinking of studying your flashcards, or even just for your own notes.</p>
        <p className="body-text">You can mention topics like: What material do these flashcards cover? Why is the content so good? How should people study the flashcards? Etc.</p>
      </>
    );

    return (
      <DynamicTooltipIcon 
        actions={null}
        addClasses="description-tooltip"
        body={body}
        hasDismissButton={true}
        heading={null}
        position="right"
        subHeading={null}
      />

      // <InfoButtonTooltip
      //   addClasses="description-tooltip"
      //   label="description-tooltip"
      //   place="right"
      // >
      //   <>
      //     <p className="body-text">This is the full description of your class, for other people thinking of studying your flashcards, or even just for your own notes.</p>
      //     <p className="body-text">You can mention topics like: What material do these flashcards cover? Why is the content so good? How should people study the flashcards? Etc.</p>
      //   </>
      // </InfoButtonTooltip>
    );
  }

  renderEditDescriptionButton() {
    if (!this.props.isPackMetadataEditable || this.props.isEditingResource || this.props.isPreviewing) {
      return null;
    }

    return (
      <EditButton 
        addClasses="edit-description-button"
        onClick={this.handleEditButtonClick}
      />
    );
  }

  renderFieldCaption() {
    return (
      <div className="field-caption">
        {this.renderCaptionText()}
        {this.renderLimitText()}
      </div>
    );
  }

  renderCaptionText() {
    if (!this.state.caption) {
      return null;
    }

    return (
      <span className="caption-text">{this.state.caption}</span>
    );
  }

  renderLimitText() {
    const limitText = `(${this.state.charCount} of ${this.CHAR_LIMIT} char)`;

    return (
      <span className="limit-text">{limitText}</span>
    );
  }

      

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = () => {
    this.setResources();

    if (this.props.onCancelEditDescriptionRequest) {
      this.props.onCancelEditDescriptionRequest();
    }
  }

  handleDescriptionChange = (markdown) => {
    const newCharCount = (markdown) ? markdown.length : 0;
    const isOverCharLimit = (newCharCount > this.CHAR_LIMIT);

    if (isOverCharLimit != this.state.isOverCharLimit) {
      this.handleOverCharLimitChange(isOverCharLimit);
    }

    this.setState({
      charCount: newCharCount,
      descriptionMarkdown: markdown,
      hasInvalidInput: false,
    });
  }

  handleEditButtonClick = () => {
    if (this.props.onEditDescriptionRequest) {
      this.props.onEditDescriptionRequest();
    } 
  }

  handleMarkdownHelpButtonClick = () => {
    window.open('https://www.markdownguide.org/basic-syntax/', '_blank','noopener');
  }

  handleMarkdownTabChange = (tab) => {
    this.setState({
      selectedMarkdownTab: tab,
    })
  }

  handleOverCharLimitChange = (isOverLimit) => {
    const caption = isOverLimit ? 'Your description exceeds the character limit' : '';

    this.setState({
      caption: caption,
      isOverCharLimit: isOverLimit,
    })
  }

  handleSaveChangesButtonClick = () => {
    if (this.state.isOverCharLimit) {
      this.setState({
        hasInvalidInput: true,
      });

      return false;
    }

    if (this.props.onUpdateDescriptionRequest) {
      this.props.onUpdateDescriptionRequest(this.state.descriptionMarkdown);
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  convertMarkdownToHtml = (markdown) => {
    const convertedHtml = this.converter.makeHtml(markdown);
    const sanitizedHtml = sanitizeHtml(convertedHtml, {
      allowedTags: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
    });

    return sanitizedHtml;
  }

  hasResource = (resourceKey, testProps) => {
    const props = testProps || this.props;

    return !!(props.metadata && props.metadata.global && props.metadata.global[resourceKey] && props.metadata.global[resourceKey].value);
  }

  hasResourceChanged = (resourceKey, prevProps) => {
    if (!(this.hasResource(resourceKey, prevProps) && this.hasResource(resourceKey, this.props))) { 
      return false; 
    }

    const prevResourceValue = prevProps.metadata.global[resourceKey].value;
    const resourceValue = this.props.metadata.global[resourceKey].value;

    return (resourceValue != prevResourceValue);
  }

  manageResources = (prevProps) => {
    if (this.hasResourceChanged('description', prevProps)) {
      this.setDescriptionMarkdown();
    }
  }

  setDescriptionMarkdown = () => {
    if (this.hasResource('description')) {

      this.setState({
        descriptionMarkdown: this.props.metadata.global.description.value || "",
      })
    }
  }

  setResources = () => {
    this.setDescriptionMarkdown();
  }
}

PackAboutDescriptionSegment.propTypes = PT;

export default PackAboutDescriptionSegment;
