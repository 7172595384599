
import Modal                     from '_views/shared/Modal';
// import PackModel                 from '_legacy-models/PackModel';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import SimpleTextButton          from '_views/shared/SimpleTextButton';
import StringHelper              from '_utils/StringHelper';
import TextareaField             from '_views/shared/TextareaField';

import packMessage               from '_models/packMessage';

class MessageAuthorModalSet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasValidInput: false,
      isConfirmationModalOpen: false,
      isProcessing: false,
      message: "",
      shouldOpenConfirmationModal: false,
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        isOpen,
        onCloseRequest,
        onMessageSent,
        pack,
        user,
    */

    // this.Pack = new PackModel;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.resetFields();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.resetFields();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <div className="modal-set">
        {this.renderInputModal()}
        {this.renderConfirmationModal()}
      </div>
    );
  }

  renderInputModal() {
    const pack = this.props.pack;
    const recipient = pack.flags.isCertified ? 'Brainscape' : pack.creatorName;

    return (
      <Modal
        addClasses="message-author-input-modal"
        isOpen={this.props.isOpen}
        onClosed={this.handleInputModalClosed}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Message the Author</div>

        <div className="modal-guidance">Your message below will be sent to {recipient}. They will be able to reply to you via email.</div>

        <form
          className="message-form"
          onSubmit={this.handleFormSubmit}
        >

          <TextareaField 
            addClasses="message"
            id="message"
            onChange={this.handleMessageChange}
            name="message"
            placeholder="Type your message to the author here"
            value={this.state.message}
          />
        </form>

        <div className="modal-actions">

          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="Cancel"
            onClick={this.handleCancelButtonClick}
          />

          <PillButton
            addClasses="resolve-modal-button"
            isDisabled={!this.state.hasValidInput}
            isProcessing={this.state.isProcessing}
            label="Send message"
            onClick={this.handleSendMessageButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderConfirmationModal() {
    const pack = this.props.pack;
    const recipient = pack.isMarket ? 'Brainscape' : pack.creatorName;

    return (
      <Modal
        addClasses="message-author-confirmation-modal"
        isOpen={this.state.isConfirmationModalOpen}
        onCloseRequest={this.handleCloseConfirmationModalRequest}
      >

        <div className="modal-title">Message Sent</div>

        <div className="modal-message">A message has been sent to {recipient}.</div>

        <div className="modal-actions">

          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={this.handleCloseConfirmationModalRequest}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = () => {
    this.props.onCloseRequest();
  }

  handleCloseConfirmationModalRequest = () => {
    this.setState({
      isConfirmationModalOpen: false,
      shouldOpenConfirmationModal: false,
    })
  }

  handleInputModalClosed = () => {
    if (this.state.shouldOpenConfirmationModal) {
      this.setState({
        isConfirmationModalOpen: true,
      })
    }
  }

  handleSendMessageButtonClick = () => {
    if (this.state.hasValidInput && !this.props.isProcessing) {
      this.sendMessage();
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.hasValidInput && !this.props.isProcessing) {
      this.sendMessage();
    }
  }

  handleMessageChange = (e) => {
    const messageText = e.target.value;
    const hasValidInput = !StringHelper.isWhitespace(messageText);

    this.setState({
      hasValidInput: hasValidInput,
      message: messageText,
    })
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  resetFields() {
    this.setState({
      hasValidInput: false,
      isConfirmationModalOpen: false,
      isProcessing: false,
      message: "",
      shouldOpenConfirmationModal: false,
    });
  }

  sendMessage() {
    this.setState({
      isProcessing: true,
    });

    const packId = this.props.pack.packId;
    const messageText = this.state.message;

    packMessage.create(this.props.pack.packId, this.state.message).then(() => {
      this.showConfirmationModal();
    }).catch(err => {
      console.log('Problem sending message to author');

      this.setState({
        isProcessing: false,
      });
    });
  }

  showConfirmationModal() {
    this.setState({
      shouldOpenConfirmationModal: true,
    }, () => {
      this.props.onCloseRequest();
      // this will start animation of closing of input modal, upon its animation and close, the input modal's onClosed handler will fire. That handler then opens the confirmation modal (because this.state.shouldOpenConfirmationModal is true.)
      if (this.props.onMessageSent) {
        this.props.onMessageSent();
      }
    });
  }
}

export default MessageAuthorModalSet;
