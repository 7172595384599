
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import React                  from 'react';
import UiHelper               from '_utils/UiHelper';

import {toClassStr} from '_utils/UiHelper';


class AccountOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingOptionAction: false,
    };

    /*
      this.props:
        addClasses,
        openPosition,
        shouldButtonTurn,
    */

    this.menuOptionHash = {
      logOut: {id: 'logOut', tag: 'logout', label: 'Log Out'},
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillMount() {
    this.updateUserOptions();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser && !prevProps.currentUser) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['dashboard-options-button', this.props.addClasses]);

    return (
      <div className={classes}>
        <OptionsMenuButton
          isProcessing={this.state.isProcessingOptionAction}
          menuOptions={this.state.menuOptions}
          onOptionClick={() => this.handleOptionClick()}
          openPosition={this.props.openPosition}
          shouldButtonTurn={this.props.shouldButtonTurn}
          tooltipContent={this.props.tooltipContent} 
          tooltipPosition={this.props.tooltipPosition}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick() {
    this.handleOptionActionRequest();
  }

  handleOptionActionRequest() {
    this.performLogOutAction();
  }

  performLogOutAction() {
    UiHelper.navigate('/sign_out', 'Logging Out...');
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updateUserOptions() {
    let menuOptionsForUser = Object.keys(this.menuOptionHash).reduce((accumulator, menuOption) => {
      accumulator.push(this.menuOptionHash[menuOption]);
      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

export default AccountOptionsButton;
