
import EventManager       from '@brainscape/event-manager';
import React              from 'react';
import Spinner            from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

class EditOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        isDisabled,
        isProcessing,
        onClick,
        title,
        tooltipContent,
        tooltipPosition,
    */

    this.events = new EventManager();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const hasTooltipClass = this.props.tooltipContent ? 'has-tooltip' : '';
    const isDisabledClass = this.props.isDisabled ? 'is-disabled' : '';
    const isProcessingClass = this.props.isProcessing ? 'is-processing' : '';

    let classes = toClassStr(['icon-button', 'edit-options-button', hasTooltipClass, isDisabledClass, isProcessingClass, this.props.addClasses,]);

    return (
      <div
        className={classes}
        onClick={this.handleButtonClick}
        onMouseEnter={this.handleButtonMouseEnter}
        onMouseLeave={this.handleButtonMouseLeave}
        title={this.props.title}
        ref={(elem) => { this.elem = elem }} 
      >
        <div className="icons">
          <i className="edit-icon" />
          <i className="ellipsis-icon" />
        </div>

        {this.renderSpinner()}
      </div>
    );
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleButtonMouseEnter = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }
  }

  handleButtonMouseLeave = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.props.tooltipContent,
      elem: this.elem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };
}

export default EditOptionsButton;
