
import EventManager           from '@brainscape/event-manager';
import EventBus               from '_utils/EventBus';
import LearnerModel           from '_legacy-models/LearnerModel';
import Modal                  from '_views/shared/Modal';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import PackModel              from '_legacy-models/PackModel';
import PillButton             from '_views/shared/PillButton';
import React                  from 'react';
import Tracker                from '_utils/Tracker';

import packLearnerTransform   from '_models/packLearnerTransform';

import {toClassStr} from '_utils/UiHelper';

const IMMEDIATE_CSV_EXPORT_LEARNER_MAX = 1000;


class LearnerListOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExportedCsvModalOpen        : false,
      isProcessingPrivacyRequest    : false,
      isProcessingOptionAction      : false,
      csvMessage                    : null,
      menuOptions                   : [],
      upgradeModalOpts              : {},
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        isUserPro,
        learner,
        onShowBulkActionsBarRequest,
        pack,
        packPurpose,
        userRole,
    */

    this.learner = new LearnerModel;
    this.Pack = new PackModel;

    this.menuOptionHash = {
      addLearner: {id: 'addLearner', tag: 'add-learner', label: 'Add New Learner(s) to Class', onlyPackAdmin: true},
      exportCsv: {id: 'exportCsv', tag: 'export-csv', label: 'Export Learners to CSV', onlyDeckEditor: true, onlyPro: true, paywall: 'csv_export_learners', featuresList: 'list-8'},
      bulkActions: {id: 'bulkActions', tag: 'bulk-actions', label: 'Show Bulk Actions', onlyPackAdmin: true},
      clearTransforms: {id: 'clearTransforms', tag: 'clear-transforms', label: 'Reset Sorting and Filters', onlyPackAdmin: true},
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillMount() {
    this.updateUserOptions();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUserPro != prevProps.isUserPro) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['learner-list-options-button', this.props.addClasses]);

    return (
      <div className={classes}>

        <OptionsMenuButton
          iconType={this.props.iconType}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.isUserPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition="bottomLeft"
          shouldButtonTurn={false}
        />

        {this.renderExportedCsvModal()}
      </div>
    );
  }

  renderExportedCsvModal() {
    return (
      <Modal
        addClasses="exported-csv-modal"
        isOpen={this.state.isExportedCsvModalOpen}
        onCloseRequest={() => this.handleExportedCsvModalCloseRequest()}
      >

        <div className="modal-title">Learners Exported</div>

        <div className="modal-message">{this.state.csvMessage}</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={() => this.handleExportedCsvOkButtonClick()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'addLearner':
        this.performAddLearnerAction();
      break;
      case 'exportCsv':
        this.performExportCsvAction();
      break;
      case 'bulkActions':
        this.performBulkActionsAction();
      break;
      case 'clearTransforms':
        this.performClearTransformsAction();
      break;
    }
  }

  handleOptionClick(optionId) {
    let requiresPro = this.menuOptionHash[optionId].onlyPro;

    if (requiresPro && !this.props.isUserPro) {
      const desiredAction = this.menuOptionHash[optionId].label || "";
      const paywall = this.menuOptionHash[optionId].paywall || "";
      let featuresList = this.menuOptionHash[optionId].featuresList;
      this.showUpgradeModal(desiredAction, paywall, featuresList);
    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  performAddLearnerAction() {
    this.triggerSharePackModalSetOpen();
  }

  performBulkActionsAction() {
    this.props.onShowBulkActionsBarRequest();
  }

  performClearTransformsAction = () => {
    const userId = this.props.currentUser.userId;
    const packId = this.props.pack.packId;

    packLearnerTransform.clear(userId, packId, 'filters');
    packLearnerTransform.clear(userId, packId, 'sorters');
  }

  handleExportedCsvModalCloseRequest() {
    this.setState({
      isExportedCsvModalOpen: false,
    });
  }

  handleExportedCsvOkButtonClick() {
    this.setState({
      isExportedCsvModalOpen: false,
    });
  }

  performExportCsvAction() {
    if (!this.props.currentUser.flags.isBscAdmin && this.hasNonProLearners()) {
      this.triggerGroupLicenseRequiredModal();
      return false;
    }

    this.setState({
      isProcessingOptionAction: true,
    });

    const learnerCount = this.props.pack.stats.subscriberCount;
    let csvMessage;

    if (learnerCount >= IMMEDIATE_CSV_EXPORT_LEARNER_MAX) {
      csvMessage = `Your CSV is being prepared and will be emailed to ${this.props.currentUser.profile.email}.`;
      this.invokeCsvExportJob();

    } else {
      csvMessage = "Check your browser's downloads folder for a CSV of your classes' learners.";
      this.invokeImmediateCsvExport();
    }

    this.setState({
      csvMessage: csvMessage,
      isExportedCsvModalOpen: true,
      isProcessingOptionAction: false
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerGroupLicenseRequiredModal = () => {
    EventManager.emitEvent('group-license-required-modal:open', {});
  }

  triggerInstructorUpgradeModalOpen(opts) {
    EventManager.emitEvent('instructor-upgrade-modal:open', {
      paywall: opts.paywall,
      purpose: opts.purpose,
    });
  }

  triggerSharePackModalSetOpen() {
    EventManager.emitEvent('share-pack-modal-set:open', {
      authenticityToken:    this.props.authenticityToken,
      pack:                 this.props.pack,
    });
  }

  triggerUpgradeModalOpen(desiredAction, paywall, message, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
      message:       message,
      featuresList:  featuresList,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  hasNonProLearners = () => {
    const learners = this.props.pack.learners;

    if (this.props.currentUser.flags.isPro && learners.length < 2) {
      return false;
    }

    if (learners.some(learner => !learner.flags.isPro)) {
      return true;
    }

    return false;
  }

  invokeCsvExportJob = () => {
    EventBus.publish(
      'pack:export_csv',
      {pack_id: this.props.pack.packId},
      true,
    );
  }

  invokeImmediateCsvExport = () => {
    location.href = '/packs/' + this.props.pack.packId + '/users.csv';
  }

  showUpgradeModal(desiredAction, paywall, featuresList) {
    Tracker.trackPaywallWarning(paywall);

    let message = "Privacy, Learner Stats, and other powerful features for teaching are available with a Pro account."
    this.triggerUpgradeModalOpen(desiredAction, paywall, message, featuresList);
  }

  updateUserOptions() {
    const pack = this.props.pack;

    let menuOptionsForUser = Object.keys(this.menuOptionHash).reduce((accumulator, menuOption) => {

      const permitPerEditDeckRights = (this.menuOptionHash[menuOption].onlyDeckEditor) ? pack.flags.areDecksEditable : true;
      let permitPerPackAdminRights = (this.menuOptionHash[menuOption].onlyPackAdmin) ? (pack.permission == 'admin') : true;

      if (permitPerEditDeckRights && permitPerPackAdminRights) {
        accumulator.push(this.menuOptionHash[menuOption]);
      }

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

export default LearnerListOptionsButton;
