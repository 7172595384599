
// substrate and utils
import eachDataTransferFile    from '_utils/eachDataTransferFile';
import EventManager            from '@brainscape/event-manager';
import PropTypes               from 'prop-types';
import React                   from 'react';
import removeMd                from 'remove-markdown';
import sanitizeHtml            from 'sanitize-html/dist/sanitize-html.min';
import showdown                from 'showdown';
import StringHelper            from '_utils/StringHelper';
import UiHelper                from '_utils/UiHelper';
import {toClassStr}            from '_utils/UiHelper';

// sub-components
import DropWrapper             from '_views/shared/DropWrapper';
import PillButton              from '_views/shared/PillButton';
import SimpleTextButton        from '_views/shared/SimpleTextButton';
import SmartTextField          from '_views/shared/smart-cards/SmartTextField';
import SoundButton             from '_views/shared/SoundButton';
import StudyCardOptionsButton  from '_views/shared/smart-cards/StudyCardOptionsButton';

import {
  AlertButton,
  CloseButton,
  ConcealButton,
  EditButton,
  InfoButton,
  SoundWaveButton,
}                              from '_views/shared/IconButton';


const PT = {
  addClasses:                 PropTypes.string,
  card:                       PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cardCount:                  PropTypes.number,
  cardMode:                   PropTypes.string, // 'edit', 'display'
  cardPosition:               PropTypes.number,
  currentUser:                PropTypes.object,
  context:                    PropTypes.string, // 'editor', 'browse', 'preview', 'study'
  deck:                       PropTypes.object,
  editMode:                   PropTypes.string, // 'simple', 'advanced', 'source'
  editorHasChanges:           PropTypes.bool,
  face:                       PropTypes.string, // 'question' or 'answer'
  format:                     PropTypes.string, // 'md', text', 'html'
  inlineStyle:                PropTypes.object,
  isAnswerShowing:            PropTypes.bool,
  isAtCheckpoint:             PropTypes.bool,
  isAudioMuted:               PropTypes.bool,
  isBlurred:                  PropTypes.bool,
  isCurrentCard:              PropTypes.bool,
  isEditable:                 PropTypes.bool,
  isLoadingCardDisplayData:   PropTypes.bool,
  isMobileViewportSize:       PropTypes.bool,
  isShowingAdditionalFields:  PropTypes.bool,
  isViewingSource:            PropTypes.bool,
  layout:                     PropTypes.string, // 'single-card', 'list'
  onAddImageFile:             PropTypes.func,
  onAddSoundFile:             PropTypes.func,
  onCardFlipRequest:          PropTypes.func,
  onFieldChange:              PropTypes.func,
  onViewLargeImageRequest:    PropTypes.func,
  pack:                       PropTypes.object,
  packId:                     PropTypes.node,
  shouldReportBaseFontSize:   PropTypes.bool,
  transitionState:            PropTypes.string, // for transitions during study
  viewportStyle:              PropTypes.string, // 'mobile', 'desktop' (or 'phone', 'tablet', 'desktop')
};

// the following are needed to dynamically calculate size of card image containers
const CARD_FACE_SELECTOR = '.smart-card-face';
const BODY_FIELD_SELECTOR = '.body-field';

const NAME_TO_SCF_CLASS_MAP = {
  qMdPrompt: 'scf-prompt',
  aMdPrompt: 'scf-prompt',
  qMdBody: 'scf-body',
  aMdBody: 'scf-body',
  qMdClarifier: 'scf-clarifier',
  aMdClarifier: 'scf-clarifier',
  aMdFootnote: 'scf-footnote',
  qImageCaption: 'scf-image-caption',
  aImageCaption: 'scf-image-caption',
}

const SCF_BODY_CHAR_LIMIT = {
  'scf-body-xl': 4,
  'scf-body-l': 18,
  'scf-body-m': 72,
};

const PROMPT_CHAR_LIMIT = 72;
const CLARIFIER_CHAR_LIMIT = 72;

const DEFAULT_FILE_SIZE_LIMIT =  5242880; // 5MB
const DEFAULT_ACCEPTABLE_FILE_MIME_TYPES = {
  image:  ['image/gif', 'image/png', 'image/jpeg'],
  audio:  ['audio/mpeg'],
  csv:    ['text/csv'],
};


class SmartCardFace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      baseFontSize: 11,
      isProcessing: false,
      studyContextStyles: {},
    };

    this.converter = new showdown.Converter();
    this.converter.setOption('simpleLineBreaks', true);

    this.elem = null;

    this.addImageButtonElem = null;
    this.addSoundButtonElem = null;

    this.dragOverTimeout = null;
    this.viewportStyleTimeout = null;

    this.bodyFieldHeight = null;

    this.IS_SVG_ENABLED  = !!this.props.currentUser?.flags?.isBscAdmin;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    this.clearTimeoutsAndIntervals();

    if (this.props.shouldSetDynamicType) {
      this.setBaseFontSize();
      this.startWindowResizeMonitor();
    }

    if (this.props.shouldReportBaseFontSize) {
      this.reportBaseFontSize();
    }

    if (this.props.context == 'browse' && this.props.viewportStyle) {
      this.startKeyDownMonitor();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.shouldSetDynamicType) {

      if (prevProps.viewportStyle != this.props.viewportStyle) {
        this.viewportStyleTimeout = setTimeout(() => {
          this.setBaseFontSize();
        }, 500);
      }
    }

    if (!(prevProps.context == 'browse' && prevProps.viewportStyle) && (this.props.context == 'browse' && this.props.viewportStyle)) {
      this.startKeyDownMonitor();
    }

    if ((prevProps.context == 'browse' && prevProps.viewportStyle) && !(this.props.context == 'browse' && this.props.viewportStyle)) {
      this.stopKeyDownMonitor();
    }
  }

  componentWillUnmount() {
    if (this.props.shouldSetDynamicType) {
      this.stopWindowResizeMonitor();
    }

    this.clearTimeoutsAndIntervals();

    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const card = this.props.card;

    if (!card) {
      return null;
    }

    const face = this.props.face;
    const contextClass = `${this.props.context}-context`;
    const cardModeClass = (this.props.cardMode) ? `${this.props.cardMode}-card-mode` : '';
    const editModeClass = (this.props.cardMode == 'edit') ? `${this.props.editMode}-edit-mode` : '';
    const layoutClass = `${this.props.layout}-layout`;
    const viewportStyleClass = (this.props.viewportStyle) ? `${this.props.viewportStyle}-viewport-style` : ''; 
    const formatClass = `${this.props.format}-format`;

    const legacyAnimationClass = (this.props.context == 'study' && this.props.isLegacyAnimation) ? 'study-card-face is-legacy-animation' : '';

    const revealAnimationClass = ((this.props.context == 'study' || this.props.context == 'browse') && !this.props.isLegacyAnimation) ? 'is-reveal-animation' : '';
    const levelClass = `level-${card.stats?.level || 0}`;
    const isBlurredClass = (this.props.isBlurred) ? 'blur-card' : '';

    const classes = toClassStr(['smart-card-face', legacyAnimationClass, revealAnimationClass, `${face}-face`, levelClass, contextClass, cardModeClass, editModeClass, layoutClass, viewportStyleClass, formatClass, isBlurredClass, this.props.addClasses]);

    const facePrefix = this.getFacePrefix();
    let inlineStyle = {};

    const isDropDisabled = this.props.editorHasChanges && !this.props.isCurrentCard;

    if (this.props.shouldSetDynamicType) {
      inlineStyle.fontSize = `${this.state.baseFontSize}px`;
    }

    if (this.props.cardMode == 'edit') {
      return (
        <div className={classes}>

          <DropWrapper
            addClasses="smart-card-face-drop-wrapper"
            isDisabled={isDropDisabled}
            onFileDrop={this.handleFileDrop}
          >
            {this.renderCardContents()}
          </DropWrapper>
        </div>
      );
    }

    return (
      <div className={classes} style={inlineStyle} ref={(elem) => this.elem = elem}
>
        {this.renderCardFaceOverlay()}
        {this.renderCardContents()}
      </div>
    );
  };

  renderCardFaceOverlay = () => {
    if (!this.props.isBlurred) {
      return null;
    }

    return (
      <div className="card-face-overlay">
        <PillButton
          label="Upgrade To Pro"
          onClick={this.handleUpgradeRequest}
        />
      </div>
    );
  }

  renderCardContents = () => {
    if (this.props.cardMode != 'edit') {
      return this.renderPreview();
    }

    if (this.props.editMode == 'source') {
      return this.renderSourceCode();
    }

    if (this.props.editMode == 'simple') {
      return this.renderBodyTextField();
    }

    switch (this.props.format) {
      case 'md': 
        return this.renderMdFields();
      break;
      case 'html': 
        return this.renderHtmlFields();
      break;
      default:
        return this.renderBodyTextField();
    }
  }

  renderSourceCode = () => {
    const card = this.props.card;
    const face = this.props.face;
    const source = this.props.card[face];
    const formatLabel = (this.props.format == 'md') ? 'markdown' : 'HTML';

    const cardId = (card == 'new') ? 'new' : card.cardId;
    const id = `${face}-${cardId}`;

    return (
      <div className="card-contents">

        {this.renderCardFaceHeader()}

        <div className="main-fields-container">
          <SmartTextField 
            addClasses={`card-field ${face}-field source-field`}
            cardId={cardId}
            format="text"
            id={id}
            key={id}
            name={face}
            onChange={this.handleCardFieldChange}
            onFieldModeChange={this.handleFieldModeChange}
            onKeyDown={this.props.onKeyDown}       
            placeholder={`${face} source ${formatLabel}`}
            value={source || ''}
          />
        </div>
      </div>
    );
  }

  renderMdFields = () => {
    const face = this.props.face;
    const facePrefix = this.getFacePrefix();
    const faceLabel = StringHelper.toTitleCase(face);

    const bodyFieldLength = this.getBodyFieldLength();
    const bodyScfClassName = this.getBodyScfClassName(bodyFieldLength);
    const clarifierScfClassName = this.getClarifierScfClassName(bodyFieldLength);

    return (
      <div className="card-contents">

        {this.renderCardFaceHeader()}

        <div className="main-fields-container">
          <div className="main-fields">
            {this.renderPromptSmartTextField(`${facePrefix}MdPrompt`, 'Add Prompt:', 'scf-prompt', 'md', PROMPT_CHAR_LIMIT)}
            {this.renderSmartTextField(`${facePrefix}MdBody`, faceLabel, bodyScfClassName, 'md')}
            {this.renderSmartTextField(`${facePrefix}MdClarifier`, '(Add Clarifier)', clarifierScfClassName, 'md', CLARIFIER_CHAR_LIMIT)}
            {this.renderImageFields()}
          </div>
        </div>

        {this.renderSmartTextField(`${facePrefix}MdFootnote`, 'Add Footnote', 'scf-footnote', 'md')}

        {this.renderCardFaceFooter()}
      </div>
    );
  }

  renderHtmlFields = () => {
    const face = this.props.face;
    const faceLabel = StringHelper.toTitleCase(face);

    return (
      <div className="card-contents">

      {this.renderCardFaceHeader()}

        <div className="main-fields-container">
          <div className="main-fields">
            {this.renderPromptSmartTextField(`prompt`, 'Prompt (optional)', 'scf-prompt', 'text')}
            {this.renderSmartTextField(face, faceLabel, 'scf-body', 'html')}
            {this.renderImageFields()}
          </div>
        </div>
      </div>
    );
  }

  renderBodyTextField = () => {
    const card = this.props.card;
    const face = this.props.face;
    const facePrefix = this.getFacePrefix();
    const faceLabel = StringHelper.toTitleCase(face);
    const fieldKey = (this.props.format == 'md') ? `${facePrefix}MdBody` : face;
    const value = this.props.card[face];

    const classes = toClassStr([`card-field ${face}-field body-text-field scf-body`]);

    return (
      <div className="card-contents">

        <header className="card-face-header">
          <div className="header-row main-row">
            <div className="card-type-indicator">{facePrefix}</div>

            {this.renderSmartTextField(fieldKey, faceLabel, classes, 'text')}
            {this.renderCardControls(facePrefix)}
          </div>
        </header>

        {this.renderAdditionalFieldsNotice()}
      </div>
    );
  }

  renderPromptSmartTextField(fieldKey, placeholder, addClasses, fieldFormat, charLimit) {
    const face = this.props.face;

    if (face == 'answer') {
      return null;
    }
    
    return this.renderSmartTextField(fieldKey, placeholder, addClasses, fieldFormat, charLimit);
  }

  renderSmartTextField(fieldKey, placeholder, addClasses, fieldFormat, charLimit=null) {
    const card = this.props.card;
    const face = this.props.face;
    const value = this.props.card[fieldKey];

    const classes = toClassStr([`${face}-field`, addClasses]);
    const cardId = (card == 'new') ? 'new' : card.cardId;
    const id = `${fieldKey}-${cardId}`;
    
    return (
      <SmartTextField 
        addClasses={classes}
        cardId={cardId}
        charLimit={charLimit}
        format={fieldFormat || this.props.format}
        id={id}
        key={id}
        name={fieldKey}
        onChange={this.handleCardFieldChange}
        onFieldModeChange={this.handleFieldModeChange}
        onKeyDown={this.props.onKeyDown}       
        placeholder={placeholder}
        value={value}             
      />
    );
  }

  renderCardControls = (facePrefix) => {
    if (this.props.cardMode == 'edit') {
      return (
        <div className="card-controls">
          {this.renderImageControls(facePrefix)}
          {this.renderSoundControls(facePrefix)}
        </div>
      );
    }

    return (
      <div className="card-controls">
        {this.renderProcessingImageIndicator()}
        {this.renderSoundControls(facePrefix)}
      </div>
    );   
  }

  renderSoundControls = (facePrefix) => {
    const card = this.props.card;
    const soundUrlKey = this.getMediaUrlKey('sound')

    if (card[soundUrlKey] && card[soundUrlKey] != 'destroy') {
      return this.renderExistingSoundControls(facePrefix);
    }

    return this.renderAddSoundControls(facePrefix);
  }

  renderExistingSoundControls = (facePrefix) => {
    if (this.props.isBlurred) {
      return null;
    }
    
    if (this.props.context == 'study' && !this.props.isCurrentCard) {
      return null;
    }

    if (this.props.transitionState) {
      return null;
    }

    const soundUrlKey = this.getMediaUrlKey('sound')
    const soundUrl = this.props.card[soundUrlKey];
    const soundId = (this.props.transitionState) ? `${this.props.card.cardId}-${this.props.face}-${this.props.face}-${this.props.transitionState}` : `${this.props.card.cardId}-${this.props.face}`;

    if (!soundUrl) {
      return null;
    }

    let shouldAutoplay = false;

    if ((this.props.context == 'study' || this.props.context == 'browse') && this.props.isCurrentCard && !this.props.isAudioMuted && this.props.cardMode != 'edit') {
      const face = this.props.face;

      shouldAutoplay = (this.props.isAnswerShowing) ? (face == 'answer') : (face == 'question');
    }

    return (
      <div className="card-sound-and-controls">

        <SoundButton
          shouldAutoplay={shouldAutoplay}
          soundId={soundId}
          soundUrl={soundUrl}
        />

        {this.renderRemoveSoundButton()}
      </div>
    );
  }

  renderAddSoundControls = (facePrefix) => {
    if (this.props.cardMode != 'edit') {
      return null;
    }

    const cardId = this.props.card.cardId;
    const inputElemId = `${facePrefix}-sound-file-input-${cardId}`;

    return (
      <div className="add-sound-controls">

        <div 
          className='add-sound-button' 
          onClick={this.handleAddSoundButtonClick}
          onMouseEnter={this.handleAddSoundButtonMouseEnter}
          onMouseLeave={this.handleAddSoundButtonMouseLeave}
          ref={(elem => this.addSoundButtonElem = elem)}
        >

          <label className='sound-file-input-label' htmlFor={inputElemId}></label>

          <input
            className="sound-file-input"
            id={inputElemId}
            accept='audio/mpeg'
            name='sound'
            onChange={this.handleFileInputChange}
            type='file'
          />
        </div>
      </div>
    );
  }

  renderRemoveSoundButton = () => {
    if (this.props.cardMode != 'edit') {
      return null;
    }

    return (
      <CloseButton
        addClasses="remove-sound-button"
        onClick={this.handleRemoveSoundButtonClick}
      />
    );
  }

  renderImageFields = () => {
    const facePrefix = this.getFacePrefix();

    return (
      <div className="image-fields">
        {this.renderImage()}
        {this.renderImageCaptionTextField()}
      </div>
    );
  }

  renderImage = () => {
    const card = this.props.card;
    const imageUrlKey = this.getMediaUrlKey('image');

    if (card[imageUrlKey] && card[imageUrlKey] != 'destroy') {
      return this.renderExistingImageAndControls();
    }

    return null;
  }

  renderImageControls = (facePrefix) => {
    const card = this.props.card;
    const imageUrlKey = this.getMediaUrlKey('image');

    if (card[imageUrlKey] && card[imageUrlKey] != 'destroy') {

      if (this.props.editMode == 'advanced') {
        return null;  // existing image will be displayed lower in card face
      }

      return this.renderExistingImageAndControls();
    }

    return this.renderAddImageControls(facePrefix);
  }

  renderProcessingImageIndicator = () => {
    const card = this.props.card;
    const imageUrlKey = this.getMediaUrlKey('image');

    if (!card[imageUrlKey]) {
      return null;
    }

    if (!card[imageUrlKey].startsWith('data')) {
      return null;
    }

    const imageUrl = this.getImageUrl();

    if (!imageUrl) {
      return null;
    }

    const classes = toClassStr(['card-image-and-controls', 'is-new-attachment', 'is-zoomable']);

    return (
      <div className={classes}>

        <div className="status">Processing uploaded image...</div>

        <div className="card-image">
          <img className="card-sized-image" src={imageUrl} onClick={(e) => this.handleImageClick(e, imageUrl)} />
        </div>
      </div>
    );
  }

  renderExistingImageAndControls = () => {
    const imageUrl = this.getImageUrl();
    const fullSizeImageUrl = this.getFullSizeImageUrl();

    if (!imageUrl) {
      return null;
    }

    const isNewAttachmentClass = (imageUrl.startsWith('data')) ? 'is-new-attachment' : '';
    const zoomClass = (fullSizeImageUrl) ? 'is-zoomable' : '';
    const classes = toClassStr(['card-image-and-controls', zoomClass, isNewAttachmentClass]);

    return (
      <div className={classes}>

        <div className="card-image">
          <img className="card-sized-image" src={imageUrl} onClick={(e) => this.handleImageClick(e, fullSizeImageUrl)} />

          {this.renderHtmlImageCaption()}
        </div>

        {this.renderRemoveImageButton()}
      </div>
    );
  }

  renderAddImageControls = (facePrefix) => {
    if (this.props.cardMode != 'edit') {
      return null;
    }

    const cardId = this.props.card.cardId;
    const inputElemId = `${facePrefix}-image-file-input-${cardId}`;
    let acceptedImageFormatList = 'image/gif, image/jpeg, image/png';

    if (this.IS_SVG_ENABLED) {
      acceptedImageFormatList += ', image/svg+xml';
    }

    return (
      <div className="add-image-controls">

        <div 
          className='add-image-button' 
          onClick={this.handleAddImageButtonClick}
          onMouseEnter={this.handleAddImageButtonMouseEnter}
          onMouseLeave={this.handleAddImageButtonMouseLeave}
          ref={(elem => this.addImageButtonElem = elem)}
        >

          <label className='image-file-input-label' htmlFor={inputElemId}></label>

          <input
            className="image-file-input"
            id={inputElemId}
            accept={acceptedImageFormatList}
            name='image'
            onChange={this.handleFileInputChange}
            type='file'
          />
        </div>
      </div>
    );
  }

  renderRemoveImageButton = () => {
    if (this.props.cardMode != 'edit') {
      return null;
    }

    return (
      <CloseButton
        addClasses="remove-image-button"
        onClick={this.handleRemoveImageButtonClick}
      />
    );
  }

  renderImageCaptionTextField = () => {
    const card = this.props.card;
    const imageUrlKey = this.getMediaUrlKey('image');

    if (!(card && card[imageUrlKey] && card[imageUrlKey] != "destroy")) {
      return null;
    }

    const face = this.props.face;
    const facePrefix = this.getFacePrefix();
    const imageCaption = card[`${facePrefix}ImageCaption`] || '';

    if (this.props.cardMode != 'edit' && !imageCaption) {
      return null;
    }

    return this.renderSmartTextField(`${facePrefix}ImageCaption`, 'Add Image Caption', 'scf-image-caption', 'text');
  }

  renderAdditionalFieldsNotice = () => {
    if (!this.hasAdditionalFields()) {
      return null;
    }

    const card = this.props.card;
    const face = this.props.face;
    const facePrefix = this.getFacePrefix();

    return (
      <div className="additional-fields-notice">

        <AlertButton 
          addClasses="additional-fields-alert-button"
        />

        <div className="message-and-button">

          <div className="additional-fields-message">This card face has at least one advanced field.</div>

          <SimpleTextButton 
            addClasses="additional-fields-mode-button"
            label="See all fields in Advanced Cards mode"
            onClick={this.handleAdditionalFieldsModeButtonClick}
          />
        </div>
      </div>
    );
  }

  renderPreview = () => {
    if (this.props.cardMode == 'edit') {
      return null;
    }

    const card = this.props.card;

    if (!card) {
      return null;
    }

    const html = (this.props.face == 'answer') ? card.answerHtml : card.questionHtml;

    return (
      <div className="card-contents">

        {this.renderCardFaceHeader()}

        <div className="main-fields-container">

          {this.renderHtmlCardPrompt()}

          <div className="preview-html" dangerouslySetInnerHTML={{__html: html}} onClick={this.handlePreviewHtmlClick}></div>

          {this.renderNonMdCardImage()}
        </div>

        {this.renderCardFaceFooter()}
      </div>
    );
  }

  renderCardFaceHeader = () => {
    const facePrefix = this.getFacePrefix();

    return (
      <header className="card-face-header">
        {this.renderCardHeaderUpperRow()}

        <div className="header-row main-row">
          <div className="card-type-indicator">{facePrefix}</div>
          {this.renderCardControls(facePrefix)}
        </div>
      </header>
    );
  }

  renderCardFaceFooter = () => {
    if (this.props.context != 'study') return null;
    if (this.props.face != 'answer') return null;
    if (this.props.cardMode == 'edit') return null;    

    return (
      <footer className="card-face-footer">
        <ConcealButton 
          onClick={this.handleConcealButtonClick}
          tooltipContent="Hide Answer"
          tooltipPosition="top"
        />
      </footer>
    );
  }

  renderHtmlCardPrompt() {
    if (this.props.format != 'html') {
      return null;
    }

    if (!this.props.card.prompt || this.props.face == 'answer') {
      return null;
    }

    const prompt = StringHelper.safeDecode(this.props.card.prompt);

    return (
      <div className="card-prompt">
        {prompt}
      </div>
    );
  }

  renderNonMdCardImage = () => {
    if (this.props.format == 'md') {
      return null;
    }

    return this.renderExistingImageAndControls();
  }

  renderHtmlImageCaption() {
    if (this.props.format != 'html') {
      return null;
    }

    if (this.props.cardMode == 'edit') {
      return null;
    }

    let caption = (this.props.face == 'answer') ? this.props.card.aImageCaption || null : this.props.card.qImageCaption || null


    if (!caption) {
      return null;
    }

    caption = StringHelper.safeDecode(caption);

    return (
      <div className="image-caption">
        {caption}
      </div>
    );
  }

  renderCardHeaderUpperRow = () => {
    if (this.props.layout != 'single-card') {
      return null;
    }

    return (
      <div className="header-row upper-row">
        <div className="left-buttons">
          {this.renderBookmarkButton()}
        </div>

        <div className="center">
          {this.renderCardIndicator()}
        </div>

        <div className="right-buttons">
          {this.renderStudyCardOptionsButton()}
        </div>
      </div>
    );
  }

  renderBookmarkButton = () => {
    return null;
  }

  renderCardIndicator = () => {
    if (this.props.context == 'browse') {
      const cardPosition = this.props.cardPosition || this.props.card.number;
      const cardCount = this.props.cardCount || null;
      const cardInfo = cardCount ? `Card ${cardPosition} of ${cardCount}` : `Card ${cardPosition}`;
      const cardIndicator = (this.props.cardMode == 'edit') ? `Editing ${cardInfo}` : cardInfo;

      return (
        <div className="card-indicator">{cardIndicator}</div>
      );
    }

    if (this.props.context == 'study' && this.props.isLoadingCardDisplayData) {
      return (
        <div className="card-indicator is-loading">Loading Cards...</div>
      );
    }

    if (this.props.context == 'study' && this.props.cardMode == 'edit') {
      return (
        <div className="card-indicator">Editing Card</div>
      );
    }

    return null;
  }

  renderStudyCardOptionsButton = () => {
    if (['browse', 'study'].indexOf(this.props.context) == -1) {
      return null;
    }

    if (this.props.cardMode == 'edit') {
      return null;
    }

    if (this.props.context == 'study' && this.props.currentUser?.flags?.isFtse) {
      return false;
    }

    const isCardEditable = this.props.deck.flags?.isEditable || false;
    const isUserPro = this.props.currentUser?.flags?.isPro || false;

    return (
      <StudyCardOptionsButton 
        cardId={this.props.card.cardId}
        currentUser={this.props.currentUser}
        deckId={this.props.deck.deckId}
        isCardEditable={isCardEditable}
        isUserPro={isUserPro}
        openPosition="bottomLeft"
        pack={this.props.pack}
        packId={this.props.packId}
        tooltipContent="Advanced Card Options"
        tooltipPosition="left"
      />
    );
  }

  renderEditButton() {
    if (this.props.cardMode == 'edit') {
      return null;
    }

    if (this.props.context == 'study' && this.props.currentUser?.flags?.isFtse) {
      return false;
    }

    const card = this.props.card;
    const deck = this.props.deck;

    const title = (deck?.flags?.isEditable) ? 'Edit this Card' : 'Suggest Changes to this Card';

    return (
      <EditButton
        addClasses='edit-card-button'
        onClick={this.handleEditButtonClick}
        tooltipContent={title}
        tooltipPosition="bottom"
      />
    );
  }

  renderFlashBody = (flash) => {
    return (
      <div className="flash-body">
        {this.renderFlashTitle(flash.title)}
        {this.renderFlashMessage(flash.message)}
      </div>
    );
  }

  renderFlashTitle = (title) => {
    if (!title) {
      return null;
    }

    return (
      <div className="title">{title}</div>
    );
  }

  renderFlashMessage = (message) => {
    if (!message) {
      return null;
    }

    return (
      <div className="message" title={message}>{message}</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAdditionalFieldsModeButtonClick = () => {
    this.triggerCardRowClick();
    this.triggerSelectAdvancedEditMode();
  }

  handleAddSoundButtonClick = (e) => {
    e.stopPropagation();

    this.triggerTooltipClose();

    if (!this.props.isCurrentCard) {
      this.triggerCardRowClick();
    }

    if (!this.props.currentUser?.flags?.isPro) {
      e.preventDefault();

      this.triggerAddMediaPaywallModalOpen();
      return false;
    }
  }

  handleAddSoundButtonMouseEnter = () => {
    this.triggerTooltipOpen({
      content: 'Add sound (MP3) to your Flashcard',
      elem: this.addSoundButtonElem,
      position: 'top',
    });
  } 

  handleAddSoundButtonMouseLeave = () => {
    this.triggerTooltipClose();
  } 

  handleAddImageButtonClick = (e) => {
    e.stopPropagation();

    this.triggerTooltipClose();

    if (!this.props.isCurrentCard) {
      this.triggerCardRowClick();
    }

    if (!this.props.currentUser?.flags?.isPro) {
      e.preventDefault();
      
      this.triggerAddMediaPaywallModalOpen();
      return false;
    }
  }

  handleAddImageButtonMouseEnter = () => {
    this.triggerTooltipOpen({
      content: 'Add an image to your Flashcard',
      elem: this.addImageButtonElem,
      position: 'top',
    });
  } 

  handleAddImageButtonMouseLeave = () => {
    this.triggerTooltipClose();
  } 

  handleCardFieldChange = (e) => {
    const target = e.target;
    const fieldId = (e.isPseudoEventObject) ? target.key : target.getAttribute('name') || target.getAttribute('id');
    let value = target.value;

    if (fieldId == 'qMdPrompt') {
      value = value.slice(0, PROMPT_CHAR_LIMIT);
    }

    if ((fieldId == 'qMdClarifier' || fieldId == 'aMdClarifier')) {
      value = value.slice(0, CLARIFIER_CHAR_LIMIT);
    }

    this.props.onFieldChange(fieldId, value);
  }

  handleConcealButtonClick = () => {
    this.triggerRevealCardFaceRequest();
  }

  handleDragEnd = () => {
    console.log('in SmartCardFace.handleDragEnd');
  }

  handleEditButtonClick = () => {
    const card = this.props.card;
    const deck = this.props.deck;

    this.triggerEditCardModeRequest();
  }

  handleFieldModeChange = (mode) => {
    if (mode == 'writeable' && !this.props.isCurrentCard) {
      this.triggerCardRowClick();
    }
  }

  handleFileDrop = (e) => {
    if (!this.props.currentUser?.flags?.isPro) {
      this.triggerAddMediaPaywallModalOpen();
      return false;
    }

    if (this.props.editorHasChanges && !this.props.isCurrentCard) {
      return false;
    }

    if (!this.props.isCurrentCard) {
      this.triggerCardRowClick();
    }

    eachDataTransferFile(e, this.addFileAttachment);
  }

  handleFileInputChange = (e) => {
    if (!this.props.currentUser?.flags?.isPro) {
      this.triggerAddMediaPaywallModalOpen();
      return false;
    }

    if (this.props.editorHasChanges && !this.props.isCurrentCard) {
      return false;
    }

    if (!this.props.isCurrentCard) {
      this.triggerCardRowClick();
    }

    this.addFileAttachment(e.target.files[0]);
  };

  handleImageClick = (e, fullSizeImageUrl) => {
    e.stopPropagation();

    if (fullSizeImageUrl && !this.props.isBlurred) {
      this.triggerImageViewerOpen(fullSizeImageUrl)
    }
  }

  handlePreviewHtmlClick = (e) => {
    if (e && e.target && e.target.classList.contains('scf-img-dsp')) {
      e.stopPropagation();

      const fullSizeImageUrl = this.getFullSizeImageUrl() || this.getImageUrl();

      if (fullSizeImageUrl) {
        this.triggerImageViewerOpen(fullSizeImageUrl)
      }
    }
  }

  handleRemoveImageButtonClick = () => {
    if (!this.props.isCurrentCard) {
      this.triggerCardRowClick();
    }

    this.triggerConfirmRemoveMediaModalOpen('image', () => {
      this.removeFileAttachment('image');
    });
  }

  handleRemoveSoundButtonClick = () => {
    if (!this.props.isCurrentCard) {
      this.triggerCardRowClick();
    }

    this.triggerConfirmRemoveMediaModalOpen('sound', () => {
      this.removeFileAttachment('sound');
    });
  }

  handleUpgradeRequest = () => {
    this.triggerUpgradeModalOpen('Access to these Flashcards', 'preview');
  };

  handleWindowResize = (e) => {
    this.setBaseFontSize();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerAddMediaPaywallModalOpen = () => {
    this.triggerUpgradeModalOpen('Adding images or sounds', 'card_media', 'list-7');
  }

  triggerBadMediaTypeModalOpen = () => {
    let validFormatsMessage = 'Valid formats- Image: JPG, PNG, GIF. Audio: MP3.';

    if (this.IS_SVG_ENABLED) {
      validFormatsMessage = 'Valid formats- Image: JPG, PNG, GIF, SVG. Audio: MP3.';
    }

    this.triggerInfoModalOpen({
      title: 'Unsupported File Type',
      message: validFormatsMessage,
      resolveButtonText: 'Got it',
    });
  }

  triggerRevealCardFaceRequest() {
    const newFace = (this.props.face == 'answer') ? 'question' : 'answer';

    EventManager.emitEvent('smart-card:reveal-card-face-requested', {
      face: newFace,
    });
  }

  triggerConfirmRemoveMediaModalOpen = (mediaType, callback) => {
    EventManager.emitEvent('caution-modal:open', {
      actionText: `remove this ${mediaType} from your card`,
      resolveButtonText: `Yes, remove ${mediaType}`,
      onResolution: callback,
    });
  }

  triggerConfirmRemoveMediaModalClose = () => {
    EventManager.emitEvent('caution-modal:close', {});
  }

  triggerCardRowClick = (data) => {
    EventManager.emitEvent('current-card:change-request', {
      cardId: this.props.card.cardId,
      // tabId: this.props.context,
    });
  }

  triggerEditCardModeRequest = () => {
    const card = this.props.card;

    EventManager.emitEvent('smart-card:edit-card-mode-request', {
      cardId: card.cardId,
      deckId: card.deckId,
      packId: card.packId,
    });
  }

  triggerFileTooLargeModalOpen = () => {
    this.triggerInfoModalOpen({
      message: 'Files must be less than 5MB',
      title:   'File Too Large',
      resolveButtonText: 'Got it',
    });
  }

  triggerImageViewerOpen = (imageUrl) => {    
    EventManager.emitEvent('image-viewer:open', {
      url: imageUrl,
    });
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerInfoModalClose(viewProps) {
    EventManager.emitEvent('info-modal:close', viewProps);
  }

  triggerSelectAdvancedEditMode = () => {
    EventManager.emitEvent('smart-card:select-advanced-edit-mode', {});
  }

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };

  triggerUpgradeModalOpen(desiredAction, paywall, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
      featuresList:  featuresList,
    });
  }

  /*
  ==================================================
   PUBLISHERS
  ==================================================
  */

  publishBaseFontSize = (fontSize) => {
    EventManager.emitEvent('smartCardFace:baseFontSize', {fontSize: fontSize});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  addFileAttachment = (file) => {    
    if (file.size > DEFAULT_FILE_SIZE_LIMIT) {
      this.triggerFileTooLargeModalOpen();
      return false;
    }

    const mimeType = file.type;
    let mediaType = null;
    let acceptedImageFormats = DEFAULT_ACCEPTABLE_FILE_MIME_TYPES['image'];

    if (this.IS_SVG_ENABLED) {
      acceptedImageFormats.push('image/svg+xml');
    }

    if (acceptedImageFormats.indexOf(mimeType) != -1) {
      mediaType = 'Image';
    } else if (mimeType === 'audio/mpeg') {
      mediaType = 'Sound';
    }

    if (!mediaType) {
      this.triggerBadMediaTypeModalOpen(file.name);
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onabort = () => this.uploadError(reader.error);
    reader.onerror = () => this.uploadError(reader.error);
    reader.onloadend = () => this.updateCardMediaField(mediaType, reader.result);
  }

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.dragOverTimeout);
    clearTimeout(this.viewportStyleTimeout);
  }

  convertMarkdownToHtml = (markdown) => {
    const convertedHtml = this.converter.makeHtml(markdown);
    const sanitizedHtml = sanitizeHtml(convertedHtml, {
      allowedTags: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
    });

    return sanitizedHtml;
  }

  getBodyFieldLength = () => {
    const card = this.props.card;
    const face = this.props.face;
    const facePrefix = this.getFacePrefix();
    const fieldKey = (this.props.format == 'md') ? `${facePrefix}MdBody` : face;
    let value = card[fieldKey];

    if (!value) {
      return 0;
    }

    if (this.props.format == 'md') {
      value = removeMd(value);
    }

    const trailingSpaceIndex = value.indexOf('&nbsp;');

    if (trailingSpaceIndex != -1) {
      value = value.substring(0, trailingSpaceIndex);
    }

    return value.slice(0, -1).length;
  }

  getBodyScfClassName = (bodyFieldLength) => {
    if (bodyFieldLength <= SCF_BODY_CHAR_LIMIT['scf-body-xl']) {
      return 'scf-body-xl'
    }

    if (bodyFieldLength <= SCF_BODY_CHAR_LIMIT['scf-body-l']) {
      return 'scf-body-l'
    }

    if (bodyFieldLength <= SCF_BODY_CHAR_LIMIT['scf-body-m']) {
      return 'scf-body-m'
    }

    return 'scf-body'
  }

  getClarifierScfClassName = (bodyFieldLength) => {
    if (bodyFieldLength <= SCF_BODY_CHAR_LIMIT['scf-body-m']) {
      return 'scf-clarifier-short'
    }

    return 'scf-clarifier'
  }

  getCurrentBodyFieldHeight = () => {
    const smartCardFaceSelector = `${CARD_FACE_SELECTOR}.${this.props.face}-face`;
    const scf = document.querySelector(smartCardFaceSelector);
    const bodyField = scf.querySelector(BODY_FIELD_SELECTOR);

    return bodyField.getBoundingClientRect().height;
  }

  getFacePrefix = () => {
    return this.props.face == 'question' ? 'q' : 'a';
  }

  getFullSizeImageUrl = () => {
    const fullSizeImageUrl = (this.props.face == 'answer') ? this.props.card.aOriginalImageUrl || this.props.card.aOriginalImageURL || null : this.props.card.qOriginalImageUrl || this.props.card.qOriginalImageURL || null;

    return fullSizeImageUrl;
  }

  getImageUrl = () => {
    const imageUrl = (this.props.face == 'answer') ? this.props.card.aImageUrl || this.props.card.aImageURL || null : this.props.card.qImageUrl || this.props.card.qImageURL || null;

    return imageUrl;
  }

  getMediaUrlKey = (mediaType) => {
    const upperMediaType = StringHelper.toTitleCase(mediaType);
    const facePrefix = this.getFacePrefix();

    return `${facePrefix}${upperMediaType}Url`;
  }

  hasAdditionalFields = () => {
    const card = this.props.card;
    const facePrefix = this.getFacePrefix();

    return (card[`${facePrefix}MdPrompt`] || card[`${facePrefix}MdClarifier`] || card[`${facePrefix}ImageCaption`] || card[`${facePrefix}MdFootnote`]);
  }

  hasImage = () => {
    return !!(this.props.card[this.getMediaUrlKey('image')]);
  }

  monitorDragOver = () => {
    document.addEventListener('dragover', function(){
      clearTimeout(this.dragOverTimeout);
      this.dragOverTimeout = setTimeout(this.handleDragEnd, 200);
    }, false);
  }


  removeFileAttachment = (mediaType) => {
    this.updateCardMediaField(mediaType, 'destroy');
    this.triggerConfirmRemoveMediaModalClose();
  }

  reportBaseFontSize = () => {
    if (this._isMounted) {
      const cardSlider = document.querySelector('.study-card-table .card-slider');

      if (cardSlider) {
        const fontSize = cardSlider.style.fontSize;

        if (fontSize) {
          this.publishBaseFontSize(parseInt(fontSize));
        }
      }
    }
  }

  setBaseFontSize = () => {
    const smartCardFaceRect = this.elem.getBoundingClientRect();
    let fontSize = this.state.baseFontSize;

    if (smartCardFaceRect) {
      fontSize = UiHelper.getSmartCardBaseFontSize(smartCardFaceRect);
    }

    this.setState({
      baseFontSize: fontSize,
    });
  }

  startKeyDownMonitor = () => {
    if (this._isMounted) {
      document.addEventListener('keydown', this.props.onKeyDown);
    }
  }

  startWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.addEventListener('resize', (e) => UiHelper.debounce(this.handleWindowResize(e), 250));
    }
  }

  stopKeyDownMonitor = () => {
    if (this._isMounted) {
      document.removeEventListener('keydown', this.props.onKeyDown);
    }
  }

  stopWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.removeEventListener('resize', (e) => UiHelper.debounce(this.handleWindowResize(e), 250));
    }
  }

  updateCardMediaField = (mediaType, location) => {
    const fieldId = this.getMediaUrlKey(mediaType);
    const shouldSave = (location == 'destroy');

    this.props.onFieldChange(fieldId, location, shouldSave);
  }

  uploadError = (err) => {
    this.triggerFileUploadErrorFlashOpen();
  };
}

SmartCardFace.propTypes = PT;

export default SmartCardFace;
