
import EventManager           from '@brainscape/event-manager';

import pack                   from '_models/pack';
import packDeck               from '_models/packDeck';
import packDeckTransform      from '_models/packDeckTransform';
import packStatTransform      from '_models/packStatTransform';


const currentPackDecksConcern = {

  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch(userId, pack) {
    const packId = pack.packId;
    const currentPack = {...pack};

    return new Promise((resolve, reject) => {
      try {
        packDeck.index(packId).then(decksData => {
          currentPack.decks = decksData.decks;
          currentPack.deckIds = decksData.deckIds;

          packDeckTransform.index(userId, packId, currentPack.deckIds).then(deckTransforms => {
            currentPack.localDeckTransforms = deckTransforms;

            const transformedStats = packStatTransform.show(currentPack);

            currentPack.transformedStats = transformedStats;

            packDeck.setCachedIndex(currentPack.packId, currentPack.decks);

            const currentPackDeckData = {
              currentPack: currentPack,
            };

            this.publishPackDecksRetrieved(currentPackDeckData);

            resolve(currentPackDeckData);
          });
        }).catch(err => {
          console.error('Problem fetching pack decks data. packId, err:', packId, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  // get checks session store for cached objects, publishes immediately to allow display, then refreshes cached copy by conducting fetches against the API
  get(userId, pack) {
    const packId = pack.packId;
    const currentPack = {...pack};

    return new Promise((resolve, reject) => {
      try {
        const currentPackDecks = packDeck.getCachedIndex(packId);

        if (currentPackDecks) {
          currentPack.decks = currentPackDecks;
          currentPack.deckIds = currentPackDecks.map(deck => deck.deckId);

          packDeckTransform.index(userId, packId, currentPack.deckIds).then(deckTransforms => {
            currentPack.localDeckTransforms = deckTransforms;

            const transformedStats = packStatTransform.show(currentPack);

            currentPack.transformedStats = transformedStats;

            const currentPackDeckData = {
              currentPack: currentPack,
              isFromCache: true,
            };

            this.publishPackDecksRetrieved(currentPackDeckData);
          });
        }

        this.fetch(userId, currentPack).then(currentPackDeckData => {
          resolve(currentPackDeckData);
        }).catch(err => {
          console.error('Problem getting pack decks data. packId, err:', packId, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  handlePackDecksReordered(currentPack, eventData) {
    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;

    updatedPack.decks = eventData.decks;
    updatedPack.deckIds = eventData.deckIds;

    pack.setCachedItem(updatedPackId, updatedPack);
    packDeck.setCachedIndex(updatedPackId, updatedPack.decks);

    return {
      currentPack: updatedPack,
      currentPackId: updatedPackId,
    };
  },

  handlePackDecksReorderRequest(currentPack, eventData) {
    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;

    const updatedPackDecks = [...updatedPack.decks];
    const updatedPackDeckIds = [...updatedPack.deckIds];

    const reorderedDeckIds = eventData.reorderedDeckIds;

    updatedPackDecks.sort((a, b) => reorderedDeckIds.indexOf(a.deckId) - reorderedDeckIds.indexOf(b.deckId));
    updatedPackDeckIds.sort((a, b) => reorderedDeckIds.indexOf(a) - reorderedDeckIds.indexOf(b));

    updatedPack.decks = updatedPackDecks;
    updatedPack.deckIds = updatedPackDeckIds;

    pack.setCachedItem(updatedPackId, updatedPack);
    packDeck.setCachedIndex(updatedPackId, updatedPack.decks);

    return {
      currentPack: updatedPack,
      currentPackId: updatedPackId,
    };
  },

  handleDeckRemoved(currentPack, eventData) {
    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;
    const deckId = eventData.deckId;
    const deckIndex = updatedPack.deckIds.indexOf(deckId);

    if (deckIndex == -1) {
      return false;
    }

    updatedPack.deckIds.splice(deckIndex, 1);
    updatedPack.deckRows.splice(deckIndex, 1);
    updatedPack.decks.splice(deckIndex, 1);
    updatedPack.stats = {...updatedPack.stats, ...eventData.packStats};

    const selectionIndex = updatedPack.localDeckTransforms?.selections.ids?.indexOf(deckId);

    if (selectionIndex && selectionIndex != -1) {
      updatedPack.localDeckTransforms.selections.ids?.splice(selectionIndex, 1);
    }

    const transformedStats = packStatTransform.show(updatedPack);
    updatedPack.transformedStats = transformedStats;

    pack.setCachedItem(updatedPackId, updatedPack);
    packDeck.setCachedIndex(updatedPackId, updatedPack.decks);

    return {
      currentPack: updatedPack,
    }
  },

  handleDeckUpdated(currentPack, eventData) {
    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;
    const updatedDeck = eventData.deck;
    const deckIndex = updatedPack.deckIds.indexOf(updatedDeck.deckId);

    if (deckIndex == -1) {
      return false;
    }

    updatedPack.decks[deckIndex] = {...updatedPack.decks[deckIndex], ...updatedDeck};

    pack.setCachedItem(updatedPackId, updatedPack);

    return {
      currentPack: updatedPack,
    }
  },

  handlePackDeckSelectionsUpdated(currentPack, eventData) {
    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;

    const transforms = {...updatedPack.localDeckTransforms};
    transforms.selections = eventData.selections || transforms.selections;

    updatedPack.localDeckTransforms = transforms;

    const transformedStats = packStatTransform.show(updatedPack);
    updatedPack.transformedStats = transformedStats;

    pack.setCachedItem(updatedPackId, updatedPack);

    return {
      currentPack: updatedPack,
    };
  },

  publishPackDecksRetrieved(currentPackDeckData) {
    EventManager.emitEvent('pack-decks:retrieved', currentPackDeckData);
  },
};

export default currentPackDecksConcern;
