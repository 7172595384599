
import {
  EditButton,
}                                     from '_views/shared/IconButton';

import EventManager                   from '@brainscape/event-manager';

import DynamicTooltipIcon                   from '_views/shared/DynamicTooltipIcon';
import PropTypes                      from 'prop-types';
import RadioButtonsField              from '_views/shared/RadioButtonsField';
import React                          from 'react';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                            : PropTypes.string,
  hasDefaultStudyModeResource           : PropTypes.bool,
  isEditingResource                     : PropTypes.bool,
  isEditingSettings                     : PropTypes.bool,
  isPackMetadataEditable                : PropTypes.bool,
  isPreviewing                          : PropTypes.bool,
  isProcessingDefaultStudyModeUpdate    : PropTypes.bool,
  isProcessingPrivacyUpdate             : PropTypes.bool,
  isProcessingRoleUpdate                : PropTypes.bool,
  metadata                              : PropTypes.object,
  onUpdateDefaultStudyModeRequest       : PropTypes.func,
  onUpdatePrivacyRequest                : PropTypes.func,
  onUpdateRoleRequest                   : PropTypes.func,
  pack                                  : PropTypes.object,
  segmentId                             : PropTypes.node,         
};


class PackAboutSettingsSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headlineText: "",
    }

    this.privacyOptions = [
      {label: 'Public', value: 'public', className: 'public-option'},
      {label: 'Private', value: 'private', className: 'private-option'},
    ];

    this.roleOptions = [
      {label: 'Instructor', value: 'Instructor'},
      {label: 'Student', value: 'Student'},
    ];

    this.studyMixOptions = [
      {value: 'progressive', label: 'Progressive'},
      {value: 'random', label: 'Random'},
    ];
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!(this.props.isPackMetadataEditable) || this.props.isPreviewing) {
      return null;
    }

    const requiresProClass = !this.props.currentUser.flags.isPro ? 'requires-pro' : '';

    return (
      <div className="segment about-tab-segment pack-about-settings-segment">

        <div className="segment-anchor" id={this.props.segmentId}></div>

        <div className="segment-header about-tab-segment-header settings-header">
          <div className="segment-heading about-tab-segment-heading settings-heading">Settings</div>
        </div>

        <div className="settings-controls">
          {this.renderRoleSettingsControl()}
          {this.renderStudyMixTypeSettingsControl()}
          {this.renderPrivacySettingsControl()}
        </div>
      </div>
    );
  }

  renderRoleSettingsControl() {
    const isProcessingClass = (this.props.isProcessingRoleUpdate) ? 'is-processing' : '';
    const classes = toClassStr(['settings-control role-setting', isProcessingClass]);

    return (
      <div className={classes}>
        <div className="heading-and-tooltip">
          <div className="setting-heading">Your Role</div>

          <DynamicTooltipIcon 
            actions={null}
            body="This pertains to your role in this particular class.  Are you learning it yourself, or teaching others?"
            hasDismissButton={true}
            heading="Your role"
            position="right"
            subHeading={null}
            isMobileViewportSize={this.props.isMobileViewportSize}
          />
        </div>

        <RadioButtonsField
          addClasses="setting-buttons pack-role-buttons"
          buttons={this.roleOptions}
          isDisabled={this.isEditingOtherResource()}
          isInline={true}
          name="roleOption"
          onClick={this.handleRoleButtonClick}
          value={this.props.metadata?.global?.role?.value}
        />
      </div>
    );
  }

  renderStudyMixTypeSettingsControl() {
    const isProcessingClass = (this.props.isProcessingDefaultStudyModeUpdate) ? 'is-processing' : '';
    const classes = toClassStr(['settings-control study-mix-type-setting', isProcessingClass]);
    const defaultStudyMixMode = ((this.props.hasDefaultStudyModeResource && this.props.metadata.global.default_study_mode.value == 'random') ? 'random' : 'progressive');

    return (
      <div className={classes}>
        <div className="heading-and-tooltip">

          <div className="setting-heading">Default Study Mix Type</div>

          <DynamicTooltipIcon 
            actions={null}
            addClasses="study-mix-tooltip"
            body={this.renderStudyMixTypesTooltipBody()}
            hasDismissButton={true}
            heading={'About Study Mix Types'}
            position="right"
            subHeading={null}
            isMobileViewportSize={this.props.isMobileViewportSize}
          />
        </div>

        <RadioButtonsField
          addClasses="setting-buttons study-mix-buttons"
          buttons={this.studyMixOptions}
          isDisabled={this.isEditingOtherResource()}
          isInline={true}
          name="studyMixOption"
          onClick={this.handleStudyMixButtonClick}
          value={defaultStudyMixMode}
        />
      </div>
    );
  }

  renderStudyMixTypesTooltipBody() {
    return (
      <>
        <p className="body-text"><strong>'Progressive'</strong> selects cards from decks in the order they appear. So, learners will not see cards from the 2nd deck until they've mostly mastered the 1st deck.  Once they've moved to a later deck, cards from earlier decks will still repeat occasionally.</p>
        <p className="body-text"><strong>'Random'</strong> will select from a pool of cards across all selected decks. The repetition priority is still given to the lower-confidence items, but new/un-seen cards may be chosen from any position in any of the selected decks.</p>
        <p className="body-text">This setting sets the default mode. Learners can always switch between mix types.</p>
      </>
    );
  }

  renderPrivacySettingsControl() {
    const isProcessingClass = (this.props.isProcessingPrivacyUpdate) ? 'is-processing' : '';
    const requiresProClass = !this.props.currentUser.flags.isPro ? 'requires-pro' : '';
    const classes = toClassStr(['settings-control privacy-setting', isProcessingClass, requiresProClass]);

    return (
      <div className={classes}>
        <div className="heading-and-tooltip">
          <div className="setting-heading">Class Visibility</div>

          <DynamicTooltipIcon 
            actions={null}
            addClasses="class-visibility-tooltip"
            body="Marking your class as Private will remove it from the Brainscape market and will prevent anyone else from joining without your explicit invitation. Other users will also need Brainscape Pro in order to access it."
            hasDismissButton={true}
            heading={'About Study Mix Types'}
            position="left"
            subHeading={null}
            isMobileViewportSize={this.props.isMobileViewportSize}
          />
        </div>

        <RadioButtonsField
          addClasses="setting-buttons pack-privacy-buttons"
          buttons={this.privacyOptions}
          isDisabled={this.isEditingOtherResource()}
          isInline={true}
          name="privacyOption"
          onClick={this.handlePrivacyButtonClick}
          value={this.props.pack.flags.isPrivate ? 'private' : 'public'}
        />
      </div>
    );
  }
      

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handlePrivacyButtonClick = (value) => {
    this.props.onUpdatePrivacyRequest(value);
  }

  handleRoleButtonClick = (value) => {
    this.props.onUpdateRoleRequest(value);
  }

  handleStudyMixButtonClick = (value) => {
    this.props.onUpdateDefaultStudyModeRequest(value);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  isEditingOtherResource = () => {
    return (this.props.isEditingResource && !this.props.isEditingSettings);
  }
}

PackAboutSettingsSegment.propTypes = PT;

export default PackAboutSettingsSegment;
