
import cardClipboard          from '_models/cardClipboard';
import EventManager           from '@brainscape/event-manager';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import packDuplicate          from '_models/packDuplicate';
import React                  from 'react';
import Tracker                from '_utils/Tracker';

import {toClassStr}           from '_utils/UiHelper';


const MENU_OPTIONS_HASH = {
  editCard: {id: 'editCard', tag: 'edit-card', label: 'Edit this Card', onlyEditor: true,},
  suggestChange: {id: 'suggestChange', tag: 'suggest-change', label: 'Suggest a Change to this Card', onlyNonEditor: true, onlyFullyStudyable: true},
  duplicateClass: {id: 'duplicateClass', tag: 'duplicate-class', label: 'Duplicate Class (to edit on my own)', onlyPro: true, onlyPublic: true, onlyUgc: true, paywall: 'duplicate_class'},
  copyCard: {id: 'copyCard', tag: 'copy-card', label: 'Copy Card (to paste into a Deck)', onlyPro: true, onlyPublic: true, onlyUgc: true, paywall: 'copy_study_card'},
  shareCard: {id: 'shareCard', tag: 'share-card', label: 'Share Card By Link', onlyPublic: true},
};


class StudyCardOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingOptionAction: false,
      menuOptions: null,
    };

    /*
      this.props:
        addClasses,
        cardId,
        currentUser,
        deckId,
        pack,
        packId,
        isCardEditable,
        isUserPro,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.updateUserOptions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['study-card-options-button', this.props.addClasses]);

    return (
      <div className={classes}>
        <OptionsMenuButton
          addClasses="no-option-icons"
          iconType="edit-options"
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.isUserPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={this.handleOptionClick}
          openPosition={this.props.openPosition || 'bottom'}
          tooltipContent={this.props.tooltipContent || "Advanced Card Options"}
          tooltipPosition={this.props.tooltipPosition || "left"}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick = (optionId) => {
    const requiresPro = MENU_OPTIONS_HASH[optionId].onlyPro;

    if (requiresPro && !this.props.isUserPro) {
      const desiredAction = MENU_OPTIONS_HASH[optionId].label;
      const paywall = MENU_OPTIONS_HASH[optionId].paywall;
      const featuresList = MENU_OPTIONS_HASH[optionId].featuresList;

      this.showUpgradeModal(desiredAction, paywall, featuresList);

    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleOptionActionRequest = (optionId) => {
    switch (optionId) {
      case 'editCard':
        this.triggerEditCardModeRequest();
      break;
      case 'suggestChange':
        this.triggerSuggestEditModalSetOpen();
      break;
      case 'copyCard':
        this.copyCard();
      break;
      case 'duplicateClass':
        this.duplicateClass();
      break;
      case 'shareCard':
        this.shareCardByLink();
      break;
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerEditCardModeRequest = () => {
    EventManager.emitEvent('smart-card:edit-card-mode-request', {
      cardId: this.props.cardId,
      deckId: this.props.deckId,
      packId: this.props.packId,
    });
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerShareCardByLinkModalOpen = () => {
    EventManager.emitEvent('share-card-by-link-modal:open', {
      cardId: this.props.cardId,
      deckId: this.props.deckId,
      packId: this.props.packId,
    });
  }

//   triggerSharePackModalSetEmailOpen = () => {
//     EventManager.emitEvent('share-pack-modal-set:open', {
//       currentUser: this.props.currentUser,
//       hasBackOption: false,
//       pack: this.props.pack,
//       phase: 'email',
//     });
//   }
// 
//   triggerSharePackModalSetOpen = () => {
//     EventManager.emitEvent('share-pack-modal-set:open', {
//       currentUser: this.props.currentUser,
//       pack: this.props.pack,
//     });
//   }

  triggerSuggestEditModalSetOpen = () => {
    EventManager.emitEvent('suggest-edit-modal-set:open', {
      cardContentType: 'md',
      cardId: this.props.cardId,
      deckId: this.props.deckId,
      isUserPro: this.props.isUserPro,
      packId: this.props.packId,
    });
  };

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type='success', duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }

  triggerUpgradeModalOpen = (desiredAction, paywall, featuresList) => {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      featuresList:  featuresList,
      paywall:       paywall,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  copyCard = () => {
    const {packId, deckId, cardId} = this.props;
    const cardIds = [cardId];

    this.setState({
      isProcessingOptionAction: true,
    });

    cardClipboard.copy(packId, deckId, cardIds).then(() => {
      this.setState({
        isProcessingOptionAction: false,
      }, () => {
        this.triggerToastOpen('Card copied for paste into Deck');
      });
    });
  }

  duplicateClass = () => {
    this.setState({
      isProcessingOptionAction: true,
    });

    packDuplicate.create(this.props.pack.packId).then(() => {
      this.triggerInfoModalOpen({
        title: 'Class Duplication Started',
        message: "We're creating a copy of this class and all of its decks. This will appear as a new class in your Classes. (This may take a few minutes. Feel free to refresh page to see if it's done.)",
        resolveButtonText: 'Got it',
        onCloseRequest: () => {
          this.setState({
            isProcessingOptionAction: false,
          });
        }
      });
    });
  }

  shareCardByLink = () => {
    this.triggerShareCardByLinkModalOpen();
  }

  showUpgradeModal = (desiredAction, paywall, featuresList) => {
    Tracker.trackPaywallWarning(paywall);
    this.triggerUpgradeModalOpen(desiredAction, paywall, featuresList);
  }

  updateUserOptions = () => {
    const menuOptionsForUser = Object.keys(MENU_OPTIONS_HASH).reduce((accumulator, menuOption) => {
      const permitPerEditRights = (MENU_OPTIONS_HASH[menuOption].onlyEditor) ? this.props.isCardEditable : true;
      const permitPerNonEditRights = (MENU_OPTIONS_HASH[menuOption].onlyNonEditor) ? !this.props.isCardEditable : true;
      const permitPerFullStudyableRights = (MENU_OPTIONS_HASH[menuOption].onlyFullyStudyable) ? this.props.pack?.permission != 'preview' : true;
      const permitPerPublicPack = (MENU_OPTIONS_HASH[menuOption].onlyPublic) ? !this.props.pack?.private : true;
      const permitPerUgc = (MENU_OPTIONS_HASH[menuOption].onlyUgc) ? !this.props.pack?.certified : true;

      if (permitPerEditRights && permitPerNonEditRights && permitPerFullStudyableRights && permitPerPublicPack && permitPerUgc) {
        accumulator.push(MENU_OPTIONS_HASH[menuOption]);
      }

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser,
    });
  }

}

export default StudyCardOptionsButton;
