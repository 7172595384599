
import model from '_core/newModel';

const BASE_PATH = 'me/user_cards';


const userCards = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index() {
    const path = 'me/user_cards';
    return model.index(path);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default userCards;
