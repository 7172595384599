
// substrate and utils
import EventManager                   from '@brainscape/event-manager';
import LoadingOverlay                 from '_views/shared/LoadingOverlay';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import {toClassStr}                   from '_utils/UiHelper';

// models
import pack                           from '_models/pack';
import packMetadata                   from '_models/packMetadata';

// sub-components
import PackAboutBrainscapeSegment     from '_views/pack-detail/desktop/about/PackAboutBrainscapeSegment';
import PackAboutBscAdminSegment       from '_views/pack-detail/desktop/about/PackAboutBscAdminSegment';
import PackAboutDescriptionSegment    from '_views/pack-detail/desktop/about/PackAboutDescriptionSegment';
import PackAboutHeadlineSegment       from '_views/pack-detail/desktop/about/PackAboutHeadlineSegment';
import PackAboutPurposeSegment        from '_views/pack-detail/desktop/about/PackAboutPurposeSegment';
import PackAboutRoleSettingSegment    from '_views/pack-detail/desktop/about/PackAboutRoleSettingSegment';
import PackAboutSettingsSegment       from '_views/pack-detail/desktop/about/PackAboutSettingsSegment';
import PackAboutSupplementalSegment   from '_views/pack-detail/desktop/about/PackAboutSupplementalSegment';
import PackAboutVideoSegment          from '_views/pack-detail/desktop/about/PackAboutVideoSegment';
import PackMetaField                  from '_views/pack-detail/desktop/about/PackMetaField';
import PillButton                     from '_views/shared/PillButton';
import SegmentNavigator               from '_views/shared/SegmentNavigator';
import SimpleTextButton               from '_views/shared/SimpleTextButton';
import TextareaField                  from '_views/shared/TextareaField';

// modals
import RequiredMetadataModal          from '_views/modals/RequiredMetadataModal';


const PT = {
  addClasses                      : PropTypes.string,
  authenticityToken               : PropTypes.string,
  isLoadingPackMetadata           : PropTypes.bool,
  metadata                        : PropTypes.object,
  onPackMetadataUpdated           : PropTypes.func,
  pack                            : PropTypes.object,           
  shouldShowAddMetadataPrompt     : PropTypes.bool,
};


class PackAboutSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditingBscAdminFields             : false,
      isEditingDescription                : false,
      isEditingHeadline                   : false,
      isEditingPurpose                    : false,
      isEditingRoleSetting                : false,
      isEditingSettings                   : false,
      isEditingSupplemental               : false,
      isEditingVideo                      : false,
      isMobileViewportSize                : false,
      isPreviewing                        : false,
      isProcessingBscAdminFieldsUpdate    : false,
      isProcessingDefaultStudyModeUpdate  : false,
      isProcessingDescriptionUpdate       : false,
      isProcessingHeadlineUpdate          : false,
      isProcessingPurposeUpdate           : false,
      isProcessingRoleUpdate              : false,
      isProcessingSupplementalUpdate      : false,
      isProcessingVideoUrlUpdate          : false,
      selectedMarkdownTab                 : 'write',
    };

    this._isMounted = false;

    this.bscAdminSegments = [
      {id: 'video', label: 'Intro Video'},
      {id: 'purpose', label: 'Purpose'},
      {id: 'supplemental', label: 'Supplemental Info'},
      {id: 'headline', label: 'Headline'},
      {id: 'description', label: 'Description'},
      {id: 'settings', label: 'Settings'},
      {id: 'bscAdmin', label: 'BSC Admin'},
    ]

    this.adminSegments = [
      {id: 'video', label: 'Intro Video'},
      {id: 'purpose', label: 'Purpose'},
      {id: 'supplemental', label: 'Supplemental Info'},
      {id: 'headline', label: 'Headline'},
      {id: 'description', label: 'Description'},
      {id: 'settings', label: 'Settings'},
    ]

    this.editSegments = [
      {id: 'video', label: 'Intro Video'},
      {id: 'headline', label: 'Headline'},
      {id: 'description', label: 'Description'},
      {id: 'purpose', label: 'Purpose'},
      {id: 'role', label: 'Role'},
      {id: 'brainscape', label: 'About Brainscape'},
    ]

    this.showSegments = [
      {id: 'video', label: 'Intro Video'},
      {id: 'headline', label: 'Headline'},
      {id: 'description', label: 'Description'},
      {id: 'purpose', label: 'Purpose'},
      {id: 'brainscape', label: 'About Brainscape'},
    ]
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    if (!this.props.pack.metadata || this.props.isLoadingPackMetadata) {
      return (
        <LoadingOverlay
          addClasses="section-loading-overlay"
          isOpen={true}
          message="Loading Class Info..."
          shouldTransitionIn={true}
          shouldTransitionOut={true}
        />
      );
    }

    const isEditingClass = (this.isEditingResource() && !this.state.isEditingSettings) ? 'is-editing' : '';
    const classes = toClassStr(['pack-about-section', isEditingClass, this.props.addClasses]);

    return (
      <section className={classes}>

        <div className="section-sidebar">
          <SegmentNavigator
            scrollElementId="detail-scroll-element"
            segmentsElementId="segments-element" 
            segments={this.getActiveSegments()}
          />
        </div>

        {this.renderSegments()}

        {this.renderRequiredPackMetadataModalSet()}

      </section>
    );
  }

  renderSegments() {
    if (this.isPackMetadataEditable() && !this.state.isPreviewing) {
      return (
        <div className="section-content" id="segments-element">
          {this.renderAdminBar()}
          {this.renderVideoSegment()}
          {this.renderPurposeSegment()}
          {this.renderSupplementalSegment()}
          {this.renderHeadlineSegment()}
          {this.renderDescriptionSegment()}
          {this.renderSettingsSegment()}
          {this.renderBscAdminSegment()}
        </div>
      );
    }

    return (
      <div className="section-content" id="segments-element">
        {this.renderAdminBar()}
        {this.renderVideoSegment()}
        {this.renderHeadlineSegment()}
        {this.renderDescriptionSegment()}
        {this.renderPurposeSegment()}
        {this.renderRoleSettingSegment()}
        {this.renderBrainscapeSegment()}
      </div>
    );
  }

  renderAdminBar() {
    if (!(this.isPackMetadataEditable())) {
      return null;
    }

    return (
      <div className="admin-bar">
        {this.renderPreviewToggle()}
        {this.renderEditStatus()}
      </div>
    );
  }

  renderPreviewToggle() {
    if (this.isEditingResource()) {
      return null;
    }

    const previewText = this.state.isPreviewing ? 'Exit Preview' : 'Preview';
    const isPreviewingClass = this.state.isPreviewing ? 'is-previewing' : '';
    const classes = toClassStr(['preview-toggle-button', isPreviewingClass]);

    return (
      <SimpleTextButton
        addClasses={classes}
        isDisabled={this.isEditingResource()}
        label={previewText}
        onClick={this.handlePreviewToggleButtonClick}
      />
    );
  }

  renderEditStatus() {
    if (!this.isEditingResource()) {
      return null;
    }

    return (
      <div className="edit-status">Editing {this.getCurrentEditResource()}</div>
    );
  }

  renderVideoSegment() {
    if (!this.hasResource('video_url') && !this.isPackMetadataEditable()) {
      return null;
    }

    if (this.state.isPreviewing && !this.hasResource('video_url')) {
      return null;
    }

    return (
      <PackAboutVideoSegment 
        hasVideoCaptionResource={this.hasResource('video_caption')}   
        hasVideoUrlResource={this.hasResource('video_url')}       
        isEditingResource={this.isEditingResource()}          
        isEditingVideo={this.state.isEditingVideo}  
        isMobileViewportSize={this.props.isMobileViewportSize}        
        isPackMetadataEditable={this.isPackMetadataEditable()}     
        isPreviewing={this.state.isPreviewing}               
        isProcessing={this.state.isProcessingVideoUpdate}
        metadata={this.props.pack.metadata}                    
        onAddVideoRequest={this.handleAddVideoRequest}          
        onCancelEditVideoRequest={this.handleCancelEditVideoRequest}
        onEditVideoRequest={this.handleEditVideoRequest}          
        onUpdateVideoRequest={this.handleUpdateVideoRequest}
        segmentId="video"         
      />
    );
  }

  renderHeadlineSegment() {
    return (
      <PackAboutHeadlineSegment
        hasHeadlineResource={this.hasResource('headline')}   
        isEditingHeadline={this.state.isEditingHeadline}          
        isEditingResource={this.isEditingResource()}
        isMobileViewportSize={this.props.isMobileViewportSize}        
        isPackMetadataEditable={this.isPackMetadataEditable()}     
        isPreviewing={this.state.isPreviewing}               
        isProcessing={this.state.isProcessingHeadlineUpdate}
        metadata={this.props.pack.metadata}                    
        onCancelEditHeadlineRequest={this.handleCancelEditHeadlineRequest}
        onEditHeadlineRequest={this.handleEditHeadlineRequest}          
        onUpdateHeadlineRequest={this.handleUpdateHeadlineRequest}
        pack={this.props.pack}
        segmentId="headline"         
      />
    );
  }  

  renderDescriptionSegment() {
    return (
      <PackAboutDescriptionSegment
        hasDescriptionResource={this.hasResource('description')}   
        isEditingDescription={this.state.isEditingDescription}          
        isEditingResource={this.isEditingResource()}
        isMobileViewportSize={this.props.isMobileViewportSize}        
        isPackMetadataEditable={this.isPackMetadataEditable()}     
        isPreviewing={this.state.isPreviewing}               
        isProcessing={this.state.isProcessingDescriptionUpdate}
        metadata={this.props.pack.metadata}                    
        onCancelEditDescriptionRequest={this.handleCancelEditDescriptionRequest}
        onEditDescriptionRequest={this.handleEditDescriptionRequest}          
        onUpdateDescriptionRequest={this.handleUpdateDescriptionRequest}
        segmentId="description"         
      />
    );
  }

  renderPurposeSegment() {
    return (
      <PackAboutPurposeSegment
        isEditingPurpose={this.state.isEditingPurpose}          
        isEditingResource={this.isEditingResource()}
        isMobileViewportSize={this.props.isMobileViewportSize}        
        isPackMetadataEditable={this.isPackMetadataEditable()}     
        isPreviewing={this.state.isPreviewing}               
        isProcessing={this.state.isProcessingPurposeUpdate}
        metadata={this.props.pack.metadata}                    
        onAddPurposeRequest={this.handleAddPurposeRequest}
        onCancelEditPurposeRequest={this.handleCancelEditPurposeRequest}
        onEditPurposeRequest={this.handleEditPurposeRequest}          
        onUpdatePurposeRequest={this.handleUpdatePurposeRequest}
        pack={this.props.pack} 
        segmentId="purpose"         
      />
    );
  }  
  
  renderSupplementalSegment() {
    return (
      <PackAboutSupplementalSegment
        isEditingSupplemental={this.state.isEditingSupplemental}          
        isEditingResource={this.isEditingResource()}
        isMobileViewportSize={this.props.isMobileViewportSize}        
        isPackMetadataEditable={this.isPackMetadataEditable()}     
        isPreviewing={this.state.isPreviewing}               
        isProcessing={this.state.isProcessingSupplementalUpdate}
        metadata={this.props.pack.metadata}                    
        onCancelEditSupplementalRequest={this.handleCancelEditSupplementalRequest}
        onEditSupplementalRequest={this.handleEditSupplementalRequest}          
        onUpdateSupplementalRequest={this.handleUpdateSupplementalRequest}
        segmentId="supplemental"         
      />
    );
  }  
  
  renderSettingsSegment() {
    return (
      <PackAboutSettingsSegment
        currentUser={this.props.currentUser}
        hasDefaultStudyModeResource={this.hasResource('default_study_mode')}
        isEditingSettings={this.state.isEditingSettings}          
        isEditingResource={this.isEditingResource()}
        isMobileViewportSize={this.props.isMobileViewportSize}        
        isPackMetadataEditable={this.isPackMetadataEditable()}     
        isPreviewing={this.state.isPreviewing}               
        isProcessingDefaultStudyModeUpdate={this.state.isProcessingDefaultStudyModeUpdate}
        isProcessingPrivacyUpdate={this.state.isProcessingPrivacyUpdate}
        isProcessingRoleUpdate={this.state.isProcessingRoleUpdate}
        metadata={this.props.pack.metadata}                    
        onUpdateRoleRequest={this.handleUpdateRoleRequest}
        onUpdatePrivacyRequest={this.handleUpdatePrivacyRequest}
        onUpdateDefaultStudyModeRequest={this.handleUpdateDefaultStudyModeRequest}
        pack={this.props.pack}   
        segmentId="settings"         
      />
    );
  }  
  
  renderRoleSettingSegment() {
    if (this.isPackMetadataEditable()) {
      return null;
    }

    if (!this.isPackRoleEditable()) {
      return null;
    }

    return (
      <PackAboutRoleSettingSegment
        hasDefaultStudyModeResource={this.hasResource('default_study_mode')}
        isEditingRoleSetting={this.state.isEditingRoleSetting}          
        isEditingResource={this.isEditingResource()}
        isMobileViewportSize={this.props.isMobileViewportSize}        
        isPackMetadataEditable={this.isPackMetadataEditable()}     
        isPackRoleEditable={this.isPackRoleEditable()}     
        isPreviewing={this.state.isPreviewing}               
        isProcessingDefaultStudyModeUpdate={this.state.isProcessingDefaultStudyModeUpdate}
        isProcessingPrivacyUpdate={this.state.isProcessingPrivacyUpdate}
        isProcessingRoleUpdate={this.state.isProcessingRoleUpdate}
        metadata={this.props.pack.metadata}                    
        onUpdateRoleRequest={this.handleUpdateRoleRequest}
        onUpdatePrivacyRequest={this.handleUpdatePrivacyRequest}
        onUpdateDefaultStudyModeRequest={this.handleUpdateDefaultStudyModeRequest}
        pack={this.props.pack}   
        segmentId="role"         
      />
    );
  } 

  renderBscAdminSegment() {
    if (!this.props.currentUser.flags.isBscAdmin) {
      return null;
    }

    return (
      <PackAboutBscAdminSegment
        isEditingBscAdminFields={this.state.isEditingBscAdminFields}          
        isEditingResource={this.isEditingResource()}
        isMobileViewportSize={this.props.isMobileViewportSize}        
        isPackMetadataEditable={this.isPackMetadataEditable()}     
        isPreviewing={this.state.isPreviewing}               
        isProcessing={this.state.isProcessingBscAdminFieldsUpdate}
        metadata={this.props.pack.metadata}                    
        onCancelEditBscAdminFieldsRequest={this.handleCancelEditBscAdminFieldsRequest}
        onEditBscAdminFieldsRequest={this.handleEditBscAdminFieldsRequest}          
        onUpdateBscAdminFieldsRequest={this.handleUpdateBscAdminFieldsRequest}
        pack={this.props.pack}
        segmentId="bscAdmin"         
      />
    );
  }   

  renderBrainscapeSegment() {
    return (
      <PackAboutBrainscapeSegment
        hasVideo={this.hasResource('video_url')}   
        segmentId="brainscape"         
      />
    );
  }

  renderRequiredPackMetadataModalSet() {
    if (!this.isPackRoleEditable()) {
      return null;
    }

    return (
      <RequiredMetadataModal 
        metadata={this.props.pack.metadata}
        onUpdatePackMetadataRequest={this.updatePackMetadata}
        pack={this.props.pack}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  }
  

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // Tab level handlers

  handlePreviewToggleButtonClick = () => {
    this.setState({
      isPreviewing: !this.state.isPreviewing,
    });
  }


  // Video segment handlers

  handleAddVideoRequest = () => {
    this.setState({
      isEditingVideo: true,
    }, () => {
      this.scrollToSegment('video');
    });
  }

  handleCancelEditVideoRequest = () => {
    this.setState({
      isEditingVideo: false,
    });
  }

  handleEditVideoRequest = () => {
    this.setState({
      isEditingVideo: true,
    }, () => {
      this.scrollToSegment('video');
    });
  }

  handleUpdateVideoRequest = (videoProps) => {
    const urlFieldId = this.props.pack.metadata?.global?.video_url?.field_id;
    const captionFieldId = this.props.pack.metadata?.global?.video_caption?.field_id;
    const metum = [
      {"field_id": urlFieldId, "value": videoProps.url}, 
      {"field_id": captionFieldId, "value": videoProps.caption}, 
    ]

    this.setState({
      isProcessingVideoUpdate: true,
    })

    this.updatePackMetadata({
      metum: metum,
      callback: () => {
        this.setState({
          isEditingVideo: false,
          isPreviewing: false,
          isProcessingVideoUpdate: false,
        })
      }
    });
  }


  // Headline segment handlers

  handleCancelEditHeadlineRequest = () => {
    this.setState({
      isEditingHeadline: false,
    });
  }

  handleEditHeadlineRequest = () => {
    this.setState({
      isEditingHeadline: true,
    }, () => {
      this.scrollToSegment('headline');
    });
  }

  handleUpdateHeadlineRequest = (headlineText) => {
    const fieldId = this.props.pack.metadata?.global?.headline?.field_id;

    this.setState({
      isProcessingHeadlineUpdate: true,
    })

    this.updatePackMetadata({
      fieldId: fieldId,
      value: headlineText,
      callback: () => {
        this.setState({
          isEditingHeadline: false,
          isPreviewing: false,
          isProcessingHeadlineUpdate: false,
        })
      }
    });
  }


  // Description segment handlers

  handleCancelEditDescriptionRequest = () => {
    this.setState({
      isEditingDescription: false,
    });
  }

  handleEditDescriptionRequest = () => {
    this.setState({
      isEditingDescription: true,
    }, () => {
      this.scrollToSegment('description');
    });
  }

  handleUpdateDescriptionRequest = (descriptionMarkdown) => {
    const fieldId = this.props.pack.metadata?.global?.description?.field_id;

    this.setState({
      isProcessingDescriptionUpdate: true,
    })

    this.updatePackMetadata({
      fieldId: fieldId,
      value: descriptionMarkdown,
      callback: () => {
        this.setState({
          isEditingDescription: false,
          isPreviewing: false,
          isProcessingDescriptionUpdate: false,
        })
      }
    });
  }


  // Purpose segment handlers

  handleCancelEditPurposeRequest = () => {
    this.setState({
      isEditingPurpose: false,
    });
  }

  handleEditPurposeRequest = () => {
    this.setState({
      isEditingPurpose: true,
    }, () => {
      this.scrollToSegment('purpose');
    });
  }

  handleAddPurposeRequest = (fieldId) => {
    this.setState({
      isProcessingPurposeUpdate: true,
    })

    this.updatePackMetadata({
      fieldId: fieldId,
      value: true,
      callback: () => {
        this.setState({
          isEditingPurpose: true, // leave form open for attribute entry
          isPreviewing: false,
          isProcessingPurposeUpdate: false,
        }, () => {
          this.scrollToSegment('purpose');
        });
      }
    });
  }

  handleUpdatePurposeRequest = (metum) => {
    this.setState({
      isProcessingPurposeUpdate: true,
    })

    this.updatePackMetadata({
      metum: metum,
      callback: () => {
        this.setState({
          isEditingPurpose: false,
          isPreviewing: false,
          isProcessingPurposeUpdate: false,
        })
      }
    });
  }


  // Supplemental Info segment handlers

  handleCancelEditSupplementalRequest = () => {
    this.setState({
      isEditingSupplemental: false,
    });
  }

  handleEditSupplementalRequest = () => {
    this.setState({
      isEditingSupplemental: true,
    }, () => {
      this.scrollToSegment('supplemental');
    });
  }

  handleUpdateSupplementalRequest = (metum) => {
    this.setState({
      isProcessingSupplementalUpdate: true,
    })

    this.updatePackMetadata({
      metum: metum,
      callback: () => {
        this.setState({
          isEditingSupplemental: false,
          isPreviewing: false,
          isProcessingSupplementalUpdate: false,
        })
      }
    });
  }


  // Settings segment handlers

  handleUpdateRoleRequest = (value) => {
    const fieldId = this.props.pack.metadata?.global?.role?.field_id;

    this.setState({
      isEditingSettings: true,
      isProcessingRoleUpdate: true,
    })

    this.updatePackMetadata({
      fieldId: fieldId,
      value: value,
      callback: () => {
        this.setState({
          isEditingSettings: false,
          isPreviewing: false,
          isProcessingRoleUpdate: false,
        })
      }
    });
  }

  handleUpdateDefaultStudyModeRequest = (value) => {
    const fieldId = this.props.pack.metadata?.global?.default_study_mode?.field_id;

    this.setState({
      isEditingSettings: true,
      isProcessingDefaultStudyModeUpdate: true,
    })

    this.updatePackMetadata({
      fieldId: fieldId,
      value: value,
      callback: () => {
        this.setState({
          isEditingSettings: false,
          isPreviewing: false,
          isProcessingDefaultStudyModeUpdate: false,
        })
      }
    });
  }

  handleUpdatePrivacyRequest = (value) => {
    const newPrivacyState = (value == 'private');

    if (newPrivacyState == this.props.pack.flags.isPrivate) {
      return false;
    }

    if (!this.props.currentUser.flags.isPro) {
      this.triggerUpgradeModalOpen({
        paywall: 'privacy',
        purpose: purpose,
        featuresList: 'list-3',
      });

    } else {
      this.setState({
        isEditingSettings: true,
        isProcessingPrivacyUpdate: true,
      })

      this.triggerEditPackPrivacyModalOpen(newPrivacyState);
    }
  }

  handlePackPrivacyChanged = () => {
    this.triggerRefreshPackDetailSection();
    this.triggerEditPackPrivacyModalClose();

    this.setState({
      isEditingSettings: false,
      isProcessingPrivacyUpdate: false,
    })
  }

  handlePackPrivacyUpdateCancelled = () => {
    this.setState({
      isEditingSettings: false,
      isProcessingPrivacyUpdate: false,
    })
  }


  // BSC Admin field segment handlers

  handleCancelEditBscAdminFieldsRequest = () => {
    this.setState({
      isEditingBscAdminFields: false,
    });
  }

  handleEditBscAdminFieldsRequest = () => {
    this.setState({
      isEditingBscAdminFields: true,
    }, () => {
      this.scrollToSegment('bscAdmin');
    });
  }

  handleUpdateBscAdminFieldsRequest = (creatorUserId) => {
    this.setState({
      isProcessingBscAdminFieldsUpdate: true,
    });

    const data = {
      userId: creatorUserId,
    };

    pack.update(this.props.pack.packId, data).then(() => {
      this.setState({
        isEditingBscAdminFields: false,
        isPreviewing: false,
        isProcessingBscAdminFieldsUpdate: false,
      });
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerEditPackPrivacyModalOpen(newPrivacyState) {
    EventManager.emitEvent('edit-pack-privacy-modal:open', {
      // authenticityToken       : this.props.authenticityToken,
      newPrivacyState         : newPrivacyState,
      pack                    : this.props.pack,
      onCloseRequest          : this.handlePackPrivacyUpdateCancelled,
      onPackPrivacyChanged    : this.handlePackPrivacyChanged,
    });
  }

  triggerEditPackPrivacyModalClose() {
    EventManager.emitEvent('edit-pack-privacy-modal:close', {});
  }

  triggerPrivacyUpgradeModalOpen() {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: 'Make Class Private',
      paywall:       'privacy',
    });
  }

  triggerUpgradeModalOpen(opts) {
    EventManager.emitEvent('upgrade-modal:open', {
      paywall: opts.paywall,
      purpose: opts.purpose,
      featuresList: opts.featuresList,
    });
  }

  triggerRefreshPackDetailSection() {
    EventManager.emitEvent('refresh-pack-detail-section', {});
  }

  triggerScrollToSegment(data) {
    EventManager.emitEvent('segment-navigator:select-segment', data);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getActiveSegments() {
    let segments = this.getUserSegments();

    const activeSegments = segments.filter((segment, index) => {
      if (segment.id == 'video' && !this.hasResource('video_url') && (this.props.pack.permission != 'admin' || this.state.isPreviewing)) {
        return false;
      }

      if (segment.id == 'settings' && (this.props.pack.permission != 'admin' || this.state.isPreviewing)) {
        return false;
      }

      return true;
    });

    return activeSegments;
  }

  getCurrentEditResource = () => {
    if (this.state.isEditingDescription) {
      return 'Description';
    }

    if (this.state.isEditingVideo) {
      return 'Video';
    }

    if (this.state.isEditingHeadline) {
      return 'Headline';
    }

    if (this.state.isEditingPurpose) {
      return 'Purpose';
    }

    if (this.state.isEditingSupplemental) {
      return 'Supplemental Info';
    }

    if (this.state.isEditingSettings) {
      return 'Settings';
    }

    if (this.state.isEditingBscAdminFields) {
      return 'BSC Admin';
    }

    return null;   
  }

  getPurpose = () => {
    let purposeLabel = null;

    if (this.props.pack.metadata && this.props.pack.metadata.purposes) {
      const purposes = this.props.pack.metadata.purposes;
      const purposeKeys = Object.keys(purposes);

      for (let i=0; i <= purposeKeys.length; i++) {
        const purposeKey = purposeKeys[i];
        const purpose = purposes[purposeKey];

        if (purpose && purpose.value == true) {
          purposeLabel = purpose.field_label;
          break;
        }
      }
    }

    return purposeLabel;
  }

  getResource = (resourceKey) => {
    if (this.hasResource(resourceKey)) {
      return this.props.pack.metadata?.global[resourceKey].value;
    } 

    return null;
  }

  getUserSegments = () => {
    if (this.state.isPreviewing) {
      return [...this.showSegments];
    }

    if (this.isPackMetadataEditable() && this.props.currentUser.flags.isBscAdmin) {
      return [...this.bscAdminSegments];
    }

    if (this.isPackMetadataEditable()) {
      return [...this.adminSegments];
    }

    if (this.isPackRoleEditable()) {
      return [...this.editSegments];
    }

    return [...this.showSegments];
  }

  hasResource = (resourceKey, testProps) => {
    const props = testProps || this.props;

    return !!(props.pack.metadata && props.pack.metadata.global && props.pack.metadata.global[resourceKey] && props.pack.metadata.global[resourceKey].value);
  }

  isEditingResource = () => {
    return (this.state.isEditingDescription || this.state.isEditingHeadline || this.state.isEditingVideo || this.state.isEditingPurpose || this.state.isEditingSupplemental || this.state.isEditingSettings || this.state.isEditingBscAdminFields);
  }

  isPackMetadataEditable = () => {
    const pack = this.props.pack;
    return (pack.permission == 'admin' && !pack.flags.isCertified);
  }

  isPackRoleEditable = () => {
    const pack = this.props.pack;
    const isEditable = (['admin'].indexOf(pack.permission) != -1) 
    return (!pack.flags.isCertified && isEditable);
  }

  scrollToSegment = (segmentId) => {
    this.triggerScrollToSegment({
      segmentId: segmentId,
      shouldRefreshSegments: true,
    })
  }

  updatePackMetadata = (opts) => {
    let metum = opts.metum;

    if (!metum) {
      metum = [{"field_id": opts.fieldId, "value": opts.value}];
    }

    packMetadata.update(this.props.pack.packId, metum).then(metadata => {
      if (opts.callback) {
        opts.callback();
      }
    });
  }
}

PackAboutSection.propTypes = PT;

export default PackAboutSection;
