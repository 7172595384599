
import CautionModalController              from '_controllers/modals/CautionModalController';
import CopyAndPlaceCardsModalSetController from '_controllers/modals/CopyAndPlaceCardsModalSetController';
import DeckPreviewModalController          from '_controllers/modals/DeckPreviewModalController';
import EditCardController                  from '_controllers/modals/EditCardController';
import ErrorModalController                from '_controllers/modals/ErrorModalController';
import InfoModalController                 from '_controllers/modals/InfoModalController';
import DialogModalController               from '_controllers/modals/DialogModalController';
import PackSuggestionsModalController      from '_controllers/modals/PackSuggestionsModalController';
import PropTypes                           from 'prop-types';
import React                               from 'react';
import ShareCardByLinkModalController      from '_controllers/modals/ShareCardByLinkModalController';
import SuggestEditModalSetController       from '_controllers/modals/SuggestEditModalSetController';
import UpgradeModalController              from '_controllers/modals/UpgradeModalController';

const PT = {currentUser: PropTypes.object};

class StudyModalControllers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <div className="modal-controllers">
        <DeckPreviewModalController 
          currentUser={this.props.currentUser}
        />
        <PackSuggestionsModalController />

        <EditCardController 
          currentUser={this.props.currentUser}
        />
        <ShareCardByLinkModalController />
        <SuggestEditModalSetController />

        <CopyAndPlaceCardsModalSetController />

        <CautionModalController />
        <ErrorModalController />
        <InfoModalController />
        <DialogModalController />
        <UpgradeModalController 
          currentUser={this.props.currentUser}
        />
      </div>
      // TODO: Order of controllers above dictates relative z-index of associated views. Regulate this more explicitly using css.
    );
  }
}

StudyModalControllers.propTypes = PT;

export default StudyModalControllers;
