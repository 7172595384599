
import model from '_core/newModel';

const categoryOption = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  index(isFullIndex=false) {
    const path = (isFullIndex) ? 'categories?all_cats=true' : 'categories/';

    return new Promise((resolve, reject) => {
      try {
        model.index(path).then(categoriesData => {

          const sortedCategories = categoriesData.sort((a, b) => {
            return (a.category.name > b.category.name) ? 1 : -1;
          });

          const options = sortedCategories.map(categoryData => {
            return {
              id: categoryData.category.id,
              label: `${categoryData.category.id} - ${categoryData.category.name}`,
            };
          });

          resolve(options);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },



  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default categoryOption;
