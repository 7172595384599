
import {apiPost}     from '_core/Api1';
import {metaContent} from '_utils/MetaContent';
import FormError     from './FormError';
import PillButton    from '_views/shared/PillButton';
import PropTypes     from 'prop-types';
import React         from 'react';
import TextField     from '_views/shared/TextField';
import StringHelper  from '_utils/StringHelper';


class ForgotForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email           : '', 
      isDisabled      : true,
      isProcessing    : false,
      formError       : null,
    };
  }

  render = () => {
    return (
      <form className='forgot-form' onSubmit={this.handleSubmit}>

        <TextField 
          addClasses="email"
          descriptor="Your E-mail"
          id="email"
          name="email"
          onChange={this.handleChange}
          type="email"
          value={this.state.email}
        />

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button reset-password-button"
            isDisabled={this.state.isDisabled}
            isProcessing={this.state.isProcessing}
            label="Reset Password"
            onClick={this.handleSubmit}
          />

          <input type="submit" className="hidden-submit-button" />
        </div>

        <div style={{height: '1.7em'}}>
          <FormError>{this.state.formError}</FormError>
        </div>
      </form>
    );
  }

  handleChange = (e) => {
    const email = e.target.value;
    const isDisabled = !StringHelper.isValidEmail(email);

    this.setState({
      email: e.target.value,
      formError: null,
      isDisabled: isDisabled,
    });
  }

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!StringHelper.isValidEmail(this.state.email)) {
      this.setState({
        isDisabled: true,
        formError: 'Please provide a valid e-mail address',
      });

      return false;
    }

    this.setState({
      isDisabled: false,
      isProcessing: true, 
      formError: null,
    });

    const params = {email: this.state.email};

    apiPost('password_reset', params).then((rs) => {
      alert(rs.msg);

      if (!this.props.shouldSuppressRedirect) {
        window.location = rs.redirect_to || '/';
        return;
      }

      if (this.props.onPasswordReset) {
        this.props.onPasswordReset();
      }
    }).catch((err) => {
      this.setState({
        isDisabled: true,
        isProcessing: false, 
        formError: err.message
      });
    });

    return false;
  }
};

ForgotForm.propTypes = {};

export default ForgotForm;
