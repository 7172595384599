
import model                  from '_core/newModel';
import SessionStorageHelper   from '_utils/SessionStorageHelper';


const pack = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  create(data) {
    return model.create('packs', data).then(pack => {
      model.publish('pack:created', {pack: pack});
    });
  },

  show(packId) {
    const path = `packs/${packId}`;

    return new Promise((resolve, reject) => {
      try {
        model.show(path).then(res => {
          const pack = res;

          // remove redundant data (fields included under pack.stats object))
          delete pack.cardCount;
          delete pack.deckCount;

          pack.deckIds = pack.deckRows.map(deckRow => deckRow.deckId);
          const augmentedPack = this.setDefaultAttributes(pack);

          resolve(augmentedPack);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  update(packId, packData) {
    const path = `packs/${packId}`;
    return model.update(path, packData).then(pack => {
      const augmentedPack = this.setDefaultAttributes(pack);
      model.publish('pack:updated', {pack: augmentedPack});
    });
  },

  destroy(packId) {
    const path = `packs/${packId}`;
    return model.destroy(path).then(() => model.publish('pack:removed', {packId: packId}));
  },


  /*
  ==================================================
   CACHING
  ==================================================
  */

  getCachedItem(packId) {
    return SessionStorageHelper.getPackItem(packId, 'pack') || null;
  },

  setCachedItem(packId, pack) {
    SessionStorageHelper.setPackItem(packId, 'pack', pack);
  },


  /*
  ==================================================
   CONVENIENCE METHODS
  ==================================================
  */

  areDecksEditable(pack) {
    return (['admin', 'edit'].indexOf(pack.permission) != -1);
  },

  isDuplicatable(user, pack) {
    // this can change if the user's permission level is changed via the Learners tab. mimics server side logic

    if (pack.flags.isCertified) {
      const isBscAdmin = user.flags.isBscAdmin;
      const isPackAdmin = pack.permission == 'admin';

      return (isBscAdmin && isPackAdmin);
    }

    if (pack.flags.isPrivate) {
      return user.flags.isPro && this.isEditable(user, pack);
    }

    return user.flags.isPro;
  },

  isEditable(pack) {
    return (['admin'].indexOf(pack.permission) != -1);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  setDefaultAttributes(pack) {
    // avoid issues with spread operator assignments by explicity setting values for fields sometimes unset in API response

    const augmentedPack = {...pack};

    augmentedPack.activeIconUrl = pack.activeIconUrl || '';
    augmentedPack.certified = pack.certified || false;
    augmentedPack.desc = pack.desc || '';
    augmentedPack.marketBody = pack.marketBody || '';
    augmentedPack.marketHeadline = pack.marketHeadline || '';
    augmentedPack.welcomeMessage = pack.welcomeMessage || '';

    return augmentedPack;
  },

};

export default pack;
