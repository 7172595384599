
import {BackButton}                   from '_views/shared/IconButton';
import EventManager                   from '@brainscape/event-manager';
import MasteryCircle                  from '_views/shared/MasteryCircle';
import React                          from 'react';
import NumberHelper                   from '_utils/NumberHelper';

import {toClassStr} from '_utils/UiHelper';

class OverallStatsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        isAtCheckpoint,
        isClosingCheckpoint,
        isMobileViewportSize,
        mixCardCount,
        mixMastery,
        mixRatingLevelCounts,
        onCloseStudyMixRequest,
        roundStepCount,
        roundStepIndex,
    */

    this._isMounted = false;
    this.confidenceRatings = null;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isAtCheckpointClass = this.props.isAtCheckpoint ? 'is-at-checkpoint' : '';
    const isClosingCheckpointClass = this.props.isClosingCheckpoint ? 'is-closing-checkpoint' : '';
    const classes = toClassStr(['overall-stats-section', isAtCheckpointClass, isClosingCheckpointClass, this.props.addClasses]);
    const mixMastery = NumberHelper.percentageStr(this.props.mixMastery);
    const cardIndex = Math.min(this.props.roundStepIndex, this.props.roundStepCount);
    const tooltipPlace = this.props.isMobileViewportSize ? 'bottom' : 'right';

    return (
      <div className={classes}>

          <MasteryCircle 
            addClasses="overall-mastery"
            animationDuration={0}
            background="dark"
            key="overallMastery"
            mastery={this.props.mixMastery}
            shouldAnimate={false}
            shouldCountUpMastery={false}
            shouldShowLevels={false}
            shouldShowMasteryValue={true}
            shouldShowMasteryValueLabel={true}
            tooltipContent={this.renderOverallMasteryCircleTooltip()}
            tooltipPosition="right"
          />

          {this.renderCheckpointCircle()}

          {this.renderConfidenceRatingsBars()}

        <footer className="overall-stats-footer">
          <h4 className="status-title">Cards rated in Round:</h4>
          <div className="card-index">{cardIndex + " / " + this.props.roundStepCount}</div>
        </footer>
      </div>
    );
  }

  renderOverallMasteryCircleTooltip() {
    return (
      <div className="rich-content-tooltip">
        <div className="rich-content-tooltip-body">
          <p className="body-text">This graph depicts your overall mastery for the cards in your study mix.</p>
          <p className="body-text">Overall mastery will reach 100% when you have rated each card with a confidence level of 5.</p>
        </div>
      </div>
    );
  }

  renderCheckpointCircle() {
    if (!this.props.isAtCheckpoint) {
      return null;
    }

    return (
      <div className="checkpoint-circle" />
    );
  }

  renderConfidenceRatingsTooltip() {
    return (
      <div className="rich-content-tooltip">
        <div className="rich-content-tooltip-body">
          <p className="body-text">These bars show you how many cards you have at each confidence level, for all the decks you have checked in your mix.</p>
          <p className="body-text">The top bar shows how many cards you have yet to see and rate.</p>
        </div>
      </div>
    );
  }

  renderConfidenceRatingsBars() {
    if (this.props.isMobileViewportSize) {
      return this.renderMobileConfidenceRatingsBars();
    }

    const bars = this.props.mixRatingLevelCounts.map((ratingLevelCount, index) => {
      return this.renderConfidenceRatingsBar(index, ratingLevelCount);
    });

    return (
      <div 
        className="confidence-ratings"
        onMouseEnter={this.handleConfidenceRatingsMouseEnter}
        onMouseLeave={this.handleConfidenceRatingsMouseLeave}
        ref={(elem) => { this.confidenceRatings = elem }}
      >
        <h4 className="confidence-ratings-heading">Confidence Ratings</h4>
        <ul className="confidence-ratings-bars">
          {bars}
        </ul>
      </div>
    );
  } 

  renderConfidenceRatingsBar(level, levelCount) {
    const levelLabel = (level == 0) ? 'New' : level;
    const countTitle = (level == 0) ? levelCount + " unrated cards" : levelCount + " cards rated as " + level;
    const countPercentage = (levelCount / this.props.mixCardCount) * 100;

    return (
      <li className={"confidence-ratings-bar level-" + level} key={level}>
        <div className="level-label">{levelLabel}</div>

        <div className="base-bar">
          <div 
            className="count-bar"
            style={{width: countPercentage + '%'}}
            title={countTitle}
          />
        </div>

        <div className="level-count">{levelCount}</div>
      </li>
    );
  }

  renderMobileConfidenceRatingsBars() {
    const bars = this.props.mixRatingLevelCounts.map((ratingLevelCount, index) => {
      if (ratingLevelCount > 0) {
        return this.renderMobileConfidenceRatingBar(index, ratingLevelCount);
      }
    });

    const unviewedCardsBar = bars.shift();
    bars.push(unviewedCardsBar);

    return (
      <ul className="mobile-confidence-ratings-bars">
        {bars}
      </ul>
    );
  } 

  renderMobileConfidenceRatingBar(level, levelCount) {
    const levelLabel = (level == 0) ? 'New' : level;
    const countPercentage = (levelCount / this.props.mixCardCount) * 100;

    return (
      <li 
        className={"mobile-confidence-ratings-bar level-" + level} 
        key={level}
        style={{width: countPercentage + '%'}}
      >
        {this.renderLevelCount(countPercentage, levelCount)}
      </li>
    );
  }

  renderLevelCount(countPercentage, levelCount) {
    if (countPercentage < 8) {
      return null;
    }

    return (
      <div className="level-count">{levelCount}</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBackButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }
    
    this.props.onCloseStudyMixRequest();
  }

  handleConfidenceRatingsMouseEnter = (e) => {
    const tooltipPlace = this.props.isMobileViewportSize ? 'bottom' : 'right';
    const tooltipContent = this.renderConfidenceRatingsTooltip();
    const tooltipPosition = tooltipPlace;

    this.triggerTooltipOpen({
      content: tooltipContent,
      elem: this.confidenceRatings,
      position: tooltipPosition,
    });
  }

  handleConfidenceRatingsMouseLeave = () => {
    this.triggerTooltipClose();
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };
}

export default OverallStatsSection;
