
import PropTypes        from 'prop-types';
import React            from 'react';

import {toClassStr}     from '_utils/UiHelper';

const PT = {
  addClasses            : PropTypes.string,
  buttons               : PropTypes.array,
  caption               : PropTypes.string,
  id                    : PropTypes.node,
  isDisabled            : PropTypes.bool,
  isInline              : PropTypes.bool,
  label                 : PropTypes.string,
  name                  : PropTypes.string,
  onClick               : PropTypes.func,
  value                 : PropTypes.node,
};


class RadioButtonsField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const isInlineClass = (this.props.isInline) ? 'is-inline' : '';
    const isDisabledClass = (this.props.isDisabled) ? 'is-disabled' : '';

    let classes = toClassStr(["radio-buttons-field", isInlineClass, isDisabledClass, this.props.addClasses]);

    return (
      <div className={classes}>

        {this.renderFieldLabel()}

        <ul className="radio-button-list">
          {this.renderButtons()}
        </ul>

        {this.renderFieldCaption()}

      </div>
    );
  }

  renderButtons() {
    let buttons = this.props.buttons.map((button, index) => {
      let isChecked = (button.value == this.props.value) ? 'checked' : '';
      let classes = toClassStr(["radio-button", button.className, isChecked]);

      return (
        <li
          key={index}
          className={classes} 
          id={this.props.name + '-' + button.value}
          name={this.props.name}
          data-value={button.value}
          onClick={() => this.handleButtonClick(button.value)}
        >
          {button.label}
        </li>
      );
    });

    return buttons;
  }

  renderFieldLabel() {
    if (!this.props.label) {
      return null;
    }

    return (
      <div className="field-label">
        {this.props.label}
      </div>
    );
  }

  renderFieldCaption() {
    if (!this.props.caption) {
      return null;
    }

    return (
      <div className="field-caption">
        {this.props.caption}
      </div>
    );
  }

  handleButtonClick(buttonValue) {
    if (!this.props.isDisabled) {
      this.props.onClick(buttonValue);
    }
  }
}

RadioButtonsField.propTypes = PT;

export default RadioButtonsField;
