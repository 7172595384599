
import { DefaultParsers, ParseCardList } from 'parse-card-list';
import { AiListParser } from '_services/AiListParser';

const Parsers = [...DefaultParsers, AiListParser];

const TEMP_TEXT_FILE = 'PastedText.txt';

const ParseCardListService = {
  _cardRows: null,
  _fileName: "",
  _parser: null,
  _pcl: null,


  /*
  ==================================================
   CORE METHODS 
  ==================================================
  */

  acceptFile(filePointer) {
    return new Promise((resolve, reject) => {
      const fileName = filePointer.name;
      const mimeType = filePointer.type;
    
      const reader = new FileReader();
    
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        this.setFile(mimeType, arrayBuffer, fileName);
        resolve({
          arrayBuffer: arrayBuffer,
          fileName: this.getFileName(),
          mimeType: this.getMimeType(),
          parserName: this.getParser()?.name,
        });
      };
    
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };

      // begin reading the fileData as an ArrayBuffer and when it completes, the reader.onload above will be called
      reader.readAsArrayBuffer(filePointer);
    });
  },
  
  acceptText(text) {
    return new Promise((resolve, reject) => {
      try {
        const mimeType = 'text/plain';
        const arrayBuffer = new TextEncoder().encode(text).buffer; // Convert text to ArrayBuffer
    
        // Set the fileName and pass the ArrayBuffer directly
        this.setFile(mimeType, arrayBuffer, TEMP_TEXT_FILE);
        
        resolve({
          arrayBuffer: arrayBuffer,
          fileName: '',
          mimeType: mimeType,
          parserName: this.getParser()?.name,
        });
      } catch (err) {
        reject(err);
      }
    });
  },

  clearFile() {
    this._pcl = null;
    this._fileName = "";
    this._parser = null;
    this._cardRows = null;
  },

  parseArrayBuffer() {
    // this is an alias for parseFile to maintain consistency with the mobile app implementation
    return this.parseFile();
  },

  parseFile() {
    if (!this._pcl) {
      return Promise.reject(new Error('No file to parse'));
    }
    if (!this._parser) {
      return Promise.reject(new Error('No parser specified'));
    }

    return this._pcl.parse(this._parser)
      .then(crs => {
        this._cardRows = crs;
        return crs;
      })
      .catch(err => {
        console.error(err);
        return Promise.reject(err);
      });
  },

  setFile(mimeType, body, fileName) {
    this.clearFile();

    // Create a new ParseCardList instance with the parsers and file information
    this._pcl = new ParseCardList(Parsers, mimeType, body);
    this._fileName = fileName;
    this.setParser(this.getDefaultParser());
  },

  
  /*
  ==================================================
   UTILITY METHODS 
  ==================================================
  */

  getCardRows() {
    return this._cardRows;
  },

  getDefaultParser() {
    return this._pcl ? this._pcl.defaultParser : null;
  },

  getFileName() {
    return this._fileName;
  },

  getMimeType() {
    return this._pcl ? this._pcl.mimeType : "";
  }, 

  getParser() {
    return this._parser;
  },

  setParser(parser) {
    this._parser = parser;
  }, 

  getPcl() {
    return this._pcl;
  },

  getSupportedParsers() {
    return this._pcl ? this._pcl.supportedParsers.map(sp => sp.parser) : [];
  },

  clearCardRows() {
    this._cardRows = null;
  },
};

export default ParseCardListService;
