import EventManager           from '@brainscape/event-manager';
import PropTypes              from 'prop-types';
import React                  from 'react';

import LoadingOverlay         from '_views/shared/LoadingOverlay';
import PillButton             from '_views/shared/PillButton';

import {toClassStr}           from '_utils/UiHelper';

const PT = {
  addClasses:                 PropTypes.string,
  currentPack:                PropTypes.object,
  currentUser:                PropTypes.object,
};

const INVITED_MEDIUMS = ['admin_share', 'email_link', 'invite_link'];


class PrivateClassOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <LoadingOverlay
        addClasses="detail-loading-overlay private-class-overlay"
        isOpen={true}
        message={this.renderPrivateClassMessage()}
        shouldTransitionIn={true}
      />
    );
  }

  renderPrivateClassMessage() {
    return (
      <div className="private-class-message">
        <div className="private-class-heading">Private Class</div>

        {this.renderPrivateClassText()}
        {this.renderUpgradeToProButton()}
      </div>
    );
  }

  renderPrivateClassText() {
    if (this.wasInvited()) {
      return (
        <div className="private-class-text">
          You were invited to <strong>{this.props.currentPack.name}</strong>. It is a
          Private Class which requires Brainscape Pro to access and study.
        </div>
      );
    }

    return (
      <div className="private-class-text">
        <strong>{this.props.currentPack.name}</strong> is a private class.
        <br/>
        To access and study a private class, you need <strong>BOTH</strong> the creator of this class to give you full study access (or above) <strong>AND</strong> Brainscape Pro.
      </div>
    );
  }

  renderUpgradeToProButton() {
    if (this.props.currentUser.flags?.isPro) {
      return null;
    }

    return (
      <PillButton
        addClasses="upgrade-to-pro-button"
        label="Upgrade To Pro"
        onClick={this.handleUpgradeButtonClick}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleUpgradeButtonClick = () => {
    this.triggerUpgradeModalOpen('Unlocking a Private Class', 'private_access');
  };


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerUpgradeModalOpen(desiredAction, paywall) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall: paywall,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  wasInvited = () => {
    return (INVITED_MEDIUMS.indexOf(this.props.currentPack.discoveryMedium) != -1);
  }
}

PrivateClassOverlay.propTypes = PT;

export default PrivateClassOverlay;