class RevealMode {
  constructor(modeMap) {
    this.modeMap = modeMap;
  }

  effMode = (mode) => this.modeMap[mode];
}

const RevealModes = {
  normal: new RevealMode({ q: "q", a: "a" }),
  reverse: new RevealMode({ q: "a", a: "q" }),
};

export { RevealMode, RevealModes };
