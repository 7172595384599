
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';

class ErrorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
    };

    /*
      this.props:
        addClasses,
        isOpen,
        errorListing,
        errorMessage,
        onCloseRequest,
        title,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <Modal
        addClasses="error-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        {this.renderTitle()}
        {this.renderErrorListingOrMessage()}

        <div className="modal-actions">

          <PillButton
            addClasses="ack-modal-button" 
            label="Ok"
            onClick={this.props.onCloseRequest}
          />

        </div>
      </Modal>
    );
  }

  renderTitle() {
    if (this.props.title) {
      return (
        <div className="modal-title">{this.props.title}</div>
      );
    }

    return (
      <div className="modal-title">Error</div>
    );
  }

  renderErrorListingOrMessage() {
    if (this.props.errorListing) {
      return this.renderErrorListing()
    }

    return this.renderMessage();
  }


  renderErrorListing() {
    const errors = this.props.errorListing.errors;
    const names  = Object.keys(errors);
    const lis    = names.map((name) => {
      return (
        <li key={name}><b>{name}: </b>{errors[name]}</li>
      );
    });

    return (
      <div className="error-listing">
        
        <div className="modal-message">
          {this.props.errorListing.message}
        </div>

        <ul className="error-list">{lis}</ul>
      </div>
    );
  }

  renderMessage() {
    if (this.props.errorMessage) {
      return (
        <div className="modal-message">{this.props.errorMessage}</div>
      );
    }

    return (
      <div className="modal-message">There was a problem with your last request.</div>
    );
  }
}

export default ErrorModal;
