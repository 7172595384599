
import React from "react";
import PropTypes from "prop-types";
import SmartCardFace from "_views/shared/smart-cards/SmartCardFace";

const CardFaceView = ({ card, deck, pack, onClick, revealed, revealMode, confidenceLevel, context, currentUser, face, format, cardMode, layout, minHeight, onViewLargeImageRequest, transitionState, isAtCheckpoint, isAudioMuted, isLoadingCardDisplayData, viewportStyle, cardCount, cardPosition, packId, ...props }) => {
  const mode = revealMode.effMode(props.mode);
  const html = mode === "q" ? card.questionHtml : card.answerHtml;

  const isCurrentCard = true;

  return (
    <div className="card-face-view">

      <SmartCardFace
        addClasses="card-face-view-html"
        bottomCardMinHeight={props.bottomCardMinHeight}
        card={card}
        cardCount={cardCount} 
        cardMode={cardMode} 
        cardPosition={cardPosition} 
        confidenceLevel={confidenceLevel}
        context={context}
        currentUser={currentUser}
        deck={deck}
        face={face}
        format="md"
        topCardMinHeight={props.topCardMinHeight}
        isAnswerShowing={revealed}
        isAtCheckpoint={isAtCheckpoint}
        isAudioMuted={isAudioMuted}
        isCurrentCard={isCurrentCard}
        isLoadingCardDisplayData={isLoadingCardDisplayData}
        isShowingAdditionalFields={true}
        key="question-face"
        layout="single-card"
        onViewLargeImageRequest={onViewLargeImageRequest}
        pack={pack}
        packId={packId}
        parentHeight={props.parentHeight}
        shouldReportBaseFontSize={true}
        transitionState={transitionState}
        viewportStyle={viewportStyle}
      />
    </div>
  );
};

CardFaceView.propTypes = {
  card: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  revealMode: PropTypes.object.isRequired,
};

export { CardFaceView as default };
