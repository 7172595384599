
import EventManager               from '@brainscape/event-manager';
import PropTypes                  from 'prop-types';
import React                      from 'react';
import removeMd                   from 'remove-markdown';
import RoundCheckbox              from '_views/shared/RoundCheckbox';
import UiHelper                   from '_utils/UiHelper';
  
import {toClassStr}   from '_utils/UiHelper';

import {
  DismissButton,
  DuplicateButton,
  DragButton,
  InsertButton,
}                     from '_views/shared/IconButton';


const PT = {
  card:               PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cardCount:          PropTypes.number,
  isCreatingNewCard:  PropTypes.bool,
  isCurrentCard:      PropTypes.bool,
  isDraggable:        PropTypes.bool,
  isLastAdded:        PropTypes.bool,
  isLastUpdated:      PropTypes.bool,
  isSelected:         PropTypes.bool,
  listNumber:         PropTypes.number,
  sidebarMode:        PropTypes.string,
};

const BADGE_DURATION = 5000;

class DeckCardRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldFadeBadge: false,
    };

    this.lastAddedTimeout = null;
    this.lastUpdatedTimeout = null;

    this.cardTitle = null;
    this.elem = null;

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFE CYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
  }

  componentDidUpdate = (prevProps) => {
    const card = this.props.card;
    const prevCard = prevProps.card;

    if (!prevProps.isLastAdded && this.props.isLastAdded) {
      this.animateLastAddedBadge();
    }

    if (!prevProps.isLastUpdated && this.props.isLastUpdated) {
      this.animateLastUpdatedBadge();
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.clearTimeoutsAndIntervals();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const card = this.props.card;
    const hasQuestionMediaClass = (card.qImageUrl || card.qSoundUrl) ? 'has-question-media' : '';
    const isCurrentCardClass = (this.props.isCurrentCard) ? 'is-current-card' : '';
    const isLastAddedClass = (this.props.isLastAdded) ? 'is-last-added' : '';
    const isLastUpdatedClass = (this.props.isLastUpdated) ? 'is-last-updated' : '';
    const sidebarModeClass = (this.props.sidebarMode == 'mini') ? 'is-mini' : 'is-full';
    const hasPromptClass = (card.prompt) ? 'has-prompt' : '';
    const fadeLastAddedBadgeClass = (this.state.shouldLastAddedBadgeFade) ? 'fade-last-added-badge' : '';
    const fadeLastUpdatedBadgeClass = (this.state.shouldLastUpdatedBadgeFade) ? 'fade-last-updated-badge' : '';
    const id = (this.props.isNewCard) ? 'deck-card-row-new' : `deck-card-row-${card.cardId}`;

    const classes = toClassStr(['deck-card-row', isCurrentCardClass, isLastAddedClass,isLastUpdatedClass, sidebarModeClass, hasPromptClass, hasQuestionMediaClass, fadeLastAddedBadgeClass, fadeLastUpdatedBadgeClass]);

    return (
      <div 
        className={classes} 
        id={id} 
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={elem => this.elem = elem}
      >
        {this.renderDragButton()}
        {this.renderCardNumber()}
        {this.renderCheckbox()}
        {this.renderCardTextRow()}
      </div>
    );
  };

  renderDragButton() {
    if (!this.props.isDraggable) {
      return null;
    }

    return (
      <DragButton
        addClasses="drag-card-button"
      />
    );
  }

  renderCardNumber() {
    if (this.props.sidebarMode != 'mini') {
      return null;
    }

    const cardNumber = this.props.listNumber;

    return (
      <div className="card-number">{cardNumber}</div>
    );
  }

  renderCheckbox() {
    if (this.props.sidebarMode == 'mini') {
      return null;
    }

    return (
      <RoundCheckbox 
        isChecked={this.props.isSelected} 
        onClick={this.handleCheckboxClick} 
      />
    );
  }

  renderCardTextRow() {
    if (this.props.sidebarMode == 'mini') {
      return null;
    }

    const question = this.props.card.cardId ? this.stripSpecialCharacters(this.props.card.questionPreview) : 'New Card';
    const answer = this.props.card.cardId ? this.stripSpecialCharacters(this.props.card.answerPreview) : '';

    return (
      <div className="card-text-row">
        <div 
          className="card-title"
          ref={(elem) => { this.cardTitle = elem }} 
        >
          <div className="title-question">{this.props.listNumber}: {removeMd(question)}</div>
          <div className="title-answer">{removeMd(answer)}</div>
        </div>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCheckboxClick = () => {
    EventManager.emitEvent('card-selection:updated', {
      cardId:   this.props.card.cardId,
      isSelected: !this.props.isSelected,
    });
  };

  handleClick = (e) => {
    e.stopPropagation();

    this.triggerTooltipClose();

    const cardId = this.props.card.cardId;
    this.triggerChangeCurrentCardRequest(cardId);
  };

  handleMouseEnter = () => {
    const card = this.props.card;
    const cardLabel = this.stripSpecialCharacters(card.questionPreview) || '';

    this.triggerTooltipOpen({
      content: `Q: ${cardLabel}`,
      elem: this.elem,
      position: 'right',
    });
  }

  handleMouseLeave = () => {
    this.triggerTooltipClose();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  }

  triggerChangeCurrentCardRequest = (cardId) => {
    EventManager.emitEvent('current-card:change-request', {
      cardId: cardId,
      tabId: 'edit',
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  animateLastAddedBadge = () => {
    this.setState({
      shouldLastAddedBadgeFade: false,
    });

    clearTimeout(this.lastAddedTimeout);

    this.lastAddedTimeout = setTimeout(() => {
      this.setState({
        shouldLastAddedBadgeFade: true,
      })
    }, BADGE_DURATION);
  }

  animateLastUpdatedBadge = () => {
    this.setState({
      shouldLastUpdatedBadgeFade: false,
    });

    clearTimeout(this.lastUpdatedTimeout);

    this.lastUpdatedTimeout = setTimeout(() => {
      this.setState({
        shouldLastUpdatedBadgeFade: true,
      })
    }, BADGE_DURATION);
  }

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.lastAddedTimeout);
    clearTimeout(this.lastUpdatedTimeout);
  }

  stripSpecialCharacters = (text) => {
    let strippedText = text.replace(/[^a-zA-Z0-9 ]/g, "");

    return strippedText;
  }

}

DeckCardRow.propTypes = PT;

export default DeckCardRow;
