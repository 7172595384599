
// substrate and utils
import EventManager                         from '@brainscape/event-manager';
import PropTypes                            from 'prop-types';
import React                                from 'react';
import {toClassStr}                         from '_utils/UiHelper';

// top z-level view controllers
import DetailLoadingOverlayController       from '_controllers/DetailLoadingOverlayController';
import PageLoadingOverlayController         from '_controllers/PageLoadingOverlayController';
import ToastController                      from '_controllers/ToastController';
import TooltipController                    from '_controllers/TooltipController';
    
// sub-components
import DashboardSidebar                     from '_views/shared/DashboardSidebar';
import DashboardMobileTopbar                from '_views/shared/DashboardMobileTopbar';
import DeckDetailBody                       from '_views/deck-detail/desktop/DeckDetailBody';
import FtueMessaging                        from '_views/shared/ftue/FtueMessaging';
import TransitionWrapper                    from '_views/shared/TransitionWrapper';
    
// modal view controllers
import CautionModalController               from '_controllers/modals/CautionModalController';
import CopyAndPlaceCardsModalSetController  from '_controllers/modals/CopyAndPlaceCardsModalSetController';
import CopyAndPlaceDeckModalSetController   from '_controllers/modals/CopyAndPlaceDeckModalSetController';
import CreateDeckModalController            from '_controllers/modals/CreateDeckModalController';
import CreatePackModalController            from '_controllers/modals/CreatePackModalController';
import DeckPreviewModalController           from '_controllers/modals/DeckPreviewModalController';
import EarnMoneyModalController             from '_controllers/modals/EarnMoneyModalController';
import EditDeckInfoModalController          from '_controllers/modals/EditDeckInfoModalController';
import EditPackPrivacyModalController       from '_controllers/modals/EditPackPrivacyModalController';
import ImportCardsModalController           from '_controllers/modals/ImportCardsModalController';
import InfoModalController                  from '_controllers/modals/InfoModalController';
import DialogModalController                from '_controllers/modals/DialogModalController';
import ManageDeckCategoriesModalController  from '_controllers/modals/ManageDeckCategoriesModalController';
import SharePackByLinkModalController       from '_controllers/modals/SharePackByLinkModalController';
import SharePackModalSetController          from '_controllers/modals/SharePackModalSetController';
import SuggestEditModalSetController        from '_controllers/modals/SuggestEditModalSetController';
import UpgradeModalController               from '_controllers/modals/UpgradeModalController';
              
import GetTheAppModalController             from '_controllers/modals/GetTheAppModalController';

// images
import defaultPackIconLightBg               from '_images/shared/ugc-clear-bg.svg';
import defaultPackIconDarkBg                from '_images/shared/ugc-blue-bg.svg';
import defaultAvatar                        from '_images/shared/avatar-generic-square.png';

      
const PT = {      
  addClasses:                           PropTypes.string,
  cardClipboard:                        PropTypes.object,
  currentCardId:                        PropTypes.node,
  currentDeck:                          PropTypes.object,
  currentPack:                          PropTypes.object,
  currentTabId:                         PropTypes.string,
  currentUser:                          PropTypes.object,
  currentUserPacks:                     PropTypes.array,
  isFtue:                               PropTypes.bool,
  isLoadingDeck:                        PropTypes.bool,
  isLoadingDeckCards:                   PropTypes.bool,
  isLoadingPack:                        PropTypes.bool,
  isLoadingTrendingPacks:               PropTypes.bool,
  isLoadingUser:                        PropTypes.bool,
  isLoadingUserPacks:                   PropTypes.bool,
  isLoadingTrendingPacks:               PropTypes.bool,
  isPaginatingDeckCards:                PropTypes.bool,  
  isMobileViewportSize:                 PropTypes.bool,
  isMobileSidebarOpen:                  PropTypes.bool,
  isShowingCachedDeckCards:             PropTypes.bool,
  isShowingCachedUserPacks:             PropTypes.bool,
  sidebarMode:                          PropTypes.string,
  trendingPacks:                        PropTypes.array,
};


class DeckDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCardHasChanges: false,
      shouldPulseAddCardsButton: false,
      shouldPulseCreateDeckButton: false,
      shouldPulseCreatePackButton: false,
      shouldPulseFindButton: false,
      shouldPulseStudyButton: false,
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.startBrokenImageMonitor();
    this.manageDeckLoadingOverlay();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoadingPack != this.props.isLoadingPack) {
      this.manageDeckLoadingOverlay();
    }

    if (prevProps.isLoadingDeck != this.props.isLoadingDeck) {
      this.manageDeckLoadingOverlay();
    }

    if (prevProps.isLoadingDeckCards != this.props.isLoadingDeckCards) {
      this.manageDeckLoadingOverlay();
    }

    if (prevProps.currentDeck?.cards && !this.props.currentDeck?.cards) {
      this.manageDeckLoadingOverlay();
    }
  }

  componentWillUnmount() {
    this.stopBrokenImageMonitor();
    this._isMounted = false;
  }




  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const isMiniSidebarClass = (this.props.sidebarMode == 'mini' ? 'is-mini-sidebar' : '');
    const classes = toClassStr(['desktop-page deck-detail-page', isMiniSidebarClass, this.props.addClasses]);

    return (
      <div className={classes}>
        <TooltipController />
        <ToastController />
        <PageLoadingOverlayController />
        <DetailLoadingOverlayController />

        <div className="page-shell">
          {this.renderDashboardMobileTopbar()}
          {this.renderDashboardMobileSidebar()}
          {this.renderDashboardDesktopSidebar()}
          {this.renderDeckDetailBody()}
          {this.renderFtueMessaging()}
        </div>

        <CautionModalController />
        <CreateDeckModalController />
        <CreatePackModalController />
        <CopyAndPlaceCardsModalSetController />
        <CopyAndPlaceDeckModalSetController currentUserPacks={this.props.currentUserPacks} />
        <EarnMoneyModalController />
        <EditDeckInfoModalController />
        <ImportCardsModalController />
        <InfoModalController />
        <DialogModalController />
        <ManageDeckCategoriesModalController />
        <SuggestEditModalSetController />
        <UpgradeModalController />
        <GetTheAppModalController />
      </div>
    );
  }

  renderDashboardDesktopSidebar = () => {
    if (this.props.isMobileViewportSize) {
      return null;
    }

    return this.renderDashboardSidebar();
  }

  renderDashboardMobileTopbar() {
    if (!this.props.isMobileViewportSize) {
      return null;
    }

    return (
      <DashboardMobileTopbar
        isLoadingUserInfo={this.props.isLoadingUser}
        currentUser={this.props.currentUser}
        onShowClassesRequest={() => this.handleShowClassesRequest()}
        userPacks={this.props.currentUserPacks}
      />
    );
  }

  renderDashboardMobileSidebar() {
    // NOTE: This is not really a "Sidebar", it's an overlay
    if (!this.props.isMobileViewportSize) {
      return null;
    }

    const isOpen = this.props.isMobileViewportSize && this.props.isMobileSidebarOpen == true;

    return (
      <TransitionWrapper
        addClasses="dashboard-mobile-sidebar-transition-wrapper"
        onTransitionedOut={() => {}}
        shouldTransitionIn={isOpen}
        shouldTransitionOut={!isOpen}
        transitionInDelay={100}
        transitionInDuration={500}
        transitionOutDuration={500}
      >
        {this.renderDashboardSidebar()}
      </TransitionWrapper>
    );
  }

  renderDashboardSidebar = () => {
    // const isEmptyLibrary = !(this.props.currentUserPacks.length > 0);
    const currentUser = this.props.currentUser;

    return (
      <DashboardSidebar
        currentPack={this.props.currentPack}
        currentPackId={this.props.currentPack.packId}
        currentUser={this.props.currentUser}
        // isEmptyLibrary={isEmptyLibrary}
        isLoadingTrendingPacks={this.props.isLoadingTrendingPacks}
        isLoadingUserInfo={this.props.isLoadingUser}
        isLoadingUserPacks={this.props.isLoadingUserPacks}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isPackDetailView={true}
        isPaginatingDeckCards={this.props.isPaginatingDeckCards}
        isShowingCachedUserPacks={this.props.isShowingCachedUserPacks}
        onHideClassesRequest={() => this.handleHideClassesRequest()}
        shouldPulseCreatePackButton={this.state.shouldPulseCreatePackButton}
        shouldPulseFindButton={this.state.shouldPulseFindButton}
        sidebarMode={this.props.sidebarMode}
        trendingPacks={this.props.trendingPacks}
        userPacks={this.props.currentUserPacks}
      />
    )
  }

  renderDeckDetailBody = () => { 
    if (this.props.isLoadingPack) {
      return null;
    }

    if (this.props.isLoadingDeck) {
      return null;
    }

    if (this.props.isLoadingDeckCards && !this.props.isShowingCachedDeckCards) {
      return null;
    }

    if (!this.props.currentDeck.cards) {
      return null;
    }

    return (
      <DeckDetailBody
        cardClipboard={this.props.cardClipboard}
        currentCardHasChanges={this.state.currentCardHasChanges}
        currentCardId={this.props.currentCardId}
        currentDeck={this.props.currentDeck}
        currentPack={this.props.currentPack}
        currentTabId={this.props.currentTabId}
        currentUser={this.props.currentUser}
        isFtse={this.props.currentUser.flags.isFtse}
        isFtue={this.props.currentUser.flags.isFtue}
        isLoadingDeck={this.props.isLoadingDeck}
        isLoadingDeckCards={this.props.isLoadingDeckCards}
        isLoadingPack={this.props.isLoadingPack}
        isLoadingUser={this.props.isLoadingUser}
        isPaginatingDeckCards={this.props.isPaginatingDeckCards}
        isShowingCachedDeckCards={this.props.isShowingCachedDeckCards}
        isMobileViewportSize={this.props.isMobileViewportSize}
        sidebarMode={this.props.sidebarMode}
      />
    );
  }

  renderEmptyLibraryMessaging = () => {
    return null;
  }

  renderUnownedPackMessaging = () => {
    return null;
  }

  renderFtueMessaging = () => {
    if (!this.props.isFtue) {
      return null;
    }

    const currentView = 'deck-detail';

    return (
      <FtueMessaging
        currentPack={this.props.currentPack}
        currentUser={this.props.currentUser}
        currentView={currentView}
        isFtue={this.props.isFtue}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  };


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBrokenImageError = (e) => {
    e.stopPropagation();

    const elem = e.target;
    const imageKind = elem.getAttribute('data-img-kind');

    if (!imageKind) {
      return false;
    }

    let src;

    switch (imageKind) {
      case 'avatar':
        src = defaultAvatar;
      break;

      case 'ugsIconWhiteBg': 
        src = defaultPackIconLightBg;
      break;

      case 'ugsIconBlueBg': 
        src = defaultPackIconDarkBg;
      break;

      case 'catIcon':
        src = defaultPackIconLightBg;
      break;
    }

    if (!src) {
      return false;
    }

    elem.src = src;
  }

  handleShowClassesRequest = () => {
    this.props.handleShowClassesRequest();
  }

  handleHideClassesRequest = () => {
    this.props.handleHideClassesRequest();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerDetailLoadingOverlayClose = () => {
    EventManager.emitEvent('detail-loading-overlay:close', {});
  }

  triggerDetailLoadingOverlayOpen = (message) => {
    EventManager.emitEvent('detail-loading-overlay:open', {message: message});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  startBrokenImageMonitor = () => {
    document.addEventListener('error', (e) => {
      if (e.target.tagName.toLowerCase() !== 'img') {
        return false;
      }

      this.handleBrokenImageError(e);
    }, true);  
  }

  stopBrokenImageMonitor = () => {
    document.removeEventListener('error', (e) => {
      if (e.target.tagName.toLowerCase() !== 'img') {
        return false;
      }

      this.handleBrokenImageError(e);
    }, true);  
  }

  manageDeckLoadingOverlay = () => {
    if (this.props.isLoadingPack) {
      this.triggerDetailLoadingOverlayOpen('Loading Deck Detail...');
      return true;
    }

    if (this.props.isLoadingDeck) {
      this.triggerDetailLoadingOverlayOpen('Loading Deck Detail...');
      return true;
    }

    if (this.props.isLoadingDeckCards && !this.props.isShowingCachedDeckCards) {
      this.triggerDetailLoadingOverlayOpen('Loading Deck Detail...');
      return true;
    }

    if (!this.props.currentDeck?.cards) {
      this.triggerDetailLoadingOverlayOpen('Loading Deck Detail...');
      return true;
    }

    this.triggerDetailLoadingOverlayClose();
  }
}

DeckDetailPage.propTypes = PT;

export default DeckDetailPage;
