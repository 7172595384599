
// substrate and utils
import EventManager               from '@brainscape/event-manager';
import NumberHelper               from '_utils/NumberHelper';
import PersonHelper               from '_utils/PersonHelper';
import React                      from 'react';
import StudyHelper                from '_utils/StudyHelper';
import TimeHelper                 from '_utils/TimeHelper';
import Tracker                    from '_utils/Tracker';
import UiHelper                   from '_utils/UiHelper';
import {toClassStr}               from '_utils/UiHelper';

// models
import packLearner                from '_models/packLearner';
import packLearnerTransform       from '_models/packLearnerTransform';
import packMetadata               from '_models/packMetadata';

// sub-components
import DynamicTooltipIcon         from '_views/shared/DynamicTooltipIcon';
import Pulldown                   from '_views/shared/Pulldown';
import RichContentTooltip         from '_views/shared/RichContentTooltip';
import RoundCheckbox              from '_views/shared/RoundCheckbox';
import SimpleProgressBar          from '_views/shared/SimpleProgressBar';
import Spinner                    from '_views/shared/Spinner';
import Stat                       from '_views/shared/Stat';

import {
  ForwardButton,
  RemoveButton,
} from '_views/shared/IconButton';


const PERMISSION_OPTIONS = [
  { id: 'preview', label: 'Preview' },
  { id: 'study', label: 'Full Study' },
  { id: 'edit', label: 'Edit' },
  { id: 'admin', label: 'Admin' },
];


class LearnerRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      isPermissionPulldownOpen: false,
      isProcessingPermissionChange: false,
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        learner,
        learnerIndex,
        onCheckboxClick,
        pack,
    */

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const learner = this.props.learner;
    const stats = learner.stats || {};
    const isCurrentUser = learner.flags.isCurrentUser;

    const avatarTitle = `See ${learner.firstName}'s profile`;
    const learnerName = PersonHelper.getProfileName(learner, this.props.pack.permission);

    const isCurrentUserClass = (isCurrentUser) ? 'is-current-user' : ''; 
    let masteryClass = '';

    if (this.canUserViewStats && stats.mastery) {
      const mastery = stats.mastery;
      const masteryPercentage = Math.floor(mastery * 100) || 0;
      const masteryLevel = StudyHelper.getMasteryLevel(masteryPercentage);
      masteryClass = `mastery-level-${masteryLevel}`;
    }

    let classes = toClassStr(['learner-row', isCurrentUserClass, masteryClass, this.props.addClasses]);

    return (
      <li className={classes} onClick={this.handleLearnerRowClick}>
        <ul className="learner-row-contents">
          <li className="learner-selection-checkbox col col-1">
            {this.renderLearnerSelectionCheckbox()}
          </li>

          <li className="learner-avatar-name-and-mastery col col-2">
            <div className="learner-avatar">
              <img
                className="learner-avatar-image"
                data-img-kind="avatar"
                onClick={this.handleLearnerAvatarClick}
                src={learner.avatarUrl}
                title={avatarTitle}
              />
            </div>

            <div className="learner-name-and-mastery">
              <div className="name-and-mastery-bar">
                <h4 className="learner-name" title={learnerName} onClick={this.handleLearnerNameClick}>{learnerName}</h4>
                {this.renderMasteryProgressBar()}
              </div>
            </div>
          </li>

          <li className="table-column mastery col col-3 stat-col">
            {this.renderMastery()}
          </li>

          <li className="table-column days-studied col col-4 stat-col">
            {this.renderStat(stats.daysStudied)}
          </li>

          <li className="table-column time-studied col col-5 stat-col">
            {this.renderTimeStudied()}
          </li>

          <li className="table-column cards-studied col col-6 stat-col">
            <div className="unique">
              {this.renderStat(stats.seenCount)}
            </div>
            <div className="total">
              {this.renderStat(stats.totalCardsStudied)}
            </div>
          </li>

          <li className="table-column permissions col col-7 stat-col">{this.renderPermission()}</li>

          <li className="learner-actions col col-8">
            {this.renderRemoveLearnerButton()}
            {this.renderLearnerDetailButton()}
          </li>
        </ul>
      </li>
    );
  }

  renderLearnerSelectionCheckbox = () => {
    if (this.props.pack.permission != 'admin') { return null;
    }

    const tooltipContent = (this.props.isSelected) ? 'Unselect Learner' : 'Select Learner';
    
    return (
      <RoundCheckbox
        isChecked={this.props.isSelected}
        value={this.props.learner.userId}
        onClick={this.handleLearnerSelectionCheckboxClick}
        tooltipContent={tooltipContent}
        tooltipPosition="right"
      />
    );
  }

  renderMasteryProgressBar = () => {
    if (this.props.pack.permission != 'admin') { 
      return null;
    }

    if (this.areStatsLocked()) {
      return null;
    }

    const stats = this.props.learner.stats;

    if (!stats) {
      return null;
    }

    const masteryPercentage = (stats.mastery || 0) * 100;

    return (
      <SimpleProgressBar
        baseValue={100}
        barValue={masteryPercentage}
        isLight={true}
        shouldDepictLevel={false}
      />
    );
  }

  renderStat = (stat) => {
    if (!this.canUserViewStats()) {
      return null;
    }

    if (this.areStatsLocked()) {
      return this.renderStatsLock();
    }

    if (typeof stat == 'undefined') {
      return null;
    }

    const displayedStat = (stat === 0 || stat === null) ? '0' : stat;

    return (
      <Stat
        addClasses="learner-stat"
        background="light"
        isMobileViewportSize={this.props.isMobileViewportSize}
        orientation="horizontal"
        value={displayedStat}
      />
    );
  }

  renderStatsLock = () => {
    const actions = [
      {
        label: 'School',
        tag: 'school',
        handler: this.handleStatsLockSchoolLicenseRequest,
      },
      {
        label: 'Company',
        tag: 'company',
        handler: this.handleStatsLockCompanyLicenseRequest,
      },
    ];

    return (
      <div className="stat stat-locked">
        <DynamicTooltipIcon
          actions={actions} 
          addClasses="locked-icon"
          body="Stats and reports require a group license. Please choose your organization type:"
          delay={500}
          hasDismissButton={true}
          heading="Unlock Stats & Reports"
          iconType="lock"
          position="top"
        />
      </div>
    );
  }

  renderMastery = () => {
    let mastery = this.props.learner.stats?.mastery;

    if (typeof mastery == 'undefined') {
      return null;
    }

    mastery = (mastery === 0 || mastery === null) ? 0 : mastery;

    const stat = `${NumberHelper.percentageStr(mastery, 1)}%`;

    return this.renderStat(stat);
  }

  renderTimeStudied = () => {
    let timeStudied = this.props.learner?.stats?.timeStudied;

    if (typeof timeStudied == 'undefined') {
      return null;
    }

    timeStudied = (timeStudied === 0 || timeStudied === null) ? 0 : timeStudied;

    const asObject = true;
    const decimalPlace = 1;
    const timeParts = TimeHelper.msToRoundedTime(timeStudied, decimalPlace, asObject);

    if (!(timeParts && Object.keys(timeParts).length > 0)) {
      return null;
    }

    const stat = `${timeParts.value} ${timeParts.units}`;

    return this.renderStat(stat);
  }

  renderPermission() {
    const {permissionValue, permissionLabel} = packLearner.showPermission(this.props.learner);

    if (this.state.isProcessingPermissionChange) {
      return (
        <Spinner 
          color="blue"
        />
      )
    }

    if (!this.canUserChangePermissions()) {
      return permissionLabel;
    }

    return (
      <Pulldown
        addClasses="permission-pulldown"
        isOpen={this.state.isPermissionPulldownOpen}
        isProcessing={this.isProcessingPermissionChange}
        options={PERMISSION_OPTIONS}
        selectedValue={permissionValue}
        shouldSuppressNullOption={true}
        onButtonClick={this.handlePermissionButtonClick}
        onOptionClick={this.handlePermissionOptionClick}
        onOutsideClick={this.handlePermissionOutsideClick}
      />
    );
  }

  renderRemoveLearnerButton = () => {
    if (this.props.pack.permission != 'admin') { 
      return null;
    }

    if (this.props.learner.flags.isCurrentUser) {
      return null;
    }

    return (
      <RemoveButton
        addClasses="remove-learner-button"
        onClick={this.handleRemoveLearnerButtonClick}
        tooltipContent="Remove Learner from Class"
        tooltipPosition="left"
      />
    );
  }

  renderLearnerDetailButton() {
    let tooltipContent = null;

    if (this.areStatsLocked()) {
      tooltipContent = this.renderLicenseRequiredTooltip();
    } else {
      tooltipContent = (this.props.pack.permission == 'admin') ? 'View Learner Progress Report' : 'View Learner Profile';
    }

    return (
      <ForwardButton
        addClasses="action-button learner-forward-button"
        key="learner-forward-button"
        onClick={this.handleLearnerForwardButtonClick}
        tooltipContent={tooltipContent}
        tooltipPosition="left"
      />
    );
  }

  renderLicenseRequiredTooltip = () => {
    const actions = [
      {
        label: 'School',
        tag: 'school',
        handler: this.handleStatsLockSchoolLicenseRequest,
      },
      {
        label: 'Company',
        tag: 'company',
        handler: this.handleStatsLockCompanyLicenseRequest,
      },
    ];

    return (
      <RichContentTooltip
        actions={actions}
        addClasses="info-tooltip-content license-required-tooltip" 
        hasDismissButton={true}
        body="If you're looking for a Learner Progress Report, stats and reports require a group license. Please choose your organization type:"
        heading="View Learner Profile"
        position="left"
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleLearnerAvatarClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.openLearnerProfile();
  }

  handleLearnerForwardButtonClick = () => {
    if (!this.canUserViewStats()) {
      this.openLearnerProfile();
      return true;
    }

    if (this.areStatsLocked()) {
      this.openLearnerProfile();
      return true;
    }

    this.triggerLearnerProgressModalOpen();
  }

  handleLearnerNameClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.openLearnerProfile();
  }

  handleLearnerSelectionCheckboxClick = () => {

    packLearnerTransform.update(this.props.currentUser.userId, this.props.pack.packId, 'selections', {
      learnerIds: [this.props.learner.userId],
      isSelected: !this.props.isSelected,
    }).then(() => {
      this.props.onCheckboxClick(this.props.learner.userId);
    });
  }

  handleLearnerRowClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (!this.canUserViewStats()) {
      this.openLearnerProfile();
      return true;
    }

    if (this.areStatsLocked()) {
      return false;
    }

    this.triggerLearnerProgressModalOpen();
  }

  handleLearnerProgressModalUpgradeRequest = () => {
    const pack = this.props.pack;

    Tracker.trackPaywallWarning('stats');

    packMetadata.show(pack.packId).then(metadata => {
      const role = metadata.global?.role?.value || 'Student';
      const purpose = packMetadata.getPurpose(metadata) || 'General Learning/Other';

      if (role == 'Instructor') {
        this.triggerInstructorUpgradeModalOpen(purpose);
        return true;
      }

      this.triggerUpgradeModalOpen();
    });
  }

  handlePermissionButtonClick = () => {
    this.setState({
      isPermissionPulldownOpen: !this.state.isPermissionPulldownOpen,
    });
  };

  handlePermissionOptionClick = (optionId) => {
    this.setState(
      {
        isPermissionPulldownOpen: false,
        isProcessingPermissionChange: true,
      },
      () => {
        this.performPermissionChange(optionId);
      },
    );
  };

  handlePermissionOutsideClick = () => {
    this.setState({
      isPermissionPulldownOpen: false,
    });
  };

  handleRemoveLearnerButtonClick = () => {
    const message = (
      <div className="remove-learner-modal-content">
        <div className="modal-message">You are about to remove this Learner from this Class. This action cannot be undone.</div>

        <div className="modal-guidance secondary">
          (Note: The user will not be removed from Brainscape and may always be re-invited to this Class.)
        </div>
      </div>
    );

    this.triggerCautionModalOpen({
      message: message,
      resolveButtonText: 'Yes, Remove Learner',
      onResolution: () => {
        this.triggerCautionModalClose();
        this.removeLearner();
      },
    });
  }

  handleStatsLockCompanyLicenseRequest = () => {
    UiHelper.openInNewTab('https://share.hsforms.com/1nTvQA_8oT82t7-1_dbIy_A1fype', 'license');
  }

  handleStatsLockSchoolLicenseRequest = () => {
    UiHelper.openInNewTab('https://share.hsforms.com/1qLB8v_qTQS-gNXihGtFriA1fype', 'license');
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalClose() {
    EventManager.emitEvent('caution-modal:close', {});
  }

  triggerCautionModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerInstructorUpgradeModalOpen(purpose) {
    EventManager.emitEvent('upgrade-modal:open', {
      paywall: 'stats',
      purpose: purpose,
      featuresList: 'list-8',
    });
  }

  triggerLearnerProgressModalOpen = () => {
    EventManager.emitEvent('learner-progress-modal:open', {
      learner: this.props.learner,
      pack: this.props.pack,
    });
  }

  triggerUpgradeModalOpen(desiredAction, paywall, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall: paywall,
      featuresList: featuresList,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  areStatsLocked = () => {
    // every user can see their own stats, do not block with a lock
    if (this.props.learner.flags.isCurrentUser) {
      return false;
    }

    // only pack admins can see other learner's stats
    if (this.props.pack.permission != 'admin') {
      return true;
    }

    // if user is a pack admin, we let them see stats of one user as a demo
    if (this.props.learnerIndex == 1) {
      return false;
    }

    // if the pack admin is not pro, they cannot access learner stats beyond the demo stats
    if (!this.props.currentUser.flags.isPro) {
      return true;
    }

    // if the pack admin is pro, and the learner is pro, they can see the stats (buying a license from us allows them to make learners pro at a discount)
    if (this.props.learner.flags.isPro) {
      return false;
    }

    // all other cases, the stats should be locked
    return true;
  }

  canUserChangePermissions = () => {
    return (this.props.pack.permission == 'admin' || (this.props.learner?.flags?.isCurrentUser && this.props.currentUser.flags.isBscAdmin));
  }

  canUserViewStats = () => {
    if (this.props.learner?.flags?.isCurrentUser) {
      return true;
    }

    return (this.props.pack.permission == 'admin');
  }

  completePermissionChange = (optionId) => {
    const learner = this.props.learner;

    const learnerData = {
      permissionOptionId: optionId,
      discoveryMedium: learner.discoveryMedium,
    };

    packLearner.update(this.props.pack.packId, learner.userId, learnerData).then(() => {
      this.setState({
        isProcessingPermissionChange: false,
      });
    });
  }

  openLearnerProfile = () => {
    this.setState({
      isProcessing: true,
    });

    UiHelper.openInNewTab(this.props.learner.profilePath, 'profile');
  }

  performPermissionChange = (optionId) => {
    const pack = this.props.pack;
    const learner = this.props.learner;
    const {permissionValue, permissionLabel} = packLearner.showPermission(learner);
    const currentUser = this.props.currentUser;

    if (learner.flags.isCurrentUser && permissionValue == 'admin' && optionId != 'admin' && !(currentUser.flags.isBscAdmin || currentUser.flags.isBscEmployee)) {

      const message = (
        <div className="modal-message">
          You are about to remove your own Admin rights. After this change takes
          place, you will not be able to reassign yourself Admin rights to this
          Class. Are you sure that you wish to proceed?
        </div>
      );

      this.triggerCautionModalOpen({
        message: message,
        resolveButtonText: 'Yes, I know what I am doing',
        onResolution: () => {
          this.triggerCautionModalClose();
          this.completePermissionChange(optionId);
        },
        onCloseRequest: () => {
          this.setState({
            isProcessingPermissionChange: false,
          });
        }
      });

      return true;
    }

    this.completePermissionChange(optionId);
  }

  removeLearner = () => {
    packLearner.remove(this.props.pack.packId, this.props.learner.userId);
  }
}

export default LearnerRow;
