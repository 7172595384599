
import EventManager              from '@brainscape/event-manager';
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import SimpleTextButton          from '_views/shared/SimpleTextButton';
import StringHelper              from '_utils/StringHelper';
import TextareaField             from '_views/shared/TextareaField';
import UiHelper                  from '_utils/UiHelper';

class SuggestEditModalSet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasValidInput: false,
      isConfirmationModalOpen: false,
      isProcessing: false,
      suggestionText: "",
      shouldOpenConfirmationModal: false,
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        cardContentType,
        cardId,
        deckId,
        isOpen,
        isUserPro,
        onCopyAndPlaceCardRequest,
        onCloseRequest,
        onSubmitSuggestion,
        packId,
        suggestionMetadata,
    */

    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.resetFields();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    return (
      <div className="modal-set suggest-edit-modal-set">
        {this.renderInputModal()}
        {this.renderConfirmationModal()}
      </div>
    );
  }

  renderInputModal() {
    const suggestEditData = this.props.suggestionMetadata?.suggestEdit || null;

    if (!suggestEditData?.canSuggest) {
      return this.renderSuggestionsNotPermittedModal();
    }

    return (
      <Modal
        addClasses="suggest-edit-input-modal"
        isOpen={this.props.isOpen}
        onClosed={this.handleInputModalClosed}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Suggest An Edit</div>

        <div className="modal-message">{this.renderModalMessage()}</div>

        <form
          className="suggestion-form"
          onSubmit={this.handleFormSubmit}
        >

          <TextareaField 
            addClasses="suggestion"
            id="suggestion"
            onChange={this.handleSuggestionChange}
            name="suggestion"
            placeholder="Type your suggestion to the author here"
            value={this.state.suggestionText}
          />
        </form>

        <div className="modal-actions">

          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="Cancel"
            onClick={this.handleCancelButtonClick}
          />

          <PillButton
            addClasses="resolve-modal-button"
            isDisabled={!this.state.hasValidInput}
            isProcessing={this.state.isProcessing}
            label="Send suggestion"
            onClick={this.handleSendSuggestionButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderSuggestionsNotPermittedModal = () => {
    const suggestEditData = this.props.suggestionMetadata?.suggestEdit || null;
    const creatorName = suggestEditData?.creatorName ? `(${suggestEditData?.creatorName})` : '';

    return (
      <Modal
        addClasses="suggestions-not-permitted-modal"
        isOpen={this.props.isOpen}
        onClosed={this.handleInputModalClosed}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Info</div>

        <div className="modal-message">You do not have permissions to edit or suggest changes to this class. If you know the class creator {creatorName}, ask them to invite you to the class.</div>

        <div className="modal-actions">

          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={this.handleCancelButtonClick}
          />
        </div>
      </Modal>
    );
  }

//   renderCopyCardButton() {
//     if (!(this.props.suggestionMetadata?.suggestEdit?.suggestCopy)) {
//       return null;
//     }
// 
//     return (
//       <SimpleTextButton
//         addClasses="copy-card-text-button resolve-modal-text-button"
//         label="Copy Card"
//         onClick={this.handleCopyCardButtonClick}
//       />
//     );
//   }

  renderModalMessage = () => {
    const suggestEditData = this.props.suggestionMetadata?.suggestEdit || null;

    if (suggestEditData?.certified) {
      return 'Brainscape-certified flashcards are maintained by our partners. We will pass your suggestion on to them.';
    }

    const creatorName = suggestEditData?.creatorName || 'the class creator';

    return `Your suggestion will be sent to ${creatorName} to consider.`
  }

  renderConfirmationModal() {
    const recipient = this.props.suggestionMetadata?.suggestEdit?.creatorName || 'the class creator';

    return (
      <Modal
        addClasses="suggestion-confirmation-modal"
        isOpen={this.state.isConfirmationModalOpen}
        onCloseRequest={this.handleCloseConfirmationModalRequest}
      >

        <div className="modal-title">Suggestion Sent<span className="out-box">📤</span></div>

        <div className="modal-message">You'll earn good Karma if {recipient} accepts your edit suggestion.</div>

        <div className="modal-actions">

          <PillButton
            addClasses="resolve-modal-button"
            label="Cool!"
            onClick={this.handleCloseConfirmationModalRequest}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = () => {
    this.props.onCloseRequest();
  }

  handleCloseConfirmationModalRequest = () => {
    this.setState({
      isConfirmationModalOpen: false,
      shouldOpenConfirmationModal: false,
    })
  }

//   handleCopyCardButtonClick = () => {
//     if (!this.props.isUserPro) {
//       this.triggerUpgradeModalOpen('Copy and Place Cards', 'copy_and_place_cards');
//     } else {
//       this.triggerCopyAndPlaceCardsModalSetOpen();
//     }
// 
//     this.props.onCloseRequest();
//   }

  handleInputModalClosed = () => {
    if (this.state.shouldOpenConfirmationModal) {
      this.setState({
        isConfirmationModalOpen: true,
      })
    }
  }

  handleSendSuggestionButtonClick = () => {
    if (this.state.hasValidInput && !this.props.isProcessing) {
      this.props.onSubmitSuggestion(this.state.suggestionText);
      this.showConfirmationModal();
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.hasValidInput && !this.props.isProcessing) {
      this.props.onSubmitSuggestion(this.state.suggestionText);
      this.showConfirmationModal();
    }
  }

  handleSuggestionChange = (e) => {
    const suggestionText = e.target.value;
    const hasValidInput = !StringHelper.isWhitespace(suggestionText);

    this.setState({
      hasValidInput: hasValidInput,
      suggestionText: suggestionText,
    })
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  // triggerCopyAndPlaceCardsModalSetOpen() {
  //   EventManager.emitEvent('copy-and-place-cards-modal-set:open', {
  //     cardIds       : [this.props.cardId],
  //     contentType   : this.props.cardContentType,
  //     deckId        : this.props.deckId,
  //     packId        : this.props.packId,
  //   });
  // }

  // triggerUpgradeModalOpen(desiredAction, paywall) {
  //   EventManager.emitEvent('upgrade-modal:open', {
  //     desiredAction: desiredAction,
  //     paywall:       paywall,
  //   });
  // }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  resetFields() {
    this.setState({
      hasValidInput                 : false,
      isConfirmationModalOpen       : false,
      isProcessing                  : false,
      suggestionText                : "",
      shouldOpenConfirmationModal   : false,
    });
  }

  showConfirmationModal() {
    this.setState({
      shouldOpenConfirmationModal: true,
    }, () => {
      this.props.onCloseRequest();
    });
  }
}

export default SuggestEditModalSet;
