
import EventManager           from '@brainscape/event-manager';

import pack                   from '_models/pack';
import packStatTransform      from '_models/packStatTransform';


const currentPackConcern = {

  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch(packId) {
    return new Promise((resolve, reject) => {
      try {
        pack.show(packId).then(packData => {

          pack.setCachedItem(packId, packData);

          const currentPackData = {
            currentPack: packData,
            isFromCache: false,
          };

          this.publishPackRetrieved(currentPackData);

          resolve(currentPackData);
        }).catch(err => {
          console.error('Problem fetching pack data. packId, err:', packId, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  get(packId) {
    // get checks session store for cached objects, publishes immediately to allow display, then refreshes cached copy by conducting fetches against the API

    return new Promise((resolve, reject) => {
      try {
        const currentPack = pack.getCachedItem(packId);

        if (currentPack) {
          const currentPackData = {
            currentPack: currentPack,
            isFromCache: true,
          };

          this.publishPackRetrieved(currentPackData);
        }

        // continue on to refresh cache
        this.fetch(packId).then(currentPackData => {
          resolve(currentPackData);
        }).catch(err => {
          console.error('Problem getting pack data. packId, err:', packId, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  handleCardCreated(currentPack, eventData) {
    const updatedCard = eventData.card;

    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;

    updatedPack.stats = {...updatedPack.stats, ...eventData.packStats};

    if (updatedPack.deckIds && updatedPack.localDeckTransforms) {
      const transformedPackStats = packStatTransform.show(updatedPack);
      updatedPack.transformedStats = transformedPackStats;
    }

    pack.setCachedItem(updatedPackId, updatedPack);

    return {
      currentPack: updatedPack,
    }
  },

  handleCardInserted(currentPack, eventData) {
    return this.handleCardCreated(currentPack, eventData);
  },

  handleCardRemoved(currentPack, eventData) {
    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;

    updatedPack.stats = {...updatedPack.stats, ...eventData.packStats};

    if (updatedPack.deckIds && updatedPack.localDeckTransforms) {
      const transformedPackStats = packStatTransform.show(updatedPack);
      updatedPack.transformedStats = transformedPackStats;
    }

    pack.setCachedItem(updatedPackId, updatedPack);

    return {
      currentPack: updatedPack,
    }
  },

  handleCardConfidenceUpdated(currentPack, eventData) {
    const {deckStats, packStats, deckId} = eventData;

    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;
    updatedPack.stats = {...updatedPack.stats, ...packStats};

    if (updatedPack.decks) {
      const decks = [...updatedPack.decks];
      const changedDeckIndex = updatedPack.deckIds.indexOf(deckId);

      if (changedDeckIndex != -1) {
        const changedDeck = decks[changedDeckIndex];
        changedDeck.stats = {...changedDeck.stats, ...deckStats};
        updatedPack.decks = decks;
      }

      const transformedPackStats = packStatTransform.show(updatedPack);
      updatedPack.transformedStats = transformedPackStats;
    }

    pack.setCachedItem(updatedPackId, updatedPack);

    return {
      currentPack: updatedPack,
    }
  },

  handlePackUpdated(currentPack, eventData) {
    const currentPackId = currentPack.packId;
    const updatedPack = {...currentPack, ...eventData.pack};

    pack.setCachedItem(currentPackId, updatedPack);

    return {
      currentPack: updatedPack,
    };
  },

  publishPackRetrieved(currentPackData) {
    EventManager.emitEvent('pack:retrieved', currentPackData);
  },
};

export default currentPackConcern;
