
import model                  from '_core/newModel';
import SessionStorageHelper   from '_utils/SessionStorageHelper';

const packMetadata = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  show(packId) {
    const path = 'packs/' + packId + '/metadata';

    return new Promise((resolve, reject) => {
      try {
        model.show(path).then(rsJson => {
          const metadata = this.postProcess(rsJson);
          resolve(metadata);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  update(packId, metadata) {
    const path = 'packs/' + packId + '/metadata';
    const data = {
      metadata: metadata,
    };

    return new Promise((resolve, reject) => {
      try {
        model.update(path, data).then(updatedMetadata => {
          const processedMetadata = this.postProcess(updatedMetadata);
          model.publish('pack-metadata:updated', {
            metadata: processedMetadata,
            packId: packId,
          });

          resolve(processedMetadata);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   CACHING
  ==================================================
  */

  getCachedItem(packId) {
    return SessionStorageHelper.getPackItem(packId, 'packMetadata') || null;
  },

  setCachedItem(packId, packMetadata) {
    SessionStorageHelper.setPackItem(packId, 'packMetadata', packMetadata);
  },


  /*
  ==================================================
   CONVENIENCE METHODS
  ==================================================
  */

  getPurpose(metadata) { // metadata will need to be on hand via a previous call to show...
    let purposeLabel = null;

    if (metadata?.purposes) {
      const purposes = metadata.purposes;
      const purposeKeys = Object.keys(purposes);

      for (let i = 0; i <= purposeKeys.length; i++) {
        const purposeKey = purposeKeys[i];
        const purpose = purposes[purposeKey];

        if (purpose && purpose.value == true) {
          purposeLabel = purpose.field_label;
          break;
        }
      }
    }

    return purposeLabel;
  },

  getRole(metadata) { // metadata will need to be on hand via a previous call to show...
    return metadata?.global?.role?.value || null;
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  postProcess(metadata) {
    let processedMetadata = {...metadata};
    const globalKey = '__global__';
    const purposesKey = '__meta_purpose__';

    processedMetadata.global = metadata[globalKey];
    processedMetadata.purposes = metadata[purposesKey];

    delete processedMetadata[globalKey];
    delete processedMetadata[purposesKey];

    return processedMetadata;
  }
};

export default packMetadata;
