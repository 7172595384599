
import DeckOptionsButton              from '_views/shared/DeckOptionsButton';
import EventManager                   from '@brainscape/event-manager';
import PillButton                     from '_views/shared/PillButton';
import PropTypes                      from 'prop-types';
import Pulldown                       from '_views/shared/Pulldown';
import React                          from 'react';
import Spinner                        from '_views/shared/Spinner';
import StudyButton                    from '_views/shared/StudyButton';
import StudyPillButton                from '_views/shared/StudyPillButton';
import StudyHelper                    from '_utils/StudyHelper';
import TabSwitcher                    from '_views/shared/TabSwitcher';
import TextField                      from '_views/shared/TextField';
import Tracker                        from '_utils/Tracker';
import UiHelper                       from '_utils/UiHelper';
import userLocalStore                 from '_models/userLocalStore';

import {apiPatch}                     from '_core/Api2';
import {toClassStr}                   from '_utils/UiHelper';

import packDeck                       from '_models/packDeck';
import packDeckOptions                from '_models/packDeckOptions';

import {
  DismissButton,
  EditButton,
}                                     from '_views/shared/IconButton';


const PT = {
  addClasses:               PropTypes.string,
  currentCardId:            PropTypes.node,
  currentDeck:              PropTypes.object,
  currentPack:              PropTypes.object,
  currentTabId:             PropTypes.node,
  currentUser:              PropTypes.object,
  isMobileViewportSize:     PropTypes.bool,
  isPaginatingDeckCards:    PropTypes.bool,
  tabs:                     PropTypes.object,
}


class DeckDetailHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeckPulldownOpen: false,
      isEditingDeckDesc: false,
      isEditingDeckName: false,
      isHoveringPackName: false,
      isProcessingDeckChange: false,
      isProcessingDeckNameUpdate: false,
      deckDesc: this.props.currentDeck?.desc || "",
      deckName: this.props.currentDeck?.name || "",
      deckOptions: [],
      savedDeckDesc: this.props.currentDeck?.desc || "",
      savedDeckName: this.props.currentDeck?.name || "",
    };

    this._isMounted = false;

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.getDeckOptions();
    this.subscribeToEvents();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentDeck != prevProps.currentDeck) {
      this.resetDeckInfo();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.events.disable();
  }

  subscribeToEvents = () => {
    this.events.addListener('deck:created',  this.handleDeckCreated);
    this.events.addListener('deck:removed',  this.handleDeckRemoved);
    this.events.addListener('deck:updated',  this.handleDeckUpdated);
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const deck = this.props.currentDeck;
    const pack = this.props.currentPack;

    const isHoveringPackNameClass = this.state.isHoveringPackName ? 'is-hovering-pack-name' : '';

    const isUserBscAdminClass = this.props.currentUser.flags.isBscAdmin ? 'is-bsc-admin' : '';

    const classes = toClassStr(['deck-detail-header', isHoveringPackNameClass, isUserBscAdminClass, this.props.addClasses]);

    const artworkUrl = pack.activeIconUrl || pack.iconUrl || '/assets/app_icons/ugc-white-bg.svg';

    return (
       <header className={classes}>

        <div className="first-row">

          <div className="pack-and-deck">

            <div className="pack" onClick={this.handlePackInfoClick}>
              <div className="pack-artwork">
                <img className="pack-icon-image" src={artworkUrl} data-img-kind="ugsIconWhiteBg"/>
              </div>

              <div className="pack-name" 
                onMouseEnter={this.handlePackNameMouseEnter} 
                onMouseLeave={this.handlePackNameMouseLeave}
                title={pack.name} 
              >
                {pack.name}
              </div>
            </div>

            <div className="deck-name-and-desc">
              <div className="deck">
                {this.renderDeckNamePulldownOrForm()}

                <DeckOptionsButton
                  addClasses="action-button"
                  currentDeck={this.props.currentDeck}
                  currentPack={this.props.currentPack}
                  currentUser={this.props.currentUser}
                  iconType="horizontal"
                  key="deck-options-button"
                  tooltipContent="Edit, Copy, Reset, more"
                  tooltipPosition="bottom"
                />

              </div>

              {this.renderDeckDescOrForm()}
            </div>
          </div>

          {this.renderStudyButton()}
        </div>           

        <div className="second-row">
          <TabSwitcher
            currentTabId={this.props.currentTabId}
            onTabClick={this.handleTabClick}
            tabs={this.props.tabs}
          />
        </div>
      </header>
    );
  }

  renderDeckNamePulldownOrForm() {
    if (this.state.isEditingDeckName) {
      return this.renderDeckNameForm();
    }

    return (
      <div className="deck-name-pulldown-and-edit-button">
        {this.renderDeckNameAndPulldown()}
        {this.renderDeckChangeSpinner()}
        {this.renderDeckNameEditButton()}
      </div>
    );
  }

  renderDeckNameAndPulldown() {
    const deck = this.props.currentDeck;
    const pack = this.props.currentPack;

    return (
      <div className="deck-name-and-pulldown" title={this.state.savedDeckName}>
        
        <div className="deck-name">{this.state.savedDeckName}</div>

        <Pulldown
          addClasses="deck-pulldown"
          isOpen={this.state.isDeckPulldownOpen}
          options={this.state.deckOptions}
          selectedValue={deck.deckId}
          shouldSuppressNullOption={true}
          onButtonClick={this.handleDeckPulldownButtonClick}
          onOptionClick={this.handleDeckPulldownOptionClick}
          onOutsideClick={this.handleDeckPulldownOutsideClick}
        />
      </div>
    );
  }

  renderDeckChangeSpinner() {
    if (!this.state.isProcessingDeckChange) {
      return null;
    }

    return (
      <Spinner 
        color="blue"
      />
    );
  }

  renderDeckNameEditButton() {
    if (!this.props.currentDeck.flags.isEditable) {
      return null;
    }

    if (this.props.currentPack.permission != 'admin') {
      return null;
    }

    if (this.state.isProcessingDeckChange) {
      return null;
    }

    return (
      <EditButton
        addClasses="edit-deck-name-button"
        isDisabled={this.state.isEditingDeckDesc}
        onClick={this.handleEditDeckNameButtonClick}
        tooltipContent="Edit Deck title"
        tooltipPosition="bottom"
      />
    );
  }

  renderDeckNameForm() {
    return (
      <form className="deck-name-form" onSubmit={this.handleDeckNameFormSubmit}>
        <TextField 
          addClasses="deck-name-text-field"
          buttons={this.renderDeckNameFormFieldButtons()}
          hasInitialFocus={true}
          id="deck-name"
          onChange={this.handleDeckNameChange}
          placeholder={this.props.placeholder || 'Enter Deck Name'}
          value={this.state.deckName}
        />

        <input type="submit" className="hidden-submit-button" />
      </form>
    );
  }

  renderDeckNameFormFieldButtons() {
    return (
      <div className="deck-name-form-field-buttons">

        <DismissButton 
          addClasses="dismiss-deck-name-form-button"
          onClick={this.handleDeckNameFormDismissButtonClick}
        />

        <PillButton
          addClasses="save-deck-name-form-button"
          isProcessing={this.state.isProcessingDeckNameUpdate}
          label="Save"
          onClick={this.handleDeckNameFormSaveButtonClick}
        />
      </div>
    );
  }

  renderDeckDescOrForm() {
    const {currentPack, currentDeck} = this.props;

    if (this.state.isEditingDeckDesc) {
      return this.renderDeckDescForm();
    }

    if (!currentDeck.flags.isEditable && !currentDeck.desc) {
      return null;
    }

    return (
      <div className="deck-desc-and-edit-button">
        {this.renderDeckDesc()}
        {this.renderDeckDescEditButton()}
      </div>
    );
  }

  renderDeckDescForm() {
    return (
      <form className="deck-desc-form" onSubmit={this.handleDeckDescFormSubmit}>
        <TextField
          addClasses="deck-desc-text-field"
          buttons={this.renderDeckDescFormFieldButtons()}
          hasInitialFocus={true}
          id="deck-desc"
          onChange={this.handleDeckDescChange}
          placeholder={'Enter Deck objective'}
          value={this.state.deckDesc}
        />

        <input type="submit" className="hidden-submit-button" />
      </form>
    );
  }

  renderDeckDescFormFieldButtons() {
    return (
      <div className="deck-desc-form-field-buttons">
        <DismissButton 
          addClasses="dismiss-deck-desc-form-button"
          onClick={this.handleDeckDescFormDismissButtonClick}
        />

        <PillButton
          addClasses="save-deck-desc-form-button"
          isProcessing={this.state.isProcessingDeckDescUpdate}
          label="Save"
          onClick={this.handleDeckDescFormSaveButtonClick}
        />
      </div>
    );
  }

  renderDeckDesc() {
    const {currentPack, currentDeck} = this.props;

    if (currentDeck.flags.isEditable) {

      if (!currentDeck.desc) {
        return (
          <div className="deck-desc placeholder">
            Add a short description of the topics covered in this deck
          </div>
        );
      }

      return (
        <div className="deck-desc" title={currentDeck.desc}>
          {currentDeck.desc}
        </div>
      );
    }

    if (!currentDeck.desc) {
      return (
        <div className="deck-desc" />
      )
    }

    return (
      <div className="deck-desc" title={currentDeck.desc}>
        {currentDeck.desc}
      </div>
    );
  }

  renderDeckDescEditButton() {
    if (!this.props.currentDeck.flags.isEditable) {
      return null;
    }

    if (this.state.isEditingDeckName) {
      return null;
    }

    if (this.state.isProcessingDeckChange) {
      return null;
    }

    return (
      <EditButton
        addClasses="edit-deck-desc-button"
        onClick={this.handleEditDeckDescButtonClick}
        tooltipContent="Edit Deck objective"
        tooltipPosition="bottom"
      />
    );
  }

  renderStudyButton() {
    const pack = this.props.currentPack;
    const deck = this.props.currentDeck;

    const isDisabled = (this.props.currentCardHasChanges || deck.stats.cardCount < 1);
    const buttonLabel = this.props.isMobileViewportSize ? 'Study' : 'Study Deck';
    const studyMixUrl = StudyHelper.getPackDeckStudyMixUrl(this.props.currentUser.userId, pack, deck.deckId);

    return (
      <StudyPillButton 
        isDisabled={isDisabled}
        label={buttonLabel}
        studyMixUrl={studyMixUrl}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleDeckCreated = (eventData) => {
    if (eventData.packId != this.props.currentPack.packId) {
      return false;
    }

    this.getDeckOptions();
  }

  handleDeckRemoved = (eventData) => {
    if (eventData.packId != this.props.currentPack.packId) {
      return false;
    }

    this.getDeckOptions();
  }

  handleDeckUpdated = (eventData) => {
    if (eventData.packId != this.props.currentPack.packId) {
      return false;
    }

    this.getDeckOptions();
  }


  handleEditDeckDescButtonClick = () => {
    this.setState({
      isEditingDeckDesc: true,
    })
  }

  handleDeckDescChange = (e) => {
    this.setState({
      deckDesc: e.target.value,
    })
  } 

  handleDeckDescFormDismissButtonClick = () => {
    this.setState({
      isEditingDeckDesc: false,
      deckDesc: this.state.savedDeckDesc,
    })
  }

  handleDeckDescFormSaveButtonClick = () => {
    this.handleDeckDescFormSubmit();
  }

  handleDeckDescFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.updateDeckDesc();
  }

  handleEditDeckNameButtonClick = () => {
    this.setState({
      isEditingDeckName: true,
    })
  }

  handleDeckNameChange = (e) => {
    this.setState({
      deckName: e.target.value,
    })
  } 

  handleDeckNameFormDismissButtonClick = () => {
    this.setState({
      isEditingDeckName: false,
      deckName: this.state.savedDeckName,
    })
  }

  handleDeckNameFormSaveButtonClick = () => {
    this.handleDeckNameFormSubmit();
  }

  handleDeckNameFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.state.deckName != "") {
      this.updateDeckName();

    } else {
      this.setState({
        deckName: this.state.savedDeckName,
        isEditingDeckName: false,
      });
    }
  }

  handleDeckPulldownButtonClick = () => {
    if (this.state.isEditingDeckDesc) {
      return false;
    }

    this.setState({
      isDeckPulldownOpen: !this.state.isDeckPulldownOpen,
    });
  }

  handleDeckPulldownOptionClick = (optionId) => {
    this.setState({
      isDeckPulldownOpen: false,
    }, () => {
      if (optionId == 'new') {
        this.triggerCreateDeckModalOpen();
      } else {
        this.triggerCurrentDeckChangeRequest(optionId);
      }
    });
  }

  handleDeckChanged = () => {
    this.setState({
      isProcessingDeckChange: false,
    });
  }

  handleDeckPulldownOutsideClick = () => {
    this.setState({
      isDeckPulldownOpen: false,
    })
  }

  handlePackInfoClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.triggerPackDetailViewRequest(this.props.currentPack.packId);
  }

  handlePackNameMouseEnter = () => {
    this.setState({
      isHoveringPackName: true,
    })
  }

  handlePackNameMouseLeave = () => {
    this.setState({
      isHoveringPackName: false,
    })
  }

  handleStudyButtonClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.currentCardHasChanges) {
      return false;
    }

    const deck = this.props.currentDeck;
    const pack = this.props.currentPack;
    const studyMixPath = StudyHelper.getPackDeckStudyMixUrl(pack.userId, pack, deck.deckId);

    if (deck.isLocked) {
      Tracker.trackPaywallWarning('locked_deck');
      this.triggerUnlockDeckModalOpen();
      return false;
    }

    if (!deck.hasCards) {
      this.triggerDeckHasNoCardsModalOpen();
      return false;
    }

    if (!deck.isStudyable) {
      Tracker.trackPaywallWarning('study_rights');
      this.triggerStudyRightsUpgradeModalOpen();
      return false;
    }

    UiHelper.navigate(studyMixPath, 'Loading Study Experience');
  }

  handleTabClick = (tabId) => {
    this.triggerCurrentTabChangeRequest(tabId);
  }



  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCurrentDeckChangeRequest = (deckId) => {
    EventManager.emitEvent('current-deck:change-request', {
      deckId: deckId,
      tabId: this.props.currentTabId,
    });
  }

  triggerCurrentTabChangeRequest(tabId) {
    EventManager.emitEvent('current-tab:change-request', {tabId: tabId});
  }

  triggerCreateDeckModalOpen = () => {
    EventManager.emitEvent('create-deck-modal:open', {
      currentUser: this.props.currentUser,
      packId: this.props.currentPack.packId,
    })
  }

  triggerDeckHasNoCardsModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message: "Before you can study this deck, you or the Class creator must add cards to this deck.",
      resolveButtonText: 'Got it',
      title: 'This Deck Has No Cards',
    });
  }

  triggerPackDetailViewRequest = (packId, deckId=null, cardId=null, tabId='decks') => {
    EventManager.emitEvent('pack-detail-view:change-request', {
      packId: packId,
      deckId: deckId,
      cardId: cardId,
      tabId: tabId,
    });
  }

  triggerStudyRightsUpgradeModalOpen = () => {
    EventManager.emitEvent('upgrade-modal:open', {
      message: 'This Deck requires Pro to Study.',
      paywall: 'study_rights',
    });
  }

  triggerCurrentTabChangeRequest(tabId) {
    EventManager.emitEvent('current-tab:change-request', {tabId: tabId});
  }

  triggerUnlockDeckModalOpen(onCloseRequestCallback) {
    EventManager.emitEvent('unlock-deck-modal:open', {
      onCloseRequest: onCloseRequestCallback,
      pack:           this.props.currentPack,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getDeckOptions = () => {
    packDeckOptions.index(this.props.currentPack.packId, this.props.currentDeck.deckId).then(options => {
      let deckOptions = options;

      if (["edit", "admin"].includes(this.props.currentPack.permission)) {
        deckOptions.push({
          id: 'new',
          label: 'Create New Deck...',
        });
      }

      this.setState({
        deckOptions: deckOptions,
      });
    }).catch(err => {
      console.error(err);
    });
  }

  performChangeDeck = (deckId) => {
    if (deckId == this.props.currentDeck.deckId) {
      return false;
    }

    this.triggerSetCurrentDeckRequest(deckId);
  }

  resetDeckInfo = () => {
    const desc = this.props.currentDeck.desc;
    const name = this.props.currentDeck.name;

    this.setState({
      deckDesc: desc,
      deckName: name,
      isDeckPulldownOpen: false,
      isEditingDeckName: false,
      isHoveringPackName: false,
      isProcessingDeckChange: false,
      isProcessingDeckDescUpdate: false,
      isProcessingDeckNameUpdate: false,
      savedDeckDesc: desc,
      savedDeckName: name,
    });
  }

  updateDeckDesc = () => {
    this.setState({
      isProcessingDeckDescUpdate: true,
    });

    const packId = this.props.currentPack.packId;
    const deckId = this.props.currentDeck.deckId;

    const deckData = {
      desc: this.state.deckDesc,
    };

    packDeck.update(packId, deckId, deckData).then(() => {
      this.setState({
        isEditingDeckDesc: false,
        isProcessingDeckDescUpdate: false,
        savedDeckDesc: this.state.deckDesc,
      });
    }).catch(err => {
      console.log('Something went wrong in DeckDetailheader.updateDeckDesc. err:', err);

      this.setState({
        isEditingDeckDesc: false,
        isProcessingDeckDescUpdate: false,
      });
    });
  }

  updateDeckName = () => {
    this.setState({
      isProcessingDeckNameUpdate: true,
    });

    const packId = this.props.currentPack.packId;
    const deckId = this.props.currentDeck.deckId;

    const deckData = {
      name: this.state.deckName,
    };

    packDeck.update(packId, deckId, deckData).then(() => {
      this.setState({
        isEditingDeckName: false,
        isProcessingDeckNameUpdate: false,
        savedDeckName: this.state.deckName,
      });
    }).catch(err => {
      console.log('Something went wrong in DeckDetailheader.updateDeckName. err:', err);

      this.setState({
        isEditingDeckName: false,
        isProcessingDeckNameUpdate: false,
      });
    });
  }
}

DeckDetailHeader.propTypes = PT;

export default DeckDetailHeader;
