
import EventManager   from "@brainscape/event-manager";
import React          from 'react';
import StringHelper   from '_utils/StringHelper';

import {toClassStr} from '_utils/UiHelper';

import {EditButton, ExpandButton, InfoButton, PlayButton, PauseButton} from '_views/shared/IconButton';

class StudyCardFace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    /*
      this.props:
        addClasses,
        card,
        cardContentType,
        confidenceLevel,
        currentSound: {
          isPresent,
          isPlaying,
          isAnswerFace,
          soundUrl,
        },
        isAnswerFace,
        mixName,
        onCardFlipRequest,
        onPauseSoundRequest,
        onPlaySoundRequest,
        onViewLargeImageRequest,
        stepDeckCardCount,
        stepDeckCardIndex,
        stepDeckName,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const faceClass = this.props.isAnswerFace ? 'answer-face' : 'question-face';
    const confidenceClass = 'confidence-' + this.props.confidenceLevel;
    const classes = toClassStr(['study-card-face', faceClass, confidenceClass, this.props.addClasses]);

    return (
      <div className={classes} ref="card" onClick={(e) => this.handleCardClick(e)}>
        <header className="card-face-header">
          <div className="card-type-indicator">{(this.props.isAnswerFace) ? "A" : "Q"}</div>
          <div className="card-controls">
            {this.renderPlayPauseToggle()}
            {this.renderEditButton()}
            {this.renderInfoButton()}
          </div>
        </header>

        <div className="card-content">
          {this.renderCardPrompt()}
          {this.renderCardBody()}
        </div>
      </div>
    );
  }

  renderPlayPauseToggle() {
    if (!(this.props.currentSound && this.props.currentSound.isPresent)) {
      return null;
    }

    if (this.props.currentSound.isPlaying) {
      return (
        <PauseButton
          addClasses="card-sound-control"
          onClick={() => this.handlePauseSoundClick()}
        />
      );
    }

    return (
      <PlayButton
        addClasses="card-sound-control"
        onClick={() => this.handlePlaySoundClick()}
      />
    );
  }

  renderEditButton() {
    const card = this.props.card;

    const title = (!card.canEdit) ? 'Suggest Changes to this Card' : (card.contentType == 'text') ? 'Edit this Card' : 'Close Study and Edit this Card';

    return (
      <EditButton
        addClasses='edit-card-button'
        onClick={this.handleEditButtonClick}
        tooltipContent={title}
        tooltipPosition="bottom"
      />
    );
  }

  renderInfoButton() {
    return (
      <InfoButton
      addClasses="info-card-button"
      onClick={() => {}}
      shouldAllowClickPropagation={true}
      tooltipContent={this.renderInfoButtonTooltip()}
      tooltipPosition="bottom"
      />
    );
  }

  renderInfoButtonTooltip() {
    return (
      <div className="tooltip-content full-name-and-description">
        <p className="tooltip-text">Mix: {this.props.mixName}</p>
        <p className="tooltip-text">Deck: {this.props.stepDeckName}</p>
        <p className="tooltip-text">Card: {this.props.stepDeckCardIndex}/{this.props.stepDeckCardCount}</p>
      </div>
    );
  }

  renderCardPrompt() {
    if (!this.props.card.prompt || this.props.isAnswerFace) {
      return null;
    }

    const prompt = StringHelper.safeDecode(this.props.card.prompt);

    return (
      <div className="card-prompt" onClick={(e) => this.handleCardPromptClick(e)}>
        {prompt}
      </div>
    );
  }

  renderCardBody() {
    let content = (this.props.isAnswerFace) ? this.props.card.answer : this.props.card.question;

    return (
      <div className="card-body" onClick={(e) => this.handleCardBodyClick(e)}>
        <div className="inner-html" dangerouslySetInnerHTML={{__html: this.massageContent(content)}} />
        {this.renderCardImage()}
      </div>
    );
  }

  renderCardImage() {
    let imageUrl = (this.props.isAnswerFace) ? this.props.card.aImageUrl || this.props.card.aImageURL || null : this.props.card.qImageUrl || this.props.card.qImageURL || null;
    let fullSizeUrl = (this.props.isAnswerFace) ? this.props.card.aOriginalImageUrl || this.props.card.aOriginalImageURL || null : this.props.card.qOriginalImageUrl || this.props.card.qOriginalImageURL || null;

    if (!imageUrl) {
      return null;
    }

    const zoomClass = (fullSizeUrl) ? 'is-zoomable' : '';
    const classes = toClassStr(['card-image', zoomClass]);

    return (
      <div className={classes}>
        <img className="card-sized-image" src={imageUrl} onClick={(e) => this.handleImageClick(e, fullSizeUrl)} />
        {this.renderExpandButton(fullSizeUrl)}
      </div>
    );
  }

  renderExpandButton(fullSizeUrl) {
    if (!fullSizeUrl) {
      return null;
    }

    return (
      <ExpandButton
        addClasses="card-image-expand-button"
        onClick={() => this.handleImageExpandButtonClick(fullSizeUrl)}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCardBodyClick(e) {
    e.stopPropagation();
  }

  handleCardPromptClick(e) {
    e.stopPropagation();
  }

  handleCardClick(e) {
    e.stopPropagation();
    this.props.onCardFlipRequest();
  }

  handleEditButtonClick = (e) => {
    e.stopPropagation();

    EventManager.emitEvent('edit-card:request', {
      cardId: this.props.card.cardId,
      deckId: this.props.card.deckId,
      packId: this.props.card.packId,
    });
  }

  handleImageClick(e, fullSizeUrl) {
    e.stopPropagation();

    if (fullSizeUrl) {
      this.props.onViewLargeImageRequest(fullSizeUrl);
    }
  }

  handleImageExpandButtonClick(url) {
    this.props.onViewLargeImageRequest(url);
  }

  handlePauseSoundClick() {
    this.props.onPauseSoundRequest();
  }

  handlePlaySoundClick() {
    this.props.onPlaySoundRequest();
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  massageContent(content) {
    const massagedContent = this.props.card.contentType === 'text'
      ? StringHelper.simpleFormat(content)
      : content;

    var span= document.createElement('span');
    var anchors;
    span.innerHTML = massagedContent;
    anchors = span.getElementsByTagName('a');
    if (!anchors) { anchors = [] }

    for (var i=0; i<anchors.length; i++) {
      anchors[i].setAttribute('target', '_blank');
      anchors[i].setAttribute('rel', 'nofollow noopener noreferrer');
    }

    return span.innerHTML;
  }
}

export default StudyCardFace;
