
import EventManager       from '@brainscape/event-manager';
import PropTypes          from 'prop-types';
import React              from 'react';

import {DismissButton}    from '_views/shared/IconButton';

import {toClassStr}       from '_utils/UiHelper';

const PT = {
  addClasses:             PropTypes.string,
  actions:                PropTypes.array,
  body:                   PropTypes.oneOfType([
                            PropTypes.node,
                            PropTypes.object,
                          ]),
  hasDismissButton:       PropTypes.bool,
  heading:                PropTypes.string,
  subHeading:             PropTypes.string,
};


class RichContentTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const classes = toClassStr(['rich-content-tooltip', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderDismissButton()}
        {this.renderHeading()}
        {this.renderSubHeading()}
        {this.renderBody()}
        {this.renderActions()}
      </div>
    );
  }

  renderDismissButton() {
    if (!this.props.hasDismissButton) {
      return null;
    }

    return (
      <DismissButton
        addClasses="dismiss-tooltip-button"
        onClick={() => this.handleDismissButtonClick()}
      />
    );
  }

  renderHeading = () => {
    if (!this.props.heading) {
      return null;
    }

    return (
      <div className="rich-content-tooltip-heading">{this.props.heading}</div>
    );
  }

  renderSubHeading = () => {
    if (!this.props.subHeading) {
      return null;
    }

    return (
      <div className="rich-content-tooltip-sub-heading">{this.props.subHeading}</div>
    );
  }

  renderBody = () => {
    if (!this.props.body) {
      return null;
    }

    return (
      <div className="rich-content-tooltip-body">{this.props.body}</div>
    );
  }

  renderActions = () => {
    if (!this.props.actions) {
      return null;
    }

    const actions = this.props.actions.map((action, index) => {
      const classes = toClassStr(['action', action.tag]);

      return (
        <li className={classes} onClick={(e) => this.handleActionClick(e, action.handler)} key={`action-${index}`}>{action.label}</li>
      );
    });

    return (
      <ul className="actions">
        {actions}
      </ul>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleActionClick = (e, actionHandler) => {
    e.stopPropagation();

    this.triggerTooltipClose();

    if (actionHandler) {
      actionHandler();
    }
  }

  handleDismissButtonClick = () => {
    this.triggerTooltipClose();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {
      shouldCloseImmediately: true,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

RichContentTooltip.propTypes = PT;

export default RichContentTooltip;
