
import {
  apiError,
  apiGet,
  cardPath,
} from '_core/Api2';

import EditCardModal from '_views/modals/EditCardModal';
import EventManager  from '@brainscape/event-manager';
import PropTypes     from 'prop-types';
import React         from 'react';

const PT = {
  currentUser:       PropTypes.object,
};

class EditCardController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      editProps: null,
      isModalOpen: false, 
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this.events.addListener('edit-card:request', this.handleRequest);
  };

  componentWillUnmount = () => this.events.disable();


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const editProps = this.state.editProps;

    if (!editProps) { 
      return null; 
    }

    const rqOpts = editProps.rqOpts;
    const rsOpts = editProps.rsOpts;

    return (
      <EditCardModal
        card={rsOpts.card}
        cardId={rqOpts.cardId}
        deckId={rqOpts.deckId}
        isUserPro={rsOpts.isUserPro}
        onCompleted={this.handleEditCompleted}
        packId={rqOpts.packId}
      />
    );
  };


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleEditCompleted = (e) => {
    const editProps = this.state.editProps;
    if (!editProps) { return null; }

    const rqOpts = editProps.rqOpts;
    const rsOpts = editProps.rsOpts;

    this.setState({
      editProps: null,
    }, () => {
      this.safeOnComplete(rqOpts, rsOpts, {
        card:        e.card,
        cardChanged: e.cardChanged,
      });
    });
  };

  handleRequest = (rqOpts) => {
    const packId = rqOpts.packId;
    const deckId = rqOpts.deckId;
    const cardId = rqOpts.cardId;
    const path   = cardPath(packId, deckId, cardId, 'edit/request');

    apiGet(path).then((rsOpts) => {
      switch (rsOpts.editType) {
        case 'modal':
          this.setState({
            editProps: {rqOpts: rqOpts, rsOpts: rsOpts},
            isUserPro: rsOpts.isUserPro,
          });
          break;

        case 'suggest':
          this.openSuggestEdit(rqOpts, rsOpts);
          break;

        case 'redirect':
          window.location = rsOpts.url;
          break;

        default:
          const error = new Error(`unknown response (${rsOpts.editType})`);
          apiError(error);
          this.safeOnComplete(rqOpts, rsOpts, {error: error});
      }
    }).catch(apiError);
  };



  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  openSuggestEdit = (rqOpts, rsOpts) => {
    EventManager.emitEvent('suggest-edit-modal-set:open', {
      cardContentType:    rsOpts.suggestEdit.contentType,
      cardId:             rqOpts.cardId,
      deckId:             rqOpts.deckId,
      isUserPro:          rsOpts.isUserPro,
      packId:             rqOpts.packId,
      onCloseRequest:     () => this.safeOnComplete(rqOpts, rsOpts),
      suggestionMetadata: rsOpts.suggestEdit,
    });
  };

  safeOnComplete = (rqOpts, rsOpts, opts={}) => {
    if (!rqOpts.onComplete) { return; }

    rqOpts.onComplete({editType: rsOpts.editType, ...opts});
  };
}

EditCardController.propTypes = PT;

export default EditCardController;
