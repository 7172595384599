
import EventManager           from '@brainscape/event-manager';

import pack                   from '_models/pack';
import packDeck               from '_models/packDeck';
import packLearner            from '_models/packLearner';
import packLearnerTransform   from '_models/packLearnerTransform';


const currentPackLearnersConcern = {
  // get checks session store for cached objects, publishes immediately to allow display, then refreshes cached copy by conducting fetches against the API
  get(userId, pack) {
    const currentPackId = pack.packId;
    let currentPack = {...pack};

    return new Promise((resolve, reject) => {
      try {
        const currentPackLearners = packLearner.getCachedIndex(currentPackId);

        if (currentPackLearners) {
          currentPack.learners = currentPackLearners;
          currentPack.learnerIds = currentPackLearners.map(learner => learner.userId);

          packLearnerTransform.index(userId, currentPackId, currentPack.learnerIds).then(learnerTransforms => {
            currentPack = this.applyTransforms(currentPack, learnerTransforms);

            const currentPackLearnerData = {
              learners: currentPackLearners,
              learnerIds: currentPack.learnerIds,
              currentPack: currentPack,
              isFromCache: true,
            };

            this.publish(currentPackLearnerData);
          });
        }

        this.fetch(userId, currentPack).then(currentPackLearnerData => {
          resolve(currentPackLearnerData);
        }).catch(err => {
          console.error('Problem with packLearners index. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch(userId, pack) {
    const currentPackId = pack.packId;
    let currentPack = {...pack};

    return new Promise((resolve, reject) => {
      try {
        packLearner.index(currentPackId, userId).then(learnersData => {
          // we have the fully paginated set of learners when the promise resolves

          currentPack.learners = learnersData.learners;
          currentPack.learnerIds = learnersData.learnerIds;
          currentPack.metadata = null;
          currentPack.leaderboard = null;
          currentPack.leaderboardOrder = null;

          packLearnerTransform.index(userId, currentPackId, currentPack.learnerIds).then(learnerTransforms => {
            currentPack = this.applyTransforms(currentPack, learnerTransforms);

            const currentPackLearnerData = {
              currentPack: currentPack,
              learnerIds: currentPack.learnerIds,
              learners: currentPack.learners,
              isFromCache: false,
            };

            packLearner.setCachedIndex(currentPackId, currentPack.learners);

            this.publish(currentPackLearnerData);
            resolve(currentPackLearnerData);
          });
        }).catch(err => {
          console.error('Problem with packLearners index. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  applyTransforms(currentPack, learnerTransforms) {
    currentPack.localLearnerTransforms = {...currentPack.localLearnerTransforms, ...learnerTransforms};

    const filteredLearners = packLearner.filter([...currentPack.learners], learnerTransforms.filters);
    const sortedLearners = packLearner.sort([...filteredLearners], learnerTransforms.sorters);

    currentPack.transformedLearnerIds = sortedLearners.map(transformedLearner => transformedLearner.userId);

    return currentPack;
  },

  publish(currentPackLearnerData) {
    EventManager.emitEvent('pack-learners:retrieved', currentPackLearnerData);
  },

  handlePackLearnerRemoved(currentUser, currentPack, eventData) {
    const currentUserId = currentUser.userId;
    const currentPackId = currentPack.packId;

    const updatedPack = {...currentPack};
    const removedLearnerId = eventData.userId;

    if (!updatedPack.learners) {
      return false;
    }

    const removedLearnerIndex = updatedPack.learners.findIndex(learner => (learner.userId == removedLearnerId));

    if (!removedLearnerIndex || removedLearnerIndex == -1) {
      return false;
    }

    updatedPack.learners.splice(removedLearnerIndex, 1);
    updatedPack.learnerIds.splice(removedLearnerIndex, 1);

    const removedSelectedLearnerIndex = updatedPack.localLearnerTransforms?.selections?.ids?.indexOf(removedLearnerId);

    if (removedSelectedLearnerIndex && removedSelectedLearnerIndex != -1) {
      updatedPack.localLearnerTransforms.selections.ids.splice(removedSelectedLearnerIndex, 1);
    }

    const removedTransformedLearnerIndex = updatedPack.transformedLearnerIds.indexOf(removedLearnerId);

    if (removedTransformedLearnerIndex && removedTransformedLearnerIndex != -1) {
      updatedPack.transformedLearnerIds.splice(removedTransformedLearnerIndex, 1);
    }

    pack.setCachedItem(currentPackId, updatedPack);
    packLearner.setCachedIndex(currentPackId, updatedPack.learners);

    return {
      currentPack: updatedPack,
    };
  },

  handlePackLearnerUpdated(currentUser, currentPack, currentTabId, eventData) {
    const currentUserId = currentUser.userId;
    const currentPackId = currentPack.packId;

    const updatedPack = {...currentPack};
    const learners = (currentTabId == 'leaderboard') ? updatedPack.leaderboard : updatedPack.learners;

    if (!learners) {
      return false;
    }

    const updatedLearner = learners.find(learner => (learner.userId == eventData.userId));

    if (!updatedLearner) {
      return false;
    }

    updatedLearner.permission = eventData.permission;
    updatedLearner.discoveryMedium = eventData.discoveryMedium || updatedLearner.discoveryMedium;

    if (updatedLearner.userId == currentUserId) {
      // the updatedLearner is also the currentUser, reflect the permission changes on the pack level too
      updatedPack.permission = eventData.permission;
      updatedPack.discoveryMedium = eventData.discoveryMedium || updatedPack.discoveryMedium;

      updatedPack.flags.areDecksEditable = pack.areDecksEditable(updatedPack);
      updatedPack.flags.isEditable = pack.isEditable(updatedPack);
      updatedPack.flags.isDuplicatable = pack.isDuplicatable(currentUser, updatedPack);

      const decksAreEditable = packDeck.isEditable(updatedPack);
      const decksAreDuplicatable = packDeck.isDuplicatable(currentUser, updatedPack);

      updatedPack.decks = updatedPack.decks.map(deck => {
        deck.flags.isEditable = decksAreEditable;
        deck.flags.isDuplicatable = decksAreDuplicatable;
        deck.flags.isLocked = packDeck.isLocked(currentUser, updatedPack, deck.deckId);

        return deck;
      });
    }

    if (currentTabId == 'learners' && updatedLearner.userId == currentUserId) {

      if (eventData.permission == 'admin') {
        // if the current user has just become the admin, we need learner stats
        this.fetch(currentUserId, updatedPack);
      } else {
        packLearnerTransform.clear(currentUserId, currentPackId, 'filters');
        packLearnerTransform.clear(currentUserId, currentPackId, 'sorters');
      }
    }

    pack.setCachedItem(currentPackId, updatedPack);
    packLearner.setCachedIndex(currentPackId, learners);

    return {
      currentPack: updatedPack,
    };
  },

  handleLearnerFiltersUpdated(currentPack, eventData) {
    let updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;

    const learnerTransforms = eventData;
    updatedPack = this.applyTransforms(updatedPack, learnerTransforms);

    pack.setCachedItem(updatedPackId, updatedPack);
    packLearner.setCachedIndex(updatedPackId, updatedPack.learners);

    return {
      currentPack: updatedPack,
    };
  },

  handleLearnerSelectionsUpdated(currentPack, eventData) {
    let updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;

    const transforms = {...updatedPack.localLearnerTransforms};
    transforms.selections = eventData.selections || transforms.selections;

    updatedPack.localLearnerTransforms = transforms;

    pack.setCachedItem(updatedPackId, updatedPack);

    return {
      currentPack: updatedPack,
    };
  },

  handleLearnerSortersUpdated(currentPack, eventData) {
    let updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;

    const learnerTransforms = eventData;
    updatedPack = this.applyTransforms(updatedPack, learnerTransforms);

    pack.setCachedItem(updatedPackId, updatedPack);
    packLearner.setCachedIndex(updatedPackId, updatedPack.learners);

    return {
      currentPack: updatedPack,
    };
  },
};

export default currentPackLearnersConcern;
