
import EventManager                                    from '@brainscape/event-manager';
import GaHelper                                        from '_utils/GaHelper';
import DynamicTooltipIcon                              from '_views/shared/DynamicTooltipIcon';
import NumberHelper                                    from '_utils/NumberHelper';
import React                                           from 'react';
import SimpleProgressBar                               from '_views/shared/SimpleProgressBar';
import StudyButton                                     from '_views/shared/StudyButton';
import StudyHelper                                     from '_utils/StudyHelper';
import RichContentTooltip                              from '_views/shared/RichContentTooltip';

import {
  AddButton, 
  AddButtonCircled, 
  DismissButton
} from '_views/shared/IconButton';

import {toClassStr} from '_utils/UiHelper';

// images
import defaultPackIconDarkBg                from '_images/shared/ugc-blue-bg.svg';


const FALLBACK_PACK_ICON_URL = '/assets/app_icons/ugc-blue-bg.svg';


class SidebarPack extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      isProcessingTrendingPackDetail: false,
    };

    /*
      this.props:
        addClasses,
        currentUserId,
        isProcessing,
        isRestrictedByPrivacy,
        isSelected,
        isTrendingPack,
        isUserPack,
        isUserPro,
        onAddTrendingPackRequest,
        onRemovePackRequest,
        onTrendingPackClick,
        onTrendingPackDetailRequest,
        onUserPackClick,
        sidebarMode,
        sidebarPack,
    */

    this.sidebarPackElem = null;
    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return this.renderPack();
  }

  renderAddPackButton() {
    if (!this.props.isTrendingPack) {
      return null;
    }

    return (
      <AddButtonCircled
        addClasses="add-class-button"
        isProcessing={this.state.isProcessingTrendingPackDetail}
        onClick={this.handleAddClassButtonClick}
      />
    );
  }

  renderPack() {
    const sidebarPack = this.props.sidebarPack;
    const isPrivateClass = sidebarPack.private ? 'is-private' : '';
    const isSelectedClass = this.props.isSelected ? 'is-selected' : '';
    const proRequiredClass = (sidebarPack.private && sidebarPack.discoveryMedium != 'invited' && !this.props.isUserPro) ? 'pro-required' : '';
    const classes = toClassStr(['sidebar-pack', isPrivateClass, isSelectedClass, proRequiredClass, this.props.addClasses]);

    const name = sidebarPack.name;

    const artworkUrlOrImage = sidebarPack.iconUrl || sidebarPack.activeIconUrl || defaultPackIconDarkBg;

    const imageKind = (this.props.isMarketPack) ? 'catIcon' : 'ugsIconBlueBg';

    return (
      <li 
        className={classes}
        id={this.props.id} 
        onClick={this.handlePackClick} 
        ref={(elem) => {this.sidebarPackElem = elem}} 
        onMouseEnter={this.handlePackMouseEnter}
        onMouseLeave={this.handlePackMouseLeave}
      >
        <div className="pack-icon" title={name}>
          <img className="pack-icon-image" src={artworkUrlOrImage} data-img-kind={imageKind} />
          {this.renderStudyButton()}
        </div>

        <div className="name-and-metadata">

          <div className="name-and-privacy-flag">
            <div className="pack-name" title={name}>{name}</div>
            {this.renderPrivateFlag()}
            {this.renderUserPackDynamicTooltipIcon()}            
            {this.renderTrendingPackDynamicTooltipIcon()}            
          </div>

          {this.renderProgressBar()}
        </div>

        {this.renderRemovePackButton()}
        {this.renderAddPackButton()}
      </li>
    );
  }

  renderStudyButton() {
    if (this.props.isTrendingPack) {
      return null;
    }

    if (this.props.isRestrictedByPrivacy) {
      return null;
    }

    if (this.props.sidebarMode == 'mini') {
      return null;
    }

    if (this.props.sidebarPack.stats?.cardCount < 1) {
      return null;
    }

    if (this.props.sidebarPack.private && !this.props.isUserPro) {
      return null;
    }

    return (
      <StudyButton
        onClick={this.handleStudyButtonClick}
        tooltipContent="Study this Class"
        tooltipPosition="right"
      />
    );
  }

  renderPrivateFlag() {
    if (!this.props.sidebarPack.private) {
      return null;
    }

    return (
      <div className="private-flag">Private</div>
    );
  }

  renderTrendingPackDynamicTooltipIcon() {
    if (!this.props.isTrendingPack) {
      return null;
    }
    
    const sidebarPack = this.props.sidebarPack;
    const actions = [
      {
        label: 'Add to Library',
        tag: 'add-to-library',
        handler: this.handleAddTrendingPackActionClick,
      },
      {
        label: 'See More',
        tag: 'see-more',
        handler: this.handleSeeTrendingPackActionClick,
      },
    ];

    return (
      <DynamicTooltipIcon 
        actions={actions}
        addClasses="pack-info-button trending-pack-info-button"
        body={this.renderTrendingPackTooltipBody()}
        hasDismissButton={true}
        heading={sidebarPack.name}
        position="top"
        subHeading="Brainscape Certified Class"
      />
    ); 
  }

  renderTrendingPackTooltipBody() {
    const sidebarPack = this.props.sidebarPack;

    const deckCount = NumberHelper.displayNumber(sidebarPack.stats.deckCount);
    const cardCount = NumberHelper.displayNumber(sidebarPack.stats.cardCount);
    const learnerCount = NumberHelper.displayNumber(sidebarPack.subscriberCount);

    return (
      <div
        className="sidebar-pack-tooltip trending-pack-tooltip" 
      >
        <div className="body-text market-headline" dangerouslySetInnerHTML={{__html: sidebarPack.marketHeadline}}></div>
        <div className="body-text pack-stats">
          <div className="pack-stat decks"><div className="label">Decks</div><div className="stat">{deckCount}</div></div>
          <div className="pack-stat cards"><div className="label">Cards</div><div className="stat">{cardCount}</div></div>
          <div className="pack-stat decks"><div className="label">Learners</div><div className="stat">{learnerCount}</div></div>
        </div>
      </div>
    )
  }

  renderUserPackDynamicTooltipIcon() {
    if (this.props.isTrendingPack) {
      return null;
    }

    const opts = this.getUserPackTooltipComponents();

    return (
      <DynamicTooltipIcon
        actions={opts.actions}
        addClasses="pack-info-button user-pack-info-button"
        body={opts.body}
        delay={500}
        hasDismissButton={true}
        heading={opts.heading}
        position="right"
        subHeading={opts.subHeading}
      />
    ); 
  }

  renderUserPackTooltipBody() {
    const sidebarPack = this.props.sidebarPack;

    const cardCount = NumberHelper.displayNumber(sidebarPack.stats.cardCount);
    const seenCount = NumberHelper.displayNumber(sidebarPack.stats.seenCount);
    const mastery = NumberHelper.percentageStr(sidebarPack.stats.mastery, 1);

    return (
      <div
        className="sidebar-pack-tooltip user-pack-tooltip" 
      >
        {this.renderUserPackDesc()}

        <div className="body-text pack-stats">
          <div className="pack-stat decks"><div className="label">Card<br/>Count</div><div className="stat">{cardCount}</div></div>
          <div className="pack-stat cards"><div className="label">Cards<br/>Studied</div><div className="stat">{seenCount}</div></div>
          <div className="pack-stat decks"><div className="label"><br/>Mastery</div><div className="stat">{mastery}%</div></div>
        </div>
      </div>
    );
  }

  renderUserPackDesc() {
    const sidebarPack = this.props.sidebarPack;

    if (sidebarPack.certified) {
      return (
        <div className="body-text desc" dangerouslySetInnerHTML={{__html: sidebarPack.marketHeadline}} />
      )
    }

    return (
      <div className="body-text desc">{sidebarPack.desc}</div>    
    );
  }

  renderProgressBar() {
    if (!this.props.isUserPack) {
      return null;
    }

    const mastery = (this.props.sidebarPack.stats) ? this.props.sidebarPack.stats.mastery || 0 : 0;
    const masteryPercentage = mastery * 100;

    return (
      <SimpleProgressBar 
        baseValue={100}
        barValue={masteryPercentage}
      />
    );
  }

  renderRemovePackButton() {
    if (!this.props.isUserPack) {
      return null;
    }

    return (
      <DismissButton
        addClasses="remove-class-button"
        onClick={this.handleRemoveClassButtonClick}
        tooltipContent="Remove Class from My Classes"
        tooltipPosition="right"
      />
    );
  }

  renderUserPackRichContentTooltip() {
    const opts = this.getUserPackTooltipComponents();

    return (
      <RichContentTooltip
        actions={opts.actions}
        addClasses={'user-pack-tooltip-dialog'} 
        body={opts.body}
        hasDismissButton={true}
        heading={opts.heading}
        subHeading={opts.subHeading}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddClassButtonClick = () => {
    if (this.props.onAddTrendingPackRequest) {
      this.props.onAddTrendingPackRequest(this.props.sidebarPack.categoryId);
    }
  }

  handleAddTrendingPackActionClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.onAddTrendingPackRequest) {
      this.props.onAddTrendingPackRequest(this.props.sidebarPack.categoryId);
    }
  }

  handlePackClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.isUserPack) {
      this.props.onUserPackClick(this.props.sidebarPack);
    }

    if (this.props.isTrendingPack) {
      this.props.onTrendingPackClick(this.props.sidebarPack);
    }

    this.triggerTooltipClose({shouldCloseImmediately: true});
  }

  handlePackMouseEnter = (e) => {
    if (this.props.sidebarMode == 'mini') {
      this.handleUserPackMouseEnter();
    }

    return false;
  }

  handlePackMouseLeave = (e) => {
    if (this.props.sidebarMode == 'mini') {
      this.handleUserPackMouseLeave();
    }

    return false;
  }

  handleRemoveClassButtonClick = () => {    
    if (this.props.onRemovePackRequest) {
      this.props.onRemovePackRequest(this.props.sidebarPack);
    }
  }

  handleSeeTrendingPackActionClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.isTrendingPack) {
      this.props.onTrendingPackClick(this.props.sidebarPack);
    }
  }

  handleSelectUserPackActionClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.props.onUserPackClick(this.props.sidebarPack);
  }

  handleStudyButtonClick = () => {
    this.launchStudy();
  }

  handleStudyUserPackActionClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.launchStudy();
  }

  handleUserPackMouseEnter = () => {
    this.triggerUserPackTooltipOpen();
  }

  handleUserPackMouseLeave = () => {
    this.triggerTooltipClose();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipClose = (eventData={}) => {
    EventManager.emitEvent('tooltip:close', eventData);
  }

  triggerUserPackTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.renderUserPackRichContentTooltip(),
      elem: this.sidebarPackElem,
      position: 'right',
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getUserPackTooltipComponents = () => {
    const sidebarPack = this.props.sidebarPack;
    const isStudyable = ((!this.props.isRestrictedByPrivacy) && (sidebarPack.stats?.cardCount > 0) && (!(sidebarPack.private && !this.props.isUserPro)));

    const actions = [
      {
        label: 'Select',
        tag: 'select-pack',
        handler: this.handleSelectUserPackActionClick,
      },
    ];

    if (isStudyable) {
      actions.push(      {
        label: 'Study',
        tag: 'study-pack',
        handler: this.handleStudyUserPackActionClick,
      });
    }

    const heading = sidebarPack.name;
    const subHeading = sidebarPack.certified ? 'Brainscape Certified' : `By: ${sidebarPack.creatorName}`;
    const body = this.renderUserPackTooltipBody();

    return {
      actions: actions,
      heading: heading,
      subHeading: subHeading,
      body: body,
    }
  }

  launchStudy = () => {
    const currentUserId = this.props.currentUserId;
    const sidebarPack = this.props.sidebarPack;

    const studyMixType = StudyHelper.getPackStudyMixType(currentUserId, sidebarPack);
    const studyMixUrl = StudyHelper.getPackStudyMixUrl(currentUserId, sidebarPack);
    const deckCount = sidebarPack.stats?.deckCount || 1;

    GaHelper.fireGaEvent('Study_Mix', studyMixType, deckCount);
    GaHelper.followUrlDelayed(studyMixUrl);
  }
}

export default SidebarPack;
