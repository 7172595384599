import React from 'react';
import { toClassStr } from '_utils/UiHelper';

const SvgIcon = ({ addClasses, svgIconTag }) => {

  /*
  ==================================================
   SUB-RENDERERS
  ==================================================
  */

  const renderGlyph = () => {
    switch (svgIconTag) {
      case 'import-list-icon':
        return (
          <svg viewBox="0 0 37 42" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="3" fill="white" />
            <rect x="3.77734" y="4.21875" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="3.77734" y="12.7266" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="3.77734" y="21.4141" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="16.9102" y="4.21875" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="16.9102" y="12.7266" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="16.9102" y="21.4141" width="9.375" height="4.28571" fill="currentColor" />
            <rect x="13.5" y="19" width="22" height="22" rx="11" fill="currentColor" stroke="white" strokeWidth="2" />
            <path d="M20.25 28.936L24.186 25L28.122 28.936M24.186 25.5467V35Z" fill="currentColor" />
            <path d="M20.25 28.936L24.186 25L28.122 28.936M24.186 25.5467V35" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square" />
          </svg>
        );
      default:
        return null;
    }
  };

  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['svg-icon', svgIconTag, addClasses]);

  return (
    <div className={classes}>
      {renderGlyph()}
    </div>
  );
}

export default SvgIcon;
