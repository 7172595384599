
import model          from '_core/newModel';
import userLocalStore from '_models/userLocalStore';


const packDeckTransform = {

  /*
  ==================================================
   LOCAL CRUD METHODS (returns promises)
  ==================================================
  */

  index(userId, packId, packDeckIds) {
    return new Promise((resolve, reject) => {
      try {
        const filters = this.getLocalFilters();
        const selections = this.getLocalSelections(userId, packId, packDeckIds);
        const sorters = this.getLocalSorters();
        
        resolve({
          filters: filters,
          selections: selections,
          sorters: sorters,
        });

      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  update(type, transformData) {
    return new Promise((resolve, reject) => {
      try {
        let resultData;

        switch (type) {
          case 'selections':
            resultData = this.updateSelections(transformData);
            model.publish('deck-selections:updated', resultData);
          break;
        }
        
        resolve(resultData);

      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getLocalFilters() {
    return {};
  },

  getLocalSelections(userId, packId, packDeckIds) {
    if (!userLocalStore.isPackMixSet(userId, packId)) {
      userLocalStore.selectPackDecks(userId, packId, packDeckIds);

      return {
        ids: packDeckIds,
      };
    }

    let localSelectionIds = userLocalStore.getSelectedPackDeckIds(userId, packId);

    // reconcile with any undetected packDeckId deletions
    userLocalStore.unselectPackDecks(userId, packId, localSelectionIds);
    localSelectionIds = localSelectionIds.filter(id => packDeckIds.indexOf(id) != -1);
    userLocalStore.selectPackDecks(userId, packId, localSelectionIds);

    return {
      ids: localSelectionIds,
    };
  },

  getLocalSorters() {
    return {};
  },

  updateSelections(transformData) {
    const {userId, packId, deckIds, isSelected} = transformData;

    if (isSelected) {
      userLocalStore.selectPackDecks(userId, packId, deckIds);
    } else {
      userLocalStore.unselectPackDecks(userId, packId, deckIds);
    }

    const selectedIds = userLocalStore.getSelectedPackDeckIds(userId, packId);

    return {
      deckIds: deckIds,
      packId: packId,
      selections: {
        ids: selectedIds,
      },
    };
  }
};

export default packDeckTransform;
