/**
 * CBRManager implements the CBR algorithm in a cross-platform style.
 *
 * The idea is that we can start using this on all platforms.
 *
 * Note: The "cross-platform" is, at the moment, theoretical.
 */
class CBRManager {
  constructor(initData) {
    if (initData) {
      this.cardCount  = Object.keys(initData.cards).length;
      this.cards      = initData.cards;
      this.deckCount  = Object.keys(initData.decks).length;
      this.decks      = initData.decks;
      this.isFtse     = initData.isFtse;
      this.levels     = initData.levels;
      this.mixType    = initData.mixType;
      this.packCount  = Object.keys(initData.packs).length;
      this.packs      = initData.packs;
    } else {
      this.cardCount  = 0;
      this.cards      = {}; // card data by card ID
      this.deckCount  = 0;
      this.decks      = {}; // deck data by deck ID
      this.isFtse     = false;
      this.levels     = [[], [], [], [], [], []]; // Card IDs by level
      this.mixType    = null;
      this.packCount  = 0;
      this.packs      = {}; // pack data by pack ID
    }

    if (!this.mixType) { this.mixType = 'progressive'; }

    // A partial history of the study activity (see MAX_HISTORY).
    this.cardIDHistory = [];
    this.cardsRated    = 0;
    this.recentLimits  = [
      0,                         // level 0: never used
      2,                         // level 1: should repeat often
      this.cardRange(2,  4, 20), // level 2: should repeat semi-often
      this.cardRange(2, 10, 20), // level 3: should repeat less-often
      this.cardRange(2, 10, 20), // level 4: should repeat less-often
      this.cardRange(2, 10, 20), // level 5: should repeat less-often
    ];

    // It's easier to always have a "last studied at" for each card.  So, if
    // a card has not been studied yet, we use this date.
    this.dfltEpoch = new Date('2000-01-01');

    if (DEBUG > 1) {
      console.log(
        `== CBR init: cardCount=${this.cardCount}, ` +
        `recentLimits=${this.recentLimits}, ` +
        `mixType=${this.mixType}`,
      );
    }
  }

  cardRange(min, max, divisor) {
    const value = Math.ceil(this.cardCount / divisor);

    return Math.max(min, Math.min(max, value));
  }

  between(min, max, value) {
    return Math.max(min, Math.min(max, value))
  }

  getCards() {
    return this.cards;
  }

  getDecks() {
    return this.decks;
  }

  getPacks() {
    return this.packs;
  }

  getCard(cardID) {
    return this.cards[cardID];
  }

  getDeck(deckID) {
    return this.decks[deckID];
  }

  getPack(packID) {
    return this.packs[packID];
  }

  getCardCount() {
    return this.cardCount;
  }

  getTotalCardsStudied() {
    let ratingLevelCounts = this.confidenceLevelData();
    let totalCardsStudied = 0;

    for (let i=1; i <= 5; i++) {
      totalCardsStudied = totalCardsStudied + ratingLevelCounts[i];
    }

    return totalCardsStudied;
  }

  getMastery() {
    return this.mastery();
  }

  mastery() {
    if (this.cardCount < 1) { return 0; }

    let adjSum = 1 * this.levels[1].length +
                 2 * this.levels[2].length +
                 3 * this.levels[3].length +
                 4 * this.levels[4].length +
                 5 * this.levels[5].length;

    return adjSum / (this.cardCount * 5);
  }

  levelFor(cardID) {
    let c = this.cards[cardID];
    if (!c) { return 0; }
    return c.level || 0;
  }

  /**
   * Call this to indicate that the card has been studied.
   *
   * This is necessary because we don't want to have to access a database
   * or make a call accross the network to execute the algorithm.
   */
  cardStudied(cardID, newLevel, studiedAt=null) {
    let c = this.cards[cardID];
    if (!c) { return; } // TODO: Should we log a warning?

    if (newLevel < 0) { newLevel = 0 }
    if (newLevel > 5) { newLevel = 5 }

    if (DEBUG > 1) {
      console.log(`== cardStudied     card: ${cardID}, level: ${newLevel}`);
    }

    c.studiedAt = studiedAt || new Date();

    // Move the card ID from the old position in the levels arrays to the
    // new position.  Note: This is necessary even if the user rates the
    // card at the same level because the levels arrays are sorted by
    // the time the card was last studied.
    const oldLevel = c.level;
    const oldPos   = this.levels[oldLevel].indexOf(cardID);

    if (oldPos >= 0) { this.levels[oldLevel].splice(oldPos, 1); }
    this.levels[newLevel].push(cardID);

    c.level = newLevel;

    this.cardIDHistory.push(cardID);
    if (this.cardIDHistory.length > MAX_HISTORY) { this.cardIDHistory.shift() }
    this.cardsRated += 1;
  }

  /**
   * Get confidence Level breakdowns
   */
  confidenceLevelData() {
    return this.levels.map((l) => l.length);
  }

  prevCardID() {
    if (this.cardIDHistory.length == 0) { return null; }
    return this.cardIDHistory[this.cardIDHistory.length - 1];
  }

  /**
   * Returns the ID of the next card the user should study.
   *
   * See #cardStudied
   */
  nextCardID() {
    const st         = new Date().getTime();
    const cl         = this.confidenceLevelData();
    const debugLines = [];

    if (this.isFtse && this.cardIDHistory.length == 7) {
      const forceId = this.shouldForceRepeat();
      if (forceId) { return forceId; }
    }

    if (DEBUG > 2) {
      console.log(`== nextCardID - history: ${this.cardIDHistory.join(', ')}`);
    }

    for (let j = 0; j < 200; j++) {
      const level = this.randomLevel(cl)

      if (DEBUG > 1) {
        debugLines.push(`== nextCardID      loop: ${j}, level: ${level}`);
      }

      // Level 1 means that the user us unsure.  To avoid overwhelming
      // users with new information, we will not add any unseen cards
      // until they have fewer than 7 cards at level 1.
      if (level == 0 && cl[1] >= 7) {
        if (DEBUG > 1) {
          debugLines[debugLines.length - 1] += ', level 1 >= 7'
        }
        continue;
      }

      const cardID = this.lruCardIDForLevel(level, debugLines)
      if (cardID) {
        if (DEBUG > 0) {
          console.log(
            `== nextCardID     found: ${cardID}\n` +
            `                  level: ${level}\n` +
            `                  confs: ${cl}\n` +
            `                mastery: ${Math.round(this.mastery() * 10000) / 100}%\n` +
            `                   time: ${new Date().getTime() - st}ms`,
          );
          if (DEBUG > 1) {
            debugLines.forEach((l) => console.log(l));
          }
        }

        return cardID;
      }

      if (DEBUG > 1) {
        debugLines[debugLines.length - 1] += ', 404'
      }
    }

    // last-ditch effort...
    const cardID = this.anyCardID()
    if (cardID) {
      if (DEBUG > 0) {
        console.log(
          `== nextCardID -   found: ${cardID}\n` +
          `                  confs: ${cl}\n` +
          `                mastery: ${Math.round(this.mastery() * 10000) / 100}%\n` +
          `                   time: ${new Date().getTime() - st}ms\n` +
          `             last-ditch: true`,
        );
        if (DEBUG > 1) {
          debugLines.forEach((l) => console.log(l));
        }
      }

      return cardID
    }

    return null;
  }

  // This will return the least-recently-studied-ish card for this level.
  //
  // We don't always want the literal lest recently studied card becuase
  // that would have the user studying the cards in the same order over
  // and over.
  //
  // So, instead, we will find several least-recently-studied cards and
  // randomly choose from amoung them.
  lruCardIDForLevel(level, debugLines) {
    // The IDs are stored in the order tha they were added to the session.
    const cardIDs = this.levels[level];
    if (cardIDs.length < 1) {
      if (DEBUG > 1) {
        debugLines.push(`== LRU IDs             : no cards at level ${level}`);
      }
      return null;
    }

    // Level 0 has different logic.
    if (level == 0) {
      if (this.mixType == 'random') {
        return cardIDs[Math.floor(Math.random() * cardIDs.length)];
      } else {
        // Level 0 cards have never been seen so we don't need to check
        // the last studied at, none of them have been studied.
        return cardIDs[0];
      }
    }

    // Randomly select a card that hasn't been seen recently from the
    // oldest 20% of the cards (max 20).  The randomness varies the
    // order that studiers review cards, but limiting it to the 20%
    // oldest make sure that they will review all the cards quickly,
    // even if most of them are in bucket 5.

    const skipIDs = this.lastNCardIDs(this.recentLimits[level]);
    const ct      = Math.min(20, Math.ceil(cardIDs.length / 5));
    const lruIDs  = cardIDs.slice(0, ct).filter((id) => !skipIDs.includes(id));

    if (DEBUG > 2) {
      debugLines.push(
        `== LRU IDs    level IDs: ${cardIDs.join(', ')}\n` +
        `           recent limit: ${this.recentLimits[level]}\n` +
        `          recent limits: ${this.recentLimits.join(', ')}\n` +
        `               skip IDs: ${skipIDs.join(', ')}\n` +
        `                     ct: ${ct}\n` +
        `              limit IDs: ${cardIDs.slice(0, ct).join(', ')}\n` +
        `                LRU IDs: ${lruIDs.join(', ')} (len: ${lruIDs.length})`,
      );
    }

    if (lruIDs.length < 1) { return null; }

    return lruIDs[Math.floor(Math.random() * lruIDs.length)];
  }

  randomLevel(cl) {
    // Choose the confidence level based on static probabilities (1-5).
    // Notice, level 0 is missing.  Because levels 0 & 1 are treated
    // differently (see below), their probabilities are lumped together.
    let level = 1 + this.randomChoice([
      cl[0] == 0 && cl[1] == 0 ? 0 : 0.63,
      cl[2] == 0 ? 0 : 0.22,
      cl[3] == 0 ? 0 : 0.10,
      cl[4] == 0 ? 0 : 0.04,
      cl[5] == 0 ? 0 : 0.01,
    ])

    // Confidence 1 has additional selection requirements.
    //
    // The idea is that people can generally remember 7 new things at a
    // time.  So, we only wan to present new items to the user when they
    // are feeling reasonably confident about the current items.
    if (level == 1) {
      if (cl[0] == 0) { return 1 } // Shortcut...

      switch (cl[1]) {
        case 0:   level = 0; break;
        case 1:   level = this.randomChoice([0.90, 0.10]); break;
        case 2:   level = this.randomChoice([0.70, 0.30]); break;
        case 3:   level = this.randomChoice([0.40, 0.60]); break;
        case 4:   level = this.randomChoice([0.18, 0.82]); break;
        case 5:   level = this.randomChoice([0.12, 0.88]); break;
        case 6:   level = this.randomChoice([0.05, 0.95]); break;
        default:  level = 1; break;
      }
    }

    return level
  }

  randomChoice(vals) {
    let total = vals.reduce((t, i) => t + i )
    let rnd   = Math.random() * total

    let it = 0
    for (let i = 0; i < vals.length; i++) {
      it += vals[i]
      if (rnd <= it) { return i }
    }

    return vals.length - 1
  }

  lastNCardIDs(n) {
    const len = this.cardIDHistory.length

    if (len <= n) { return this.cardIDHistory }

    return this.cardIDHistory.slice(len - n, len)
  }

  anyCardID() {
    // Return a unseen card if there is one.
    // Note: This was copied from old code.  I don't know why this
    //       method should return unseen cards first.
    if (this.levels[0].length > 0) { return this.levels[0][0] }

    if (this.cardCount < 1) { return null; }

    const cardIDs = Object.keys(this.cards);

    return cardIDs[Math.floor(Math.random() * this.cardCount)];
  }

  shouldForceRepeat = () => {
    const seen        = {};
    let   lowestId    = null;
    let   lowestLevel = 6;

    this.cardIDHistory.forEach((id) => {
      seen[id] = true;

      const level = (this.cards[id] || {}).level;
      if (level && level < lowestLevel) {
        lowestId    = id;
        lowestLevel = level;
      }
    });

    // If there has already been a repeat no need to force one.
    if (Object.keys(seen).length < this.cardIDHistory.length) { return null; }

    return lowestId;
  }
}

// The maximum history we should save.  100 was choosen arbitrarily.
const MAX_HISTORY = 100;

const DEBUG = 0;

export default CBRManager;
