
import model from '_core/newModel';

const packConfidence = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  generateDummyData(packId) {
    const path = `me/packs/${packId}/confidences/dummy_data`;
    return model.set(path).then(() => model.publish('pack-confidences:reset', {packId: packId}));
  },

  reset(packId) {
    const path = `me/packs/${packId}/confidences`;
    return model.destroy(path).then(() => model.publish('pack-confidences:reset', {packId: packId}));
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default packConfidence;
