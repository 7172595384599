
import {
  ClearButton,
}                               from '_views/shared/IconButton';
              
import React                    from 'react';
import Spinner                  from '_views/shared/Spinner';

import { toClassStr }           from '_utils/UiHelper';


class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isListeningToOutsideClicks: false,
      isShowingResults: false,
    };

    /*
      this.props:
        isProcessing,
        onClearQueryRequest,
        onOutsideClick,
        onQueryTextChange,
        onResultClick,
        placeholderText,
        queryText,
        results,
    */

    this.elem = null;
    this.inputElem = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    if (this.hasResults(this.props) && !this.state.isListeningToOutsideClicks) {
      this.startOutsideClickMonitor();

      this.triggerFocus();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.hasResults(prevProps) && this.hasResults(this.props)) {
      this.manageHasNewResults();
      return true;
    }

    if (this.hasResults(prevProps) && !this.hasResults(this.props)) {
      this.manageHasLostResults();
      return false;
    }
  }

  componentWillUnmount() {
    this.stopOutsideClickMonitor();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isProcessingClass = (this.props.isProcessing) ? 'is-processing' : '';
    const isShowingResultsClass = (this.state.isShowingResults) ? 'is-showing-results' : '';
    const classes = toClassStr(['search-box', isProcessingClass, isShowingResultsClass, this.props.addClasses]);
    const placeholder = this.props.placeholderText || "Search...";

    return (

      <div className={classes} ref={(elem) => { this.elem = elem }}>

        <div className="query-text">

          <input
            className="text-input query-text-input"
            type="text"
            onFocus={this.handleFocus}
            onChange={this.handleQueryTextChange}
            placeholder={placeholder}
            ref={(elem) => { this.inputElem = elem }}
            value={this.props.queryText}
          />

          {this.renderClearButtonOrSpinner()}
        </div>

        <ul className="search-results-list">
          {this.renderResults()}
        </ul>
      </div>
    );
  }

  renderClearButtonOrSpinner() {
    if (this.props.queryText) {
      return (
        <ClearButton
          addClasses="clear-query-text-button"
          isProcessing={this.props.isProcessing}
          onClick={() => this.handleClearQueryTextButtonClick()}
          tooltipContent="Clear Search term"
          tooltipPosition="top"
        />
      );
    }

    return null;
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner
        addClasses="blue"
      />
    );
  }

  renderResults() {
    if (!(this.props.results && this.props.results.length > 0)) {
      return null;
    }

    if (!this.state.isShowingResults) {
      return null;
    }

    let results = this.props.results.map((result, index) => {
      return (this.renderResult(result, index));
    });

    return results;
  }

  renderResult(result, index) {
    return (
      <li
        className={`result result-${result.id}`}
        id={result.id}
        key={index}
        onClick={(e) => this.handleResultClick(e, result.id)}
      >
        {this.renderResultArtwork(result)}
        <div className="result-label">{result.label}</div>
      </li>
    );
  }

  renderResultArtwork(result) {
    if (!result.artworkUrl) {
      return null;
    }

    return (
      <img className="result-artwork" src={result.artworkUrl} height="48px" />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClearQueryTextButtonClick = () => {
    this.props.onClearQueryRequest();
  }

  handleFocus = () => {
    if (this.hasResults()) {
      this.displayResults();
    }
  }

  handleOutsideClick = (e) => {
    e.stopPropagation();
    
    if (this.elem && this.elem.contains(e.target)) {
      return false;
    }

    this.dismissResults();
  }

  handleQueryTextChange = (e) => {
    e.stopPropagation();

    const newValue = e.target.value;
    this.props.onQueryTextChange(newValue);
  }

  handleResultClick = (e, resultId) => {
    e.stopPropagation();

    this.props.onResultClick(resultId);
    this.dismissResults();
  }



  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerFocus = () => {
    this.inputElem.focus();
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  dismissResults = () => {
    this.stopOutsideClickMonitor();

    this.setState({
      isShowingResults: false,
    });
  }

  displayResults = () => {
    this.startOutsideClickMonitor();

    this.setState({
      isShowingResults: true,
    });
  }

  hasResults = (props=this.props) => {
    return (props.results && props.results.length && props.results.length > 0);
  }

  manageHasNewResults = () => {
    this.displayResults();
    this.triggerFocus();
  }

  manageHasLostResults = () => {
    this.dismissResults();
  }

  startOutsideClickMonitor = () => {
    document.removeEventListener('click', this.handleOutsideClick);

    document.addEventListener('click', this.handleOutsideClick);
    if (this._isMounted) {
      this.setState({
        isListeningToOutsideClicks: true,
      });
    }
  }

  stopOutsideClickMonitor = () => {
    document.removeEventListener('click', this.handleOutsideClick);
    this.setState({
      isListeningToOutsideClicks: false,
    })
  }
}

export default SearchBox;
