import DynamicTooltipIcon        from '_views/shared/DynamicTooltipIcon';
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import PropTypes                 from 'prop-types';
import RadioButtonsField         from '_views/shared/RadioButtonsField';
import React                     from 'react';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                      : PropTypes.string,
  metadata                        : PropTypes.object,
  onModalSetCompleted             : PropTypes.func,
  onUpdatePackMetadataRequest     : PropTypes.func,
  pack                            : PropTypes.object,
};


class RequiredMetadataModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddPurposePulldownOpen: false,
      isRoleModalOpen: false,
      isPurposeModalOpen: false,
      isProcessingRoleUpdate: false,
      isProcessingPurposeUpdate: false,
      purposeOptions: [],
      selectedPurposeId: null,
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.manageModals();
  }

  componentDidUpdate(prevProps) {
    if (this.props.metadata != prevProps.metadata) {
      this.manageModals();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['required-pack-metadata-modal-set', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderRoleModal()}
        {this.renderPurposeModal()}
      </div>
    );
  }

  renderRoleModal() {
    return (
      <Modal
        addClasses="pack-metadata-role-modal"
        isOpen={this.state.isRoleModalOpen}
        isUndismissable={true}
        onClosed={this.handleRoleModalClosed}
      >

        <div className="modal-title"><span>{this.props.pack.name}</span> class created!</div>
        <div className="modal-subtitle">What's your role?</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="I'm teaching it"
            onClick={() => this.handleInstructorButtonClick()}
          />
          <PillButton
            addClasses="resolve-modal-button"
            label="I'm studying it"
            onClick={() => this.handleStudentButtonClick()}
          />
        </div>
      </Modal>
    );
  }

  renderPurposeModal() {
    return (
      <Modal
        addClasses="pack-metadata-purpose-modal"
        isOpen={this.state.isPurposeModalOpen}
        isUndismissable={true}
      >

        <div className="modal-title">Customize your Class</div>
        <div className="modal-subtitle">Class Purpose</div>

        <div className="guidance-and-tooltip">

          <div className="modal-guidance">
            What best describes the purpose of this class?
          </div>

          <DynamicTooltipIcon 
            actions={null}
            addClasses="purpose-choice-tooltip"
            body={this.renderPurposeChoiceTooltipBody()}
            hasDismissButton={true}
            heading="Purpose"
            position="right"
            subHeading={null}
            isMobileViewportSize={this.props.isMobileViewportSize}
          />
        </div>

        <RadioButtonsField
          addClasses="pack-purpose-buttons"
          buttons={this.state.purposeOptions}
          isInline={false}
          name="purposeOption"
          onClick={this.handlePurposeRadioButtonClick}
          value={this.state.selectedPurposeId}
        />

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            isDisabled={!this.state.selectedPurposeId}
            isProcessing={this.state.isProcessingPurposeUpdate}
            label="Done"
            onClick={this.handlePurposeDoneButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderPurposeChoiceTooltipBody() {
    return (
      <>
        <p className="body-text"><span className="purpose-label">School / University:</span><span className="purpose-explanation">e.g. prep for quizzes and tests.</span></p>
        <p className="body-text"><span className="purpose-label">Standardized Test:</span><span className="purpose-explanation">e.g. entrance exams like SAT or MCAT</span></p>
        <p className="body-text"><span className="purpose-label">Professional Certification:</span><span className="purpose-explanation">e.g. Series 7, USMLE</span></p>
        <p className="body-text"><span className="purpose-label">Job Skills:</span><span className="purpose-explanation">e.g. company training, or other skills not related to a standardized certification</span></p>
        <p className="body-text"><span className="purpose-label">Foreign Language:</span><span className="purpose-explanation">e.g. Arabic (general; NOT for a specific class at school)</span></p>
      </>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleInstructorButtonClick = () => {
    this.updatePackRole('Instructor');
  }

  handlePurposeDoneButtonClick = () => {
    this.updatePackPurpose();
  }

  handlePurposeRadioButtonClick = (optionId) => {
    this.setState({
      isAddPurposePulldownOpen: false,
      selectedPurposeId: optionId,
    });
  }

  handleRoleModalClosed = () => {
    this.manageModals();
  }

  handleStudentButtonClick = () => {
    this.updatePackRole('Student');
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  // TODO: Move these next two methods to PackMetadata model
  hasPurposeMetadata = () => {
    const purposes = this.props.metadata.purposes;
    const purposeKeys = Object.keys(purposes);

    for (let i=0; i<purposeKeys.length; i++) {
      if (purposes[purposeKeys[i]].value) {
        return true;
      }
    }

    return false;
  }

  hasRoleMetadata = () => {
    const global = this.props.metadata.global;

    if (!global?.role?.value) {
      return false;
    }

    return !(global.role.value == "unspecified");
  }

  setPurposeOptions() {
    const purposes = this.props.metadata.purposes;
    const purposeKeys = Object.keys(purposes);
    let purposeOptions = [];

    purposeKeys.forEach((key, i) => {
      const purpose = purposes[key];
      purposeOptions.push({
        value: purpose.field_id,
        label: purpose.field_label,
      });
    });

    this.setState({
      purposeOptions: purposeOptions,
    });
  }

  manageModals = () => {
    if (!this.props.metadata.global?.role) {
      // If we don't have any role data, then we can't update the role.
      return false;
    } else if (!this.hasRoleMetadata()) {
      this.setState({
        isRoleModalOpen: true,
      });
      return true;
    } else {
      this.setState({
        isRoleModalOpen: false,
      });
    }

    // check if user is an admin, and if there isn't already a purpose indicated
    if (this.props.pack.permission == 'admin' && !this.hasPurposeMetadata()) {
      this.setPurposeOptions();

      this.setState({
        isPurposeModalOpen: true,
      });
      return true;
    } else {
      this.setState({
        isPurposeModalOpen: false,
      });
    }

    return false;
  }

  updatePackPurpose = () => {
    this.setState({
      isProcessingPurposeUpdate: true,
    });

    this.props.onUpdatePackMetadataRequest({
      fieldId: this.state.selectedPurposeId,
      value: true,
      callback: () => {
        this.setState({
          isProcessingPurposeUpdate: false,
          isPurposeModalOpen: false,
        }, () => {
          if (this.props.onModalSetCompleted) {
            this.props.onModalSetCompleted();
          }
        });
      },
    });
  }

  updatePackRole = (role) => {
    const fieldId = this.props.metadata.global.role.field_id;

    this.setState({
      isProcessingRoleUpdate: true,
    });

    this.props.onUpdatePackMetadataRequest({
      fieldId: fieldId,
      value: role,
      callback: () => {
        this.setState({
          isProcessingRoleUpdate: false,
          isRoleModalOpen: false,
        })
      }
    });
  }
}

RequiredMetadataModal.propTypes = PT;

export default RequiredMetadataModal;
