
import React, { useState, useEffect }   from 'react';
import BruceStaticLogo                    from '_images/shared/bruce-logo.png';
import SimulatedProgressBar             from './SimulatedProgressBar';

import { quotesArray }                  from './waiting-quotes';
import { toClassStr }                   from '_utils/UiHelper';


const QUOTE_INTERVAL = 7000;
const ANIMATION_DURATION = 1000;
const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);


const BruceWaitPanel = ({ isAiProcessComplete }) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [quotes, setQuotes] = useState(shuffleArray([...quotesArray])); 

  useEffect(() => {

    const interval = setInterval(() => {
      setIsAnimating(true);

      setTimeout(() => {
        setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        setIsAnimating(false);
      }, ANIMATION_DURATION);
    }, QUOTE_INTERVAL);

    return () => clearInterval(interval);
  }, [quotes]);

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderBruceAnimation = () => {
    return (
      <>
        <div className='modal-title'>Analyzing using AI</div>
        <img src={BruceStaticLogo} className='bruce-logo' alt='Animated Bruce' />
        <div className="quote-container">
          <div className={`quote ${isAnimating ? 'animate-out' : 'animate-in'}`}>
            {quotes[currentQuoteIndex]}
          </div>
        </div>
      </>
    )
  }

  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const classes = toClassStr(['bruce-wait-panel']);

  return (
    <div className={classes}>
      {renderBruceAnimation()}
      <SimulatedProgressBar isAiProcessComplete={isAiProcessComplete} />
    </div>
  )
}

export default BruceWaitPanel;
