
import EventManager           from '@brainscape/event-manager';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import React                  from 'react';
import UiHelper               from '_utils/UiHelper';

import {toClassStr} from '_utils/UiHelper';


class DashboardOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingOptionAction: false,
    };

    /*
      this.props:
        addClasses,
        iconImageUrl,
        iconType,
        isUserPro,
        openPosition,
        shouldButtonTurn,
        title,
        userAvatarUrl,
        userProfilePath,
    */

    this.menuOptionHash = {
      viewProfile: {id: 'viewProfile', tag: 'view-profile', label: 'View Profile'},
      manageAccount: {id: 'manageAccount', tag: 'manage-account', label: 'Manage Account'},
      logOut: {id: 'logOut', tag: 'logout', label: 'Log Out'},
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillMount() {
    this.updateUserOptions();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isUserPro != prevProps.isUserPro) {
      this.updateUserOptions();
    }

    if (this.props.currentUser && !prevProps.currentUser) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['dashboard-options-button', this.props.addClasses]);

    return (
      <div className={classes}>
        <OptionsMenuButton
          iconImageUrl={this.props.iconImageUrl}
          iconType={this.props.iconType}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.isUserPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition={this.props.openPosition}
          shouldButtonTurn={this.props.shouldButtonTurn}
          title={this.props.title}
          tooltipContent={this.props.tooltipContent} 
          tooltipPosition={this.props.tooltipPosition}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick(optionId) {
    let requiresPro = this.menuOptionHash[optionId].onlyPro;

    if (requiresPro && !this.props.isUserPro) {
      let desiredAction = this.menuOptionHash[optionId].label || "";
      let paywall = this.menuOptionHash[optionId].paywall || "";
      this.triggerUpgradeModalOpen(desiredAction, paywall);
    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'viewProfile':
        this.performViewProfileAction();
      break;
      case 'manageAccount':
        this.performManageAccountAction();
      break;
      case 'logOut':
        this.performLogOutAction();
      break;
    }
  }

  performViewProfileAction() {
    UiHelper.openInNewTab(this.props.userProfilePath, 'profile');
  }

  performManageAccountAction() {
    UiHelper.navigate('/l/account', 'Loading Account Info...');
  }

  performLogOutAction() {
    UiHelper.navigate('/sign_out', 'Logging Out...');
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerUpgradeModalOpen(desiredAction, paywall) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updateUserOptions() {
    const currentUser = this.props.currentUser;
    const isBscAdmin = (currentUser && currentUser.flags && currentUser.flags.isBscAdmin);

    let menuOptionsForUser = Object.keys(this.menuOptionHash).reduce((accumulator, menuOption) => {
      // wrap this in a condition if need to restrict any options

      let permitPerBscAdminRights = (this.menuOptionHash[menuOption].onlyBscAdmin) ? isBscAdmin : true;

      if (permitPerBscAdminRights) {
        accumulator.push(this.menuOptionHash[menuOption]);
      }

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

export default DashboardOptionsButton;
