
import model                  from '_core/newModel';
import SessionStorageHelper   from '_utils/SessionStorageHelper';
import userLocalStore         from '_models/userLocalStore';


const packLearnerTransform = {

  /*
  ==================================================
   LOCAL CRUD METHODS (returns promises)
  ==================================================
  */

  index(userId, packId, packLearnerIds) {
    return new Promise((resolve, reject) => {
      try {
        const filters = this.getLocalFilters(packId);
        const selections = this.getLocalSelections(userId, packId, packLearnerIds);
        const sorters = this.getLocalSorters(userId, packId);

        resolve({
          filters: filters,
          selections: selections,
          sorters: sorters,
        });

      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  update(userId, packId, type, transformData) {

    // Allowing only one sort at a time.
    this.clear(userId, packId, 'filters')
    this.clear(userId, packId, 'sorters')
    
    return new Promise((resolve, reject) => {
      try {
        let resultData;

        switch (type) {
          case 'selections':
            resultData = this.updateSelections(userId, packId, transformData);
            model.publish('learner-selections:updated', resultData);

            resolve(resultData);
          break;

          case 'filters':
            resultData = this.updateFilters(userId, packId, transformData);
            model.publish('learner-filters:updated', resultData);

            resolve(resultData);
          break;

          case 'sorters':
            resultData = this.updateSorters(userId, packId, transformData);
            model.publish('learner-sorters:updated', resultData);

            resolve(resultData);
          break;
        }
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  destroy(userId, packId, type, transformData) {
    return new Promise((resolve, reject) => {
      try {
        let resultData;

        switch (type) {
          case 'filters':
            resultData = this.removeFilters(userId, packId, transformData);
            model.publish('learner-filters:updated', resultData);

            resolve(resultData);
          break;

          case 'sorters':
            resultData = this.removeSorters(userId, packId, transformData);
            model.publish('learner-sorters:updated', resultData);

            resolve(resultData);
          break;
        }
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  clear(userId, packId, type) {
    return new Promise((resolve, reject) => {
      try {
        let resultData;

        switch (type) {
          case 'filters':
            resultData = this.clearFilters(userId, packId);
            model.publish('learner-filters:updated', resultData);

            resolve(resultData);
          break;

          case 'sorters':
            resultData = this.clearSorters(userId, packId);
            model.publish('learner-sorters:updated', resultData);

            resolve(resultData);
          break;
        }
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearFilters(userId, packId) {
    SessionStorageHelper.setPackItem(packId, 'currentPackLearnerFilters', {});

    return {
      packId: packId,
      filters: {},
      sorters: this.getLocalSorters(userId, packId),
    }
  },

  clearSorters(userId, packId) {
    userLocalStore.setPackLearnerSorters(userId, packId, {});

    return {
      packId: packId,
      filters: this.getLocalFilters(userId, packId),
      sorters: {},
    }
  },

  getLocalFilters(packId) {
    // filters are not persistent across sessions or different current packs and are read from session
    const currentPackLearnerFilters = SessionStorageHelper.getPackItem(packId, 'currentPackLearnerFilters');
    return currentPackLearnerFilters || {};
  },

  getLocalSelections(userId, packId, packLearnerIds) {
    // selections are persisted across sessions in LocalStorage
    let localSelectionIds = userLocalStore.getSelectedPackLearnerIds(userId, packId);

    if (Object.keys(localSelectionIds).length < 1) {
      userLocalStore.unselectPackLearners(userId, packId, packLearnerIds);

      return {
        ids: localSelectionIds,
      };
    }

    // reconcile with any undetected packLearner deletions
    localSelectionIds = localSelectionIds.filter(id => packLearnerIds.indexOf(id) != -1);
    userLocalStore.selectPackLearners(userId, packId, localSelectionIds);

    return {
      ids: localSelectionIds,
    };
  },

  getLocalSorters(userId, packId) {
    // sorters are persisted across sessions in LocalStorage
    let currentPackLearnerSorters = userLocalStore.getPackLearnerSorters(userId, packId);
    return currentPackLearnerSorters || {};
  },

  removeFilters(userId, packId, transformData) {
    const {key} = transformData;

    const currentPackLearnerFilters = this.getLocalFilters(packId);
    delete currentPackLearnerFilters[key];

    SessionStorageHelper.setPackItem(packId, 'currentPackLearnerFilters', currentPackLearnerFilters);

    return {
      packId: packId,
      filters: currentPackLearnerFilters,
      sorters: this.getLocalSorters(userId, packId),
    }
  },

  removeSorters(userId, packId, transformData) {
    const {key} = transformData;

    const currentPackLearnerSorters = this.getLocalSorters(userId, packId);
    delete currentPackLearnerSorters[key];

    userLocalStore.setPackLearnerSorters(userId, packId, currentPackLearnerSorters);

    return {
      packId: packId,
      filters: this.getLocalFilters(packId),
      sorters: currentPackLearnerSorters,
    }
  },

  updateFilters(userId, packId, transformData) {    
    const {key, q} = transformData;

    const currentPackLearnerFilters = this.getLocalFilters(packId);
    currentPackLearnerFilters[key] = q;

    SessionStorageHelper.setPackItem(packId, 'currentPackLearnerFilters', currentPackLearnerFilters);

    return {
      packId: packId,
      filters: currentPackLearnerFilters,
      sorters: this.getLocalSorters(userId, packId),
    }
  },

  updateSelections(userId, packId, transformData) {
    const {learnerIds, isSelected} = transformData;

    if (isSelected) {
      userLocalStore.selectPackLearners(userId, packId, learnerIds);
    } else {
      userLocalStore.unselectPackLearners(userId, packId, learnerIds);
    }

    const selectedIds = userLocalStore.getSelectedPackLearnerIds(userId, packId);

    return {
      learnerIds: learnerIds,
      packId: packId,
      selections: {
        ids: selectedIds,
      },
    };
  },

  updateSorters(userId, packId, transformData) {    
    const {key, direction} = transformData;
    const currentPackLearnerSorters = this.getLocalSorters(userId, packId);
    currentPackLearnerSorters[key] = direction;

    userLocalStore.setPackLearnerSorters(userId, packId, currentPackLearnerSorters);

    return {
      packId: packId,
      filters: this.getLocalFilters(packId),
      sorters: currentPackLearnerSorters,
    }
  },
};

export default packLearnerTransform;
