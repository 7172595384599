
import SharePackByLinkModal         from '_views/modals/SharePackByLinkModal';
import EventManager                 from '@brainscape/event-manager';
import React                        from 'react';

class SharePackByLinkModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener(
      'share-pack-by-link-modal:open',
      (data) => this.setState({
        isModalOpen: true,
        viewProps: data
      }),
    );

    this.events.addListener(
      'share-pack-by-link-modal:close',
      () => {
        this.handleCloseRequest();
      }
    );
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    return (
      <SharePackByLinkModal
        addClasses={viewProps.addClasses}
        authenticityToken={viewProps.authenticityToken}
        currentUser={viewProps.currentUser}
        isOpen={this.state.isModalOpen}
        isProcessing={viewProps.isProcessing}
        isSingular={true}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        pack={viewProps.pack}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after all child modal close animations are complete. Removes modals from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }
}

export default SharePackByLinkModalController;
