
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import OldLearnersProgressBar    from '_views/pack-detail/desktop/learners/OldLearnersProgressBar';
import React                     from 'react';

class LearnerProgressModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        isOpen,
        isProcessing,
        learner,
        learnerDeckStats,
        onCloseRequest,
        pack,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <Modal
        addClasses="learner-progress-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
        size="large"
      >

        <div className="modal-title">{this.props.learner.firstName}'s Study Progress</div>

        <div className="modal-subtitle">Studying {this.props.pack.name}</div>

        {this.renderProgressTable()}

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            isProcessing={this.props.isProcessing}
            label="Done"
            onClick={() => this.handleDoneButtonClick()}
          />
        </div>
      </Modal>
    );
  }

  renderProgressTable() {
    if (!this.props.learnerDeckStats) {
      return null;
    }

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-left">Deck</th>
            <th>Progress</th>
            <th>Mastery</th>
            <th>Unique Cards Studied</th>
          </tr>
        </thead>
        <tbody>
          {this.renderProgressRows()}
        </tbody>
      </table>
    );
  }

  renderProgressRows() {
    const progressBars = this.props.learnerDeckStats.map((deckStats, index) => {
      return this.renderProgressRow(deckStats, index);
    });

    return progressBars;
  }

  renderProgressRow(deckStats, index) {
    return (
      <tr key={index}>
        <td>{deckStats.deckName}</td>
        <td>
          <OldLearnersProgressBar
            forceRefetch={false}
            deckID={deckStats.deckId}
            masteryRatio={deckStats.mastery}
            percentages={deckStats.percentages}
          />
        </td>
        <td className="text-center">{`${deckStats.mastery}%`}</td>
        <td className="text-center">{deckStats.seenCount + '/' + deckStats.cardCount}</td>
      </tr>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleDoneButtonClick() {
    this.props.onCloseRequest();
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

export default LearnerProgressModal;
