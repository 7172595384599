
import model from '_core/newModel';

const deckConfidence = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  reset(packId, deckId) {
    const path = `me/decks/${deckId}/confidences`;
    return model.destroy(path).then(() => model.publish('deck-confidences:reset', {
      packId: packId,
      deckId: deckId,
    }));
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckConfidence;
