
import model                  from '_core/newModel';
import SessionStorageHelper   from '_utils/SessionStorageHelper';

const packLeaderboard = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index(packId) {
    const path = `packs/${packId}/leaderboard`;
    return model.index(path);
  },


  /*
  ==================================================
   CACHING
  ==================================================
  */

  getCachedIndex(packId) {
    return SessionStorageHelper.getPackItem(packId, 'packLeaderboard') || null;
  },

  setCachedIndex(packId, packLeaderboard) {
    SessionStorageHelper.setPackItem(packId, 'packLeaderboard', packLeaderboard);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default packLeaderboard;
