
import DeckModel                 from '_legacy-models/DeckModel';
import EventManager              from '@brainscape/event-manager';
import FileDropZoneField         from '_views/shared/FileDropZoneField';
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import SimpleTextButton          from '_views/shared/SimpleTextButton';
import TextField                 from '_views/shared/TextField';
import TextareaField             from '_views/shared/TextareaField';

class ImportDeckModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      csvFile               : null,
      deckName              : '',
      deckObjective         : '',
      errorMessage          : '',
      hasInvalidInput       : false,
      isProcessing          : false,
    };

    /*
      this.props:
        addClasses,
        isOpen,
        onCloseRequest,
        pack,
    */

    this.Deck = new DeckModel;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const packName = this.props.pack.name;

    return (
      <Modal
        addClasses="import-deck-modal"
        isOpen={this.props.isOpen}
        onClosed={this.props.onClosed}
        onCloseRequest={this.props.onCloseRequest}
      >

        <div className="modal-title">Import Deck</div>

        <div className="modal-guidance">Upload a CSV file. It will become a Deck within the <span className="pack-name">{packName}</span> Class.</div>

        <form
          className="import-deck-form"
          onSubmit={this.handleFormSubmit}
        >

          <FileDropZoneField
            addClasses='import-csv-drop-zone'
            accepts={['.csv']}
            onFileSelectionError={this.handleFileSelectionError}
            onFileSelected={this.handleFileSelected}
            label="CSV Source File"
            prompt="Drop a CSV file here or click to browse"
            shouldSuppressErrorDisplay={true}
          />

          <TextField
            addClasses="deck-name"
            id="deck-name"
            label="Deck Name"
            onChange={this.handleNameChange}
            placeholder="Name your Deck (filename is default)"
            value={this.state.deckName}
          />

          <TextField
            addClasses="deck-objective"
            id="deck-objective"
            label="Deck Objective"
            onChange={this.handleObjectiveChange}
            placeholder="Describe the topics covered in the Cards of this Deck"
            value={this.state.deckObjective}
          />

        </form>

        {this.renderErrorMessage()}

        <div className="modal-actions">

          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="Cancel"
            onClick={this.handleCancelButtonClick}
          />

          <PillButton
            addClasses="resolve-modal-button"
            isDisabled={!this.state.csvFile}
            isProcessing={this.state.isProcessing}
            label="Import"
            onClick={this.handleImportButtonClick}
          />

          <SimpleTextButton
            addClasses="help-text-button"
            label="Help"
            onClick={this.handleHelpButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderErrorMessage() {
    if (this.state.errorMessage == '') {
      return null;
    }

    return (
      <div className="error-message">{this.state.errorMessage}</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = () => {
    this.props.onCloseRequest();
  }

  handleImportButtonClick = () => {
    this.handleImportRequest();
  }

  handleImportRequest = () => {
    this.setState({isProcessing: true});

    const packId        = this.props.pack.packId;
    const deckName      = this.state.deckName;
    const deckObjective = this.state.deckObjective;
    const csvFile       = this.state.csvFile;

    this.Deck.import(packId, deckName, deckObjective, csvFile).then((rs) => {
      this.props.onCloseRequest();
      // this.triggerRefreshPackDetailSection();
      this.publishDeckImported();

      let msg = rs.msg;

      if (rs.warnings && rs.warnings.length > 0) {
        msg += "\n\nImport Warnings:";
        rs.warnings.forEach((w) => { msg += `\n  * ${w}`; });
      }

      alert(msg);

    }).catch((err) => {
      this.setState({
        errorMessage:    `Could not process the CSV: ${err.message}.`,
        hasInvalidInput: true,
      });
    });
  }

  handleHelpButtonClick = () => {
    window.open('https://brainscape.zendesk.com/hc/en-us/articles/115002369931-How-do-I-import-a-csv-file-correctly-', '_blank', 'noopener');
  }

  handleFileSelected = (fileData) => {
    if (fileData) {
      this.setState({
        csvFile: fileData,
        errorMessage: '',
        hasInvalidInput: false,
      });
    }
  }

  handleFileSelectionError = (error, file) => {
    this.setState({
      csvFile: null,
      errorMessage: error.message,
      hasInvalidInput: true,
    })
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.handleImportRequest();
  }

  handleNameChange = (e) => {
    const deckName = e.target.value;
    this.setState({
      deckName: deckName,
    })
  }

  handleObjectiveChange = (e) => {
    const deckObjective = e.target.value;
    this.setState({
      deckObjective: deckObjective,
    })
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishDeckImported() {
    EventManager.emitEvent('deck:imported', {
      packId: this.props.pack.packId,
    });
  }
}

export default ImportDeckModal;
