
import React                          from 'react';

import {toClassStr} from '_utils/UiHelper';

class TransitionWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTransitionedIn: false,
      isTransitionedOut: false,
      isTransitioningIn: false,
      isTransitioningOut: false,
    };

    /*
      this.props:
        addClasses,
        onTransitionedIn,
        onTransitionedOut,
        shouldTransitionIn,
        shouldTransitionOut,
        transitionInDelay,
        transitionInDuration,
        transitionOutDuration,
    */

    this._isMounted = false;

    this.transitionInDelay = null;
    this.transitionInDuration = null;
    this.transitionOutDuration = null;

    this.TRANSITION_IN_DELAY_DEFAULT = 100;
    this.TRANSITION_IN_DURATION_DEFAULT = 500;
    this.TRANSITION_OUT_DURATION_DEFAULT = 800;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();

    this.manageTransitionRequests();
  }

  componentDidUpdate(prevProps) {
    this.manageTransitionRequests(prevProps);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.clearTimeoutsAndIntervals();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const transitioningInClass = this.state.isTransitioningIn ? 'is-transitioning-in' : '';
    const transitioningOutClass = this.state.isTransitioningOut ? 'is-transitioning-out' : '';
    const transitionedInClass = this.state.isTransitionedIn ? 'is-transitioned-in' : '';
    const transitionedOutClass = this.state.isTransitionedOut ? 'is-transitioned-out' : '';

    const classes = toClassStr(['transition-wrapper', transitioningInClass, transitioningOutClass, transitionedInClass, transitionedOutClass, this.props.addClasses]);

    return (
      <div className={classes}>
        {this.props.children}
      </div>
    );
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  invokeTransitionIn() {
    clearTimeout(this.transitionInDelay);
    this.setState({
      isTransitionedIn: false,
      isTransitioningIn: false,
      isTransitionedOut: false,
      isTransitioningOut: false,
    })

    this.transitionInDelay = setTimeout(() => {
      clearTimeout(this.transitionInDelay);

      this.setState({
        isTransitioningIn: true,
      }, () => {
        this.completeTransitionIn();
      });
    }, this.props.transitionInDelay || this.TRANSITION_IN_DELAY_DEFAULT);
  }

  completeTransitionIn() {
    clearTimeout(this.transitionInDuration);

    this.transitionInDuration = setTimeout(() => {
      clearTimeout(this.transitionInDuration);

      this.setState({
        isTransitionedIn: true,
        isTransitioningIn: false,
      }, () => {
        if (this.props.onTransitionedIn) {
          this.props.onTransitionedIn();
        }
      });
    }, this.props.transitionInDuration || this.TRANSITION_IN_DURATION_DEFAULT);
  }

  invokeTransitionOut() {
    this.setState({
      isTransitionedOut: false,
      isTransitioningOut: true,
    }, () => {
      this.completeTransitionOut();
    })
  }

  completeTransitionOut() {
    clearTimeout(this.transitionOutDuration);

    this.transitionOutDuration = setTimeout(() => {
      clearTimeout(this.transitionOutDuration);

      this.setState({
        isTransitionedOut: true,
        isTransitioningOut: false,
      }, () => {
        if (this.props.onTransitionedOut) {
          this.props.onTransitionedOut();
        }
      });
    }, this.props.transitionOutDuration || this.TRANSITION_OUT_DURATION_DEFAULT);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  manageTransitionRequests(prevProps) {
    if (!prevProps) {

      if (this.props.shouldTransitionIn) {
        this.invokeTransitionIn();
      } 

      if (this.props.shouldTransitionOut) {
        this.invokeTransitionOut();
      }

      return;
    }

    if (!prevProps.shouldTransitionIn && this.props.shouldTransitionIn) {
      this.invokeTransitionIn();
    }

    if (!prevProps.shouldTransitionOut && this.props.shouldTransitionOut) {
      this.invokeTransitionOut();
    }    
  }

  clearTimeoutsAndIntervals() {
    clearTimeout(this.transitionInDelay);
    clearTimeout(this.transitionInDuration);
    clearTimeout(this.transitionOutDuration);
  }
}

export default TransitionWrapper;
