import React from "react";

const useCardSlider = (
  parentH,
  headerH,
  hiContentH,
  loContentH,
  hrH,
  scrollRef,
  revealed
) => {
  const afRef = React.useRef(null);
  const [mins, setMinsRaw] = React.useState([null, null]);
  const slideH = parentH - headerH;

  const setMins = (hi, lo) => {
    setMinsRaw([Math.floor(hi), lo]);
  };

  React.useEffect(() => {
    let canceled = false;
    if (!revealed) {
      // If we're only showing the top view, it should be centered.
      setMins(slideH, null);
    } else {
      const [hiMin, loMin] = calcRevealedMins(
        slideH,
        hiContentH,
        loContentH,
        hrH
      );

      const range = hiMin - mins[0];
      const start = Date.now();
      let hasScrolled = false;

      cancelAnimationFrame(afRef.current);
      afRef.current = requestAnimationFrame(function animate(ts) {
        if (canceled) return;

        const pct = (Date.now() - start) / ANIM_DUR;
        const min = Math.max(hiMin, hiMin - Math.floor(range * (1 - pct)));

        setMins(min, loMin);

        if (min !== hiMin) {
          afRef.current = requestAnimationFrame(animate);
        } else {
          const minH = (hiMin || hiContentH) + (loMin || loContentH);
          if (!hasScrolled && minH > slideH) {
            const hiWindow = Math.ceil(slideH * 0.2);
            const st = hiContentH - hiWindow;

            if (st > 0) {
              scrollRef.current?.scrollTo({ top: st, behavior: "smooth" });
            }

            hasScrolled = true;
          }
        }
      });
    }

    return () => {
      canceled = true;
      cancelAnimationFrame(afRef.current);
      afRef.current = null;
    };
  }, [revealed, parentH, headerH, hiContentH, loContentH, hrH]);

  return mins;
};

const calcRevealedMins = (slideH, hiContentH, loContentH, hrH) => {
  const h = halfHeight(slideH, hrH);
  if (h && h > 0) {
    // We want to error on the side of cation in order to avoid scroll bars
    // for single pixel differences.
    const hiCeilH = Math.ceil(hiContentH);
    const loCeilH = Math.ceil(loContentH);

    if (hiCeilH <= h && loCeilH <= h) {
      // If both sides are less than half height, make them equal.
      return [h, h];
    }

    const extra = Math.floor((h * 2 - hiCeilH - loCeilH) / 2);
    if (extra > 0) {
      // If both sides can fit in the area, then try to make them "even".
      return [hiCeilH + extra, loCeilH + extra];
    }
  }

  return [hiContentH, null];
};

// Returns the "half height" taking into account the Hr and pixel rounding.
const halfHeight = (slideH, hrH) => {
  if (!slideH) return null;

  // The `floor` & `-2` is to reduce jitter due to pixel rounding;
  return Math.floor((slideH - hrH) / 2 - 2);
};

const ANIM_DUR = 150; // Animation Duration, milliseconds

export { useCardSlider as default };
