
const DomHelper = {

  getAncestor(elem, selector) {
    let parent = elem.parentElement;

    if (!parent) {
      return null;
    }

    if (!selector) {
      return parent;
    }

    while (parent) {
      if (parent.matches(selector)) {
        return parent;
      }

      parent = parent.parentElement;
    }

    return null;
  },

  getMetaTagContent(metaTagName) {
    const metaTags = document.getElementsByTagName('meta');

    for (let i = 0; i < metaTags.length; i++) {
      if (metaTags[i].getAttribute('name') === metaTagName) {
        return metaTags[i].getAttribute('content');
      }
    }

    return null;
  },

  getNextSibling(elem, selector) {
    // adapted from https://gomakethings.com/finding-the-next-and-previous-sibling-elements-that-match-a-selector-with-vanilla-js/
    
    let sibling = elem.nextElementSibling;

    if (!sibling) {
      return null;
    }

    if (!selector) {
      return sibling;
    }

    // go to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) {
        return sibling;
      }

      sibling = sibling.nextElementSibling;
    }

    return null;
  },

  getPrevSibling(elem, selector) {
    // adapted from https://gomakethings.com/finding-the-next-and-previous-sibling-elements-that-match-a-selector-with-vanilla-js/
    
    let sibling = elem.previousElementSibling;

    if (!sibling) {
      return null;
    }

    if (!selector) {
      return sibling;
    }

    // go to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) {
        return sibling;
      }

      sibling = sibling.previousElementSibling;
    }

    return null;
  },

  getSelfOrAncestor(elem, selector) {
    if (elem.matches(selector)) {
      return elem;
    }

    return DomHelper.getAncestor(elem, selector);
  }
};

export default DomHelper;
