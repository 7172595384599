
import model from '_core/newModel';
import pack from '_models/pack';


const userPack = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index() {
    const path = 'me/library';
    const isPaginated = true;

    const paginationOpts = {
      collectionKey: 'packs',
      eventTag: 'user-packs',
      page: 1,
      perPage: 15,
    };

    return model.index(path, isPaginated, paginationOpts);
  },

  remove(id) {
    const path = `me/library/${id}`;

    return model.destroy(path)
      .then(res => {
        // Publish event that pack was removed
        model.publish('pack:removed', { packId: id });
        return res;
      })
      .catch(err => {
        // Check if the error is because it was a UGC with just 1 single user.
        if (err.message && err.message.includes('You must delete')) {
          return pack.destroy(id)
            .then(res => {
              model.publish('pack:deleted', { packId: id });
              return res;
            })
            .catch(innerErr => {
              console.error('Failed to delete the pack:', innerErr);
              return Promise.reject(innerErr);  // Use Promise.reject() to ensure the error is propagated properly
            });
        } else {
          return Promise.reject(err);  // Use Promise.reject() to propagate other errors
        }
      });
  },


  /*
  ==================================================
   LOCAL TRANSFORM METHODS
  ==================================================
  */

  filter(userPacks, filters) {
    if (!(filters && filters?.length && filters.length > 0)) {
      return userPacks;
    }

    let filteredPacks = [...userPacks];
    let resultPacks;

    filters.forEach(filter => {
      resultPacks = [];

      filteredPacks.forEach((pack, index) => {
        if (pack[filter.key].indexOf(filter.q) != -1) {
          resultPacks.push(pack);
        }
      });

      filteredPacks = [...resultPacks];
    });

    return resultPacks;
  },

  sort(userPacks, sorters) {
    if (!(sorters && Object.keys(sorters))) {
      sorters.name = 'asc';
    }
    
    let sortedPacks = [...userPacks];
    let resultPacks;

    Object.keys(sorters).forEach(sorterKey => {
      const sorterDirection = sorters[sorterKey];

      resultPacks = [];

      resultPacks = sortedPacks.sort((a, b) => {
        if (sorterDirection == 'asc') {
          return ((a[sorterKey] >= b[sorterKey]) ? 1 : -1);
        } else {
          return ((a[sorterKey] <= b[sorterKey]) ? 1 : -1);
        }
      });

      sortedPacks = [...resultPacks];
    });

    return resultPacks;
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default userPack;
