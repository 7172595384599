
// substrate and utils
import EventManager               from '@brainscape/event-manager';
import PillButton                 from '_views/shared/PillButton';
import PropTypes                  from 'prop-types';
import React                      from 'react';
import {toClassStr}               from '_utils/UiHelper';

// models
import userLocalStore             from '_models/userLocalStore';

// sub-components
import RevealCardFaceButton       from '_views/shared/smart-cards/RevealCardFaceButton';
import SimpleTextButton           from '_views/shared/SimpleTextButton';
import SmartCard                  from '_views/shared/smart-cards/SmartCard';
import UiHelper                   from '_utils/UiHelper';

import {
  BackButton,
  ForwardButton,
}                                 from '_views/shared/IconButton';

import {RevealModes}               from "_views/shared/smart-cards/animation/Constants";


const PT = {
  addClasses:               PropTypes.string,
  currentUser:              PropTypes.object,
  deck:                     PropTypes.object,
  isMobileViewportSize:     PropTypes.bool,
  pack:                     PropTypes.object,
}

const CARD_LIMIT_COUNT = 15;
const CARD_LIMIT_PERCENTAGE = 40;


class DeckBrowseSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      baseFontSize: 30,
      browseCardFace: 'question',
      currentCard: null,
      currentCardMode: 'display',
      currentIndex: 0,
      imageViewerUrl: null,
      isImageViewerOpen: false,
      skipDirection: 'forward',
      viewportStyle: this.getBrowseViewportStylePref(),
    };

    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.setupBrowseData();
    this.subscribeToEvents();
    this.startKeydownMonitor();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cards != this.props.cards) {
      this.setupBrowseData();
    }

    if (this.props.currentCardId != prevProps.currentCardId) {
      this.setupBrowseData();
    }
  }

  componentWillUnmount() {
    this.stopKeydownMonitor();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('smart-card:display-card-mode-entered', this.handleSmartCardDisplayCardModeEntered);
    this.events.addListener('smart-card:edit-card-mode-entered', this.handleSmartCardEditCardModeEntered);
    this.events.addListener('viewport-style-change-request', this.handleViewportStyleChangeRequested);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewport = (this.props.isMobileViewportSize) ? 'mobile' : '';
    const classes = toClassStr(['deck-browse-section', viewport, this.props.addClasses]);

    return (
      <section className={classes} id="primary-nav" data-pack-id={this.props.pack.packId}>

        <header className="deck-browse-section-header">
          {this.renderViewportStyleSelector()}
        </header>

        {this.renderBrowseCards()}
      </section>
    );
  }

  renderViewportStyleSelector = () => {
    return (
      <div className="viewport-style-selector">
        {this.renderViewportStyleSelectorButton('desktop', 'Simulate Desktop setting')}
        {this.renderViewportStyleSelectorButton('mobile', 'Simulate Mobile setting')}
      </div>
    );
  }

  renderViewportStyleSelectorButton = (style, tooltipText) => {
    const isSelectedClass = (this.state.viewportStyle == style) ? 'is-selected' : '';
    const viewportStyleButtonClass = `${style}-viewport-style-text-button`;
    const classes = toClassStr(['viewport-style-text-button', viewportStyleButtonClass, isSelectedClass]);

    return (
      <SimpleTextButton 
        addClasses={classes}
        label={style} 
        onClick={() => this.handleViewportStyleSelectorButtonClick(style)}
        tooltipContent={tooltipText}
        tooltipPosition='top'
      />
    );
  }

  renderBrowseCards() {
    const cards = this.props.cards;

    if (!(cards && cards.length > 0)) {
      return this.renderNoCardsInfo();
    }

    return (
      <div className="browse-cards">

        <div className="card-gutter left">
          {this.renderBackButton()}
        </div>

        {this.renderChromeAndCard()}
        
        <div className="card-gutter right">
          {this.renderForwardButton()}
        </div>
      </div>
    );
  }

  renderBackButton() {
    if (this.state.currentCardMode == 'edit') {
      return null;
    }

    if (this.state.currentIndex - 1 < 0) {
      return null;
    }

    return (
      <BackButton 
        onClick={this.handleBackButtonClick}
      />
    );
  }

  renderChromeAndCard() {
    const browseCard = {
      ...this.props.currentCard,
      packId: this.props.pack.packId,
    };

    const viewportStyleClass = `${this.state.viewportStyle}-viewport-style`; 
    const deviceChrome = this.props.isMobileViewportSize ? 'no-device-chrome' : 'device-chrome';
    const classes = toClassStr([deviceChrome, viewportStyleClass]);

    return (
      <div className={classes}>

        <div className="cards-and-bar">
          
          {this.renderSmartCard(browseCard)}

          <div className="study-controls">
            {this.renderRevealCardFaceButton()}
          </div>
        </div>
      </div>
    );
  }

  renderSmartCard(card) {
    const deck = this.props.deck;
    const isAnswerFace = (this.state.browseCardFace == 'answer');

    const cardPosition = this.state.currentIndex + 1;
    const cardCount = this.props.cards.length;

    const isBlurred = isAnswerFace ? this.isCardBlurred(cardPosition, cardCount) : false;

    return (
      <SmartCard
        card={card}
        cardCount={cardCount}
        cardMode="display"
        cardPosition={cardPosition}
        context="browse"
        currentCardFace={this.state.browseCardFace}
        currentUser={this.props.currentUser}
        deck={this.props.deck}
        format="md"
        isAtCheckpoint={false}
        isAudioMuted={false}
        isBlurred={isBlurred}
        isCurrentCard={true}
        isMobileViewportSize={this.props.isMobileViewportSize}
        layout="single-card"
        pack={this.props.pack}
        packId={this.props.pack.packId}
        skipDirection={this.state.skipDirection}
        viewportStyle={this.state.viewportStyle}
      />
    );
  }

  renderRevealCardFaceButton = () => {
    const isDisabled = (this.state.currentCardMode == 'edit');
    const tooltipContent = (this.state.currentCardMode == 'edit') ? 'Save your card or hit the "Resume Browse" button to continue' : 'Press SPACEBAR to continue';

    return (
      <RevealCardFaceButton 
        face={this.state.browseCardFace}
        isDisabled={isDisabled}
        isFtse={false}
        level={0}
        onRevealCardFaceRequest={this.handleBrowseCardFlipRequest}
        shouldPulse={false}
        tooltipContent={tooltipContent}
        tooltipPosition="top"
      />
    );
  }

  renderForwardButton() {
    if (this.state.currentCardMode == 'edit') {
      return null;
    }

    const cards = this.props.cards;
    const currentIndex = this.state.currentIndex;

    if (currentIndex + 1 >= cards.length) {
      return null;
    }

    return (
      <ForwardButton 
        onClick={this.handleForwardButtonClick}
      />
    );
  }

  renderNoCardsInfo() {
    return (
      <div className="deck-no-cards-info">
        <h2 className="card-name">Add Cards to your Deck</h2>
        <p className="card-name-caption"><strong>Your Deck has no Cards</strong>. Add Cards to your Deck to get started.</p>

        <PillButton
          addClasses="pill-button-emphasized add-cards-button"
          isProcessing={this.state.isProcessingDeckCreate}
          label="Add Cards"
          onClick={this.handleAddCardsButtonClick}
        />
      </div>
    );
  }

  renderContentTypeFlag() {
    if (!this.props.isUserBscAdmin) {
      return null;
    }

    return (
      <div className="content-type-flag">{this.props.deck.contentType}</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddCardsButtonClick = () => {
    this.triggerCurrentTabChangeRequest('edit');
  }

  handleBackButtonClick = () => {
    this.skipBack();
  }

  handleBrowseCardFlipRequest = (e) => {
    if (e) {
      e.stopPropagation();
    }

    const face = (this.state.browseCardFace == 'question') ? 'answer' : 'question';

    this.setState({
      browseCardFace: face,
    });
  }

  handleForwardButtonClick = () => {
    this.skipForward();
  }

  handleImageViewerClose = () => {
    this.setState({
      isImageViewerOpen: false,
    });
  }

  handleKeyDown = (e) => {
    if (this.state.currentCardMode == 'edit') {
      return false;
    }

    // left arrow
    if (e.keyCode == 37) {
      e.preventDefault();
      e.stopPropagation();

      this.skipBack();
      return true;
    }

    // right arrow
    if (e.keyCode == 39) {
      e.preventDefault();
      e.stopPropagation();

      this.skipForward();
      return true;
    }
  };

  handleSmartCardDisplayCardModeEntered = (eventData) => {
    if (eventData.cardId != this.props.currentCard.cardId) {
      return false;
    }

    this.setState({
      currentCardMode: 'display',
    });
  }

  handleSmartCardEditCardModeEntered = (eventData) => {
    if (eventData.cardId != this.props.currentCard.cardId) {
      return false;
    }

    this.setState({
      currentCardMode: 'edit',
    });
  }

  handleViewLargeImageRequest = (url) => {
    this.setState({
      imageViewerUrl: url,
      isImageViewerOpen: true,
    });
  }

  handleViewportStyleChangeRequested = (eventData) => {
    this.setState({
      viewportStyle: eventData.newStyle,
    }, () => {
      this.setBrowseViewportStylePref(eventData.newStyle);
    });
  }

  handleViewportStyleSelectorButtonClick = (style) => {
    this.setState({
      viewportStyle: style,
    }, () => {
      this.setBrowseViewportStylePref(style);
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCurrentCardIndexChanged = (card) => {
    EventManager.emitEvent('dashboard:current-card-index-changed', {
      card: card,
    });
  }

  triggerCurrentTabChangeRequest = (tabId='preview') => {
    EventManager.emitEvent('current-tab:change-request', {
      tabId: tabId,
    });
  }

  triggerNextCardRequest = () => {
    const cardId = this.props.currentCard.cardId;

    EventManager.emitEvent('current-card:next-card-request', {
      cardId: cardId,
    });
  }

  triggerPrevCardRequest = () => {
    const cardId = this.props.currentCard.cardId;

    EventManager.emitEvent('current-card:prev-card-request', {
      cardId: cardId,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getBrowseViewportStylePref = () => {
    const browseViewportStylePref = userLocalStore.getUserLocalPref(this.props.currentUser.userId, 'browseViewportStylePref');
    return browseViewportStylePref || 'desktop';
  }

  setBrowseViewportStylePref = (style) => {
    userLocalStore.setUserLocalPref(this.props.currentUser.userId, 'browseViewportStylePref', style);
  }

  getCurrentIndex = (cardId) => {
    const cards = this.props.cards;

    if (!(cards && cards.length > 0)) {
      return -1;
    }

    const index = cards.findIndex((card) => {
      return (card.cardId == cardId);
    });

    return index;
  }

  hasCardLimit = () => {
    return (this.props.deck.flags.isLocked && !this.props.currentUser.flags.isPro);
  }

  isCardBlurred = (cardPosition, cardCount) => {
    if (!this.hasCardLimit()) {
      return false;
    }

    if (cardPosition > CARD_LIMIT_COUNT) {
      return true;
    }

    if (((cardPosition / cardCount) * 100) > CARD_LIMIT_PERCENTAGE) {
      return true;
    }

    return false;
  }

  skipBack = () => {
    this.setState({
      skipDirection: 'back',
    }, () => {
      this.triggerPrevCardRequest();
    });
  }

  skipForward = () => {
    this.setState({
      skipDirection: 'forward',
    }, () => {
      this.triggerNextCardRequest();
    });
  }

  setNewCard = (increment) => {
    let newIndex = this.state.currentIndex + increment;
    const newCard = this.props.cards[newIndex];

    if (newIndex < 0) {
      newIndex = this.props.cards.length - 1;
    }

    if (newIndex >= this.props.cards.length) {
      newIndex = 0;
    }

    this.setState({
      browseCardFace: 'question',
      currentIndex: newIndex,
    }, () => {
      this.triggerCurrentCardIndexChanged(newCard);
    });
  }

  setupBrowseData = () => {
    const cards = this.props.cards;

    if (!cards) {
      return false;
    }

    let currentIndex = (this.props.currentCard) ? this.getCurrentIndex(this.props.currentCard.cardId) : 0;

    if (currentIndex == -1) {
      return false;
    }

    this.setState({
      browseCardFace: 'question',
      currentIndex: currentIndex,
    });
  }

  startKeydownMonitor = () => {
    if (this._isMounted) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  stopKeydownMonitor = () => {
    if (this._isMounted) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }
}

DeckBrowseSection.propTypes = PT;

export default DeckBrowseSection;
