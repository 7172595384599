
import {LargeCtaButton}         from '_views/shared/PillButton';
import AnimatedCards            from '_views/shared/AnimatedCards';
import Bubble                   from '_views/shared/Bubble';
import BubbleDialog             from '_views/shared/BubbleDialog';
import ConfidenceLevelButtons   from '_views/shared/ConfidenceLevelButtons';
import EarlyExitBubble          from './EarlyExitBubble';
import EventManager             from '@brainscape/event-manager';
import PropTypes                from 'prop-types';
import React                    from 'react';
import StringHelper             from '_utils/StringHelper';


import {toClassStr} from '_utils/UiHelper';


const PT = {
  isFtse:                     PropTypes.bool,
};

const REPEAT_FREQUENCIES = {
  1 : 'often',
  2 : 'often',
  3 : 'occasionally',
  4 : 'rarely',
  5 : 'rarely',
}


class FtseMessaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openMessage: {
        firstStart: false,
        firstQuestion: false,
        firstAnswer: false,
        postFirstRating: false,
        firstRepeatCardAndRoundMidpoint: false,
        firstRepeatCard: false,
        firstRoundMidpoint: false,
      },
      closeMessage: {
        firstStart: false,
        firstQuestion: false,
        firstAnswer: false,
        postFirstRating: false,
        firstRepeatCardAndRoundMidpoint: false,
        firstRepeatCard: false,
        firstRoundMidpoint: false,
      },
      messageData: {},
    };

    this.events = new EventManager();
    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.subscribeToEvents();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.unsubscribeToEvents();
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    if (!this.props.isFtse) {
      return false;
    }

    this.events.addListener('ftse:open-message',              this.handleOpenMessage);
    this.events.addListener('ftse:close-message',             this.handleCloseMessage);
    this.events.addListener('ftse:close-messages',            this.handleCloseMessages);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['ftse-messaging', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderFirstStartMessage()}
        {this.renderFirstQuestionMessage()}
        {this.renderFirstAnswerMessage()}
        {this.renderPostFirstRatingMessage()}
        {this.renderFirstRepeatCardAndRoundMidpointMessage()}
        {this.renderFirstRepeatCardMessage()}
        {this.renderFirstRoundMidpointMessage()}
        {this.renderEarlyExitMessage()}
      </div>
    );
  }

  renderFirstStartMessage() {
    if (!this.state.openMessage.firstStart) return null;

    return (
      <Bubble
        addClasses="first-start-bubble"
        contractionDelay={60000}
        expansionDelay={200}
        isEscapeKeyActive={true}
        onClosed={this.handleFirstStartMessageClosed}
        shouldAutoContract={true}
        shouldClose={this.state.closeMessage.firstStart}
        shouldContract={true}
        shouldExpand={true}
        size="full"
      >
        <div className="study-intro">
          <AnimatedCards />

          <div className="about-text">
            Brainscape's Confidence-Based Repetition &reg; (CBR)<br />system helps you learn faster, using brain science.
          </div>

          <LargeCtaButton
            addClasses="study"
            label="Study 10 Cards"
            onClick={this.handleFirstStartBubbleCtaClick}
          />
        </div>
      </Bubble>
    );
  }

  renderFirstQuestionMessage() {
    if (!this.state.openMessage.firstQuestion) return null;

    return (
      <BubbleDialog
        addClasses="first-question-bubble"
        expansionDelay={800}
        message="Reveal the answer when you're ready."
        onClosed={this.handleFirstQuestionMessageClosed}
        size="small"
        shouldAutoContract={false}
        shouldClose={this.state.closeMessage.firstQuestion}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderFirstAnswerMessage() {
    if (!this.state.openMessage.firstAnswer) return null;

    return (
      <BubbleDialog
        addClasses="first-answer-bubble"
        expansionDelay={1500}
        message='Rate how confidently you knew the answer.'
        onClosed={this.handleFirstAnswerMessageClosed}
        size="small"
        shouldAutoContract={false}
        shouldClose={this.state.closeMessage.firstAnswer}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderPostFirstRatingMessage() {
    if (!this.state.openMessage.postFirstRating) return null;

    const level = this.state.messageData.postFirstRating?.level;

    return (
      <Bubble
        addClasses="post-first-rating-bubble cbr-bubble"
        expansionDelay={800}
        isEscapeKeyActive={true}
        onClosed={this.handlePostFirstRatingMessageClosed}
        shouldAutoContract={false}
        shouldClose={this.state.closeMessage.postFirstRating}
        shouldContract={true}
        shouldExpand={true}
        size="full"
      >
        <div className="cbr-info">
          <div className="rating">You rated this card a {level}.</div>

          <div className="explanation">{this.getCbrExplanation()}</div>

          <LargeCtaButton
            addClasses="got-it"
            label="Got it!"
            onClick={this.handlePostFirstRatingBubbleCtaClick}
            title="Hit [Space Bar] to resume studying"
          />

          <div className="legends-and-buttons">
            <div className="button-legends-and-arrow">
              <div className="button-legend repeat-often">Repeat Often</div>

              <div className="arrow">
                <div className="left-triangle" />
                <div className="line" />
                <div className="right-triangle" />
              </div>

              <div className="button-legend repeat-rarely">Repeat Rarely</div>
            </div>

            <ConfidenceLevelButtons
              highlightedRatingButton={level}
              onButtonClick={() => {}}
            />
          </div>
        </div>
      </Bubble>
    );
  }

  renderFirstRepeatCardAndRoundMidpointMessage() {
    if (!this.state.openMessage.firstRepeaCardAndRoundMidpoint) return null;

    const level = this.state.messageData.firstRepeatCardAndRoundMidpoint?.level;

    if (!level) return null;

    return (
      <BubbleDialog
        addClasses="first-repeat-card-and-round-midpoint-bubble"
        expansionDelay={100}
        message={this.renderFirstRepeatCardAndRoundMidpointMessageText(level)}
        onClosed={this.handleFirstRepeatCardAndRoundMidpointMessageClosed}
        size="small"
        shouldAutoContract={false}
        shouldClose={this.state.closeMessage.firstRepeatCardAndRoundMidpoint}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderFirstRepeatCardAndRoundMidpointMessageText(level) {
    const repeatFrequencyPhrase = this.getRepeatFrequencyPhrase(level);

    return (
      <span className="first-repeat-message">
        {`You're seeing this card again because you rated it a ${level}.`}
        <br /><br />
        {`${level}'s repeat ${repeatFrequencyPhrase}.`}
        <br /><br />
        You're also halfway through your study round!
      </span>
    );
  }

  renderFirstRepeatCardMessage() {
    if (!this.state.openMessage.firstRepeatCard) return null;

    const level = this.state.messageData.firstRepeatCard?.level;

    if (!level) return null;

    return (
      <BubbleDialog
        addClasses="first-repeat-card-bubble"
        expansionDelay={100}
        message={this.renderFirstRepeatCardMessageText(level)}
        onClosed={this.handleFirstRepeatCardMessageClosed}
        size="small"
        shouldAutoContract={false}
        shouldClose={this.state.closeMessage.firstRepeatCard}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderFirstRepeatCardMessageText(level) {
    const repeatFrequencyPhrase = this.getRepeatFrequencyPhrase(level);

    return (
      <span className="first-repeat-message">
        {`You're seeing this card again because you rated it a ${level}.`}
        <br /><br />
        {`${level}'s repeat ${repeatFrequencyPhrase}.`}
      </span>
    );
  }

  renderFirstRoundMidpointMessage() {
    if (!this.state.openMessage.firstRoundMidpoint) return null;

    let bubbleMessage = "You're halfway there. Keep going until your goal!";
    const roundLength = this.state.messageData.firstRoundMidpoint?.roundLength;
    const cardNumber = this.state.messageData.firstRoundMidpoint?.cardNumber;

    if (roundLength && cardNumber) {
      bubbleMessage = `You're halfway there. Just ${roundLength - cardNumber} more cards until your goal!`;
    } 

    return (
      <BubbleDialog
        addClasses="first-round-midpoint-bubble"
        expansionDelay={100}
        message={bubbleMessage}
        onClosed={this.handleFirstRoundMidpointMessageClosed}
        size="small"
        shouldAutoContract={true}
        shouldClose={this.state.closeMessage.firstRoundMidpoint}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderEarlyExitMessage() {
    if (!this.state.openMessage.earlyExit) return null;

    const cardNumber = this.state.messageData.earlyExit?.cardNumber;

    return (
      <EarlyExitBubble
        onConfirmCloseStudyMix={this.handleCloseStudySessionRequest}
        onClosed={this.handleEarlyExitMessageClosed}
        onResumeStudyRequest={this.handleResumeStudyRequest}
        roundStepIndex={cardNumber}
        shouldClose={this.state.closeMessage.earlyExit}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleMessageClosed = (messageKey) => {
    const newState = {...this.state};
    newState.openMessage[messageKey] = false;

    this.setState(newState, () => {
      const messageTag = StringHelper.toKebobCase(messageKey);
      this.publishFtseMessageClosed(messageTag);
    });
  }

  handleCloseMessage = (eventData) => {
    const messageTag = eventData.messageTag;
    const messageKey = StringHelper.toCamelCase(messageTag);

    if (!messageKey) return false;

    const newState = {...this.state};

    newState.closeMessage[messageKey] = true;

    this.setState(newState);
  }

  handleCloseMessages = () => { 
    // close open messages
    const closeMessage = {...this.state.closeMessage};
    const openMessage = {...this.state.openMessage};
    const openMessageKeys = Object.keys(openMessage);

    openMessageKeys.forEach(key => {
      if (openMessage[key]) closeMessage[key] = true;
    });

    this.setState({
      closeMessage: closeMessage,
    });
  }

  handleCloseStudySessionRequest = () => {
    this.triggerDismissFtseRequest();
    this.triggerCloseStudySessionRequest();
  }

  handleFirstAnswerMessageClosed = () => {
    this.handleMessageClosed('firstAnswer');
  }

  handleFirstStartBubbleCtaClick = () => {
    const newState = {...this.state};
    newState.closeMessage.firstStart = true;

    this.setState(newState, () => {
      this.publishActionPerformed('study-10-btn');
    });
  }

  handleFirstStartMessageClosed = () => {
    this.handleMessageClosed('firstStart');
  }

  handleFirstQuestionMessageClosed = () => {
    this.handleMessageClosed('firstQuestion');
  }

  handleFirstRoundMidpointMessageClosed = () => {
    this.handleMessageClosed('firstRoundMidpoint');
  }

  handleFirstRepeatCardAndRoundMidpointMessageClosed = () => {
    this.handleMessageClosed('firstRepeatCardAndRoundMidpoint');
  }

  handleFirstRepeatCardMessageClosed = () => {
    this.handleMessageClosed('firstRepeatCard');
  }

  handleOpenMessage = (eventData) => {    
    const messageTag = eventData.messageTag;
    const messageKey = StringHelper.toCamelCase(messageTag);

    if (!messageKey) return false;

    const newState = {...this.state};

    newState.openMessage[messageKey] = true;
    newState.messageData[messageKey] = eventData;

    this.setState(newState, () => {
      this.publishFtseMessageSeen(messageTag);
    })
  }

  handlePostFirstRatingMessageClosed = () => {
    this.handleMessageClosed('postFirstRating');
  } 

  handlePostFirstRatingBubbleCtaClick = () => {
    const newState = {...this.state};
    newState.closeMessage.postFirstRating = true;

    this.setState(newState);
  }

  handleResumeStudyRequest = () => {
    const newState = {...this.state};
    newState.closeMessage.earlyExit = true;

    this.setState(newState);
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishFtseMessageSeen = (messageTag) => {
    EventManager.emitEvent('ftse:message-seen', {
      messageTag: messageTag,
    });
  }

  publishFtseMessageClosed = (messageTag) => {
    EventManager.emitEvent('ftse:message-closed', {
      messageTag: messageTag,
    });
  }

  publishActionPerformed = (actionTag) => {
    EventManager.emitEvent('ftse:action-performed', {
      actionTag: actionTag,
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerDismissFtseRequest = () => {
    EventManager.emitEvent('ftse:dismiss-requested', {});
  }

  triggerCloseStudySessionRequest = () => {
    EventManager.emitEvent('study-session:close-requested', {
      endFtse: true,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getCbrExplanation() {
    const cardRating = this.state.messageData.firstAnswer?.level;

    if (cardRating < 3) {
      return "It will repeat soon.";
    }

    if (cardRating == 3) {
      return "It will repeat in a while.";
    }

    if (cardRating > 3) {
      return "It shouldn't repeat for awhile.";
    }

    return "It will repeat in time.";
  }

  getRepeatFrequencyPhrase(level) {
    return REPEAT_FREQUENCIES[level] || 'in time';
  }
}

FtseMessaging.propTypes = PT;

export default FtseMessaging;
