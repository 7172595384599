  
// substrate and utils
import PropTypes                      from 'prop-types';
import React                          from 'react';
import NumberHelper                   from '_utils/NumberHelper';

// sub-components
import DashboardOptionsButton         from '_views/shared/DashboardOptionsButton';
import LabeledBackButton             from '_views/shared/LabeledBackButton';
import Spinner                        from '_views/shared/Spinner';

const PT = {      
  addClasses:                           PropTypes.string,
  currentUser:                          PropTypes.object,
  isLoadingUser:                        PropTypes.bool,
  onShowClassesRequest:                 PropTypes.func,
  userPacks:                            PropTypes.array,
};


class DashboardMobileTopbar extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const userPackCount = (this.props.userPacks?.length) ? this.props.userPacks.length : 0;
    const displayUserPackCount = '(' + NumberHelper.displayNumber(userPackCount) + ')' || 0;

    return (
      <div className="dashboard-mobile-topbar">

        <LabeledBackButton 
          label={'My Classes' + ' ' + displayUserPackCount}
          onClick={(e) => this.handleMobileHomeButtonClick(e)}
        />

        <div className="branding" onClick={(e) => this.handleMobileHomeButtonClick(e)}>
          <span className="dom-only">Brainscape</span>
        </div>

        {this.renderDashboardMobileOptionsButton()}
      </div>
    );
  }

  renderDashboardMobileOptionsButton() {
    if (this.props.isLoadingUserInfo) {
      return (
        <div className="options-button image">
          <Spinner />
        </div>
      );
    }

    return (
      <DashboardOptionsButton
        iconImageUrl={this.props.currentUser.profile.avatarUrl}
        iconType="image"
        isUserPro={this.props.currentUser.flags.isPro}
        openPosition="bottomLeft"
        shouldButtonTurn={false}
        userProfilePath={`/profiles/${this.props.currentUser.profileId}`}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleMobileClassCountClick(e) {
    if (e) {
      e.stopPropagation();
    }
    
    this.props.onShowClassesRequest();
  }

  handleMobileHomeButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }
    
    this.props.onShowClassesRequest();
  }
}

DashboardMobileTopbar.propTypes = PT;

export default DashboardMobileTopbar;
