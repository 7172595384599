
import EventManager             from '@brainscape/event-manager';
import ImageViewerController    from '_controllers/ImageViewerController';
import Modal                    from '_views/shared/Modal';
import PillButton               from '_views/shared/PillButton';
import SmartCardList            from '_views/shared/smart-cards/SmartCardList';
import PropTypes                from 'prop-types';
import React                    from 'react';
        
import {toClassStr}             from '_utils/UiHelper';


const PT = {
  cardLimitCount:         PropTypes.number,
  cardLimitPercentage:    PropTypes.number,
  cards:                  PropTypes.array,
  currentCardId:          PropTypes.node,
  currentUser:            PropTypes.object,
  deck:                   PropTypes.object,
  isOpen:                 PropTypes.bool,
  onCloseRequest:         PropTypes.func,
  onClosed:               PropTypes.func,
  pack:                   PropTypes.object, 
};


class DeckPreviewModal extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      haveCardsChanged: false,
      hasCardLimit:  true,
      isListBlurred: true,
      isProcessingEnrollmentRequest: false,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this.events.addListeners([
      ['card:updated',     this.handleCardUpdated],
      ['userCard:updated', this.handleUserCardUpdated],
    ]);

    this.setBlurEnforcement();
  };

  componentWillUnmount = () => this.events.disable();


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    let classes = toClassStr(['preview-card-list', (this.state.isListBlurred ? 'blur-list' : ''), this.props.addClasses]);

    return (
      <Modal
        addClasses='deck-preview-modal'
        isOpen={this.props.isOpen}
        onClosed={this.props.onClosed}
        onCloseRequest={this.handleCloseRequest}
        size="large"
      >
        <ImageViewerController />

        <div className="modal-title">
          {this.props.deck.name} Flashcards Preview
        </div>

        <div className={classes}>
          <div className="card-list-overlay">
            {this.renderPrivateListCta()}
          </div>

          {this.renderSmartCardList()}
          {this.renderContentTypeFlag()}
        </div>
      </Modal>
    );
  };

  renderPrivateListCta() {
    if (!(this.state.isListBlurred && this.props.pack.flags.isPrivate)) {
      return null;
    }

    return (
      <div className="private-product-action">
        <p className="private-product-notice">This is a private class.</p>
        {this.renderPrivateClassInstructions()}
        {this.renderBlurredListCtaButton()}
      </div>
    );
  }

  renderPrivateClassInstructions() {
    if (this.props.currentUser.flags.isPro) { 
      return null; 
    }

    const base   = '/pricing';
    const packId = this.props.pack.packId;

    return (
      <div className="private-class-instructions">
        <a href={`${base}?paywall=private_access&pack_id=${packId}`}>
          You need a Pro account to access a Private class
        </a>
        <a href={`${base}?paywall=private_access_code&pack_id=${packId}`}>
          I have an access code
        </a>
      </div>
    );
  }

  renderBlurredListCtaButton() {
    if (this.props.currentUser.flags.isPro) {
      return (
        <PillButton
          label="Study These Flashcards"
          onClick={this.handleEnrollmentRequest}
          isProcessing={this.state.isProcessingEnrollmentRequest}
        />
      );
    }

    return (
      <PillButton
        label="Upgrade To Pro"
        onClick={this.handleUpgradeRequest}
        isProcessing={this.state.isProcessingUpgradeRequest}
      />
    );
  }

  renderSmartCardList() {
    const cardLimitCount      = this.props.cardLimitCount;
    const cardLimitPercentage = this.props.cardLimitPercentage;
    const cards               = this.props.cards;
    const hasCardLimit        = this.state.hasCardLimit;
    const isListBlurred       = this.state.isListBlurred;

    let cardLimit = false;

    if (hasCardLimit) {
      cardLimit = Math.max(cardLimitCount, ((cardLimitPercentage/100) * cards.length), 0);
    }

    return (
      <div className='multi-cards'>
        <SmartCardList
          cardLimitCount={cardLimitCount}
          cardLimitPercentage={cardLimitPercentage}
          cards={this.props.cards}
          context="preview"
          currentCardId={this.props.currentCardId}
          currentUser={this.props.currentUser}
          deck={this.props.deck}
          format="md"
          hasCardLimit={hasCardLimit}
          isBlurred={isListBlurred}
          isPreviewModal={true}
          isShowingAdditionalFields={true}
          onViewLargeImageRequest={this.props.onViewLargeImageRequest}
          pack={this.props.pack}
          view={(this.props.isMobileViewportSize) ? 'mobile' : 'desktop'}
        />
      </div>
    );
  }

  renderContentTypeFlag() {
    if (!this.props.currentUser.flags.isBscAdmin) {
      return null;
    }

    return (
      <div className="content-type-flag">{this.props.deck.contentType}</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseRequest = () => {
    if (this.state.haveCardsChanged) {
      EventManager.emitEvent('refresh-pack-detail-section', {});
    }

    if (this.props.onCloseRequest) { this.props.onCloseRequest(); };
  };

  handleCardUpdated     = () => this.setState({haveCardsChanged: true});
  handleUserCardUpdated = () => this.setState({haveCardsChanged: true});

  handleEnrollmentRequest = () => {
    this.setState({isProcessingEnrollmentRequest: true});
    location.href = this.props.pack.paths.enrollmentPath;
  };

  handleUpgradeRequest = () => {
    this.setState({isProcessingUpgradeRequest: true});
    location.href = '/pricing/?paywall=private_access';
  };



  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  calcHasCardLimit = () => {
    const pack = this.props.pack;
    const deck = this.props.deck;
    const currentUser = this.props.currentUser;

    if (pack.permission == 'admin' || currentUser.flags.isPro) { 
      return false; 
    }

    return deck.flags.isLocked;
  }

  setBlurEnforcement() {
    const pack = this.props.pack;

    let shouldBlurListOnPrivacy = pack.flags.isPrivate && !this.props.currentUser.flags.isPro;

    this.setState({
      hasCardLimit:  this.calcHasCardLimit(),
      isListBlurred: shouldBlurListOnPrivacy,
    });
  }

}

DeckPreviewModal.propTypes = PT;

export default DeckPreviewModal;
