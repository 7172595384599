
import DynamicTooltipIcon             from '_views/shared/DynamicTooltipIcon';
import PillButton                     from '_views/shared/PillButton';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import SimpleTextButton               from '_views/shared/SimpleTextButton';
import TextField                      from '_views/shared/TextField';

import {toClassStr} from '_utils/UiHelper';

import {
  EditButton,
}                                     from '_views/shared/IconButton';

const PT = {
  addClasses                      : PropTypes.string,
  isPackMetadataEditable          : PropTypes.bool,
  isPreviewing                    : PropTypes.bool,
  isProcessing                    : PropTypes.bool,
  isEditingBscAdminFields         : PropTypes.bool,
  isEditingResource               : PropTypes.bool,
  metadata                        : PropTypes.object,
  onCancelBscAdminFieldsRequest   : PropTypes.func,
  onEditBscAdminFieldsRequest     : PropTypes.func,
  onUpdateBscAdminFieldsRequest   : PropTypes.func,
  pack                            : PropTypes.object,
  segmentId                       : PropTypes.node,         
};


class PackAboutBscAdminSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caption: "",
      hasInvalidInput: false,
      creatorId: "",
    }
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.setResources();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.metadata != this.props.metadata) {
      this.manageResources(prevProps);
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isEditingSegmentClass = this.props.isEditingBscAdminFields ? 'is-editing-segment' : '';
    const hasInvalidInputClass = this.state.hasInvalidInput ? 'has-invalid-input' : '';
    const classes = toClassStr(['segment about-tab-segment pack-about-bsc-admin-segment', isEditingSegmentClass, hasInvalidInputClass]);

    return (
      <div className={classes}>
        <div className="segment-anchor" id={this.props.segmentId}></div>
        {this.renderBscAdminHeader()}
        {/* {this.renderHeadlineText()} */}
        {this.renderCreatorIdTextField()}
      </div>
    );
  }

  renderBscAdminHeader() {
    return (
      <div className="segment-header about-tab-segment-header bsc-admin-header"> 
        <div className="segment-heading about-tab-segment-heading bsc-admin-heading">BSC Admin Fields</div>

        <div className="action-buttons">
          {this.renderBscAdminFieldsTooltip()}
          {this.renderEditBscAdminFieldsButton()}
        </div>

        {this.renderBscAdminFieldsEditActionButtons()}
      </div>
    );
  }

  renderBscAdminFieldsTooltip() {
    if (!this.props.isPackMetadataEditable || this.props.isPreviewing) {
      return null;
    }

    return (
      <DynamicTooltipIcon 
        actions={null}
        addClasses="bsc-admin-fields-tooltip"
        body="Edit data in these fields in administrative management of this class."
        hasDismissButton={true}
        heading={null}
        position="right"
        subHeading={null}
      />
    );
  }

  renderEditBscAdminFieldsButton() {
    if (!this.props.isPackMetadataEditable || this.props.isEditingResource || this.props.isPreviewing) {
      return null;
    }

    return (
      <EditButton 
        addClasses="edit-bsc-admin-fields-button"
        onClick={this.handleEditButtonClick}
      />
    );
  }

  renderBscAdminFieldsEditActionButtons() {
    if (!(this.props.isPackMetadataEditable && this.props.isEditingBscAdminFields)) {
      return null;
    }

    const saveButtonLabel = (this.props.isMobileViewportSize) ? 'Save' : 'Save changes';

    return (
      <div className="edit-action-buttons">

        <SimpleTextButton
          addClasses="cancel-button cancel-edit-bsc-admin-fields-button"
          label="Cancel"
          onClick={this.handleCancelButtonClick}
        />

        <PillButton
          addClasses="save-button save-bsc-admin-fields-changes-button"
          isProcessing={this.props.isProcessing}
          label={saveButtonLabel}
          onClick={this.handleSaveChangesButtonClick}
        />
      </div>
    );
  }  

  renderCreatorIdTextField = () => {
    return (
      <TextField 
        addClasses="creator-id-text-field"
        id="creatorId"
        isReadOnly={!this.props.isEditingBscAdminFields}
        label="Creator ID"
        onChange={this.handleCreatorIdFieldChange}
        placeholder={'Enter a valid Creator Id'}
        value={this.state.creatorId}
      />
    );

  }
      

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  handleEditButtonClick = () => {
    if (this.props.onEditBscAdminFieldsRequest) {
      this.props.onEditBscAdminFieldsRequest();
    } 
  }

  handleCancelButtonClick = () => {
    this.setResources();

    this.setState({
      caption: "",
      hasInvalidInput: false,
    });

    if (this.props.onCancelEditBscAdminFieldsRequest) {
      this.props.onCancelEditBscAdminFieldsRequest();
    }
  }

  handleCreatorIdFieldChange = (e) => {
    this.setState({
      hasInvalidInput: false,
      creatorId: e.target.value,
    })
  }

  handleSaveChangesButtonClick = () => {
    if (this.props.onUpdateBscAdminFieldsRequest) {
      this.props.onUpdateBscAdminFieldsRequest(this.state.creatorId);
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  hasResource = (resourceKey, testProps) => {
    const props = testProps || this.props;

    return !!(props.metadata && props.metadata.global && props.metadata.global[resourceKey] && props.metadata.global[resourceKey].value);
  }

  hasResourceChanged = (resourceKey, prevProps) => {
    if (!(this.hasResource(resourceKey, prevProps) && this.hasResource(resourceKey, this.props))) { 
      return false; 
    }

    const prevResourceValue = prevProps.metadata.global[resourceKey].value;
    const resourceValue = this.props.metadata.global[resourceKey].value;

    return (resourceValue != prevResourceValue);
  }

  manageResources = (prevProps) => {
    if (prevProps.pack.creatorId != this.props.pack.creatorId) {
      this.setCreatorId();
    }
  }

  setCreatorId = () => {
    this.setState({
      creatorId: this.props.pack.creatorId,
    })
  }

  setResources = () => {
    this.setCreatorId();
  }
}

PackAboutBscAdminSegment.propTypes = PT;

export default PackAboutBscAdminSegment;
