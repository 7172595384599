
import LoadingOverlay               from '_views/shared/LoadingOverlay';
import EventManager                 from '@brainscape/event-manager';
import PropTypes                    from 'prop-types';
import React                        from 'react';

import {toClassStr} from '_utils/UiHelper';

class DetailLoadingOverlayController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isDetailLoadingOverlayOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('detail-loading-overlay:open', this.handleOpenRequest)
    this.events.addListener('detail-loading-overlay:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    const classes = toClassStr(['detail-loading-overlay', viewProps.addClasses]);
    const message = viewProps.message || 'Loading Class Detail...';

    return (
      <LoadingOverlay
        addClasses={classes}
        isOpaque={viewProps.isOpaque}
        isOpen={this.state.isDetailLoadingOverlayOpen}
        message={message}
        onClosed={this.handleClosed}
        shouldTransitionIn={true}
        shouldTransitionOut={true}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes overlay closing animation. Overlay is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isDetailLoadingOverlayOpen: false,
    }, () => {
      this.publishDetailOverlayClosed();

      if (this.state.viewProps?.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after overlay close animation is complete. Removes overlay from DOM
  handleClosed = () => {
    this.setState({
      viewProps: null
    }, () => {
      if (this.state.viewProps?.onClosed) {
        this.state.viewProps.onClosed();
      }
    });
  }

  handleOpenRequest = (data) => {
    this.setState({
      isDetailLoadingOverlayOpen: true,
      viewProps: data,
    }, () => {
      this.publishDetailOverlayOpened();
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishDetailOverlayClosed() {
    EventManager.emitEvent('detail-overlay:closed', {});
  }

  publishDetailOverlayOpened() {
    EventManager.emitEvent('detail-overlay:opened', {});
  }
}

export default DetailLoadingOverlayController;
