
import Modal              from '_views/shared/Modal';
import PillButton         from '_views/shared/PillButton';
import React              from 'react';
import SimpleTextButton   from '_views/shared/SimpleTextButton';
import SubscriptionModel  from '_legacy-models/SubscriptionModel'

import {toClassStr} from '_utils/UiHelper';

class CancelSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phase: this.props.initialPhase || 1,
      isProcessingCancellation: false
    };

    /* 
      this.props: 
        addClasses,
        authenticityToken,
        initialPhase,
        onClose,
        onError,
        onSuccess,
        show (boolean),
        subscriptionBillingId
    */

    this.Subscription = new SubscriptionModel;
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    if (this.state.phase == 1) {
      return (
        this.renderPhase1Modal()
      );
    }

    if (this.state.phase == 2) {
      return (
        this.renderPhase2Modal()
      );
    }

    return (
      this.renderErrorModal()
    );
  }

  renderPhase1Modal() {
    const classes = toClassStr(['cancel-subscription-modal phase-1', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.show}
        onCloseRequest={this.handleCloseModal}
      >

        <div className="modal-title">Leaving so soon?</div>

        <div className="modal-message">Have you really accomplished your learning goals for now? You might want to <a href="/pricing">review all the great benefits</a> you get with Brainscape Pro before you sever ties.</div>
        <div className="modal-guidance">Note: Canceling may forfeit your access to your flashcards and your study data (or that of your students). You will also lose your ability to lock in these low prices before they go up.</div>

        <div className="modal-actions">
          <PillButton
            label="Do Not Cancel"
            onClick={this.handleCloseModal}
          />

          <SimpleTextButton
            addClasses="resolve-modal-text-button"
            isProcessing={this.state.isProcessingCancellation}
            label="Cancel Subscription"
            onClick={this.handleConfirmCancelClick}
            spinnerColor='blue'
          />

        </div>
      </Modal>
    );
  }

  renderPhase2Modal() {
    const classes = toClassStr(['cancel-subscription-modal phase-2', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.show}
        onCloseRequest={this.handleCloseModal}
      >

        <div className="modal-title">Sorry to see you go!</div>

        <div className="modal-message">Your Brainscape Pro subscription has been canceled.  We would really appreciate if you could fill out this <a href="https://docs.google.com/forms/d/e/1FAIpQLSeF7_xR8huiX6J7XDoqq9MaszOcO0bYWIinX7zm5TspKGAXFQ/viewform?vc=0&c=0&w=1" target="_blank" rel="nofollow noopener">quick 2-min survey</a> to help us better understand how we lost you.</div>
        <div className="modal-message">Thank you in advance for helping us improve Brainscape!</div>

        <div className="modal-actions">
          <PillButton
            addClasses="cancel-modal-button"
            label="Ok"
            onClick={this.handleCloseModal}
          />
        </div>
      </Modal>
    );
  }

  renderErrorModal() {
    const classes = toClassStr(['cancel-subscription-modal phase-3', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.show}
        onCloseRequest={this.handleCloseModal}
      >

        <div className="modal-title">Problem</div>

        <div className="modal-message">There was a problem encountered while processing your subscription cancellation.</div>
        <div className="modal-message">Please contact our customer service department at: <a href="mailto:support@brainscape.com">support@brainscape.com</a></div>
        <div className="modal-message"><a href="https://brainscape.zendesk.com/hc/en-us/articles/115003978012-How-do-I-cancel-my-Pro-subscription-and-any-future-billings" target="_blank" rel="nofollow noopener">Additional Information on Canceling</a></div>

        <div className="modal-actions">
          <PillButton
            addClasses="cancel-modal-button"
            label="Ok"
            onClick={this.handleCloseModal}
          />
        </div>
      </Modal>
    );
  }

  handleCloseModal = () => {
    this.props.onClose();
  }

  handleConfirmCancelClick = () => {
    this.setState({
      isProcessingCancellation: true
    });

    this.Subscription.delete({
      reqData: {
        authenticity_token: this.props.authenticityToken,
        billing_id: this.props.subscriptionBillingId
      },
      done: (data) => {
        this.setState({
          phase: 2,
          isProcessingCancellation: false
        });
        this.props.onSuccess();
      },
      fail: (message) => {
        console.log ('something went wrong in Subscription.delete. message:', message);
        this.setState({
          phase: 3,
          isProcessingCancellation: false
        });
        this.props.onError();
      }
    });
  }
}

export default CancelSubscriptionModal;
