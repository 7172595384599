
import EventManager       from '@brainscape/event-manager';
import OptionsMenuButton  from '_views/shared/OptionsMenuButton';
import PropTypes          from 'prop-types';
import React              from 'react';
import Tracker            from '_utils/Tracker';
import UiHelper           from '_utils/UiHelper';

import deckConfidence     from '_models/deckConfidence';
import deckDuplicate      from '_models/deckDuplicate';
import packDeck           from '_models/packDeck';

import {toClassStr}       from '_utils/UiHelper';

const PT = {
  addClasses:                   PropTypes.string,
  currentDeck:                  PropTypes.object,
  currentPack:                  PropTypes.object,
  currentUser:                  PropTypes.object,
  iconType:                     PropTypes.string,
  initialOptionsOpenState:      PropTypes.bool,
  isMobileViewportSize:         PropTypes.bool,
  tooltipContent:               PropTypes.string,
  tooltipPosition:              PropTypes.string,
};

const MENU_OPTIONS_HASH = {
  editDeckInfo:{id: 'editDeckInfo', tag: 'edit-deck-info', label: 'Edit Deck Info', onlyDeckEditor: true},
  previewDeck: {id: 'previewDeck', tag: 'preview-deck', label: 'Preview the Cards in this Deck', onlyDeckEditor: true, onlyOnMobileViewportSize: true},
  editDeck: {id: 'editDeck', tag: 'edit-deck', label: 'Edit the Cards in this Deck', onlyDeckEditor: true, onlyOnMobileViewportSize: true},
  browseDeck: {id: 'browseDeck', tag: 'browse-deck', label: 'Browse the Cards in this Deck', onlyDeckEditor: true, onlyOnMobileViewportSize: true},
  separator1: {id: '---', label: null, onlyDeckEditor: true},
  exportDeck: {id: 'exportDeck', tag: 'export-deck', label: 'Export Deck', onlyPackAdmin: true, onlyPro: false},
  duplicateDeck: {id: 'duplicateDeck', label: 'Duplicate Deck', onlyDuplicatable: true, onlyPro: true, onlyDeckEditor: true, paywall: 'duplicate_deck', tag: 'duplicate-deck', featuresList: 'list-5'},
  copyAndPlaceDeck: {id: 'copyAndPlaceDeck', label: 'Copy & Place Deck', onlyDuplicatable: true, onlyPro: true, paywall: 'copy_and_place_deck', tag: 'copy-and-place-deck', warning: true, featuresList: 'list-5'},
  resetStats: {id: 'resetStats', tag: 'reset-stats', label: 'Reset Deck Stats', onlyPro: true, paywall: 'stats_reset_deck', featuresList: 'list-2', onlyIfHasCards: true, onlyStudyable: true},
  separator2: {id: '---', label: null, onlyDeckEditor: true},
  removeDeck: {id: 'removeDeck', tag: 'remove-deck', label: 'Remove this Deck', onlyPackAdmin: true},
  manageDeckCategories: {id: 'manageDeckCategories', tag: 'manage-deck-categories', label: 'Manage Deck Categories', onlyBscAdmin: true},
};



class DeckOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingOptionAction: false,
      menuOptions: [],
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.updateUserOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser.flags.isPro != prevProps.currentUser.flags.isPro) {
      this.updateUserOptions();
    }

    if (this.props.currentPack.permission != prevProps.currentPack.permission) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['deck-options-button', this.props.addClasses]);
    const openPosition = 'bottomLeft';

    return (
      <div className={classes}>
        <OptionsMenuButton
          addClasses="no-option-icons"
          iconType={this.props.iconType}
          initialOpenState={this.props.initialOptionsOpenState}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.currentUser.flags.isPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition={openPosition}
          shouldButtonTurn={false}
          tooltipContent={this.props.tooltipContent}
          tooltipPosition={this.props.tooltipPosition}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick(optionId) {
    let requiresPro = MENU_OPTIONS_HASH[optionId].onlyPro;

    if (requiresPro && !this.props.currentUser.flags.isPro) {
      let desiredAction = MENU_OPTIONS_HASH[optionId].label;
      let paywall = MENU_OPTIONS_HASH[optionId].paywall;
      let featuresList = MENU_OPTIONS_HASH[optionId].featuresList;

      this.showUpgradeModal(desiredAction, paywall, featuresList);

    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleOptionActionRequest(optionId) {
    this.setState({
      isProcessingOptionAction: true,
    });

    switch (optionId) {
      case 'browseDeck':
        this.performBrowseDeckAction();
      break;
      case 'copyAndPlaceDeck':
        this.performCopyAndPlaceDeckAction();
      break;
      case 'duplicateDeck':
        this.performDuplicateDeckAction();
      break;
      case 'editDeckInfo':
        this.performEditDeckInfoAction();
      break;
      case 'editDeck':
        this.performEditDeckAction();
      break;
      case 'exportDeck':
        this.performExportDeckAction();
      break;
      case 'previewDeck':
        this.performPreviewDeckAction();
      break;
      case 'resetStats':
        this.performResetStatsAction();
      break;
      case 'removeDeck':
        this.performRemoveDeckAction();
      break;
      case 'manageDeckCategories':
        this.performManageDeckCategoriesAction();
      break;
      default:
        this.setState({
          isProcessingOptionAction: false,
        });
    }
  }

  performEditDeckInfoAction = () => {
    this.triggerEditDeckInfoModalOpen();

    this.setState({
      isProcessingOptionAction: false,
    });
  }

  performEditDeckAction = () => {
    this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId, null, 'edit');

    this.setState({
      isProcessingOptionAction: false,
    });
  }

  performPreviewDeckAction = () => {
    this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId, null, 'preview');

    this.setState({
      isProcessingOptionAction: false,
    });
  }

  performBrowseDeckAction = () => {
    this.triggerDeckDetailViewRequest(this.props.pack.packId, this.props.deck.deckId, null, 'browse');

    this.setState({
      isProcessingOptionAction: false,
    });
  }

  performExportDeckAction = () => {
    // trigger a server side action that returns a csv file and leaves the current page loaded in the browser
    const exportPath = `/decks/${this.props.currentDeck.deckId}.csv`;
    location.href = exportPath;

    this.triggerInfoModalOpen({
      title: 'Deck Exported',
      message: "Check your browser's downloads folder for a CSV of your deck",
      resolveButtonText: 'Got it',
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performCopyAndPlaceDeckAction = () => {
    this.triggerCopyAndPlaceDeckModalSetOpen({
      currentUser: this.props.currentUser,
      deckId: this.props.currentDeck.deckId,
      packId: this.props.currentPack.packId,
    });

    this.setState({
      isProcessingOptionAction: false,
    });
  }

  performDuplicateDeckAction() {
    deckDuplicate.create(this.props.currentPack.packId, this.props.currentDeck.deckId).then(() => {
      this.triggerInfoModalOpen({
        title: 'Deck Duplication Started',
        message: "We're creating a copy of this deck and all of its cards. This will appear as a new deck in your class. (This may take a few minutes. Feel free to refresh page to see if it's done.)",
        resolveButtonText: 'Got it',
        onCloseRequest: () => {
          this.setState({
            isProcessingOptionAction: false,
          });
        }
      });
    });
  }

  performResetStatsAction() {
    this.triggerConfirmModalOpen({
      actionText: 'Reset Deck Stats? This action will reset your confidences for each card in the deck and cannot be undone',
      resolveButtonText: 'Yes, Reset Stats',
      onResolution: () => {
        deckConfidence.reset(this.props.currentPack.packId, this.props.currentDeck.deckId).then(() => {
          this.setState({
            isProcessingOptionAction: false,
          });
        });
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performRemoveDeckAction = () => {
    const actionText = `remove the ${this.props.currentDeck.name} deck from your class`;

    this.triggerConfirmModalOpen({
      actionText: actionText,
      resolveButtonText: 'Yes, remove deck',
      onResolution: () => {
        packDeck.destroy(this.props.currentPack.packId, this.props.currentDeck.deckId);

        this.setState({
          isProcessingOptionAction: false,
        });
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performManageDeckCategoriesAction = () => {
    this.triggerManageDeckCategoriesModalOpen();

    this.setState({
      isProcessingOptionAction: false,
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCopyAndPlaceDeckModalSetOpen(viewProps) {
    EventManager.emitEvent('copy-and-place-deck-modal-set:open', viewProps);
  }

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerManageDeckCategoriesModalOpen() {
    EventManager.emitEvent('manage-deck-categories-modal:open', {
      deck: this.props.currentDeck,
      packId: this.props.currentPack.packId,
    });
  }

  triggerDeckDetailViewRequest = (packId, deckId, cardId=null, tabId='preview') => {
    EventManager.emitEvent('deck-detail-view:change-request', {
      cardId: cardId,
      deckId: deckId,
      packId: packId,
      tabId: tabId,
    });
  }

  triggerEditDeckInfoModalOpen = () => {
    EventManager.emitEvent('edit-deck-info-modal:open', {
      currentUser: this.props.currentUser,
      deck: this.props.currentDeck,
      packId: this.props.currentPack.packId,
    });
  };

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerUpgradeModalOpen(desiredAction, paywall, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      featuresList:  featuresList,
      paywall:       paywall,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  showUpgradeModal(desiredAction, paywall, featuresList) {
    if (!paywall) {
      if (desiredAction == 'Export CSV') { paywall = 'csv_export_pack'; }
    }
    Tracker.trackPaywallWarning(paywall);
    this.triggerUpgradeModalOpen(desiredAction, paywall, featuresList);
  }

  updateUserOptions() {
    const currentUser = this.props.currentUser;
    const currentPack = this.props.currentPack;
    const currentDeck = this.props.currentDeck;

    let menuOptionsForUser = Object.keys(MENU_OPTIONS_HASH).reduce((accumulator, menuOption) => {
      let permitPerEditDeckRights = (MENU_OPTIONS_HASH[menuOption].onlyDeckEditor) ? currentDeck.flags.isEditable : true;
      let permitPerDuplicateRights = (MENU_OPTIONS_HASH[menuOption].onlyDuplicatable) ? ((currentPack.flags.isPrivate) ? currentPack.permission == 'admin' : currentDeck.flags.isDuplicatable) : true;
      let permitPerCardsExistence = (MENU_OPTIONS_HASH[menuOption].onlyIfHasCards) ? currentDeck.stats.cardCount > 0 : true;
      let permitPerBscAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyBscAdmin) ? currentUser.flags.isBscAdmin : true;
      let permitPerPackAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyPackAdmin) ? currentPack.permission == 'admin' : true;
      let permitPerPackOrBscAdminRights = (MENU_OPTIONS_HASH[menuOption].onlyPackOrBscAdmin) ? (currentPack.permission == 'admin' || currentUser.flags.isBscAdmin) : true;

      let renderDeckActionButtons = (MENU_OPTIONS_HASH[menuOption].onlyOnMobileViewportSize) ? this.props.isMobileViewportSize : true;

      if (permitPerEditDeckRights && permitPerBscAdminRights && permitPerPackAdminRights && permitPerPackOrBscAdminRights && permitPerCardsExistence && permitPerDuplicateRights && renderDeckActionButtons) {
        accumulator.push(MENU_OPTIONS_HASH[menuOption]);
      }
      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

DeckOptionsButton.propTypes = PT;

export default DeckOptionsButton;
