
import { AddButtonCircled }         from '_views/shared/IconButton';
import CategoryModel                from '_legacy-models/CategoryModel';
import PropTypes                    from 'prop-types';
import React                        from 'react';
import UiHelper                     from '_utils/UiHelper';

import { toClassStr }               from '_utils/UiHelper';

const PT = {
  authenticityToken:      PropTypes.string,
  profileUser:            PropTypes.object,
  subject:                PropTypes.object,
};

class PackTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.Category = new CategoryModel();
    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let packIconUrl = this.props.subject.activeIconUrl || 'assets/app_icons/ugs.png';
    packIconUrl = (packIconUrl.indexOf('assets') == 0) ? '/' + packIconUrl : packIconUrl;

    return (
      <li className='pack-item' key={this.props.subject.id}>
        <a href={this.props.subject.subjectLink} className='pack-link'>
          <div className='pack-tile'>
            <div className='tile-icon'>
              <img src={packIconUrl} className='tile-icon-img' alt={this.props.subject.name} />
              {this.renderAddPackButton(this.props.subject.subjectLink, this.props.subject.sourceId || this.props.subject.packId)}
            </div>
            <div className='tile-metadata'>
              <h4 className='subject-name'>
                <span className={this.props.subject.isCertified ? 'isCertified' : ''}></span>
                {this.props.subject.name}
              </h4>
              <div className='subject-stats'>
                <div className='stat subject-decks'>{this.props.subject.deckCount} decks</div>
                <div className='stat subject-cards'>{this.props.subject.cardCount} cards</div>
              </div>
            </div>
          </div>
        </a>
      </li>
    )
  }

  renderAddPackButton(link, packId) {
    const currentUser = this.props.currentUserId;
    const isCurrentUserSubscribedToPack = link.includes('/l/study?') ? true : false;

    if (!currentUser) {
      return null;
    }

    if (this.props.currentUserId === this.props.profileUser.profileId) {
      return null;
    }

    if (isCurrentUserSubscribedToPack) {
      return null;
    }

    if (this.props.subject.isCertified) {
      return (
        <AddButtonCircled
          addClasses="add-class-button"
          onClick={e => this.handleAddCertifiedPackButtonClick(e, packId)}
        />
      );
    }

    return (
      <AddButtonCircled
        addClasses="add-class-button"
        onClick={e => this.handleAddUgcPackButtonClick(e, packId)}
      />
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddCertifiedPackButtonClick = (e, packId) => {
    e.preventDefault();
    this.addCertifiedPackToLibrary(packId);
  }

  handleAddUgcPackButtonClick = (e, packId) => {
    e.preventDefault();
    this.addUgcPackToLibrary(packId);
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  addCertifiedPackToLibrary = (packId) => {
    this.Category.enroll({
      authenticity_token: this.props.authenticityToken,
      id: packId,
      done: (data) => {
        if (data.packId) {
          UiHelper.navigate('/l/dashboard');
        } else {
          console.log('Something went wrong in add pack to library');
        }
      },
      fail: (jqXHR, status) => {
        console.log(
          'Something went wrong in add pack to library. jqXHR, status:',
          jqXHR,
          status,
        );
      },
    });
  };

  addUgcPackToLibrary = (packId) => {
    UiHelper.navigate(`/packs/${packId}/subscribe`);
  }

}

PackTile.propTypes = PT;

export default PackTile;
