
import DeckPreviewModal from '_views/modals/DeckPreviewModal';
import EventManager     from '@brainscape/event-manager';
import PropTypes        from 'prop-types';
import React            from 'react';

import deckCard         from '_models/deckCard';

const PT = {
  currentUser: PropTypes.object,
};


class DeckPreviewModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      cards: [],
      currentCardId: null,
      isDeckPreviewModalOpen: false, 
      modalProps: null
    };
    
    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this.events.addListeners([
      ['card:updated',             this.handleCardUpdated],
      ['card-confidence:updated',  this.handleCardConfidenceUpdated],
      ['current-card:change-request',  this.handleCurrentCardChangeRequest],
      ['deck-preview-modal:close', this.handleCloseRequest],
      ['deck-preview-modal:open',  this.handleOpenRequest],
    ]);
  };

  componentWillUnmount = () => this.events.disable();


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const modalProps = this.state.modalProps;

    if (!(modalProps && this.state.cards?.length > 0)) { 
      return null; 
    }

    const currentUser = this.props.currentUser;
    const currentUserFlags = currentUser?.flags || null;
    const currentUserFeatures = currentUserFlags?.features || null;

    return (
      <DeckPreviewModal
        cardLimitCount={15}
        cardLimitPercentage={40}
        cards={this.state.cards}
        currentCardId={this.state.currentCardId}
        currentUser={currentUser}
        deck={modalProps.deck}
        isOpen={this.state.isDeckPreviewModalOpen}
        onCloseRequest={this.handleCloseRequest}
        onClosed={this.handleModalClosed}
        pack={modalProps.pack}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCardConfidenceUpdated = (eventData) => {
    const updatedCard = this.getCard(eventData.cardId);
    updatedCard.stats.level = eventData.level;

    this.setCard(eventData.cardId, updatedCard);
  }

  handleCardUpdated = (eventData) => {
    const updatedCard = eventData.card;
    this.setCard(updatedCard.cardId, updatedCard);
  }

  handleCurrentCardChangeRequest = (eventData) => {
    this.setState({
      currentCardId: eventData.cardId,
    });
  }

  handleOpenRequest = (eventData) => {
    const pack = eventData.pack;
    const deck = eventData.deck;

    deckCard.index(pack.packId, deck.deckId).then(res => {
      this.setState({
        cards: res.cards,
        isDeckPreviewModalOpen: true, 
        modalProps: eventData,
      }, () => {
        if (this.state.modalProps.onOpened) {
          this.state.modalProps.onOpened();
        }
      });
    });
  };

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    const cb = this.state.modalProps && this.state.modalProps.onCloseRequest;
    this.setState({isDeckPreviewModalOpen: false}, cb);
  };

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.modalProps.onClosed;
    this.setState({modalProps: null}, onClosed);
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getCard = (cardId) => {
    const cards = [...this.state.cards];
    const result = cards.find(card => card.cardId == cardId);
    return result || null;
  };

  setCard = (cardId, card) => {
    const cards = [...this.state.cards];
    const cardIndex = cards.findIndex(card => card.cardId == cardId);

    cards[cardIndex] = card;

    this.setState({
      cards: cards,
    });
  };
}


DeckPreviewModalController.propTypes = PT;

export default DeckPreviewModalController;
