
export const quotesArray = [
  'Feel free to grab a snack',
  'Get ready to Brainscape & Chill',
  "It's worth the wait, trust me",
  'The waiting makes it sweeter',
  'Give us a sec 🙏',
  'Your patience will be rewarded',
  'Enjoy the suspense',
  'Waiting is a sign of true love',
  'Just count backwards from 10 in Klingon',
  'Downloading awesomeness',
  'Please wait',
  'Waiting builds character',
  "One sec. Rome wasn't built in a day.",
  'Time flies when you\'re having fun',
  'The anticipation is killing me too!',
  '♫ The waiting is the hardest part ♫',
  'Almost there. Time heals all downloads',
  'Good things come to those who wait.',
  "Patience is a virtue, and you're nailing it!",
  'Taking a moment to make it perfect.',
  'This wait will be worth it!',
  'Your awesomeness is loading...',
  'Hold on magic is happening.',
  'A little patience goes a long way.',
  'Brewing something special for you.',
  'Thank you for your patience!',
  'Loading coolness. Just a moment.',
  'Waiting makes it even better.',
  "We're almost there, thanks for waiting!",
  "Hang tight, our gremlins are workin' on it!",
  'Give it a moment, it\'s doing a yoga stretch',
  'Patience, young padawan.',
  'Chill, our chakras are aligning.',
  'Wait a sec, we\'re ironing out the pixels',
  'Sing it with me: "Sweet Caroline DA-DA-DAH!"',
  'Baby shark doo-doo-do-do!',
  'Go grab a glass of water while you wait!',
  'How\'s the weather in your town today?',
  'Do 10 push-ups while you wait. I dare you!',
  'Text someone you care about while you wait',
  'So... how\'s this weather we\'ve been having?',
  'I love your hair! What shampoo do you use?',
  'So, you come here often?',
  'Even instant noodles take two minutes',
  'Your next "Aha!" moment is loading',
  'Downloading genius vibes',
  'Have you stretched today?',
  'Blink a few times to relax your eyes',
  'Take a deep breath while you wait',
  'Take a moment to feel gratitude',
  'Are you sitting up straight?',
  'Do some quick hand stretches while you wait',
  'Don\'t forget to smile today!'
];
