
const PersonHelper = {

  getCreatorName(creatorFullName) {
    const parts = creatorFullName.split(' ');
    const firstName = parts[0];

    if (parts.length > 1) {
      const lastName = parts[parts.length - 1];

      return `${firstName} ${lastName.charAt(0)}.`;
    }

    return firstName;
  },

  getProfileName(profileUser, currentUserPackPermission) {
    if (profileUser?.flags?.isCurrentUser) {
      return `${profileUser.fullName} (You)`;
    }

    if (currentUserPackPermission == 'admin') {
      return profileUser.fullName;
    }

    if (profileUser.lastName) {
      return `${profileUser.firstName} ${profileUser.lastName.charAt(0)}.`;
    }

    return profileUser.firstName;
  },

  getInitials(fullName) {
    let initials = '';

    fullName.split(' ').forEach(name => {
      initials = initials + name.charAt(0);
    });

    return initials.slice(0, 3);

  },
};

export default PersonHelper;
