/* 
  NOTE: DEPRECATED. Individual Models are being refactored to unbind them from the
  deprecated Model.js class.
  
  When this refactoring work is complete, this file can be deleted.
*/

import LegacyApiService from '../core/LegacyApiService';

class Model extends LegacyApiService {

  constructor() {
    super();

    this.modelName;
    this.memberName;
    this.memberTag;
    this.memberKey;
    this.collectionName;
    this.collectionTag;
    this.collectionKey;
    this.resourcePath;
    this.resourceRoute;
    this.schema;
    this.nameKey;
  }

  generateNomenclature() {
    this.modelName = this.pascalize(this.memberName);
    this.memberTag = this.memberName.split(" ").join("-").toLowerCase();
    this.memberKey = this.camelize(this.memberName);
    this.collectionName = this.memberName + 's';
    this.collectionTag = this.collectionName.split(" ").join("-").toLowerCase();
    this.collectionKey = this.camelize(this.collectionName);
    this.resourcePath = '/' + this.collectionTag;
    this.resourceRoute = '/api' + this.resourcePath;
  }

  camelize(str) { // from stack exchange (https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case)
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
      return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
    }).replace(/\s+/g, '');
  }

  pascalize(str) {
    let camelizedStr = this.camelize(str);
    return camelizedStr.charAt(0).toUpperCase() + camelizedStr.slice(1);
  }

  getConfig() {
    return {
      modelName: this.modelName,
      memberName: this.memberName,
      memberTag: this.memberTag,
      memberKey: this.memberKey,
      collectionName: this.collectionName,
      collectionTag: this.collectionTag,
      collectionKey: this.collectionKey,
      resourcePath: this.resourcePath,
      resourceRoute: this.resourceRoute,
      nameKey: this.nameKey
    };
  }

  getSchema(filter) {
    let fullSchema = {...this.schema};
    let schema = {};

    switch (filter) {
      case 'admin':
        return fullSchema;
      break;

      case 'onlyAdmin':
        Object.keys(fullSchema).forEach((field, index) => {
          if (fullSchema[field].onlyAdmin) {
            schema[field] = fullSchema[field];
          }
        });
        return schema;
      break;

      default:
        Object.keys(fullSchema).forEach((field, index) => {
          if (!fullSchema[field].onlyAdmin) {
            schema[field] = fullSchema[field];
          }
        });
        return schema;
      break;
    }
  }

  getResourceRoute() {
    return this.resourceRoute;
  }

  getNameKey() {
    return this.nameKey;
  }

  create(opts) {
    let serverData = {};
    serverData[this.memberKey] = this.translateToServerKeys(opts.reqData);
    opts.reqData = serverData;
    super.create(opts);
  }

  update(opts) {
    let serverData = {};
    serverData[this.memberKey] = this.translateToServerKeys(opts.reqData);
    opts.reqData = serverData;
    super.update(opts);
  }

  translateToServerKeys(data) {
    // NOTE: a "translateToClientKeys" for show and index is not needed b/c the API returns data using camelCase keys, same as the Client side javascript. However snake_case is still required for the update and create actions :-(
    // TODO: remove need for this by performing these translations server side
    let translatedData = {};

    Object.keys(data).forEach((clientKey, index) => {
      let clientSchema  = this.schema[clientKey];
      let serverKey     = clientSchema ? clientSchema.serverKey : null;

      if (serverKey) { translatedData[serverKey] = data[clientKey]; }
    });

    return translatedData;
  }

  getOptions(opts, valueKey = 'id', labelKey = this.nameKey) {
    this.index({
      done: (collection) => {
        let options = collection.map((member, index) => {

          return ({
            value: member[valueKey],
            label: member[labelKey]
          });
        });

        opts.done(options);
      },
      fail: (jqXhr, message) => {
        opts.fail(jqXhr, message);
      }
    })
  }

  validate(inputData) {
    if (Object.keys(this.schema).length < 1) {
      return {
        isValid: true,
        invalidFields: [],
        message: "valid " + this.memberName + " input data",
        validData: inputData
      };
    }

    let schema = this.schema;
    let isValid = true;
    let invalidFields = [];
    let message = "";

    Object.keys(schema).forEach((key) => {

      if (schema[key].isRequired && (!inputData[key] || inputData[key] === "")) {
        isValid = false;
        invalidFields.push(key);
        message += "A value for " + this.schema[key].label + " is required. "
      }
    });

    return {
      isValid: isValid,
      invalidFields: invalidFields,
      message: (isValid) ? "valid " + this.memberName + " input data" : message,
      validData: (isValid) ? inputData : null
    }
  }

  tryParseJSON(jsonString) {
    // from stack overflow (https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try)

    try {
      var o = JSON.parse(jsonString.replace(/\n/g,""));
      if (o && typeof o === "object") {
        return o;
      }
    }
    catch (e) {}

    return false;
  };
}

export default Model;
