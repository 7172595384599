
import EventManager           from '@brainscape/event-manager';
import ImageViewer            from '_views/shared/ImageViewer';
import React                  from 'react';

class ImageViewerController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false, 
      viewProps: null
    };

    this.events = new EventManager();
  }

  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('image-viewer:open',  this.handleOpenRequest);
    this.events.addListener('image-viewer:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const viewProps = this.state.viewProps;

    if (!viewProps) { 
      return null; 
    }
    
    return (
      <ImageViewer
        isOpen={this.state.isModalOpen}
        url={viewProps.url}
        onClose={this.handleClosed}
        shouldTransitionIn={true}
        shouldTransitionOut={true}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOpenRequest = (data) => {
    this.setState({
      isModalOpen: true, 
      viewProps: data,
    });
  }

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }
}

export default ImageViewerController;
