
import React          from 'react';
import UrlHelper      from '_utils/UrlHelper'
import {toClassStr}   from '_utils/UiHelper';


class VideoEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    /*
      this.props:
        addClasses,
        shouldAutoPlay,
        videoUrl
    */
  }

  render() {
    if (!this.props.videoUrl) {
      return null;
    }
    
    const classes = toClassStr(['video-embed', this.props.addClasses]);

    let autoPlayProps = {};
    let videoUrl = UrlHelper.appendQueryParam(this.props.videoUrl, 'rel', 0)

    if (this.props.shouldAutoPlay) {
      autoPlayProps.allow = "autoplay; encrypted-media";
      videoUrl = UrlHelper.appendQueryParam(videoUrl, 'autoplay', 1);
    }

    return (
      <div className={classes}>
        <iframe className="video-iframe" width="100%" height="100%" src={videoUrl} frameBorder="0" allowFullScreen {...autoPlayProps}></iframe>
      </div>
    );
  }
};

export default VideoEmbed;
