
import model from '_core/newModel';

const studyDeckSeed = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  show(packId, deckId) {
    const path = `study/packs/${packId}/decks/${deckId}/seeds`;
    return model.show(path);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default studyDeckSeed;
