
import CopyAndPlaceDeckModalSet     from '_views/modals/CopyAndPlaceDeckModalSet';
import EventManager                 from '@brainscape/event-manager';
import PillButton                   from '_views/shared/PillButton';
import PropTypes                    from 'prop-types';
import Modal                        from '_views/shared/Modal';
import React                        from 'react';

import deckPlacement                from '_models/deckPlacement';

const PT = {  
  currentUserPacks:       PropTypes.array,
};


class CopyAndPlaceDeckModalSetController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isCopyAndPlaceDeckModalOpen: false,
      isConfirmationModalOpen: false,
      userEditablePacks: [],
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('copy-and-place-deck-modal-set:open', this.setupModalData);
    this.events.addListener('copy-and-place-deck-modal-set:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    return (
      <div className="copy-and-place-deck-modal-set">
        {this.renderCopyAndPlaceDeckModal()}
        {this.renderConfirmationModal()}
      </div>
    );
  }

  renderCopyAndPlaceDeckModal() {
    const viewProps = this.state.viewProps;

    if (!this.state.isCopyAndPlaceDeckModalOpen) {
      return null;
    }

    return (
      <CopyAndPlaceDeckModalSet
        addClasses={viewProps.addClasses}
        deckId={viewProps.deckId}
        packId={viewProps.packId}
        isOpen={this.state.isCopyAndPlaceDeckModalOpen}
        onCloseRequest={this.handleCloseRequest}
        onClosed={this.handleClosed}
        onSubmit={this.handleSubmit}
        userEditablePacks={this.state.userEditablePacks}
      />
    );
  }

  renderConfirmationModal() {
    if (!this.state.isConfirmationModalOpen) {
      return null;
    }

    return (
      <Modal
        addClasses="copy-deck-confirmation-modal"
        isOpen={this.state.isConfirmationModalOpen}
        onCloseRequest={this.handleCloseConfirmationModalRequest}
      >

        <div className="modal-title">Deck Copy and Placement Started</div>

        <div className="modal-message">This operation can take a while. We will send you an e-mail to notify you when the process is complete. Feel free to refresh this page to check as well.</div>

        <div className="modal-actions">

          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={this.handleCloseConfirmationModalRequest}
          />
        </div>
      </Modal>
    );
  }



  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseConfirmationModalRequest = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  }

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isCopyAndPlaceDeckModalOpen: false,
    }, () => {
      if (this.state.viewProps?.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  handleSubmit = (destinationPackId) => {
    const viewProps = this.state.viewProps;
    const sourcePackId = viewProps.packId;
    const deckId = viewProps.deckId;

    deckPlacement.create(sourcePackId, deckId, destinationPackId).then(() => {
      this.setState({
        isCopyAndPlaceDeckModalOpen: false,
        isConfirmationModalOpen: true,
      });
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  isUserPackEditable = (permission) => {
    return (['admin', 'edit'].indexOf(permission) != -1);
  }

  setupModalData = (viewProps) => {
    const userEditablePacks = [];

    this.props.currentUserPacks.forEach(userPack => {
      if (this.isUserPackEditable(userPack.permission)) {
        userEditablePacks.push({
          id: userPack.packId, 
          label: userPack.name,
        });
      }
    });

    this.setState({
      isCopyAndPlaceDeckModalOpen: true,
      userEditablePacks: userEditablePacks,
      viewProps: viewProps,
    });
  }
}

CopyAndPlaceDeckModalSetController.propTypes = PT;

export default CopyAndPlaceDeckModalSetController;
