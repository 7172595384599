
import EventManager           from '@brainscape/event-manager';
import PaywallModal           from '_views/modals/paywall/PaywallModal';
import React                  from 'react';
import StringHelper           from '_utils/StringHelper';
import Tracker                from '_utils/Tracker';


/**
 * This makes it easy to open upgrade dialogs from anywhere.  Just make
 * sure that this controller is rendered in the highest level component.
 */
class UpgradeModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {isModalOpen: false, viewProps: null};
    this.events = new EventManager();
  }

  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('upgrade-modal:open',  this.handleOpenRequest);
    this.events.addListener('upgrade-modal:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const viewProps = this.state.viewProps;

    if (!viewProps) { 
      return null; 
    }
    
    return (
      <PaywallModal
        desiredAction={viewProps.desiredAction}
        isOpen={this.state.isModalOpen}
        onCloseRequest={this.handleCloseRequest}
        paywall={viewProps.paywall}
        featuresList={viewProps.featuresList}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOpenRequest = (data) => {
    this.setState({
      isModalOpen: true, 
      viewProps: data,
    });
  };

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    const vp = this.state.viewProps;
    const cb = vp && vp.onCloseRequest ? vp.onCloseRequest : null;

    this.setState({isModalOpen: false}, cb);
  };
}

export default UpgradeModalController;
