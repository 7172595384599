
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import SharePackByEmailModal     from '_views/modals/SharePackByEmailModal';
import SharePackByLinkModal      from '_views/modals/SharePackByLinkModal';
import TextField                 from '_views/shared/TextField';

import {toClassStr} from '_utils/UiHelper';

class SharePackModalSet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isIntroModalOpen          : this.props.phase === 'email' ? false : true,
      isLinkModalOpen           : false,
      isEmailModalOpen          : this.props.phase === 'email' || false,
      isEmailConfirmModalOpen   : false,
      phase                     : this.props.phase || 'intro', // intro, link, email, emailConfirm
      postModalCallback         : () => {},
      shareMethod               : 'link', // link or email
    };

    /*
      this.props:
        addClasses,
        currentUser,
        hasBackOption,
        isModalSetOpen,
        onModalSetClosed,
        pack,
        phase,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const phaseClass = this.state.phase + '-phase';
    const classes = toClassStr(['share-pack-modal-set', phaseClass]);

    return (
      <div className={classes}>
        {this.renderIntroModal()}
        {this.renderLinkModal()}
        {this.renderEmailModal()}
        {this.renderEmailConfirmModal()}
      </div>
    );
  }

  renderIntroModal() {
    return (
      <Modal
        addClasses="share-intro-modal"
        isOpen={this.state.isIntroModalOpen}
        onClosed={() => this.state.postModalCallback()}
        onCloseRequest={() => this.handleIntroModalCloseRequest()}
      >

        <div className="modal-title">Share Class</div>

        <div className="modal-guidance">How do you want to share this class? Choose a method.</div>

        <div className="share-methods">
          {this.renderLinkShareMethod()}
          {this.renderEmailShareMethod()}
        </div>

        <div className="modal-aside">*If a user simply "discovers" your class on the market, rather than visiting your free link, they will need a Brainscape Pro account to study it.</div>

        <div className="modal-actions">

          <div className="cancel-modal-text-button" onClick={(e) => this.handleIntroCancelButtonClick(e)}>Cancel</div>

          <PillButton
            addClasses="resolve-modal-button forward"
            label="Continue"
            onClick={() => this.handleIntroContinueButtonClick()}
          />
        </div>
      </Modal>
    );
  }

  renderLinkShareMethod() {
    const isSelectedClass = (this.state.shareMethod == "link") ? 'is-selected' : '';
    const classes = toClassStr(['share-method link', isSelectedClass]);

    return (
      <div className={classes} onClick={(e) => this.handleLinkShareMethodClick(e)}>
        <div className="share-method-title">Generate a link</div>
        <div className="share-method-caption">Easy, share anywhere</div>
      </div>
    );
  }

  renderEmailShareMethod() {
    const isSelectedClass = (this.state.shareMethod == "email") ? 'is-selected' : '';
    const classes = toClassStr(['share-method email', isSelectedClass]);

    return (
      <div className={classes} onClick={(e) => this.handleEmailShareMethodClick(e)}>
      <div className="share-method-title">Share by e-mail address</div>
      <div className="share-method-caption">Powerful, customize permissions</div>
      </div>
    );
  }

  renderLinkModal() {
    return (
      <SharePackByLinkModal
        currentUser={this.props.currentUser}
        isOpen={this.state.isLinkModalOpen}
        onBackRequest={() => this.handleLinkModalBackRequest()}
        onClosed={() => this.state.postModalCallback()}
        onCloseRequest={() => this.handleLinkModalCloseRequest()}
        pack={this.props.pack}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  }

  renderEmailModal() {
    return (
      <SharePackByEmailModal
        currentUser={this.props.currentUser}
        hasBackOption={this.props.hasBackOption}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isOpen={this.state.isEmailModalOpen}
        onBackRequest={() => this.handleEmailModalBackRequest()}
        onClosed={() => this.state.postModalCallback()}
        onCloseRequest={() => this.handleEmailModalCloseRequest()}
        onEmailSent={() => this.handleEmailSent()}
        pack={this.props.pack}
      />
    );
  }

  renderEmailConfirmModal() {
    return (
      <Modal
        addClasses="email-confirm-modal"
        isOpen={this.state.isEmailConfirmModalOpen}
        onClosed={() => this.state.postModalCallback()}
        onCloseRequest={() => this.handleEmailConfirmModalCloseRequest()}
      >

        <div className="modal-title">Share Email Sent</div>

        <div className="modal-message">Tip: Be sure your recipient checks their Spam folder and marks emails from Brainscape as Not Spam.</div>

        <div className="modal-actions">

          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={() => this.handleEmailConfirmModalCloseRequest()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleEmailShareMethodClick(e) {
    e.stopPropagation();

    if (this.state.shareMethod == 'email') {
      this.setState({
        isIntroModalOpen: false,
        postModalCallback: () => {
          this.invokeEmailPhase();
        }
      });
    } else {
      this.setState({
        shareMethod: 'email',
      });
    }
  }

  handleEmailConfirmModalCloseRequest() {
    this.setState({
      isEmailConfirmModalOpen: false,
      postModalCallback: () => {
        this.props.onModalSetClosed();
      }
    });
  }

  handleEmailModalCloseRequest() {
    this.setState({
      isEmailModalOpen: false,
      postModalCallback: () => {
        this.props.onModalSetClosed();
      }
    });
  }

  handleEmailModalBackRequest() {
    this.setState({
      isEmailModalOpen: false,
      postModalCallback: () => {
        this.invokeIntroPhase();
      }
    });
  }

  handleEmailSent() {
    this.setState({
      isEmailModalOpen: false,
      postModalCallback: () => {
        this.invokeEmailConfirmPhase();
      }
    });
  }

  handleIntroContinueButtonClick() {
    this.setState({
      isIntroModalOpen: false,
      postModalCallback: () => {
        if (this.state.shareMethod == 'link') {
          this.invokeLinkPhase();
        } else {
          this.invokeEmailPhase();
        }
      }
    });
  }

  handleIntroCancelButtonClick(e) {
    e.stopPropagation();
    this.handleIntroModalCloseRequest();
  }

  handleIntroModalCloseRequest() {
    this.setState({
      isIntroModalOpen: false,
      postModalCallback: () => {
        this.props.onModalSetClosed();
      }
    });
  }

  handleLinkShareMethodClick(e) {
    e.stopPropagation();

    if (this.state.shareMethod == 'link') {
      this.setState({
        isIntroModalOpen: false,
        postModalCallback: () => {
          this.invokeLinkPhase();
        }
      });
    } else {
      this.setState({
        shareMethod: 'link',
      });
    }
  }

  handleLinkModalCloseRequest() {
    this.setState({
      isLinkModalOpen: false,
      postModalCallback: () => {
        this.props.onModalSetClosed();
      }
    });
  }

  handleLinkModalBackRequest() {
    this.setState({
      isLinkModalOpen: false,
      postModalCallback: () => {
        this.invokeIntroPhase();
      }
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  invokeIntroPhase() {
    this.setState({
      isIntroModalOpen          : true,
      isLinkModalOpen           : false,
      isEmailModalOpen          : false,
      isEmailConfirmModalOpen   : false,
      phase                     : 'intro',
    });
  }

  invokeLinkPhase() {
    this.setState({
      isIntroModalOpen          : false,
      isLinkModalOpen           : true,
      isEmailModalOpen          : false,
      isEmailConfirmModalOpen   : false,
      phase                     : 'link',
      shareMethod               : 'link',
    });
  }

  invokeEmailPhase() {
    this.setState({
      isIntroModalOpen          : false,
      isLinkModalOpen           : false,
      isEmailModalOpen          : true,
      isEmailConfirmModalOpen   : false,
      phase                     : 'email',
      shareMethod               : 'email',
    });
  }

  invokeEmailConfirmPhase() {
    this.setState({
      isIntroModalOpen          : false,
      isLinkModalOpen           : false,
      isEmailModalOpen          : false,
      isEmailConfirmModalOpen   : true,
      phase                     : 'emailConfirm',
    });
  }
}

export default SharePackModalSet;