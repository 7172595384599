
import EventManager       from '@brainscape/event-manager';
import PillButton         from '_views/shared/PillButton';
import React              from 'react';
import UiHelper           from '_utils/UiHelper';

class FtsePreBounceBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callback: null,
      isProcessingSuggestedPacks: false,
    };

    this.BANNER_ID = 'ftse-pre-bounce';
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <div className="banner-body">

        <div className="message">
          Are these not the flashcards you were looking for?
        </div>

        <div className="banner-actions">

          <div className="button-and-descriptor">
            <PillButton
              addClasses="info-banner-button make-cards-button"
              label="Create a Class"
              onClick={this.handleMakeCardsButtonClick}
            />
            <div className="descriptor">Make my own cards</div>
          </div>

          <div className="button-and-descriptor">
            <PillButton
              addClasses="resolve-banner-button show-suggestions-button"
              isProcessing={this.state.isProcessingSuggestedPacks}
              label="Suggest Classes"
              onClick={this.handleShowSuggestionsButtonClick}
            />
            <div className="descriptor">Show me other cool subjects</div>
          </div>

          <div className="button-and-descriptor">
            <PillButton
              addClasses="neutral-banner-button dashboard-button"
              label="See my Dashboard"
              onClick={this.handleDashboardButtonClick}
            />
            <div className="descriptor">&nbsp;</div>
          </div>
        </div>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleMakeCardsButtonClick = () => {
    this.triggerBannerClose();
    const createFtueUrl = '/l/dashboard?ftue=true&intent=create';
    UiHelper.navigate(createFtueUrl, 'Starting create process...');
  }

  handlePackSuggestionsModalCloseRequest = () => {
    this.triggerBannerClose();
    UiHelper.navigate('/l/dashboard',  'Loading Your Library...');
  }

  handleShowSuggestionsButtonClick = () => {
    this.triggerBannerClose();
    this.triggerPackSuggestionsModalOpen();
  }

  handleDashboardButtonClick = () => {
    this.triggerBannerClose();
    UiHelper.navigate('/l/dashboard', 'Loading Your Library...');
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerBannerClose = () => {
    EventManager.emitEvent('banner:close', {
      block:          'page',
      id:             'ftse-pre-bounce',
      page:           'study',
      position:       'top',
    });
  }

  triggerPackSuggestionsModalOpen = () => {
    EventManager.emitEvent('pack-suggestions-modal:open', {
      onCloseRequest: this.handlePackSuggestionsModalCloseRequest,
    });
  }
};

export default FtsePreBounceBanner;
