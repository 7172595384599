
import deckCard               from '_models/deckCard';
import model                  from '_core/newModel';

const cardClipboard = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  show() {
    const path = `clipboard`;
    return model.show(path);
  },

  copy(packId, deckId, cardIds) {
    const path = `packs/${packId}/decks/${deckId}/cards/cb/copy`;
    const data = {
      cardIds: cardIds,
    };

    return model.create(path, data).then(() => { 
      model.publish('card-clipboard:cards-copied', {
        action: 'copy',
        cardIds: cardIds,
        deckId: deckId,
        packId: packId,
      });
    });
  },

  clear() {
    const path = `clipboard`;

    return model.destroy(path).then(cardClipboardData => { 
      model.publish('card-clipboard:cleared', {
        clipboard: cardClipboardData.clipboard,
      });
    });
  },

  cut(packId, deckId, cardIds) {
    const path = `packs/${packId}/decks/${deckId}/cards/cb/cut`;
    const data = {
      cardIds: cardIds,
    };

    return model.create(path, data).then(() => { 
      model.publish('card-clipboard:cards-cut', {
        action: 'cut',
        cardIds: cardIds,
        deckId: deckId,
        packId: packId,
      });
    });
  },

  paste(packId, deckId) {
    return new Promise((resolve, reject) => {
      try {
        const path = `packs/${packId}/decks/${deckId}/cards/cb/paste`;
        const data = {};

        model.create(path, data).then(() => {
          deckCard.index(packId, deckId).then(cardsData => {

            const cardClipboard = {
              action: 'paste',
              cardIds: cardsData.cardIds,
              cards: cardsData.cards,
              deckId: deckId,
              packId: packId,
            };

            model.publish('card-clipboard:cards-pasted', cardClipboard);

            resolve(cardClipboard);
          }).catch(err => {
            throw err;
          });
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default cardClipboard;
