
// substrate and utils
import EventManager                   from '@brainscape/event-manager';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import scrollIntoView                 from 'scroll-into-view-if-needed';
import {toClassStr}                   from '_utils/UiHelper';

// models
import cardClipboard                  from '_models/cardClipboard';
import cardReversalJob                from '_models/cardReversalJob';
import deckCard                       from '_models/deckCard';
// import deckCardReordering             from '_models/deckCardReordering';
import userLocalStore                 from '_models/userLocalStore';

// sub-components
import DeckCardList                   from '_views/deck-detail/desktop/edit/DeckCardList';
import DeckEditSectionOptionsButton   from '_views/deck-detail/desktop/edit/DeckEditSectionOptionsButton';
import Pulldown                       from '_views/shared/Pulldown';
import RoundCheckbox                  from '_views/shared/RoundCheckbox';
import TransitionWrapper              from '_views/shared/TransitionWrapper';

import {
  AddButton,
  ContractButton,
  DropupButton,
  ExtendButton,
}                         from '_views/shared/IconButton';


const PT = {
  cardSelections:             PropTypes.object,
  cards:                      PropTypes.array,
  cardsHaveChanges:           PropTypes.bool,
  currentUser:                PropTypes.object,
  deck:                       PropTypes.object,
  isPaginatingDeckCards:      PropTypes.bool,
  pack:                       PropTypes.object,
  selectionCount:             PropTypes.number,
  sidebarMode:                PropTypes.string,
};

const HTML_BULK_ACTIONS_OPTIONS = [
  {id: 'cut', label: 'Cut Cards'},
  {id: 'copy', label: 'Copy Cards'},
  {id: 'paste', label: 'Paste Cards'},
  {id: 'delete', label: 'Delete Cards'},
  {id: 'apply-prompt', label: 'Apply Prompt', featuresList: 'list-5'},
  {id: 'create-reverse', label: 'Create Reverse Cards', featuresList: 'list-5'},
];

const BULK_ACTIONS_OPTIONS = [
  {id: 'copy', label: 'Copy Cards (to paste)'},
  {id: 'delete', label: 'Delete Cards'},
  {id: 'create-reverse', label: 'Create Reverse Cards', featuresList: 'list-5'},
];

const FLAG_DURATION = 6500; // this should be at least 1.5s longer than BADGE_DURATION in DeckCardRow


class DeckEditSectionSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBulkActionsPulldownOpen: false,
      isOrganizePulldownOpen: false,
      isProcessingBulkActionsChange: false,
      isProcessingOrganizeChange: false,
      lastAddedCardId: null,
      lastUpdatedCardId: null,
      organizeOptions: null,
      selectedBulkActionsOption: -1,
      selectedOrganizeOption: -1,
      shouldShowBulkActionsBar: null,
      sorting: false,
    };

    this.events = new EventManager();

    this.roundCheckbox = null;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.scrollToCurrentCard();
    this.clearTimeoutsAndIntervals();

    this.events.addListeners([
      ['card:added', this.handleCardAdded],
      ['card:updated', this.handleCardUpdated],
      ['card-reversal-job:started', this.handleCardReversalJobStarted],
      ['current-card:scroll-to-request', this.handleCurrentCardScrollToRequest],
    ]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectionCount > 0 && this.props.selectionCount < 1) {
      this.setState({
        shouldShowBulkActionsBar: false,
      });
    }

    if (prevProps.selectionCount < 1 && this.props.selectionCount > 0) {
      this.setState({
        shouldShowBulkActionsBar: true,
      });
    }

    if (this.props.currentCardId != prevProps.currentCardId) {
      this.scrollToCurrentCard();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const cards = this.props.cards;
    const optionsButtonType = (this.props.sidebarMode == 'mini') ? 'vertical' : 'horizontal';
    const sidebarModeClass = (this.props.sidebarMode == 'mini') ? 'is-mini' : 'is-full';
    const classes = toClassStr(['deck-edit-section-sidebar', sidebarModeClass, this.props.addClasses]);

    const allCardsCheckboxState = this.getAllCardsCheckboxState();
    const cardListHeading = (this.props.selectionCount < 1) ? 'Cards' : `Cards (${this.props.selectionCount} of ${cards.length})`;

    return (
      <div className={classes}>

        <header className="card-list-header">

          <div className="top-row">
            
            <RoundCheckbox
              addClasses="all-cards-checkbox"
              isChecked={allCardsCheckboxState}
              isDisabled={this.props.deck.cards.length < 1}
              isThreeState={true}
              onClick={this.handleAllCardsCheckboxClick}
              ref={(elem) => {this.roundCheckbox = elem}}
              state={allCardsCheckboxState}
              tooltipContent="Select Cards for Bulk Actions"
              tooltipPosition="top"
              value={this.props.deck.deckId}
            />

            <div className="card-list-heading">{cardListHeading}</div>

            <DeckEditSectionOptionsButton
              addClasses="deck-editor-options-button"
              currentUser={this.props.currentUser}
              deck={this.props.deck}
              iconType={optionsButtonType}
              isBulkActionsBarShowing={this.isBulkActionsBarOpen()}
              onChangeBulkActionsDisplayRequest={this.handleBulkActionsDisplayRequest}
              pack={this.props.pack}
            />

            {this.renderChangeModeButton()}

          </div>

          <div className="bottom-row">
            {this.renderBulkActionsBar()}
          </div>
        </header>

        <DeckCardList
          cardSelections={this.props.cardSelections}
          cards={this.props.cards}
          cardsHaveChanges={this.props.cardsHaveChanges}
          currentCardId={this.props.currentCardId}
          currentUser={this.props.currentUser}
          deckId={this.props.deck.deckId}
          deckCardView={this.props.deckCardView}
          isCreatingNewCard={this.props.isCreatingNewCard}
          isPaginatingDeckCards={this.props.isPaginatingDeckCards}
          lastAddedCardId={this.state.lastAddedCardId}
          lastUpdatedCardId={this.state.lastUpdatedCardId}
          // onSortEnd={this.handleSortEnd}
          packId={this.props.pack.packId}
          sidebarMode={this.props.sidebarMode}
        />

        {/* {this.renderMiniSidebarFooter()} */}
      </div>
    );
  };

  renderChangeModeButton() {
    if (this.props.sidebarMode == 'mini') {
      return (
        <ExtendButton 
          addClasses="extend-sidebar-button"
          onClick={this.handleExtendEditorSidebarButtonClick}
          tooltipContent="Extend to Full Sidebar, Perform Bulk Actions"
          tooltipPosition="top"
        />
      );
    }

    return (
      <ContractButton 
        addClasses="contract-sidebar-button"
        onClick={this.handleContractEditorSidebarButtonClick}
        tooltipContent="Contract to Mini Sidebar"
        tooltipPosition="top"
      />
    );
  }

  renderBulkActionsBar() {
    const isBulkActionsBarOpen = this.isBulkActionsBarOpen();

    return (
      <TransitionWrapper
        addClasses="bulk-actions-bar-transition-wrapper"
        shouldTransitionIn={isBulkActionsBarOpen}
        shouldTransitionOut={!isBulkActionsBarOpen}
        transitionInDelay={100}
        transitionInDuration={250}
        transitionOutDuration={250}
      >

        <div className="bulk-actions-bar">

          <Pulldown
            addClasses="bulk-actions-pulldown"
            isOpen={this.state.isBulkActionsPulldownOpen}
            isProcessing={this.isProcessingBulkActionsChange}
            isUserPro={this.props.currentUser.flags.isPro}
            options={BULK_ACTIONS_OPTIONS}
            placeholderText={'Bulk Actions'}
            selectedValue={this.state.selectedBulkActionsOption}
            shouldSuppressNullOption={false}
            onButtonClick={this.handleBulkActionsButtonClick}
            onOptionClick={this.handleBulkActionsOptionClick}
            onOutsideClick={this.handleBulkActionsOutsideClick}
          />
        </div>
      </TransitionWrapper>
    );
  }

//   renderMiniSidebarFooter() {
//     if (this.props.sidebarMode != 'mini') {
//       return null;
//     }
// 
//     return (
//       <div className="mini-sidebar-footer">
//         <AddButton 
//           addClasses="add-card-button"
//           onClick={this.handleAddCardButtonClick}
//           tooltipContent="Add New Card At End of Deck"
//           tooltipPosition="right"
//         />
//       </div>
//     );
//   }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // handleAddCardButtonClick = () => {
  //   EventManager.emitEvent('deck-editor:act:new', {});
  // }

  handleAllCardsCheckboxClick = () => {
    const allCardsCheckboxState = this.getAllCardsCheckboxState();
    let newState = allCardsCheckboxState;

    switch (allCardsCheckboxState) {
      case 'all':
        newState = 'none';
      break;
      case 'mixed':
        newState = 'all';
      break;
      case 'none':
        newState = 'all';
      break;
    }

    const isSelected = (newState == 'all');

    EventManager.emitEvent('card-selections:updated', {isSelected: isSelected});
  };

  handleBulkActionsButtonClick = () => {
    this.setState({
      isBulkActionsPulldownOpen: !this.state.isBulkActionsPulldownOpen,
    })
  }

  handleBulkActionsDisplayRequest = (state) => {
    if (state) {
      this.handleExtendEditorSidebarButtonClick();
    }

    this.setState({
      shouldShowBulkActionsBar: state,
    });
  }

  handleBulkActionsOptionClick = (optionId) => {
    this.setState({
      isBulkActionsPulldownOpen:     false,
    }, () => {
      this.performBulkActionsTask(optionId);
    });
  }

  handleBulkActionsOutsideClick = () => {
    this.setState({
      isBulkActionsPulldownOpen: false,
    })
  }

  handleCardAdded = (data) => {
    if (!data && !data.card) {
      return false;
    }

    this.setState({
      lastAddedCardId: data.card.cardId,
      lastUpdatedCardId: null,
    }, () => {
      this.removeLastAddedFlagAfterDelay();
    });
  }

  handleCardReversalJobStarted = (eventData) => {
    console.log('in handleCardReversalJobStarted. eventData:', eventData);
    this.triggerInfoModalOpen({
      message:
        'Reversing cards will make copies of your selected cards, but with the positions of the Question and Answer faces swapped. In other words, in these new cards, the Question becomes the Answer, and vice versa. These cards will be pasted at the end of your current cards. \n\nDepending on the size of your deck, this may take a short time to complete.',
      resolveButtonText: 'Ok',
      title: 'About Reverse Cards',
    });
  }

  handleCardUpdated = (data) => {
    if (!data && !data.card) {
      return false;
    }

    this.setState({
      lastAddedCardId: null,
      lastUpdatedCardId: data.card.cardId,
    }, () => {
      this.removeLastUpdatedFlagAfterDelay();
    });
  }

  handleContractEditorSidebarButtonClick = () => {
    this.triggerTooltipClose();
    this.triggerEditorSidebarModeChangeRequest('mini');
  }

  handleCurrentCardScrollToRequest = () => {
    this.scrollToCurrentCard();
  }

  handleExtendEditorSidebarButtonClick = () => {
    this.triggerTooltipClose();
    this.triggerEditorSidebarModeChangeRequest('full');
  }

  getSelectedCardIds = () => {
    const cardSelections = this.props.cardSelections;
    let cardSelectionIds = [];

    Object.keys(cardSelections).forEach(id => {
      if (cardSelections[id]) {
        cardSelectionIds.push(parseInt(id));
      }
    });

    return cardSelectionIds;
  }

  handleOptionCopyCardsAction = () => {
    const packId = this.props.pack.packId;
    const deckId = this.props.deck.deckId;
    const cardIds = this.getSelectedCardIds();

    if (!(cardIds && cardIds.length > 0)) {
      return false;
    }

    cardClipboard.copy(packId, deckId, cardIds);
  }

  handleOptionDeleteCardsAction = () => {
    const packId = this.props.pack.packId;
    const deckId = this.props.deck.deckId;
    const cardIds = this.getSelectedCardIds();

    if (!(cardIds && cardIds.length > 0)) {
      return false;
    }

    this.triggerConfirmModalOpen({
      actionText: `remove ${cardIds.length} cards from your deck (this action can not be undone)`,
      resolveButtonText: 'Yes, delete cards',
      onResolution: () => {
        deckCard.destroyMulti(packId, deckId, cardIds);
      },
    });
  }

  handleOptionPasteCardsAction = () => {
    const packId = this.props.pack.packId;
    const deckId = this.props.deck.deckId;

    cardClipboard.paste(packId, deckId);
  }

  handleOptionReverseCardsAction = () => {
    const packId = this.props.pack.packId;
    const deckId = this.props.deck.deckId;
    const cardIds = this.getSelectedCardIds();

    if (!(cardIds && cardIds.length > 0)) {
      return false;
    }

    cardReversalJob.create(packId, deckId, cardIds);
  }

  handleRetractBulkActionsBarButtonClick = () => {
    this.setState({
      shouldShowBulkActionsBar: false,
    })
  }

//   handleSortEnd = (sortedIds) => {
//     console.log('in handleSortEnd. sortedIds:', sortedIds);
// 
//     const packId = this.props.pack.packId;
//     const deckId = this.props.deck.deckId;
// 
//     deckCardReordering.create(packId, deckId, sortedIds);
//   };


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerEditorSidebarModeChangeRequest = (mode) => {
    EventManager.emitEvent('editor-sidebar:mode-change-request', {mode: mode});
  }

  triggerInfoModalOpen = (viewProps) => {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  triggerToastOpen = (message, type='success') => {
    EventManager.emitEvent('toast:open', {
      message: message,
      type: type,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.lastAddedFlagTimeout);
    clearTimeout(this.lastUpdatedFlagTimeout);
  }

  getAllCardsCheckboxState = () => {
    const cardCount = this.props.deck.cardIds?.length;
    const selectionCount = this.props.selectionCount;

    if (selectionCount == 0) {
      return 'none';
    }

    if (selectionCount == cardCount) {
      return 'all';
    }

    return 'mixed';
  }

  isBulkActionsBarOpen = () => {
    if (this.state.shouldShowBulkActionsBar === true) {
      return true;
    }

    if (this.state.shouldShowBulkActionsBar === false) {
      return false;
    }

    return (this.props.selectionCount > 0)
  }

  performBulkActionsTask = (optionId) => {
    if (this.props.selectionCount < 1) {
      this.triggerToastOpen('Select some cards to perform Bulk Actions', 'error');
      return false;
    }

    switch (optionId) {
      case 'copy':
        this.handleOptionCopyCardsAction();
      break;
      case 'paste':
        this.handleOptionPasteCardsAction();
      break;
      case 'delete':
      this.handleOptionDeleteCardsAction();
      break;
      case 'create-reverse':
        this.handleOptionReverseCardsAction();
      break;
    }
  }

  removeLastAddedFlagAfterDelay = () => {
    clearTimeout(this.lastAddedFlagTimeout);

    this.lastAddedFlagTimeout = setTimeout(() => {
      this.setState({
        lastAddedCardId: null,
      });
    }, FLAG_DURATION);
  }

  removeLastUpdatedFlagAfterDelay = () => {
    clearTimeout(this.lastUpdatedFlagTimeout);

    this.lastUpdatedFlagTimeout = setTimeout(() => {
      this.setState({
        lastUpdatedCardId: null,
      });
    }, FLAG_DURATION);
  }

  scrollToCurrentCard = () => {
    const deckCardRowId = `#deck-card-row-${this.props.currentCardId}`;
    this.scrollToDeckCardRowById(deckCardRowId);
  }

  scrollToDeckCardRowById(id) {
    const currentDeckCardRow = document.querySelector(id);

    if (currentDeckCardRow) {
      scrollIntoView(currentDeckCardRow, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
}

DeckEditSectionSidebar.propTypes = PT;

export default DeckEditSectionSidebar;
