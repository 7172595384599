
import model from '_core/newModel';

const BASE_PATH = 'me/suggest/packs';


const trendingPack = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index() {
    return model.index(BASE_PATH)
      .then(res => {
        if (!res?.rows || res.rows.length === 0) {
          return [];
        }

        // Process rows to place trendingPack stats into standard stats sub-object
        const rows = res.rows.map(row => {
          row.stats = {
            cardCount: row.cardCount,
            deckCount: row.deckCount
          };

          delete row.cardCount;
          delete row.deckCount;

          return row;
        });

        return rows;
      })
      .catch(err => {
        console.log('Error fetching trending packs:', err);
        return Promise.reject(err);
      });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default trendingPack;
