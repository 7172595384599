
import Bubble                         from '_views/shared/Bubble';
import BubbleDialog                   from '_views/shared/BubbleDialog';
import EventManager                   from '@brainscape/event-manager';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import Tracker                        from '_utils/Tracker';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses              : PropTypes.string,
  currentPack             : PropTypes.object,
  currentView             : PropTypes.string, // 'pack-detail', 'deck-detail'
  isMobileViewportSize    : PropTypes.bool,
};

class FtueCreateMessaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSeenPackHelp                 : false,
      isCardHelpBubbleOpen            : false,
      isDeckHelpBubbleOpen            : false,
      isEditorHelpBubbleOpen          : false,
      isPackHelpBubbleOpen            : false,
      isPackMetadataModalSetOpen      : false,
      shouldCollapseCardHelpBubble    : false,
      shouldCollapseDeckHelpBubble    : false,
      shouldCollapseEditorHelpBubble  : false,
      shouldCollapsePackHelpBubble    : false,
      phase                           : null, // introVideo or packHelp, createPack, selectPurpose, deckHelp, createDeck, cardHelp, createCard
    };

    this._isMounted = false;

    this.events = new EventManager();

    this.pulseCreatePackButtonTimeout = null;
    this.pulseCreateDeckButtonTimeout = null;
    this.pulseAddCardsButtonTimeout = null;

    this.PRE_PULSE_CREATE_PACK_BUTTON_DELAY = 3000;
    this.PRE_PULSE_CREATE_DECK_BUTTON_DELAY = 3000;
    this.PRE_PULSE_ADD_CARDS_BUTTON_DELAY = 3000;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();

    this.events.addListeners([
      ['card:created',                      this.handleCardCreated],
      ['deck:created',                      this.handleDeckCreated],
      ['deck:create-dismissed',             this.handleDeckCreateDismissed],
      ['deck:create-requested',             this.handleDeckCreateRequested],
      ['pack:created',                      this.handlePackCreated],
      ['pack:create-dismissed',             this.handlePackCreateDismissed],
    ]);

    this.invokeFtue();
  };

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this.events.disable();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['ftue-create-messaging', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderPackHelpBubble()}
        {this.renderDeckHelpBubble()}
        {this.renderCardHelpBubble()}
        {this.renderEditorHelpBubble()}
      </div>
    );
  }

  renderPackHelpBubble() {
    if (!this.state.isPackHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';

    return (
      <BubbleDialog
        expansionDelay={1000}
        message="The first step is to create a Class, which is a container for your Flashcards. Click the plus sign at the lower left to get started."
        onClose={this.handlePackHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapsePackHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Creating Flashcards'
      />
    );
  }

  renderDeckHelpBubble() {
    if (!this.state.isDeckHelpBubbleOpen) {
      return null;
    }

    const bubbleCorner = this.props.isMobileViewportSize ? 'topRight' : 'bottomRight';
    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';

    return (
      <BubbleDialog
        corner={bubbleCorner}
        expansionDelay={1000}
        message="To help organize your Class, you'll create 'Decks' to group Flashcards (like chapters in a book). Click the orange 'Create New Deck' button to create at least one Deck."
        onClose={this.handleDeckHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseDeckHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Great!'
      />
    );
  }

  renderCardHelpBubble() {
    if (!this.state.isCardHelpBubbleOpen) {
      return null;
    }

    const bubbleCorner = this.props.isMobileViewportSize ? 'topRight' : 'bottomLeft';
    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';

    return (
      <BubbleDialog
        corner={bubbleCorner}
        expansionDelay={1000}
        message="Next, you will use the Flashcard editor to create your Flashcards. Click the 'Add Cards' button above to continue."
        onClose={this.handleCardHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseCardHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Ready for Flashcards'
      />
    );
  }

  renderEditorHelpBubble() {
    if (!this.state.isEditorHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';
    const bubbleCorner = (this.props.isMobileViewportSize) ? 'topRight' : 'bottomRight';

    return (
      <BubbleDialog
        corner={bubbleCorner}
        expansionDelay={1000}
        message='Add a Question and Answer for one or more cards, and then you will be able to study by clicking any blue "STUDY" button.'
        onClose={this.handleEditorHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseEditorHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Create Your Cards'
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleIntroVideoModalCloseRequest = () => {
    this.setState({
      phase: 'createPack',
    }, () => {
      this.triggerCreatePackModalOpen();
    });
  }

  handleCardHelpBubbleCollapse = () => {
    this.setState({
      isCardHelpBubbleOpen: false,
    }, () => {
      // check to see if bubble collapsed due to user continuing, or dismissing
      if (!this.state.shouldCollapseCardHelpBubble) {
        // user dismissed bubble
        this.triggerFtueDismissRequest();
      }
    });
  }
  
  handleDeckHelpBubbleCollapse = () => {
    this.setState({
      isDeckHelpBubbleOpen: false,
    }, () => {
      // check to see if bubble collapsed due to user continuing, or dismissing
      if (!this.state.shouldCollapseDeckHelpBubble) {
        // user dismissed bubble
        this.triggerFtueDismissRequest();
      }
    });
  }
  
  handlePackHelpBubbleCollapse = () => {
    this.setState({
      isPackHelpBubbleOpen: false,
    }, () => {
      // check to see if bubble collapsed due to user continuing, or dismissing
      if (!this.state.shouldCollapsePackHelpBubble) {
        // user dismissed bubble
        this.triggerFtueDismissRequest();
      }
    });
  }

  handlePackCreateRequested = (data) => {
    this.setState({
      phase: 'createPack',
      shouldCollapsePackHelpBubble: true,
    });
  }

  handlePackCreateDismissed = () => {
    if (this.state.phase == 'createPack' && !this.state.hasSeenPackHelp) {

      this.setState({
        hasSeenPackHelp: true,
        isPackHelpBubbleOpen: true,
        phase: 'packHelp',
        shouldCollapsePackHelpBubble: false,
      }, () => {
        this.pulseCreatePackButton();
      });

      return true;
    }

    this.setState({
      shouldCollapsePackHelpBubble: true,
    }, () => {
      this.triggerFtueDismissRequest();
    });
  }

  handlePackCreated = () => {
    this.setState({
      isDeckHelpBubbleOpen: true,
      phase: 'deckHelp',
    }, () => {
      this.pulseCreateDeckButton();
    });
  } 

  handleDeckCreateRequested = () => {
    this.setState({
      phase: 'createDeck',
      shouldCollapseDeckHelpBubble: true,
    });
  }

  handleDeckCreateDismissed = () => {
    this.setState({
      shouldCollapseDeckHelpBubble: true,
    }, () => {
      this.triggerFtueDismissRequest();
    });
  }

  handleDeckCreated = () => {
    this.setState({
      phase: 'cardHelp',
      isCardHelpBubbleOpen: true,
    });

    this.pulseAddCardsButton();
  }

  handleCardCreateRequested = () => {
    this.setState({
      phase: 'createCard',
      shouldCollapseCardHelpBubble: true,
    });
  }

  handleCardCreateDismissed = () => {
    this.setState({
      shouldCollapseCardHelpBubble: true,
    }, () => {
      this.triggerFtueDismissRequest();
    });
  }

  handleCardCreated = () => {
    this.setState({
      phase: 'ftueEnd',
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerFtueDismissRequest(viewProps) {
    EventManager.emitEvent('ftue:dismiss-request', viewProps);
  }

  triggerCreatePackModalOpen(viewProps) {
    EventManager.emitEvent('create-pack-modal:open', viewProps);
  }

  triggerFtuePulseAddCardsButtonRequest(viewProps) {
    EventManager.emitEvent('ftue:pulse-add-cards-button-request', viewProps);
  }

  triggerFtuePulseCreateDeckButtonRequest(viewProps) {
    EventManager.emitEvent('ftue:pulse-create-deck-button-request', viewProps);
  }

  triggerFtuePulseCreatePackButtonRequest(viewProps) {
    EventManager.emitEvent('ftue:pulse-create-pack-button-request', viewProps);
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  pulseAddCardsButton() {
    clearTimeout(this.pulseAddCardsButtonTimeout);

    this.pulseAddCardsButtonTimeout = setTimeout(() => {
      this.triggerFtuePulseAddCardsButtonRequest();
    }, this.PRE_PULSE_ADD_CARDS_BUTTON_DELAY);
  }

  pulseCreatePackButton() {
    clearTimeout(this.pulseCreatePackButtonTimeout);

    this.pulseCreatePackButtonTimeout = setTimeout(() => {
      this.triggerFtuePulseCreatePackButtonRequest();
    }, this.PRE_PULSE_CREATE_PACK_BUTTON_DELAY);
  }

  pulseCreateDeckButton() {
    clearTimeout(this.pulseCreateDeckButtonTimeout);

    this.pulseCreateDeckButtonTimeout = setTimeout(() => {
      this.triggerFtuePulseCreateDeckButtonRequest();
    }, this.PRE_PULSE_CREATE_DECK_BUTTON_DELAY);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.pulseCreatePackButtonTimeout);
    clearTimeout(this.pulseCreateDeckButtonTimeout);
    clearTimeout(this.pulseAddCardsButtonTimeout);
  }

  invokeFtue() {
    if (this.props.currentView == 'pack-detail' || this.props.currentView == 'mobile-class-index' ) {
      this.setState({
        phase: 'launch',
      }, () => {
        this.triggerCreatePackModalOpen({isFtce: true});
      });

      Tracker.trackDashboardProgress('ftue_intro_create');

      return true;
    }

    if (this.props.currentView == 'deck-detail') {
      this.setState({
        isEditorHelpBubbleOpen: true,
        phase: 'editorHelp',
      });

      return true;
    }    
  }
}

FtueCreateMessaging.propTypes = PT;

export default FtueCreateMessaging;
