
import { apiGet } from '_core/Api2';
import TimeHelper from '_utils/TimeHelper';
import UiHelper from '_utils/UiHelper';

const DEFAULT_MAX_DURATION = (1000 + 60 * 5); // 5 minutes


const DelayedResultsHelper = {

  handleDelayedResults(rs, maxDuration=DEFAULT_MAX_DURATION) {
    if (!rs) {
      return Promise.reject(new Error('Need initial delayed result response'));
    }

    if (rs.delayedResultId) {
      return apiGet(`delayed_results/${rs.delayedResultId}`).then(function(newRs) {
        return DelayedResultsHelper.handleDelayedResults(newRs, maxDuration);
      });
    }

    return new Promise((resolve, reject) => {
      try {
        // if this point is reached, we have a delayed result id and can start monitoring for a completed result

        const startTimestamp = TimeHelper.now();

        const monitorProgress = (rs) => {
          if (TimeHelper.now() - startTimestamp > maxDuration) {
            return reject(new Error('Delayed result processing time exceeded maximum duration'));
          }

          if (rs.id && rs.startedEpoch) {
            if (rs.stoppedEpoch) {
              if (rs.error) {
                return reject(new Error(rs.error));
              }
        
              const delayedResult = JSON.parse(rs.result);
        
              if (delayedResult?.resultId) {
                return resolve(delayedResult);
              }
        
              return reject(new Error('Unexpected response'));
            }
        
            // Wait for 2 seconds and check again
            UiHelper.sleep(2000).then(() => {
              apiGet(`delayed_results/${rs.id}`).then((newRs) => {
                monitorProgress(newRs);
              }).catch(reject);
            });
          }
        }

        monitorProgress(rs);

      } catch(err) {
        reject(err);
      }
    });  
  }
};

export default DelayedResultsHelper;
