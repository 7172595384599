
// substrate and utils
import EventManager               from '@brainscape/event-manager';
import NumberHelper               from '_utils/NumberHelper';
import PersonHelper               from '_utils/PersonHelper';
import React                      from 'react';
import StudyHelper                from '_utils/StudyHelper';
import TimeHelper                 from '_utils/TimeHelper';
import UiHelper                   from '_utils/UiHelper';
import {toClassStr}               from '_utils/UiHelper';

// models
import packLearner                from '_models/packLearner';
import packLearnerTransform       from '_models/packLearnerTransform';
import packMetadata               from '_models/packMetadata';

// sub-components
import Pulldown                   from '_views/shared/Pulldown';
import RoundCheckbox              from '_views/shared/RoundCheckbox';
import SimpleProgressBar          from '_views/shared/SimpleProgressBar';
import Spinner                    from '_views/shared/Spinner';
import DynamicTooltipIcon               from '_views/shared/DynamicTooltipIcon';

import {
  ForwardButton,
  RemoveButton,
} from '_views/shared/IconButton';


const PERMISSION_OPTIONS = [
  { id: 'preview', label: 'Preview' },
  { id: 'study', label: 'Full Study' },
  { id: 'edit', label: 'Edit' },
  { id: 'admin', label: 'Admin' },
];


class LearnerTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
      isPermissionPulldownOpen: false,
      isProcessingPermissionChange: false,
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        learner,
        pack,
    */

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const learner = this.props.learner;
    const stats = learner.stats || {};
    const isCurrentUser = learner.flags.isCurrentUser;

    const avatarTitle = `See ${learner.firstName}'s profile`;
    const learnerName = PersonHelper.getProfileName(learner, this.props.pack.permission);

    const isCurrentUserClass = (isCurrentUser) ? 'is-current-user' : ''; 
    let masteryClass = '';

    if (this.canUserViewStats && stats.mastery) {
      const mastery = stats.mastery;
      const masteryPercentage = Math.floor(mastery * 100) || 0;
      const masteryLevel = StudyHelper.getMasteryLevel(masteryPercentage);
      masteryClass = `mastery-level-${masteryLevel}`;
    }

    let classes = toClassStr(['learner-block tile', isCurrentUserClass, masteryClass, this.props.addClasses]);

    return (
      <li className={classes} onClick={(e) => this.handleLearnerTileClick(e)}>
        <div className="tile-content">
          <div className="first-row">
            <div className="learner-avatar">
              <img
                className="learner-avatar-image"
                src={learner.avatarUrl}
                title={avatarTitle}
              />
            </div>

            <div className="learner-name-and-mastery">
              <div className="name-and-mastery-percentage">
                <h4 className="learner-name">{learnerName}</h4>
              </div>

              <div className="mastery-progress-bar">{this.renderMasteryProgressBar()}</div>
            </div>

            {/* <div className="learner-actions">{this.renderRowIndicator()}</div> */}
          </div>

          <div className="learner-stats-and-permissions">
            <div className="table-row mastery">
              <div className="label">Mastery:</div>
              <span className="stat">{this.renderMastery()}</span>
            </div>

            <div className="table-row days-studied">
              <div className='label'>Days studied:</div>
              <span className="stat">{this.renderStat(stats.daysStudied)}</span>
            </div>

            <div className="table-row time-studied">
              <div className='label'>Time studied:</div>
              <span className="stat">{this.renderTimeStudied()}</span>
            </div>

            <div className="table-row cards-studied">
              <div className="label">Cards studied:</div>
              <div className="unique-and-total">
                <div className="unique">
                  <div className="label">Unique:</div>
                  <span className="stat">{this.renderStat(stats.seenCount)}</span>
                </div>
                <div className="total">
                  <div className="label">Total:</div>
                  <span className="stat">{this.renderStat(stats.totalCardsStudied)}</span>
                </div>
              </div>
            </div>

            <div className="table-row permission">
              <div className="label">Permission:</div>
              <span className="stat">{this.renderPermission()}</span>
            </div>
          </div>
        </div>
      </li>
    );
  }

  renderLearnerSelectionCheckbox = () => {
    if (this.props.pack.permission != 'admin') { return null;
    }

    const tooltipContent = (this.props.isSelected) ? 'Unselect Learner' : 'Select Learner';
    
    return (
      <RoundCheckbox
        isChecked={this.props.isSelected}
        value={this.props.learner.userId}
        onClick={this.handleLearnerSelectionCheckboxClick}
        tooltipContent={tooltipContent}
        tooltipPosition="right"
      />
    );
  }

  renderMasteryProgressBar = () => {
    if (this.props.pack.permission != 'admin') { return null;
    }

    const stats = this.props.learner.stats;

    if (!stats) {
      return null;
    }

    const masteryPercentage = (stats.mastery || 0) * 100;

    return (
      <SimpleProgressBar
        baseValue={100}
        barValue={masteryPercentage}
        isLight={true}
        shouldDepictLevel={false}
      />
    );
  }

  renderStat = (stat) => {
    if (typeof stat == 'undefined') {
      return null;
    }
    if (!this.canUserViewStats()) {
      return null;
    }

    if (!(this.props.learner.flags.isCurrentUser || this.props.currentUser.flags.isPro)) {
      return this.renderStatsLock();
    }

    const displayedStat = (stat === 0 || stat === null) ? '0' : stat;

    return (
      <div className="stat">
        {displayedStat}
      </div>
    );
  }

  renderStatsLock = () => {
    const actions = [{
      label: 'Upgrade to Pro',
      tag: 'upgrade',
      handler: this.handleStatsLockUpgradeRequest,
    }];

    return (
      <div className="stat stat-locked">
        <DynamicTooltipIcon
          actions={actions} 
          addClasses="locked-icon"
          body="Upgrade to Pro and help your students rise to their challenge by keeping track of their progress with Brainscape Certified™ Progress Stats."
          delay={500}
          hasDismissButton={true}
          heading="Unlock Stats & Reports"
          iconType="lock"
          position="top"
        />
      </div>
    );
  }

  renderMastery = () => {
    if (!this.canUserViewStats()) {
      return null;
    }

    if (!(this.props.learner.flags.isCurrentUser || this.props.currentUser.flags.isPro)) {
      return this.renderStatsLock();
    }

    const mastery = this.props.learner.stats?.mastery;

    if (typeof mastery == 'undefined') {
      return null;
    }

    if (mastery === 0 || mastery === null) {
      return (
        <div className="stat">0</div>
      );
    }

    const masteryPercentage = NumberHelper.percentageStr(mastery, 1);

    return (
      <div className="stat">{masteryPercentage}%</div>
    );
  }

  renderTimeStudied = () => {
    if (!this.canUserViewStats()) {
      return null;
    }

    if (!(this.props.learner.flags.isCurrentUser || this.props.currentUser.flags.isPro)) {
      return this.renderStatsLock();
    }

    const timeStudied = this.props.learner?.stats?.timeStudied;

    if (typeof timeStudied == 'undefined') {
      return null;
    }

    if (timeStudied === 0 || timeStudied === null) {
      return (
        <div className="stat">0</div>
      );
    }

    const decimalPlace = 1;
    const asObject = true;
    const timeParts = TimeHelper.msToRoundedTime(timeStudied, decimalPlace, asObject);

    if (!(timeParts && Object.keys(timeParts).length > 0)) {
      return null;
    }

    return (
      <div className="stat">
        <span className="stat-value">{timeParts.value}</span>
        <span className="stat-units">{timeParts.units}</span>
      </div>
    );
  }

  renderPermission() {
    const {permissionValue, permissionLabel} = packLearner.showPermission(this.props.learner);

    if (this.state.isProcessingPermissionChange) {
      return (
        <Spinner 
          color="blue"
        />
      )
    }

    if (!this.canUserChangePermissions()) {
      return permissionLabel;
    }

    return (
      <Pulldown
        addClasses="permission-pulldown"
        isOpen={this.state.isPermissionPulldownOpen}
        isProcessing={this.isProcessingPermissionChange}
        options={PERMISSION_OPTIONS}
        selectedValue={permissionValue}
        shouldSuppressNullOption={true}
        onButtonClick={this.handlePermissionButtonClick}
        onOptionClick={this.handlePermissionOptionClick}
        onOutsideClick={this.handlePermissionOutsideClick}
      />
    );
  }

  renderRemoveLearnerButton = () => {
    if (this.props.pack.permission != 'admin') { 
      return null;
    }

    if (this.props.learner.flags.isCurrentUser) {
      return null;
    }

    return (
      <RemoveButton
        addClasses="remove-learner-button"
        onClick={this.handleRemoveLearnerButtonClick}
        tooltipContent="Remove Learner from Class"
        tooltipPosition="left"
      />
    );
  }

  renderLearnerDetailButton() {
    const tooltipContent = (this.props.pack.permission == 'admin') ? 'View Learner Progress Report' : 'View Learner Profile';

    return (
      <ForwardButton
        addClasses="action-button learner-forward-button"
        key="learner-forward-button"
        onClick={this.handleLearnerForwardButtonClick}
        tooltipContent={tooltipContent}
        tooltipPosition="left"
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleLearnerAvatarClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.openLearnerProfile();
  }

  handleLearnerSelectionCheckboxClick = () => {
    packLearnerTransform.update(this.props.currentUser.userId, this.props.pack.packId, 'selections', {
      learnerIds: [this.props.learner.userId],
      isSelected: !this.props.isSelected,
    });
  }

  handleLearnerForwardButtonClick = () => {
    this.handleLearnerTileClick();
  }

  handleLearnerTileClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    const currentUser = this.props.currentUser;
    const pack = this.props.pack;

    if (pack.permission == 'admin') {

      if (currentUser.flags.isPro) {
        this.triggerLearnerProgressModalOpen();
        return true;
      }

      this.handleLearnerProgressModalUpgradeRequest();
      return true;
    }

    this.openLearnerProfile();
  }

  handleLearnerProgressModalUpgradeRequest = () => {
    const pack = this.props.pack;

    Util.trackPaywallWarning('stats');

    packMetadata.show(pack.packId).then(metadata => {
      const role = metadata.global?.role?.value || 'Student';
      const purpose = packMetadata.getPurpose(metadata) || 'General Learning/Other';

      if (role == 'Instructor') {
        this.triggerInstructorUpgradeModalOpen(purpose);
        return true;
      }

      this.triggerUpgradeModalOpen();
    });
  }

  handlePermissionButtonClick = () => {
    this.setState({
      isPermissionPulldownOpen: !this.state.isPermissionPulldownOpen,
    });
  };

  handlePermissionOptionClick = (optionId) => {
    this.setState(
      {
        isPermissionPulldownOpen: false,
        isProcessingPermissionChange: true,
      },
      () => {
        this.performPermissionChange(optionId);
      },
    );
  };

  handlePermissionOutsideClick = () => {
    this.setState({
      isPermissionPulldownOpen: false,
    });
  };

  handleRemoveLearnerButtonClick = () => {
    const message = (
      <div className="remove-learner-modal-content">
        <div className="modal-message">You are about to remove this Learner from this Class. This action cannot be undone.</div>

        <div className="modal-guidance secondary">
          (Note: The user will not be removed from Brainscape and may always be re-invited to this Class.)
        </div>
      </div>
    );

    this.triggerCautionModalOpen({
      message: message,
      resolveButtonText: 'Yes, Remove Learner',
      onResolution: () => {
        this.triggerCautionModalClose();
        this.removeLearner();
      },
    });
  }

  handleStatsLockUpgradeRequest = () => {
    this.triggerUpgradeModalOpen(
      'Access to stats and reports on all learners',
      'stats',
      'list-8',
    );
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalClose() {
    EventManager.emitEvent('caution-modal:close', {});
  }

  triggerCautionModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerInstructorUpgradeModalOpen(purpose) {
    EventManager.emitEvent('upgrade-modal:open', {
      paywall: 'stats',
      purpose: purpose,
      featuresList: 'list-8',
    });
  }

  triggerLearnerProgressModalOpen = () => {
    EventManager.emitEvent('learner-progress-modal:open', {
      learner: this.props.learner,
      pack: this.props.pack,
    });
  }

  triggerUpgradeModalOpen(desiredAction, paywall, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall: paywall,
      featuresList: featuresList,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  canUserChangePermissions = () => {
    return (this.props.pack.permission == 'admin' || (this.props.learner?.flags?.isCurrentUser && this.props.currentUser.flags.isBscAdmin));
  }

  canUserViewStats = () => {
    return (this.props.pack.permission == 'admin' || this.props.learner?.flags?.isCurrentUser);
  }

  completePermissionChange = (optionId) => {
    const learner = this.props.learner;

    const learnerData = {
      permissionOptionId: optionId,
      discoveryMedium: learner.discoveryMedium,
    };

    packLearner.update(this.props.pack.packId, learner.userId, learnerData).then(() => {
      this.setState({
        isProcessingPermissionChange: false,
      });
    });
  }

  openLearnerProfile = () => {
    this.setState({
      isProcessing: true,
    });

    UiHelper.openInNewTab(this.props.learner.profilePath, 'profile');
  }

  performPermissionChange = (optionId) => {
    const pack = this.props.pack;
    const learner = this.props.learner;
    const {permissionValue, permissionLabel} = packLearner.showPermission(learner);
    const currentUser = this.props.currentUser;

    if (learner.flags.isCurrentUser && permissionValue == 'admin' && optionId != 'admin' && !(currentUser.flags.isBscAdmin || currentUser.flags.isBscEmployee)) {

      const message = (
        <div className="modal-message">
          You are about to remove your own Admin rights. After this change takes
          place, you will not be able to reassign yourself Admin rights to this
          Class. Are you sure that you wish to proceed?
        </div>
      );

      this.triggerCautionModalOpen({
        message: message,
        resolveButtonText: 'Yes, I know what I am doing',
        onResolution: () => {
          this.triggerCautionModalClose();
          this.completePermissionChange(optionId);
        },
        onCloseRequest: () => {
          this.setState({
            isProcessingPermissionChange: false,
          });
        }
      });

      return true;
    }

    this.completePermissionChange(optionId);
  }

  removeLearner = () => {
    packLearner.remove(packId, this.props.learner.userId);
  }
}

export default LearnerTile;
