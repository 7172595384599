
import EventManager           from '@brainscape/event-manager';
import SessionStorageHelper   from '_utils/SessionStorageHelper';

import packDeck               from '_models/packDeck';


const currentDeckConcern = {
  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch(packId, deckId, currentDeck={}) {
    return new Promise((resolve, reject) => {
      try {
        packDeck.show(packId, deckId).then(packDeckData => {

          let currentDeck = {...currentDeck, ...packDeckData.deck};
          SessionStorageHelper.setPackItem(currentDeck.packId, 'currentDeck', currentDeck);

          const currentDeckData = {
            currentDeck: currentDeck,
            deckId: currentDeck.deckId,
            packId: packId,
          };

          this.publishDeckRetrieved(currentDeckData);
          resolve(currentDeckData);
        }).catch(err => {
          console.error('Problem fetching packDeck data. packId, deckId, err:', packId, deckId, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  // get checks session store for cached objects, publishes immediately to allow display, then refreshes cached copy by conducting fetches against the API
  get(packId, deckId) {    
    return new Promise((resolve, reject) => {
      try {
        const currentDeck = SessionStorageHelper.getPackItem(packId, 'currentDeck');

        if (currentDeck) {
          const currentDeckData = {
            currentDeck: currentDeck,
            deckId: currentDeck.deckId,
            packId: packId,
          };

          this.publishDeckRetrieved(currentDeckData);

          // resolve right away to allow UI to update quickly
          resolve(currentDeckData);

          // continue on to refresh cache
          this.fetch(packId, deckId);
          return true;
        }

        this.fetch(packId, deckId).then(currentDeckData => {
          resolve(currentDeckData);
        }).catch(err => {
          console.error('Problem getting packDeck data. packId, deckId, err:', packId, deckId, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  handleDeckRetrieved(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck, ...eventData.currentDeck};
    const updatedDeckId = updatedDeck.deckId;

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);

    return {
      currentDeck: updatedDeck,
      currentDeckId: updatedDeck.deckId,
    }
  },

  handleDeckUpdated(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck, ...eventData.deck};
    const updatedDeckId = updatedDeck.deckId;

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);

    return {
      currentDeck: updatedDeck,
      currentDeckId: updatedDeck.deckId,
    }
  },

  publishDeckRetrieved(currentDeckData) {
    EventManager.emitEvent('deck:retrieved', currentDeckData);
  },
};

export default currentDeckConcern;
