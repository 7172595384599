
import PropTypes                      from 'prop-types';
import React                          from 'react';
import VideoEmbed                     from '_views/shared/VideoEmbed';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                      : PropTypes.string,
  hasVideo                        : PropTypes.bool,
  segmentId                       : PropTypes.node,         
};


class PackAboutBrainscapeSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['segment about-tab-segment pack-about-brainscape-segment', this.props.addClasses]);

    return (
      <div className={classes}>
        <div className="segment-anchor" id={this.props.segmentId}></div>

        <div className="segment-header about-tab-segment-header brainscape-header"> 
          <div className="segment-heading about-tab-segment-heading brainscape-heading">About Brainscape</div>
        </div>

        <div className="overview-text">
          <p className="overview-line">Brainscape is the most efficient way to study adaptive flashcards.  Our unique Confidence-Based Repetition method is proven by decades of cognitive science research to help you learn twice as fast, and to retain knowledge for longer, than studying with traditional methods.</p>
          <p className="overview-line">You can find adaptive flashcards for thousands of other subjects by searching our market.  And if you'd like to make your own flashcards, you can feel free to click the + sign in the left column of your dashboard, and go nuts!  Brainscape makes it easy to collaborate with others and/or just to manage your own knowledge in any way you choose.</p>
          <p className="overview-line">However you choose to use this app, Brainscape hopes to help you <strong>Rise to Your Challenge</strong>.</p>
        </div>

        {this.renderBrainscapeVideo()}
      </div>
    );
  }

  renderBrainscapeVideo() {
    if (this.props.hasVideo) {
      return null;
    }

    return (
      <VideoEmbed
        shouldAutoPlay={false}
        videoUrl="https://www.youtube.com/embed/mAxlGrrEsYs"
      />
    );
  }
      

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

}

PackAboutBrainscapeSegment.propTypes = PT;

export default PackAboutBrainscapeSegment;
