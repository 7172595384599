import React from 'react';

const ErrorField = (props) => {
  if (!props.children) { return null; }

  return (
    <div className='error-field'>
      <div className='alert-error'>{props.children}</div>
    </div>
  );
}

ErrorField.propTypes = {};

export default ErrorField;
