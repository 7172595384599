
import Bubble                         from '_views/shared/Bubble';
import BrowserCompatibilityModal      from '_views/modals/BrowserCompatibilityModal';
import Checkpoint                     from './Checkpoint';
import EventManager                   from '@brainscape/event-manager';
import GaHelper                       from '_utils/GaHelper'
import PropTypes                      from 'prop-types';
import React                          from 'react';
import StudyBody                      from './StudyBody';
import StudyHelper                    from '_utils/StudyHelper';
import StudyModalControllers          from './StudyModalControllers';
import StudySidebar                   from './StudySidebar';
import Tracker                        from '_utils/Tracker';
import UiHelper                       from '_utils/UiHelper';
import userLocalStore                 from '_models/userLocalStore';

// top z-level view controllers
import DetailLoadingOverlayController       from '_controllers/DetailLoadingOverlayController';
import FtseController                       from '_controllers/FtseController';
import ToastController                      from '_controllers/ToastController';
import TooltipController                    from '_controllers/TooltipController';

import { toClassStr }                 from '_utils/UiHelper';


const PT = {
  addClasses:                 PropTypes.string,
  checkpointType:             PropTypes.string,
  currentCard:                PropTypes.object,
  currentCardFace:            PropTypes.string,
  currentCardId:              PropTypes.node,
  currentCardLevel:           PropTypes.number,
  currentDeck:                PropTypes.object,
  currentDeckId:              PropTypes.node,
  currentPack:                PropTypes.object,
  currentPackId:              PropTypes.node,
  currentRoundStats:          PropTypes.object,
  currentSessionStats:        PropTypes.object,
  currentStepIndex:           PropTypes.number,
  currentUser:                PropTypes.object,
  currentUserId:              PropTypes.node,
  estimatedTimeLeft:          PropTypes.number,
  isAtCheckpoint:             PropTypes.bool,
  // isAudioMuted:               PropTypes.bool,
  isFtse:                     PropTypes.bool,
  isFtue:                     PropTypes.bool,
  isMobileViewportSize:       PropTypes.bool,
  isLoadingCardDisplayData:   PropTypes.bool,
  isLoadingStudySession:      PropTypes.bool,
  roundLength:                PropTypes.number,
  totalTimeStudied:           PropTypes.number,
};


const BUILD_MASTERY_DURATION = 1200;



class StudyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.manageStudySessionLoadingOverlay();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoadingStudySession != this.props.isLoadingStudySession) {
      this.manageStudySessionLoadingOverlay();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */


  render() {
    const classes = toClassStr(['study-page', this.props.addClasses,]);

    if (this.props.isMobileViewportSize) {
      UiHelper.hideZendeskWidget();
    }

    return (
      <div className={classes}>
        <TooltipController />
        <ToastController />
        <DetailLoadingOverlayController />
        <FtseController
          currentCardFace={this.props.currentCardFace}
          currentCard={this.props.currentCard}
          currentRoundStats={this.props.currentRoundStats}
          isFtse={this.props.isFtse}
        />

        <div className="page-shell">
          {this.renderStudySidebar()}
          {this.renderStudyBody()}
          {this.renderCheckpointBubble()}
        </div>

        <StudyModalControllers currentUser={this.props.currentUser} />
      </div>
    );
  }

  renderStudySidebar() {
    const roundStats = this.props.currentRoundStats;
    const sessionStats = this.props.currentSessionStats;

    return (
      <StudySidebar
        buildMasteryDuration={BUILD_MASTERY_DURATION}
        checkpointType={this.props.checkpointType}
        currentCard={this.props.currentCard}
        currentCardLevel={this.props.currentCardLevel}
        currentDeck={this.props.currentDeck}
        currentPack={this.props.currentPack}
        currentRoundStats={this.props.currentRoundStats}
        currentSessionStats={this.props.currentSessionStats}
        currentStepIndex={this.props.currentStepIndex}
        currentUser={this.props.currentUser}
        estimatedTimeLeft={this.props.estimatedTimeLeft}
        isAtCheckpoint={this.props.isAtCheckpoint}
        isAudioMuted={this.props.isAtCheckpoint}
        isFtse={this.props.isFtse}
        isFtue={this.props.isFtue}
        isMixRestricted={this.isMixRestricted()}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isProcessingEstimatedTimeLeft={this.props.estimatedTimeLeft}
        isUserPro={this.props.currentUser.flags.isPro}
        mixCardCount={roundStats.cardCount}
        mixCardsStudied={roundStats.cardRated}
        mixDeckCount={sessionStats.deckCount}
        mixMastery={sessionStats.masteryRatio}
        mixRatingLevelCounts={roundStats.levelCounts}
        mixRoundCount={sessionStats.roundsCompleted}
        roundElapsedTime={roundStats.roundElapsedTime}
        roundLength={this.props.roundLength}
        totalTimeStudied={this.props.totalTimeStudied}
      />
    );
  }

  renderStudyBody() {
    if (this.props.isLoadingStudySession) {
      return null;
    }
    
    return (
      <StudyBody
        addClasses={""}
        currentCard={this.props.currentCard}
        currentCardFace={this.props.currentCardFace}
        currentCardId={this.props.currentCardId}
        currentCardLevel={this.props.currentCardLevel}
        currentDeck={this.props.currentDeck}
        currentDeckId={this.props.currentDeckId}
        currentPack={this.props.currentPack}
        currentPackId={this.props.currentPackId}
        currentStepIndex={this.props.currentStepIndex}
        currentUser={this.props.currentUser}
        isAtCheckpoint={this.props.isAtCheckpoint}
        isAudioMuted={this.props.isAtCheckpoint}
        isFtse={this.props.isFtse}
        isLoadingCardDisplayData={this.props.isLoadingCardDisplayData}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  }

  renderCheckpointBubble() {
    if (!(this.props.isAtCheckpoint && this.props.checkpointType == 'fullScreen')) {
      return null;
    }

    const classes = toClassStr(['checkpoint-bubble', this.props.addClasses]);
    const roundStats = this.props.currentRoundStats;
    const sessionStats = this.props.currentSessionStats;

    return (
      <Bubble
        addClasses={classes}
        expansionDelay={500}
        isEscapeKeyActive={true}
        isScriptedClose={true}
        onClose={() => this.finishCloseCheckpoint()}
        onCloseRequest={() => this.handleNextRoundRequest()}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCloseCheckpoint}
        shouldContract={true}
        shouldExpand={true}
        size="full"
      >
        <Checkpoint
          buildMasteryDuration={BUILD_MASTERY_DURATION}
          checkpointType={this.props.checkpointType}
          currentUser={this.props.currentUser}
          estimatedTimeLeft={this.props.estimatedTimeLeft}
          isMixRestricted={this.isMixRestricted()}
          isMobileViewportSize={this.props.isMobileViewportSize}
          isProcessingEstimatedTimeLeft={this.props.estimatedTimeLeft}
          isUserPro={this.props.currentUser.flags.isPro}
          mixCardCount={roundStats.cardCount}
          mixCardsStudied={roundStats.cardRated}
          mixDeckCount={sessionStats.deckCount}
          mixMastery={sessionStats.masteryRatio}
          mixRatingLevelCounts={roundStats.levelCounts}
          mixRoundCount={sessionStats.roundsCompleted}
          roundElapsedTime={roundStats.roundElapsedTime}
          timeStudied={this.props.totalTimeStudied}
        />
      </Bubble>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */


  triggerDetailLoadingOverlayClose = () => {
    EventManager.emitEvent('detail-loading-overlay:close', {});
  }

  triggerDetailLoadingOverlayOpen = (message) => {
    EventManager.emitEvent('detail-loading-overlay:open', {message: message});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  isMixRestricted = () => {
    const pack = this.props.currentPack;
    const user = this.props.currentUser;

    if (user.flags.isPro) {
      return false;
    }

    if (pack.permission == 'study' && pack.discoveryMedium == 'discovered') {
      return true;
    }

    return false;
  }

  manageStudySessionLoadingOverlay = () => {
    if (this.props.isLoadingStudySession) {
      this.triggerDetailLoadingOverlayOpen('Loading Study Session...');
    } else {
      this.triggerDetailLoadingOverlayClose();
    }
  }
}

StudyPage.propTypes = PT;

export default StudyPage;
