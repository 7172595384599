
const LocalStorageHelper = {

  clear: () => {
    localStorage.clear();
  },

  getItem: (itemKey) => {
    let item = null;

    try {
      const itemStr = localStorage.getItem(itemKey);

      if (itemStr) {
        item = JSON.parse(itemStr);
      }

    } catch(err) {
      console.log('Something went wrong reading from local storage. err:', err);
      return item;
    }

    return item;
  },

  setItem: (itemKey, itemValue) => {
    try {
      localStorage.setItem(itemKey, JSON.stringify(itemValue));
      
    } catch(err) {
      console.log('Something went wrong writing to local storage. err:', err);
      return false;
    }

    return true;
  },

  removeItem: (itemKey) => {
    localStorage.removeItem(itemKey);
  },
}

export default LocalStorageHelper;
