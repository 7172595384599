
import EventManager       from '@brainscape/event-manager';
import PropTypes          from 'prop-types';
import React              from 'react';
import Tooltip            from '_views/shared/Tooltip';
import UiHelper           from '_utils/UiHelper';

const PT = {  
  content:                PropTypes.node,
  elem:                   PropTypes.object,
  position:               PropTypes.string,
};

class TooltipController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDebugMode: false,
      isTooltipOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();

    this.cancelElem = null;

    this.closeTimeout = null;
    this.stowawayTimeout = null;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.clearTimeoutsAndIntervals();

    this.events.addListener('tooltip:open', this.handleOpenRequest);
    this.events.addListener('tooltip:close', this.handleCloseRequest);

    if (this.props.viewProps) {
      this.handleOpenRequest(this.props.viewProps);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.viewProps && this.props.viewProps) {
      this.handleOpenRequest(this.props.viewProps);
    }

    if (!prevProps.shouldClose && this.props.shouldClose) {
      this.handleCloseRequest();
    }
  }

  componentWillUnmount() {
    this.events.disable();
    this.clearTimeoutsAndIntervals();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;

    if (!viewProps) { 
      return null; 
    }

    return (
      <Tooltip
        addClasses={viewProps.addClasses}
        content={viewProps.content}
        isOpen={this.state.isTooltipOpen}
        elem={viewProps.elem}
        onCloseRequest={this.handleCloseRequest}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        position={viewProps.position}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseRequest = (eventData) => {
    this.closeElem = this.openElem;

    if (this.state.isDebugMode) {
      return false;
    }

    if (eventData?.shouldCloseImmediately) {
      this.setState({
        isHoveringTooltip: false,
        isTooltipOpen: false,
      }, () => {
        this.waitAndRemove();
      });

      return true;
    }

    this.closeTimeout = setTimeout(() => {
      if (this.state.isHoveringTooltip) {
        return false;
      }

      this.setState({
        isHoveringTooltip: false,
        isTooltipOpen: false,
      }, () => {
        this.waitAndRemove();
      });
    }, 500);
  }

  handleMouseEnter = () => {
    this.setState({
      isHoveringTooltip: true,
    });
  }

  handleMouseLeave = () => {
    this.setState({
      isHoveringTooltip: false,
    }, () => {
      this.handleCloseRequest();
    });
  }  

  handleOpenRequest = (data) => {
    this.openElem = data.elem;
    clearTimeout(this.stowawayTimeout);
    clearTimeout(this.closeTimeout);
    clearTimeout(this.openTimeout);

    this.openTimeout = setTimeout(() => {
      if (this.openElem && this.openElem == this.closeElem) {
        this.openElem = null;
        this.closeElem = null;

        return false;
      }

      this.openElem = null;
      this.closeElem = null;

      this.setState({
        isHoveringTooltip: false,
        isTooltipOpen: true,
        viewProps: data,
      });
    }, 300);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.closeTimeout);
    clearTimeout(this.stowawayTimeout);
    clearTimeout(this.openTimeout);
  }

  waitAndRemove = () => {
    clearTimeout(this.stowawayTimeout);

    this.stowawayTimeout = setTimeout(() => {
      if (!this.state.isTooltipOpen) {
        this.setState({
          viewProps: null,
        });
      }
    }, 300);
  }

}

TooltipController.propTypes = PT;

export default TooltipController;
