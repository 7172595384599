
import CopyAndPlaceCardsModalSet    from '_views/modals/CopyAndPlaceCardsModalSet';
import EventManager                 from '@brainscape/event-manager';
import React                        from 'react';

class CopyAndPlaceCardsModalSetController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('copy-and-place-cards-modal-set:open', this.handleOpenRequest);
    this.events.addListener('copy-and-place-cards-modal-set:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    return (
      <CopyAndPlaceCardsModalSet
        addClasses={viewProps.addClasses}
        cardIds={viewProps.cardIds}
        contentType={viewProps.contentType}
        deckId={viewProps.deckId}
        isOpen={this.state.isModalOpen}
        isProcessing={viewProps.isProcessing}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        packId={viewProps.packId}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  handleConfirmationResolution = () => {
    if (this.state.viewProps && this.state.viewProps.onResolution) {
      this.state.viewProps.onResolution();
    }
  }

  handleOpenRequest = (data) => {
    console.log('in CopyAndPlaceCardsModalSetController.handleOpenRequest. data:', data);
    this.setState({
      isModalOpen: true,
      viewProps: data,
    });
  }
}


export default CopyAndPlaceCardsModalSetController;
