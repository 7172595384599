
import AccountBrainscapeLabsFeaturesList  from '_account/AccountBrainscapeLabsFeaturesList';
import AccountProfileInfoForm             from '_account/AccountProfileInfoForm';
import AccountNotificationsList           from '_account/AccountNotificationsList';
import AccountSettingsForm                from '_account/AccountSettingsForm';
import AccountSidebar                     from '_account/AccountSidebar';
import EventManager                       from '@brainscape/event-manager';
import PropTypes                          from 'prop-types';
import React                              from 'react';
import ToastController                    from '_controllers/ToastController';
        
import { toClassStr }                     from '_utils/UiHelper';

const PT = {
  addClasses:                         PropTypes.string,
  authenticityToken:                  PropTypes.string,
  currentDetailId:                    PropTypes.string,
  currentUser:                        PropTypes.object,
  editedAccountSettings:              PropTypes.object,
  editedUserProfile:                  PropTypes.object,
  isEditingAccountSettings:           PropTypes.bool,
  isEditingUserProfile:               PropTypes.bool,
  isLoadingUserInfo:                  PropTypes.bool,
  onAccountSettingsFieldChange:       PropTypes.func,
  onAccountSettingsFormSubmit:        PropTypes.func,
  onDetailChangeRequest:              PropTypes.func,
  onUserProfileFieldChange:           PropTypes.func,
  onUserProfileFormSubmit:            PropTypes.func,
  onResetAccountSettingsFormRequest:  PropTypes.func,
  onResetUserProfileFormRequest:      PropTypes.func,
  stripeKey:                          PropTypes.string,
};

class AccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.manageDetailOverlay();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoadingUserInfo != this.props.isLoadingUserInfo) {
      this.manageDetailOverlay();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['page-shell', this.props.addClasses]);

    return (
      <div className={classes}>
        <ToastController />
        {this.renderAccountSidebar()}
        {this.renderAccountDetail()}
      </div>
    );
  }

  renderAccountSidebar() {
    return (
      <AccountSidebar
        currentDetailId={this.props.currentDetailId}
        currentUser={this.props.currentUser}
        isLoadingUserInfo={this.props.isLoadingUserInfo}
        onDetailChangeRequest={this.props.onDetailChangeRequest}
      />
    );
  }

  renderAccountDetail() {
    if (this.props.isLoadingUserInfo) {
      return null;
    }

    switch (this.props.currentDetailId) {
      case 'profile-info':
        return this.renderProfileInfoDetail();
      break;
      case 'account-settings':
        return this.renderAccountSettingsDetail();
      break;
      // case 'brainscape-labs':
      //   return this.renderBrainscapeLabsDetail();
      // break;
      // case 'notifications':
      //   return this.renderNotificationsDetail();
      // break;
      default:
        return this.renderProfileInfoDetail();
    }
  }

  renderProfileInfoDetail() {
    return (
      <div className='account-detail profile-info'>
        <h4 className='section-heading'>Profile Info</h4>
        <AccountProfileInfoForm 
          currentUser={this.props.currentUser}
          editedUserProfile={this.props.editedUserProfile}
          isEditingUserProfile={this.props.isEditingUserProfile}
          onFieldChange={this.props.onUserProfileFieldChange}
          onFormSubmit={this.props.onUserProfileFormSubmit}
          onResetFormRequest={this.props.onResetUserProfileFormRequest}
        />
      </div>
    );
  }

  renderAccountSettingsDetail() {
    return (
      <div className='account-detail account-settings'>
        <h4 className='section-heading'>Account Settings</h4>
        <AccountSettingsForm 
          authenticityToken={this.props.authenticityToken}
          currentUser={this.props.currentUser}
          editedAccountSettings={this.props.editedAccountSettings}
          isEditingAccountSettings={this.props.isEditingAccountSettings}
          isMobileViewportSize={this.props.isMobileViewportSize}
          onFieldChange={this.props.onAccountSettingsFieldChange}
          onFormSubmit={this.props.onAccountSettingsFormSubmit}
          onResetFormRequest={this.props.onResetAccountSettingsFormRequest}
          stripeKey={this.props.stripeKey}
        />
      </div>
    );
  }

  // renderBrainscapeLabsDetail() {
  //   return (
  //     <div className='account-detail brainscape-labs'>
  //       <h4 className='section-heading'>Brainscape Labs</h4>
  //       <AccountBrainscapeLabsFeaturesList 
  //         currentUser={this.props.currentUser}
  //       />
  //     </div>
  //   );
  // }

  // renderNotificationsDetail() {
  //   return (
  //     <div className='account-detail notifications'>
  //       <h4 className='section-heading'>Notifications</h4>
  //       <AccountNotificationsList 
  //         currentUser={this.props.currentUser}
  //       />
  //     </div>
  //   );
  // }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */



  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerDetailLoadingOverlayClose = () => {
    EventManager.emitEvent('detail-loading-overlay:close', {});
  }

  triggerDetailLoadingOverlayOpen = () => {
    EventManager.emitEvent('detail-loading-overlay:open', {message: 'Loading Account Details...'})
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  manageDetailOverlay = () => {
    if (this.props.isLoadingUserInfo) {
      this.triggerDetailLoadingOverlayOpen();
    } else {
      this.triggerDetailLoadingOverlayClose();
    }
  }
}

AccountPage.propTypes = PT;

export default AccountPage;
