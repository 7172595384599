
import Model from '../core/Model';

class SubscriptionModel extends Model {
  constructor(props) {
    super(props);

    this.memberName = 'Subscription';
    super.generateNomenclature();

    this.schema = {
      id: {key: 'id', type: 'text', label: 'Id', tag: 'id'},
      planName: {key: 'planName', type: 'text', label: 'Plan Name', tag: 'plan-name'},
      startDate: {key: 'startDate', type: 'text', label: 'Start Date', tag: 'start-date'},
      endDate: {key: 'endDate', type: 'text', label: 'Renewal or End Date', tag: 'end-date'},
      cancelDate: {key: 'cancelDate', type: 'text', label: 'Canceled On', tag: 'cancel-date'}
    }

    this.nameKey = "planName";
  }

  update(opts) {
    let url = this.buildUrl(this.getResourceUrlBase() + '/update.json', opts.params);

    return $.ajax({
      type: 'PUT',
      url: url,
      data: JSON.stringify(opts.reqData),
      contentType: "application/json"
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }

  delete(opts) {
    let url = this.buildUrl(this.getResourceUrlBase() + '/cancel.json', opts.params);

    return $.ajax({
      type: 'DELETE',
      url: url,
      data: JSON.stringify(opts.reqData),
      contentType: "application/json"
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }

}

export default SubscriptionModel;
