
import LibraryModel              from '_legacy-models/LibraryModel';
import Modal                  from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import SimpleTextButton          from '_views/shared/SimpleTextButton';

import {toClassStr} from '_utils/UiHelper';

class ResetLocalPrefsSidebarOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWarningModalOpen: false,
      isAcknowledgementModalOpen: false,
      isProcessingReset: false,
      postModalCallback: () => {},
    };

    /*
      this.props:
        addClasses,
        userId,
    */

    this.library = new LibraryModel;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['reset-local-prefs-sidebar-option account-section-item', this.props.addClasses]);

    return (
      <li 
        className={classes}
        onClick={(e) => this.handleSidebarOptionClick(e)}
      >
        Reset Local Preferences

        {this.renderWarningModal()}
        {this.renderAcknowledgementModal()}
      </li>
    );
  }

  renderWarningModal() {
    return (
      <Modal
        addClasses="warning-modal"
        isOpen={this.state.isWarningModalOpen}
        onClosed={() => this.state.postModalCallback()}
        onCloseRequest={() => this.handleCloseWarningModalRequest()}
      >

        <div className="modal-title">Reset Local Preferences</div>

        <div className="modal-message">This action will clear your application settings (selected tabs, study type preferences, checked decks, etc) for this device and this device only.</div>
        <div className="modal-message">Using it may clear up some user interface inconsistencies.</div>
        <div className="modal-message">WARNING: if you share this device with other Brainscape users, their settings may be reset as well.</div>

        <div className="modal-actions">

          <PillButton
            addClasses="cancel-modal-button"
            label="Cancel"
            onClick={() => this.handleCancelButtonClick()}
          />
          
          <SimpleTextButton
            addClasses="resolve-modal-text-button"
            isProcessing={this.state.isProcessingReset}
            label="Yes, please reset my local preferences"
            onClick={() => this.handleConfirmButtonClick()}
          />
        </div>
      </Modal>
    );
  }

  renderAcknowledgementModal() {
    return (
      <Modal
        addClasses="ackowledgement-modal"
        isOpen={this.state.isAcknowledgementModalOpen}
        onCloseRequest={() => this.handleCloseAcknowledgementModalRequest()}
      >

        <div className="modal-title">Local Preferences Reset</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={() => this.handleAcknowledgementButtonClick()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAcknowledgementButtonClick() {
    this.setState({
      isAcknowledgementModalOpen: false,
    });
  }

  handleCancelButtonClick() {
    this.setState({
      isWarningModalOpen: false,
      postModalCallback: () => {},
    });
  }

  handleCloseAcknowledgementModalRequest() {
    this.setState({
      isAcknowledgementModalOpen: false,
    });
  }

  handleCloseWarningModalRequest() {
    this.setState({
      isWarningModalOpen: false,
    });
  }

  handleConfirmButtonClick() {
    this.setState({
      isProcessingReset: true,
    }, () => {
      this.resetLocalPreferences();   
    });
  }

  handleOpenAcknowledgementModalRequest() {
    this.setState({
      isAcknowledgementModalOpen: true,
    });
  }

  handleSidebarOptionClick(e) {
    e.stopPropagation();

    this.setState({
      isWarningModalOpen: true,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  resetLocalPreferences() {
    this.library.resetUserLibrary(this.props.userId);

    // on callback
    this.setState({
      isProcessingReset: false,
      isWarningModalOpen: false,
      postModalCallback: () => this.handleOpenAcknowledgementModalRequest(),
    });
  }
}

export default ResetLocalPrefsSidebarOption;
