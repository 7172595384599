
import Files                          from 'react-files';
import React                          from 'react';
import SimpleProgressBar              from '_views/shared/SimpleProgressBar';

import {toClassStr} from '_utils/UiHelper';

class FileDropZoneField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      isInvalidFileInput: false,
      selectedFileName: '',
      selectedFilePreviewUrl: null,
    };

    /*
      this.props:
        addClasses,
        accepts,
        canAcceptMultiple,
        id,
        label,  // now displayed as 'leftCaption'
        leftCaption
        maxFiles,
        onFileSelectionError,
        onFileSelected,
        prompt,
        rightCaption,
        shouldSuppressErrorDisplay,
    */

    // NOTE: This component does not serve as a true controlled form field. The component determines the field input value and reports it to the parent component via data passed to the onFileSelected event handler

    this.suppressFileChangeEvent = false;
    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const hasSelectedFileClass = this.state.selectedFileName ? 'has-selected-file' : '';
    const hasSelectedFilePreviewClass = this.state.selectedFilePreviewUrl ? 'has-preview-image' : '';
    const isInvalidClass = this.state.hasInvalidInput ? 'is-invalid' : '';
    const classes = toClassStr(['file-drop-zone-field', hasSelectedFileClass, hasSelectedFilePreviewClass, isInvalidClass, this.props.addClasses]);
    const prompt = this.props.prompt || 'Drop files here or click to upload';
    const dropAreaLabel = (this.state.selectedFileName != '' && !this.state.hasInvalidFileInput) ? this.state.selectedFileName : prompt;
    const maxFiles = this.props.maxFiles || 1;

    return (
      <div className={classes}>

        <Files
          accepts={this.props.accepts}
          className='drop-area'
          clickable={true}
          dropActiveClassName="active"
          maxFiles={this.props.maxFiles + 1}
          maxFileSize={10000000}
          minFileSize={0}
          multiple={this.props.canAcceptMultiple}
          onChange={this.handleFileChange}
          onError={this.handleFileError}
        >
          {this.renderPreviewImage()}
          <div className="drop-area-label">{dropAreaLabel}</div>
        </Files>

        <div className='upload-info'>
          {this.renderFieldCaption()}
        </div>

        {this.renderErrorMessage()}
      </div>
    );
  }

  renderErrorMessage() {
    if (!this.state.isInvalidFileInput || this.props.shouldSuppressErrorDisplay) {
      return null;
    }

    return (
      <div className='error-message'>
        {this.state.errorMessage}
      </div>
    );
  }

  renderFieldCaption() {
    const leftCaption = this.props.leftCaption || this.props.label || null;
    const rightCaption = this.props.rightCaption || null;

    if (!(leftCaption || rightCaption)) {
      return null;
    }

    return (
      <div className="field-caption">
        <div className="left-field-caption">{leftCaption}</div> 
        <div className="right-field-caption">{rightCaption}</div>
      </div>
    );
  }

  renderPreviewImage() {
    if (!this.state.selectedFilePreviewUrl) {
      return null;
    }

    return (
      <img className="preview-image" src={this.state.selectedFilePreviewUrl} />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleFileChange = (fileData) => {
    const maxSize = this.props.maxSize || 1;
    let filePreviewUrl = null;

    if (this.suppressFileChangeEvent || this.state.hasInvalidInput || !fileData || fileData.length == 0) {
      return false;
    }

    if (fileData.length > maxSize) {
      fileData.shift(); // removes the least recently selected file to allow room for the new file
    }

    if (fileData[0].preview && fileData[0].preview.type == 'image') {
      filePreviewUrl = fileData[0].preview.url;
    }

    this.setState({
      errorMessage: '',
      isInvalidFileInput: false,
      selectedFileName: fileData[0].name,
      selectedFilePreviewUrl: filePreviewUrl,
    }, () => {
      if (this.props.onFileSelected) {
        this.props.onFileSelected(fileData[0]);
      }
    });

  }

  handleFileError = (error, file) => {
    this.suppressFileChangeEvent = true; // necessary because Files package fires change event even if error event fires

    this.setState({
      errorMessage: error.message,
      isInvalidFileInput: true,
      selectedFileName: '',
      selectedFilePreviewUrl: null,
    }, () => {
      if (this.props.onFileSelectionError) {
        this.props.onFileSelectionError(error);
      }
      this.suppressFileChangeEvent = false;
    });
  }
}

export default FileDropZoneField;
