
import model from '_core/newModel';

const deckPosition = {

  //  DEPRECATED IN FAVOR OF packDeckReordering.js

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  update(packId, deckId, position) {
    const positionData = {
      position: position,
    };

    return model.update(`packs/${packId}/decks/${deckId}/positions`, positionData).then((data) => model.publish('deck-position:updated', data));
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckPosition;
