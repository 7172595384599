
import EventManager from '@brainscape/event-manager';
import React from 'react';

import { toClassStr } from '_utils/UiHelper';

class ProgressMeter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    /*
      this.props:
        addClasses,
        isAtCheckpoint,
        isMobileViewportSize,
        preRoundStepRatings,
        stepCount,
        stepIndex,
        stepRatings // array of confidence level ratings with from 0 to stepCount entries
    */

    this._isMounted = false;
    this.progressMeter = null;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let classes = toClassStr(['progress-meter', this.props.addClasses]);
    let progressSteps = [];

    for (let i = 0; i < this.props.stepCount; i++) {
      let stepRating = this.props.stepRatings[i] || 0;
      progressSteps.push(this.renderProgressStep(stepRating, i));
    }

    return (
      <ul
        className={classes}
        onMouseEnter={this.handleProgressMeterMouseEnter}
        onMouseLeave={this.handleProgressMeterMouseLeave}
        ref={elem => this.progressMeter = elem}
      >
        {progressSteps}
      </ul>
    );
  }

  renderProgressStep(stepRating, index) {
    const isCurrentStep = ((index == this.props.stepIndex) && !this.props.isAtCheckpoint) ? 'is-current-step' : '';
    const wasLastRated = (index == this.props.stepIndex - 1) ? 'was-last-rated' : '';
    const confidenceLevel = `confidence-${stepRating?.level || 0}`;

    const classes = toClassStr(['progress-step', confidenceLevel, isCurrentStep, wasLastRated,]);
    let deltaLabel;

     if (wasLastRated) {
       const delta = stepRating?.confidenceGained || 0;
       deltaLabel = (delta >= 0) ? `+${delta}` : `${delta}`;
     } else {
       deltaLabel = null;
     }

    return (
      <li className={classes} key={index}>
        {this.renderDot(stepRating?.level || 0, isCurrentStep)}

        <div className="rating-number">
          <div className="digit">{deltaLabel}</div>
        </div>
      </li>
    );
  }

  renderDot(level, isCurrentStep) {
    let name = 'dot' + level + (isCurrentStep && !this.props.isAtCheckpoint ? 'Active' : '');
    let classes = toClassStr(['dot', name]);

    return <div className={classes}></div>;
  }

  renderTooltipMessage() {
    if (this.props.isAtCheckpoint) {
      return;
    }

    return (
      <div className="rich-content-tooltip">
        <div className="rich-content-tooltip-body">
          <p className="body-text">
            You've currently studied {this.props.stepIndex} out of{' '} {this.props.stepCount} cards this round.
          </p>
          {this.renderCardsLeftMessage()}
        </div>
      </div>
    );
  }

  renderCardsLeftMessage() {
    if (this.props.stepCount - this.props.stepIndex <= 6) {
      return (
        <p className="body-text">
          Only {this.props.stepCount - this.props.stepIndex} cards left!
        </p>
      );
    }

    return (
      <p className="body-text">Keep going, you're just getting started!</p>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleProgressMeterMouseEnter = (e) => {
    const tooltipPlace = this.props.isMobileViewportSize ? 'bottom' : 'right';
    const tooltipContent = this.renderTooltipMessage();
    const tooltipPosition = tooltipPlace;

    this.triggerTooltipOpen({
      content: tooltipContent,
      elem: this.progressMeter,
      position: tooltipPosition,
    });
  };

  handleProgressMeterMouseLeave = () => {
    this.triggerTooltipClose();
  };

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };
}

export default ProgressMeter;
