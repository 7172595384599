
import EventManager     from '@brainscape/event-manager';
import PropTypes        from 'prop-types';
import React            from 'react';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses:           PropTypes.string,
  isThreeState:         PropTypes.bool,
  isChecked:            PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  isDisabled:           PropTypes.bool,
  state:                PropTypes.string,  // 'all', 'none', or 'mixed'
  name:                 PropTypes.string,
  onBlur:               PropTypes.func,
  onClick:              PropTypes.func,
  onFocus:              PropTypes.func,
  onKeyDown:            PropTypes.func,
  prompt:               PropTypes.string,
  tabIndex:             PropTypes.node,
  tooltipContent:       PropTypes.node,
  tooltipPosition:      PropTypes.string,
  value:                PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
};


class RoundCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasFocus: false,
    };

    this.roundCheckbox = null;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const disabledClass = (this.props.isDisabled) ? 'is-disabled' : '';
    const focusClass = (this.state.hasFocus) ? 'has-focus' : '';
    let checkClass;

    if (this.props.isThreeState) {
      checkClass = this.getThreeStateCheckedClass();
    } else {
      checkClass = (this.props.isChecked) ? 'is-checked' : 'is-not-checked';
    }

    let classes = toClassStr(['round-checkbox', checkClass, disabledClass, focusClass, this.props.addClasses]);

    return (
      <div
        className={classes}
        onBlur={this.handleBlur}
        onClick={this.handleClick}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        tabIndex={this.props.tabIndex}
        ref={(elem) => {this.roundCheckbox = elem}}
      >
        <div className="checkbox-control" />
        {this.renderPrompt()}
        {this.renderInputField()}
      </div>
    );
  }

  renderPrompt() {
    if (!this.props.prompt) {
      return null;
    }

    return (
      <div className="checkbox-prompt">
        {this.props.prompt}
      </div>
    );
  }

  renderInputField() {
    if (!(this.props.name || this.props.value)) {
      return null;
    }

    return (
      <input
        className="checkbox-value"
        id={this.props.name}
        name={this.props.name}
        type="hidden"
        value={this.props.value}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBlur = (e) => {
    this.setState({
      hasFocus: false,
    });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleClick = (e) => {
    e.stopPropagation();

    if (!this.props.isDisabled) {
      this.props.onClick(e);
    }
  }

  handleFocus = (e) => {
    this.setState({
      hasFocus: true,
    });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  handleKeyDown = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  handleMouseEnter = (e) => {
    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }
  }

  handleMouseLeave = (e) => {
    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.props.tooltipContent,
      elem: this.roundCheckbox,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  focus = () => {
    if (this.roundCheckbox) { 
      this.roundCheckbox.focus(); 
    }
  }

  getThreeStateCheckedClass() {
    if (this.props.state == 'all') {
      return 'is-checked';
    }
    
    if (this.props.state == 'none') {
      return 'is-not-checked';
    }

    return 'is-mixed';
  }
}

RoundCheckbox.propTypes = PT;

export default RoundCheckbox;
