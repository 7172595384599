import {apiPost}                      from '_core/Api1';
import EventBus                       from '_utils/EventBus';
import EventManager                   from '@brainscape/event-manager';
import PropTypes                      from 'prop-types';
import React, { lazy, Suspense }      from 'react';
import GaHelper                       from '_utils/GaHelper';
import Tracker                        from '_utils/Tracker';
import Spinner                        from '_views/shared/Spinner'

const ErrorModal =          lazy(() => import('_views/modals/ErrorModal'));
const ForgotModal =         lazy(() => import('_registration/ForgotModal'));
const LoginModal =          lazy(() => import('_registration/LoginModal'));
const RegistrationModal =   lazy(() => import('_registration/RegistrationModal'));

/**
 * This manages the Log In and Registration UI.
 */
class RegistrationController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      alert                     : props.alert,
      isForgotModalOpen         : false,
      isLoginModalOpen          : false,
      isRegistrationModalOpen   : false,
      modalProps                : null, 
    };

    this.events = new EventManager();
  }

  render() {
    if (this.state.alert) {
      return (
        <Suspense fallback={<Spinner addClasses='fixed' />}>
          <ErrorModal
            errorMessage={this.state.alert}
            isOpen={true}
            onCloseRequest={this.handleAlertClose}
            title="Registration Error"
          />
        </Suspense>
      );
    }

    const modalProps = this.state.modalProps;
    if (!modalProps) { return null; }

    if (modalProps.method == 'forgot') {
      return (
        <Suspense fallback={<Spinner addClasses='fixed' />}>
          <ForgotModal 
            isOpen={this.state.isForgotModalOpen}
            isProcessing={modalProps.isProcessing}
            onClosed={this.handleClosed}
            onCloseRequest={this.handleCloseRequest}
          />
        </Suspense>
      );

    } else if (modalProps.method === 'login') {
      return (
        <Suspense fallback={<Spinner addClasses='fixed' />}>
          <LoginModal
            authenticityToken={this.props.authenticityToken}
            categoryId={modalProps.categoryId}
            isOpen={this.state.isLoginModalOpen}
            isProcessing={modalProps.isProcessing}
            onCloseRequest={this.handleCloseRequest}
            onClosed={this.handleClosed}
            onSubmit={this.handleSubmit}
            onSwitch={this.handleSwitch}
            packId={modalProps.packId}
            returnTo={modalProps.returnTo}
            source={modalProps.source}
            subject={modalProps.subject}
          />
        </Suspense>
      );

    } else {
      return (
        <Suspense fallback={<Spinner addClasses='fixed' />}>
          <RegistrationModal
            authenticityToken={this.props.authenticityToken}
            categoryId={modalProps.categoryId}
            isOpen={this.state.isRegistrationModalOpen}
            isProcessing={modalProps.isProcessing}
            onClosed={this.handleClosed}
            onCloseRequest={this.handleCloseRequest}
            onSubmit={this.handleSubmit}
            onSwitch={this.handleSwitch}
            packId={modalProps.packId}
            returnTo={modalProps.returnTo}
            source={modalProps.source}
            subject={modalProps.subject}
          />
        </Suspense>
      );
    }
  }

  componentDidMount() {
    window.AppleID.auth.init({
      clientId:    this.props.appleClientId,
      redirectURI: this.props.appleRedirectUri,
      scope:       'name email',
      state:       this.props.appleBsKey,
    });

    this.events.addListener('reg:forgot:open', this.openForgot);
    this.events.addListener('reg:login:open', this.openLogin);
    this.events.addListener('reg:register:open', this.openRegister);

    // Something of a hack to allow legacy code to open registration.
    window.openRegisterModal = (opts) => {
      EventManager.emitEvent('reg:register:open', opts);
    }
  }

  componentWillUnmount() {
    this.events.disable();
  }

  handleAlertClose = () => {
    this.setState({alert: null});
  }

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isForgotModalOpen         : false,
      isLoginModalOpen          : false,
      isRegistrationModalOpen   : false,
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.modalProps.onClosed;

    this.setState({
      modalProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  track_login_initiated = () => {
    gtag('event', 'login_initiated', {
      send_to: GaHelper.ga_4_id(),
    });
  }

  openLogin = (opts) => {
    if (!opts) { opts = {}; }

    const name = this.initFor(opts);
    Tracker.trackModalPageview('signin', name);
    EventBus.publish('web:signin:dialog', {name: name}, true);
    this.track_login_initiated()

    this.setState({
      isLoginModalOpen          : true,
      modalProps                : {...opts, ...{method: 'login'}},
    });
  }

  openRegister = (opts) => {
    if (!opts) { opts = {}; }

    const name = this.initFor(opts);
    Tracker.trackModalPageview('reg', name);
    EventBus.publish('web:reg:dialog', {name: name}, true);

    this.setState({
      isRegistrationModalOpen   : true,
      modalProps                : {...opts, ...{method: 'reg'}},
    });
  }

  // initFor = (opts) => opts.source ? `initiated-${opts.source}` : 'initiated';
  initFor = (opts) => {
    if (opts.resources) {
      let cardId = opts.resources.cardId ? `&cardId=${opts.resources.cardId}` : '';
      
      return `initiated-${opts.source}&source=${opts.source}&packId=${opts.resources.packId}&deckId=${opts.resources.deckId}${cardId}`;
    } else if (opts.source) {
      return `initiated-${opts.source}`
    } else {
      return 'initiated';
    }
  }

  openForgot = (opts) => {
    Tracker.trackModalPageview('forgot', 'initiated');
    EventBus.publish('web:forgot:dialog', {name: 'initiated'}, true);

    if (!opts) { opts = {}; }

    this.setState({
      isForgotModalOpen         : true,
      modalProps                : {...opts, ...{method: 'forgot'}},
    });
  }

  handleSwitch = (method) => {
    switch(method) {
      case 'forgot': return this.openForgot(this.state.modalProps);
      case 'login':  return this.openLogin(this.state.modalProps);
      case 'reg':    return this.openRegister(this.state.modalProps);
    }
  }

  handleSubmit = (provider) => {
    const categoryId = this.state.modalProps.categoryId;
    const packId     = this.state.modalProps.packId;
    const returnTo   = this.state.modalProps.returnTo;
    let   req        = Promise.resolve('OK');
    const source     = this.state.modalProps.source;

    if (categoryId || packId || returnTo || source) {
      const url = 'sessions/pre';
      const pms = {cat_id: categoryId, pack_id: packId, return_to: returnTo, source: source};

      req = req.then(() => apiPost(url, pms)).catch(err => console.error(err));
    }

    req.then(() => this.startAuth(provider)).catch(this.alert);
  }

  startAuth = (provider) => {
    switch (provider) {
      case 'apple':
        AppleID.auth.signIn();
        break;

      case 'facebook':
        this.submitAuthForm('/auth/facebook');
        break;

      case 'google':
        this.submitAuthForm('/auth/google');
        break;
    }
  }

  submitAuthForm = (path) => {
    const f = document.getElementById('login-modal-form');

    if (f) {
      f.setAttribute('action', path);
      f.submit();
    } else {
      console.error('Could not find the login modal form.');
    }
  };

  alert = (msg) => this.setState({modalProps: null, alert: msg});
}
RegistrationController.propTypes = {
  alert:             PropTypes.string,
  appleBsKey:        PropTypes.string,
  appleClientId:     PropTypes.string,
  appleRedirectUri:  PropTypes.string,
  authenticityToken: PropTypes.string,
};

export default RegistrationController;
