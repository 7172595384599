
import ColorHelper                    from '_utils/ColorHelper';
import EventManager                   from '@brainscape/event-manager';
import PersonHelper                   from '_utils/PersonHelper';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import UiHelper                       from '_utils/UiHelper';

import {toClassStr}                   from '_utils/UiHelper';

const GENERIC_AVATAR_FILE_NAME = 'avatar_generic_square';


const PT = {
  addClasses:           PropTypes.string,
  avatarName:           PropTypes.string,
  avatarUrl:            PropTypes.string,
  isMobileViewportSize: PropTypes.bool,
  onClick:              PropTypes.func,
  target:               PropTypes.string,
  tooltipContent:       PropTypes.node,
  tooltipPosition:      PropTypes.string,
};


class UserAvatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this.userAvatarElem = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (this.props.linkUrl) {
      return this.renderLinkAndAvatar();
    }

    return this.renderAvatar();
  }

  renderLinkAndAvatar() {
    const avatarTypeClass = (this.hasSetAvatarImage()) ? 'user-image' : 'user-initials';
    const classes = toClassStr(['user-avatar', avatarTypeClass, this.props.addClasses]);

    return (
      <a 
        className={classes}
        href={this.props.linkUrl}
        onMouseEnter={this.handleUserAvatarMouseEnter}
        onMouseLeave={this.handleUserAvatarMouseLeave}
        ref={(elem) => { this.userAvatarElem = elem }}
        target={this.props.target || null}
      >
        {this.renderAvatarCore()}
      </a>
    );
  }

  renderAvatar() {    
    const avatarTypeClass = (this.hasSetAvatarImage()) ? 'user-image' : 'user-initials';
    const classes = toClassStr(['user-avatar', avatarTypeClass, this.props.addClasses]);

    return (
      <div 
        className={classes}
        onClick={this.handleUserAvatarClick}
        onMouseEnter={this.handleUserAvatarMouseEnter}
        onMouseLeave={this.handleUserAvatarMouseLeave}
        ref={(elem) => { this.userAvatarElem = elem }}
      >
        {this.renderAvatarCore()}
      </div>
    );
  }

  renderAvatarCore() {
    const avatarUrl = this.props.avatarUrl || "";

    if (this.hasSetAvatarImage()) {
      return (
        <img className="user-avatar-image" src={avatarUrl} data-img-kind="avatar"/>
      );
    }

    const initials = this.getUserInitials(this.props.avatarName) || '?';
    const backgroundColor = ColorHelper.getTextColor(initials);
    const elemStyle = {backgroundColor: backgroundColor};

    return (
      <div className="user-avatar-initials" style={elemStyle}>{initials}</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleUserAvatarClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.onClick) {
      this.props.onClick();
      return true;
    }
  }

  handleUserAvatarMouseEnter = (e) => {
    if (!this.props.tooltipContent) {
      return false;
    }

    this.triggerTooltipOpen({
      content: this.props.tooltipContent,
      elem: this.userAvatarElem,
      position: this.props.tooltipPosition || 'right',
    });
  }

  handleUserAvatarMouseLeave = () => {
    this.triggerTooltipClose();
  }

  handleUserPackClick = (sidebarPack) => {
    this.triggerPackDetailViewRequest(sidebarPack.packId);

    if (this.props.isMobileViewportSize) {
      this.props.onHideClassesRequest();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  }

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getUserInitials = (userName) => {
    if (userName) {
      return PersonHelper.getInitials(userName);
    }

    return '?';
  }

  hasSetAvatarImage = () => {
    const avatarUrl = this.props.avatarUrl || "";

    return (avatarUrl && avatarUrl.indexOf(GENERIC_AVATAR_FILE_NAME) == -1)
  }
}

UserAvatar.propTypes = PT;

export default UserAvatar;
