
import DynamicTooltipIcon        from '_views/shared/DynamicTooltipIcon';
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import TextField                 from '_views/shared/TextField';
import StringHelper              from '_utils/StringHelper';

const PT = {      
  addClasses:                    PropTypes.string,
  currentUser:                   PropTypes.object,
  deck:                          PropTypes.object,
  isOpen:                        PropTypes.bool,
  isProcessing:                  PropTypes.bool,
  onCloseRequest:                PropTypes.func,
  onFormSubmit:                  PropTypes.func,
  onOpened:                      PropTypes.func,
  packId:                        PropTypes.node,
};


class EditDeckInfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      hasChanges: false,
      hasValidInput: true,
      deck: this.props.deck,
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.deck.deckId != prevProps.deck.deckId) {
      this.resetFields();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const deck = this.props.deck;

    return (
      <Modal
        addClasses="edit-deck-info-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Edit Deck Info</div>

        <div className="modal-guidance">A Deck is a subset of Flashcards in a Class, similar to chapters in a book</div>

        <form
          className="form edit-deck-info-form"
          onSubmit={this.handleFormSubmit}
        >

          <TextField 
            addClasses="deck-name"
            buttons={this.renderDeckNameInfoIcon()}
            errorMessage={this.state.errors.name}
            hasInitialFocus={true}
            isInvalid={!!this.state.errors.name}
            id="deck-name"
            onChange={(e) => this.handleFieldChange(e, 'name')}
            placeholder="Deck title"
            value={this.state.deck.name}
          />

          <TextField 
            addClasses="deck-objective"
            buttons={this.renderDeckObjectiveInfoIcon()}
            id="deck-objective"
            onChange={(e) => this.handleFieldChange(e, 'desc')}
            placeholder="Deck objective (optional)"
            type={'adjustable-textarea'}
            value={this.state.deck.desc}
          />

          <input type="submit" className="hidden-submit-button" />

          <div className="modal-actions">
            <PillButton
              addClasses="resolve-modal-button"
              isDisabled={!(this.state.hasValidInput && this.state.hasChanges)}
              isProcessing={this.props.isProcessing}
              label="Save"
              onClick={() => this.handleSaveButtonClick()}
            />
          </div>
        </form>
      </Modal>
    );
  }

  renderDeckNameInfoIcon() {
    return (
      <DynamicTooltipIcon 
        body="e.g. Chapter 1, Cellular Metabolism, Everyday phrases, etc"
        iconType="info"
        isSimpleTooltip={true}
        position="top"
      />
    );
  }

  renderDeckObjectiveInfoIcon() {
    return (
      <DynamicTooltipIcon 
        body="A short description of the topics covered in this deck"
        iconType="info"
        isSimpleTooltip={true}
        position="top"
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleSaveButtonClick = () => {
    this.handleFormSubmit();
  }

  handleFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.state.hasValidInput && !this.props.isProcessing) {
      this.props.onFormSubmit(this.state.deck);
    }
  }

  handleFieldChange = (e, fieldKey) => {
    const fieldValue = e.target.value;
    const deck = {...this.state.deck};

    deck[fieldKey] = fieldValue;

    this.setState({
      deck: deck,
    }, () => {
      this.validate();
    });
  }
  

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  hasChanges = () => {
    const oldDeck = this.props.deck;
    const newDeck = this.state.deck;

    return (oldDeck != newDeck);
  }

  resetFields = () => {
    this.setState({
      deck: this.props.deck,
    }, () => {
      this.validate();
    });
  }

  validate = () => {
    let errors = {...this.state.errors};

    if (StringHelper.isBlank(this.state.deck.name)) {
      errors.name = 'Deck Name cannot be blank';
    } else {
      errors = {};
    }

    this.setState({
      errors: errors,
      hasChanges: this.hasChanges(),
      hasValidInput: Object.keys(errors).length == 0,
    });
  }
}

EditDeckInfoModal.propTypes = PT;

export default EditDeckInfoModal;
