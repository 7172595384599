
import AccountOptionsButton     from '_account/AccountOptionsButton';
import BruceButton              from '_views/shared/BruceButton';
import EventManager             from '@brainscape/event-manager';
import PropTypes                from 'prop-types';
import React                    from 'react';
import Spinner                  from '_views/shared/Spinner';
import UiHelper                 from '_utils/UiHelper';
import UserAvatar               from '_views/shared/UserAvatar';

import { toClassStr }           from '_utils/UiHelper';

const PT = {
  addClasses:           PropTypes.string,
  currentDetailId:      PropTypes.string,
  currentUser:          PropTypes.object,
  isLoadingUserInfo:    PropTypes.bool,
};

const MENU_OPTIONS = [
  {
    id: 'profile-info', 
    label: 'profile info',
  },
  {
    id: 'account-settings', 
    label: 'account settings', 
  },
  {
    id: 'view-profile', 
    label: 'view public profile', 
  },
  // {
  //   id: 'brainscape-labs', 
  //   label: 'brainscape labs', 
  // },
  // {
  //   id: 'notifications', 
  //   label: 'notifications', 
  // },
];

class AccountSidebar extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['account-sidebar', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderSidebarUser()}
        {this.renderSidebarMenu()}
      </div>
    );
  }

  renderSidebarUser() {    
    return (
      <div className="sidebar-header">

        <div className='controls'>
          <BruceButton 
            backArrow={true}
            onClick={this.handleBruceButtonClick}
            tooltipContent="Back to dashboard"
            tooltipPosition="right"
          />
          <AccountOptionsButton 
            openPosition="bottomLeft"
            shouldButtonTurn={true}
            tooltipContent="Manage account, log out"
            tooltipPosition={'left'}
          />
        </div>

        {this.renderUserInfo()}
      </div>
    );
  }

  renderSidebarMenu() {    
    const listItems = MENU_OPTIONS.map(({id, label}) => {
      const isSelectedClass = (this.props.currentDetailId == id) ? 'is-selected' : '';
      const classes = toClassStr(['list-item', isSelectedClass]);

      return (
        <li 
          className={classes}
          key={id} 
          onClick={() => this.handleDetailChangeRequest(id)} 
        >
          {label}
        </li>
      );
    });

    return (
      <div className="sidebar-account-menu">
      
        <h4 className="section-heading">Account overview</h4>

        <ul className='list-items'>
          {listItems}
        </ul>
      </div>
    );
  }

  renderUserInfo() {
    if (this.props.isLoadingUserInfo) {
      return <div className='user-avatar-and-name'>{this.renderSpinner()}</div>;
    }

    const userProfile = this.props.currentUser.profile;

    return (
      <div className='user-avatar-and-name'>
      
        <UserAvatar 
          addClasses="account-user-avatar"
          avatarName={userProfile.fullName}
          avatarUrl={userProfile.avatarUrl}
          linkUrl={`/profiles/${userProfile.profileId}`}
          target="profile"
          tooltipContent={`${userProfile.firstName}'s Profile`}
          tooltipPosition="right"
        />

        <h3 className="user-name" title={userProfile.firstName}>
          Hi, {userProfile.firstName}!
        </h3>
      </div>
    )
  }

  renderSpinner() {
    return <Spinner />;
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBruceButtonClick = () => {
    UiHelper.navigate('/l/dashboard', "Opening Dashboard");
  }

  handleDetailChangeRequest = (detailId) => {
    if (detailId == 'view-profile') {
      UiHelper.openInNewTab(`/profiles/${this.props.currentUser.profile.profileId}`, 'profile');
      return true;
    }

    this.props.onDetailChangeRequest(detailId);
  }

  handleUserAvatarClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    UiHelper.openInNewTab(`/profiles/${this.props.currentUser.profile.profileId}`, 'profile');
  }

}

AccountSidebar.propTypes = PT;

export default AccountSidebar;
