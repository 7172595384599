
import Modal                     from '_views/shared/Modal';
import React                     from 'react';
import {BackButton}              from '_views/shared/IconButton';

import {toClassStr}              from '_utils/UiHelper';

class SplitModal extends React.Component {
  constructor(props) {
    super(props);

    }


    /*
    ==================================================
     LIFECYCLE METHODS
    ==================================================
    */


    /*
    ==================================================
     RENDERERS
    ==================================================
    */

  render() {
    let classes = toClassStr(['split-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >
        {this.renderBackButton()}
        {this.props.children}

      </Modal>
    );
  }

  renderBackButton() {
    if (this.props.isSecondPanelVisible == 1) {
      return (
        <BackButton
          addClasses='payment-details-back-button'
          onClick={() => this.props.onBackButtonClick()}
        />
      );
    }
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = () => {
    this.props.onCloseRequest();
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}


export default SplitModal;
