
import React from 'react';
import Modal from '_views/shared/Modal';

import { toClassStr } from '_utils/UiHelper';

class FlashcardMakerLinkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }

    /*
      this.props:
        addClasses,
        isOpen,
        onCloseRequest,
    */
  }

  render() {
    const classes = toClassStr(['flashcard-maker-link-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={this.props.onCloseRequest}
        size="medium"
      >

        <div className="modal-title">Register or log in to access</div>
        <div className="modal-message">
          <p>You need to be logged in to see a user's profile and their flashcards.</p>
        </div>

      </Modal>
    );
  }
};

export default FlashcardMakerLinkModal;
