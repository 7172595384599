
import model from '_core/newModel';
import {waitForJob} from '_core/Api2';


const packDuplicate = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId) {
    return new Promise((resolve, reject) => {
      try {
        const path = `packs/${packId}/duplicates`;

        model.create(path).then(data => {
          const jobData = {
            jobId: data?.job?.id || null,
            packId: packId,
          };

          model.publish('pack-duplicate:job-created', jobData);

          this.monitorPackDuplicateJob(jobData);

          resolve(jobData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error('something went wrong during pack duplicate job create. err:', err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  monitorPackDuplicateJob(jobData) {
    if (jobData.jobId == -1) {
      // job has already been performed

      model.publish('pack-duplicate:job-completed', jobData);
      return true;
    }

    waitForJob(jobData).then(() => {
      model.publish('pack-duplicate:job-completed', jobData);
    });
  },
};

export default packDuplicate;
