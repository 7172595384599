
import React                          from 'react';

import {toClassStr} from '_utils/UiHelper';

class SimpleProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        barValue,
        baseValue,
        isLight,
        onClick,
        valueTitle,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isLightClass = this.props.isLight ? 'is-light' : '';
    const classes = toClassStr(['simple-progress-bar', isLightClass, this.props.addClasses]);
    const barPercentage = (this.props.barValue / this.props.baseValue) * 100;

    return (
      <div className={classes} onClick={(e) => this.handleBarClick(e)}>
        <div className="base-bar">
          <div 
            className="value-bar"
            style={{width: barPercentage + '%'}}
            title={this.props.valueTitle}
          />
        </div>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBarClick(e) {
    if (this.props.onClick) {
      
      if (e) {
        e.stopPropagation();
      }

      this.props.onClick();
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */
}

export default SimpleProgressBar;
