
import model from '_core/newModel';

const PATH = 'me/passwords';

const KEY_MAP = {
  currentPassword:        {serverKey: 'current_password'},
  password:               {serverKey: 'password'},
  passwordConfirmation:   {serverKey: 'password_confirmation'},
}

const userPassword = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  update(data) {
    const reqData = this.mapInputData(data);
    return model.update(PATH, reqData);
  },

  /*
  ==================================================
   OTHER CORE METHODS
  ==================================================
  */

  validate(data) {
    const {currentPassword, password, passwordConfirmation} = data;

    if (currentPassword && StringHelper.isBlank(currentPassword)) {
      return {
        isValid: false,
        errors: {
          currentPassword: "Current Password can't be blank",
        },
      };
    }

    if (password) {
      const rating = this.ratePassword(password);

      if (rating.passwordQuality < 10) {
        return {
          isValid: false,
          errors: {
            password: rating.ratingMessage,
          },
        }
      }
    }

    if (passwordConfirmation) {

      if (StringHelper.isBlank(currentPassword)) {
        return {
          isValid: false,
          errors: {
            currentPassword: "Current Password can't be blank",
          },
        };
      }

      if (StringHelper.isBlank(password)) {
        return {
          isValid: false,
          errors: {
            password: "New Password can't be blank",
          },
        };
      }

      if (StringHelper.isBlank(passwordConfirmation)) {
        return {
          isValid: false,
          errors: {
            passwordConfirmation: "Password Confirmation can't be blank",
          },
        };
      }

      if (password != passwordConfirmation) {
        return {
          isValid: false,
          errors: {
            password: "Passwords don't match",
            passwordConfirmation: "Passwords don't match",
          },
        };
      }
    }

    if (currentPassword && password && passwordConfirmation) {
      return {
        isValid: true,
      };
    }

    return {
      isValid: false,
      errors: {
        form: 'Form data incomplete or invalid',
      },
    }
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  mapInputData(data) {
    const clientKeys = Object.keys(data);
    const user = {};

    clientKeys.forEach(key => {
      const field = KEY_MAP[key];

      if (field) {
        user[KEY_MAP[key].serverKey] = data[key];
      }
    });

    return {
      user: user,
    }
  },

  ratePassword(password) {
    // when all conditions are met, quality will add up to 10

    let quality = Math.min(7, password.length);
    let message;

    if (password.length < 8) {
      quality = Math.max(0, quality - 1);
      message = 'Password requires at least 8 characters.'
    }

    if (password.length > 128) {
      quality = Math.max(0, quality - 1);
      message = message || 'Password can not be more than 128 characters.';
    }

    if (password !== password.toUpperCase()) {
      quality = quality + 1;
    } else {
      message = message || 'Password does not contain a lower case letter.'
    }

    if (password !== password.toLowerCase()) {
      quality = quality + 1;
    } else {
      message = message || 'Password does not contain an upper case letter.'
    }

    if (password.match(/\d+/g)) {
      quality = quality + 1;
    } else {
      message = message || 'Password does not contain a number.'
    }

    if (password.search(/[^a-zA-Z0-9!#%+:=? @]/) !== -1) {
      quality = Math.max(0, quality - 1);
      message = 'Password contains an unsupported character. Supported special characters include !#%+:=?@ and [space].';
    }

    return {passwordQuality: quality, ratingMessage: message};
  },
};

export default userPassword;
