
import EventManager         from '@brainscape/event-manager';
import Modal                from '_views/shared/Modal';
import PillButton           from '_views/shared/PillButton';
import PropTypes            from 'prop-types';
import React                from 'react';
import SimpleTextButton     from '_views/shared/SimpleTextButton';
import shuffleAnimation     from '_images/shared/spaced_repetition.gif'

import {toClassStr}         from '_utils/UiHelper';

const PT = {  
  addClasses:               PropTypes.string,
  confidenceLevel:          PropTypes.string,
  isOpen:                   PropTypes.bool,
  message:                  PropTypes.string,
  media:                    PropTypes.string,
  onCloseRequest:           PropTypes.func,
  onResolution:             PropTypes.func,
  resolveButtonText:        PropTypes.string,
  title:                    PropTypes.string,
};

class SpacedRepetitionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    this._isMounted = false;

    // this.SHUFFLE_ANIMATION_URL = "/assets/cms/public-views/shared/spaced_repetition.gif";
    this.SHUFFLE_ANIMATION_URL = shuffleAnimation;
  }

  confidenceLevelContentMap = {
    'level-1': { title: 1, message: 'repeat very soon' },
    'level-2': { title: 2, message: 'repeat pretty soon' },
    'level-3': { title: 3, message: 'repeat in a while' },
    'level-4': { title: 4, message: 'repeat in a few days' },
    'level-5': { title: 5, message: 'hardly ever repeat' },
    default: { title: 'Spaced Repetition', message: 'repeat optimally' },
  };

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const confidenceLevelClass = this.props.confidenceLevel;
    const classes = toClassStr(['spaced-repetition-modal', confidenceLevelClass, this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        {this.renderTitle()}
        {this.renderMessage()}
        {this.renderMedia()}

        <div className="modal-actions">

          <PillButton
            addClasses="pill-button-emphasized resolve-modal-button register-button" 
            label={this.renderResolveButtonLabel()}
            onClick={this.handleResolveButtonClick}
          />

          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="Cancel"
            onClick={this.props.onCloseRequest}
          />
        </div>
      </Modal>
    );
  }

  renderTitle() {
    const { confidenceLevel, title = "Spaced Repetition" } = this.props;
    const { title: titleContent } = this.confidenceLevelContentMap[confidenceLevel] || this.confidenceLevelContentMap.default;

    return (
      <div className="modal-title">
        {confidenceLevel ? (
          <>You Rated This Card a <div className='confidence-level-icon'>{titleContent}</div></>
        ) : (
          {title}
        )}
      </div>
    );
  }

  renderMessage() {
    const { confidenceLevel, message = "The secret to learning more while studying less." } = this.props;
    const { message: messageContent } = this.confidenceLevelContentMap[confidenceLevel] || this.confidenceLevelContentMap.default;

    return (
      <div className="modal-message"> {confidenceLevel ? `Learn faster with Spaced Repetition in Brainscape. If you keep studying these flashcards, this card will ${messageContent}.` : message} </div>
    );
  }

  renderMedia() {
    return (
      <div className="modal-media">
        <img
          src={this.SHUFFLE_ANIMATION_URL}
          alt="Brainscape Confidence Based Repetition animation"
        />
      </div>
    );
  }

  renderResolveButtonLabel() {
    if (this.props.resolveButtonText) {
      return this.props.resolveButtonText;
    }

    return "Continue Studying";
  }

  handleResolveButtonClick = () => {
    if (this.props.onResolution) {
      this.props.onCloseRequest();
      const data = {
        categoryId: this.props.categoryId,
        packId:     this.props.packId,
        returnTo:   this.props.returnTo,
        source:     this.props.source,
        subject:    this.props.subject,
      };
      EventManager.emitEvent('reg:register:open', data);
    } else {
      this.props.onCloseRequest();
    }
  }
}

SpacedRepetitionModal.propTypes = PT;

export default SpacedRepetitionModal;
