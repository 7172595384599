
import model from '_core/newModel';

const BASE_PATH = 'me/stats';

const KEY_MAP = {}

const userStat = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index() {
    return model.index(BASE_PATH);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default userStat;
