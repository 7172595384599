
import model from '_core/newModel';

const deckCategory = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  index(packId, deckId) {
    const path = `packs/${packId}/decks/${deckId}/categories`;

    return model.index(path);
  },

  update(packId, deckId, categoryIds) {
    const path = `packs/${packId}/decks/${deckId}/categories`;
    const data = {
      categoryIds: categoryIds,
    };

    return model.update(path, data).then(() => model.publish('deck-categories:updated', {
      packId: packId,
      deckId: deckId,
      categoryIds: categoryIds,
    }));
  },

  destroy(packId, deckId, categoryIds) {    
    const path = `packs/${packId}/decks/${deckId}/categories`;
    const data = {
      categoryIds: categoryIds,
    };

    return model.destroy(path, data).then(() => model.publish('deck-categories:removed', {
      packId: packId,
      deckId: deckId,
      categoryIds: categoryIds,
    }));
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckCategory;
