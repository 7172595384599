
import Modal            from '_views/shared/Modal';
import PillButton       from '_views/shared/PillButton';
import React            from 'react';
import RoundCheckbox    from '_views/shared/RoundCheckbox';
import UiHelper         from '_utils/UiHelper';
import userLocalStore   from '_models/userLocalStore';

import {toClassStr} from '_utils/UiHelper';

class BrowserCompatibilityModal extends React.Component {
  constructor(props) {
    super(props);

    // this.Library = new LibraryModel;

    this.state = {
      isOpen: this.props.show || false,
      isIgnored: false
    };

    /* 
      this.props: 
        addClasses
        show (boolean),
        onClose,
        userId
    */
  }

  componentDidMount() {
    if (UiHelper.isInternetExplorer()) {
      const isIgnored = this.isWarningIgnored();
      this.setState({
        isOpen: !isIgnored,
        isIgnored: isIgnored
      });
    }
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    let classes = toClassStr(['browser-compatibility-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.state.isOpen}
        onCloseRequest={() => this.handleClose()}
      >

        <div className="modal-title">Browser Compatibility Info</div>

        <div className="modal-message">Welcome to Brainscape. You appear to be using Internet Explorer.</div>
        <div className="modal-message">A standards compliant browser such as Chrome, Safari, Firefox, or Edge is required for the full Brainscape experience.</div>

        <h5 className="links-heading">Useful links:</h5>

        <ul className="modal-list">
          <li className="modal-list-item"><a className="browser-link chrome" href="https://www.google.com/chrome" target="_blank" rel="nofollow noopener noreferrer"><span className="link-label">Google Chrome</span></a></li>
          <li className="modal-list-item"><a className="browser-link safari" href="https://www.apple.com/safari/" target="_blank" rel="nofollow noopener noreferrer"><span className="link-label">Safari for Mac</span></a></li>
          <li className="modal-list-item"><a className="browser-link firefox" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="nofollow noopener noreferrer"><span className="link-label">Mozilla Firefox</span></a></li>
          <li className="modal-list-item"><a className="browser-link edge" href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank" rel="nofollow noopener noreferrer"><span className="link-label">Microsoft Edge</span></a></li>
        </ul>

        <RoundCheckbox
          addClasses="ignore-warning"
          isChecked={this.state.isIgnored}
          onClick={(e) => this.handleIgnoreCheckboxClick(e)}
          prompt="Don't remind me again"
        />

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={(e) => this.handleClose(e)}
          />
        </div>
      </Modal>
    );
  }

  handleClose(e) {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      isOpen: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  }

  handleIgnoreCheckboxClick(e) {
    e.stopPropagation();

    const isIgnored = this.state.isIgnored;
    this.setState({
      isIgnored: !isIgnored
    }, () => {
      userLocalStore.updateUserLocalPrefs(this.props.userId, 'shouldIgnoreIeUsage', this.state.isIgnored);
    });
  }

  isWarningIgnored() {
    const isIgnored = !!userLocalStore.getUserLocalPref(this.props.userId, 'shouldIgnoreIeUsage');

    return isIgnored;
  }
}

export default BrowserCompatibilityModal;
