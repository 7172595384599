
import EventBus     from '../utils/EventBus';
import EventManager from '@brainscape/event-manager';

import 'url-search-params-polyfill';

// Exported: Standard methods to work with APIv2 endpoints that are JSON and
//           use the `respond_to_error` error handling.
const apiDelete   = (path, params={}, headers={}) => apiAjax(path, 'DELETE', params, headers);
const apiGet      = (path, params={}, headers={}) => apiAjax(path, 'GET',    params, headers);
const apiPatch    = (path, params={}, headers={}) => apiAjax(path, 'PATCH',  params, headers);
const apiPost     = (path, params={}, headers={}) => apiAjax(path, 'POST',   params, headers);
const apiPut      = (path, params={}, headers={}) => apiAjax(path, 'PUT',    params, headers);

const apiPostFile = (path, file, ct)  => apiFile(path, 'POST',   file, ct);

// Exported: Standard methods to construct api paths for APIv2.
const cardPath = (packId, deckId, cardId, part=null) => {
  let path = `packs/${packId}/decks/${deckId}/cards/${cardId}`;
  if (part) { path += `/${part}`; }

  return path;
};

// Exported: Standard methods to construct api paths for APIv2.
const deckPath = (packId, deckId, part=null) => {
  let path = `packs/${packId}/decks/${deckId}`;
  if (part) { path += `/${part}`; }

  return path;
};

// Exported: Handles a standard error message that is displayed to the user.
const apiError = (error) => {
  const opts = {msg: 'Unknown Error (code: 433)'};

  if (error instanceof Error) {
    opts.code = error.code;
    opts.msg  = error.message;
    opts.url  = error.url;
  }

  EventBus.publish('error:msg', opts, true);
  EventManager.emitEvent('error-modal:open', {
    errorMessage: `There was an error contacting the server: ${opts.msg}.`,
  });
};

// Convenience method to reduce duplication.
const apiAjax = (path, method, params, headers={}) => {
  let   url  = `/api/v2/${path}`;
  const requestHeaders = {...{'Content-Type': 'application/json'}, ...headers};
  const opts = {method: method, headers: requestHeaders};

  if (params) {
    if (method === 'DELETE' || method === 'GET' || method === 'HEAD') {
      const sp = new URLSearchParams();

      Object.keys(params).forEach((key) => sp.append(key, params[key]));
      url += `?${sp.toString()}`.replace(/\?$/, '');
    } else {
      opts.body = JSON.stringify(params);
    }
  }

  return fetch(url, opts).then(checkJsonResponse);
};

// Convenience method to reduce duplication.
const apiFile = (path, method, file, ct) => {
  let   url  = `/api/v2/${path}`;
  const opts = {method: method, body: file, headers: {'Content-Type': ct}};

  return fetch(url, opts).then(checkJsonResponse).catch(err => {
    console.error(err);
  });
};

const checkJsonResponse = (rs) => {
  if (rs.ok) { return rs.status === 204 ? {} : rs.json(); }

  const ct = rs.headers.get('content-type');

  if (ct.indexOf('json') >= 0) {
    return rs.json().then((data) => {
      throwError({error: data.error, errors: data.errors}, rs);
    });
  }

  if (ct.indexOf('html') >= 0) {
    //throwError({error: 'Unknown Error (code: 741)'}, rs);
    return rs.text().then((data) => throwError({error: `Unknown Error (code: 741): ${data}`}, rs));
  }

  return rs.text().then((data) => { throwError({error: data}, rs); });
};

const throwError = (data, rs=null) => {
  const e = new Error(data.error);

  if (data.errors) { e.errors = data.errors; }

  if (rs) {
    e.url  = rs.url;
    e.code = rs.status;
  }

  throw e;
};

const waitForJob = (rs) => {
  console.log('in Api2.waitForJob. rs:', rs);

  const jobId = rs['jobId'];
  if (!jobId) { throw new Error('could not read the job ID'); }

  const cb = (resolve, reject) => checkJobStatus(jobId, resolve, reject);
  return new Promise(cb);
};

const checkJobStatus = (jobId, resolve, reject, i=0) => {
  if (i >= 20) { throw new Error(`too many job status attempts: ${i}`); }

  const cb = () => apiGet(`jobs/${jobId}`).then((rs) => {
    rs.status ? checkJobStatus(jobId, resolve, reject, i + 1) : resolve();
  }).catch((err) => {
    console.error('Could not check job status: ', err);
    checkJobStatus(jobId, resolve, reject, i + 1);
  });

  setTimeout(cb, delayFor(i));
};


const delayFor = (i) => {
  return [250, 500, 750, 1000, 2000, 4000, 8000, 16000, 32000][i] || 60000;
}

export {
  apiDelete,
  apiError,
  apiGet,
  apiPatch,
  apiPost,
  apiPostFile,
  apiPut,
  cardPath,
  deckPath,
  waitForJob,
};
