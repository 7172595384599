
const SessionStorageHelper = {

  clear() {
    sessionStorage.clear();
  },

  getItem(itemKey) {
    let item = null;

    try {
      const itemStr = sessionStorage.getItem(itemKey);

      if (itemStr) {
        item = JSON.parse(itemStr);
      }

    } catch(err) {
      console.log('Something went wrong reading from session storage. err:', err);
      return item;
    }

    return item;
  },

  setItem(itemKey, itemValue) {
    try {
      sessionStorage.setItem(itemKey, JSON.stringify(itemValue));
      
    } catch(err) {
      console.log('Something went wrong writing to session storage. err:', err);
      return false;
    }

    return true;
  },

  removeItem(itemKey) {
    sessionStorage.removeItem(itemKey);
  },

  getPackItem(packId, itemKey) {
    const item = this.getItem(itemKey);
    return (item?.packId == packId) ? item?.data : null;
  },

  setPackItem(packId, itemKey, itemValue) {
    let packItem = {
      packId: packId, 
      data: itemValue,
    };

    return this.setItem(itemKey, packItem);
  }, 

  getPackDeckItem(packId, deckId, itemKey) {
    const item = this.getItem(itemKey);
    return (item?.packId == packId && item?.deckId == deckId) ? item?.data : null;
  },

  setPackDeckItem(packId, deckId, itemKey, itemValue) {
    let packDeckItem = {
      packId: packId, 
      deckId: deckId, 
      data: itemValue,
    };

    return this.setItem(itemKey, packDeckItem);
  }, 
}

export default SessionStorageHelper;
