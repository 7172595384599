
import {
  EditButton,
}                                     from '_views/shared/IconButton';

import DynamicTooltipIcon                   from '_views/shared/DynamicTooltipIcon';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import UrlHelper                      from '_utils/UrlHelper';
import VideoEmbed                     from '_views/shared/VideoEmbed';
import VideoModalPoster               from '_views/shared/VideoModalPoster';
import VideoPlaceholder               from '_views/shared/VideoPlaceholder';

import {toClassStr}                   from '_utils/UiHelper';

const PT = {
  addClasses:                   PropTypes.string,
  hasVideoCaptionResource:      PropTypes.bool,
  hasVideoUrlResource:          PropTypes.bool,
  isEditingResource:            PropTypes.bool,
  isEditingVideo:               PropTypes.bool,
  isMobileViewportSize:         PropTypes.bool,
  isPackMetadataEditable:       PropTypes.bool,
  isPreviewing:                 PropTypes.bool,
  isProcessing:                 PropTypes.bool,
  metadata:                     PropTypes.object,
  onAddVideoRequest:            PropTypes.func,
  onCancelEditVideoRequest:     PropTypes.func,
  onEditVideoRequest:           PropTypes.func,
  onUpdateVideoRequest:         PropTypes.func,
  segmentId:                    PropTypes.node,         
};


class PackAboutVideoSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoCaption: "",
      videoUrl: "",
    }
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.setResources();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.metadata != this.props.metadata) {
      this.manageResources(prevProps);
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isPlayableClass = (this.getVideoUrl() && !this.props.isEditingVideo) ? 'is-playable' : '';
    const isEditingSegmentClass = this.props.isEditingVideo ? 'is-editing-segment' : '';

    const classes = toClassStr(['segment about-tab-segment pack-about-video-segment', isPlayableClass, isEditingSegmentClass, this.props.addClasses]);

    return (
      <div className={classes}>

        <div className="segment-anchor" id={this.props.segmentId}></div>

        <div className="segment-header about-tab-segment-header video-header">
          <div className="segment-heading about-tab-segment-heading video-heading">Intro Video</div>
          {this.renderVideoActionButtons()}
        </div>

        {this.renderVideoSegmentContent()}
      </div>
    );
  }

  renderVideoSegmentContent() {
    if (this.props.isEditingVideo) {
      return (
        <VideoPlaceholder
          isClickable={!this.props.isEditingResource}
          isEditable={!this.props.isEditingResource}
          isEditing={this.props.isEditingVideo}
          isMobileViewportSize={this.props.isMobileViewportSize}
          isProcessing={this.props.isProcessing}
          onCancel={this.handleCancelEditVideoRequest}
          onCaptionChange={this.handleVideoCaptionChange}
          onPlaceholderClick={this.handleVideoPlaceholderClick}
          onSubmit={this.handleUpdateVideoRequest}
          onUrlChange={this.handleVideoUrlChange}
          shouldShowRemoveButton={this.props.hasVideoUrlResource}
          videoCaption={this.state.videoCaption}
          videoUrl={this.state.videoUrl}
        />
      );
    }

    if (!this.getVideoUrl()) {
      return (
        <div className="add-video-prompt" onClick={this.handleAddVideoPromptClick}>Add a YouTube Video...</div>      
      );
    }

    return this.renderVideoPlayer();
  }

  renderVideoPlayer() {
    const videoProps = UrlHelper.parseYouTubeUrl(this.getVideoUrl());

    if (!videoProps.isValidUrl) {
      return this.renderBadVideoUrlPoster();
    }

    if (videoProps.type == "embed") {
      return (
        <div className="video-and-caption">
          <VideoEmbed
            shouldAutoPlay={false}
            videoUrl={videoProps.url}
          />

          {this.renderVideoCaption()}
        </div>
      );
    }

    return (
      <div className="video-and-caption">

        <VideoModalPoster
          isLink={true}
          posterImageUrl={null}
          shouldAutoPlay={true}
          videoUrl={videoProps.url}
        />

        {this.renderVideoCaption()}
      </div>
    );
  }

  renderVideoCaption() {
    if (this.props.isEditingVideo) {
      return null;
    }

    if (!this.props.hasVideoCaptionResource) {
      return null;
    }

    return (
      <div className="video-caption">
        {this.props.metadata.global.video_caption.value}
      </div>
    );
  }

  renderVideoActionButtons() {
    if (!this.props.isPackMetadataEditable || this.props.isPreviewing) {
      return null;
    }

    return (
      <div className="action-buttons">
        {this.renderVideoTooltip()}
        {this.renderEditVideoButton()}
      </div>
    );
  }

  renderVideoTooltip() {
    if (!this.props.isPackMetadataEditable || this.props.isPreviewing) {
      return null;
    }

    return (
      <DynamicTooltipIcon 
        actions={null}
        addClasses="pack-video-tooltip"
        body="Embed a YouTube video to introduce users to your Class, or to complement the material covered in your Flashcards."
        hasDismissButton={true}
        heading={null}
        position="right"
        subHeading={null}
      />
    );
  }

  renderEditVideoButton() {
    if (!this.getVideoUrl() || !this.props.isPackMetadataEditable || this.props.isEditingResource || this.props.isPreviewing) {
      return null;
    }

    return (
      <EditButton 
        addClasses="edit-video-button"
        onClick={this.handleEditButtonClick}
      />
    );
  }

  renderBadVideoUrlPoster() {
    return null;
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelEditVideoRequest = () => {
    this.setResources();

    if (this.props.onCancelEditVideoRequest) {
      this.props.onCancelEditVideoRequest();
    }
  }

  handleVideoCaptionChange = (videoCaption) => {
    this.setState({
      videoCaption: videoCaption,
    })
  }

  handleVideoUrlChange = (videoUrl) => {
    this.setState({
      videoUrl: videoUrl,
    })
  }

  handleEditButtonClick = () => {
    if (this.props.onEditVideoRequest) {
      this.props.onEditVideoRequest();
    } 
  }

  handleAddVideoPromptClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.onAddVideoRequest) {
      this.props.onAddVideoRequest();
    } 
  }

  handleVideoPlaceholderClick = () => {
    if (this.props.onEditVideoRequest) {
      this.props.onEditVideoRequest();
    } 
  }

  handleUpdateVideoRequest = (videoProps) => {
    if (this.props.onUpdateVideoRequest) {
      this.setState({
        videoUrl: videoProps.url,
        videoCaption: videoProps.caption,
      })
      this.props.onUpdateVideoRequest(videoProps);
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getVideoUrl = () => {
    return (this.hasResource('video_url')) ? this.props.metadata.global.video_url.value : '';
  }

  hasResource = (resourceKey, testProps) => {
    const props = testProps || this.props;

    return !!(props.metadata && props.metadata.global && props.metadata.global[resourceKey] && props.metadata.global[resourceKey].value);
  }

  hasResourceChanged = (resourceKey, prevProps) => {
    if (!(this.hasResource(resourceKey, prevProps) && this.hasResource(resourceKey, this.props))) { 
      return false; 
    }

    const prevResourceValue = prevProps.metadata.global[resourceKey].value;
    const resourceValue = this.props.metadata.global[resourceKey].value;

    return (resourceValue != prevResourceValue);
  }

  manageResources = (prevProps) => {
    if (this.hasResourceChanged('video_url', prevProps)) {
      this.setVideoUrl();
    }

    if (this.hasResourceChanged('video_caption', prevProps)) {
      this.setVideoCaption();
    }
  }

  setVideoUrl = () => {
    const videoUrl = (this.hasResource('video_url')) ? this.props.metadata.global.video_url.value : "";

    this.setState({
      videoUrl: videoUrl,
    })
  }

  setVideoCaption = () => {
    const videoCaption = (this.hasResource('video_caption')) ? this.props.metadata.global.video_caption.value : "";

    this.setState({
      videoCaption: videoCaption,
    })
  }

  setResources = () => {
    this.setVideoUrl();
    this.setVideoCaption();
  }
}

PackAboutVideoSegment.propTypes = PT;

export default PackAboutVideoSegment;
