
import EventManager       from '@brainscape/event-manager';
import React              from 'react';
import Spinner            from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

class StudyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        isDisabled,
        isProcessing,
        label,
        onClick,
        onMouseEnter,
        onMouseLeave,
        title,
        tooltipContent,
        tooltipPosition,
    */

    this.events = new EventManager();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const hasLabelClass = (this.props.label) ? 'has-label' : '';
    const hasTooltipClass = this.props.tooltipContent ? 'has-tooltip' : '';
    const isDisabledClass = this.props.isDisabled ? 'is-disabled' : '';
    let classes = toClassStr(['icon-button', 'study-button', hasLabelClass, hasTooltipClass, isDisabledClass, this.props.addClasses, (this.props.isProcessing) ? 'is-processing' : '']);

    return (
      <div
        className={classes}
        onClick={(e) => this.handleButtonClick(e)}
        onMouseEnter={(e) => this.handleButtonMouseEnter(e)}
        onMouseLeave={(e) => this.handleButtonMouseLeave(e)}
        title={this.props.title}
        ref={(elem) => { this.elem = elem }} 
      >
        <div className="icon-and-spinner">
          <i className="study-button-icon" />
          {this.renderSpinner()}
        </div>

        {this.renderLabel()}
      </div>
    );
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner />
    );
  }

  renderLabel() {
    if (!this.props.label) {
      return null;
    }

    return (
      <div className="label">{this.props.label}</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleButtonMouseEnter(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }

    if (this.props.onMouseEnter) {
      this.props.onMouseEnter();
    }
  }

  handleButtonMouseLeave(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }

    if (this.props.onMouseLeave) {
    this.props.onMouseLeave();
    }
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.props.tooltipContent,
      elem: this.elem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };
}

export default StudyButton;
