
import AccountProfileInfoForm             from '_account/AccountProfileInfoForm';
import AccountSettingsForm                from '_account/AccountSettingsForm';
import MobileAccountMenu                  from '_account/MobileAccountMenu';
import MobileAccountHeader                from '_account/MobileAccountHeader';
import PropTypes                          from 'prop-types';
import React                              from 'react';
import UiHelper                           from '_utils/UiHelper';

import { toClassStr }                     from '_utils/UiHelper';

const PT = {
  addClasses:                         PropTypes.string,
  authenticityToken:                  PropTypes.string,
  currentDetailId:                    PropTypes.string,
  currentUser:                        PropTypes.object,
  editedAccountSettings:              PropTypes.object,
  editedUserProfile:                  PropTypes.object,
  isEditingAccountSettings:           PropTypes.bool,
  isEditingUserProfile:               PropTypes.bool,
  isLoadingUserInfo:                  PropTypes.bool,
  isMobileViewportSize:               PropTypes.bool,
  onAccountSettingsFieldChange:       PropTypes.func,
  onAccountSettingsFormSubmit:        PropTypes.func,
  onDetailChangeRequest:              PropTypes.func,
  onUserProfileFieldChange:           PropTypes.func,
  onUserProfileFormSubmit:            PropTypes.func,
  onResetAccountSettingsFormRequest:  PropTypes.func,
  onResetUserProfileFormRequest:      PropTypes.func,
  stripeKey:                          PropTypes.string,
};

class MobileAccountScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currenScreenContent: '',
    };

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['page-shell', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderMobileAccountHeader()}
        {this.renderMobileAccountScreen()}
      </div>
    );
  }

  renderMobileAccountHeader() {
    return (
      <MobileAccountHeader
        currentUser={this.props.currentUser}
        currentDetailId={this.props.currentDetailId}
        isLoadingUserInfo={this.props.isLoadingUserInfo}
      />
    );
  }

  renderMobileAccountScreen() {
    if (this.props.isLoadingUserInfo) {
      return null;
    }

    switch (this.props.currentDetailId) {
      case 'profile-info':
        return this.renderProfileInfoDetail();
      break;
      case 'account-settings':
        return this.renderAccountSettingsDetail();
      break;
      case 'menu-screen':
        return this.renderMobileAccountMenu();
      break;
      default:
        return this.renderMobileAccountMenu();
    }
  }

  renderProfileInfoDetail() {
    return (
      <div className='mobile-screen-container profile-info'>
        <h4 className='section-heading'>Profile Info</h4>
        <AccountProfileInfoForm 
          currentUser={this.props.currentUser}
          editedUserProfile={this.props.editedUserProfile}
          isEditingUserProfile={this.props.isEditingUserProfile}
          onFieldChange={this.props.onUserProfileFieldChange}
          onFormSubmit={this.props.onUserProfileFormSubmit}
          onResetFormRequest={this.props.onResetUserProfileFormRequest}
        />
      </div>
    );
  }

  renderAccountSettingsDetail() {
    return (
      <div className='mobile-screen-container account-settings'>
        <h4 className='section-heading'>Account Settings</h4>
        <AccountSettingsForm 
          authenticityToken={this.props.authenticityToken}
          currentUser={this.props.currentUser}
          editedAccountSettings={this.props.editedAccountSettings}
          isEditingAccountSettings={this.props.isEditingAccountSettings}
          isMobileViewportSize={this.props.isMobileViewportSize}
          onFieldChange={this.props.onAccountSettingsFieldChange}
          onFormSubmit={this.props.onAccountSettingsFormSubmit}
          onResetFormRequest={this.props.onResetAccountSettingsFormRequest}
          stripeKey={this.props.stripeKey}
        />
      </div>
    );
  }

  renderMobileAccountMenu() {
    return (
      <MobileAccountMenu 
        currentUser={this.props.currentUser}
        onDetailChangeRequest={this.props.onDetailChangeRequest}
      />
    )
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */



  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

MobileAccountScreen.propTypes = PT;

export default MobileAccountScreen;
