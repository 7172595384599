import {createConsumer} from '@rails/actioncable';

let Consumer = null;

const cable = () => {
  if (Consumer) { return Consumer; }

  return Consumer = createConsumer(cableUrl());
};

const cableUrl = () => {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') == 'action-cable-url') {
      return metas[i].getAttribute('content');
    }
  }

  throw new Error('could not find the action cable url');
};

const createSubscription = (channelName, mixin) => {
  return cable().subscriptions.create(channelName, mixin);
};

const removeSubscription = (subscription) => {
  cable().subscriptions.remove(subscription);
}

export {cable, createSubscription, removeSubscription};
