
import EventManager            from '@brainscape/event-manager';

import pack                    from '_models/pack';
import packMetadata            from '_models/packMetadata';
import userLocalStore          from '_models/userLocalStore';


const currentPackMetadataConcern = {

  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch(pack) {
    const currentPackId = pack.packId;
    let currentPack = {...pack};

    return new Promise((resolve, reject) => {
      try {
        packMetadata.show(currentPackId).then(metadata => {
          currentPack.metadata = metadata;
          currentPack.learners = null;
          currentPack.learnerIds = null;
          currentPack.transformedLearnerIds = null;
          currentPack.leaderboard = null;
          currentPack.leaderboardOrder = null;

          packMetadata.setCachedItem(currentPack.packId, currentPack.metadata);

          const currentPackMetadataData = {
            currentPack: currentPack,
          };

          this.publishPackMetadatRetrieved(currentPackMetadataData);
          resolve(currentPackMetadataData);
        }).catch(err => {
          console.error('Problem with packMetadata index. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  // get checks session store for cached objects, publishes immediately to allow display, then refreshes cached copy by conducting fetches against the API
  get(pack) {
    const currentPackId = pack.packId;
    let currentPack = {...pack};

    return new Promise((resolve, reject) => {
      try {
        const currentPackMetadata = packMetadata.getCachedItem(currentPackId);

        if (currentPackMetadata) {
          currentPack.metadata = currentPackMetadata;

          const currentPackMetadataData = {
            currentPack: currentPack
          };

          this.publishPackMetadatRetrieved(currentPackMetadataData);
        }

        this.fetch(currentPack).then(currentPackMetadataData => {
          resolve(currentPackMetadataData);
        }).catch(err => {
          console.error('Problem with packMetadata index. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  handlePackMetadataUpdated(currentPack, eventData) {
    const updatedPack = {...currentPack};
    const updatedPackId = updatedPack.packId;
    updatedPack.metadata = eventData.metadata;

    pack.setCachedItem(updatedPackId, updatedPack);
    packMetadata.setCachedItem(updatedPackId, updatedPack.metadata);

    return {
      currentPack: updatedPack,
    };
  },

  hasRequiredMetadata(userId, packId) {    
    return userLocalStore.getPackMetadataStatus(userId, packId);
  },

  publishPackMetadatRetrieved(currentPackMetadataData) {
    EventManager.emitEvent('pack-metadata:retrieved', currentPackMetadataData);
  },

  setPackMetadataStatus(userId, packId, state) {
    userLocalStore.setPackMetadataStatus(userId, packId, state);
  },
};

export default currentPackMetadataConcern;
