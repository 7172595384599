
import DeckRow              from '_views/pack-detail/desktop/decks/DeckRow';
import EventManager         from '@brainscape/event-manager';
import React                from 'react';
import Sortable             from 'sortablejs';
import StudyHelper          from '_utils/StudyHelper';

import {toClassStr}         from '_utils/UiHelper';
import {
  CircledAddButton,
}                           from '_views/shared/IconButton';


class DeckList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      areItemsDraggable: true,
      isSortable: false,
      shouldPulseAddCardsButton: false,
      shouldPulseCreateDeckButton: false,
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        currentUser,
        hasLegacyStyles,
        isMobileViewportSize,
        onDeckChange,
        onDeckDetailRequest,
        pack,
        selectedDecks,
    */

    this._isMounted = false;

    this.listElement = null;
    this.sortable = null;
    this.sortedIds = null;

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.subscribeToEvents();
    this.initSortable();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isSortable && this.props.isSortable) {
      this.initSortable();
    }
  }

  componentWillUnmount() {
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('ftue:pulse-add-cards-button-request',    this.handlePulseAddCardsButtonRequest);
    this.events.addListener('ftue:pulse-create-deck-button-request',  this.handlePulseCreateDeckButtonRequest);
  } 

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {    
    const pulseAddCardsClass = this.state.shouldPulseAddCardsButton ? 'pulse-add-cards-button' : '';
    const classes = toClassStr(['deck-list', pulseAddCardsClass, this.props.addClasses]);

    return (
      <ul className={classes} ref={(i) => this.listElement = i}>
        {this.renderDeckRows()}
        {this.renderCreateNewDeckRow()}
      </ul>
    );
  }

  renderDeckRows = () => {
    const pack = this.props.pack;
    const currentUser = this.props.currentUser;

    const deckRows = this.props.pack.decks.map((deck, index) => {
      const deckId = deck.deckId;
      const isSelected = (pack.localDeckTransforms.selections?.ids?.indexOf(deckId) != -1);
      const studyMixPath = StudyHelper.getPackDeckStudyMixUrl(currentUser.userId, pack, deckId);
      const canCopyAndPlace = (currentUser.flags.features?.copy_and_place_cards) || false;

      return (
        <DeckRow
          authenticityToken={this.props.authenticityToken}
          canCopyAndPlace={canCopyAndPlace}
          currentUser={this.props.currentUser}
          deck={deck}
          hasLegacyStyles={this.props.hasLegacyStyles}
          isDraggable={this.props.isSortable}
          isMobileViewportSize={this.props.isMobileViewportSize}
          isSelected={isSelected}
          key={`deck-row-${deck.deckId}`}
          onDeckDetailRequest={this.props.onDeckDetailRequest}
          pack={this.props.pack}
          studyMixPath={studyMixPath}
        />
      );
    }); 

    return deckRows; 
  } 

  renderCreateNewDeckRow() {
    if (!this.props.pack.flags.areDecksEditable) {
      return null;
    }

    const needsDecksClass = (this.props.pack.decks.length < 1) ? 'needs-decks' : '';
    const pulseClass = (this.state.shouldPulseCreateDeckButton) ? 'pulse' : ''
    const classes = toClassStr(['deck-row create-new-deck-row', needsDecksClass, pulseClass]); 

    return (
      <li className={classes} onClick={this.handleNewDeckRowClick}>
        <ul className="deck-row-contents">

          <CircledAddButton 
            shouldAllowClickPropagation={true}
          />

          <li className="label-and-bar">
            <div className="new-deck-label">Create New Deck</div>
            <div className="new-deck-progress-bar" />
          </li>

          <li className="action-buttons" />
        </ul>
      </li>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleNewDeckRowClick = () => {
    this.triggerCreateDeckModalOpen()
  }

  handlePulseAddCardsButtonRequest = () => {
    this.setState({
      shouldPulseAddCardsButton: true,
    });
  }

  handlePulseCreateDeckButtonRequest = () => {
    this.setState({
      shouldPulseCreateDeckButton: true,
    });
  }

  handleSortStart = (e) => {
    const src = e.oldIndex;
    this.sortedIds = this.props.pack.deckIds;
  }

  handleSortEnd = (e) => {
    const src = e.oldIndex;
    const dst = e.newIndex;

    // Keep track of the order of the decks as the user moves them around.
    this.sortedIds.splice(dst, 0, this.sortedIds.splice(src, 1)[0]);

    this.triggerPackDecksReorderRequest(this.sortedIds);
  };



  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCreateDeckModalOpen = () => {
    EventManager.emitEvent('create-deck-modal:open', {
      currentUser: this.props.currentUser,
      packId: this.props.pack.packId,
    })
  }

  triggerPackDecksReorderRequest = (sortedIds) => {
    EventManager.emitEvent('pack-decks:reorder-request', {
      packId: this.props.pack.packId,
      reorderedDeckIds: sortedIds,
    });
  }

  triggerUpgradeModalOpen = (viewProps) => {
    EventManager.emitEvent('upgrade-modal:open', viewProps);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  initSortable = () => {    
    if (!this.props.isSortable) {
      return false;
    }

    if (this.listElement) {
      const opts = {
        direction: 'vertical',
        onStart:  this.handleSortStart,
        onEnd:    this.handleSortEnd,
        animation: 150,
        ghostClass: "sortable-ghost",  // Class name for the drop placeholder
        draggable: ".deck-row:not(.create-new-deck-row)",
        delay: 400,
        delayOnTouchOnly: true,
      };

      this.sortable = new Sortable(this.listElement, opts);

      this.setState({
        isSortable: true,
      });
    }
  }
}

export default DeckList;
