
import deckCard     from '_models/deckCard';
import model        from '_core/newModel';

import {waitForJob} from '_core/Api2';

const deckCsvImportJob = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId, fileData) {
    return new Promise((resolve, reject) => {
      try {
        const path = `packs/${packId}/decks/${deckId}/csv/admin`;

        model.upload(path, fileData, 'text/csv').then(data => {
          const jobData = {
            deckId: deckId,
            jobId: data.jobId,
            packId: packId,
          };

          model.publish('deck-csv-import:job-created', jobData);
          this.monitorCsvImportJob(jobData);
          resolve(jobData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error('something went wrong during deck csv import job create. err:', err);
        reject(err);
      }
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  monitorCsvImportJob(jobData) {
    if (jobData.jobId == -1) {
      // job has already been performed

      model.publish('deck-csv-import:job-completed', jobData);
      return true;
    }

    waitForJob(jobData).then(() => {
      model.publish('deck-csv-import:job-completed', jobData);
    });
  },

};

export default deckCsvImportJob;
