
import EventManager               from '@brainscape/event-manager';
import FtueCreateMessaging        from '_views/shared/ftue/FtueCreateMessaging';
import FtueFindMessaging          from '_views/shared/ftue/FtueFindMessaging';
import FtuePromptModal            from '_views/modals/FtuePromptModal';
import FtueStudyMessaging         from '_views/shared/ftue/FtueStudyMessaging';
import PropTypes                  from 'prop-types';
import React                      from 'react';
import UiHelper                   from '_utils/UiHelper';

const PT = {
  addClasses                      : PropTypes.string,
  currentPack                     : PropTypes.object,
  currentUser                     : PropTypes.object,
  currentView                     : PropTypes.string, // 'pack-detail', deck-detail'
  isFtue                          : PropTypes.bool,
  isMobileViewportSize            : PropTypes.bool,
};

class FtueMessaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ftueType                    : null, // create, study, find
      intent                      : this.props.currentUser?.flags?.intent,
      intentMethod                : this.props.currentUser?.flags?.intent ? 'context' : null,
      isFtuePromptModalOpen       : false,
      lastFtueEvent: null,
    }
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this.manageFtueDisplay();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevProps.isFtue && this.props.isFtue) {
      this.manageFtueDisplay();
    }
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.props.isFtue) {
      return null;
    }

    return (
      <div className="ftue-messaging">
        {this.renderFtuePromptModal()}
        {this.renderFtueMessaging()}
      </div>
    );
  }

  renderFtuePromptModal = () => {
    if (this.state.intent) { 
      return null; 
    }

    return (
      <FtuePromptModal
        isOpen={true}
        onCloseRequest={this.handleFtuePromptModalCloseRequest}
        onIntentSelected={this.handleIntentSelected}
      />
    );
  }

  renderFtueMessaging = () => {
    if (!this.state.ftueType) {
      return null;
    }

    switch (this.state.ftueType) {
      case 'create':
        return this.renderFtueCreateMessaging();
      break;
      case 'find':
        return null;
        // TODO: Build a true Find FTUE here
        // return this.renderFtueFindMessaging();
      break;
      case 'study':
        return this.renderFtueStudyMessaging();
      break;
    }
  }

  renderFtueCreateMessaging() {
    return (
      <FtueCreateMessaging
        currentPack={this.props.currentPack}
        currentView={this.props.currentView}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  }

  renderFtueFindMessaging() {
    return (
      <FtueFindMessaging
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  }

  renderFtueStudyMessaging() {
    return (
      <FtueStudyMessaging
        currentPack={this.props.currentPack}
        currentView={this.props.currentView}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  }
  

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleIntentSelected = (intent) => {
    const ftueType = this.getFtueType(intent);

    this.setState({
      ftueType: ftueType,
      intent: intent,
      intentMethod: 'modal',
      isFtuePromptModalOpen: false,
    }, () => {
      if (ftueType == 'find') {
        UiHelper.openInNewTab('/subjects');
      }

      this.publishIntentSelected(intent);
    })
  }

  handleFtuePromptModalCloseRequest = () => {
    const intent = 'study';
    const ftueType = this.getFtueType(intent);

    this.setState({
      ftueType: ftueType,
      intent: intent,
      isFtuePromptModalOpen: false,
    }, () => {
      this.publishIntentSelected(intent);
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishIntentSelected = (intent) => {
    EventManager.emitEvent('current-user:intent-selected', {intent: intent});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  manageFtueDisplay = () => {
    if (!this.state.intent) {
      this.setState({
        isFtuePromptModalOpen: true,
      })
    } else {
      const ftueType = this.getFtueType(this.state.intent);
      this.setState({
        ftueType: ftueType,
      })
    }
  }

  getFtueType = (intent) => {
    let ftueType;

    switch (intent) {
      case 'create':
        ftueType = 'create';
      break;
      case 'teachers':
        ftueType = 'create';
      break;
      case 'companies':
        ftueType = 'create';
      break;
      case 'find':
        ftueType = 'find';
      break;
      case 'study':
        ftueType = 'study';
      break;
      default:
        ftueType = 'study';
    }

    return ftueType;
  }
}

FtueMessaging.propTypes = PT;

export default FtueMessaging;
