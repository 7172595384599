
import AccountOptionsButton   from '_account/AccountOptionsButton';
import LabeledBackButton     from '_views/shared/LabeledBackButton';
import PropTypes              from 'prop-types';
import React                  from 'react';
import Spinner                from '_views/shared/Spinner';
import UiHelper               from '_utils/UiHelper';

import { toClassStr }         from '_utils/UiHelper';

const PT = {
  currentUser         : PropTypes.object,
  isLoadingUserInfo   :PropTypes.bool,
  currentDetailId     :PropTypes.string,
};

class MobileAccountHeader extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const backButtonLabel = this.props.currentDetailId == 'default' ? 'Back to Dashboard' : 'Back to Account Overview';

    return (
      <div className="mobile-header-container mobile-account-header">

        <div className='controls'>
          
          <LabeledBackButton 
            label={backButtonLabel}
            onClick={this.handleBackButtonClick}
          />

          <AccountOptionsButton 
            openPosition='bottomLeft'
            shouldButtonTurn={true}
          />
        </div>

        {this.renderUserInfo()}
      </div>
    );
  }

  renderUserInfo() {
    if (this.props.isLoadingUserInfo) {
      return <div className='user-avatar-and-name'>{this.renderSpinner()}</div>;
    }

    return (
      <div className='user-avatar-and-name'>
        <div className="user-avatar">
          <img className="user-avatar-image" src={this.props.currentUser.profile.avatarUrl} />
        </div>

        <h3 className="user-name" title={this.props.currentUser.profile.firstName}>
          Hi, {this.props.currentUser.profile.firstName}!
        </h3>
      </div>
    )
  }

  renderSpinner() {
    return <Spinner />;
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBackButtonClick = () => {
    const navigateTo = this.props.currentDetailId == 'default' ? '/l/dashboard' : '/l/account';
    UiHelper.navigate(navigateTo, false);
  }
}

MobileAccountHeader.propTypes = PT;

export default MobileAccountHeader;
