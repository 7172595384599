
import EventManager from '@brainscape/event-manager';
import React from 'react';

import { toClassStr } from '_utils/UiHelper';

import needle1 from '_images/study/confidence-meter-needle.svg';

class ConfidenceMeter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPosition: 0,
      newPosition: 0,
    };

    /*
      this.props:
        addClasses,
        confidenceGained,
        isAtCheckpoint,
        isMobileViewportSize,
        minLevel,
        maxLevel,
        roundEndConfidenceGained,
        shouldCompressRoundEndConfidence,
        shouldDimRoundEndConfidence,
        shouldShowRoundEndConfidence,
    */

    this._isMounted = false;

    this.postRatingDelay = null;
    this.rotationInterval = null;
    this.confidenceMeter = null;

    this.MAX_ROTATION = 140; // Position of max rotation of needle in degrees
    this.ROTATION_TICK_DURATION = 50; // time between needle movements in ms
    this.POST_RATING_DELAY = 500; // Amount of time to allow Progress meter animation
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.updateNeedlePosition();
  }

  componentDidUpdate(prevProps) {
    if (this.props.confidenceGained != prevProps.confidenceGained) {
      this.updateNeedlePosition();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const showRoundEndConfidenceClass = this.props.shouldShowRoundEndConfidence
      ? 'show-confidence'
      : '';
    const compressRoundEndConfidenceClass = this.props
      .shouldCompressRoundEndConfidence
      ? 'compress-confidence'
      : '';
    const dimRoundEndConfidenceClass = this.props.shouldDimRoundEndConfidence
      ? 'dim-confidence'
      : '';
    const classes = toClassStr([
      'confidence-meter',
      showRoundEndConfidenceClass,
      compressRoundEndConfidenceClass,
      dimRoundEndConfidenceClass,
      this.props.addClasses,
    ]);

    return (
      <div
        className={classes}
        onMouseEnter={this.handleConfidenceMeterMouseEnter}
        onMouseLeave={this.handleConfidenceMeterMouseLeave}
        ref={(elem) => {
          this.confidenceMeter = elem;
        }}
      >
        <div
          className="needle-housing"
          ref={(elem) => (this.needleHousing = elem)}
        >
          <img className="needle" src={needle1} />
        </div>

        <div className="confidence-data">

          <div className="confidence-gained">
            {this.renderRoundEndConfidenceGained()}
          </div>
          
          <div className="limits">
            <div className="min-level">{this.props.minLevel}</div>
            <div className="max-level">{this.props.maxLevel}</div>
          </div>

          <div className="meter-label">Confidence Gained</div>
        </div>
      </div>
    );
  }

  renderRoundEndConfidenceGained() {
    const sign = this.props.roundEndConfidenceGained >= 0 ? '+' : '';
    return sign + this.props.roundEndConfidenceGained;
  }

  renderConfidenceMeterTooltip() {
    if (this.props.isAtCheckpoint) {
      return null;
    }

    return (
      <div className="rich-content-tooltip">
        <div className="rich-content-tooltip-body">
          <p className="body-text">
            This represents the net change in your confidence levels for this
            round.
          </p>
          <p className="body-text">
            e.g. An increase from a 1 to a 3 would be +2.
          </p>
        </div>
      </div>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleConfidenceMeterMouseEnter = (e) => {
    const tooltipPlace = this.props.isMobileViewportSize ? 'bottom' : 'right';
    const tooltipContent = this.renderConfidenceMeterTooltip();
    const tooltipPosition = tooltipPlace;

    this.triggerTooltipOpen({
      content: tooltipContent,
      elem: this.confidenceMeter,
      position: tooltipPosition,
    });
  };

  handleConfidenceMeterMouseLeave = () => {
    this.triggerTooltipClose();
  };

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  updateNeedlePosition() {
    clearTimeout(this.postRatingDelay);

    this.postRatingDelay = setTimeout(() => {
      clearTimeout(this.postRatingDelay);
      this.moveNeedle();
    }, this.POST_RATING_DELAY);
  }

  moveNeedle() {
    clearInterval(this.rotationInterval);

    const oldPosition = this.state.oldPosition;
    const newPosition = this.props.confidenceGained;
    let position = oldPosition;
    let ticks = 0;
    let direction = newPosition - oldPosition >= 0 ? 1 : -1;
    let rotation = 0;

    this.rotationInterval = setInterval(() => {
      if (ticks >= Math.abs(newPosition - oldPosition)) {
        clearInterval(this.rotationInterval);
        this.setState({
          oldPosition: newPosition,
        });
      } else {
        ticks++;
        position = oldPosition + ticks * direction;
        rotation = (position / 50) * this.MAX_ROTATION;
        this.needleHousing.style.transform = 'rotate(' + rotation + 'deg)';
      }
    }, this.ROTATION_TICK_DURATION);
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearInterval(this.rotationInterval);
    clearTimeout(this.postRatingDelay);
  }
}

export default ConfidenceMeter;
