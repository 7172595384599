
import { BackButton }           from '_views/shared/IconButton';
import BruceButton              from '_views/shared/BruceButton';
import Checkpoint               from '_study/Checkpoint';
import EventManager             from '@brainscape/event-manager';
import LibraryModel             from '_legacy-models/LibraryModel';
import OverallStatsSection      from '_study/OverallStatsSection';
import React                    from 'react';
import StudyMixOptionsButton    from '_study/StudyMixOptionsButton';
import TabSwitcher              from '_views/shared/TabSwitcher';
import ThisRoundStatsSection    from '_study/ThisRoundStatsSection';
import TransitionWrapper        from '_views/shared/TransitionWrapper';
import UiHelper                 from '_utils/UiHelper';
import userLocalStore           from '_models/userLocalStore';

import { toClassStr }           from '_utils/UiHelper';

class StudySidebar extends React.Component {
  constructor(props) {
    super(props);

    // this.Library = new LibraryModel();

    this.state = {
      currentTabId: null,
      isCurrentTabLoading: false,
    };

    /*
      this.props:
        addClasses,
        buildMasteryDuration,
        checkpointType,
        currentUser,
        dashboardPath,
        isAtCheckpoint,
        isContinuousStudyEnabled,
        isMixRestricted,
        isMobileViewportSize,
        isUserMixAdmin,
        isUserPro,
        mixCardCount,
        mixCardsStudied,
        mixDeckCount,
        mixDescription,
        mixFullname,
        mixIconUrl,
        mixMastery,
        mixName,
        mixRatingLevelCounts,
        mixRoundCount,
        mixTimeToComplete,
        mixType,
        onCheckpointClosed,
        onCloseStudyMixRequest,
        onDismissSidebarCheckpoint,
        onNextRoundRequest,
        onSetStudyMixTypeRequest,
        onToggleContinuousStudy,
        roundConfidenceGained,
        roundElapsedTime,
        roundEndConfidenceGained,
        roundStepCount,
        roundStepIndex,
        roundStepRatings,
        sessionMastery,
        sessionRatings,
        sessionRatingLevelCounts,
        shouldCloseCheckpoint,
        shouldCompressRoundEndConfidence,
        shouldDimRoundEndConfidence,
        shouldPulseNextRoundButton,
        shouldShowRoundEndConfidence,
        stepCard,
        userId,
    */

    this._isMounted = false;
    this.bruceLogo = null;

    this.tabs = {
      thisRound: { id: 'thisRound', label: 'This Round' },
      overall: { id: 'overall', label: 'Overall' },
    };
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    if (this.props.userId != null) {
      this.setCurrentTabFromPrefs();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId != prevProps.userId) {
      this.setCurrentTabFromPrefs();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['study-sidebar', this.props.addClasses]);

    return (
      <div className={classes}>
        <header className="study-sidebar-header">
          <div className="first-row">
            <BruceButton 
              backArrow={true}
              onClick={(e) => this.handleBackButtonClick(e)}
              tooltipContent="Back to dashboard"
              tooltipPosition="right"
            />

            <div className="study-mix-info">
              <div className="study-mix-icon" 
                onClick={this.handleStudyMixInfoClick}
                onMouseEnter={this.handleStudyMixInfoMouseEnter}
                onMouseLeave={this.handleStudyMixInfoMouseLeave}
              >
                <img
                  className="study-mix-icon-image"
                  src={this.props.mixIconUrl}
                />
              </div>

              <h3 className="study-mix-name" 
                onClick={this.handleStudyMixInfoClick}
                onMouseEnter={this.handleStudyMixInfoMouseEnter}
                onMouseLeave={this.handleStudyMixInfoMouseLeave}
              >
                {this.props.mixName}
              </h3>
            </div>

            <StudyMixOptionsButton
              isContinuousStudyEnabled={this.props.isContinuousStudyEnabled}
              isDisabled={
                this.props.isAtCheckpoint &&
                this.props.checkpointType == 'fullScreen'
              }
              isMixRestricted={this.props.isMixRestricted}
              isUserPro={this.props.isUserPro}
              mixType={this.props.mixType}
              // onResetMixStats={() => this.props.onResetMixStats()}
              onSetStudyMixTypeRequest={(mixType) =>
                this.props.onSetStudyMixTypeRequest(mixType)
              }
              onToggleContinuousStudy={() =>
                this.props.onToggleContinuousStudy()
              }
              stepCard={this.props.stepCard}
              tooltipContent="Study settings, log out"
              tooltipPosition="bottom"
            />
          </div>

          <div className="second-row">
            <TabSwitcher
              addClasses="is-dark"
              currentTabId={this.state.currentTabId}
              isCurrentTabLoading={this.state.isCurrentTabLoading}
              onTabClick={(tabId) => this.handleTabClick(tabId)}
              tabs={this.tabs}
            />
          </div>
        </header>

        <div className="mobile-study-sidebar-header">
          <div className="mobile-back-button">
            <BackButton onClick={(e) => this.handleBackButtonClick(e)} />
          </div>
        </div>

        <div className="sidebar-sections">
          {this.renderThisRoundStatsSection()}
          {this.renderOverallStatsSection()}
          {this.renderCheckpoint()}
        </div>

        <div className="mobile-study-sidebar-footer">
          <StudyMixOptionsButton
            isContinuousStudyEnabled={this.props.isContinuousStudyEnabled}
            isDisabled={
              this.props.isAtCheckpoint &&
              this.props.checkpointType == 'fullScreen'
            }
            isMixRestricted={this.props.isMixRestricted}
            isMobileViewportSize={true}
            isUserPro={this.props.isUserPro}
            mixType={this.props.mixType}
            onChangeProgressView={(viewId) =>
              this.handleChangeProgressView(viewId)
            }
            // onResetMixStats={() => this.props.onResetMixStats()}
            onSetStudyMixTypeRequest={(studyMixType) =>
              this.handleSetStudyMixTypeRequest(studyMixType)
            }
            onToggleContinuousStudy={() => this.props.onToggleContinuousStudy()}
            progressView={this.state.currentTabId}
            stepCard={this.props.stepCard}
          />
        </div>
      </div>
    );
  }

  renderStudyMixNameTooltip() {
    if (!this.props.mixDecks) {
      return null;
    }

    const deckNames = Object.keys(this.props.mixDecks).map(
      (mixDeckKey, index) => {
        const mixDeck = this.props.mixDecks[mixDeckKey];
        return (
          <p className="tooltip-text" key={index}>
            {mixDeck.name}
          </p>
        );
      },
    );

    return (
      <div className="rich-content-tooltip">
        <div className="tooltip-heading">
          Decks in mix ({this.props.mixDeckCount}):
        </div>
        {deckNames}
      </div>
    );
  }

  renderThisRoundStatsSection() {
    if (this.state.currentTabId != 'thisRound') {
      return null;
    }

    return (
      <ThisRoundStatsSection
        isAtCheckpoint={this.props.isAtCheckpoint}
        isMobileViewportSize={this.props.isMobileViewportSize}
        mixRoundCount={this.props.mixRoundCount}
        onCloseStudyMixRequest={() => this.props.onCloseStudyMixRequest()}
        preRoundStepRatings={this.props.preRoundStepRatings}
        roundConfidenceGained={this.props.roundConfidenceGained}
        roundElapsedTime={this.props.roundElapsedTime}
        roundEndConfidenceGained={this.props.roundEndConfidenceGained}
        roundStepCount={this.props.roundStepCount}
        roundStepIndex={this.props.roundStepIndex}
        roundStepRatings={this.props.roundStepRatings}
        shouldCompressRoundEndConfidence={
          this.props.shouldCompressRoundEndConfidence
        }
        shouldDimRoundEndConfidence={this.props.shouldDimRoundEndConfidence}
        shouldShowRoundEndConfidence={this.props.shouldShowRoundEndConfidence}
      />
    );
  }

  renderOverallStatsSection() {
    if (this.state.currentTabId != 'overall') {
      return null;
    }

    return (
      <OverallStatsSection
        isAtCheckpoint={this.props.isAtCheckpoint}
        isClosingCheckpoint={this.props.shouldCloseCheckpoint}
        isMobileViewportSize={this.props.isMobileViewportSize}
        mixCardCount={this.props.mixCardCount}
        mixMastery={this.props.mixMastery}
        mixRatingLevelCounts={this.props.mixRatingLevelCounts}
        roundStepCount={this.props.roundStepCount}
        roundStepIndex={this.props.roundStepIndex}
      />
    );
  }

  renderCheckpoint() {
    if (
      !this.props.isAtCheckpoint ||
      this.props.checkpointType == 'fullScreen'
    ) {
      return null;
    }

    return (
      <TransitionWrapper
        addClasses="checkpoint-transition-wrapper"
        onTransitionedOut={() => this.props.onCheckpointClosed()}
        shouldTransitionIn={true}
        shouldTransitionOut={this.props.shouldCloseCheckpoint}
        transitionInDelay={100}
        transitionInDuration={500}
        transitionOutDuration={500}
      >
        <Checkpoint
          addClasses="sidebar-checkpoint"
          buildMasteryDuration={250}
          checkpointType={this.props.checkpointType}
          currentUser={this.props.currentUser}
          isContinuousStudyEnabled={this.props.isContinuousStudyEnabled}
          isMixRestricted={this.props.isMixRestricted}
          isMobileViewportSize={this.props.isMobileViewportSize}
          isUserPro={this.props.isUserPro}
          mixCardCount={this.props.mixCardCount}
          mixCardsStudied={this.props.mixCardsStudied}
          mixDeckCount={this.props.mixDeckCount}
          mixDecks={this.props.mixDecks}
          mixMastery={this.props.mixMastery}
          mixName={this.props.mixName}
          mixRatingLevelCounts={this.props.mixRatingLevelCounts}
          mixRoundCount={this.props.mixRoundCount}
          mixTimeToComplete={this.props.mixTimeToComplete}
          onNextRoundRequest={() => this.props.onNextRoundRequest()}
          onCloseStudyMixRequest={() => this.props.onCloseStudyMix()}
          onDismissSidebarCheckpoint={() =>
            this.props.onDismissSidebarCheckpoint()
          }
          onToggleContinuousStudy={() => this.props.onToggleContinuousStudy()}
          roundElapsedTime={this.props.roundElapsedTime}
          sessionElapsedTime={this.props.sessionElapsedTime}
          sessionMastery={this.props.sessionMastery}
          sessionRatings={this.props.sessionRatings}
          sessionRatingLevelCounts={this.props.sessionRatingLevelCounts}
          shouldPulseNextRoundButton={this.props.shouldPulseNextRoundButton}
          stepCard={this.props.stepCard}
          timeStudied={this.props.timeStudied}
        />
      </TransitionWrapper>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBackButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }

    this.props.onCloseStudyMixRequest();
  }

  handleTabClick(tabId) {
    this.setState(
      {
        currentTabId: tabId,
      },
      () => {
        this.updateStudySidebarTabPref(tabId);
        if (this.props.isAtCheckpoint) {
          this.props.onNextRoundRequest();
        }
      },
    );
  }

  handleChangeProgressView(viewId) {
    this.handleTabClick(viewId);
  }

  handleSetStudyMixTypeRequest(studyMixType) {
    console.log('set study mix type here. studyMixType:', studyMixType);
  }

  handleChangeProgressView(viewId) {
    this.handleTabClick(viewId);
  }

  handleTabClick(tabId) {
    this.setState({
      currentTabId: tabId,
    }, () => {
      this.updateStudySidebarTabPref(tabId);
      if (this.props.isAtCheckpoint) {
        this.props.onNextRoundRequest();
      }
    })
  }

  handleSetStudyMixTypeRequest(studyMixType) {
    console.log('set study mix type here. studyMixType:', studyMixType);
  }

  handleStudyMixInfoClick = (e) => {
    e.stopPropagation();

    if (this.props.dashboardPath) {
      UiHelper.navigate(this.props.dashboardPath, false);
    }
  }

  handleStudyMixInfoMouseEnter = (e) => {
    e.stopPropagation();

    const tooltipElem = e.target;

    this.triggerTooltipOpen({
      content: `View ${this.props.mixName} in Dashboard`,
      elem: tooltipElem,
      position: 'bottom',
    });
  };

  handleStudyMixInfoMouseLeave = () => {
    this.triggerTooltipClose();
  };


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  hasMixNameTooltipContent() {
    return (
      (this.props.mixFullname != null &&
        this.props.mixFullname != this.props.mixName) ||
      this.props.mixDescription != null
    );
  }

  updateStudySidebarTabPref(tabId) {
    userLocalStore.updateUserLocalPrefs(
      this.props.userId,
      'studySidebarTabPref',
      tabId,
    );
  }

  getStudySidebarTabPref() {
    return userLocalStore.getUserLocalPref(
      this.props.userId,
      'studySidebarTabPref',
    );
  }

  setCurrentTabFromPrefs() {
    this.setState({
      currentTabId: this.getStudySidebarTabPref() || 'thisRound',
    });
  }
}

export default StudySidebar;
