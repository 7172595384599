
import {LargeCtaButton}         from '_views/shared/PillButton';
import AnimatedCards            from '_views/shared/AnimatedCards';
import Bubble                   from '_views/shared/Bubble';
import BubbleDialog             from '_views/shared/BubbleDialog';
// import ConfidenceLevelButtons   from '_study/ConfidenceLevelButtons';
import EventManager             from '@brainscape/event-manager';
import FtsePreBounceBanner      from '_study/FtsePreBounceBanner';
import PreExitBubble            from '_study/PreExitBubble';
import React                    from 'react';
import Tracker                  from '_utils/Tracker';

import {toClassStr} from '_utils/UiHelper';

class FtseMessaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardLedger                      : null,
      hasHadFirstRepeat               : false,
      hasSeenMidpointBubble           : false,
      shouldCloseCbrBubble            : false,
      shouldCloseIntroBubble          : false,
      shouldMonitorTopRegion          : false,
      shouldShowAnswerPrompts         : false,
      shouldShowCbrBubble             : false,
      shouldShowFirstRepeatBubble     : false,
      shouldShowIntroBubble           : false,
      shouldShowQuestionPrompts       : false,
    };

    /*
      this.props:
        addClasses,
        ftseAnswerCardbarRating,
        ftsePostCbrBubbleAction,
        isAtRoundMidpoint,
        onAnswerCardbarPulseTrigger,
        onConfirmCloseStudyMix,
        onPreExitBubbleClosed,
        onPreExitBubbleDismissed,
        onQuestionCardbarPulseTrigger,
        roundStepIndex,
        shouldClosePreExitBubble,
        shouldShowPreExitBubble,
        stepCard,
        stepConfidenceLevel,
        wasAnswerCardbarClicked,
        wasQuestionCardbarClicked,
    */

    this._isMounted = false;
    this.boundKeydownHandler = this.handleKeydown.bind(this);

    this.isInTopRegion = false;
    this.TOP_REGION_THRESHOLD = 200; // position in pixels marking top region
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.invokeFtse();
  }

  componentDidUpdate(prevProps) {
    // check for upstream clicks on Question and Answer Cardbars
    if (!prevProps.wasQuestionCardbarClicked && this.props.wasQuestionCardbarClicked) {
      this.handleQuestionToolbarClick();
    }

    if (!prevProps.wasAnswerCardbarClicked && this.props.wasAnswerCardbarClicked) {
      this.handleAnswerToolbarClick();
    }

    if (!prevProps.wasQuestionCardbarClicked && this.props.wasQuestionCardbarClicked) {
      this.handleQuestionToolbarClick();
    }

    if ((this.props.roundStepIndex == 0 && this.state.cardLedger == null) || this.props.roundStepIndex != prevProps.roundStepIndex) {
      this.checkAndUpdateCardLedger();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.deactivateKeyboardMonitor();
    this.deactivateTopRegionMonitor();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['ftse-messaging', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderIntroBubble()}
        {this.renderQuestionBubble()}
        {this.renderAnswerBubble()}
        {this.renderCbrBubble()}
        {this.renderRoundMidpointBubble()}
        {this.renderFirstRepeatBubble()}
        {this.renderPreExitBubble()}
      </div>
    );
  }

  renderIntroBubble() {
    if (!this.state.shouldShowIntroBubble) {
      return null;
    }

    return (
      <Bubble
        addClasses="intro-bubble"
        contractionDelay={60000}
        expansionDelay={1000}
        isEscapeKeyActive={true}
        onClose={() => this.handleIntroBubbleClose()}
        shouldAutoContract={true}
        shouldClose={this.state.shouldCloseIntroBubble}
        shouldContract={true}
        shouldExpand={true}
        size="full"
      >
        <div className="study-intro">
          <AnimatedCards />

          <div className="about-text">
            Brainscape's Confidence-Based Repetition &reg; (CBR)<br />system helps you learn faster, using brain science.
          </div>

          <LargeCtaButton
            addClasses="study"
            label="Study 10 Cards"
            onClick={this.handleIntroBubbleCtaClick}
          />
        </div>
      </Bubble>
    );
  }

  renderQuestionBubble() {
    if (!this.state.shouldShowQuestionPrompts) {
      return null;
    }

    return (
      <BubbleDialog
        addClasses="question-bubble"
        expansionDelay={10000}
        message="Reveal the answer when you're ready."
        onClose={() => {}}
        size="small"
        shouldAutoContract={false}
        shouldClose={!this.state.shouldShowQuestionPrompts}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderAnswerBubble() {
    if (!this.state.shouldShowAnswerPrompts) {
      return null;
    }

    return (
      <BubbleDialog
        addClasses="answer-bubble"
        expansionDelay={10000}
        message='Rate how confidently you knew the answer.'
        onClose={() => {}}
        size="small"
        shouldAutoContract={false}
        shouldClose={!this.state.shouldShowAnswerPrompts}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderCbrBubble() {
    if (!this.state.shouldShowCbrBubble) {
      return null;
    }

    return (
      <Bubble
        addClasses="cbr-bubble"
        contractionDelay={30000}
        expansionDelay={100}
        isEscapeKeyActive={true}
        onClose={() => this.handleCbrBubbleClose()}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCloseCbrBubble}
        shouldContract={true}
        shouldExpand={true}
        size="full"
      >
        <div className="cbr-info">
          <div className="rating">You rated this card a {this.props.ftseAnswerCardbarRating}.</div>

          <div className="explanation">{this.renderCbrExplanation()}</div>

          <LargeCtaButton
            addClasses="got-it"
            label="Got it!"
            onClick={() => this.handleCbrBubbleCtaClick()}
            title="Hit [Space Bar] to resume studying"
          />

          <div className="legends-and-buttons">
            <div className="button-legends-and-arrow">
              <div className="button-legend repeat-often">Repeat Often</div>

              <div className="arrow">
                <div className="left-triangle" />
                <div className="line" />
                <div className="right-triangle" />
              </div>

              <div className="button-legend repeat-rarely">Repeat Rarely</div>
            </div>

            <ConfidenceLevelButtons
              highlightedRatingButton={this.props.ftseAnswerCardbarRating}
              onButtonClick={() => {}}
            />
          </div>
        </div>
      </Bubble>
    );
  }

  renderCbrExplanation() {
    if (this.props.ftseAnswerCardbarRating < 3) {
      return "It will repeat soon.";
    }

    if (this.props.ftseAnswerCardbarRating == 3) {
      return "It will repeat in a while.";
    }

    if (this.props.ftseAnswerCardbarRating > 3) {
      return "It shouldn't repeat for awhile.";
    }
  }

  renderRoundMidpointBubble() {
    if (!this.props.isAtRoundMidpoint || this.state.hasSeenMidpointBubble) {
      return null;
    }

    return (
      <BubbleDialog
        addClasses="round-midpoint-bubble"
        contractionDelay={7500}
        expansionDelay={100}
        message="You're halfway there. Just 5 more cards until your goal!"
        onClose={() => this.handleMidpointBubbleClose()}
        size="small"
        shouldAutoContract={true}
        shouldClose={!this.props.isAtRoundMidpoint}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderFirstRepeatBubble() {
    if (!this.state.shouldShowFirstRepeatBubble || this.state.hasSeenMidpointBubble) {
      return null;
    }

    return (
      <BubbleDialog
        addClasses="first-repeat-bubble"
        contractionDelay={7500}
        expansionDelay={1500}
        message={this.renderFirstRepeatMessage()}
        onClose={() => this.handleFirstRepeatBubbleClose()}
        size="small"
        shouldAutoContract={true}
        shouldClose={!this.state.shouldShowFirstRepeatBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
      />
    );
  }

  renderFirstRepeatMessage() {
    const frequencyHash = {
      1 : 'often',
      2 : 'often',
      3 : 'occasionally',
      4 : 'rarely',
      5 : 'rarely',
    }

    const level = this.state.firstRepeatedRatingLevel;
    const frequency = frequencyHash[this.state.firstRepeatedRatingLevel];

    return (
      <span className="first-repeat-message">
        {`You're seeing this card again because you rated it a ${level}.`}
        <br /><br />
        {`${level}'s repeat ${frequency}.`}
      </span>
    );
  }

  renderPreExitBubble() {
    if (!this.props.shouldShowPreExitBubble) {
      return null;
    }

    return (
      <PreExitBubble
        onConfirmCloseStudyMix={() => this.props.onConfirmCloseStudyMix()}
        onPreExitBubbleClosed={() => this.props.onPreExitBubbleClosed()}
        onPreExitBubbleDismissed={() => this.props.onPreExitBubbleDismissed()}
        roundStepIndex={this.props.roundStepIndex}
        shouldClose={this.props.shouldClosePreExitBubble}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAnswerToolbarClick = () => { // click was detected upstream
    if (this.state.shouldShowAnswerPrompts) {
      this.setState({
        shouldShowAnswerPrompts: false,
        shouldShowCbrBubble: true,
      }, () => {
        this.activateKeyboardMonitor();
        Tracker.trackStudyProgress('ftse_first_rating');
      });

      return true;
    }

    if (this.state.shouldShowFirstRepeatBubble) {
      this.setState({
        shouldShowFirstRepeatBubble: false,
      });

      return true;
    }

    if (this.state.isAtRoundMidpoint) {
      this.setState({
        isAtRoundMidpoint: false,
      });

      return true;
    }
  }

  handleCbrBubbleClose() {
    this.deactivateKeyboardMonitor();

    this.setState({
      shouldShowCbrBubble: false
    }, () => {
      if (this.props.ftsePostCbrBubbleAction) {
        this.props.ftsePostCbrBubbleAction();
      }
    });
  }

  handleCbrBubbleCtaClick() {
    this.setState({
      shouldCloseCbrBubble: true
    });
  }

  handleEscapeKeyClick() {
    if (this.state.shouldShowIntroBubble) {
      this.setState({
        shouldCloseIntroBubble: true
      });
      return true;
    }

    if (this.state.shouldShowCbrBubble) {
      this.setState({
        shouldCloseCbrBubble: true
      });
      return true;
    }
  }

  handleFirstRepeatBubbleClose() {
    this.setState({
      shouldShowFirstRepeatBubble: false,
    });
  }

  handleFtsePreBounceBannerCloseRequest = () => {
    this.setState({
      shouldMonitorTopRegion: false,
    }, () => {
      this.deactivateTopRegionMonitor();
    });
  }

  handleIntroBubbleCtaClick = () => {
    this.setState({shouldCloseIntroBubble: true}, () => {
      Tracker.trackStudyProgress('ftse_study_10_btn');
    });
  }

  handleIntroBubbleClose() {
    this.deactivateKeyboardMonitor();
    this.activateTopRegionMonitor();

    this.setState({
      shouldShowIntroBubble: false,
      shouldShowQuestionPrompts: true,
    }, () => {
      // trigger cardbar pulse upstream and wait for cardbar click from upstream
      this.triggerQuestionCardbarPulse();
    });
  }

  handleKeydown(e) {
    e.stopPropagation(); // prevents bubble phase handling altogether (see comment on activateKeyboardMonitor)
    const keyCode = e.keyCode;

    switch (keyCode) {
      case 27: // escape
        e.preventDefault();
        this.handleEscapeKeyClick();
      break;
      case 32: // space bar
        e.preventDefault();
        this.handleSpaceBarClick();
      break;
    }
  }

  handleMidpointBubbleClose() {
    this.setState({
      hasSeenMidpointBubble: true,
    });
  }

  handleMousemove = (e) => {
    if (!this.state.shouldMonitorTopRegion) {
      return false;
    }

    if (this.isShowingFullScreenMessage()) {
      return false;
    }

    if (e.pageY < this.TOP_REGION_THRESHOLD) {
      if (!this.isInTopRegion) {
        this.isInTopRegion = true;
        this.triggerFtsePreBounceBannerOpen();
      }
    } else {
      if (this.isInTopRegion) {
        this.isInTopRegion = false;
        this.triggerFtsePreBounceBannerContract();
      }
    }
  }

  handleQuestionToolbarClick = () => { // click was detected upstream
    if (this.state.shouldShowQuestionPrompts) {
      this.setState({
        shouldShowQuestionPrompts: false,
        shouldShowAnswerPrompts: true,
      }, () => {
        // trigger cardbar pulse upstream and wait for cardbar click from upstream
        this.triggerAnswerCardbarPulse();
        Tracker.trackStudyProgress('ftse_first_reveal');
      });

      return true;
    }

    if (this.state.shouldShowFirstRepeatBubble) {
      this.setState({
        shouldShowFirstRepeatBubble: false,
      });

      return true;
    }

    if (this.state.isAtRoundMidpoint) {
      this.setState({
        isAtRoundMidpoint: false,
      });

      return true;
    }
  }

  handleSpaceBarClick() {
    if (this.state.shouldShowCbrBubble) {
      this.setState({
        shouldCloseCbrBubble: true
      });
      return true;
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerFtsePreBounceBannerClose() {
    EventManager.emitEvent('banner:close', {
      block:          'page',
      id:             'ftse-pre-bounce',
      page:           'study',
      position:       'top',
    });
  }

  triggerFtsePreBounceBannerContract() {
    EventManager.emitEvent('banner:contract', {
      block:          'page',
      id:             'ftse-pre-bounce',
      page:           'study',
      position:       'top',
    });
  }

  triggerFtsePreBounceBannerOpen() {
    EventManager.emitEvent('banner:open', {
      addClasses:     'ftse-pre-bounce-banner',
      block:          'page',
      children:       (<FtsePreBounceBanner />), 
      id:             'ftse-pre-bounce',
      onCloseRequest: this.handleFtsePreBounceBannerCloseRequest, 
      page:           'study',
      position:       'top',
      shouldRepeat:   true,
    });
  }


  /*
  ==================================================
   ADDITIONAL STATE MANAGEMENT
  ==================================================
  */

  checkAndUpdateCardLedger() {
    if (!this.props.stepCard || this.state.hasHadFirstRepeat) {
      return false;
    }

    const stepCardId = this.props.stepCard.cardId.toString();
    let cardLedger = this.state.cardLedger || {};

    if (stepCardId in cardLedger) {
      // this is the first repeat

      this.setState({
        hasHadFirstRepeat: true,
        firstRepeatedRatingLevel: this.props.stepConfidenceLevel,
        shouldShowFirstRepeatBubble: true,
      });

      Tracker.trackStudyProgress('ftse_first_repeat');
    }

    cardLedger[stepCardId] = this.props.stepConfidenceLevel;
    this.setState({
      cardLedger: cardLedger
    });
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  triggerAnswerCardbarPulse() {
    this.props.onAnswerCardbarPulseTrigger();
  }

  triggerQuestionCardbarPulse() {
    this.props.onQuestionCardbarPulseTrigger();
  }

  invokeFtse() {
    this.setState({
      shouldShowIntroBubble: true
    }, () => {
      this.activateKeyboardMonitor();
      Tracker.trackStudyProgress('ftse_intro');
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  activateKeyboardMonitor() {
    // NOTE: true flag at end of call designates handler to fire during capture phase, thus firing before standard bubble phase handlers
    document.addEventListener('keydown', this.boundKeydownHandler, true);
  }

  activateTopRegionMonitor() {
    this.isInTopRegion = true; // setting to true at start, will suppress firing of any events until the user moves from out of the top region and explicitly enters the region.

    this.setState({
      shouldMonitorTopRegion: true,
    }, () => {
      document.addEventListener('mousemove', this.handleMousemove);
    });
  }

  deactivateKeyboardMonitor() {
    document.removeEventListener('keydown', this.boundKeydownHandler, true);
  }

  deactivateTopRegionMonitor() {
    document.removeEventListener('mousemove', this.handleMousemove);
  }

  isShowingFullScreenMessage = () => {
    return (this.state.shouldShowIntroBubble || this.state.shouldShowCbrBubble || this.props.shouldShowPreExitBubble);
  }
}

export default FtseMessaging;
