
import React                          from 'react';
import SimpleTextButton               from '_views/shared/SimpleTextButton';

import {toClassStr} from '_utils/UiHelper';

class IconTextButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        all props from SimpleTextButton
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['icon-text-button', this.props.addClasses]);

    return (
      <SimpleTextButton
        {...this.props}
        addClasses={classes}
      />
    );
  }
}


/*
==================================================
 PRE-CONFIGURED ICON TEXT BUTTONS
==================================================
*/

const CircledAddIconTextButton = (props) => {
  const classes = toClassStr(['circled-add-icon-text-button', props.addClasses]);

  return (
    <IconTextButton 
      {...props}
      addClasses={classes}
      iconClass="ion-android-add-circle"
    />
  );
}

const ExternalLinkIconTextButton = (props) => {
  const classes = toClassStr(['external-link-text-button', props.addClasses]);

  return (
    <IconTextButton 
      {...props}
      addClasses={classes}
      iconClass="ion-android-open"
    />
  );
}


export {
  IconTextButton as default,
  CircledAddIconTextButton,
  ExternalLinkIconTextButton,
}
