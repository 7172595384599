
import DynamicTooltipIcon       from '_views/shared/DynamicTooltipIcon';
import PropTypes                from 'prop-types';
import React                    from 'react';
import Stat                     from '_views/shared/Stat';
import TimeHelper               from '_utils/TimeHelper';

import { toClassStr }           from '_utils/UiHelper';


const PT = {
  addClasses: PropTypes.string,
  background: PropTypes.string,  // 'light' (default) or 'dark' 
  deckCount: PropTypes.number,
  estimatedTimeLeft: PropTypes.number,
  isMobileViewportSize: PropTypes.bool,
  orientation: PropTypes.string,  // 'vertical' or 'horizontal' (default)
  selectedDeckCount: PropTypes.number,
  timeStudied: PropTypes.number,
  tooltipPosition: PropTypes.string,
};


class EstimatedTimeLeftSnippet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const {estimatedTimeLeft, selectedDeckCount, deckCount} = this.props;

    if (!estimatedTimeLeft || isNaN(estimatedTimeLeft) || !isFinite(estimatedTimeLeft)) {
      return null;
    }

    const decksSuffix = this.getDecksSuffix();
    const estimatedTimeLeftStat = `${TimeHelper.msToNarrativeTime(estimatedTimeLeft)}`;
    const classes = toClassStr(['estimated-time-left-snippet', this.props.addClasses]);

    return (
      <Stat
        addClasses={classes}
        background={this.props.background || 'light'}
        isMobileViewportSize={this.props.isMobileViewportSize}
        label="Est. Time Left:"
        orientation={this.props.orientation || 'horizontal'}
        value={estimatedTimeLeftStat}
        tooltipHeading="Estimated Time Left"
        tooltipBody={this.renderDynamicTooltipIconBody()}
        tooltipPosition={this.props.tooltipPosition}
      />
    );
  }

  renderDynamicTooltipIconBody() {
    const {estimatedTimeLeft, selectedDeckCount, timeStudied, selectedDecksMastery} = this.props;

    const hasReachedDeckMastery = (selectedDecksMastery >= 0.99) ? true : false;
    const timeStudiedStat = !timeStudied ? '0 min' : TimeHelper.msToNarrativeTime(timeStudied);
    const isTooltip = true;
    const decksSuffix = this.getDecksSuffix(isTooltip);

    const estimatedTimeLeftStat = TimeHelper.msToNarrativeTime(estimatedTimeLeft);

    if (hasReachedDeckMastery) {
      return (
        <>
          <div className="rich-content-tooltip-sub-heading">Congratulations!</div>
          <p className="body-text">`You've reached 100% Mastery of ${decksSuffix}.`</p>
        </>
      );
    } 

    if (selectedDeckCount > 0) {
      return (
        <>
          <p className="body-text" cyid={"estTimeLeftCumulative"}>You have studied for a cumulative of <strong>{timeStudiedStat}</strong> in this class (excluding any long distracted pauses).</p>
          <p className="body-text" cyid={"estTimeLeftSummary"}>If you continue to improve your confidence at this rate, we project that you will need to study another <strong>{estimatedTimeLeftStat}</strong> to reach 100% Mastery of {decksSuffix}.</p>
        </>
      );
    }

    return (
      <p className="body-text">To see your estimated time left, please check the boxes under the Decks tab below, for the decks you aim to master.</p>
    );
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getDecksSuffix = (isTooltip=false) => {
    const {selectedDeckCount, deckCount} = this.props;

    if (selectedDeckCount && deckCount) {

      if (selectedDeckCount == deckCount) {
        return (isTooltip) ? 'this class' : '';
      }

      return (isTooltip) ? `the (${selectedDeckCount} of ${deckCount}) decks selected in this mix` : ` (${selectedDeckCount} / ${deckCount} decks)`;
    }

    if (!deckCount && selectedDeckCount) {
      return (isTooltip) ? `${selectedDeckCount} decks` : ` (${selectedDeckCount} decks)`;
    }

    return '';
  }
}

EstimatedTimeLeftSnippet.propTypes = PT;

export default EstimatedTimeLeftSnippet;
