
import React                from 'react';
import Spinner              from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

class TabSwitcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*   
      this.props:
        addClasses,
        currentTabId,
        isCurrentTabLoading,
        onTabClick,
        tabs: {
          tabId: {
            id: <string>,
            label: >string>
          },
          ...
        },
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
 

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['tab-switcher', this.props.addClasses]);

    let tabItems = [];

    Object.keys(this.props.tabs).forEach((tabId, index) => {
      tabItems.push(this.renderTabItem(this.props.tabs[tabId], index));
    });

    return (
      <ul className={classes}>
        {tabItems}
      </ul>
    );
  }

  renderTabItem(tab, index) {
    let isSelected = (tab.id == this.props.currentTabId);
    let isLoading = (this.props.isCurrentTabLoading && isSelected);
    let tabClasses = toClassStr(['tab-item', 'tab-item-' + tab.id, isSelected ? 'is-selected' : '', isLoading ? 'is-loading' : '']);

    return (
      <li key={index} className={tabClasses} onClick={(e) => this.handleTabClick(e, tab.id)}>
        <div className="tab-label">
          {this.renderTabLabel(tab)}
        </div>
        {this.renderSpinner(isLoading)}
      </li>
    );
  }

  renderTabLabel(tab) {
    const count = (typeof(tab.count) != 'undefined') ? ' (' + tab.count + ')' : '';
    return tab.label + count;
  }

  renderSpinner(isLoading) {
    if (!isLoading) {
      return null;
    }

    return (
      <Spinner
        addStyles="tab-spinner"
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleTabClick(e, tabId) {
    if (e) {
      e.stopPropagation();
    }
    
    this.props.onTabClick(tabId);
  }
}

export default TabSwitcher;
