
import model                  from '_core/newModel';


const deckCardDuplicate = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId, cardId, position) {
    return new Promise((resolve, reject) => {
      try {
        const sourceCardIds = [cardId];

        const copyPath = `packs/${packId}/decks/${deckId}/cards/cb/copy`;
        const pastePath = `packs/${packId}/decks/${deckId}/cards/cb/paste`;
        const deckShowPath = `packs/${packId}/decks/${deckId}`;
        const reorderingPath = `packs/${packId}/decks/${deckId}/cards/reorder`;

        model.create(copyPath, {
          cardIds: sourceCardIds,
          shouldSuppressMessage: true,
        }).then(() => {
          model.create(pastePath, {isImmediate: true}).then(() => {
            model.show(deckShowPath).then(data => {
              const deck = data.deck;
              const deckCardIds = deck.cardIds;
              const duplicatedCardId = deckCardIds[deckCardIds.length - 1];

              const orderedCardIds = [...deckCardIds.slice(0, position), duplicatedCardId, ...deckCardIds.slice(position, deckCardIds.length - 1)];

              const reorderedCardIdsData = {
                cardIds: orderedCardIds.join(','),
              };

              model.create(reorderingPath, reorderedCardIdsData).then(() => {
                const cardShowPath = `packs/${packId}/decks/${deckId}/cards/${duplicatedCardId}`;

                model.show(cardShowPath).then(duplicatedCard => {
                  const resultData = {
                    packId: packId,
                    deckId: deckId,
                    sourceCardId: cardId,
                    duplicatedCardId: duplicatedCardId,
                    duplicatedCard: duplicatedCard,
                    orderedCardIds: orderedCardIds,
                    position: position,
                  };

                  model.publish('card:duplicated', resultData);

                  resolve(resultData);
                }).catch(err => {
                  throw err;
                });
              }).catch(err => {
                throw err;
              });
            }).catch(err => {
              throw err;
            });
          }).catch(err => {
            throw err;
          });
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.log('something went wrong in deckCardDuplicate. err:', err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckCardDuplicate;
