
import React                from 'react'
import EventBus             from '_utils/EventBus';
import EventManager         from '@brainscape/event-manager';
import ToastController      from '_controllers/ToastController';
import {createSubscription} from '_core/Cable';
import SimpleTextButton     from '_views/shared/SimpleTextButton';

class ExportCsvButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      s3: props.s3 || true,
    };
    /*
    this.props:
      pack_id,
    */      
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <>
        <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="Export CSV"
            onClick={(e) => this.handleButtonClick(e)}
          />
        <ToastController />
      </>
    );
  }

  triggerToastOpen = (message, type='success', duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }

  renderLink(link) {
    return (
      <div>
        You're all set. 
        <a 
          target={'_blank'}
          href={link}
         >
           Click this temporary link to download your CSV.
         </a>
      </div>
    )
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */
  
  handleCsvResponse = data => {
    if (data.status == "Complete") { 
      const link = data.meta;
      const msg = this.renderLink(link)
      this.triggerToastOpen(msg, 'success', 20000)
    } else if ( data.status.match(/failed|processing/) ) {
      const msg = "Something went wrong. Please contact Brainscape Support."
      this.triggerToastOpen(msg)
    }
  };     

  handleButtonClick(e) {
    if (e) { e.stopPropagation() }
  
    this.triggerToastOpen('Stay tuned. A link to download your CSV is being processed now.', 'success', 5000) 

    fetch(`${window.location.pathname}.csv?s3=${this.state.s3}`)
      .then(response => {
        if (!response.ok) { throw new Error('Network response was not ok') }
        return response.json();
      })
      .then(resp => {
        createSubscription(
          { channel: 'BackgroundJobTrackerChannel', id: resp.filename },
          { received: this.handleCsvResponse }
        );
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }
}

export default ExportCsvButton;
