
import {
  apiError,
  apiPatch,
  cardPath,
} from '_core/Api2';

import EventBus         from '_utils/EventBus';
import EventManager     from '@brainscape/event-manager';
import Modal            from '_views/shared/Modal';
import PillButton       from '_views/shared/PillButton';
import PropTypes        from 'prop-types';
import React            from 'react';
import SimpleTextButton from '_views/shared/SimpleTextButton';
import StringHelper     from '_utils/StringHelper';
import TextField        from '_views/shared/TextField';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses:  PropTypes.string,
  card:        PropTypes.object.isRequired,
  cardId:      PropTypes.node.isRequired,
  deckId:      PropTypes.node.isRequired,
  isUserPro:   PropTypes.bool.isRequired,
  onCompleted: PropTypes.func.isRequired,
  packId:      PropTypes.node.isRequired,
};


class EditCardModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answer                        : props.card.answer,
      isMonitoringKeydown           : false,
      isProcessing                  : false,
      shouldTriggerAnswerSelection  : false,
      question                      : props.card.question,
    };

    this.answerInput    = null;
    this.questionInput  = null;
    this.submitButton   = null;
    this.events         = new EventManager();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['edit-card-modal', this.props.addClasses]);

    const question = this.state.question;
    const answer   = this.state.answer;
    const anyBlank = StringHelper.isBlank(question) || StringHelper.isBlank(answer);
    const fullEditorPath = `/l/dashboard/${this.props.packId}/decks/${this.props.deckId}/cards/${this.props.cardId}/edit`;
    const syntaxHelpUrl = "https://brainscape.zendesk.com/hc/en-us/articles/8961531870221-Brainscape-Flashcard-Formatting-Tips";

    return (
      <Modal
        addClasses={classes}
        isOpen={true}
        onCloseRequest={this.handleCloseRequest}
      >
        <div className='modal-title'>Edit Card</div>
        <div className="modal-message forward-icon"><a href={fullEditorPath}>Close Study/Preview and Open in Full Editor</a></div>
        <div className="modal-guidance new-tab-icon"><a href={syntaxHelpUrl} target="_blank">Formatting Syntax Guide</a></div>

        <form className='edit-card-form' onSubmit={this.handleSubmit}>

          <div className="card-faces">
            
            <TextField
              hasInitialSelection={true}
              id='question'
              label='Question'
              onChange={this.handleFieldChange}
              onKeyDown={this.handleKeyDown}
              ref={(input) => this.questionInput = input}
              type="textarea"
              value={question}
            />

            <TextField
              id='answer'
              label='Answer'
              onBlur={this.handleAnswerBlur}
              onChange={this.handleFieldChange}
              onKeyDown={this.handleKeyDown}
              ref={(input) => this.answerInput = input}
              type="textarea"
              value={answer}
            />
          </div>

          <div className='modal-actions'>
            <SimpleTextButton
              addClasses='cancel-modal-text-button'
              label='Cancel'
              onClick={this.handleCloseRequest}
              spinnerColor='blue'
            />

            <PillButton
              addClasses='resolve-modal-button pill-button-emphasized'
              id="edit-card-submit-button"
              isDisabled={anyBlank}
              isProcessing={this.state.isProcessing}
              label='Submit'
              onClick={this.handleSubmit}
              onKeyDown={this.handleKeyDown}
              ref={(elem) => this.submitButton = elem}
              tabIndex="-1"
            />
          </div>
        </form>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleKeyDown = (e) => {
    if (e.keyCode == 9 && e.target.id == 'question') { // tab
      e.preventDefault();
      if (this.answerInput) {
        this.answerInput.focusAndSelect();
      }
    }

    if (e.keyCode == 9 && e.target.id == 'answer') { // tab
      e.preventDefault();
      if (this.submitButton) {
        this.submitButton.focus();
      }
    }

    if (e.keyCode == 9 && e.target.id == 'edit-card-submit-button') { // tab
      e.preventDefault();
      if (this.questionInput) {
        this.questionInput.focusAndSelect();
      }
    }

    if (e.shiftKey && e.keyCode == 9 && e.target.id == 'question') { // shift-tab
      e.preventDefault();
      if (this.submitButton) {
        this.submitButton.focus();
      }
    }

    if (e.shiftKey && e.keyCode == 9 && e.target.id == 'answer') { // shift-tab
      e.preventDefault();
      if (this.questionInput) {
        this.questionInput.focusAndSelect();
      }
    }

    if (e.shiftKey && e.keyCode == 9 && e.target.id == 'edit-card-submit-button') { // shift-tab
      e.preventDefault();
      if (this.answerInput) {
        this.answerInput.focusAndSelect();
      }
    }

    if (e.keyCode == 13 && e.target.id == 'edit-card-submit-button') {
      e.preventDefault();
      this.handleSubmit();
    }
  };

  handleCloseRequest = () => {
    if (!this.anyChange()) {
      this.props.onCompleted({cardChanged: false});
      return;
    }

    const msg = 'You made changes.  Do you want to close the editor and ' +
                'drop the changes?';
    if (confirm(msg)) { this.props.onCompleted({cardChanged: 'canceled'}); }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!this.anyChange()) {
      const msg = 'No changes were made.  Close the editor?';
      if (confirm(msg)) { this.handleCloseRequest(); }
      return;
    }

    this.setState({isProcessing: true});

    const packId = this.props.packId;
    const deckId = this.props.deckId;
    const cardId = this.props.cardId;
    const path   = cardPath(packId, deckId, cardId);
    const body   = {
      answer: this.state.answer,
      isMdSource: true,
      is_web: true,
      question: this.state.question, 
    };

    apiPatch(path, body).then(this.handleUpdate).catch(this.handleError);
  };

  handleUpdate = (card) => {
    this.props.onCompleted({cardChanged: true, card: card});
    EventManager.emitEvent('card:updated', {card: card});
  };

  handleError = (error) => {
    this.setState({isProcessing: false});

    if (error instanceof Error && error.errors) {
      const cardId = this.props.cardId;
      const errors = error.errors;
      const msg    = `card ${cardId} errors: ${JSON.stringify(errors)}`;
      const opts   = {msg: msg, code: error.code, url: error.url};
      EventBus.publish('error:msg', opts);

      EventManager.emitEvent('error-modal:open', {
        errorListing: {
          message: 'Could not update the card because of the following errors:',
          errors: error.errors,
        }
      });
      return;
    }

    apiError(error);
  };

  handleFieldChange = (e) => {
    switch (e.target.id) {
      case 'answer':   return this.setState({answer: e.target.value});
      case 'question': return this.setState({question: e.target.value});
    }
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  anyChange = () => {
    return this.props.card.question != this.state.question ||
           this.props.card.answer != this.state.answer;
  };
}

EditCardModal.propTypes = PT;

export default EditCardModal;
