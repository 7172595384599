
import React from 'react';

const WordyHr = (props) => {
  return (
    <div className='wordy-hr'>
      <hr />
      <span>{props.children}</span>
      <hr />
    </div>
  );
};

WordyHr.propTypes = {};

export default WordyHr;
