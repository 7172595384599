
import Model from '../core/Model';

class CategoryModel extends Model {
  constructor(props) {
    super(props);
  }

  index(opts) {
    const url = this.buildUrl('/api/categories/', opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
    .done((data, status) => {
      const categories = data.data;

      let categoryData = categories.map((category, index) => {
        return {
          name: category.name,
          browsePath: '/learn/' + category.url,
          iconUrl: category.thumb,
          id: category.id,
        };
      });

      opts.done(categoryData);
    })
    .fail((jqXHR, status) => {
      opts.fail(status);
    });
  }

  show(opts) {
    const url = this.buildUrl('/api/categories/' + opts.id, opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
    .done((data, status) => {
      opts.done(data);
    })
    .fail((jqXHR, status) => {
      opts.fail(status);
    });
  }

  enroll(opts) {
    const url = this.buildUrl('/api/categories/' + opts.id + '/enrollment', opts.params);

    return $.ajax({
      type: 'POST',
      url: url
    })
    .done((data, status) => {
      opts.done(data);
    })
    .fail((jqXHR, status) => {
      opts.fail(status);
    });
  }
}

export default CategoryModel;
