
const eachDataTransferFile = (e, cb) => {
  if (e.dataTransfer.items) {
    const items = e.dataTransfer.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind !== 'file') { next; }

      cb(item.getAsFile());
    }
  } else {
    const files = e.dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      cb(files[i]);
    }
  }
};

export default eachDataTransferFile;
