
import PropTypes        from 'prop-types';
import React            from 'react';

import {toClassStr} from '_utils/UiHelper';


const PT = {
  addClasses                  : PropTypes.string,
  buttons                     : PropTypes.node, // react components
  caption                     : PropTypes.string,
  charLimit                   : PropTypes.number,
  descriptor                  : PropTypes.string, // text appears after field gets focus or receives a value      
  hasInitialFocus             : PropTypes.bool,
  hasInitialSelection         : PropTypes.bool,
  id                          : PropTypes.node,
  isInvalid                   : PropTypes.bool,
  label                       : PropTypes.string,
  name                        : PropTypes.string,
  onBlur                      : PropTypes.func,
  onChange                    : PropTypes.func,
  onOverCharLimitChange       : PropTypes.func,
  onFocus                     : PropTypes.func,
  onKeyDown                   : PropTypes.func,
  mode                        : PropTypes.string, // write or preview
  placeholder                 : PropTypes.string,
  type                        : PropTypes.string, // input or textarea
  value                       : PropTypes.node,
};


class SimpleMdeTextField extends React.Component {
  constructor(props) {
    super(props);

    this.elem = null;
    this.simpleMdeTextField = null;
    this.input = null;

    this.state = {
      charCount: (this.props.value) ? this.props.value.length : 0,
      hasFocus: false,
      isOverCharLimit: false,
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.initSimpleMde();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isInvalidClass = this.props.isInvalid ? 'is-invalid' : ''
    const isOverCharLimitClass = (this.props.charLimit && (this.props.charLimit - this.state.charCount < 0)) ? 'is-over-char-limit' : '';
    const hasContentClass = this.props.value ? 'has-content' : '';
    const hasFocusClass = this.state.hasFocus ? 'has-focus' : '';

    const classes = toClassStr(['text-field', isInvalidClass, isOverCharLimitClass, hasContentClass, hasFocusClass, this.props.addClasses]);

    return (
      <div className={classes} ref={(elem) => {this.elem = elem}}>

        {this.renderFieldLabel()}

        <div className="input-and-buttons">
          {this.renderSimpleMdeTextareaField()}
          {this.renderFieldDescriptor()}
          {this.renderFieldButtons()}
        </div>

        {this.renderFieldCaption()}

      </div>
    );
  }

  renderFieldLabel() {
    if (!this.props.label) {
      return null;
    }

    return (
      <div className="field-label">
        {this.props.label}
      </div>
    );
  }

  renderFieldDescriptor() {
    if (!this.props.descriptor) {
      return null;
    }

    return (
      <div className="field-descriptor">
        {this.props.descriptor}
      </div>
    );
  }

  renderFieldButtons() {
    if (!this.props.buttons) {
      return null;
    }

    return this.props.buttons;
  }

  renderFieldCaption() {
    if (!this.props.caption && !this.props.charLimit) {
      return null;
    }

    return (
      <div className="field-caption">
        {this.renderCaptionText()}
        {this.renderLimitText()}
      </div>
    );
  }

  renderCaptionText() {
    if (!this.props.caption) {
      return null;
    }

    return (
      <span className="caption-text">{this.props.caption}</span>
    );
  }

  renderLimitText() {
    if (!this.props.charLimit) {
      return null;
    }

    const limitText = `(${this.state.charCount} of ${this.props.charLimit} char)`;

    return (
      <span className="limit-text">{limitText}</span>
    );
  }

  renderSimpleMdeTextareaField() {
    return (
      <textarea
        id={this.props.id}
        key={this.props.name}
        name={this.props.name}
        onChange={this.handleChange}
        ref={(elem) => this.simpleMdeTextField = elem}
        value={this.props.value || ''}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBlur = (e) => {
    this.setState({
      hasFocus: false,
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleMdeFieldChange = (value) => {
    const e = {
      isPseudoEventObject: true,
      target: {
        key: this.props.name,
        value: value,
      }
    };

    this.handleChange(e);
  }

  handleChange = (e) => {
    const newCharCount = (e.target.value) ? e.target.value.length : 0;
    const isOverCharLimit = (newCharCount > this.props.charLimit);

    if ((isOverCharLimit != this.state.isOverCharLimit) && this.props.onOverCharLimitChange) {
      this.props.onOverCharLimitChange(isOverCharLimit);
    }

    this.setState({
      charCount: newCharCount,
      isOverCharLimit: isOverCharLimit,
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  handleFocus = (e) => {
    this.setState({
      hasFocus: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  focus = () => {
    if (this.simpleMde && this.simpleMde.focus) {
      this.simpleMde.focus();
    }
  }

  initSimpleMde = () => {
    // SimpleMDE is an object/function in the global space by virtue of a script load in the library-dashboard rails template. TODO: Create a package with this script that can be loaded in an ES6 import statement.
    this.simpleMde = new SimpleMDE({
      autofocus: true,
      element: this.simpleMdeTextField,
      toolbar: ["bold", "italic", "|", "unordered-list", "ordered-list", "|", "code", "link"],
      placeholder: this.props.placeholder,
      spellChecker: false,
      status: false,
    });

    this.simpleMde.value(this.props.value);

    this.simpleMde.codemirror.on("change", () => {
      const val = this.simpleMde.value();
      this.handleMdeFieldChange(val);
    });

    this.simpleMde.codemirror.on("keydown", (instance, e) => this.props.onKeyDown(e));
    this.simpleMde.codemirror.on("focus", (instance, e) => this.handleFocus(e));
    this.simpleMde.codemirror.on("blur", (instance, e) => this.handleBlur(e));
  }
}

SimpleMdeTextField.propTypes = PT;

export default SimpleMdeTextField;
