
const NumberHelper = {

  displayNumber(number) {
    if (isNaN(number)) {
      return '--';
    }

    if (Math.abs(number) < 1000) {
      return number;
    }

    if (Math.abs(number) < 5000) {
      return number.toLocaleString();
    }

    if (Math.abs(number) < 1000000) {
      return this.sign(number)*((Math.abs(number)/1000).toFixed(1)) + 'k';
    }

    return this.sign(number)*((Math.abs(number)/1000000).toFixed(1)) + 'm';
  },

  percentageStr(value, decimalPoints = 1) {
    // value is number between 0 and 1, decimalPoints is number of digits after decimal

    const percentageNumber = value * Math.pow(10, 2 + decimalPoints);
    const truncatedPercentageStr = percentageNumber.toString().split('.', 1)[0];
    const finalPercentageNumber = truncatedPercentageStr / Math.pow(10, decimalPoints);

    return finalPercentageNumber.toFixed(decimalPoints);
  },

  sign(number) {
    // performs same functionality as Math.sign, which is unsupported in IE
    return number > 0 ? 1 : number < 0 ? -1 : 0;
  },
}

export default NumberHelper;
