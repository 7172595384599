
import EventManager     from '@brainscape/event-manager';
import PropTypes        from 'prop-types';
import React            from 'react';
import ReactMde         from 'react-mde';
import sanitizeHtml     from 'sanitize-html/dist/sanitize-html.min';
import showdown         from 'showdown';


import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses:             PropTypes.string,
  buttons:                PropTypes.node, // react components
  caption:                PropTypes.string,
  charLimit:              PropTypes.number,
  descriptor:             PropTypes.string, // text appears after field gets focus or receives a value 
  errorMessage:           PropTypes.string,
  hasInitialFocus:        PropTypes.bool,
  hasInitialSelection:    PropTypes.bool,
  id:                     PropTypes.node,
  isDynamic:              PropTypes.bool,
  isInvalid:              PropTypes.bool,
  isReadOnly:             PropTypes.bool,
  label:                  PropTypes.string,
  name:                   PropTypes.string,
  onBlur:                 PropTypes.func,
  onChange:               PropTypes.func,
  onOverCharLimitChange:  PropTypes.func,
  onFocus:                PropTypes.func,
  onKeyDown:              PropTypes.func,
  mode:                   PropTypes.string, // write or preview
  placeholder:            PropTypes.string,
  type:                   PropTypes.string, // input or textarea
  tooltipContent:         PropTypes.string,
  tooltipPosition:        PropTypes.string,
  value:                  PropTypes.node,
};


class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.input = null;

    this.state = {
      charCount: (this.props.value) ? this.props.value.length : 0,
      hasFocus: false,
      isExtendedText: false,
      isOverCharLimit: false,
      shouldRemovePlaceholder: false,
    }

    this.converter = new showdown.Converter();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    if (this.props.hasInitialFocus) { 
      this.focus(); 
    }

    if (this.props.hasInitialSelection) { 
      this.focusAndSelect(); 
    }

    if (this.props.isDynamic || this.isDynamicType()) {
      this.adjustElemHeight();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value && !this.props.value) {
      this.setState({
        shouldRemovePlaceholder: false,
      });
    }

    if (prevProps.value != this.props.value && (this.isDynamicType() || this.props.isDynamic)) {
      this.adjustElemHeight();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isInvalidClass = this.props.isInvalid ? 'is-invalid' : ''
    const isOverCharLimitClass = (this.props.charLimit && (this.props.charLimit - this.state.charCount < 0)) ? 'is-over-char-limit' : '';
    const hasContentClass = this.props.value ? 'has-content' : '';
    const hasFocusClass = this.state.hasFocus ? 'has-focus' : '';
    const hasCaptionClass = this.hasCaption() ? 'has-caption' : '';
    const textSizeClass = this.getTextSizeClass();

    let classes = toClassStr(['text-field', textSizeClass, isInvalidClass, isOverCharLimitClass, hasContentClass, hasFocusClass, hasCaptionClass, this.props.addClasses]);

    return (
      <div className={classes}>

        {this.renderFieldLabel()}

        <div 
          className="input-and-buttons"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleClick}
        >
          {this.renderField()}
          {this.renderFieldDescriptor()}
          {this.renderFieldButtons()}
        </div>

        {this.renderFieldCaption()}
      </div>
    );
  }

  renderFieldLabel() {
    if (!this.props.label) {
      return null;
    }

    return (
      <div className="field-label">
        {this.props.label}
      </div>
    );
  }

  renderField() {
    if (this.props.type == 'mde-textarea') {
      return this.renderMdeTextareaField();
    }

    if (this.props.type == 'adjustable-textarea') {
      return this.renderAdjustableTextareaField();
    }

    if (this.props.type == 'dynamic-textarea') {
      return this.renderDynamicTextareaField();
    }

    if (this.props.type == 'textarea') {
      return this.renderTextareaField();
    }

    return this.renderInputField();
  }

  renderMdeTextareaField() {
    const markdownPlaceholder = {"placeholder": "Format your description using markdown. (See help above)"};

    const textSizeClass = this.getTextSizeClass();
    const textareaClasses = toClassStr(['textarea-input', 'mde-textarea-input', textSizeClass]);

    const classes = {
      textArea: textareaClasses
    } 

    const childProps = {
      textArea: {
        id: this.props.id,
        name: this.props.name,
        placeholder: this.props.placeholder,
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        onKeyDown: this.props.onKeyDown,
      },
      reactMde: {
        ref: (elem) => {this.input = elem},
      }
    }

    const l18n = {
      preview: 'Preview',
      write: 'Markdown',
    };

    return (
      <ReactMde
        childProps={childProps}
        classes={classes}
        l18n={l18n}
        value={this.props.value}
        onChange={this.handleMdeFieldChange}
        selectedTab={this.props.mode}
        textAreaProps={markdownPlaceholder}
        onTabChange={this.handleMarkdownTabChange}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(this.convertMarkdownToHtml(markdown))
        }
      />
    );
  }

  renderDynamicTextareaField() {
    const isPlaceholderClass = (!this.props.value && this.props.placeholder) ? 'is-placeholder' : '';
    const isExtendedTextClass = (this.state.isExtendedText) ? 'is-extended-text' : '';
    const textSizeClass = this.getTextSizeClass();

    const classes = toClassStr(['dynamic-textarea dynamic-textarea-input', isPlaceholderClass, isExtendedTextClass, textSizeClass]);

    return (
      <div className="dynamic-textarea dynamic-textarea-wrapper">
        <div 
          className={classes}
          contentEditable={true}
          id={this.props.id}
          name={this.props.name}
          onBlur={this.handleBlur}
          onChange={this.handleDynamicChange}
          onFocus={this.handleFocus}
          onKeyDown={this.props.onKeyDown}
          readOnly={this.props.isReadOnly}
          ref={(input) => {this.input = input}}
          suppressContentEditableWarning={true}
        >
          {this.renderDynamicValue()}
        </div>
      </div>
    );
  }

  renderDynamicValue = () => {
    if (!this.props.value) {
      return this.props.placeholder || null;
    }

    return this.props.value;
  }

  renderAdjustableTextareaField() {
    const textSizeClass = this.getTextSizeClass();
    const classes = toClassStr(['textarea-input', 'adjustable-textarea-input', textSizeClass]);

    return (
      <textarea
        className={classes}
        name={this.props.name}
        id={this.props.id}
        placeholder={this.props.placeholder}
        readOnly={this.props.isReadOnly}
        ref={(input) => {this.input = input}}
        value={this.props.value}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
      />
    );
  }

  renderTextareaField() {
    return (
      <textarea
        className="textarea-input"
        name={this.props.name}
        id={this.props.id}
        placeholder={this.props.placeholder}
        readOnly={this.props.isReadOnly}
        ref={(input) => {this.input = input}}
        value={this.props.value}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
      />
    );
  }

  renderInputField() {
    const type = this.props.type || 'text';
    const autoComplete = (type == 'password') ? 'off' : '';

    return (
      <input
        autoComplete={autoComplete}
        className="text-input"
        name={this.props.name}
        id={this.props.id}
        placeholder={this.props.placeholder}
        readOnly={this.props.isReadOnly}
        ref={(input) => {this.input = input}}
        type={type}
        value={this.props.value}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
      />
    );
  }

  renderFieldDescriptor() {
    if (!this.props.descriptor) {
      return null;
    }

    return (
      <div className="field-descriptor">
        {this.props.descriptor}
      </div>
    );
  }

  renderFieldButtons() {
    if (!this.props.buttons) {
      return null;
    }

    return this.props.buttons;
  }

  renderFieldCaption() {
    if (!this.hasCaption()) {
      return null;
    }

    return (
      <div className="field-caption">
        {this.renderCaptionText()}
        {this.renderLimitText()}
        {this.renderErrorMessage()}
      </div>
    );
  }

  renderCaptionText() {
    if (this.props.errorMessage) {
      return null;
    }

    if (!this.props.caption) {
      return null;
    }

    return (
      <span className="caption-text">{this.props.caption}</span>
    );
  }

  renderLimitText() {
    if (this.props.errorMessage) {
      return null;
    }

    if (!this.props.charLimit) {
      return null;
    }

    const limitText = `(${this.state.charCount} of ${this.props.charLimit} char)`;

    return (
      <span className="limit-text">{limitText}</span>
    );
  }

  renderErrorMessage() {
    if (!this.props.errorMessage) {
      return null;
    }
    
    return (
      <div className="error-message">
        {this.props.errorMessage}
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBlur = (e) => {
    const shouldRemovePlaceholder = (this.props.value) ? true : false;

    this.setState({
      hasFocus: false,
      shouldRemovePlaceholder: shouldRemovePlaceholder,
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  handleClick = () => {
    this.triggerTooltipClose();
  }

  handleMdeFieldChange = (value) => {
    const e = {
      isPseudoEventObject: true,
      target: {
        key: this.props.id,
        value: value,
      }
    };

    this.handleChange(e);
  }

  handleChange = (e) => {
    const newCharCount = (e.target.value) ? e.target.value.length : 0;
    const isOverCharLimit = (newCharCount > this.props.charLimit);
    const shouldRemovePlaceholder = (e.target.value) ? true : false;

    if ((isOverCharLimit != this.state.isOverCharLimit) && this.props.onOverCharLimitChange) {
      this.props.onOverCharLimitChange(isOverCharLimit);
    }

    this.setState({
      charCount: newCharCount,
      isOverCharLimit: isOverCharLimit,
      shouldRemovePlaceholder: shouldRemovePlaceholder,
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  handleFocus = (e) => {
    this.setState({
      hasFocus: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  handleMarkdownTabChange = (tab) => {
    this.props.onModeChangeRequest(tab);
  }

  handleMouseEnter = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }
  }

  handleMouseLeave = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.props.tooltipContent,
      elem: this.input,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  adjustElemHeight = () => {
    let elem = this.input;

    if (this.props.type == 'mde-textarea') {
      elem = document.querySelector(`#${this.props.id}`);
    }

    elem.style.height = '0px';
    elem.style.height = (elem.scrollHeight + 2) + 'px';
  }

  convertMarkdownToHtml = (markdown) => {
    const convertedHtml = this.converter.makeHtml(markdown);
    const sanitizedHtml = sanitizeHtml(convertedHtml, {
      allowedTags: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
    });

    return sanitizedHtml;
  }

  focus = () => {
    if (this.input) { this.input.focus(); }
  }

  focusAndSelect = () => {
    if (this.input) {
      this.input.focus();
      this.input.select();
    }
  }

  getTextSizeClass = () => {
    if (!this.props.isScf) {
      return null;
    }

    const fieldName = this.props.name || this.props.id;

    if (!fieldName) {
      return 'scf-body';
    }

    if (fieldName.indexOf('Prompt') != -1) {
      return 'scf-prompt';
    }

    if (fieldName.indexOf('ImageCaption') != -1) {
      return 'scf-image-caption';
    }

    if (fieldName.indexOf('Footnote') != -1) {
      return 'scf-footnote';
    }

    let value = this.props.value;

    const trailingSpaceIndex = value.indexOf('&nbsp;');
    const isClarifier = (fieldName.indexOf('Clarifier') != -1);

    if (trailingSpaceIndex != -1) {
      value = value.substring(0, trailingSpaceIndex);
    }

    const textLength = value.slice(0, -1).length;

    if (textLength <= 4) {
      return (isClarifier) ? 'scf-clarifier-short' : 'scf-body-xl';
    }

    if (textLength <= 18) {
      return (isClarifier) ? 'scf-clarifier-short' : 'scf-body-l';
    }

    if (textLength <= 42) {
      return (isClarifier) ? 'scf-clarifier-short' : 'scf-body-m';
    }

    return (isClarifier) ? 'scf-clarifier' : 'scf-body';
  }

  hasCaption = () => {
    return (this.props.caption || this.props.charLimit || this.props.errorMessage);
  }

  isDynamicType = () => {
    return (this.props.type == 'adjustable-textarea' || this.props.type == 'mde-textarea');
  }
}

TextField.propTypes = PT;

export default TextField;
