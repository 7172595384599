
import PillButton                     from '_views/shared/PillButton';
import React, { useEffect, useState } from 'react'
import ReactDOM                       from 'react-dom';
import TextField                      from '_views/shared/TextField';
import {apiPost}                      from '_core/Api2';
import {createSubscription, cable}    from '_core/Cable';
import {loadStripe}                   from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements
}                                     from '@stripe/react-stripe-js';
import {toClassStr}                   from '_utils/UiHelper';
import StringHelper                   from '_utils/StringHelper';
import GaHelper                       from '_utils/GaHelper';
import TimeHelper                     from '_utils/TimeHelper';
import { defaultIntegrations }        from '@sentry/browser';
import {omit}                         from 'lodash'


const StripeForm = (props) => {
  const stripeKey     = document.querySelector("meta[name=\"stripePublishableKey\"]").content;
  const stripePromise = loadStripe(stripeKey);

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  )
};

const CheckoutForm = (props) => {

  const {clientSecret, setupIntentId, currentActivePlanPriceTotal, handlePurchaseSuccessful} = props;

  const [stripeStatus, setStripeStatus]                          = useState('')
  const [isStripeFieldValid, setIsStripeFieldValid]              = useState(null)
  const [stripeFieldErrorMessage, setStripeFieldErrorMessage]    = useState('')
  const [values, setValues]                                      = useState({});
  const [errors, setErrors]                                      = useState({});
  const [isDisabled, setIsDisabled]                              = useState(true)
  const [isProcessing, setIsProcessing]                          = useState(false)
  const [optionalCode, setOptionalCode]                          = useState(props.optionalCode)
  const [planId, setPlanId]                                      = useState(props.planId)
  const [timeouts, setTimeouts]                                  = useState([]);
  const [isOptionalCodeInputFieldVisible, setIsOptionalCodeInputFieldVisible] = useState(false)

  const handleToggleOptionalCodeInputField = () => {setIsOptionalCodeInputFieldVisible(!isOptionalCodeInputFieldVisible)}

  const stripe     = useStripe();
  const elements   = useElements();

  useEffect(() => {
    setOptionalCode(optionalCode)
  }, [optionalCode]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && values.name && values.email && isStripeFieldValid) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [values, isStripeFieldValid]);

  useEffect(() => {
    const keyDownHandler = (event) => {

      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => { 
    // GA4
    if (window.gtag) {
      gtag('event', 'page_view', {
        page_title: 'upgrade_plan',
        page_location: `${window.location.href}?upgrade_plan=${props.currentActivePlanClassName}`,
          logged_state: 2,
          paywall: props.paywall,
          user_email: window?.userObj?.email
      });
    }
  }, []);

  useEffect(() => {
    const mobileSlideContainer = document.querySelector('.mobile-slide-container');
    if (mobileSlideContainer) {
      // mobileSlideContainer.scrollTo({top: 0, behavior: 'smooth'});
      // Works for ios Safari
      mobileSlideContainer.scrollTop = 0;
    }
  }, [])

  useEffect(() => {
    return () => {
      cancelWebSocketFallback();
    };
  }, []);

  const inputStyle = {
    color: '#545454',
    fontWeight: '400',
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '14px',
    '::placeholder': {
      color: '#BBB',
    },
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // Checks interval in 3s, 5s, and 10s, and reports failure at 18s
  const handleWebSocketFallback = () => {
    timeouts.push(setTimeout(function() {
      checkSubscription();
      timeouts.push(setTimeout(function() {
        checkSubscription();
        timeouts.push(setTimeout(function() {
          checkSubscription();
          timeouts.push(setTimeout(function() {
            // Otherwise report error.
            const message = "Something seems to have gone wrong with processing your account. However, your account may still have been processed. Please manually refresh the page to see if you have received Pro. If you did not, please contact Customer Support.";
            setStripeStatus(message); 
            setIsProcessing(false); 
          }, 20000));
        }, 5000));
      }, 5000));
    }, 3000));

    setTimeouts(timeouts);
  };

  const cancelWebSocketFallback = () => {
    timeouts.forEach(clearTimeout);
    setTimeouts([]);
  };  

  const checkSubscription = () => {
    const url = "subscriptions/current_sub";
    apiPost(url).then((resp) => {
      if (resp == null) return false;

      const date = new Date(resp.subscription.started_at);
      if ( TimeHelper.isDateLessThanTenMinutesOld(date) ) {
        setStripeStatus("Account confirmed!")
        handlePurchaseSuccessful()
      }
    }).catch(err => {
      console.log(err);
    });
  };

  const handleSubscriptionResponse = data => {
    console.log('handleSubscriptionResponse', data)
    if (data.status == "authorized") {
      setStripeStatus("Account confirmed!")
      handlePurchaseSuccessful()
    } else if ( data.status.match(/payment_action_required/) ) {
      handlePaymentActionRequired(data)
    } else if ( data.status.match(/failed|processing/) ) {
      if ( data.msg.match(/incomplete/) ) {
        setStripeStatus('Payment requires further authorization.')
      } else {
        setStripeStatus(data.msg)  
      }
    }
  };

  const handleRejectionResponse = data => {
    setStripeStatus(data.msg)
  };

  const handleSubmit = async (event) => {
    if (event) { event.preventDefault(); }

    if (elements == null) { return }

    setIsProcessing(true)
    setStripeStatus('Processing details now..')

    stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: values.name,
          email: values.email,
        },
        metadata: {
          coupon: optionalCode,
          plan_id: planId,
        }
      },
    }).then((result) => {
      if (result?.error) {
        const message = "Something seems to have gone wrong with processing your account. " + result?.error?.message;
        setStripeStatus(message)
        setIsProcessing(false)
      } else {
        setStripeStatus('Confirming credit card details..');
        createSubscription(
          {channel: 'PaymentsChannel', si_id: setupIntentId},
          {
            received: handleSubscriptionResponse,
            rejected: handleRejectionResponse,
          },
        );
        handleWebSocketFallback();

        cable().connection.webSocket.onerror = function () {
          // console.log('Websocket connection error!');
          handleWebSocketFallback();
        };

      }
    }).catch(err => {
      setStripeStatus(err.message)
    })

  };

  const handlePaymentActionRequired = (data) => {
    cancelWebSocketFallback();

    stripe.confirmCardPayment(data.payment_intent_secret)
      .then((result) => {
        if (result?.error) {
          const message = "Something seems to have gone wrong with processing your account. " + result?.error?.message;
          setStripeStatus(message)
          setIsProcessing(false)
        } else {
          setStripeStatus('Payment succeeded! Creating Brainscape Subscription.');
        }
      }).catch(err => {
        setStripeStatus(err.message)
    })
  }  

  const handleStripeChange = (e) => {
    if (e.complete) {
      setIsStripeFieldValid(true)
    } else if (e.error) {
      setStripeFieldErrorMessage(e.error.message)
      setIsStripeFieldValid(false)
    } else {
      setIsStripeFieldValid(false)
    }
  }

  const handleChange = (e) => {
    e.persist();

    let name = e.target.name;
    let val = e.target.value;

    validate(e, name, val);

    setValues({
        ...values,
        [name]:val,
    })
  }

  const handleCodeValidationReset = () => {
    setOptionalCode('');
    props.handleCodeValidationReset()
  }

  const handleAccessOrDiscountCodeChange = (discountCode) => {
    setOptionalCode(discountCode);

    if (discountCode.length == 0) {
      handleCodeValidationReset();
    }
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const validate = (e, name, value) => {
    switch (name) {
      case 'name':
        if (StringHelper.isBlank(value)) {
          setErrors({
              ...errors,
              name:'Please enter your name.'
          })
        } else if (value.trim().length >= 50) {
          setErrors({
            ...errors,
            name:'Name is too long (maximum is 50 characters).'
          })
        } else {
          let newObj = omit(errors, "name");
          setErrors(newObj);
        }

      break;

      case 'email':
        if (!StringHelper.isValidEmail(value)) {
          if (StringHelper.isBlank(value)) {
            setErrors({
              ...errors,
              email:'Please enter an email.'
            })
          } else {
            setErrors({
              ...errors,
              email:'A valid email is required.'
            })
          }
        } else {
            let newObj = omit(errors, "email");
            setErrors(newObj);
        }
      break;

        default:
          break;
    }
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  const renderRequiredFieldsMessage = () => {
    if (isDisabled) {
      return (
        <div className='required-fields-message'>
          (All fields are required)
        </div>
      )
    }
  }

  const renderNameField = () => {
    const classes = toClassStr(['name']);

    return (
      <>
        <TextField
          addClasses={classes}
          errorMessage={errors.name ?errors.name : ''}
          isInvalid={!!errors.name}
          id="name"
          name="name"
          hasInitialFocus={true}
          onChange={handleChange}
          placeholder="Cardholder's Name"
          type="name"
        />
      </>
    )
  }

  const renderEmailField = () => {
    const classes = toClassStr(['email']);

    return (
      <>
        <TextField
          addClasses={classes}
          errorMessage={errors.email ?errors.email : ''}
          isInvalid={!!errors.email}
          id="email"
          name="email"
          onChange={handleChange}
          placeholder="Your E-mail (to send receipt)"
          type="email"
        />
      </>
    )
  }

  const renderStripeField = () => {
    const errorStyling = isStripeFieldValid == false ? 'invalid-field' : '';
    const classes = toClassStr(['stripe-element-text-field', errorStyling]);

    return (
      <>
        <div className={classes}>
          <CardElement
            onChange={handleStripeChange}
            options={{
              style: {
                base: inputStyle,
              },
            }}
          />
        </div>

        {
          isStripeFieldValid == false && <span className='invalid-text'>{stripeFieldErrorMessage}</span>
        }
      </>
    )
  }

  const renderOptionalCodeField = () => {
    const invalidDiscountCodeMessage = props.isDiscountCodeValid ? '' : 'Invalid code!';
    const classes = toClassStr(['optional-code']);

    return (
      <>
        <div
          className='validate-code-link'
          onClick={handleToggleOptionalCodeInputField}
        >I have a code</div>

        {(isOptionalCodeInputFieldVisible || optionalCode) &&
          <>
            <TextField
              addClasses={classes}
              errorMessage={invalidDiscountCodeMessage ? invalidDiscountCodeMessage : ''}
              isInvalid={!!invalidDiscountCodeMessage}
              buttons={renderValidateCodeButton()}
              id="optionalCode"
              name="optionalCode"
              onChange={(e) => handleAccessOrDiscountCodeChange(e.target.value)}
              placeholder="Enter code (optional)"
              type="optionalCode"
              value={optionalCode}
            />
          </>
        }
      </>
    )
  }

  const renderValidateCodeButton = () => {
    if (props.codeValidationButtonLabel == 'apply') {
      return (
        <PillButton
          addClasses='apply-code-button'
          label={props.codeValidationButtonLabel}
          isDisabled={optionalCode == '' || props.isDiscountCodeProcessing ? true : false}
          isProcessing={props.isDiscountCodeProcessing}
          onClick={() => props.handleCodeValidation(optionalCode)}
        />
      );
    }

    return (
      <PillButton
        addClasses='apply-code-button'
        label={props.codeValidationButtonLabel}
        onClick={() => handleCodeValidationReset()}
      />
    );
  }

  const renderFormSubmitButton = () => {
    return (
      <>
        <PillButton
          addClasses='pill-button-emphasized'
          isDisabled={isDisabled}
          isProcessing={isProcessing}
          label={"Pay " + currentActivePlanPriceTotal}
          onClick={handleSubmit}
        />
        <input type='submit' className='hidden-submit-button' />
      </>
    )
  }

  return (
    <>
      <div className="stripe-status">{stripeStatus}</div>
      <form onSubmit={handleSubmit}>
        {renderRequiredFieldsMessage()}
        {renderNameField()}
        {renderEmailField()}
        {renderStripeField()}
        {renderOptionalCodeField()}
        {renderFormSubmitButton()}
      </form>
    </>
  );
}

export default StripeForm;
