
import {
  EditButton,
}                                     from '_views/shared/IconButton';
import EventManager                   from '@brainscape/event-manager';
import DynamicTooltipIcon                   from '_views/shared/DynamicTooltipIcon';
import PackMetaField                  from '_views/pack-detail/desktop/about/PackMetaField';
import PillButton                     from '_views/shared/PillButton';
import PropTypes                      from 'prop-types';
import Pulldown                       from '_views/shared/Pulldown';
import React                          from 'react';
import SimpleTextButton               from '_views/shared/SimpleTextButton';
import StringHelper                   from '_utils/StringHelper';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                      : PropTypes.string,
  isPackMetadataEditable          : PropTypes.bool,
  isPreviewing                    : PropTypes.bool,
  isProcessing                    : PropTypes.bool,
  isEditingPurpose                : PropTypes.bool,
  isEditingResource               : PropTypes.bool,
  metadata                        : PropTypes.object,
  onAddPurposeRequest             : PropTypes.func,
  onCancelEditPurposeRequest      : PropTypes.func,
  onEditPurposeRequest            : PropTypes.func,
  onUpdatePurposeRequest          : PropTypes.func,
  pack                            : PropTypes.object,
  segmentId                       : PropTypes.node,         
};


class PackAboutPurposeSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPurpose              : null,
      currentPurposeAttributes    : null,
      hasInvalidInput             : false,
      isAddPurposePulldownOpen    : false,
      purposeOptions              : [],
    }
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.setPurposeOptions();
    this.managePurposeData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.metadata.purposes != this.props.metadata.purposes) {
      this.managePurposeData();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isEditingSegmentClass = this.props.isEditingPurpose ? 'is-editing-segment' : '';
    const hasInvalidInputClass = this.state.hasInvalidInput ? 'has-invalid-input' : '';
    const classes = toClassStr(['segment about-tab-segment pack-about-purpose-segment', isEditingSegmentClass, hasInvalidInputClass]);

    return (

      <div className={classes}>
        <div className="segment-anchor" id={this.props.segmentId}></div>

        <div className="segment-header about-tab-segment-header purpose-header"> 
          <div className="segment-heading about-tab-segment-heading purpose-heading">Purpose</div>

          <div className="action-buttons">
            {this.renderPurposeTooltip()}
            {this.renderEditPurposeButton()}
          </div>

          {this.renderPurposeEditActionButtons()}
        </div>

        {this.renderPurposeAndAtrributes()}
      </div>
    );
  }

  renderPurposeTooltip() {
    if (!this.props.isPackMetadataEditable || this.props.isPreviewing) {
      return null;
    }

    return (
      <DynamicTooltipIcon 
        actions={null}
        addClasses="market-headline-tooltip"
        body="Your Class Purpose helps us customize your Brainscape experience, and could help other people find your flashcards (if you choose to keep them public)."
        hasDismissButton={true}
        heading={null}
        position="right"
        subHeading={null}
      />
    );
  }

  renderEditPurposeButton() {
    if (!this.state.currentPurpose || !this.props.isPackMetadataEditable || this.props.isEditingResource || this.props.isPreviewing) {
      return null;
    }

    return (
      <EditButton 
        addClasses="edit-purpose-button"
        onClick={this.handleEditButtonClick}
      />
    );
  }

  renderPurposeEditActionButtons() {
    if (!(this.props.isPackMetadataEditable && this.props.isEditingPurpose)) {
      return null;
    }

    const saveButtonLabel = (this.props.isMobileViewportSize) ? 'Save' : 'Save changes';

    return (
      <div className="edit-action-buttons">

        <SimpleTextButton
          addClasses="cancel-button cancel-edit-purpose-button"
          label="Cancel"
          onClick={this.handleCancelButtonClick}
        />

        <PillButton
          addClasses="save-button save-purpose-changes-button"
          isDisabled={!this.state.currentPurpose}
          isProcessing={this.props.isProcessing}
          label={saveButtonLabel}
          onClick={this.handleSaveChangesButtonClick}
        />
      </div>
    );
  }

  renderPurposeAndAtrributes = () => {
    if (!this.state.currentPurpose && (!this.props.pack.isUserPackAdmin || this.props.isPreviewing)) {
      return this.renderGeneralLearningItem();
    }

    return (
      <div className="purpose-and-attributes">
        {this.renderShowablePurposeAndAttributeFields()}
        {this.renderEditablePurposeAndAttributeFields()}
      </div>
    );
  }

  renderShowablePurposeAndAttributeFields() {
    if (this.props.isEditingPurpose) {
      return null;
    }

    return (
      <div className="purpose-and-attributes-display">
        {this.renderPurpose()}
        {this.renderPurposeAttributes()}
        {this.renderAddPurposePrompt()}
      </div>
    );
  } 

  renderEditablePurposeAndAttributeFields() {
    if (!this.props.isEditingPurpose) {
      return null;
    }

    return (
      <form className="purpose-and-attributes-form" onSubmit={this.handleFormSubmit}>
        {this.renderPurpose()}
        {this.renderPurposeAttributes()}
        {this.renderAddPurposePrompt()}
        <input type="submit" className="hidden-submit-button" />
      </form>
    );
  } 

  renderPurpose() {
    if (!this.state.currentPurpose) {
      return null;
    }

    const purpose = this.state.currentPurpose;
    const fieldClass = StringHelper.toKebobCase(purpose.fieldLabel);
    const classes = toClassStr(['purpose', fieldClass]);

    return (
      <PackMetaField 
        addClasses={classes}
        fieldId={purpose.fieldId}
        fieldKey={purpose.fieldKey}
        fieldLabel={null}
        fieldType="tag"
        fieldValue={purpose.fieldLabel}
        isEditing={this.props.isEditingPurpose}
        key={purpose.fieldKey}
        onChange={this.handleFieldChange}
        onRemoveTagRequest={this.handleRemovePurposeRequest}
        options={purpose.options}
        placeholder={purpose.placeholder}
        tooltip={purpose.tooltip}
      />
    );
  }

  renderPurposeAttributes() {
    if (!(this.state.currentPurpose && this.state.currentPurposeAttributes && Object.keys(this.state.currentPurposeAttributes).length > 0)) {
      return null;
    }

    const purposeAttributes = this.state.currentPurposeAttributes;
    const attributeKeys = Object.keys(purposeAttributes);

    if (attributeKeys.length == 0) {
      return null;
    }

    const attributeItems = attributeKeys.map((key, i) => {
      return this.renderPurposeAttribute(purposeAttributes[key]); 
    })

    const reactKey = StringHelper.toKebobCase(this.state.currentPurpose.fieldLabel + '-attributes');
    const classes = toClassStr(['purpose-attributes', reactKey]);

    return (
      <div className={classes} key={reactKey}>
        {attributeItems}
      </div>
    );
  }

  renderPurposeAttribute(purposeAttribute) {
    const fieldClass = StringHelper.toKebobCase(purposeAttribute.fieldLabel);
    const classes = toClassStr(['purpose-attribute', fieldClass]);
    const fieldType = (purposeAttribute.pulldownOptions && purposeAttribute.pulldownOptions.length > 0) ? 'pulldown' : 'textField';

    return (
      <PackMetaField
        addClasses={classes}
        fieldId={purposeAttribute.fieldId}
        fieldKey={purposeAttribute.fieldKey}
        fieldLabel={purposeAttribute.fieldLabel}
        fieldType={fieldType}
        fieldValue={purposeAttribute.fieldValue}
        isEditing={this.props.isEditingPurpose}
        key={purposeAttribute.fieldKey}
        onOverCharLimitChange={this.handleOverCharLimitChange}
        onChange={this.handleFieldChange}
        options={purposeAttribute.pulldownOptions}
        placeholder={purposeAttribute.placeholder}
        tooltip={purposeAttribute.tooltip}
      />
    );
  }

  renderGeneralLearningItem() {
    return (
      <div className="purpose-and-attributes">
        <PackMetaField 
          addClasses={'purpose general-learning'}
          fieldType="pulldown"
          fieldValue="General Learning"
          key={'general_learning'}
          label="Purpose"
        />
      </div>
    );
  }

  renderAddPurposePrompt() {
    if (!(this.props.pack.isUserPackAdmin && this.props.pack.isUgs)) {
      return null;
    }

    if (this.state.currentPurpose) {
      return null;
    }

    if (this.props.isPreviewing) {
      return null;
    }

    if (this.props.isEditingResource && !this.props.isEditingPurpose) {
      // something other than purpose is being edited
      return null;
    }

    return (
      <div className="add-purpose-prompt">
        <div className="pack-purpose add-new-purpose">

          <Pulldown
            addClasses="purpose-pulldown"
            isOpen={this.state.isAddPurposePulldownOpen}
            options={this.state.purposeOptions}
            selectedValue={-1}
            shouldSuppressNullOption={true}
            onButtonClick={this.handleAddPurposePulldownButtonClick}
            onOptionClick={this.handleAddPurposePulldownOptionClick}
            onOutsideClick={this.handleAddPurposePulldownOutsideClick}
            placeholderText="Add a Class Purpose..."
          />
        </div>

        <DynamicTooltipIcon 
          actions={null}
          addClasses="purpose-choice-tooltip"
          body={this.renderAddPurposePromptTooltipBody()}
          hasDismissButton={true}
          heading={'Purpose'}
          position="right"
          subHeading={null}
        />
      </div>
    );
  }

  renderAddPurposePromptTooltipBody() {
    return (
      <>
        <p className="body-text"><span className="purpose-label">School / University:</span><span className="purpose-explanation">e.g. prep for quizzes and tests.</span></p>
        <p className="body-text"><span className="purpose-label">Standardized Test:</span><span className="purpose-explanation">e.g. entrance exams like SAT or MCAT</span></p>
        <p className="body-text"><span className="purpose-label">Professional Certification:</span><span className="purpose-explanation">e.g. Series 7, USMLE</span></p>
        <p className="body-text"><span className="purpose-label">Job Skills:</span><span className="purpose-explanation">e.g. company training, or other skills not related to a standardized certification</span></p>
        <p className="body-text"><span className="purpose-label">Foreign Language:</span><span className="purpose-explanation">e.g. Arabic (general; NOT for a specific class at school)</span></p>
      </>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddPurposePulldownButtonClick = () => {
    const isPulldownOpen = this.state.isAddPurposePulldownOpen;

    if (!isPulldownOpen) {
      this.props.onEditPurposeRequest();
    }

    this.setState({
      isAddPurposePulldownOpen: !isPulldownOpen,
    });
  }

  handleAddPurposePulldownOptionClick = (optionId) => {
    this.setState({
      isAddPurposePulldownOpen: false,
    });

    const purpose = this.props.metadata.purposes[optionId];

    // add this purpose to the pack only if it isn't already associated
    if (!purpose.value) {
      this.props.onAddPurposeRequest(purpose.field_id);
    }
  }

  handleAddPurposePulldownOutsideClick = () => {
    this.setState({
      isAddPurposePulldownOpen: false,
    });
  }

  handleCancelButtonClick = () => {
    this.setState({
      isAddPurposePulldownOpen: false,
    });

    this.managePurposeData();

    if (this.props.onCancelEditPurposeRequest) {
      this.props.onCancelEditPurposeRequest();
    }
  }

  handleEditButtonClick = () => {
    if (this.props.onEditPurposeRequest) {
      this.props.onEditPurposeRequest();
    } 
  }

  handleFieldChange = (fieldId, fieldValue) => {
    let currentPurposeAttributes = {...this.state.currentPurposeAttributes};
    const newValue = (fieldValue != -1) ? fieldValue : "";
    currentPurposeAttributes[fieldId].fieldValue = newValue;

    this.setState({
      currentPurposeAttributes: currentPurposeAttributes,
      hasInvalidInput: false,
    })
  }

  handleFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.handleSaveChanges();
  }

  handleOverCharLimitChange = (fieldId, isOverLimit) => {
    let attributes = {...this.state.currentPurposeAttributes};
    let attribute = attributes[fieldId];

    attribute.isOverLimit = isOverLimit;

    this.setState({
      currentPurposeAttributes: attributes,
    });
  }

  handleRemovePurposeRequest = (fieldId) => {
    this.triggerCautionModalOpen({
      actionText                      : 'remove a purpose from your class metadata',
      cancelButtonText                : 'No, Cancel',
      onCloseRequest                  : () => {
        this.setState({
          isProcessing: false,
        });
      },
      onResolution                    : () => {
        this.removePurposeAndAttributes(fieldId);
        this.triggerCautionModalClose();
      },
      resolveButtonText               : 'Yes, continue',
    });
  }

  handleSaveChanges = () => {
    // form an array of pack meta fields (metum)
    const purpose = this.state.currentPurpose;
    let metum = [];

    metum.push({
      field_id: purpose.fieldId, 
      value: purpose.fieldValue,
    });

    const attributes = this.state.currentPurposeAttributes;

    if (attributes) {
      const attributeKeys = Object.keys(attributes);

      for (let i=0; i<attributeKeys.length; i++) {
        const key = attributeKeys[i];

        if (attributes[key].isOverLimit) {
          this.setState({
            hasInvalidInput: true,
          });

          return false; // breaks out of loop *and* function, does not submit changes
        }

        metum.push({
          field_id: attributes[key].fieldId, 
          value: attributes[key].fieldValue,
        });
      };
    } 

    this.props.onUpdatePurposeRequest(metum);
  }

  handleSaveChangesButtonClick = () => {
    this.handleSaveChanges();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerCautionModalClose(viewProps) {
    EventManager.emitEvent('caution-modal:close', viewProps);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  generateAttributeOptions = (metaFieldOptions) => {
    let pulldownOptions = [];

    metaFieldOptions.forEach((option, i) => {
      pulldownOptions.push({
        id: option,
        label: option,
      });
    });

    pulldownOptions.unshift({
      id: -1,
      label: 'Choose One...',
    });

    return pulldownOptions;
  }

  generatePurposeOptions() {
    const purposes = this.props.metadata.purposes;
    const purposeKeys = Object.keys(purposes);
    let purposeOptions = [];

    purposeKeys.forEach((key, i) => {
      const purpose = purposes[key];
      purposeOptions.push({
        id: purpose.field_key,
        label: purpose.field_label,
      });
    });

    return purposeOptions;
  }

  managePurposeData() {
    // determine currently set purpose
    const purposes = this.props.metadata.purposes;
    let purpose;
    const purposeKeys = Object.keys(purposes);
    let currentPurpose = null;
    let currentPurposeAttributes = null;

    for (let i=0; i < purposeKeys.length; i++) {
      purpose = purposes[purposeKeys[i]];

      if (purpose.value == true) {

        currentPurpose = {
          fieldId           : purpose.field_id,
          fieldKey          : purpose.field_key,
          fieldLabel        : purpose.field_label,
          fieldValue        : purpose.value,
          placeholder       : purpose.placeholder,
          pulldownOptions   : this.state.purposeOptions,
          tooltip           : purpose.tool_tip,
        };

        break;
      }
    }

    // set up object for current purpose holding its child attributes
    if (currentPurpose && purpose && purpose.children) {
      currentPurposeAttributes = {};

      purpose.children.forEach((child, index) => {
        const attributeOptions = (child.options) ? this.generateAttributeOptions(child.options) : null;

        currentPurposeAttributes[child.field_id] = {
          fieldId           : child.field_id,
          fieldKey          : child.field_key,
          fieldLabel        : child.field_label,
          fieldValue        : child.value,
          pulldownOptions   : attributeOptions,
          placeholder       : child.placeholder,
          tooltip           : child.tool_tip,
        };
      });
    }

    // store in state
    this.setState({
      currentPurpose: currentPurpose,
      currentPurposeAttributes: currentPurposeAttributes,
    })
  }

  removePurposeAndAttributes = () => {
    // form an array of pack meta fields (metum)
    const purpose = this.state.currentPurpose;
    let metum = [];
    
    metum.push({
      field_id: purpose.fieldId, 
      value: false,
    });

    const attributes = this.state.currentPurposeAttributes;

    if (attributes) {
      const attributeKeys = Object.keys(attributes);

      attributeKeys.forEach((key, index) => {
        metum.push({
          field_id: attributes[key].fieldId, 
          value: "",
        });
      });
    }

    this.props.onUpdatePurposeRequest(metum);
  }

  setPurposeOptions = () => {
    this.setState({
      purposeOptions: this.generatePurposeOptions(),
    })
  }
}

PackAboutPurposeSegment.propTypes = PT;

export default PackAboutPurposeSegment;
