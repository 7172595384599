
import { BackButton }     from '_views/shared/IconButton';
import EventManager       from '@brainscape/event-manager';
import React              from 'react';
import Spinner            from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

class BruceButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    /*
    this.props:
      addClasses,
      isProcessing,
      onClick,
      backArrow,
    */

    this.events = new EventManager();
    this.elem = null;
  }
 


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isProcessingClass = (this.props.isProcessing) ? 'is-processing' : '';
    const hasTooltipClass = this.props.tooltipContent ? 'has-tooltip' : '';
    const hasBackArrow = this.props.backArrow ? 'has-back-arrow' : '';
    let classes = toClassStr(['icon-button', 'bruce-button', isProcessingClass, hasTooltipClass, hasBackArrow, this.props.addClasses]);

    if (hasBackArrow) {
      return (
        <div
          className={classes}
          onClick={(e) => this.handleButtonClick(e)}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          ref={(elem) => { this.elem = elem }} 
        >
          <i className="bruce-icon" />
          <BackButton
            addClasses="branding-back-button"
            onClick={(e) => this.handleButtonClick(e)}
          />
          {this.renderSpinner()}
        </div>
      );
    }

    return (
      <div
        className={classes}
        onClick={(e) => this.handleButtonClick(e)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={(elem) => { this.elem = elem }} 
      >
        <i className="bruce-icon" />
        {this.renderSpinner()}
      </div>
    );
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleMouseEnter = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }
  }

  handleMouseLeave = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      content: this.props.tooltipContent,
      elem: this.elem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };
}

export default BruceButton;
