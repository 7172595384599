
import EventManager             from '@brainscape/event-manager';
import PropTypes                from 'prop-types';
import React                    from 'react';

import packLearnerTransform     from '_models/packLearnerTransform';

import { toClassStr } from '_utils/UiHelper';

const PT = {  
  addClasses:             PropTypes.string,
  children:               PropTypes.node,
  sortDirection:          PropTypes.string,
  isSortable:             PropTypes.bool,
  label:                  PropTypes.string,
  packId:                 PropTypes.node,
  sortKey:                PropTypes.string,
  userId:                 PropTypes.node,
};


class LearnersColumnHeading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isSortableClass = (this.props.isSortable) ? 'is-sortable' : '';
    const sortDirectionClass = (this.props.isSortable) ? `sort-${this.props.sortDirection}` : '';
    const classes = toClassStr(['learners-column-heading', isSortableClass, sortDirectionClass, this.props.addClasses]);

    return (
      <div className={classes}>
        <div className="label-and-tooltip" onClick={this.handleColumnLabelClick}>
          <div className="column-heading-label">{this.props.label}</div>
          {this.props.children}
        </div>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleColumnLabelClick = (e) => {
    e.stopPropagation();

    if (!this.props.isSortable) {
      return false;
    }

    let newDirection = '';

    switch (this.props.sortDirection) {
      case 'none':
        newDirection = 'asc';
      break;
      case 'asc':
        newDirection = 'desc';
      break;
      case 'desc':
        newDirection = 'none';
      break;
    }

    this.updateLearnerTransforms(newDirection);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updateLearnerTransforms = (direction) => {
    if (direction == 'none') {
      packLearnerTransform.destroy(this.props.userId, this.props.packId, 'sorters', {
        key:      this.props.sortKey,
      });

      return true;
    }

    packLearnerTransform.update(this.props.userId, this.props.packId, 'sorters', {
      direction:  direction,
      key:        this.props.sortKey,
    });
  }
}

LearnersColumnHeading.propTypes = PT;

export default LearnersColumnHeading;
