
import EventManager   from '@brainscape/event-manager';
import PropTypes      from "prop-types";
import React          from "react";
import useCardSlider  from "_views/shared/smart-cards/animation/useCardSlider.js";
import useDimensions  from "react-cool-dimensions";

const CardSlider = ({
  fontSizeCalc,
  header,
  hi,
  lo,
  ptH,
  ptW,
  revealed,
  revealMode,
  style,
  baseFontSize,
  transitionState,
}) => {
  const scRef = React.useRef();
  const [hdRef, hdH] = useHeight();
  const [hiRef, hiH] = useHeight();
  const [loRef, loH] = useHeight();
  const [hrRef, hrH] = useHeight();

  const [hiEffMin, loEffMin] = useCardSlider(
    ptH,
    hdH,
    hiH,
    loH,
    hrH,
    scRef,
    revealed
  );

  let fontSize = baseFontSize || 11;

  if (ptW && ptH) {
    fontSize = (fontSizeCalc != null && fontSizeCalc !== "null") 
        ? eval(fontSizeCalc)
        : Math.max(11, Math.round(Math.min(ptW, ptH) * 0.05));
  }

  const parentStyle = {
    ...styles.parent,
    ...style,
    fontSize: fontSize,
  };

  return (
    <div className="card-slider" style={parentStyle}>
      <div style={styles.parent}>
        <div className="card-slider-header" ref={hdRef}>{header}</div>
        <div className="card-slider-main" style={{ ...styles.scroll, top: hdH }} ref={scRef}>
          <Label mode="q" revealMode={revealMode} />
          <CardSide face="question" ref={hiRef} minHeight={hiEffMin}>
            {React.cloneElement(hi, {
              transitionState: transitionState, 
              topCardMinHeight: hiEffMin,
              bottomCardMinHeight: loEffMin,
              parentHeight: ptH,
            })}
            {/* {hi} */}
          </CardSide>
          {revealed && (
            <>
              <Hr ref={hrRef} />
              <Label mode="a" revealMode={revealMode} />
              <CardSide face="answer" ref={loRef} minHeight={loEffMin}>
                {React.cloneElement(lo, { 
                  transitionState: transitionState, 
                  topCardMinHeight: hiEffMin,
                  bottomCardMinHeight: loEffMin,
                  parentHeight: ptH,
                })}
                {/* {lo} */}
              </CardSide>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

CardSlider.propTypes = {
  fontSizeCalc: PropTypes.string,
  header: PropTypes.object.isRequired,
  hi: PropTypes.object.isRequired,
  lo: PropTypes.object.isRequired,
  ptH: PropTypes.number.isRequired,
  ptW: PropTypes.number.isRequired,
  revealed: PropTypes.bool,
  revealMode: PropTypes.object.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const CardSide = React.forwardRef(({ children, minHeight, face }, ref) => {
  const st = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };

  if (minHeight) st.minHeight = minHeight;

  return (
    <div className={`card-slider-side ${face}-face`} style={st}>
      <div className="card-slider-side-content" ref={ref}>
        {children}
      </div>
    </div>
  );
});

CardSide.propTypes = { minHeight: PropTypes.number };

const Label = ({ mode, revealMode }) => {
  // Note: The label div has to have a height of 0 in order to slide the
  //       label value under the card contents.
  return (
    <div className="card-slider-label" style={{ height: 0 }}>
      <div className="card-slider-label-inner">
        {revealMode.effMode(mode).toUpperCase()}
      </div>
    </div>
  );
};

Label.propTypes = {
  mode: PropTypes.string.isRequired,
  revealMode: PropTypes.object.isRequired,
};

const Hr = React.forwardRef(({}, ref) => (
  <div ref={ref} className="card-slider-hr">
    <div className="card-slider-hr-inner" />
  </div>
));

const useHeight = () => {
  const { observe, height } = useDimensions({ useBorderBoxSize: true });

  return [observe, height || 0];
};

const styles = {
  parent: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  scroll: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
    overflowX: "hidden",
  },
};

export { CardSlider as default };
