import Bubble           from '_views/shared/Bubble';
import PillButton       from '_views/shared/PillButton';
import React            from 'react';

class CramPaywallBubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {questionHover: false};

    /*
      this.props:
        addClasses,
        message,
        onClose,
        onSetStudyReminder,
        onSkipTheBreak,
        reminderProcessing,
    */
  }

  render() {
    return (
        <Bubble
          addClasses='checkpoint-bubble'
          expansionDelay={0}
          isEscapeKeyActive={true}
          isScriptedClose={true}
          onClose={this.props.onClose}
          onCloseRequest={this.props.onClose}
          shouldClose={false}
          shouldContract={false}
          shouldExpand={true}
          size='full'
        >
          <div className='checkpoint-body'>
            <div style={{textAlign: 'center'}}>
              <img
                src='/assets/bsc-sleeping.png'
                alt='Brainscape Icon'
                style={{height: '50vmin', maxWidth: 894, maxHeight: 912}}
              />
            </div>

            <div style={{textAlign: 'center', paddingTop: '1em'}}>
              <div style={{fontSize: '1.2em'}}>
                {this.props.message}
              </div>
              <div style={{color: 'gray', fontSize: '0.8em', fontStyle: 'italic'}}>
                (30 free cards every 2 hours)
              </div>
            </div>

            <div style={{textAlign: 'center', padding: '1em 10vw'}}>
              You may still <b>create</b> & study your own cards, <b>browse</b> other classes, or study classes to which you've been <b>invited</b>.
            </div>

            <div className='action-buttons'>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <PillButton
                  addClasses='pill-button-medium'
                  isProcessing={this.props.reminderProcessing}
                  label='Set Study Reminder'
                  onClick={this.props.onSetStudyReminder}
                  type='neutral'
                />
                <PillButton
                  addClasses='pill-button-medium'
                  isProcessing={false}
                  label='Skip The Break'
                  onClick={this.props.onSkipTheBreak}
                />
              </div>
            </div>
          </div>
        </Bubble>
    );
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown, true);
  }

  handleKeydown = (e) => {
    if (this.props.reminderProcessing) { return; }

    switch (e.keyCode) {
      case 27: // escape
      case 32: // space bar
      case 48: // 0
      case 49: // 1
      case 50: // 2
      case 51: // 3
      case 52: // 4
      case 53: // 5
      case 54: // 6
      case 55: // 7
      case 56: // 8
      case 57: // 9
        // Stop the study controls from reacting to keyboard input.
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  }
}

export default CramPaywallBubble;
