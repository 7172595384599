
import mainShared from '_core/main-shared';

var jQuery = require('jquery');
window.jQuery = jQuery;
window.$ = jQuery;

/* 
  The below uses react rails to inspect DOM for elems with a 'data-react-class' attribute and import all of the JS for the component named in that attribute.
*/
var componentRequireContext = require.context("./../app-pages", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

const mainApp = {
  init: function() {
    mainShared.init();
  }
};

export default mainApp;
