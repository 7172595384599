
import {
  EditButton,
}                                     from '_views/shared/IconButton';

import DynamicTooltipIcon             from '_views/shared/DynamicTooltipIcon';
import PillButton                     from '_views/shared/PillButton';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import SimpleTextButton               from '_views/shared/SimpleTextButton';
import TextareaField                  from '_views/shared/TextareaField';
import UiHelper                       from '_utils/UiHelper';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                      : PropTypes.string,
  hasHeadlineResource             : PropTypes.bool,
  isPackMetadataEditable          : PropTypes.bool,
  isPreviewing                    : PropTypes.bool,
  isProcessing                    : PropTypes.bool,
  isEditingHeadline               : PropTypes.bool,
  isEditingResource               : PropTypes.bool,
  metadata                        : PropTypes.object,
  onCancelEditHeadlineRequest     : PropTypes.func,
  onEditHeadlineRequest           : PropTypes.func,
  onUpdateHeadlineRequest         : PropTypes.func,
  pack                            : PropTypes.object,
  segmentId                       : PropTypes.node,         
};


class PackAboutHeadlineSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caption: "",
      hasInvalidInput: false,
      isOverCharLimit: false,
      headlineText: "",
    }
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.setResources();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.metadata != this.props.metadata) {
      this.manageResources(prevProps);
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isEmptyClass = (!this.props.hasHeadlineResource && this.props.isPackMetadataEditable && !this.props.isPreviewing) ? 'is-empty' : '';
    const isEditingSegmentClass = this.props.isEditingHeadline ? 'is-editing-segment' : '';
    const hasInvalidInputClass = this.state.hasInvalidInput ? 'has-invalid-input' : '';
    const classes = toClassStr(['segment about-tab-segment pack-about-headline-segment', isEditingSegmentClass, hasInvalidInputClass, isEmptyClass]);

    return (
      <div className={classes}>
        <div className="segment-anchor" id={this.props.segmentId}></div>
        {this.renderHeadlineHeader()}
        {this.renderHeadlineText()}
        {this.renderHeadlineTextareaField()}
      </div>
    );
  }

  renderHeadlineHeader() {
    return (
      <div className="segment-header about-tab-segment-header headline-header"> 
        <div className="segment-heading about-tab-segment-heading headline-heading">Headline</div>

        <div className="action-buttons">
          {this.renderHeadlineTooltip()}
          {this.renderEditHeadlineButton()}
        </div>

        {this.renderHeadlineEditActionButtons()}
      </div>
    );
  }

  renderHeadlineTooltip() {
    if (!this.props.isPackMetadataEditable || this.props.isPreviewing) {
      return null;
    }

    return (
      <DynamicTooltipIcon 
        actions={null}
        addClasses="market-headline-tooltip"
        body="This text appears as a short summary of your class in various places in Brainscape."
        hasDismissButton={true}
        heading={null}        
        subHeading={null}
        position="right"
      />
    );
  }

  renderEditHeadlineButton() {
    if (!this.props.isPackMetadataEditable || this.props.isEditingResource || this.props.isPreviewing) {
      return null;
    }

    return (
      <EditButton 
        addClasses="edit-headline-button"
        onClick={this.handleEditButtonClick}
      />
    );
  }

  renderHeadlineEditActionButtons() {
    if (!(this.props.isPackMetadataEditable && this.props.isEditingHeadline)) {
      return null;
    }

    const saveButtonLabel = (this.props.isMobileViewportSize) ? 'Save' : 'Save changes';

    return (
      <div className="edit-action-buttons">

        <SimpleTextButton
          addClasses="cancel-button cancel-edit-headline-button"
          label="Cancel"
          onClick={this.handleCancelButtonClick}
        />

        <PillButton
          addClasses="save-button save-headline-changes-button"
          isProcessing={this.props.isProcessing}
          label={saveButtonLabel}
          onClick={this.handleSaveChangesButtonClick}
        />
      </div>
    );
  }  

  renderHeadlineText() {
    if (this.props.isEditingHeadline) {
      return null;
    }

    if (!this.props.hasHeadlineResource && this.props.isPackMetadataEditable && !this.props.isPreviewing) {
      return (
        <div className="headline-text">
          No headline. Click the Edit button to add a headline.
        </div>
      );
    }

    if (!this.props.hasHeadlineResource) {
      return this.renderDefaultHeadlineText();
    }

    return (
      <div className="headline-text">
        {this.props.metadata.global.headline.value}
      </div>
    );
  }

  renderDefaultHeadlineText() {
    if (this.props.pack.isMarket) {
      return (
        <div className="headline-text">
          This certified class was created by Brainscape or one of our content partners. It has been thoroughly reviewed for accuracy, comprehensiveness, and learning value.
        </div>
      );
    }

    return (
      <div className="headline-text">
        This class was created by Brainscape user <span className="profile-link" onClick={this.handleProfileLinkClick}>{this.props.pack.creatorName}</span>. Visit their <span className="profile-link" onClick={this.handleProfileLinkClick}>profile</span> to learn more about the creator.
      </div>
    );
  }

  renderHeadlineTextareaField = () => {
    if (!this.props.isEditingHeadline) {
      return null;
    }

    return (
      <TextareaField
        addClasses="pack-headline"
        caption={this.state.caption}
        charLimit={280}
        id="pack-headline"
        onChange={this.handleHeadlineTextChange}
        onOverCharLimitChange={this.handleOverCharLimitChange}
        placeholder="Enter a short description of your class"
        value={this.state.headlineText}
      />
    );
  }
      

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  handleEditButtonClick = () => {
    if (this.props.onEditHeadlineRequest) {
      this.props.onEditHeadlineRequest();
    } 
  }

  handleCancelButtonClick = () => {
    this.setResources();

    this.setState({
      caption: "",
      hasInvalidInput: false,
      isOverCharLimit: false,
    });

    if (this.props.onCancelEditHeadlineRequest) {
      this.props.onCancelEditHeadlineRequest();
    }
  }

  handleHeadlineTextChange = (e) => {
    this.setState({
      hasInvalidInput: false,
      headlineText: e.target.value,
    })
  }

  handleOverCharLimitChange = (isOverLimit) => {
    const caption = isOverLimit ? 'Your headline exceeds the character limit' : '';

    this.setState({
      caption: caption,
      isOverCharLimit: isOverLimit,
    })
  }

  handleSaveChangesButtonClick = () => {
    if (this.state.isOverCharLimit) {
      this.setState({
        hasInvalidInput: true,
      });

      return false;
    }

    if (this.props.onUpdateHeadlineRequest) {
      this.props.onUpdateHeadlineRequest(this.state.headlineText);
    }
  }

  handleProfileLinkClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    UiHelper.openInNewTab(this.props.pack.creatorProfilePath);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  hasResource = (resourceKey, testProps) => {
    const props = testProps || this.props;

    return !!(props.metadata && props.metadata.global && props.metadata.global[resourceKey] && props.metadata.global[resourceKey].value);
  }

  hasResourceChanged = (resourceKey, prevProps) => {
    if (!(this.hasResource(resourceKey, prevProps) && this.hasResource(resourceKey, this.props))) { 
      return false; 
    }

    const prevResourceValue = prevProps.metadata.global[resourceKey].value;
    const resourceValue = this.props.metadata.global[resourceKey].value;

    return (resourceValue != prevResourceValue);
  }

  manageResources = (prevProps) => {
    if (this.hasResourceChanged('headline', prevProps)) {
      this.setHeadlineText();
    }
  }

  setHeadlineText = () => {
    const headlineText = (this.hasResource('headline')) ? this.props.metadata.global.headline.value : "";

    this.setState({
      headlineText: headlineText,
    })
  }

  setResources = () => {
    this.setHeadlineText();
  }
}

PackAboutHeadlineSegment.propTypes = PT;

export default PackAboutHeadlineSegment;
