
import React            from 'react';
import TextField        from '_views/shared/TextField';

class TextareaField extends React.Component {

  // NOTE: This component is here to support existing calls to TextareaField.
  // Methods on TextField invoked via refs will not successfully execute.
  // You should use TextField directly with type of "textarea" for best results.

  render() {
    return (
      <TextField 
        {...this.props}
        addClasses="textarea-field"
        type="textarea"
      />
    );
  }
}

export default TextareaField;
