import humanizeDuration  from 'humanize-duration';

class HumanDuration {

  /*
    inSeconds: Integer
    units: ['h','d','s']
  */

  static render(props) {
    const units = props.units || ["d", "h", "m", "s"]

    const humanConfig = {
      language: "shortEn", 
      largest: 2, 
      units: units, 
      round: true,
      languages: {
        shortEn: {
          y: () => "y",
          mo: () => "mo",
          w: () => "w",
          d: () => "d",
          h: () => "hr",
          m: () => "min",
          s: () => "s",
        },
      },        
    }
    const humanizedTimeLeft = humanizeDuration(props.inSeconds * 1000, humanConfig)  

    return humanizedTimeLeft;
  }

}

export default HumanDuration;