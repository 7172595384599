
import PropTypes                from 'prop-types';
import React                    from 'react';
import TransitionWrapper        from '_views/shared/TransitionWrapper';
import {toClassStr}             from '_utils/UiHelper';

import {CloseButton}            from '_views/shared/IconButton';


const PT = {
  addClasses:                   PropTypes.string,
  isOpen:                       PropTypes.bool,
};


class QuickTipsOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldRender: this.props.isOpen,
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        shouldRender: true,
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    if (!this.state.shouldRender) {
      return null;
    }

    const classes = toClassStr(['quick-tips-overlay', this.props.addClasses]);

    return (
      <TransitionWrapper
        addClasses="quick-tips-overlay-wrapper"
        onTransitionedOut={() => this.handleTransitionedOut()}
        shouldTransitionIn={this.props.isOpen}
        shouldTransitionOut={!this.props.isOpen}
        transitionInDelay={100}
        transitionInDuration={500}
        transitionOutDuration={500}
      >

        <div className={classes}>

          <CloseButton 
            addClasses="close-overlay-button"
            onClick={() => this.handleCloseOverlayButtonClick()}
          />

          <div className="tips-section overall-tips">
            <h4 className="overlay-section-header">Overall Tips</h4>
            <ul className="tips-list">
              <li className="tip">Open the editor sidebar <span className="sidebar-button"></span> to perform bulk actions like sorting, copying, more.</li>
              <li className="tip">Use the action button <span className="card-actions-button"></span> to the right of each card to insert or duplicate.</li>
              {this.renderAdvancedCardsPitch()}
            </ul>
          </div>

          {this.renderAdvancedCardsTips()}
                    
          <div className="tips-section keyboard-shortcuts">
            <h4 className="overlay-section-header">Keyboard Shortcuts</h4>
            <ul className="tips-list">
              <li className="tip"><span className="keyboard-key">Tab</span> to go to next card field</li>
              <li className="tip"><span className="keyboard-key">Shift</span><span className="keyboard-key">Tab</span> to go to previous card field</li>
              <li className="tip"><span className="keyboard-key">Ctrl</span><span className="keyboard-key">Enter</span> to save</li>
              <li className="tip"><span className="keyboard-key">Esc</span> to discard changes</li>
            </ul>
          </div>
        </div>
      </TransitionWrapper>
    );
  }

  renderAdvancedCardsPitch = () => {
    if (this.props.format == 'html') {
      return null;
    }

    return (
      <li className="tip">For cards of a more complex nature, try using Brainscape Advanced Cards<span className="advanced-cards-option"></span>.</li>
    );
  }

  renderAdvancedCardsTips = () => {
    if (this.props.format != 'md') {
      return null;
    }

    return (
      <div className="tips-section advanced-cards-tips">
        <h4 className="overlay-section-header">Advanced Cards Tips</h4>
        <ul className="tips-list">
          <li className="tip">Use manual styles sparingly to allow Brainscape auto-styles to provide maximum benefit.</li>
          <li className="tip">Mouse over each field to reveal a tooltip explaining its usage.</li>
          <li className="tip">Add Images <span className="add-image-button"></span> and Sounds <span className="add-sound-button"></span> to supercharge learning.</li>
          <li className="tip"><a href="https://brainscape.zendesk.com/hc/en-us/articles/8961531870221-Brainscape-Flashcard-Formatting-Tips" target="_blank">More Flashcard Formatting Help</a></li>          
        </ul>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseOverlayButtonClick = () => {
    if (this.props.onCloseRequest) {
      this.props.onCloseRequest();
    }
  };

  handleTransitionedOut = () => {
    const onClosed = this.props.onClosed ? this.props.onClosed : () => {};
    const onContracted = this.props.onContracted ? this.props.onContracted : () => {};
    const onTransitionedOut = this.props.isContracting ? onContracted() : onClosed();

    this.setState({
      shouldRender: false
    }, () => {
      if (onTransitionedOut) {
        onTransitionedOut();
      }
    });
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

QuickTipsOverlay.propTypes = PT;

export default QuickTipsOverlay;
