
import React                  from 'react';
import TransitionWrapper      from '_views/shared/TransitionWrapper';
import UiHelper               from '_utils/UiHelper';
import brainscapeLogoWhite    from '_images/shared/brainscape-logo-white.png'

import {toClassStr}           from '_utils/UiHelper';

import {
  CloseButton,
}    from '_views/shared/IconButton';


class SiteMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldRender: this.props.isOpen,
    };

    /*
      this.props:
        addClasses,
        featureOsUrl,
        isOpen,
        isUserBscAdmin,
        onClosed,
        onCloseRequest,
        sidebarMode,
    */

    this._isMounted = false;
    this.BRAINSCAPE_LOGO_URL = brainscapeLogoWhite;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        shouldRender: true,
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isMiniClass = (this.props.sidebarMode == 'mini') ? 'is-mini' : '';
    const classes = toClassStr(['site-menu-transition-wrapper', isMiniClass, this.props.addClasses]);
    const featureOsUrl = this.props.featureOsUrl || 'https://brainscape.featureos.app';  

    if (!this.state.shouldRender) {
      return null;
    }

    return (
      <TransitionWrapper
        addClasses={classes}
        onTransitionedOut={() => this.handleTransitionedOut()}
        shouldTransitionIn={this.props.isOpen}
        shouldTransitionOut={!this.props.isOpen}
        transitionInDelay={100}
        transitionInDuration={500}
        transitionOutDuration={500}
      >

        <div className="site-menu">
          <div 
            className='brainscape-logo'
            onClick={(e) => this.handleLinkInNewTabClick(e, '/landing')}
            >
            <img
              src={this.BRAINSCAPE_LOGO_URL}
              alt="Brainscape logo"
            />
          </div>
          <CloseButton addClasses="close-modal-button" onClick={() => this.handleCollapseButtonClick()} />

          <ul className="site-links">
            <li className="site-link landing" onClick={(e) => this.handleLinkInNewTabClick(e, '/landing')}>Main site</li>
            <li className="site-link subjects" onClick={(e) => this.handleLinkInNewTabClick(e, '/subjects')}>Subjects</li>
            <li className="site-link educators" onClick={(e) => this.handleLinkInNewTabClick(e, '/teachers')}>Educators</li>
            <li className="site-link companies" onClick={(e) => this.handleLinkInNewTabClick(e, '/companies')}>Companies</li>
            <li className="site-link about" onClick={(e) => this.handleLinkInNewTabClick(e, '/about')}>About</li>
            <li className="site-link academy" onClick={(e) => this.handleLinkInNewTabClick(e, 'https://www.brainscape.com/academy')}>Academy</li>
            <li className="site-link pricing" onClick={(e) => this.handleLinkInNewTabClick(e, '/pricing?paywall=upgrade')}>Pricing</li>
            <li className="site-link contact" onClick={(e) => this.handleLinkInNewTabClick(e, '/contact')}>Contact</li>
            <li className="site-link features" onClick={(e) => this.handleLinkInNewTabClick(e, featureOsUrl)}>Feature Requests</li>
            <li className="site-link terms" onClick={(e) => this.handleLinkInNewTabClick(e, '/terms')}>Terms</li>
            <li className="site-link help-center" onClick={(e) => this.handleLinkInNewTabClick(e, 'https://www.brainscape.com/faq')}>Help</li>
            {this.renderAdminLink()}
          </ul>
        </div>
      </TransitionWrapper>
    )
  }

  renderAdminLink() {
    if (!this.props.isUserBscAdmin) {
      return null;
    }

    return (
      <li className="site-link admin" onClick={(e) => this.handleLinkInNewTabClick(e, '/admin')}>Admin</li>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCollapseButtonClick() {
    this.props.onCloseRequest();
  }

  handleLinkClick(e, link) {
    e.stopPropagation();
    UiHelper.navigate(link);
  }

  handleLinkInNewTabClick(e, link) {
    e.stopPropagation();
    window.open(link, '_blank', 'noopener');
  }

  handleTransitionedOut() {
    this.setState({
      shouldRender: false,
    }, () => {
      if (this.props.onClosed) {
        this.props.onClosed();
      }
    });
  }
}

export default SiteMenu;
