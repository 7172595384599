
import EventManager           from '@brainscape/event-manager';
import userStatDetail         from '_models/userStatDetail';


const estimatedTimeLeftConcern = {

  get(packId, cardCount, levelCounts) {
    return new Promise((resolve, reject) => {
      try {
        userStatDetail.index().then(statData => {
          const packStatData = statData?.studySummaries?.serverData[packId];

          if (!packStatData) throw('pack stat data not found for this user');

          const levelsStudied = packStatData.rlLvlsStudied;
          const timeStudied = packStatData.rlTimeStudied;
          const studyRate = timeStudied / levelsStudied;

          const levels = [1, 2, 3, 4, 5];
          const maxConfidence = cardCount * 5;
          const currentConfidence = levels.reduce((count, i) => count + levelCounts[i] * i, 0);
          const remainingConfidence = maxConfidence - currentConfidence;

          const estimatedTimeLeft = studyRate * remainingConfidence;

          const resData = {
            cardCount: cardCount,
            estimatedTimeLeft: estimatedTimeLeft,
            remainingConfidence: remainingConfidence, 
            studyRate: studyRate,
            totalTimeStudied: timeStudied,
          };

          this.publish(resData);
          resolve(resData);
         }).catch(err => {
           console.log('Problem getting estimatedTimeLeft. err:', err);
           reject(err);
        });
      } catch(err) {
        console.log(err);
        reject(err);
      }
    });
  },

  publish(resData) {
    EventManager.emitEvent('estimated-time-left:retrieved', resData);
  },
};

export default estimatedTimeLeftConcern;
