
import Model from '_core/Model';

class LearnerModel extends Model {
  constructor(props) {
    super(props);

    this.memberName = 'LearnerModel';
    super.generateNomenclature();

    this.schema = {
    }

    this.nameKey = "learner";
  }

  deckStats(opts) {
    let url = this.buildUrl(`/packs/${opts.packId}/users/${opts.userId}.json`, opts.params);

    return $.ajax({
      type:        'GET',
      url:         url,
      contentType: 'application/json',
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }

  updatePermissions(opts) {
    let url = this.buildUrl(`/pack_configs/${opts.packConfigId}`, opts.params);

    return $.ajax({
      type:        'PUT',
      url:         url,
      data:        JSON.stringify(opts.reqData),
      contentType: 'application/json',
      dataType:    'text',
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }

  remove(opts) { // removes learner from specific class- not user from app
    let url = this.buildUrl(`/pack_configs/${opts.packConfigId}`, opts.params);

    return $.ajax({
      contentType: 'application/json',
      data:        JSON.stringify(opts.reqData),
      type:        'DELETE',
      url:         url,
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }
}

const displayPermission = (learner) => {
  if (learner.medium == 'discovered' && learner.permission == 'study') {
    const permissionValue = 'preview';
    const permissionLabel = 'preview';
    return {permissionValue, permissionLabel};
  }

  if (learner.permission == 'study') {
    const permissionValue = 'study';
    const permissionLabel = 'full study';
    return {permissionValue, permissionLabel};
  }

  const permissionValue = learner.permission;
  const permissionLabel = learner.permission;
  return {permissionValue, permissionLabel};
};

export {LearnerModel as default, displayPermission};
