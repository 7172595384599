
import EventManager           from '@brainscape/event-manager';
import SessionStorageHelper   from '_utils/SessionStorageHelper';

import userPack               from '_models/userPack';
import userPackTransform      from '_models/userPackTransform';


const currentUserPacksConcern = {

  // get checks session store for cached objects, publishes immediately to allow display, then refreshes the  cached copy by conducting a fetch against the API
  get(userId) {
    return new Promise((resolve, reject) => {
      try {
        const currentUserPacks = SessionStorageHelper.getItem('currentUserPacks');

        if (currentUserPacks) {
          const packsData = {
            isFromCache: true,
            packs: currentUserPacks,
          };

          this.publish(packsData);
        }

        this.fetch(userId).then(currentUserPacksData => {
          resolve(currentUserPacksData);
        }).catch(err => {
          console.error('Problem getting user packs. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },


  // fetch conducts fetch against the API, and publishes for display

  /* 

  NOTE: The userPack index is paginated. The below fetch Promise resolves on the first page retrieved to allow the View Controller to present data to the user. The newModel.paginatedIndex method continues to retrieve remaining pages. 
  
  The View Controller will then wait to receive a published event from newModel.paginatedIndex containing the rest of the userPacks. 

  */

  fetch(userId) {
    return new Promise((resolve, reject) => {
      try {
        userPack.index().then(packsData => {

          packsData.isFromCache = false;

          this.publish(packsData);
          resolve(packsData);

        }).catch(err => {
          console.error('Problem fetching user packs. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  publish(currentUserPacksData) {

    EventManager.emitEvent('current-user-packs:retrieved', currentUserPacksData);
  },
};

export default currentUserPacksConcern;
