
import model from '_core/newModel';
import {waitForJob} from '_core/Api2';


const KEY_MAP = {}

const deckDuplicate = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

//   create(packId, deckId, sourcePackId=null) {
//     // if no sourcePackId is provided, then the packId is the sourcePackId (a simple duplicate)
//     // if a sourcePackId is provided, then the deck is copied from the sourcePack to the packId (copy and place)
//     let duplicateData = {};
// 
//     if (sourcePackId) {
//       duplicateData = {
//         sourcePackId: sourcePackId,
//       };
//     }
// 
//     return model.create(`packs/${packId}/decks/${deckId}/duplicates`, duplicateData);
//   },


  create(packId, deckId, sourcePackId=null) {
    // if no sourcePackId is provided, then the packId is the sourcePackId (a simple duplicate)
    // if a sourcePackId is provided, then the deck is copied from the sourcePack to the packId (copy and place)

    let duplicateData = {};

    if (sourcePackId) {
      duplicateData = {
        sourcePackId: sourcePackId,
      };
    }

    return new Promise((resolve, reject) => {
      try {
        const path = `packs/${packId}/decks/${deckId}/duplicates`;

        model.create(path, duplicateData).then(data => {
          const jobData = {
            jobId: data?.job?.id || null,
            deckId: deckId,
            packId: packId,
          };

          model.publish('deck-duplicate:job-created', jobData);

          this.monitorDeckDuplicateJob(jobData);

          resolve(jobData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error('something went wrong during deck duplicate job create. err:', err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  monitorDeckDuplicateJob(jobData) {
    if (jobData.jobId == -1) {
      // job has already been performed

      model.publish('deck-duplicate:job-completed', jobData);
      return true;
    }

    waitForJob(jobData).then(() => {
      model.publish('deck-duplicate:job-completed', jobData);
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckDuplicate;
