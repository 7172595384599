
import {apiPut}                     from '_core/Api1';
import EventManager                 from '@brainscape/event-manager';
import GetTheAppModal               from '_views/modals/GetTheAppModal';
import PropTypes                    from 'prop-types';
import React                        from 'react';
import UiHelper                     from '_utils/UiHelper';

class GetTheAppModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('get-the-app-modal:open', (data) => {

      this.setState({
        isModalOpen: true,
        viewProps: data
      }, () => {
        this.setModalSeenFlag();
      });
    });

    this.events.addListener(
      'get-the-app-modal:close',
      () => {
        this.handleCloseRequest();
      }
    );
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    return (
      <GetTheAppModal
        addClasses={viewProps.addClasses}
        isOpen={this.state.isModalOpen}
        isProcessing={viewProps.isProcessing}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  setModalSeenFlag() {
    const url = 'get_brainscape';

    apiPut(url).then(() => {
      console.log('User GetTheAppModal pref updated');
    }).catch((err) => {
      UiHelper.handleError({
        operation: 'Update GetTheAppModal User pref',
        errorMessage: null,
        errorObject: err,
        notifyUser: false,
      });
    });
  }
}

export default GetTheAppModalController;
