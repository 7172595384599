
// substrate and utils
import EventManager               from '@brainscape/event-manager';
import NumberHelper               from '_utils/NumberHelper';
import PropTypes                  from 'prop-types';
import React                      from 'react';
import StringHelper               from '_utils/StringHelper';
import StudyHelper                from '_utils/StudyHelper';
import Tracker                    from '_utils/Tracker';
import {toClassStr}               from '_utils/UiHelper';

// models
import pack                       from '_models/pack';

// sub-components
import EstimatedTimeLeftSnippet   from '_views/shared/EstimatedTimeLeftSnippet';
import MasteryCircle              from '_views/shared/MasteryCircle';
import PackIcon                   from '_views/shared/PackIcon';
import PackOptionsButton          from '_views/shared/PackOptionsButton';
import PillButton                 from '_views/shared/PillButton';
import Stat                       from '_views/shared/Stat';
import StudyControls              from '_views/shared/StudyControls';
import TabSwitcher                from '_views/shared/TabSwitcher';
import TextField                  from '_views/shared/TextField';
import UserAvatar                 from '_views/shared/UserAvatar';

import {
  DismissButton,
  EditButton,
}                                 from '_views/shared/IconButton';

const PT = {
  addClasses:                       PropTypes.string,
  authenticityToken:                PropTypes.string,
  currentPack:                      PropTypes.object,
  currentTabId:                     PropTypes.node,
  currentUser:                      PropTypes.object,
  isFtue:                           PropTypes.bool,
  isMobileViewportSize:             PropTypes.bool,
  learnersCount:                    PropTypes.number,
  onPackDeleted:                    PropTypes.func,
  onPackUpdated:                    PropTypes.func,
  onRemovePackRequest:              PropTypes.func,
  onSetDeckClickActionRequest:      PropTypes.func,
  onSetDeckSortability:             PropTypes.func,
  onSetStudyMixTypeRequest:         PropTypes.func,
  onStudyRequest:                   PropTypes.func,
  onTabClick:                       PropTypes.func,
  selectedDecks:                    PropTypes.array,
  selectedDecksCardCount:           PropTypes.number,
  selectedDecksCardsStudiedCount:   PropTypes.number,
  selectedDecksMastery:             PropTypes.number,
  shouldPulseStudyButton:           PropTypes.bool,
  shouldShowAddMetadataPrompt:      PropTypes.bool,
  shouldShowNewLearnerPrompt:       PropTypes.bool,
  studyMixType:                     PropTypes.string,
  tabs:                             PropTypes.object,
  totalTimeStudied:                 PropTypes.number,
};

const FALLBACK_PACK_ICON_URL = '/assets/app_icons/ugc-white-bg.svg';


class PackDetailHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditingPackName: false,
      isProcessingPackIconUpdate: false,
      isProcessingPackNameUpdate: false,
      packName: this.props.currentPack.name || '',
      savedPackName: this.props.currentPack.name || '',
    };

    this._isMounted = false;

    this.publicPrivateFlagElem = null;

    this.events = new EventManager();
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    this.events.addListener('edit-pack-name-form:open', () => {
      this.handleEditPackNameRequest();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentPack.packId != prevProps.currentPack.packId) {
      this.resetState();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const pack = this.props.currentPack;

    const addMetadataPromptClass = this.props.shouldShowAddMetadataPrompt
      ? 'show-add-metadata-prompt'
      : '';
    const newLearnerPromptClass = this.props.shouldShowNewLearnerPrompt
      ? 'show-new-learner-prompt'
      : '';

    const classes = toClassStr([
      'pack-detail-header',
      addMetadataPromptClass,
      newLearnerPromptClass,
      this.props.addClasses,
    ]);

    const cardsStudiedStat = `${NumberHelper.displayNumber(pack.transformedStats.seenCount)} of ${NumberHelper.displayNumber(pack.transformedStats.cardCount)}`;

    const selectedDecksCount = pack.localDeckTransforms?.selections?.ids?.length || 0;

    return (
      <header className={classes}>
        <div className="first-row">
          <PackIcon
            isEditable={true}
            pack={this.props.currentPack}
          />

          <div className="pack-header-main">
            {this.renderPackName()}

            <div className="pack-metadata">
              {this.renderPackCreator()}

              <Stat
                background="light"
                isMobileViewportSize={this.props.isMobileViewportSize}
                label="Cards Studied:"
                orientation="horizontal"
                tooltipBody={this.renderUniqueCardsStudiedTooltipIconBody()}
                tooltipHeading="Cards Studied"
                tooltipPosition="bottom"
                value={cardsStudiedStat}
              />

              <EstimatedTimeLeftSnippet
                deckCount={pack.stats.deckCount}
                estimatedTimeLeft={this.props.estimatedTimeLeft}
                isMobileViewportSize={this.props.isMobileViewportSize}
                selectedDeckCount={selectedDecksCount}
                selectedDecksMastery={pack.transformedStats.mastery}
                timeStudied={this.props.totalTimeStudied}
                tooltipPosition="bottom"
              />
            </div>

            <div className="pack-actions">
              <div className="pack-action-buttons">
                {this.renderStudyControls()}
                {this.renderShareButton()}

                <PackOptionsButton
                  authenticityToken={this.props.authenticityToken}
                  currentPack={pack}
                  currentUser={this.props.currentUser}
                  iconType="horizontal"
                  initialOptionsOpenState={false}
                  isMobileViewportSize={this.props.isMobileViewportSize}
                  onPackDeleted={this.props.onPackDeleted}
                  onPackUpdated={this.props.onPackUpdated}
                  onRemovePackRequest={this.props.onRemovePackRequest}
                  onSetDeckClickActionRequest={this.props.onSetDeckClickActionRequest}
                  tooltipContent="Import/Export, Duplicate, Reset, more"
                  tooltipPosition="top"
                />
              </div>
            </div>
          </div>

          <div className="mastery-and-mobile-icon">
            {this.renderMasteryCircle()}

            <div className="mobile-pack-icon">
              <img className="pack-icon-image" src={pack.activeIconUrl || pack.iconUrl || FALLBACK_PACK_ICON_URL} />
            </div>
          </div>
        </div>

        <div className="second-row">{this.renderTabSwitcher()}</div>
      </header>
    );
  }

  renderPackName() {
    if (this.state.isEditingPackName) {
      return this.renderPackNameForm();
    }

    const packName = this.state.packName;

    const hasPublicPrivateFlag = this.getHasPublicPrivateFlag();
    const flagClass = this.getHasPublicPrivateFlag() ? 'has-public-private-flag' : '';
    const packNameClasses = toClassStr(['pack-name', flagClass])

    return (
      <div className="pack-name-and-edit-button">
        <h1 className={packNameClasses} title={packName}>
          {packName}
        </h1>

        {this.renderPublicPrivateFlag()}
        {this.renderPackNameEditButton()}
      </div>
    );
  }

  renderPublicPrivateFlag() {
    if (!this.getHasPublicPrivateFlag()) {
      return null;
    }

    const publicPrivateFlag = this.props.currentPack.flags.isPrivate ? 'Private' : 'Public';

    return (
      <div
        className="public-private-flag"
        onClick={this.handlePublicPrivateFlagClick}
        onMouseEnter={this.handlePublicPrivateFlagMouseEnter}
        onMouseLeave={this.handlePublicPrivateFlagMouseLeave}
        ref={elem => this.publicPrivateFlagElem = elem}
      >
        {publicPrivateFlag}
      </div>
    );
  }

  renderPackNameEditButton() {
    if (this.props.currentPack.permission != 'admin') {
      return null;
    }

    return (
      <EditButton
        addClasses="edit-pack-name-button"
        onClick={this.handleEditPackNameButtonClick}
        tooltipContent="Edit Class title"
        tooltipPosition="right"
      />
    );
  }

  renderPackNameForm() {
    return (
      <form className="pack-name-form" onSubmit={this.handlePackNameFormSubmit}>
        <TextField
          addClasses="pack-name-text-field"
          buttons={this.renderPackNameFormFieldButtons()}
          hasInitialFocus={true}
          id="pack-name"
          onChange={this.handlePackNameChange}
          placeholder={this.props.placeholder || 'Enter Class Name'}
          value={this.state.packName}
        />

        <input type="submit" className="hidden-submit-button" />
      </form>
    );
  }

  renderPackNameFormFieldButtons() {
    return (
      <div className="pack-name-form-field-buttons">
        <DismissButton
          addClasses="dismiss-pack-name-form-button"
          onClick={this.handlePackNameFormDismissButtonClick}
        />

        <PillButton
          addClasses="save-pack-name-form-button"
          isProcessing={this.state.isProcessingPackNameUpdate}
          label="Save"
          onClick={this.handlePackNameFormSaveButtonClick}
        />
      </div>
    );
  }

  renderMasteryCircle() {
    let postscript = null;
    let selectionClass;

    if (
      this.props.selectedDecks &&
      this.props.selectedDecks.length < this.props.currentPack.stats.deckCount
    ) {
      postscript = `(${this.props.selectedDecks.length} / ${this.props.currentPack.stats.deckCount} decks)`;
      selectionClass = 'subset-selected';
    }

    const classes = toClassStr(['pack-mastery', selectionClass]);

    return (
      <MasteryCircle
        addClasses={classes}
        animationDuration={0}
        background="light"
        isMobileViewportSize={this.props.isMobileViewportSize}
        key="packMastery"
        mastery={this.props.currentPack.transformedStats.mastery}
        postscript={postscript}
        shouldAnimate={false}
        shouldCountUpMastery={false}
        shouldShowLevels={false}
        shouldShowMasteryValue={true}
        shouldShowMasteryValueLabel={true}
      />
    );
  }

  renderMasteryDialogBody() {
    return (
      <>
        <p className='body-text'>
          This graph displays the weighted average of your self-assessed
          confidence for all of the cards you have studied in the decks that are
          presently selected.
        </p>
        <p className='body-text'>
          <b>Tip:</b> To see your mastery of the entire class, select all decks.
        </p>
      </>
    );
  }

  renderPackCreator() {
    const pack = this.props.currentPack;

    if (pack.flags.isCertified) {
      return (
        <div className="certification-label">Brainscape Certified</div>
      );
    }

    return (
      <div className="pack-creator">
        <div className="stat-label">By:</div>
        <a className="creator-profile-link" href={pack.paths.creatorProfilePath} target="profile" rel="noopener">
          <UserAvatar 
            addClasses="creator-avatar"
            avatarUrl={pack.creatorAvatarUrl}
            avatarName={pack.creatorName}
          />
          <div className="creator-label">{pack.creatorName}</div>
        </a>
      </div>
    );
  }

  renderUniqueCardsStudiedTooltipIconBody() {
    const { cardCount, seenCount } = this.props.currentPack.transformedStats;

    return (
      <p className="body-text">
        You have studied <b>{NumberHelper.displayNumber(seenCount)} unique cards</b> out of a total of
        <b> {NumberHelper.displayNumber(cardCount)}</b> for this class.
        (Example: if you study the same 20 cards 100 times, this stat would be
        "20").
        <br />
        <br />
        This number can be reset to zero if you reset your confidences.
      </p>
    );
  }

  renderStudyControls() {
    return (
      <StudyControls
        isConfigurable={false}
        isDisabled={!this.isStudyEnabled()}
        isFtue={this.props.isFtue}
        isUserPro={this.props.currentUser.flags.isPro}
        onStudyRequest={this.handleStudyRequest}
        openPosition="bottomRight"
        pack={this.props.currentPack}
        shouldPulseStudyButton={this.props.shouldPulseStudyButton}
        tooltipContent={this.renderStudyControlsTooltipContent()}
        tooltipPosition="top"
        userId={this.props.currentUser.userId}
      />
    );
  }

  renderStudyControlsTooltipContent() {
    const pack = this.props.currentPack;
    const selectedDeckIds = pack.localDeckTransforms.selections?.ids;

    if (pack.stats.deckCount < 1) {
      return 'Add a Deck and Cards to your Class to Study';
    }

    if (pack.stats.cardCount < 1) {
      return 'Add Cards to your Class to Study';
    }

    if (selectedDeckIds.length < 1) {
      return 'Select one or more Decks to Study';
    }

    return 'Study Cards from the Decks selected below';
  }

  renderShareButton() {
    if (this.props.currentPack.flags.isPrivate && this.props.currentPack.permission != 'admin') {
      return null;
    }

    return (
      <PillButton
        addClasses="share-pack-button"
        label="Share"
        onClick={this.handleShareButtonClick}
        tooltipContent={this.props.currentPack.flags.isPrivate ? "Share this Class by e-mail" : "Share this Class by e-mail or link"}
        tooltipPosition="top"
      />
    );
  }

  renderTabSwitcher() {
    const selectedDecksCount = this.props.selectedDecks.length;
    const deckCount = this.props.currentPack.stats.deckCount;
    const decksTabCount =
      selectedDecksCount == deckCount || this.props.isMobileViewportSize
        ? deckCount
        : `${this.props.selectedDecks.length} / ${this.props.currentPack.stats.deckCount} selected`;
    let tabs = { ...this.props.tabs };
    tabs.decks.count = decksTabCount;

    return (
      <TabSwitcher
        currentTabId={this.props.currentTabId}
        onTabClick={this.handleTabClick}
        tabs={tabs}
      />
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleChooseIconButtonClick = () => {
    this.triggerChooseClassIconModalOpen();
  };

  handleEditPackNameButtonClick = () => {
    this.setState({
      isEditingPackName: true,
    });
  };

  handleEditPackNameRequest = () => {
    this.setState({
      isEditingPackName: true,
    });
  };

  handlePackNameChange = (e) => {
    this.setState({
      packName: e.target.value,
    });
  };

  handlePackNameFormDismissButtonClick = () => {
    this.setState({
      isEditingPackName: false,
      packName: this.state.savedPackName,
    });
  };

  handlePackNameFormSaveButtonClick = () => {
    this.handlePackNameFormSubmit();
  };

  handlePackNameFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const packName = this.state.packName;

    if (StringHelper.isBlank(packName)) {
      this.setState({
        packName: this.state.savedPackName,
      });

      return false;
    }

    this.setState({
      isProcessingPackNameUpdate: true,
    });

    const packData = {
      pack: {
        name: packName,
      }
    };

    pack.update(this.props.currentPack.packId, packData).then(() => {
      this.setState({
        isEditingPackName: false,
        isProcessingPackNameUpdate: false,
        packName: packName,
        savedPackName: packName,
      });
    });
  };

  handlePublicPrivateFlagClick = () => {
    if (!this.props.currentUser.flags.isPro) {
      this.triggerUpgradeModalOpen('Make Class Private or Public', 'privacy', 'list-3');
      return true;
    }

    const newPrivacyState = !this.props.currentPack.flags.isPrivate;
    this.triggerEditPackPrivacyModalOpen(newPrivacyState);
  }

  handlePublicPrivateFlagMouseEnter = () => {
    const message = this.props.currentPack.flags.isPrivate ? 'Make your class available to the world' : 'Make your class private to only users you choose (Pro)';

    this.triggerTooltipOpen({
      content: message,
      elem: this.publicPrivateFlagElem,
      position: 'bottom',
    });
  }

  handlePublicPrivateFlagMouseleave = () => {
    this.triggerTooltipClose();
  }

  handleShareButtonClick = () => {
    if (this.props.currentPack.permission == 'admin') {
      if (this.props.currentPack.flags.isPrivate){
        this.triggerSharePackModalSetEmailOpen();
      }else{
        this.triggerSharePackModalSetOpen();
      }
    } else {
      this.triggerSharePackByLinkModalOpen();
    }
  }

  handleStudyRequest = (e) => {
    if (e) {
      e.stopPropagation();
    }

    const pack = this.props.currentPack;
    const userId = this.props.currentUser.userId;
    const studyMixType = StudyHelper.getPackStudyMixType(userId, pack);
    const selectedDeckIds = pack.localDeckTransforms.selections.ids;

    if (pack.stats.deckCount < 1) {
      this.triggerPackHasNoDecksModalOpen();
      return false;
    }

    if (pack.stats.cardCount < 1) {
      this.triggerPackHasNoCardsModalOpen();
      return false;
    }

    if (selectedDeckIds.length < 1) {
      this.triggerStudyMixDisabledModalOpen();
      return false;
    }

    EventManager.emitEvent('page-loading-overlay:open');

    let studyMixUrl = StudyHelper.getPackStudyMixUrl(
      userId,
      pack,
      selectedDeckIds,
    );

    GaHelper.fireGaEvent('Study_Mix', studyMixType, selectedDeckIds.length);
    GaHelper.followUrlDelayed(studyMixUrl);
  };

  handleTabClick = (tabId) => {
    this.triggerCurrentTabChangeRequest(tabId);
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerChooseClassIconModalOpen = () => {
    EventManager.emitEvent('choose-class-icon-modal:open', {
      authenticityToken: this.props.authenticityToken,
      pack: this.props.currentPack,
    });
  }

  triggerConfirmModalOpen = (viewProps) => {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerCurrentTabChangeRequest = (tabId) => {
    EventManager.emitEvent('current-tab:change-request', {tabId: tabId});
  }

  triggerEditPackPrivacyModalOpen = (newPrivacyState) => {
    EventManager.emitEvent('edit-pack-privacy-modal:open', {
      newPrivacyState         : newPrivacyState,
      pack                    : this.props.currentPack,
    });
  }

  triggerEditPackPrivacyModalClose = () => {
    EventManager.emitEvent('edit-pack-privacy-modal:close', {});
  }

  triggerPackHasNoDecksModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message:
        'Before you can study this class, you or the Class creator must add at least one deck with at least one card to the class.',
      resolveButtonText: 'Got it',
      title: 'This Class Has No Decks',
    });
  };

  triggerPackHasNoCardsModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message:
        'Before you can study this class, you or the Class creator must add at least one card to the class.',
      resolveButtonText: 'Got it',
      title: 'This Class Has No Cards',
    });
  };

  triggerStudyMixDisabledModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message:
        'Study Mix is disabled because there are no decks checked below. Check one or more decks (circles to the left of each deck) to include them in your Study Mix.',
      resolveButtonText: 'Got it',
      title: 'Study Mix Disabled',
    });
  };

  triggerSharePackByLinkModalOpen = () => {
    EventManager.emitEvent('share-pack-by-link-modal:open', {
      authenticityToken: this.props.authenticityToken,
      currentUser: this.props.currentUser,
      pack: this.props.currentPack,
    });
  }

  triggerSharePackModalSetEmailOpen = () => {
    EventManager.emitEvent('share-pack-modal-set:open', {
      authenticityToken: this.props.authenticityToken,
      currentUser: this.props.currentUser,
      hasBackOption: false,
      pack: this.props.currentPack,
      phase: 'email',
    });
  }

  triggerSharePackModalSetOpen = () => {
    EventManager.emitEvent('share-pack-modal-set:open', {
      authenticityToken: this.props.authenticityToken,
      currentUser: this.props.currentUser,
      pack: this.props.currentPack,
    });
  }

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };

  triggerUpgradeModalOpen = (desiredAction, paywall, featuresList) => {
    Tracker.trackPaywallWarning(paywall);

    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
      featuresList:  featuresList,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getHasPublicPrivateFlag = () => {
    const pack = this.props.currentPack;

    if (pack.permission != 'admin') {
      return false;
    }

    if (!pack.flags.isPrivate && pack.stats.subscriberCount < 5) {
      return false;
    }

    return true;
  }

  isStudyEnabled = () => {
    const pack = this.props.currentPack;
    const selectedDeckIds = pack.localDeckTransforms.selections.ids;

    if (pack.stats.deckCount < 1) {
      return false;
    }

    if (pack.stats.cardCount < 1) {
      return false;
    }

    if (selectedDeckIds.length < 1) {
      return false;
    }

    return true;
  }

  resetState = () => {
    this.setState({
      isEditingPackName: false,
      isProcessingPackNameUpdate: false,
      packName: this.props.currentPack.name || '',
      savedPackName: this.props.currentPack.name || '',
    });
  }

  showUpgradeModal = (desiredAction, paywall, featuresList) => {
    Tracker.trackPaywallWarning(paywall);
    this.triggerUpgradeModalOpen(desiredAction, paywall, featuresList);
  }
}

PackDetailHeader.propTypes = PT;

export default PackDetailHeader;