
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';

class EditPackPrivacyModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    /*
      this.props:
        addClasses,
        isOpen,
        isProcessing,
        onCloseRequest,
        onPackPrivacyChangeRequest,
        newPrivacyState, // boolean
        pack,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <Modal
        addClasses="edit-pack-privacy-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        {this.renderTitle()}
        {this.renderMessage()}

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            isProcessing={this.props.isProcessing}
            label="Confirm"
            onClick={this.handleConfirmButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderTitle() {
    if (this.props.newPrivacyState) {
      return (
        <div className="modal-title">Make Class Private</div>
      );
    }

    return (
      <div className="modal-title">Make Class Public</div>
    );
  }

  renderMessage() {
    if (this.props.newPrivacyState) {
      return (
        <div className="modal-message">This class will be de-indexed and removed from search. To invite learners, you must directly share via email, and they will also need a Pro account to access it.<br/><br/><em>Note: It make take up to 24 hours for this change to take effect in the Brainscape directory.</em></div>
      );
    }

    return (
      <div className="modal-message">This class will be added back to search and any learners can study this class.<br/><br/><em>Note: It make take up to 24 hours for this change to take effect in the Brainscape directory.</em></div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleConfirmButtonClick = () => {
    if (this.props.onPackPrivacyChangeRequest) {
      this.props.onPackPrivacyChangeRequest();
    }

    this.props.onCloseRequest();
  }
}

export default EditPackPrivacyModal;
