
import model from '_core/newModel';

const category = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  index() {
    return model.index('categories/');
  },

  fullIndex() {
    return model.index('categories?all_cats');
  },

  show(categoryId) {
    return model.show(`categories/${categoryId}`);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default category;
