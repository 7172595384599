
import React        from 'react';
import VideoModal   from '_views/modals/VideoModal'

import {toClassStr} from '_utils/UiHelper';

class VideoModalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }

    /*
      this.props:
        addClasses,
        shouldAutoPlay,
        videoUrl
    */
  }

  render() {
    const classes = toClassStr(['video-modal-button', this.props.addClasses]);

    return (
      <div className={classes}>

        <div className="play-button" onClick={() => this.handlePlayButtonClick()}>
          <i className="ion-ios-play"></i>
          Play Video
        </div>

        <VideoModal
          isOpen={this.state.isOpen}
          onCloseRequest={() => this.handleCloseVideoModalRequest()}
          shouldAutoPlay={this.props.shouldAutoPlay}
          videoUrl={this.props.videoUrl}
        />
      </div>
    );
  }

  handlePlayButtonClick() {
    this.setState({isOpen: true});
  }

  handleCloseVideoModalRequest() {
    this.setState({isOpen: false});
  }

};

export default VideoModalButton;
