
import EventManager                from '@brainscape/event-manager';
import ImageViewerController       from '_controllers/ImageViewerController';
import PillButton                  from '_views/shared/PillButton';
import PropTypes                   from 'prop-types';
import React                       from 'react';
import SmartCard                   from "_views/shared/smart-cards/SmartCard";
import ConfidenceButtons           from '_views/shared/smart-cards/ConfidenceButtons';
import RevealCardFaceButton        from '_views/shared/smart-cards/RevealCardFaceButton';

import pack                        from '_models/pack';
import packDeck                    from '_models/packDeck';

import {
  BrowseButton, 
  CloseButton
} from '_views/shared/IconButton';

import {toClassStr} from '_utils/UiHelper';


const PT = {
  addClasses:                 PropTypes.string,
  currentCard:                PropTypes.object,
  currentCardFace:            PropTypes.string,
  currentCardId:              PropTypes.node,
  currentCardLevel:           PropTypes.number,
  currentDeck:                PropTypes.object,
  currentDeckId:              PropTypes.node,
  currentPack:                PropTypes.object,
  currentPackId:              PropTypes.node,
  currentStepIndex:           PropTypes.number,
  currentUser:                PropTypes.object,
  isAtCheckpoint:             PropTypes.bool,
  isAudioMuted:               PropTypes.bool,
  isFtse:                     PropTypes.bool,
  isLoadingCardDisplayData:   PropTypes.bool,
  isMobileViewportSize:       PropTypes.bool,
};

const DEAL_ANIMATION_DURATION = 400;
const DEAL_NEW_CARD_DELAY = 100; // Allows Previous Card to be rendered b4 animation


class StudyBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCardMode:                  'display',
      isProcessingPreviewCardsAction:   false,
    }

    this._isMounted = false;

    this.events = new EventManager();

    this.dealAnimationTimeout = null;
    this.dealNewCardDelay = null;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    this.clearTimeouts();

    this.events.addListeners([
      ['smartCardFace:baseFontSize',            this.handleBaseFontSizeUpdated],
      ['cardSet:baseFontSizeUpdated',           this.handleBaseFontSizeUpdated],
      ['smart-card:display-card-mode-entered',  this.handleSmartCardDisplayCardModeEntered],
      ['smart-card:edit-card-mode-entered',     this.handleSmartCardEditCardModeEntered],
      ['userCard:updated',                      this.handleUserCardUpdated],
    ]);
  }

  componentWillUnmount() {
    this.events.disable();
    this.clearTimeouts();

    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    if (!this.props.currentCard) {
      return null;
    }

    if (!this.props.currentDeck) {
      return null;
    }

    const classes = toClassStr(['study-body', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderStudyBodyContents()}
        <ImageViewerController />
      </div>
    );
  }

  renderStudyBodyContents() {
    if (!this.props.currentCard) { 
      return null; 
    }

    const currentCard = this.props.currentCard;
    const currentDeck = this.props.currentDeck;
    const deckCardPath = `/l/dashboard/${this.props.currentPackId}/decks/${currentCard.deckId}/cards/${currentCard.cardId}`;
    const deckCardIndex = currentCard.number;
    const deckCardCount = (currentDeck.cardIds) ? currentDeck.cardIds?.length : currentDeck.cards?.ids?.length;

    return (
      <div className="study-body-contents">
        <header className="study-header">
          <div className="deck-and-card-info">
            <span className="deck-label">Deck:&nbsp;</span> <a className="deck-name" href={deckCardPath}>{this.props.currentDeck.name}</a> <span className="card-label">Card:&nbsp;</span> <span className="deck-card-index">{deckCardIndex}</span>/<span className="deck-card-count">{deckCardCount}</span>
          </div>
          {this.renderPreviewCardsButton()}
        </header>

        <div className="cards-and-bar">
          
          {this.renderSmartCard()}

          <div className="study-controls">
            {this.renderRevealAnswerButton()}
            {this.renderConfidenceButtons()}
          </div>
        </div>
      </div>
    );
  }

  renderPreviewCardsButton() {
    if (this.props.isMobileViewportSize) {
      return (
        <BrowseButton
          addClasses="preview-cards-button compact"
          isProcessing={this.state.isProcessingPreviewCardsAction}
          onClick={this.handlePreviewCardsButtonClick}
          spinnerColor="blue"
          title="See all Cards in this Deck"
        />
      );
    }

    return (
      <PillButton
        addClasses="preview-cards-button full"
        isProcessing={this.state.isProcessingPreviewCardsAction}
        label="See all Cards in this Deck"
        onClick={this.handlePreviewCardsButtonClick}
        spinnerColor="blue"
      />
    );
  }

  renderSmartCard() {
    const card = this.props.currentCard;

    if (!card) {
      return null;
    }

    card.stats = card.stats || {};
    card.stats.level = this.props.currentCardLevel;

    const deck = this.props.currentDeck;
    deck.flags = deck.flags || {};
    deck.flags.isEditable = this.canEdit() || false;

    return (
      <SmartCard
        card={card}
        cardMode="display"
        context="study"
        currentCardFace={this.props.currentCardFace}
        currentUser={this.props.currentUser}
        deck={deck}
        format="md"
        isAtCheckpoint={this.props.isAtCheckpoint}
        isAudioMuted={this.props.isAudioMuted}
        isBlurred={false}
        isCurrentCard={true}
        isLoadingCardDisplayData={this.props.isLoadingCardDisplayData}
        isMobileViewportSize={this.props.isMobileViewportSize}
        layout="single-card"
        pack={this.props.currentPack}
        packId={this.props.currentPackId}
        skipDirection="forward"
        viewportStyle="desktop"
      />
    );
  }

  renderRevealAnswerButton = () => {
    if (this.props.currentCardFace == 'answer') {
      return null;
    }

    const isDisabled = (this.state.currentCardMode == 'edit');
    const tooltipContent = (this.state.currentCardMode == 'edit') ? 'Save your card or hit the "Resume Study" button to continue' : 'Press SPACEBAR to continue';

    return (
      <RevealCardFaceButton 
        face="question"
        isDisabled={isDisabled}
        isFtse={this.props.isFtse}
        level={this.props.currentCardLevel}
        tooltipContent={tooltipContent}
        tooltipPosition="top"
      />
    );
  }

  renderConfidenceButtons = () => {
    if (this.props.currentCardFace != 'answer') {
      return null;
    }

    const isDisabled = (this.state.currentCardMode == 'edit');
    const tooltipContent = (this.state.currentCardMode == 'edit') ? 'Save your card or hit the "Resume Study" button to continue' : null;

    return (
      <ConfidenceButtons 
        face="answer"
        isDisabled={isDisabled}
        isFtse={this.props.isFtse}
        level={this.props.currentCardLevel}
        tooltipContent={tooltipContent}
        tooltipPosition="top"
      />
    );
  }

  renderFtseMessaging() {
    if (!this.props.isFtse || this.state.mixRoundCount > 1 || this.state.isAtCheckpoint) {
      return null;
    }

    const isAtRoundMidpoint = this.state.roundStepIndex == 5;

    return (
      <FtseMessaging
        ftseAnswerCardbarRating={this.state.ftseAnswerCardbarRating}
        ftsePostCbrBubbleAction={this.state.ftsePostCbrBubbleAction}
        isAtRoundMidpoint={isAtRoundMidpoint}
        onAnswerCardbarPulseTrigger={() =>
          this.handleAnswerCardbarPulseTrigger()
        }
        onConfirmCloseStudyMix={() => this.handleCloseStudyMix()}
        onPreExitBubbleClosed={() => this.handlePreExitBubbleClosed()}
        onPreExitBubbleDismissed={() => this.handlePreExitBubbleDismissed()}
        onQuestionCardbarPulseTrigger={() =>
          this.handleQuestionCardbarPulseTrigger()
        }
        roundStepIndex={this.state.roundStepIndex}
        shouldClosePreExitBubble={this.state.shouldClosePreExitBubble}
        shouldShowPreExitBubble={this.state.shouldShowPreExitBubble}
        stepCard={this.state.stepCard}
        stepConfidenceLevel={this.state.stepConfidenceLevel}
        wasAnswerCardbarClicked={this.state.wasAnswerCardbarClicked}
        wasQuestionCardbarClicked={this.state.wasQuestionCardbarClicked}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handlePreviewCardsButtonClick = () => {
    const deckId = this.props.currentDeckId;
    const packId = this.props.currentPackId;

    this.setState({isProcessingPreviewCardsAction: true});

    pack.show(packId).then(packData => {
      packDeck.show(packId, deckId).then(packDeckData => {
        const pack = packData;
        const deck = packDeckData.deck;

        this.triggerPreviewModalOpen(pack, deck);

        this.setState({
          isProcessingPreviewCardsAction: false,
        });
      }).catch(err => {
        this.setState({
          isProcessingPreviewCardsAction: false,
        });
      });
    });
  }

  handleSmartCardDisplayCardModeEntered = (eventData) => {
    if (eventData.cardId != this.props.currentCardId) {
      return false;
    }

    this.setState({
      currentCardMode: 'display',
    });
  }

  handleSmartCardEditCardModeEntered = (eventData) => {
    if (eventData.cardId != this.props.currentCardId) {
      return false;
    }

    this.setState({
      currentCardMode: 'edit',
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerPreviewModalOpen = (pack, deck) => {
    EventManager.emitEvent('deck-preview-modal:open', {
      deck: deck,
      pack: pack,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  canEdit = () => {
    const packPermission = this.props.currentPack.permission;
    return (['edit', 'admin'].indexOf(packPermission) != -1);
  }

  clearTimeouts() {
    clearTimeout(this.dealNewCardDelay);
    clearTimeout(this.dealAnimationTimeout);
  }
}

StudyBody.propTypes = PT;

export default StudyBody;
