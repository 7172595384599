
import {BackButton}                   from '_views/shared/IconButton';
import ConfidenceMeter                from '_study/ConfidenceMeter';
import ProgressMeter                  from '_study/ProgressMeter';
import React                          from 'react';
import TimeHelper                     from '_utils/TimeHelper';

import {toClassStr} from '_utils/UiHelper';

class ThisRoundStatsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        isAtCheckpoint,
        isClosingCheckpoint,
        isMobileViewportSize,
        mixRoundCount,
        onCloseStudyMixRequest,
        preRoundStepRatings,
        roundConfidenceGained,
        roundElapsedTime,
        roundEndConfidenceGained,
        roundStepCount,
        roundStepIndex,
        roundStepRatings,
        shouldCompressRoundEndConfidence,
        shouldDimRoundEndConfidence,
        shouldShowRoundEndConfidence,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isAtCheckpointClass = this.props.isAtCheckpoint ? 'is-at-checkpoint' : '';
    const isClosingCheckpointClass = this.props.isClosingCheckpoint ? 'is-closing-checkpoint' : '';
    const classes = toClassStr(['this-round-stats-section', isAtCheckpointClass, isClosingCheckpointClass, this.props.addClasses]);
    const elapsedTime = TimeHelper.msToClockTime(this.props.roundElapsedTime);
    const roundCounter = (this.props.mixRoundCount > 1) ? 'This Round ( ' + this.props.mixRoundCount + ' ):' : 'This Round:';

    return (
      <div className={classes}>

        <ConfidenceMeter
          confidenceGained={this.props.roundConfidenceGained}
          isAtCheckpoint={this.props.isAtCheckpoint}
          isMobileViewportSize={this.props.isMobileViewportSize}
          minLevel={-50}
          maxLevel={50}
          roundEndConfidenceGained={this.props.roundEndConfidenceGained}
          shouldCompressRoundEndConfidence={this.props.shouldCompressRoundEndConfidence}
          shouldDimRoundEndConfidence={this.props.shouldDimRoundEndConfidence} 
          shouldShowRoundEndConfidence={this.props.shouldShowRoundEndConfidence} 
        />

        <ProgressMeter
          isAtCheckpoint={this.props.isAtCheckpoint}
          isMobileViewportSize={this.props.isMobileViewportSize}
          preRoundStepRatings={this.props.preRoundStepRatings}
          stepCount={this.props.roundStepCount}
          stepIndex={this.props.roundStepIndex}
          stepRatings={this.props.roundStepRatings}
        />
        
        <footer className="this-round-stats-footer">
          <h4 className="status-title">{roundCounter}</h4>
          <div className="elapsed-time">{elapsedTime}</div>
        </footer>
      </div>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBackButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }
    
    this.props.onCloseStudyMixRequest();
  }
}

export default ThisRoundStatsSection;
