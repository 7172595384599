
import React from 'react';
import {toClassStr} from '_utils/UiHelper';

class ConfidenceLevelButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    /*
      this.props:
        addClasses,
        highlightedRatingButton,
        onButtonClick
    */
  }

  render() {
    let confidenceButtons = [];

    for (let i = 1; i <= 5; i++) {
      const highlighted = (this.props.highlightedRatingButton && this.props.highlightedRatingButton == i) ? 'highlighted' : ''; 
      const classes = toClassStr(["confidence-level-button", "confidence-" + i, highlighted]);

      confidenceButtons.push(
        <div key={i} className={classes} onClick={(e) => this.handleButtonClick(e, i)} data-level={i} title={"Press " + i + " on your keyboard"}>{i}{this.renderConfidenceButtonText(i)}</div>
      );
    }

    return (
      <div className="round-confidence-level-buttons">
        {confidenceButtons}
      </div>
    );
  }

  renderConfidenceButtonText(i) {
    if (i == 1) {
      return (<small>Not At All</small>);
    }

    if (i == 5) {
      return (<small>Perfectly</small>);
    }

    return null;
  }

  handleButtonClick(e, rating) {
    e.stopPropagation();
    this.props.onButtonClick(rating);
  }
}

export default ConfidenceLevelButtons;
