
import ApplePaymentModal        from '_account/ApplePaymentModal';
import CancelSubscriptionModal  from '_account/CancelSubscriptionModal';
import GooglePaymentModal       from '_account/GooglePaymentModal';
import React                    from 'react';
import StripeCheckoutWidget     from '_account/StripeCheckoutWidget';

import {toClassStr} from '_utils/UiHelper';

class SubscriptionBillingRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCancellationError: false,
      isCancellationSuccess: false,
      isUpdateError: false,
      isUpdateSuccess: false,
      shouldShowAppleModal: false,
      shouldShowGoogleModal: false,
      shouldShowCancelModal: false,
      shouldShowCancellationErrorModal: false
    };

    /* 
      this.props:
        addClasses,
        authenticityToken,
        cancelDate,
        cancelPath,
        endDate,
        paymentMethod,
        planName,
        startDate,
        stripeKey,
        subscriptionBillingId,
        upgradePath,
        userEmail
    */
  }

  render() {
    let classes = toClassStr(['subscription-billing-row', this.props.addClasses]);

    return (
      <li className={classes}>
        <ul className="subscription-billing-row-items">
          <li className="row-item plan-name" onClick={(e) => this.handlePlanClick(e)}>
            <span className="plan-name">{this.props.planName}</span>
            <span className="glyphicon glyphicon-pencil" />
          </li>
          
          <li className="row-item start-date">{this.props.startDate}</li>       
          <li className="row-item end-date">{this.props.endDate}</li>

          {this.renderPaymentMethod()}          
          {this.renderCancelInfo()}
        </ul>
        {this.renderAppleModal()}
        {this.renderGoogleModal()}
        {this.renderCancelModal()}
        {this.renderCancellationErrorModal()}
      </li>
    );
  }

  renderPaymentMethod() {
    let stripeStatusClass;
    let triggerText = "Credit or Debit Card";

    if (this.props.paymentMethod != 'Stripe') {
      return (
        <li className="row-item payment-method" onClick={(e) => this.handleMobileGatewayClick(e)}>
          {this.props.paymentMethod}
        </li>
      );
    }

    if (this.state.isUpdateError) {
      stripeStatusClass = "is-error";
      triggerText = "Problem with your card info.";
    }

    if (this.state.isUpdateSuccess) {
      stripeStatusClass = "is-success";
      triggerText = "Card successfully updated.";
    }

    const classes = toClassStr(['row-item payment-method stripe-payment-method', stripeStatusClass]);

    return (
      <li className={classes}>
        <StripeCheckoutWidget 
          authenticityToken={this.props.authenticityToken}
          buttonLabel="Update Payment Details"
          onError={() => this.handleUpdateError()}
          onSuccess={() => this.handleUpdateSuccess()}
          stripeKey={this.props.stripeKey}
          subscriptionBillingId={this.props.subscriptionBillingId}
          triggerText={triggerText}
          userEmail={this.props.userEmail}
        />
      </li>
    )
  }

  renderCancelInfo() {
    if (this.state.isCancellationError) {
      return (
        <li className="row-item cancel-option is-error" onClick={(e) => this.handleCancelErrorMessageClick(e)}>
          <span className="cancel-status">Problem canceling your Subscription.</span>
          <span className="glyphicon glyphicon-info-sign" />
        </li>
      );
    }

    if (this.state.isCancellationSuccess) {
      return (
        <li className="row-item cancel-info is-success">
          Your Subscription was cancelled.
        </li>
      );
    }

    if (this.props.cancelDate) {
      return (
        <li className="row-item cancel-info canceled-at">
          Cancelled on {this.props.cancelDate}
        </li>
      );
    }

    return (
      <li className="row-item cancel-info cancel-option" onClick={(e) => this.handleCancelClick(e)}>
        Cancel
      </li>
    );
  }

  renderAppleModal() {
    if (!this.state.shouldShowAppleModal) {
      return null;
    }
    return (
      <ApplePaymentModal 
        show={this.state.shouldShowAppleModal}
        onClose={() => this.handleCloseModals()}
      />
    );
  }

  renderGoogleModal() {
    if (!this.state.shouldShowGoogleModal) {
      return null;
    }
    return (
      <GooglePaymentModal 
        show={this.state.shouldShowGoogleModal}
        onClose={() => this.handleCloseModals()}
      />
    );
  }

  renderCancelModal() {
    if (!this.state.shouldShowCancelModal) {
      return null;
    }
    return (
      <CancelSubscriptionModal 
        authenticityToken={this.props.authenticityToken}
        show={this.state.shouldShowCancelModal}
        onClose={() => this.handleCloseModals()}
        onError={() => this.handleCancellationError()}
        onSuccess={() => this.handleCancellationSuccess()}
        subscriptionBillingId={this.props.subscriptionBillingId}
      />
    );
  }

  renderCancellationErrorModal() {
    if (!this.state.shouldShowCancellationErrorModal) {
      return null;
    }
    return (
      <CancelSubscriptionModal 
        show={this.state.shouldShowCancellationErrorModal}
        onClose={() => this.handleCloseModals()}
        initialPhase={3}
      />
    );
  }

  handlePlanClick(e) {
    e.stopPropagation();
    location.href = this.props.upgradePath + '?paywall=upgrade_subscription_from_account';
  }

  handleMobileGatewayClick(e) {
    e.stopPropagation();

    if (this.props.paymentMethod == "Apple") {
      this.setState({
        shouldShowAppleModal: true
      });
    } else {
      this.setState({
        shouldShowGoogleModal: true
      });
    }
  }

  handleCancelClick(e) {
    e.stopPropagation();

    if (this.props.paymentMethod == "Apple") {
      this.setState({
        shouldShowAppleModal: true
      });
    } else if (this.props.paymentMethod == "Google") {
      this.setState({
        shouldShowGoogleModal: true
      });
    } else {
      this.setState({
        shouldShowCancelModal: true
      });
    }
  }

  handleCancellationSuccess() {
    this.setState({
      isCancellationSuccess: true
    });
  }

  handleCancellationError() {
    this.setState({
      isCancellationError: true
    });
  }

  handleCancelErrorMessageClick(e) {
    e.stopPropagation();
    this.setState({
      shouldShowCancellationErrorModal: true
    });
  }

  handleUpdateSuccess() {
    this.setState({
      isUpdateSuccess: true
    });
  }

  handleUpdateError() {
    this.setState({
      isUpdateError: true
    });
  }

  handleCloseModals() {
    this.setState({
      shouldShowAppleModal: false,
      shouldShowGoogleModal: false,
      shouldShowCancelModal: false,
      shouldShowCancellationErrorModal: false
    });
  }
}

export default SubscriptionBillingRow;
