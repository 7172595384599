
import RequiredMetadataModal   from '_views/modals/RequiredMetadataModal';
import EventManager            from '@brainscape/event-manager';
import React                   from 'react';
  
import packMetadata            from '_models/packMetadata';
import userLocalStore          from '_models/userLocalStore';


class RequiredMetadataModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      isProcessing: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListeners([
      ['required-metadata-modal:open',  this.handleOpenRequest],
      ['required-metadata-modal:close', this.handleCloseRequest],
    ]);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    return (
      <RequiredMetadataModal
        addClasses={viewProps.addClasses}
        isOpen={this.state.isModalOpen}
        metadata={viewProps.metadata}
        onModalSetCompleted={this.handleCloseRequest}
        onUpdatePackMetadataRequest={this.updatePackMetadata}
        pack={viewProps.pack}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  handleOpenRequest = (data) => {
    this.setState({
      isModalOpen: true,
      isProcessing: false,
      viewProps: data,
    }, () => {
      if (this.state.viewProps?.onOpenRequest) {
        this.state.viewProps.onOpenRequest();
      }
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updatePackMetadata = (opts) => {
    let metum = opts.metum;

    if (!metum) {
      metum = [{"field_id": opts.fieldId, "value": opts.value}];
    }

    const {userId, pack} = this.state.viewProps;

    packMetadata.update(pack.packId, metum).then(metadata => {
      const viewProps = {...this.state.viewProps};
      viewProps.metadata = metadata;

      userLocalStore.setPackMetadataStatus(userId, pack.packId, true);

      this.setState({
        viewProps: viewProps,
      }, () => {
        if (opts.callback) {
          opts.callback(metadata);
        }
      });
    });
  }
}

export default RequiredMetadataModalController;
