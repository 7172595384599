
import { BackButton }     from '_views/shared/IconButton';
import React              from 'react';

import {toClassStr}       from '_utils/UiHelper';

class LabeledBackButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };

    /*
    this.props:
      addClasses,
      label,
    */
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['labelled-back-button', this.props.addClasses]);

    return (
      <div 
        className={classes}
        onClick={(e) => this.handleButtonClick(e)}
      >

        <BackButton
          onClick={(e) => this.handleButtonClick(e)}
        />

        <div className="label">
          {this.props.label}
        </div>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

}

export default LabeledBackButton;
