
import PropTypes              from 'prop-types';
import React                  from 'react';

import DynamicTooltipIcon     from '_views/shared/DynamicTooltipIcon';
import {toClassStr}           from '_utils/UiHelper';

const PT = {
  addClasses:                 PropTypes.string,
  background:                 PropTypes.string, // 'dark' (default) or 'light'
  isMobileViewportSize:       PropTypes.bool,
  label:                      PropTypes.string,
  orientation:                PropTypes.string, // 'vertical' (default) or 'horizontal' 
  tooltipHeading:             PropTypes.string,
  tooltipSubHeading:          PropTypes.string,
  tooltipBody:                PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
  ]),
  tooltipPosition:            PropTypes.string,
  value:                      PropTypes.node,
};


class Stat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const orientationClass = this.props.orientation || 'vertical';
    const bgColorClass = (this.props.background == 'light') ? 'light-bg' : 'dark-bg';

    const classes = toClassStr(['stat', orientationClass, bgColorClass, this.props.addClasses]);

    return (
      <div className={classes}>
        <div className="label-and-label-tooltip">
          <div className="stat-label">{this.props.label}</div>
          {this.renderLabelTooltip()}
        </div>
        <div className="stat-value">{this.props.value}</div>
        {this.renderStatTooltip()}
      </div>
    );
  }

  renderLabelTooltip() {
    if (this.props.orientation == 'horizontal') {
      return null;
    }

    if (!this.props.tooltipBody) {
      return null;
    }

    return this.renderDynamicTooltipIcon();
  }

  renderStatTooltip() {
    if (this.props.orientation != 'horizontal') {
      return null;
    }

    if (!this.props.tooltipBody) {
      return null;
    }

    return this.renderDynamicTooltipIcon();
  }

  renderDynamicTooltipIcon() {
    const { tooltipHeading, tooltipSubHeading, tooltipBody, tooltipPosition } = this.props;

    return (
      <DynamicTooltipIcon 
        addClasses="stat-tooltip-icon"
        delay={500}
        hasDismissButton={true}
        heading={tooltipHeading}
        subHeading={tooltipSubHeading}
        body={tooltipBody}
        iconType="info"
        isMobileViewportSize={this.props.isMobileViewportSize}
        position={tooltipPosition}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

Stat.propTypes = PT;

export default Stat;
