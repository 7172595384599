
import EventManager                     from '@brainscape/event-manager';
import ForgotModal                      from '_registration/ForgotModal';
import PillButton                       from '_views/shared/PillButton';
import PropTypes                        from 'prop-types';
import React                            from 'react';
import ResetLocalPrefsOption            from '_account/ResetLocalPrefsOption';
import SimpleTextButton                 from '_views/shared/SimpleTextButton';
import TextField                        from '_views/shared/TextField';
import UiHelper                         from '_utils/UiHelper';
import UnlinkIdentityProviderOptions    from '_account/UnlinkIdentityProviderOptions';
import SubscriptionSection              from '_account/SubscriptionSection';

import userPassword                     from '_legacy-models/userPassword';

import { toClassStr }           from '_utils/UiHelper';


const PT = {
  authenticityToken:                  PropTypes.string,
  currentUser:                        PropTypes.object,
  editedAccountSettings:              PropTypes.object,
  isEditingAccountSettings:           PropTypes.bool,
  isLoadingUserInfo:                  PropTypes.bool,
  isMobileViewportSize:               PropTypes.bool,
  onFieldChange:                      PropTypes.func,
  onFormSubmit:                       PropTypes.func,
  onResetFormRequest:                 PropTypes.func,
  stripeKey:                          PropTypes.string,

};

class AccountSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      isCancellationError: false,
      isCancellationSuccess: false,
      isDataInvalid: false,
      isForgotModalOpen: false,
      shouldShowCancelModal: false,
      shouldShowCancellationErrorModal: false,
      value: '',
    };

    this._isMounted = false;

    this.validationDelay = null;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <div className='account-settings-wrapper'>
        {this.renderPasswordSection()}
        {this.renderSubscriptionSection()}
        {this.renderAdvancedSection()}
        {this.renderForgotModal()}
      </div>
    );
  }

  renderAdvancedSection() {
    return (
      <div className='section advanced-section'>
        <h5 className='form-heading'>Advanced</h5>

        <ResetLocalPrefsOption 
          addClasses="link"
          userId={this.props.currentUser.userId}
        />

        <UnlinkIdentityProviderOptions
          addClasses="link"
          currentUser={this.props.currentUser}
        />

        <SimpleTextButton 
          addClasses='link'
          label="Delete Account"
          onClick={this.handleDeleteAccountButtonClick}
        />
      </div>
    )
  }

  renderDiscardButton() {
    if (!this.props.isEditingAccountSettings) {
      return null;
    }

    return (
      <SimpleTextButton
        addClasses="discard-button"
        label="Discard Changes"
        onClick={this.props.onResetFormRequest}
      />
    );
  }

  renderForgotPasswordButton() {
    const hasErrorClass = (this.state.errors.currentPassword) ? 'has-error-message' : '';
    const classes = toClassStr(['forgot-password-button', hasErrorClass]);

    return (
      <SimpleTextButton 
        addClasses={classes}
        label="Forgot Password?"
        onClick={this.handleForgotPasswordButtonClick}
      /> 
    );
  }

  renderForgotModal() {
    if (!this.state.isForgotModalOpen) {
      return null;
    }

    return (
      <ForgotModal 
        isOpen={this.state.isForgotModalOpen}
        onCloseRequest={this.handleCloseForgotModalRequest}
        shouldSuppressRedirect={true}
      />
    );
  }

  renderPasswordSection() {
    const identityProvider = this.props.currentUser.profile.identityProviders;

    if (!identityProvider && identityProvider[0] != 'brainscape') {
      return (
        <div className='section password-or-provider-section'>
          <h5 className='form-heading'>Login info</h5>
          <div className='password-or-provider-details'>
            <div className='detail'>Your current account is linked to: <span>{identityProvider}</span></div>
          </div>
        </div>
      )
    }

    const accountSettings = this.props.editedAccountSettings;

    return (
      <form className='section password-or-provider-section form account-form' onSubmit={this.handleFormSubmit}>
        <h5 className='form-heading'>Password</h5>

        <TextField
          errorMessage={this.state.errors.currentPassword ? this.state.errors.currentPassword  : ''}         
          isInvalid={!!this.state.errors.currentPassword}  
          label='Current Password'
          onBlur={(e) => this.handleFieldBlur(e, 'currentPassword')}
          onChange={(e) => this.handleFieldChange(e, 'currentPassword')}
          name="currentPassword"     
          placeholder="xxxxxxxxxxxx"
          type='password'
          value={accountSettings.currentPassword}
        />

        {this.renderForgotPasswordButton()}

        <TextField
          errorMessage={this.state.errors.password ? this.state.errors.password  : ''}         
          isInvalid={!!this.state.errors.password}  
          label='New Password'
          onBlur={(e) => this.handleFieldBlur(e, 'password')}
          onChange={(e) => this.handleFieldChange(e, 'password')}  
          name="password"   
          type='password'
          value={accountSettings.password}
        />

        <TextField
          errorMessage={this.state.errors.passwordConfirmation ? this.state.errors.passwordConfirmation  : ''}
          isInvalid={!!this.state.errors.passwordConfirmation}   
          label='Confirm New Password'
          onBlur={(e) => this.handleFieldBlur(e, 'passwordConfirmation')}
          onChange={(e) => this.handleFieldChange(e, 'passwordConfirmation')}  
          name="passwordConfirmation"   
          type='password'
          value={accountSettings.passwordConfirmation}
        />

        <div className='discard-and-save-actions'>
          <PillButton
            addClasses="pill-button-emphasized pill-button-xsmall"
            isDisabled={(!this.props.isEditingAccountSettings || this.state.isDataInvalid)}
            label="Save"
            onClick={this.handleFormSubmit}
          />

          {this.renderDiscardButton()}

          <input type='submit' className='hidden-submit-button' />
        </div> 
      </form>
    )
  }

  renderSubscriptionSection() {
    return (
      <div className='section subscription-section'>
        <h5 className='form-heading'>Subscription plan</h5>
        <SubscriptionSection
          currentUser={this.props.currentUser}
          stripeKey={this.props.stripeKey}
          isMobileViewportSize={this.props.isMobileViewportSize}
        />
      </div>
    )
  }

  /*
  ==================================================
   HANDLERS
  ==================================================
  */

  handleCloseForgotModalRequest = () => {
    this.setState({
      isForgotModalOpen: false,
    });
  }

  handleDeleteAccountButtonClick = () => {
    UiHelper.openInNewTab('https://brainscape.zendesk.com/hc/en-us/articles/115002370611-How-do-I-delete-my-account-');
  }

  handleFieldBlur = (e, fieldKey) => {
    e.stopPropagation();
    this.validateData();
  } 

  handleFieldChange = (e, fieldKey) => {
    e.stopPropagation();
    const value = e.target.value;

    if (this.props.onFieldChange) {
      this.props.onFieldChange(fieldKey, value);
    }

    if (this.state.isDataInvalid && this.state.errors[fieldKey]) {
      // if user is correcting a field with an error, validate as changes propagate in
      this.validationDelay = setTimeout(this.validateData, 500);
      return true;
    }

    if (this.props.editedAccountSettings.passwordConfirmation) {
      // if user has made input on the last field of form, wait until they stop typing, then validate

      if (this.validationDelay) {
        clearTimeout(this.validationDelay);
      }

      this.validationDelay = setTimeout(this.validateData, 1000);
    }
  }

  handleForgotPasswordButtonClick = () => {
    this.setState({
      isForgotModalOpen: true,
    });
  }

  handleFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const test = this.validateData();

    if (test.isValid) {
      this.props.onFormSubmit();
    }
  }

  handleCloseModals() {
    this.setState({
      shouldShowCancelModal: false,
      shouldShowCancellationErrorModal: false
    });
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerUpgradeModalOpen = () => {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: "update plan",
      paywall:       "account_settings_update_plan",
    });
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.validationDelay);
  }

  validateData = () => {
    const test = userPassword.validate(this.props.editedAccountSettings);

    this.setState({
      errors: test.errors || {},
      isDataInvalid: !test.isValid,
    });

    return test;
  }
}

AccountSettingsForm.propTypes = PT;

export default AccountSettingsForm;
