
import EventManager           from '@brainscape/event-manager';
import PropTypes              from 'prop-types';
import React                  from 'react';
import UploadableFile         from '_views/shared/UploadableFile';

import {toClassStr}           from '_utils/UiHelper';

// models
import pack                   from '_models/pack';

// images
import defaultPackIconLightBg from '_images/shared/ugc-clear-bg.svg';
import defaultPackIconDarkBg  from '_images/shared/ugc-blue-bg.svg';


const PT = {
  addClasses:                 PropTypes.string,
  backgroundType:             PropTypes.string,
  isEditable:                 PropTypes.bool,
  pack:                       PropTypes.object,
};


class PackIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const pack = this.props.pack;

    const hasArtworkFile = !!(pack.activeIconUrl || pack.iconUrl);
    const artworkUrlOrImage = pack.activeIconUrl || pack.iconUrl || this.getFallbackImage();

    const classes = toClassStr(['pack-icon', this.props.addClasses]);

    if (this.props.isEditable && pack.permission == 'admin') {
      return (
        <UploadableFile
          addClasses={classes}
          backgroundImageUrl={artworkUrlOrImage}
          fileMediaType="image"
          hasEditButton={true}
          hasRemoveButton={hasArtworkFile}
          isProcessing={this.state.isProcessing}
          onFileLoaded={this.handlePackIconFileLoaded}
          onFileRemoveRequest={this.handlePackIconFileRemoveRequest}
        />
      );
    }

    return (
      <div className={classes}>
        <img className="pack-icon-image" src={artworkUrlOrImage} />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handlePackIconFileLoaded = (fileHandle) => {
    this.setState({
      isProcessing: true,
    });

    const packData = {
      pack: {
        activeIconUrl: fileHandle,
      }
    };

    pack.update(this.props.pack.packId, packData).then(() => {
      this.setState({
        isProcessing: false,
      });
    });
  }

  handlePackIconFileRemoveRequest = () => {
    this.setState({
      isProcessing: true,
    });

    this.triggerConfirmModalOpen({
      actionText: "remove this class's icon image",
      resolveButtonText: 'Yes, remove image',
      onResolution: () => {
        const packData = {
          pack: {
            activeIconUrl: 'destroy',
          }
        };

        pack.update(this.props.pack.packId, packData).then(() => {
          this.setState({
            isProcessing: false,
          });
        });
      },
      onCloseRequest: () => {
        this.setState({
          isProcessing: false,
        });
      }
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen = (viewProps) => {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  getFallbackImage = () => {
    return (this.props.backgroundType == 'dark') ? defaultPackIconDarkBg : defaultPackIconLightBg;
  }
}

PackIcon.propTypes = PT;

export default PackIcon;
