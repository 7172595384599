
import PropTypes from 'prop-types';
import React     from 'react';

class Link extends React.Component {
  
  render = () => {
    return <a href='#!' onClick={this.handleClick}>{this.props.children}</a>;
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onClick(e);
  }
}

Link.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Link;
