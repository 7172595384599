
import * as Cookies from "js-cookie";

const CookieHelper = {

  getCookie(name) {
    return Cookies.get(name) || null;
  },

  removeCookie(name, path) {
    let params = {};

    if (path) {
      params.path = path;
    }

    Cookies.remove(name, params);
  },

  setCookie(name, value, expires, path) {
    // expires can be a date object or an number representing days (ex. 365 == one year)
    let params = {};

    if (expires) {
      params.expires = expires;
    }

    if (path) {
      params.path = path;
    }

    Cookies.set(name, value, params);
  },
};

export default CookieHelper;
