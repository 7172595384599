
const ColorHelper = {

  getCharColor(char) {
    return (char.charCodeAt(0)) % 10;
  },

  getTextColor(text) {
    let levels = [];

    for (let i=0; i<text.length; i++) {
      levels.push(this.getCharColor(text[i]));
    }

    const redLevel = levels[0] || 9;
    const greenLevel = levels[1] || 9;
    const blueLevel = levels[2] || 9;

    return `#${redLevel}${greenLevel}${blueLevel}`;
  },
}

export default ColorHelper;
