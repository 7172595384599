
import {
  PlayButton,
  PauseButton,
} from '_views/shared/IconButton';

import EventManager       from '@brainscape/event-manager';
import PropTypes          from 'prop-types';
import React              from 'react';
import Spinner            from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses:       PropTypes.string,
  shouldAutoplay:   PropTypes.bool,
  soundId:          PropTypes.node,
  soundUrl:         PropTypes.string,
};


class SoundButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPaused: false,
      isPlaying: false,
    }

    this.player = null;
    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    this.events.addListeners([
      ['sound-player:playing', this.handleSoundButtonPlaying],
      ['sound-player:stop-all-request', this.handleStopAllRequest],
    ]);

    if (this.props.soundUrl && this.props.shouldAutoplay) {
      this.loadAndPlaySound();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.soundUrl && (this.props.soundUrl != prevProps.soundUrl)) {

      if (this.props.shouldAutoplay) {
        this.loadAndPlaySound();
      } else {
        this.loadSound();
      }
    }

    if (!prevProps.shouldAutoplay && this.props.shouldAutoplay) {
      this.loadAndPlaySound();
    } 
  }

  componentWillUnmount() { 
    this.events.disable();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.props.soundUrl) {
      return null;
    }

    const isPlayingClass = (this.state.isPlaying) ? 'is-playing' : '';
    const isLoadingClass = (this.state.isLoading) ? 'is-loading' : '';
    const classes = toClassStr(['sound-button', isLoadingClass, isPlayingClass, this.props.addClasses]);

    return (
      <div className={classes}>

        <div className="buttons">
          {this.renderPlayPauseToggle()}
        </div>

        <div className="player-container">
          {this.renderAudioTag()}
        </div>
      </div>
    );
  }

  renderPlayPauseToggle() {
    if (!this.props.soundUrl) {
      return null;
    }

    if (this.state.isPlaying) {
      return (
        <PauseButton
          addClasses="pause-sound-button"
          onClick={this.handlePauseSoundButtonClick}
        />
      );
    }

    return (
      <PlayButton
        addClasses="play-sound-button"
        onClick={this.handlePlaySoundButtonClick}
      />
    );
  }

  renderAudioTag() {
    const soundPlayerId = `sound-player-${this.props.soundId}`;

    return (
      <audio
        autoPlay={this.props.shouldAutoplay}
        className="sound-player"
        id={soundPlayerId}
        onEnded={() => this.handleSoundEnded()}
        ref={(elem) => this.player = elem}
      >
        <source
          src={this.props.soundUrl}
        />
      </audio>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handlePlaySoundRequest = () => {
    const soundPlayer = this.player;

    if (!(soundPlayer && this.props.soundUrl)) {
      return null;
    }

    if (this.state.isPaused) {
      this.resumeSound();
    } else {
      this.loadAndPlaySound();
    }
  }

  handlePlaySoundButtonClick = () => {
    this.handlePlaySoundRequest();
  }

  handlePauseSoundButtonClick = () => {
    this.pauseSound();
  }

  handleSoundButtonPlaying = (data) => {
    // stop this instance's sound if another sound is playing
    const soundId = data.soundId;

    if (soundId && soundId != this.props.soundId) {
      this.stopSound();
    }
  }

  handleSoundEnded = () => {
    this.stopSound();
  }

  handleStopAllRequest = () => {
    this.stopSound();
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishSoundPlaying() {
    EventManager.emitEvent('sound-player:playing', {
      soundId: this.props.soundId,
    });
  }

  publishSoundPaused() {
    EventManager.emitEvent('sound-player:paused', {
      soundId: this.props.soundId,
    });
  }

  publishSoundStopped() {
    EventManager.emitEvent('sound-player:stopped', {
      soundId: this.props.soundId,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  loadAndPlaySound = () => {
    if (!this._isMounted) {
      return false;
    }
    
    const soundPlayer = this.player;

    if (!(soundPlayer && this.props.soundUrl)) {
      return null;
    }

    const soundPlayerSource = this.player.querySelector('source');
    soundPlayerSource.src = this.props.soundUrl;
    soundPlayer.load();

    const playPromise = soundPlayer.play();

    if (playPromise) {
      playPromise.then(() => {
        this.setState({
          isPlaying: true,
        }, () => {
          this.publishSoundPlaying();
        });
      }).catch((err) => {
        console.log('in SoundButton.loadAndPlaySound playPromise catch block. err:', err);

        if (this._isMounted) {
          this.setState({
            isPlaying: false,
          });
        }
      });
    } else {
      // older browser, we will assume play call was successful
      this.setState({
        isPlaying: true,
      }, () => {
        this.publishSoundPlaying();
      });
    }
  }

  loadSound = () => {
    if (!this._isMounted) {
      return false;
    }
    
    const soundPlayer = this.player;

    if (!(soundPlayer && this.props.soundUrl)) {
      return null;
    }

    const soundPlayerSource = this.player.querySelector('source');
    soundPlayerSource.src = this.props.soundUrl;

    if (this._isMounted) {
      soundPlayer.load();
    }

    this.setState({
      isPlaying: false,
    });
  }  

  pauseSound = () => {
    if (!this._isMounted) {
      return false;
    }
    
    const soundPlayer = this.player;

    if (!(soundPlayer && this.props.soundUrl)) {
      return null;
    }

    soundPlayer.pause();

    this.setState({
      isPaused: true,
      isPlaying: false,
    }, () => {
      this.publishSoundPaused();
    });
  }

  resumeSound = () => {
    if (!this._isMounted) {
      return false;
    }
    
    const soundPlayer = this.player;

    if (!(soundPlayer && this.props.soundUrl)) {
      return null;
    }

    if (this._isMounted) {
      soundPlayer.play();
    }

    this.setState({
      isPaused: false,
      isPlaying: true,
    }, () => {
      this.publishSoundPlaying();
    });
  }

  stopSound = () => {
    if (!this._isMounted) {
      return false;
    }

    const soundPlayer = this.player;

    if (!soundPlayer) {
      return null;
    }

    const soundPlayerSource = this.player.querySelector('source');

    soundPlayer.pause();
    soundPlayerSource.removeAttribute('src');
    soundPlayer.load();

    this.setState({
      isPaused: false,
      isPlaying: false,
    }, () => {
      this.publishSoundStopped();
    });
  }
}


SoundButton.propTypes = PT;

export default SoundButton;
