
import Bubble                         from '_views/shared/Bubble';
import BrowserCompatibilityModal      from '_views/modals/BrowserCompatibilityModal';
import Checkpoint                     from '_study/Checkpoint';
// import CramPaywallBubble              from '_study/CramPaywallBubble';
// import CramPaywallUpgradeModal        from '_modals/CramPaywallUpgradeModal';
import EventManager                   from '@brainscape/event-manager';
import FtseMessaging                  from '_study/FtseMessaging';
import GaHelper                       from '_utils/GaHelper'
import LibraryModel                   from '_legacy-models/LibraryModel';
import React                          from 'react';
// import SetReminderModal               from '_modals/SetReminderModal';
import StudyCardTable                 from '_study/StudyCardTable';
import StudyHelper                    from '_utils/StudyHelper';
import StudyMixModel                  from '_legacy-models/StudyMixModel';
import StudyModalControllers          from '_study/StudyModalControllers';
import StudySidebar                   from '_study/StudySidebar';
import TooltipController              from '_controllers/TooltipController';
import Tracker                        from '_utils/Tracker';
import UiHelper                       from '_utils/UiHelper';
import userLocalStore                 from '_models/userLocalStore';

import ToastController                from '_controllers/ToastController';

import { toClassStr }                 from '_utils/UiHelper';

import 'url-search-params-polyfill'; // polyfill to support IE and Edge


class StudyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classCreator: props.classCreator,
      cramPaywallMessage: null,
      timeStudied: 0,
      dashboardPath: '/',
      firstAnswerMarkedAt: null,
      firstMixPackId: null,
      isAnswerShowing: false,
      isAudioMuted: true,
      isClosingStudyMix: false,
      isContinuousStudyEnabled: false,
      isMixRestricted: false,
      isMobileViewportSize: false,
      isUserMixAdmin: false,
      isUserPro: false,
      libraryPath: '/',
      mixCardCount: 0,
      mixCardsStudied: 0,
      mixDeckCount: 0,
      mixDecks: null,
      mixDescription: null,
      mixIconUrl: null,
      mixFullname: null,
      mixMastery: 0,
      mixName: 'Study Mix',
      mixPackCount: 0,
      mixRatingLevelCounts: [],
      mixRoundCount: 0,
      mixTimeToComplete: 0,
      mixType: null,
      nextRoundFirstStepCard: null,
      preRoundStepRatings: [0],
      questionStartTime: new Date(),
      reminderDialogInfo: null,
      reminderProcessing: false,
      roundConfidenceGained: 0,
      roundElapsedTime: 0,
      roundStartTime: null,
      roundStepCount: 10,
      roundStepIndex: 0,
      roundStepRatings: [0],
      sessionElapsedTime: 0,
      sessionMastery: 0,
      sessionRatingLevelCounts: [],
      sessionRatings: {},
      showUpgradeDialog: false,
      stepCard: null,
      stepConfidenceLevel: 0,
      stepData: null,
      stepDeckCardCount: 0,
      stepDeckCardIndex: 0,
      stepDeck: null,
      upgradePaywall: null,
      userId: null,

      // checkpoint support
      checkpointType: 'fullScreen',
      isAtCheckpoint: false,
      isAudioMuted: false,
      nextCheckpointType: 'fullScreen',
      roundEndConfidenceGained: 0,
      shouldAnimateCheckpointMessaging: false,
      shouldCloseCheckpoint: false,
      shouldCompressRoundEndConfidence: false,
      shouldDimRoundEndConfidence: false,
      shouldPulseNextRoundButton: false,
      shouldShowRoundEndConfidence: false,

      // ftse support
      ftseAnswerCardbarRating: 0,
      ftsePostCbrBubbleAction: null,
      shouldClosePreExitBubble: false,
      shouldPulseAnswerCardbar: false,
      shouldPulseBackToDashboardButton: false,
      shouldPulseNextRoundButton: false,
      shouldPulseQuestionCardbar: false,
      shouldShowPreExitBubble: false,
      wasAnswerCardbarClicked: false,
      wasPreExitBubbleShown: false,
      wasQuestionCardbarClicked: false,
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        classCreator,
        isFtse,
        isFtue,
        mixName,
    */

    // this.Library = new LibraryModel();
    this.StudyMix = new StudyMixModel({ isFtse: this.props.isFtse });
    this.events = new EventManager();

    this._isMounted = false;

    this.ftseCheckpointShown = false;
    this.postRoundTimeout = null;
    this.preCheckpointAnimationsTimeout = null;
    this.preResetMetersTimeout = null;
    this.preCompressRoundEndConfidenceTimeout = null;
    this.prePulseNextRoundButtonTimeout = null;
    this.resetStepRatingsInterval = null;

    this.STEPS_PER_ROUND = 10;

    this.STEP_RESET_INTERVAL = 35;
    this.MASTERY_COUNT_UP_INTERVAL = 150;

    this.FULL_SCREEN_POST_ROUND_DELAY = 2000;
    this.SIDEBAR_POST_ROUND_DELAY = 100;

    this.PRE_CHECKPOINT_ANIMATION_DELAY = 750;
    this.CHECKPOINT_ANIMATION_DURATION = 300; // as set in CSS Animation
    this.BUILD_MASTERY_DURATION = 1200;

    this.PRE_COMPRESS_ROUND_END_CONFIDENCE_DELAY = 0;
    this.COMPRESS_ROUND_END_CONFIDENCE_DURATION = 300; // AS set in CSS Animation

    this.PRE_RESET_METERS_DELAY = 0;
    this.RESET_METERS_DURATION =
      this.STEP_RESET_INTERVAL * this.STEPS_PER_ROUND;

    this.PRE_PULSE_NEXT_ROUND_BUTTON_DELAY = 1300;

    this.bannerTimeout = null;
    this.flashMessageTimeout = null;
    // this.BANNER_DELAY = 1500;
    // this.FLASH_MESSAGE_DELAY = 1500;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.setupNewStudyMix();
    this.manageViewport();
    // this.displayIncomingFlashMessages();
    // this.displayIncomingBanners();
    this.startWindowResizeMonitor();

    this.events.addListener('card:updated', this.handleCardUpdated);

    this.events.addListeners([
      ['flash-message:open-requested', this.handleFlashMessageOpenRequested],
      ['flash-message:close-requested', this.handleFlashMessageCloseRequested],
      ['banner:open-requested', this.handleBannerOpenRequested],
      ['banner:close-requested', this.handleBannerCloseRequested],
      ['pack-confidences:reset', this.handlePackConfidencesReset],
    ]);
  }

  componentWillUnmount() {
    this.events.disable();
    this.stopWindowResizeMonitor();
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    // const isBannerOpenClass = this.state.isBannerOpen ? 'is-banner-open' : '';
    // const isFlashMessageOpenClass = this.state.isFlashMessageOpen
      // ? 'is-flash-message-open'
      // : '';
    let classes = toClassStr([
      'study-page',
      // isBannerOpenClass,
      // isFlashMessageOpenClass,
      this.props.addClasses,
    ]);

    if (this.state.isMobileViewportSize) {
      UiHelper.hideZendeskWidget();
    }

    return (
      <div className={classes}>
        <TooltipController />
        <ToastController />

        {/* <FlashMessageController */}
        {/*   block="page" */}
        {/*   page="study" */}
        {/*   position="top" */}
        {/*   userId={this.state.userId} */}
        {/* /> */}

        {/* <BannerController */}
        {/*   block="page" */}
        {/*   page="study" */}
        {/*   position="top" */}
        {/*   userId={this.state.userId} */}
        {/* /> */}

        <div className="page-shell">
          {this.renderStudySidebar()}
          {this.renderStudyCardTable()}
          {this.renderBrowserCompatibilityModal()}
          {this.renderCheckpointBubble()}
          {/* {this.renderCramPaywallBubble()} */}
          {this.renderFtseMessaging()}
          {/* {this.renderUpgradeDialog()} */}
          {/* {this.renderSetReminder()} */}
        </div>

{/*         <FlashMessageController */}
{/*           block="page" */}
{/*           page="study" */}
{/*           position="bottom" */}
{/*           userId={this.state.userId} */}
{/*         /> */}
{/*  */}
{/*         <BannerController */}
{/*           block="page" */}
{/*           page="study" */}
{/*           position="bottom" */}
{/*           userId={this.state.userId} */}
{/*         /> */}

        {/* <PageLoadingOverlayController /> */}
        <StudyModalControllers currentUser={this.state.currentUser} />
      </div>
    );
  }

  renderStudySidebar() {
    return (
      <StudySidebar
        buildMasteryDuration={this.BUILD_MASTERY_DURATION}
        checkpointType={this.state.checkpointType}
        currentUser={this.state.currentUser}
        dashboardPath={this.state.dashboardPath}
        isAtCheckpoint={this.state.isAtCheckpoint}
        isContinuousStudyEnabled={this.state.isContinuousStudyEnabled}
        isMixRestricted={this.state.isMixRestricted}
        isMobileViewportSize={this.state.isMobileViewportSize}
        isUserMixAdmin={this.state.isUserMixAdmin}
        isUserPro={this.state.isUserPro}
        mixCardCount={this.state.mixCardCount}
        mixCardsStudied={this.state.mixCardsStudied}
        mixDeckCount={this.state.mixDeckCount}
        mixDecks={this.state.mixDecks}
        mixDescription={this.state.mixDescription}
        mixFullname={this.state.mixFullname}
        mixIconUrl={this.state.mixIconUrl}
        mixMastery={this.state.mixMastery}
        mixName={this.state.mixName}
        mixRatingLevelCounts={this.state.mixRatingLevelCounts}
        mixRoundCount={this.state.mixRoundCount}
        mixTimeToComplete={this.state.mixTimeToComplete}
        mixType={this.state.mixType}
        onCheckpointClosed={() => this.finishCloseCheckpoint()}
        onCloseStudyMixRequest={() => this.handleBackToLibraryRequest()}
        onDismissSidebarCheckpoint={() => this.handleDismissSidebarCheckpoint()}
        // onResetMixStats={() => this.handleResetMixStats()}
        onSetStudyMixTypeRequest={(mixType) =>
          this.handleSetStudyMixTypeRequest(mixType)
        }
        onNextRoundRequest={() => this.handleNextRoundRequest()}
        onToggleContinuousStudy={() => this.handleToggleContinuousStudy()}
        preRoundStepRatings={this.state.preRoundStepRatings}
        roundConfidenceGained={this.state.roundConfidenceGained}
        roundElapsedTime={this.state.roundElapsedTime}
        roundEndConfidenceGained={this.state.roundEndConfidenceGained}
        roundStepCount={this.state.roundStepCount}
        roundStepIndex={this.state.roundStepIndex}
        roundStepRatings={this.state.roundStepRatings}
        sessionElapsedTime={this.state.sessionElapsedTime}
        sessionMastery={this.state.sessionMastery}
        sessionRatings={this.state.sessionRatings}
        sessionRatingLevelCounts={this.state.sessionRatingLevelCounts}
        shouldCloseCheckpoint={this.state.shouldCloseCheckpoint}
        shouldCompressRoundEndConfidence={
          this.state.shouldCompressRoundEndConfidence
        }
        shouldDimRoundEndConfidence={this.state.shouldDimRoundEndConfidence}
        shouldPulseNextRoundButton={this.state.shouldPulseNextRoundButton}
        shouldShowRoundEndConfidence={this.state.shouldShowRoundEndConfidence}
        stepCard={this.state.stepCard}
        timeStudied={this.state.timeStudied}
        userId={this.state.userId}
      />
    );
  }

  renderStudyCardTable() {
    return (
      <StudyCardTable
        authenticityToken={this.props.authenticityToken}
        currentUser={this.state.currentUser}
        dashboardPath={this.state.dashboardPath}
        isAnswerShowing={this.state.isAnswerShowing}
        isAtCheckpoint={this.state.isAtCheckpoint}
        isAudioMuted={this.state.isAudioMuted}
        isClosingStudyMix={this.state.isClosingStudyMix}
        isFtse={this.props.isFtse}
        isMobileViewportSize={this.state.isMobileViewportSize}
        isUserPro={this.state.isUserPro}
        mixName={this.state.mixName}
        nextStepCard={this.state.nextStepCard}
        nextStepConfidenceLevel={this.state.nextStepConfidenceLevel}
        onAnswerCardbarClick={(rating, ftsePostCbrBubbleAction) =>
          this.handleAnswerCardbarClick(rating, ftsePostCbrBubbleAction)
        }
        onCardFlipRequest={() => this.handleCardFlipRequest()}
        onCloseStudyMixRequest={() => this.handleBackToLibraryRequest()}
        onConfidenceRating={(cardId, rating) =>
          this.handleConfidenceRating(cardId, rating)
        }
        onQuestionCardbarClick={() => this.handleQuestionCardbarClick()}
        shouldPulseAnswerCardbar={this.state.shouldPulseAnswerCardbar}
        shouldPulseQuestionCardbar={this.state.shouldPulseQuestionCardbar}
        stepCard={this.state.stepCard}
        stepConfidenceLevel={this.state.stepConfidenceLevel}
        stepData={this.state.stepData}
        stepDeckCardCount={this.state.stepDeckCardCount}
        stepDeckCardIndex={this.state.stepDeckCardIndex}
        stepDeckName={this.state.stepDeckName}
      />
    );
  }

  renderBrowserCompatibilityModal() {
    if (!this.state.userId) {
      return null;
    }

    return <BrowserCompatibilityModal userId={this.state.userId} />;
  }

  renderCheckpointBubble() {
    if (!this.state.isAtCheckpoint || this.state.checkpointType == 'sidebar') {
      return null;
    }
    if (this.state.cramPaywallMessage) {
      return null;
    }

    const animateClass = this.state.shouldAnimateCheckpointMessaging
      ? 'animate-messaging'
      : '';

    const classes = toClassStr(['checkpoint-bubble', this.props.addClasses]);

    return (
      <Bubble
        addClasses={classes}
        expansionDelay={500}
        isEscapeKeyActive={true}
        isScriptedClose={true}
        onClose={() => this.finishCloseCheckpoint()}
        onCloseRequest={() => this.handleNextRoundRequest()}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCloseCheckpoint}
        shouldContract={true}
        shouldExpand={true}
        size="full"
      >
        <Checkpoint
          addClasses={animateClass}
          buildMasteryDuration={this.BUILD_MASTERY_DURATION}
          checkpointType={this.state.checkpointType}
          currentUser={this.state.currentUser}
          isContinuousStudyEnabled={this.state.isContinuousStudyEnabled}
          isFtse={this.props.isFtse}
          isMixRestricted={this.state.isMixRestricted}
          isMobileViewportSize={this.state.isMobileViewportSize}
          isUserPro={this.state.isUserPro}
          mixCardCount={this.state.mixCardCount}
          mixCardsStudied={this.state.mixCardsStudied}
          mixDeckCount={this.state.mixDeckCount}
          mixDecks={this.state.mixDecks}
          mixMastery={this.state.mixMastery}
          mixName={this.state.mixName}
          mixRatingLevelCounts={this.state.mixRatingLevelCounts}
          mixRoundCount={this.state.mixRoundCount}
          mixTimeToComplete={this.state.mixTimeToComplete}
          onNextRoundRequest={() => this.handleNextRoundRequest()}
          onCheckpointClosed={() => this.finishCloseCheckpoint()}
          onCloseStudyMixRequest={() => this.handleCloseStudyMix()}
          onDismissSidebarCheckpoint={() =>
            this.handleDismissSidebarCheckpoint()
          }
          onToggleContinuousStudy={() => this.handleToggleContinuousStudy()}
          roundElapsedTime={this.state.roundElapsedTime}
          sessionMastery={this.state.sessionMastery}
          sessionRatings={this.state.sessionRatings}
          sessionRatingLevelCounts={this.state.sessionRatingLevelCounts}
          shouldCloseCheckpoint={this.state.shouldCloseCheckpoint}
          shouldPulseBackToDashboardButton={
            this.state.shouldPulseBackToDashboardButton
          }
          shouldPulseNextRoundButton={this.state.shouldPulseNextRoundButton}
          stepCard={this.state.stepCard}
          timeStudied={this.state.timeStudied}
        />
      </Bubble>
    );
  }

//   renderCramPaywallBubble() {
//     const msg = this.state.cramPaywallMessage;
//     if (!msg) {
//       return null;
//     }
// 
//     return (
//       <CramPaywallBubble
//         message={msg}
//         onClose={() => {
//           window.location = '/';
//         }}
//         onSetStudyReminder={this.onSetStudyReminder}
//         onSkipTheBreak={this.onSkipTheBreak}
//         reminderProcessing={this.state.reminderProcessing}
//       />
//     );
//   }

  renderFtseMessaging() {
    if (!this.props.isFtse || this.state.mixRoundCount > 1 || this.state.isAtCheckpoint) {
      return null;
    }

    const isAtRoundMidpoint = this.state.roundStepIndex == 5;

    return (
      <FtseMessaging
        ftseAnswerCardbarRating={this.state.ftseAnswerCardbarRating}
        ftsePostCbrBubbleAction={this.state.ftsePostCbrBubbleAction}
        isAtRoundMidpoint={isAtRoundMidpoint}
        onAnswerCardbarPulseTrigger={() =>
          this.handleAnswerCardbarPulseTrigger()
        }
        onConfirmCloseStudyMix={() => this.handleCloseStudyMix()}
        onPreExitBubbleClosed={() => this.handlePreExitBubbleClosed()}
        onPreExitBubbleDismissed={() => this.handlePreExitBubbleDismissed()}
        onQuestionCardbarPulseTrigger={() =>
          this.handleQuestionCardbarPulseTrigger()
        }
        roundStepIndex={this.state.roundStepIndex}
        shouldClosePreExitBubble={this.state.shouldClosePreExitBubble}
        shouldShowPreExitBubble={this.state.shouldShowPreExitBubble}
        stepCard={this.state.stepCard}
        stepConfidenceLevel={this.state.stepConfidenceLevel}
        wasAnswerCardbarClicked={this.state.wasAnswerCardbarClicked}
        wasQuestionCardbarClicked={this.state.wasQuestionCardbarClicked}
      />
    );
  }

//   renderUpgradeDialog() {
//     if (!this.state.showUpgradeDialog) {
//       return;
//     }
// 
//     return (
//       <CramPaywallUpgradeModal
//         classCreator={this.props.classCreator}
//         isHardPaywall={true}
//         isOpen={this.state.showUpgradeDialog}
//         onCloseRequest={this.handleCramUpgradeCloseRequest}
//         onHardPaywallCancellationRequest={this.handleCramUpgradeCancellation}
//         onHardPaywallResolutionRequest={this.handleCramUpgradeResolution}
//         paywall="cram_pricing"
//       />
//     );
//   }

//   renderSetReminder = () => {
//     const info = this.state.reminderDialogInfo;
//     if (!info) {
//       return null;
//     }
// 
//     return (
//       <SetReminderModal
//         email={info.email}
//         hours={info.hours}
//         name={this.state.mixName}
//         onClose={this.onSetStudyReminderClose}
//       />
//     );
//   };

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAnswerCardbarClick(rating, ftsePostCbrBubbleAction) {
    this.setState({
      wasAnswerCardbarClicked: true,
      ftseAnswerCardbarRating: rating,
      ftsePostCbrBubbleAction: ftsePostCbrBubbleAction,
      shouldPulseAnswerCardbar: false,
    });
  }

  handleAnswerCardbarPulseTrigger() {
    this.setState({
      shouldPulseAnswerCardbar: true,
    });
  }

  handleBackToLibraryRequest() {
    if (this.props.isFtse && !this.state.wasPreExitBubbleShown) {
      this.setState({
        shouldShowPreExitBubble: true,
        wasPreExitBubbleShown: true,
      });
    } else {
      this.handleCloseStudyMix();
    }
  }

  handleBannerCloseRequested = (banner) => {
    if (banner.block == 'page') {
      this.setState({
        isBannerOpen: false,
      });
    }
  };

  handleBannerOpenRequested = (banner) => {
    if (banner.block == 'page') {
      this.setState({
        isBannerOpen: true,
      });
    }
  };

  handleCardFlipRequest() {
    const isAnswerShowing = !this.state.isAnswerShowing;

    this.triggerTooltipClose();

    this.setState({
      isAnswerShowing: isAnswerShowing,
    });

    if (this.state.isAtCheckpoint && this.state.checkpointType == 'sidebar') {
      this.closeCheckpoint();
    }
  }

  // Handles events that indicate that a card has been updated by
  // some other process.
  handleCardUpdated = (data) => {
    const ns = {};
    const nc = data.card;

    const sc = this.state.stepCard;
    if (sc && sc.cardId == nc.cardId) {
      // Note: We only support updating the question & answer for now.
      ns.stepCard = { ...sc, question: nc.question, answer: nc.answer };
    }

    const psc = this.state.previousStepCard;
    if (psc && psc.cardId == nc.cardId) {
      // Note: We only support updating the question & answer for now.
      ns.previousStepCard = {
        ...psc,
        question: nc.question,
        answer: nc.answer,
      };
    }

    if (Object.keys(ns).length > 0) {
      this.setState(ns);
    }
  };

  handleCBRCardFailure = (jqXHR) => {
    let msg = 'Unknown Error';
    if (jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.error) {
      msg = jqXHR.responseJSON.error;
    }

    if (msg.indexOf('Study Break') >= 0) {
      this.setState({ cramPaywallMessage: msg });
      Tracker.trackPaywallWarning('cram');
    } else {
      alert('Study Error: ' + msg);
      location.href = '/';
    }
  };

  handleCloseStudyMix() {
    this.setState(
      {
        isClosingStudyMix: true,
      },
      () => {
        location.href = this.state.dashboardPath;
      },
    );
  }

  handleConfidenceRating(cardId, ratingLevel) {
    this.triggerTooltipClose();

    if (this.state.isProcessingRating) {
      return false;
    }

    let sessionRatings = this.state.sessionRatings;
    sessionRatings[cardId] = ratingLevel;
    let cardTimeSpent = new Date() - this.state.questionStartTime;
    let tenMinutes = 600000

    if ( cardTimeSpent > tenMinutes ) { 
      cardTimeSpent = tenMinutes
    }

    this.setState(
      {
        sessionRatings: sessionRatings,
        stepConfidenceLevel: ratingLevel,
        timeStudied: this.state.timeStudied + cardTimeSpent,
      },
      () => {
        this.StudyMix.rateAndAdvance({
          cardId: cardId,
          prevLevel: this.state.roundStepRatings[this.state.roundStepIndex],
          ratedOn: new Date(),
          timeSpent: cardTimeSpent,
          level: ratingLevel,
          done: (data) => {
            if (this.state.roundStepIndex < this.state.roundStepCount - 1) {
              this.setupNextStep(ratingLevel, data);
            } else {
              this.handleEndOfRound(ratingLevel, data);
            }
          },
          fail: (jqXHR) => this.handleCBRCardFailure(jqXHR),
        });
      },
    );
  }

  handleCramUpgradeCancellation = () => {
    this.setState(
      {
        showUpgradeDialog: false,
      },
      () => {
        UiHelper.navigate('/');
      },
    );
  };

  handleCramUpgradeResolution = () => {
    this.setState({
      showUpgradeDialog: false,
    });
  };

  handleDismissSidebarCheckpoint() {
    this.closeCheckpoint();
  }

  handleEndOfRound(lastCardRatingLevel, data) {
    this.setState({
      isAudioMuted: true,
    });

    if (this.state.nextCheckpointType == 'fullScreen') {
      this.finishStudyRound(lastCardRatingLevel, data);
    } else {
      this.setupNextStep(lastCardRatingLevel, data, () => {
        clearTimeout(this.postRoundTimeout);

        this.postRoundTimeout = setTimeout(() => {
          this.finishStudyRound(lastCardRatingLevel, data);
        }, this.SIDEBAR_POST_ROUND_DELAY);
      });
    }

    this.setState({
      lastUclRatedAt: new Date(),
    })    
  }

  handleFlashMessageCloseRequested = (flashMessage) => {
    if (flashMessage && flashMessage.block == 'page') {
      this.setState({
        isFlashMessageOpen: false,
      });
    }
  };

  handleFlashMessageOpenRequested = (flashMessage) => {
    if (flashMessage && flashMessage.block == 'page') {
      this.setState({
        isFlashMessageOpen: true,
      });
    }
  };

  handleNextRoundRequest() {
    this.requestNextStudyRound();

    if (this.state.isAtCheckpoint) {
      this.closeCheckpoint();
    }
  }

  handlePackConfidencesReset = (eventData) => {
    if (eventData.packId == this.state.firstMixPackId) {
      this.triggerToastOpen('Class stats reset', 'success');
      this.setupNewStudyMix();
    }
  }

  handlePreExitBubbleClosed() {
    this.setState({
      shouldClosePreExitBubble: false,
      shouldShowPreExitBubble: false,
    });
  }

  handlePreExitBubbleDismissed() {
    this.setState({
      shouldClosePreExitBubble: true,
    });
  }

  handleQuestionCardbarClick() {
    this.setState({
      wasQuestionCardbarClicked: true,
      shouldPulseQuestionCardbar: false,
    });
  }

  handleQuestionCardbarPulseTrigger() {
    this.setState({
      shouldPulseQuestionCardbar: true,
    });
  }

  // handleResetMixStats() {
  //   this.setupNewStudyMix();
  // }

  handleReminderDone = (data) => {
    this.setState({ reminderDialogInfo: data }, () => {
      Tracker.trackModalPageview('study', 'reminder');
    });
  };

  handleReminderFail = (jqXHR) => {
    let msg = 'Unknow Error';
    if (jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.error) {
      msg = jqXHR.responseJSON.error;
    }

    alert('Study Reminder Error: ' + msg);
  };

  handleSetStudyMixTypeRequest(studyMixType) {
    const newStudyMixType = studyMixType == 'random' ? 'random' : 'progressive';

    StudyHelper.setPackStudyMixType(
      this.state.userId,
      this.state.firstMixPackId,
      newStudyMixType,
    );

    this.setupNewStudyMix(newStudyMixType);
  }

  handleToggleContinuousStudy() {
    const newIsContinuousStudyEnabled = !this.state.isContinuousStudyEnabled;
    const nextCheckpointType = newIsContinuousStudyEnabled
      ? 'sidebar'
      : 'fullScreen';

    this.setState(
      {
        nextCheckpointType: nextCheckpointType,
        isContinuousStudyEnabled: newIsContinuousStudyEnabled,
      },
      () => {
        this.updateContinuousStudyPref(newIsContinuousStudyEnabled);
      },
    );
  }

  handleWindowResize = (e) => {
    this.manageViewport();
  };

  onSetStudyReminder = () => {
    this.setState({ reminderProcessing: true }, () => {
      $.ajax({
        method: 'GET',
        url: '/api/study/reminder',
        dataType: 'json',
      })
        .done(this.handleReminderDone)
        .fail(this.handleReminderFail);
    });
  };

  onSetStudyReminderClose = () => {
    this.setState({ reminderDialogInfo: null, reminderProcessing: false });
  };

  onSkipTheBreak = () => {
    this.setState(
      { showUpgradeDialog: true, upgradePaywall: 'cram_pricing' },
      () => {
        Tracker.trackPaywallWarning('cram_skip');
      },
    );
  };

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

//   triggerBannerOpen = (banner) => {
//     clearTimeout(this.bannerTimeout);
// 
//     this.bannerTimeout = setTimeout(() => {
//       EventManager.emitEvent('banner:open', banner);
//       clearTimeout(this.bannerTimeout);
//     }, this.BANNER_DELAY);
//   };

//   triggerFlashMessageOpen = (flashMessage) => {
//     clearTimeout(this.flashMessageTimeout);
// 
//     this.flashMessageTimeout = setTimeout(() => {
//       EventManager.emitEvent('flash-message:open', flashMessage);
//       clearTimeout(this.flashMessageTimeout);
//     }, this.FLASH_MESSAGE_DELAY);
//   };

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type, duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  }


  /*
  ==================================================
   ADDITIONAL STATE MANAGEMENT
  ==================================================
  */

  setupNewStudyMix(newStudyMixType) {
    const params = new URLSearchParams(window.location.search);
    const classes = params.get('classes');
    const mixType = newStudyMixType || params.get('type');

    this.StudyMix.create({
      classes: classes,
      mixType: mixType,
      done: (data) => {
        GaHelper.fireGaEvent('study_mix', mixType);
        this.setupStudyMixMetadata(data);
        this.startStudyMix();
      },
      fail: this.handleCBRCardFailure,
    });
  }

  setupStudyMixMetadata(studyMixData) {
    // this routine assumes a mix is comprised of a single Pack. This will need to be altered when we implement playlist mixes

    this.StudyMix.getMixMetadata((metadata) => {
      const currentUser = {
        experiments: this.props.userExperiments,
        features: this.props.userFeatures,
        flags: this.props.userFlags,
        userId: metadata.userId,
      };

      this.setState(
        {
          currentUser: currentUser,
          dashboardPath: metadata.dashboardPath || '/',
          firstMixPackId: metadata.firstMixPackId,
          timeStudied: metadata.timeStudied,
          totalConfidence: metadata.totalConfidence,
          isMixRestricted: metadata.isMixRestricted || false,
          isUserMixAdmin: metadata.isUserMixAdmin || false,
          isUserPro: metadata.isUserPro || false,
          lastUclRatedAt: metadata.lastUclRatedAt,
          libraryPath: metadata.libraryPath || '/',
          mixCardCount: metadata.mixCardCount,
          mixDeckCount: metadata.mixDeckCount,
          mixDecks: metadata.mixDecks,
          mixDescription: metadata.mixDescription || null,
          mixIconUrl: metadata.mixIconUrl || null,
          mixFullname: metadata.mixFullname || null,
          mixName: this.props.mixName || metadata.mixName || 'Study Mix',
          mixPackCount: metadata.mixPackCount,
          mixType: studyMixData.mixType || 'progressive',
          userId: metadata.userId,
        },
        () => {
          BSC.userId = metadata.userId;
          this.setupLocalUserStudyPrefs();
        },
      );
    });
  }

  setupLocalUserStudyPrefs() {
    const isContinuousStudyEnabled = this.getContinuousStudyEnabled() || false;
    const checkpointType = isContinuousStudyEnabled ? 'sidebar' : 'fullScreen';

    this.setState({
      checkpointType: checkpointType,
      isContinuousStudyEnabled: isContinuousStudyEnabled,
      nextCheckpointType: checkpointType,
    });
  }

  startStudyMix() {
    this.StudyMix.start({
      done: (data) => {
        this.setState({
          mixRoundCount: 0,
          shouldShowStudyRoundsIntro: true,
        });
        this.startNewStudyRound(data.card);
      },
      fail: (jqXHR) => this.handleCBRCardFailure(jqXHR),
    });
  }

  startNewStudyRound(firstStepCard) {
    // calculate card-level state data for first card
    const firstStepData = this.StudyMix.getStepData(firstStepCard.cardID);
    const firstStepConfidenceLevel = firstStepData.card.level;
    const firstStepDeckCardCount = firstStepData.deck.cardIDs.length;
    const firstStepDeckName = firstStepData.deck.name;
    const firstStepDeckCardIndex = firstStepCard.number;

    // calculate round-level state data
    const now = Date.now();
    let roundStepRatings = [];
    let preRoundStepRatings = [];
    roundStepRatings[0] = firstStepConfidenceLevel;
    preRoundStepRatings[0] = firstStepConfidenceLevel;

    // calculate mix-level state data
    const mixMastery = this.StudyMix.getMastery();
    const mixRatingLevelCounts = this.StudyMix.getRatingLevelCounts();
    const mixRoundCount = this.state.mixRoundCount + 1;

    // update state data to display first round step and card
    this.setState({
      isAnswerShowing: false,
      isAtCheckpoint: false,
      isAudioMuted: false,
      mixMastery: mixMastery,
      mixRatingLevelCounts: mixRatingLevelCounts,
      mixRoundCount: mixRoundCount,
      preRoundStepRatings: preRoundStepRatings,
      roundStartTime: Date.now(),
      roundStepCount: this.STEPS_PER_ROUND,
      roundStepIndex: 0,
      roundStepRatings: roundStepRatings,
      stepCard: firstStepCard,
      stepConfidenceLevel: firstStepConfidenceLevel,
      stepData: firstStepData,
      stepDeckCardCount: firstStepDeckCardCount,
      stepDeckCardIndex: firstStepDeckCardIndex,
      stepDeckName: firstStepDeckName,
    });
  }

  setupNextStep(lastCardRatingLevel, data, callback) {
    const nextCard = data.card;

    // calculate card-level state data for next card
    const nextStepData = this.StudyMix.getStepData(nextCard.cardID);
    const nextStepConfidenceLevel = nextStepData.card.level;
    const nextStepDeckCardCount = nextStepData.deck.cardIDs.length;
    const nextStepDeckName = nextStepData.deck.name;
    const nextStepDeckCardIndex = nextCard.number;
    if ( this.state.roundStepIndex == 0 ) {
      this.setState({firstAnswerMarkedAt: new Date()})
    }

    // calculate round-level state data
    const now = Date.now();
    const roundElapsedTime = now - this.state.roundStartTime;

    const currentRoundStepIndex = this.state.roundStepIndex;
    const nextStepIndex = currentRoundStepIndex + 1;
    let roundStepRatings = this.state.roundStepRatings;
    let preRoundStepRatings = this.state.preRoundStepRatings;
    roundStepRatings[currentRoundStepIndex] = lastCardRatingLevel;
    roundStepRatings[nextStepIndex] = nextStepData.card.level;
    preRoundStepRatings[nextStepIndex] = nextStepData.card.level;

    // calculate mix-level state data
    const mixMastery = this.StudyMix.getMastery();
    const mixRatingLevelCounts = this.StudyMix.getRatingLevelCounts();  
    let confidenceGained = 0;
    for (let i = 0; i < roundStepRatings.length; i++) {
      confidenceGained =
        confidenceGained + (roundStepRatings[i] - preRoundStepRatings[i]);
    }

    // update state data to display next round step and card
    this.setState(
      {
        classCreator: data.classCreator,
        isAnswerShowing: false,
        mixMastery: mixMastery,
        mixRatingLevelCounts: mixRatingLevelCounts,
        preRoundStepRatings: preRoundStepRatings,
        questionStartTime: now,
        roundConfidenceGained: confidenceGained,
        roundElapsedTime: roundElapsedTime,
        roundStepIndex: nextStepIndex,
        roundStepRatings: roundStepRatings,
        stepCard: nextCard,
        stepConfidenceLevel: nextStepConfidenceLevel,
        stepData: nextStepData,
        stepDeckCardCount: nextStepDeckCardCount,
        stepDeckCardIndex: nextStepDeckCardIndex,
        stepDeckName: nextStepDeckName,
      },
      () => {
        if (
          this.state.isAtCheckpoint &&
          this.state.checkpointType == 'sidebar'
        ) {
          this.closeCheckpoint();
        }

        if (callback) {
          callback();
        }
      },
    );
  }

  finishStudyRound = (lastCardRatingLevel, data) => {
    const nextRoundFirstStepCard = data.card;
    const cramPaywallMessage = data.cramPaywallMessage;

    // calculate round, session, and mix level state data
    let { roundElapsedTime, roundStepRatings } =
      this.calculateRoundStats(lastCardRatingLevel);

    let {
      currentConfidencePoints,
      maxPoints,
      sessionElapsedTime,
      sessionMastery,
      sessionRatingLevelCounts,
    } = this.calculateSessionStats(roundElapsedTime);

    let {
      mixCardsStudied,
      mixMastery,
      mixRatingLevelCounts,
      mixTimeToComplete,
    } = this.calculateMixStats(maxPoints, currentConfidencePoints, sessionElapsedTime);

    // update state data for display of next round, step, and card
    this.setState({
        classCreator: data.classCreator,
        roundConfidenceGained: this.state.roundConfidenceGained,
        roundElapsedTime: roundElapsedTime,
        roundStepIndex: this.state.roundStepIndex + 1,
        roundStepRatings: roundStepRatings,
        mixCardsStudied: mixCardsStudied,
        mixMastery: mixMastery,
        mixRatingLevelCounts: mixRatingLevelCounts,
        mixTimeToComplete: mixTimeToComplete,
        nextRoundFirstStepCard: nextRoundFirstStepCard,
        sessionElapsedTime: sessionElapsedTime,
        sessionMastery: sessionMastery,
        sessionRatingLevelCounts: sessionRatingLevelCounts,
        totalConfidence: currentConfidencePoints
      }, () => {
        this.openCheckpoint();

        if (cramPaywallMessage) {
          this.setState({ cramPaywallMessage: cramPaywallMessage });
          Tracker.trackPaywallWarning('cram');
        } else {
          if (this.props.isFtse && !this.ftseCheckpointShown) {
            Tracker.trackStudyProgress('ftse_checkpoint');
            this.ftseCheckpointShown = true;
          } else {
            Tracker.trackStudyProgress('round_checkpoint');
          }
        }
      },
    );
  };

  calculateRoundStats(lastCardRatingLevel) {
    const now = Date.now();
    const roundElapsedTime = now - this.state.roundStartTime;
    let roundStepRatings = this.state.roundStepRatings;

    const currentRoundStepIndex = this.state.roundStepIndex;
    
    let preRoundStepRatings = this.state.preRoundStepRatings;
    roundStepRatings[currentRoundStepIndex] = lastCardRatingLevel;

    let roundConfidenceGained = 0;
    for (let i = 0; i < roundStepRatings.length; i++) {
      roundConfidenceGained =
        roundConfidenceGained + (roundStepRatings[i] - preRoundStepRatings[i]);
    }

    return { roundElapsedTime, roundStepRatings };
  }

  calculateSessionStats(roundElapsedTime) {
    const sessionRatings = this.state.sessionRatings;

    const sessionRatingValues = Object.keys(sessionRatings).map(
      (key) => sessionRatings[key],
    );
    let ratingLevelCounts = sessionRatingValues.reduce((acc, rating) => {
      acc[rating] = acc[rating] ? acc[rating] + 1 : 1;
      return acc;
    }, {});

    let orderedCounts = {};

    for (let i = 0; i <= 5; i++) {
      orderedCounts[i] = ratingLevelCounts[i] || 0;
    }

    const mixRatingLevelCounts = this.StudyMix.getRatingLevelCounts();
    let currentConfidencePoints = 0;
    for (let i = 1; i <= 5; i++) {
      currentConfidencePoints =
        currentConfidencePoints + mixRatingLevelCounts[i] * i;
    }
    const maxPoints                = this.state.mixCardCount * 5;
    const sessionElapsedTime       = this.state.sessionElapsedTime + roundElapsedTime;
    const sessionMastery           = currentConfidencePoints / maxPoints;
    const sessionRatingLevelCounts = Object.keys(orderedCounts).map(
      (key) => orderedCounts[key],
    );

    return {
      maxPoints,
      currentConfidencePoints,
      sessionElapsedTime,
      sessionMastery,
      sessionRatingLevelCounts,
    };
  }

  calculateMixStats(maxPoints, currentConfidencePoints, sessionElapsedTime) {
    const mixCardsStudied = this.StudyMix.getTotalCardsStudied();
    const mixMastery = this.StudyMix.getMastery();
    const mixRatingLevelCounts = this.StudyMix.getRatingLevelCounts();
    let mixTimeToComplete;

    if (mixCardsStudied > 0) {

      const pointsRemaining = maxPoints - currentConfidencePoints;

      // Calculate Velocity ( points/s )
      const current_conf = this.state.totalConfidence + this.state.roundConfidenceGained;
      const studyRate    = current_conf / this.state.timeStudied;

      mixTimeToComplete = pointsRemaining / studyRate;

    } else {
      mixTimeToComplete = -1;
    }

    return {
      mixCardsStudied,
      mixMastery,
      mixRatingLevelCounts,
      mixTimeToComplete,
    };
  }

  openCheckpoint() {
    this.setState({
      checkpointType: this.state.nextCheckpointType || 'fullScreen',
      isAtCheckpoint: true,
      isAudioMuted: true,
      roundEndConfidenceGained: this.state.roundConfidenceGained,
      shouldCloseCheckpoint: false,
      shouldShowRoundEndConfidence: true,
    }, () => {
      this.performOpenCheckpointAnimations();
    });
  }

  closeCheckpoint() {
    this.performCloseCheckpointAnimations();
    // after Checkpoint closes, this.finishCloseCheckpoint will be invoked in a callback as defined as a prop to the Checkpoint component
  }

  finishCloseCheckpoint() {
    this.setState({
      isAtCheckpoint: false,
      isAudioMuted: false,
      firstAnswerMarkedAt: null,
      questionStartTime: new Date(),
      roundConfidenceGained: 0,
      roundElapsedTime: 0,
      roundStartTime: Date.now(),
      roundStepIndex: 0,
    });
  }

  requestNextStudyRound() {
    this.startNewStudyRound(this.state.nextRoundFirstStepCard);      
  }

  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  performOpenCheckpointAnimations() {
    clearTimeout(this.preCheckpointAnimationsTimeout);

    this.preCheckpointAnimationsTimeout = setTimeout(() => {
      this.setState(
        {
          shouldAnimateCheckpointMessaging: true,
        },
        () => {
          this.compressRoundEndConfidence();
        },
      );
    }, this.PRE_CHECKPOINT_ANIMATION_DELAY);
  }

  compressRoundEndConfidence() {
    clearTimeout(this.preCompressRoundEndConfidenceTimeout);

    this.preCompressRoundEndConfidenceTimeout = setTimeout(() => {
      this.setState(
        {
          shouldCompressRoundEndConfidence: true,
        },
        () => {
          this.resetMeters();
        },
      );
    }, this.PRE_COMPRESS_ROUND_END_CONFIDENCE_DELAY);
  }

  resetMeters() {
    clearTimeout(this.preResetMetersTimeout);

    this.preResetMetersTimeout = setTimeout(() => {
      // clear confidenceMeter
      this.setState({
        roundConfidenceGained: 0,
        shouldDimRoundEndConfidence: true,
      });

      // clear progressMeter
      clearInterval(this.resetStepRatingsInterval);
      let resettingStepRatings = this.state.roundStepRatings;

      this.resetStepRatingsInterval = setInterval(() => {
        resettingStepRatings.pop();

        this.setState({
          roundStepRatings: resettingStepRatings,
        });

        if (resettingStepRatings.length <= 0) {
          clearInterval(this.resetStepRatingsInterval);
          if (this.props.isFtse) {
            this.pulseBackToDashboardButton();
          } else {
            this.pulseNextRoundButton();
          }
        }
      }, this.STEP_RESET_INTERVAL);
    }, this.PRE_RESET_METERS_DELAY);
  }

  performCloseCheckpointAnimations() {
    this.setState({
      shouldAnimateCheckpointMessaging: false,
      shouldCloseCheckpoint: true,
      shouldCompressRoundEndConfidence: false,
      shouldDimRoundEndConfidence: false,
      shouldPulseNextRoundButton: false,
      shouldShowRoundEndConfidence: false,
    });
  }

  pulseBackToDashboardButton() {
    clearTimeout(this.prePulseNextRoundButtonTimeout);

    this.prePulseNextRoundButtonTimeout = setTimeout(() => {
      this.setState({
        shouldPulseBackToDashboardButton: true,
      });
    }, this.PRE_PULSE_NEXT_ROUND_BUTTON_DELAY);
  }

  pulseNextRoundButton() {
    clearTimeout(this.prePulseNextRoundButtonTimeout);

    this.prePulseNextRoundButtonTimeout = setTimeout(() => {
      this.setState({
        shouldPulseNextRoundButton: true,
      });
    }, this.PRE_PULSE_NEXT_ROUND_BUTTON_DELAY);
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.bannerTimeout);
    clearTimeout(this.flashMessageTimeout);
    clearTimeout(this.postRoundTimeout);
    clearTimeout(this.preCheckpointAnimationsTimeout);
    clearTimeout(this.preResetMetersTimeout);
    clearTimeout(this.preCompressRoundEndConfidenceTimeout);
    clearTimeout(this.prePulseNextRoundButtonTimeout);
    clearInterval(this.resetStepRatingsInterval);
  }

//   displayIncomingBanners = () => {
//     const incomingBanner = this.props.banner;
// 
//     if (incomingBanner && incomingBanner.html) {
//       this.triggerBannerOpen(incomingBanner);
//     }
//   };
// 
//   displayIncomingFlashMessages = () => {
//     const incomingFlashMessage = this.props.flashMessage;
// 
//     if (incomingFlashMessage && incomingFlashMessage.html) {
//       this.triggerFlashMessageOpen(incomingFlashMessage);
//     }
//   };

  getContinuousStudyEnabled() {
    const isContinuousStudyAllowed =
      !this.state.isMixRestricted || this.state.isUserPro;
    return this.getContinuousStudyPref() && isContinuousStudyAllowed;
  }

  getContinuousStudyPref() {
    return userLocalStore.getUserLocalPref(
      this.state.userId,
      'continuousStudyPref',
    );
  }

  manageViewport() {
    UiHelper.adjustViewportHeight();

    this.setState({
      isMobileViewportSize: UiHelper.detectIfMobileSize(),
    });
  }

  startWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.addEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  stopWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.removeEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  updateContinuousStudyPref(pref) {
    userLocalStore.updateUserLocalPrefs(
      this.state.userId,
      'continuousStudyPref',
      pref,
    );
  }
}

export default StudyPage;