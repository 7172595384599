
const ZEROED_STATS =  {
  cardCount: 0,
  deckCount: 0,
  estTimeLeft: null,
  levelCounts: [0, 0, 0, 0, 0, 0],
  mastery: 0,
  percentages: [0, 0, 0, 0, 0, 0],
  seenCount: 0,
};

const packStatTransform = {

  /*
  ==================================================
   LOCAL CRUD METHODS
  ==================================================
  */

  show(currentPack) {    
    const deckIds = currentPack.localDeckTransforms?.selections?.ids;
    const studyRate = currentPack.stats.studyRate;

    if (currentPack.decks?.length < 1) {
      return ZEROED_STATS;
    }

    return this.transformStats(currentPack, deckIds, studyRate); 
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  transformStats(pack, currentDeckIds, studyRate) {
    let packCardCount = 0;
    let packSeenCount = 0;
    let packConfidencePoints = 0;
    let packLevelCounts = [0, 0, 0, 0, 0, 0];

    currentDeckIds.forEach((deckId, index) => {
      let deck = this.getDeck(pack, deckId);

      if (!(deck && deck.stats)) {
        return;
      }

      if (deck.flags?.isLocked) {
        return;
      }

      const deckStats = deck.stats;

      packCardCount = packCardCount + deckStats.cardCount;
      packSeenCount = packSeenCount + deckStats.seenCount;

      packLevelCounts.forEach((count, index) => {
        packConfidencePoints = packConfidencePoints + deckStats.levelCounts[index] * index;
        packLevelCounts[index] = packLevelCounts[index] + deckStats.levelCounts[index];
      });
    });

    const mastery = (packCardCount > 0) ? packConfidencePoints / (packCardCount * 5) : 0;
    const maxPoints = packCardCount * 5;
    const pointsRemaining = maxPoints - packConfidencePoints;
    const estTimeLeft = (studyRate > 0) ? pointsRemaining / studyRate : null;

    const percentages = packLevelCounts.map((count, index) => {
      return (packCardCount > 0) ? packLevelCounts[index] / packCardCount : 0;
    });

    const transformedStats = {
      cardCount: packCardCount,
      deckCount: currentDeckIds.length,
      estTimeLeft: estTimeLeft,
      levelCounts: packLevelCounts,
      mastery: mastery,
      percentages: percentages,
      seenCount: packSeenCount,
    };

    return transformedStats;
  },

  getDeck(pack, deckId) {
    const deckIndex = pack.deckIds.indexOf(deckId);
    return (deckIndex != -1 && pack.decks) ? pack.decks[deckIndex] || null : null;
  },
};

export default packStatTransform;
