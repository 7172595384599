
import PackSuggestionsModal         from '_views/modals/PackSuggestionsModal';
import EventManager                 from '@brainscape/event-manager';
import PropTypes                    from 'prop-types';
import React                        from 'react';

import {
  apiError,
  apiGet,
}                                   from '_core/Api1';

class PackSuggestionsModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener(
      'pack-suggestions-modal:open', 
      (data) => {
        this.setState({
          viewProps: data
        }, () => {
          this.handleOpenRequest();
        });
      },
    );

    this.events.addListener(
      'pack-suggestions-modal:close',
      () => {
        this.handleCloseRequest();
      }
    );
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    return (
      <PackSuggestionsModal
        addClasses={viewProps.addClasses}
        isOpen={this.state.isModalOpen}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        suggestedPacks={this.state.suggestedPacks}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes overlay closing animation. Overlay is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after overlay close animation is complete. Removes overlay from DOM
  handleClosed = () => {
    this.setState({
      viewProps: null
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onClosed) {
        this.state.viewProps.onClosed();
      }
    });
  }

  handleOpenRequest = () => {
    apiGet('suggested-packs').then((data) => {
      this.setState({
        isModalOpen: true,
        suggestedPacks: data
      });
    }).catch(apiError);
  }
}

export default PackSuggestionsModalController;
