
import LoadingOverlay from '_views/shared/LoadingOverlay';
import EventManager   from '@brainscape/event-manager';
import React          from 'react';

class PageLoadingOverlayController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {viewProps: null};
    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this.events.addListeners([
      ['page-loading-overlay:open',  this.handleOpen],
      ['page-loading-overlay:close', this.handleClose],
    ]);
  };

  componentWillUnmount = () => this.events.disable();


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { return null; }

    return (
      <LoadingOverlay
        addClasses='full-page'
        isOpen={true}
        message={viewProps.message}
        shouldTransitionIn={true}
        shouldTransitionOut={true}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClose = () => {
    const cb = (this.state.viewProps || {}).onClosed;
    this.setState({viewProps: null}, cb);
  };

  handleOpen = (data) => {
    this.setState({viewProps: data || {}});
  };
}

export default PageLoadingOverlayController;
