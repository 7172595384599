
// substrate and utils
import EventManager                     from '@brainscape/event-manager';
import PropTypes                        from 'prop-types';
import React                            from 'react';
import StringHelper                     from '_utils/StringHelper';
import Tracker                          from '_utils/Tracker';

// views
import FtseMessaging                       from '_views/new-study/desktop/FtseMessaging';

const BUBBLE_EXPANSION_DELAY = 1000;


const PT = {
  currentCardFace:            PropTypes.string,
  currentCard:                PropTypes.object,
  currentRoundStats:          PropTypes.object,
  isFtse:                     PropTypes.bool,
};


class FtseController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasReached: {
        firstStart: false,
        firstQuestion: false,
        firstAnswer: false,
        postFirstRating: false,
        firstRoundMidpoint: false,
        earlyExit: false,
      },
      hasSeenMessage: {
        firstStart: false,
        firstQuestion: false,
        firstAnswer: false,
        postFirstRating: false,
        firstRoundMidpoint: false,
        earlyExit: false,
      },
    };

    this.events = new EventManager();
    this.pulseConfidenceButtonsTimeout = null;
    this.pulseRevealAnswerButtonTimeout = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
     this.clearTimeoutsAndIntervals();
    this.subscribeToEvents();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFtse && !this.props.isFtse) {
      this.unsubscribeToEvents();
      this.clearTimeoutsAndIntervals();
    }
  }

  componentWillUnmount() {
    this.unsubscribeToEvents();
     this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    if (!this.props.isFtse) {
      return false;
    }

     this.events.addListener('ftse:action-performed',                     this.handleFtseActionPerformed);
     this.events.addListener('ftse:dismiss-requested',                    this.handleFtseDismissRequested);
     this.events.addListener('ftse:message-closed',                       this.handleFtseMessageClosed);
     this.events.addListener('ftse:message-seen',                         this.handleFtseMessageSeen);
     this.events.addListener('study-session:new-session-started',         this.handleStudySessionNewSessionStarted);
     this.events.addListener('study-session:card-level-updated',          this.handleStudySessionCardLevelUpdated);
     this.events.addListener('study-session:current-ids-updated',         this.handleStudySessionCurrentIdsUpdated);
     this.events.addListener('study-session:close-requested',             this.handleCloseStudySessionRequested);
     this.events.addListener('smart-card:reveal-card-face-requested',     this.handleSmartCardRevealCardFaceRequested);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    // if (this.state.isMobileViewportSize) {
    //   return this.renderMobileScreen();
    // }

    return this.renderDesktopFtseMessaging();
  }

  renderDesktopFtseMessaging() {
    if (!this.props.isFtse) {
      return null;
    }

    return (
      <FtseMessaging
        isFtse={this.props.isFtse}
      />
    );
  }

  renderMobileScreen() {
    return null;
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCloseStudySessionRequested = (eventData) => {
    if (this.isEarlyExit(eventData)) {
      this.handleFtseUserHasReached('earlyExit', this.triggerOpenEarlyExitMessaging);
      return true;
    }

    this.handleFtseDismissRequested();
  }

  handleFtseDismissRequested = (eventData) => {
    this.unsubscribeToEvents();
    this.triggerCloseMessages();
    this.triggerFtseEndRequest();
  }

  handleFtseUserHasReached = (messageKey, triggerMessageAction) => {
    const newState = {...this.state};
    newState.hasReached[messageKey] = true;

    this.setState(newState, () => {
      triggerMessageAction();
    });
  }

  handleFtseMessageClosed = (eventData) => {
    const newState = {...this.state};
    const messageTag = eventData.messageTag;
    const messageKey = StringHelper.toCamelCase(messageTag);

    if (!messageKey) return false;

    newState.hasSeenMessage[messageKey] = true;

    this.setState(newState, () => {
      if (messageTag == 'first-start') {
        this.handleFtseUserHasReached('firstQuestion', this.triggerOpenFirstQuestionMessaging);
      }
    });
  }

  handleFtseMessageSeen = (eventData) => {
    const newState = {...this.state};
    const messageTag = eventData.messageTag;
    const messageKey = StringHelper.toCamelCase(messageTag);

    if (!messageKey) return false;

    newState.hasSeenMessage[messageKey] = true;

    this.setState(newState, () => {
      const messageId = StringHelper.toSnakeCase(messageKey);
      this.trackFtseEvent(`${messageId}_message_seen`);
    });
  }

  handleFtseActionPerformed = (eventData) => {
    const actionId = StringHelper.toSnakeCase(eventData.actionTag);
    this.trackFtseEvent(actionId);
  }

  handleSmartCardRevealCardFaceRequested = (eventData) => {
    this.triggerCloseMessages();

    if (this.isFirstAnswer(eventData)) {
      this.handleFtseActionPerformed({actionTag: 'first_reveal'});
      this.handleFtseUserHasReached('firstAnswer', this.triggerOpenFirstAnswerMessaging);
    }
  }

  handleStudySessionCardLevelUpdated = (eventData) => {
    this.triggerCloseMessages();

    if (this.isPostFirstRating(eventData)) {
      this.handleFtseActionPerformed({actionTag: 'first_rating'});
      this.handleFtseUserHasReached('postFirstRating', () => this.triggerOpenPostFirstRatingMessaging(eventData));
    }
  }

  handleStudySessionCurrentIdsUpdated = (eventData) => {
    if (this.isFirstStart(eventData)) {
      return false; // don't do anything on this event because we are presenting the firstStart message
    }

    if (this.isFirstQuestion(eventData)) {
      this.handleFtseUserHasReached('firstQuestion', this.triggerOpenFirstQuestionMessaging);
      return true;
    }

    if (this.isPostFirstRating(eventData)) {
      return false; // don't do anything on this event because we are presenting the postFirstRating message
    }
    
    if (this.isFirstRepeatCard(eventData) && this.isFirstRoundMidpoint(eventData)) {
      this.handleFtseUserHasReached('firstRepeatCardAndRoundMidpoint', () => this.triggerFirstRepeatCardAndRoundMidpointMessaging(eventData));
      return true;
    }
    
    if (this.isFirstRepeatCard(eventData)) {
      this.handleFtseActionPerformed({actionTag: 'first_repeat'});
      this.handleFtseUserHasReached('firstRepeatCard', () => this.triggerOpenFirstRepeatCardMessaging(eventData));
      return true;
    }

    if (this.isFirstRoundMidpoint(eventData)) {
      this.handleFtseUserHasReached('firstRoundMidpoint', this.triggerOpenFirstRoundMidpointMessaging);
      return true;
    }
  }

  handleStudySessionNewSessionStarted = (eventData) => {
    if (this.isFirstStart(eventData)) {
      this.handleFtseActionPerformed({actionTag: 'intro'});
      this.handleFtseUserHasReached('firstStart', this.triggerOpenFirstStartMessaging);
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCloseMessages = () => {
    EventManager.emitEvent(`ftse:close-messages`, {});
  }

  triggerFtseEndRequest = () => {
    EventManager.emitEvent(`ftse:end-requested`, {});
  }

  triggerOpenEarlyExitMessaging = () => {
    EventManager.emitEvent(`ftse:open-message`, {
      messageTag: 'early-exit',
    });
  }

  triggerOpenFirstAnswerMessaging = () => {
    EventManager.emitEvent(`ftse:open-message`, {
      messageTag: 'first-answer',
    });

    this.pulseConfidenceButtonsTimeout = setTimeout(() => {
      this.triggerPulseConfidenceButtons();
    }, BUBBLE_EXPANSION_DELAY);
  }

  triggerOpenFirstQuestionMessaging = () => {
    EventManager.emitEvent(`ftse:open-message`, {
      messageTag: 'first-question',
    });

    this.pulseRevealAnswerButtonTimeout = setTimeout(() => {
      this.triggerPulseRevealAnswerButton();
    }, BUBBLE_EXPANSION_DELAY);
  }

  triggerOpenFirstRepeatCardAndRoundMidpointMessaging = (eventData) => {
    EventManager.emitEvent(`ftse:open-message`, {
      messageTag: 'first-repeat-card-and-round-midpoint',
      level: eventData.level,
    });
  }

  triggerOpenFirstRepeatCardMessaging = (eventData) => {
    EventManager.emitEvent(`ftse:open-message`, {
      messageTag: 'first-repeat-card',
      level: eventData.level,
    });
  }

  triggerOpenFirstRoundMidpointMessaging = () => {
    EventManager.emitEvent(`ftse:open-message`, {
      messageTag: 'first-round-midpoint',
    });
  }

  triggerOpenFirstStartMessaging = () => {
    EventManager.emitEvent(`ftse:open-message`, {
      messageTag: 'first-start',
    });
  }

  triggerOpenPostFirstRatingMessaging = (eventData) => {
    EventManager.emitEvent(`ftse:open-message`, {
      messageTag: 'post-first-rating',
      level: eventData.level,
    });
  }

  triggerPulseConfidenceButtons = () => {
    EventManager.emitEvent(`ftse:pulse-confidence-buttons`, {});
  }

  triggerPulseRevealAnswerButton = () => {
    EventManager.emitEvent(`ftse:pulse-reveal-answer-button`, {});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.pulseConfidenceButtonsTimeout);
    clearTimeout(this.pulseRevealAnswerButtonTimeout);
  }

  isEarlyExit = (eventData) => {
    if (this.state.hasSeenMessage.earlyExit) return false;
    const {roundLength, cardNumber} = this.props.currentRoundStats;
    
    return (cardNumber < roundLength)
  }

  isFirstAnswer = (eventData) => {
    if (this.state.hasSeenMessage.firstAnswer) return false;

    const cardNumber = this.props.currentRoundStats?.cardNumber;
    const face = eventData.face;

    return (cardNumber == 1 && face == 'answer')
  }

  isFirstQuestion = (eventData) => {
    if (this.state.hasSeenMessage.firstQuestion) return false;

    const cardNumber = this.props.currentRoundStats?.cardNumber;
    const face = eventData.face;

    return (cardNumber == 1 && face == 'question')
  }

  isFirstRepeatCard = (eventData) => {
    if (this.state.hasSeenMessage.firstRepeatCard) return false;

    const cardRatings = this.props.currentRoundStats?.cardRatings || [];
    const newCardId = eventData.cardId;
    const isRepeat = false;

    const repeatedCardRating = cardRatings.find(cardRating => cardRating?.cardId == newCardId);

    return !!repeatedCardRating;
  }

  isFirstRoundMidpoint = (eventData) => {
    if (this.state.hasSeenMessage.firstRoundMidpoint) return false;

    const {roundLength, cardNumber} = this.props.currentRoundStats;
    
    return (cardNumber >= roundLength / 2)
  }

  isFirstStart = (eventData) => {
    return !this.state.hasSeenMessage.firstStart;
  }

  isPostFirstRating = (eventData) => {
    if (this.state.hasSeenMessage.postFirstRating) return false;

    const cardNumber = this.props.currentRoundStats?.cardNumber;

    return (cardNumber == 1)
  }

  trackFtseEvent = (eventMessageId) => {
    Tracker.trackStudyProgress(`ftse_${eventMessageId}`);
  }
}

FtseController.propTypes = PT;

export default FtseController;
