
import DynamicTooltipIcon              from '_views/shared/DynamicTooltipIcon';

import React                     from 'react';

import {toClassStr} from '_utils/UiHelper';

class PermissionsTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        isPackPrivate,
        label,
        place,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const position = this.props.tooltipPosition || "bottom";
    const label = this.props.label || "permissions-tooltip";
    const iconType = this.props.iconType || "help"; 
    const classes = toClassStr(['permissions-tooltip-wrapper', this.props.addClasses]);

    return (
      <DynamicTooltipIcon 
        addClasses="permissions-tooltip-button"
        delay={500}
        hasDismissButton={true}
        heading="User permissions"
        body={this.renderUserPermissions()}
        iconType={iconType}
        position={position}
        isMobileViewportSize={this.props.isMobileViewportSize}
      />
    );
  }

  renderUserPermissions() {
    if (this.props.isPackPrivate) {
      return (
        <>
          <div className="body-sub-heading">Private Class (<em>Private Classes can only be accessed by Pro Users</em>)</div>
          <p className='body-text'><strong>Preview or Full Study</strong>: Pro Users have the ability to study all Decks in a Class. Assigning Preview rights will not limit the number of Decks a Pro User can study.</p>
          <p className='body-text'><strong>Edit</strong>: Pro User can study all Decks and can add and delete Decks and Cards.</p>
          <p className='body-text'><strong>Admin</strong>: Pro User has full rights to this Class (including all Read, Write, Share, and Delete rights).</p>
        </>
      );
    }

    return (
      <>
          <p className='body-text'><strong>Preview</strong>: User can study a limited number of Decks in this Class (If Pro they can access all Decks).</p>
          <p className='body-text'><strong>Full Study</strong>: User can study all Decks in this Class (Whether they are Pro or not).</p>
          <p className='body-text'><strong>Edit</strong>: User can study all Decks and can add and delete Decks and Cards.</p>
          <p className='body-text'><strong>Admin</strong>: User has full rights to this Class (including all Read, Write, Share, and Delete rights).</p>
      </>
    );
  }
}

export default PermissionsTooltip;
