
import 'url-search-params-polyfill'; // polyfill to support IE and Edge

const UrlHelper = {

  appendQueryParam(baseUrl, key, value) {
    let url = baseUrl + (baseUrl.split('?')[1] ? '&':'?') + key + '=' + value;
    return url;
  },

  appendQueryParams(baseUrl, params) {
    let url = baseUrl;
    let queryString = '';

    if (!params) {
      return url;
    }

    Object.keys(params).forEach((key, index) => {
      let delim = (index == 0) ? '?' : '&';
      queryString += delim + key + '=' + params[key];
    });

    return url + queryString;
  },

  getLocationParts(locationObj) {
    return {
      hash: locationObj.hash,
      host: locationObj.host,
      hostname: locationObj.hostname,
      href: locationObj.href,
      origin: locationObj.origin,
      pathname: locationObj.pathname,
      port: locationObj.port,
      protocol: locationObj.protocol,
      search: locationObj.search,
    };
  },

  getHostName(urlStr) {
    return urlStr.split('/')[2];
  },

  getLocationPartsFromString(str) {
    var a = document.createElement('a');
    a.href = str;

    return this.getLocationParts(a);
  },

  //
  // Get value of query parameter "key" from a url string
  //
  getQueryParam(urlStr, key) {
    const locationParts = this.getLocationPartsFromString(urlStr);
    const params = new URLSearchParams(locationParts.search);

    return params.get(key);
  },

  isValidUrl(url) {
    var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return pattern.test(url);
  },

  parseYouTubeUrl(videoUrl) {
    // check if valid url at all
    if (!this.isValidUrl(videoUrl)) {
      return {isValidUrl: false};
    }

    const locationParts = this.getLocationPartsFromString(videoUrl);
    const hostname = locationParts.hostname;

    // check if youtube url
    if (hostname.indexOf('youtube.com') == -1 && hostname.indexOf('youtu.be') == -1) {
      return {isValidUrl: false};
    }

    // check if given a playlist and construct an embeddable url
    const playlistIndex = videoUrl.indexOf('/playlist?');
    if (playlistIndex != -1) {
      const playlistId = this.getQueryParam(videoUrl, 'list');

      if (playlistId) {
        const embedUrl = 'https://www.youtube.com/embed/videoseries?list=' + playlistId;

        return {
          isValidUrl: true,
          url: embedUrl,
          type: 'embed',
        }
      }
    }

    // check if given an embedded playlist url and normalize to a standard hostname
    const videoSeriesIndex = videoUrl.indexOf('/embed/videoseries?');
    if (videoSeriesIndex != -1) {
      const playlistId = this.getQueryParam(videoUrl, 'list');

      if (playlistId) {
        const embedUrl = 'https://www.youtube.com/embed/videoseries?list=' + playlistId;

        return {
          isValidUrl: true,
          url: embedUrl,
          type: 'embed',
        }
      }
    }

    // check if given an embedded video url and normalize to a standard hostname
    const embedIndex = videoUrl.indexOf('/embed/');
    if (embedIndex != -1) {
      const videoResource = videoUrl.substr(embedIndex + 7);
      const embedUrl = 'https://www.youtube.com/embed/' + videoResource;

      return {
        isValidUrl: true,
        url: embedUrl,
        type: 'embed',
      }
    }

    // try to extract video id from input and construct an embeddable url
    if (locationParts.pathname.indexOf('watch') != -1) {
      const videoId = this.getQueryParam(videoUrl, 'v');

      if (videoId) {
        const embedUrl = 'https://www.youtube.com/embed/' + videoId;

        // embeddable url
        return {
          isValidUrl: true,
          url: embedUrl,
          type: 'embed',
        }
      }
    }

    // unable to construct an embeddable url, but return link
    return {
      isValidUrl: true,
      url: videoUrl,
      type: 'link',
    }
  },
};

export default UrlHelper;
