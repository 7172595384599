
import ManageDeckCategoriesModal    from '_views/modals/ManageDeckCategoriesModal';
import EventManager                 from '@brainscape/event-manager';
import React                        from 'react';
        
import categoryOption               from '_models/categoryOption';
import deckCategory                 from '_models/deckCategory';


class ManageDeckCategoriesModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      categoryOptions: [],
      deckCategories: [],
      isLoading: false,
      isModalOpen: false,
      isProcessing: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListeners([
      ['manage-deck-categories-modal:open',  this.handleOpenRequest],
      ['manage-deck-categories-modal:close', this.handleCloseRequest],
    ]);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    return (
      <ManageDeckCategoriesModal
        addClasses={viewProps.addClasses}
        isLoading={this.state.isLoading}
        isOpen={this.state.isModalOpen}
        isProcessing={this.state.isProcessing}
        deck={viewProps.deck}
        categoryOptions={this.state.categoryOptions}
        deckCategories={this.state.deckCategories}
        onUpdateRequest={this.handleUpdateRequest}
        onCloseRequest={this.handleCloseRequest}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps?.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  handleOpenRequest = (data) => {
    this.setState({
      isLoading: true,
      isModalOpen: true,
      isProcessing: false,
      viewProps: data,
    }, () => {
      this.setupModalData();

      if (this.state.viewProps?.onOpenRequest) {
        this.state.viewProps.onOpenRequest();
      }
    });
  }

  handleUpdateRequest = (additions, removals) => {
    if (additions.length < 1 && removals.length < 1) {
      this.handleCloseRequest();
      this.triggerToastOpen('No changes to Deck Categories were specified', 'warning');

      return false;
    }

    const {packId, deck} = this.state.viewProps;
    const deckId = deck.deckId;

    if (additions.length > 0 && removals.length > 0) {
      deckCategory.update(packId, deckId, additions).then(() => {
        deckCategory.destroy(packId, deckId, removals).then(() => {
          this.handleCloseRequest();
          this.triggerToastOpen('Deck Categories Updated', 'success');
        });
      });

      return true;
    }

    if (additions.length > 0) {
      deckCategory.update(packId, deckId, additions).then(() => {
        this.handleCloseRequest();
        this.triggerToastOpen('Deck Categories Added', 'success');
      });

      return true;
    }

    if (removals.length > 0) {
      deckCategory.destroy(packId, deckId, removals).then(() => {
        this.handleCloseRequest();
        this.triggerToastOpen('Deck Categories Removed', 'success');
      });
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type='success', duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  applyAdditions = (categoryIds, additions) => {
    return [...categoryIds, ...additions];
  }

  applyRemovals = (categoryIds, removals) => {
    const newCategoryIds = categoryIds.filter(categoryId => {
      return (removals.indexOf(categoryId) == -1);
    });

    return newCategoryIds;
  }

  getDisplayCategories = (deckCategoryIds, categoryOptions) => {
    const displayCategories = deckCategoryIds.map(catId => {
      return categoryOptions.find(option => option.id == catId) || {id: catId, label: `${catId} - Unrecognized Category`};
    });

    return displayCategories;
  }

  setupModalData = () => {
    const packId = this.state.viewProps.packId;
    const deck = this.state.viewProps.deck;

    deckCategory.index(packId, deck.deckId).then(deckCategoryIds => {
      const isFullIndex = true;

      categoryOption.index(isFullIndex).then(categoryOptions => {

        const currentDeckCategories = this.getDisplayCategories(deckCategoryIds, categoryOptions);

        this.setState({
          categoryOptions: categoryOptions || [],
          deckCategories: currentDeckCategories || [],
          isLoading: false,
        });
      });
    });
  }
}

export default ManageDeckCategoriesModalController;
