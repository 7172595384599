
import {apiGet, apiPost}      from '_core/Api1';
import EventManager           from '@brainscape/event-manager';
import Modal                  from '_views/shared/Modal';
import PillButton             from '_views/shared/PillButton';
import Pulldown               from '_views/shared/Pulldown';
import React                  from 'react';
import SimpleTextButton       from '_views/shared/SimpleTextButton';
import Spinner                from '_views/shared/Spinner';
import UiHelper               from '_utils/UiHelper';

class CopyAndPlaceCardsModalSet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deckRows:             null,
      decksPulldownOpen:    false,
      destinationPackId:    null,
      isConfirmationModalOpen: false,
      isProcessing:         false,
      isLoadingDecks:       true,
      selectedDeckId:       null,
      shouldOpenConfirmationModal: false,
    };

    /*
      this.props:
        cardIds,
        contentType,
        deckId,
        isOpen,
        onCloseRequest,
        packId,
    */

    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.getUserEditableDecks();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    return (
      <div className="modal-set">
        {this.renderInputModal()}
        {this.renderConfirmationModal()}
      </div>
    );
  }

  renderInputModal() {
    const isDisabled = (this.state.selectedDeckId == null || this.state.selectedDeckId == -1);

    return (
      <Modal
        addClasses="copy-and-place-cards-modal"
        isOpen={this.props.isOpen}
        onClosed={this.handleInputModalClosed}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Copy and Place Cards</div>

        <div className="modal-guidance">You can copy cards into another deck in your library.</div>

        {this.renderNoDecksWarning()}
        {this.renderDeckSelector()}

        <div className="modal-actions">
          <PillButton
            addClasses="cancel-modal-button"
            label="Cancel"
            onClick={this.handleCancelButtonClick}
          />
          <PillButton
            addClasses="resolve-modal-button"
            isDisabled={isDisabled}
            isProcessing={this.state.isProcessing}
            label="Copy and Place"
            onClick={this.handleCopyAndPlaceButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderNoDecksWarning = () => {
    if (!this.state.deckRows)           { return null; }
    if (this.state.deckRows.length > 0) { return null; }

    return (
      <div className="modal-message">
        There are no decks in your library into which you can place these cards.
        Create a new deck, if you wish to continue.
      </div>
    );
  }

  renderDeckSelector() {
    const deckRows = this.state.deckRows;

    if (!(deckRows && deckRows.length > 0)) { 
      return null; 
    }

    const srcDeckId = this.props.deckId;
    const n         = this.props.cardIds.length;
    const name      = n == 1 ? 'selected card' : `${n} selected cards`;
    const rows      = [];

    deckRows.forEach((dr) => {
      if (srcDeckId == dr.deckId) { return; }

      rows.push({
        id:    dr.deckId,
        label: `[${dr.packName}] ${dr.deckName} (${dr.cardCt} cards)`,
      });
    });

    return (
      <div className="deck-selector">
        <div className='modal-message'>
          Choose a deck from your library into which to place the {name}:
        </div>

        <div className="pulldown-and-tooltip">
          {this.renderDecksPulldown(rows)}

          {/* <InfoButtonTooltip */}
          {/*   addClasses="copy-place-info-tooltip" */}
          {/* > */}
          {/*   <div className="tooltip-content"> */}
          {/*     <p className="tooltip-text">This is a list of the decks in classes where you have edit access.</p> */}
          {/*     <p className="tooltip-text">If you don't see the deck you're looking for, ask the admin to give you edit access to the class, or create your own new class.</p> */}
          {/*   </div> */}
          {/* </InfoButtonTooltip> */}
        </div>
      </div>
    );
  }

  renderDecksPulldown(deckOptions) {
    if (this.state.isLoadingDecks) {
      return (
        <Spinner />
      );
    }

    return (
      <Pulldown
        addClasses='decks-pulldown'
        isOpen={this.state.decksPulldownOpen}
        options={deckOptions}
        fieldName='deck'
        placeholderText='Select destination deck...'
        selectedValue={this.state.selectedDeckId || '-1'}
        shouldSuppressNullOption={false}
        onButtonClick={this.handleDecksPulldownButtonClick}
        onOptionClick={this.handleDecksPulldownOptionClick}
        onOutsideClick={this.handleDecksPulldownOutsideClick}
      />
    );
  }

  renderConfirmationModal() {
    const message = this.props.cardIds.length + ' card(s) copied. Open the parent class?';

    return (
      <Modal
        addClasses="copy-cards-confirmation-modal"
        isOpen={this.state.isConfirmationModalOpen}
        onCloseRequest={this.handleCloseConfirmationModalRequest}
      >

        <div className="modal-title">Cards copied to Deck</div>

        <div className="modal-message">{message}</div>

        <div className="modal-actions">

          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="No"
            onClick={this.handleNoButtonClick}
          />

          <PillButton
            addClasses="resolve-modal-button"
            label="Yes"
            onClick={this.handleYesButtonClick}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = () => {
    this.props.onCloseRequest();
  }

  handleCloseConfirmationModalRequest = () => {
    this.setState({
      isConfirmationModalOpen: false,
      shouldOpenConfirmationModal: false,
    })
  }

  handleCopyAndPlaceButtonClick = () => {
    this.setState({
      isProcessing: true,
    });

    const dstDeckId = this.state.selectedDeckId;

    const srcContentType = this.props.contentType;
    let   dstContentType = null;
    let   dstPackId      = null;

    this.state.deckRows.forEach((dr) => {
      if (dr.deckId == dstDeckId) {
        dstContentType = dr.contentType;
        dstPackId      = dr.packId;
      }
    });

    if (srcContentType != dstContentType) {
      const confirmationMessage = 'The content types are different: ' +
        `"${srcContentType}" → "${dstContentType}". Continue?`;

      this.triggerCautionModalOpen({
        message: confirmationMessage,
        resolveButtonText: 'Yes, Continue',
        onResolution: () => {
          this.performCopyAndPlace(dstPackId, dstDeckId);
          this.triggerCautionModalClose();
        },
        onCancellation: () => {
          this.props.onCloseRequest();
        },
      });
    } else {
      this.performCopyAndPlace(dstPackId, dstDeckId);
    }
  }

  handleDecksPulldownButtonClick = () => {
    this.setState({decksPulldownOpen: !this.state.decksPulldownOpen});
  }

  handleDecksPulldownOptionClick = (deckId) => {
    this.setState({decksPulldownOpen: false, selectedDeckId: deckId});
  }

  handleDecksPulldownOutsideClick = (deckId) => {
    this.setState({decksPulldownOpen: false});
  }

  handleInputModalClosed = () => {
    if (this.state.shouldOpenConfirmationModal) {
      this.setState({
        isConfirmationModalOpen: true,
      })
    }
  }

  handleNoButtonClick= () => {
    this.handleCloseConfirmationModalRequest();
  }

  handleYesButtonClick= () => {
    UiHelper.navigate('/l/dashboard/' + this.state.destinationPackId, 'Opening class...');
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalClose(viewProps) {
    EventManager.emitEvent('caution-modal:close', viewProps);
  }

  triggerCautionModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  closeModal = () => {
    this.props.onCloseRequest();
  }

  getUserEditableDecks = () => {
    apiGet('decks/editable').then((data) => {
      this.setupDeckSelectorOptions(data);
    }).catch((err) => {
      UiHelper.handleError({
        operation: 'Get Editable Decks',
        errorMessage: 'Could not read the list of decks',
        errorObject: err,
        notifyUser: true,
      });
      this.closeModal();
    });
  }

  setupDeckSelectorOptions = (data) => {
    const rows = [];

    (data.packs || []).forEach((pack) => {
      (pack.decks || []).forEach((deck) => {
        rows.push({
          cardCt:      this.countIds(deck.cardIds || deck.cardIDs),
          contentType: deck.contentType,
          deckId:      deck.deckId || deck.deckID,
          deckName:    deck.name,
          packId:      pack.packId || pack.packID,
          packName:    pack.name,
        });
      });
    });

    this.events.ifEnabled(() => {
      this.setState({
        deckRows: rows, 
        isLoadingDecks: false
      });
    });
  }

  countIds = (ids) => {
    if (!ids || ids == '') { return 0; }

    return ids.split(',').length;
  }

  performCopyAndPlace(dstPackId, dstDeckId) {
    const url    = `packs/${dstPackId}/decks/${dstDeckId}/cards/place`;
    const params = {
      src_pack_id:  this.props.packId,
      src_deck_id:  this.props.deckId,
      src_card_ids: this.props.cardIds.join(','),
    };

    apiPost(url, params).then((data) => {
      this.setState({
        destinationPackId: dstPackId,
        isProcessing: false,
      });

      this.showConfirmationModal();
    }).catch((err) => {
      // this.triggerInfoModalOpen({
      //   message:
      //     `There was a problem copying and placing cards. Error message: ${err.message}.`,
      //   resolveButtonText: 'Ok',
      //   shouldSuppressCancelButton: true,
      //   title: 'Problem',
      // });

      UiHelper.handleError({
        operation: 'Copy and Place Cards',
        errorMessage: err.message,
        errorObject: err,
        notifyUser: true,
      });
    });
  }

  showConfirmationModal() {
    this.setState({
      shouldOpenConfirmationModal: true,
    }, () => {
      this.props.onCloseRequest();
      // this will start animation of closing of input modal, upon its animation and close, the input modal's onClosed handler will fire. That handler then opens the confirmation modal (because this.state.shouldOpenConfirmationModal is true.)
    });
  }
}

export default CopyAndPlaceCardsModalSet;
