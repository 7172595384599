
import Modal                      from '_views/shared/Modal';
import PropTypes                  from 'prop-types';
import React                      from 'react';

const PT = {
  addClasses:            PropTypes.string,
  isOpen:                PropTypes.bool,
  isProcessing:          PropTypes.bool,
  onCloseRequest:        PropTypes.func,
}


class GetTheAppModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <Modal
        addClasses="get-the-app-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <>
          <div className="modal-title">Get Brainscape's mobile app!</div>
          <div className="modal-subtitle">Switch seamlessly between the web and the app, {'\n'} and get smarter wherever you are!</div>
          <div className='get-the-app-inner-content'>
            <div className='multi-platform-image'></div>
            <div className='qr-image-and-badges'>
              <div className='qr-image'></div>
              <div className='badges'>
                <div 
                  className='badge apple'
                  onClick={() => this.handleAppleAppStoreClick()}
                ></div>
                <div 
                  className='badge android'
                  onClick={() => this.handleGooglePlayClick()}
                ></div>
              </div>
            </div>
          </div>
        </>

      </Modal>
    );
  }

  renderLottieAnimation() {
    return (
      <div
        ref={(elem) => {
          this.animContainer = elem;
        }}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleGooglePlayClick() {
    window.open('https://play.google.com/store/apps/details?id=com.brainscape.mobile.portal', '_blank', 'noopener');
    this.props.onCloseRequest();
  }

  handleAppleAppStoreClick() {
    window.open('https://itunes.apple.com/us/app/brainscape-smart-flashcards/id442415567?mt=8', '_blank', 'noopener');
    this.props.onCloseRequest();
  }
}

GetTheAppModal.propTypes = PT;

export default GetTheAppModal;
