
import EventManager           from '@brainscape/event-manager';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import React                  from 'react';

import cardClipboard          from '_models/cardClipboard';
import deckCardDuplicate      from '_models/deckCardDuplicate';

import {toClassStr}           from '_utils/UiHelper';


class EditorCardOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingOptionAction: false,
    };

    /*
      this.props:
        addClasses,
        card,
        cardIds,
        deckId,
        packId,
        openPosition,
        tooltipContent,
        tooltipPosition,
    */

    this.menuOptionHash = {
      insertCard: {id: 'insertCard', tag: 'insert-card', label: 'Insert New Card',},
      duplicateCard: {id: 'duplicateCard', tag: 'duplicate-card', label: 'Duplicate Card',},
      copyCard: {id: 'copyCard', tag: 'copy-card', label: 'Copy Card (to paste)',},
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.updateUserOptions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['deck-card-row-options-button', this.props.addClasses]);

    return (
      <div className={classes}>
        <OptionsMenuButton
          addClasses="no-option-icons"
          iconType="horizontal"
          isProcessing={this.state.isProcessingOptionAction}
          menuOptions={this.state.menuOptions}
          onOptionClick={this.handleOptionClick}
          openPosition={this.props.openPosition}
          tooltipContent={this.props.tooltipContent || "Duplicate or Insert Card"}
          tooltipPosition={this.props.tooltipPosition || "left"}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick = (optionId) => {
    switch (optionId) {
      case 'duplicateCard':
        this.duplicateCard();
      break;
      case 'insertCard':
        EventManager.emitEvent('card:insert-new-card-request', {
          cardId: this.props.card.cardId,
          deckId: this.props.deckId,
          editMode: this.props.editMode,
          packId: this.props.packId,
        });
      break;
      case 'copyCard':
        this.copyCard();
      break;
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  copyCard = () => {
    const {packId, deckId, card} = this.props;
    const cardIds = [card.cardId];

    this.setState({
      isProcessingOptionAction: true,
    });

    cardClipboard.copy(packId, deckId, cardIds).then(() => {
      this.setState({
        isProcessingOptionAction: false,
      });
    });
  }

  duplicateCard = () => {
    const {packId, deckId, card} = this.props;

    this.setState({
      isProcessingOptionAction: true,
    });

    deckCardDuplicate.create(packId, deckId, card.cardId, card.number).then(() => {
      this.setState({
        isProcessingOptionAction: false,
      });
    });
  }

  updateUserOptions() {
    const menuOptionsForUser = Object.keys(this.menuOptionHash).reduce((accumulator, menuOption) => {
      // wrap this in a condition if need to restrict any options

      accumulator.push(this.menuOptionHash[menuOption]);

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

export default EditorCardOptionsButton;
