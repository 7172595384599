import {apiFetch, apiGet} from '../core/Api1';
import Model              from '../core/Model';

class DeckModel extends Model {
  constructor(props) {
    super(props);

    this.memberName = 'Deck';
    super.generateNomenclature();

    this.schema = {
      name: {key: 'name', serverKey: 'name', type: 'text', label: 'Name', tag: 'name', isRequired: true},
      desc: {key: 'desc', serverKey: 'desc', type: 'textarea', label: 'Objective', tag: 'desc'},
      categoryIds: {key: 'categoryIds', serverKey: 'categoryIds', type: 'text', label: 'Category ID', tag: 'category-ids', onlyAdmin: true}
    }
    this.nameKey = "name";
  }

  preview = (packId, deckId) => {
    return apiGet(`packs/${packId}/decks/${deckId}/preview`);
  }

  // This creates a new deck using the objective and CSV file.
  import = (packId, deckName, objective, csvFile) => {
    const fd = new FormData();
    if (deckName) { fd.append('name', deckName); }
    if (objective) { fd.append('desc', objective); }
    fd.append('file', csvFile);

    const opts = {method: 'POST', body: fd};

    return apiFetch(`packs/${packId}/decks/import`, opts);
  }

  create(opts) {
    const url = this.buildUrl(this.getResourceUrlBase(), opts.params);
    opts.reqData.content_type = opts.reqData.content_type || 'text';

    return $.ajax({
      type: 'POST',
      url: url,
      data: JSON.stringify(opts.reqData),
      contentType: "application/json"
    })
    .done((data, status) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, status);
      }
    })
    .fail((jqXhr, status) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, status);
      }
    });
  }

  remove(opts) {
    const url = '/packs/' + opts.packId + '/deck/' + opts.id + '.json';
    // NON STANDARD ROUTE: uses legacy non-api route

    return $.ajax({
      type: 'DELETE',
      url: url
    })
    .done((data, status) => {
      opts.done(data);
    })
    .fail((jqXHR, status) => {
      opts.fail(status, jqXHR.responseJSON||{});
    });
  }

  resetStats(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id + '/reset-stats', opts.params);

    return $.ajax({
      type: 'PATCH',
      url: url
    })
    .done((data, status) => {
      opts.done(data);
    })
    .fail((jqXHR, status) => {
      opts.fail(status, jqXHR.responseJSON||{});
    });
  }


  showUserPackDeck = (opts) => {
    return apiGet(`packs/${opts.packId}/decks/${opts.deckId}/user_pack_decks`);
  }

  copyAndPlace(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id + '/copy_and_place', opts.params);

    return $.ajax({
      type: 'POST',
      url: url,
      data: {
        src_pack_id: opts.srcPackId,
        dst_pack_id: opts.dstPackId
      }
    })
    .done((data, status) => {
      opts.done(data);
    })
    .fail((jqXHR, status) => {
      opts.fail(status, jqXHR.responseJSON||{});
    });
  }

  deckCategories(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id + '/deck-categories', opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
    .done((data, status) => {
      opts.done(data);
    })
    .fail((jqXHR, status) => {
      opts.fail(message, jqXHR.responseJSON||{});
    });
  }

  validateCategoryIds(inputData, categoryHash) {
    // TODO: Right now, we are prompting the user to enter one single categoryID,
    // but with some minor adjustments, we could handle a comma separated array of IDs

    let categoryId = inputData.categoryIds;

    if (categoryHash[categoryId]) {
      return {
        isValid: true,
        invalidFields: [],
        message: "valid " + this.memberName + " input data",
        validData: inputData
      };
    } else {
      return {
        isValid: false,
        invalidFields: ['categoryIds'],
        message: "Can't find a valid category for Id: " + categoryId,
        validData: null
      };
    }
  }
}

export default DeckModel;
