
import PropTypes              from 'prop-types';
import React                  from 'react';
import TransitionWrapper      from '_views/shared/TransitionWrapper';
import UiHelper               from '_utils/UiHelper';

import {DismissButton}        from '_views/shared/IconButton';

import {toClassStr}           from '_utils/UiHelper';

const PT = {
  content:            PropTypes.node,
  elem:               PropTypes.object,
  hasDismissButton:   PropTypes.bool,
  position:           PropTypes.string,  // top, right, bottom, left
  onCloseRequest:     PropTypes.func,  
  onClosed:           PropTypes.func,
  onMouseEnter:       PropTypes.func,
  onMouseLeave:       PropTypes.func,
};


class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      isMobileViewportSize: false,
      left: 0,
      top: 0,
      width: 0,
    };

    this._isMounted = false;
  }
  

/*
==================================================
  LIFECYCLE METHODS
==================================================
*/

componentDidMount() {
  this._isMounted = true;
  this.manageViewport();

  if (this.props.isOpen) {
    this.handleOpened();
  }
}

componentDidUpdate(prevProps) {
  if (!prevProps.isOpen && this.props.isOpen) {
    this.handleOpened();
  }

  if (prevProps.elem != this.props.elem) {
    this.handleOpened();
  }
}

componentWillUnmount() {
  this._isMounted = false;
}

/*
==================================================
  RENDERERS
==================================================
*/

  render() {
    const content = this.props.content;
    const isElement = React.isValidElement(content);

    const isElementClass = isElement ? 'is-element' : '';
    const isOpenClass = this.props.isOpen ? 'is-open' : '';
    const positionClass = this.props.position || 'top';
    const classes = toClassStr(['tooltip', isElementClass, isOpenClass, positionClass, this.props.addClasses]);

    if (!this.props.content) {
      return null;
    }

    if (this.state.isMobileViewportSize) {
      return null;
    }

    if (isElement) {
      return this.renderElementTooltip(classes);
    } else {
      return this.renderSimpleTooltip(classes);
    }
  }

  renderElementTooltip(classes) {
    const componentStyle = {
      left: this.state.left,
      top: this.state.top,
    };

    return (
      <div className={classes} style={componentStyle} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
        {this.renderDismissButton()}
        {this.props.content}
      </div>
    );
  }

  renderSimpleTooltip(classes) {
    const componentStyle = {
      height: this.state.height,
      left: this.state.left,
      top: this.state.top,
      width: this.state.width,
    };

    return (
      <div className={classes} data-label={this.props.content} style={componentStyle} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
        {this.renderDismissButton()}
      </div>
    );
  }

  renderDismissButton() {
    if (!this.props.hasDismissButton) {
      return null;
    }

    return (
      <DismissButton
        addClasses="dismiss-tooltip-button"
        onClick={() => this.handleDismissButtonClick()}
      />
    );
  }

/*
==================================================
  EVENT HANDLERS
==================================================
*/

  handleDismissButtonClick = () => {
    this.props.onCloseRequest();
  }

  handleOpened = () => {
    if (this.props.elem) {
      const elemRect = this.props.elem.getBoundingClientRect();
      this.setCoordinates(elemRect);
    }
  }


  /*
  ==================================================
    LOCAL UTILS
  ==================================================
  */

  manageViewport() {
    this.setState({
      isMobileViewportSize: UiHelper.detectIfMobileSize(),
    });
  }

  setCoordinates = (elemRect) => {
    let height;
    let left;
    let top;
    let width;

    switch (this.props.position) {
      case 'top':
        left = elemRect.left;
        top = elemRect.top;
        width = `${elemRect.width}px`;
      break;
      case 'right':
        height = `${elemRect.height}px`;
        left = elemRect.left + elemRect.width;
        top = elemRect.top;
      break;
      case 'bottom':
        left = elemRect.left;
        top = elemRect.top + elemRect.height;
        width = `${elemRect.width}px`;
      break;
      case 'left':
        height = `${elemRect.height}px`;
        left = elemRect.left;
        top = elemRect.top;
      break;
      default:
        height = `${elemRect.height}px`;
        left = elemRect.left;
        top = elemRect.top;
    }

    this.setState({
      height: height,
      left: left,
      top: top,
      width: width,
    }, () => {
       this.shiftTooltip();
    });
  }

  shiftTooltip = () => {
    const tooltip = document.querySelector('.tooltip');
    const body = document.querySelector('body');

    if (!(body && tooltip)) {
      return false;
    }

    const tooltipRect = tooltip.getBoundingClientRect();

    if (tooltipRect.top < 0) {
      this.setState({
        top: `${this.state.top - tooltipRect.top + 3}px`,
      });
    }

    if (tooltipRect.right > body.scrollWidth) {
      this.setState({
        left: `${body.scrollWidth - tooltipRect.width + 3}px`,
      });

      return true;
    }

    if (tooltipRect.left < 0) {
      this.setState({
        left: `${this.state.left - tooltipRect.left + 3}px`,
      });
    }
  }
}

Tooltip.propTypes = PT;

export default Tooltip;
