
import deckCard     from '_models/deckCard';
import model        from '_core/newModel';

const deckCardReordering = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId, cardIds) {    
    return new Promise((resolve, reject) => {
      try {
        const data = {cardIds: cardIds.join(',')};
        const path = `packs/${packId}/decks/${deckId}/cards/reorder`;

        model.create(path, data).then(() => {
          deckCard.index(packId, deckId).then(cardsData => { 
            model.publish('deck-cards:reordered', {
              packId: packId,
              deckId: deckId,
              cardIds: cardsData.cardIds,
              cards: cardsData.cards,
            });

            resolve(cardsData);
          }).catch(err => {
            throw err;
          });
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error('something went wrong during deckCard reorder create. err:', err);
        reject(err);
      }
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckCardReordering;
