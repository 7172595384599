
import DeckCardRow    from '_views/deck-detail/desktop/edit/DeckCardRow';
import EventManager   from '@brainscape/event-manager';
import PropTypes      from 'prop-types';
import React          from 'react';
import Sortable       from 'sortablejs';

import {toClassStr}   from '_utils/UiHelper';
      
const PT = {
  cardSelections:           PropTypes.object,
  cards:                    PropTypes.array,
  cardsHaveChanges:         PropTypes.bool,
  currentCardId:            PropTypes.node,
  deckId:                   PropTypes.node,
  deckCardView:             PropTypes.string,
  isPaginatingDeckCards:    PropTypes.bool,
  lastAddedCardId:          PropTypes.node,
  lastUpdatedCardId:        PropTypes.node,
  onSortEnd:                PropTypes.func,
  packId:                   PropTypes.node,
  sidebarMode:              PropTypes.string,
};


class DeckCardList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSortable: false,
    };

    this.listElement = null;
    this.sortable = null;
    this.sortedIds = null;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this.initSortable();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isPaginatingDeckCards != prevProps.isPaginatingDeckCards) {
      this.manageSortability();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const classes = toClassStr(['deck-card-list text-rows', this.props.addClasses]);

    const list = this.props.cards.map((card, index) => {
      const key = card.cardId;
      const listNumber = index + 1;
      const isLastAdded = (card && card.cardId && card.cardId == this.props.lastAddedCardId);
      const isLastUpdated = (card && card.cardId && card.cardId == this.props.lastUpdatedCardId);

      return (
        <DeckCardRow
          card={card}
          cardCount={this.props.cards.length}
          deckCardView={this.props.deckCardView}
          isCurrentCard={this.props.currentCardId == card.cardId}
          isDraggable={this.state.isSortable && !this.props.isPaginatingDeckCards}
          isLastAdded={isLastAdded}
          isLastUpdated={isLastUpdated}

          isSelected={this.props.cardSelections[card.cardId] || false}
          key={key}
          listNumber={listNumber}
          sidebarMode={this.props.sidebarMode}
        />
      );
    });

    return (
      <div className={classes} ref={(i) => this.listElement = i}>
        {list}
      </div>
    );
  };


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleSortStart = (e) => {
    const src = e.oldIndex;
    this.sortedIds = this.props.cards.map((c) => c.cardId);

    if (this.props.cardsHaveChanges) {
      this.triggerSaveChangesRequest();
    }
  }

  handleSortEnd = (e) => {
    if (!this.state.isSortable) {
      return false;
    }

    if (this.props.isPaginatingDeckCards) {
      return false;
    }

    const src = e.oldIndex;
    const dst = e.newIndex;

    // Keep track of the order of the cards as the user moves them around.
    this.sortedIds.splice(dst, 0, this.sortedIds.splice(src, 1)[0]);
    const sortedCardId = this.sortedIds[dst];

    this.triggerDeckCardsReorderRequest(this.sortedIds, sortedCardId);
  };


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerDeckCardsReorderRequest = (sortedIds, sortedCardId) => {
    EventManager.emitEvent('deck-cards:reorder-request', {
      deckId: this.props.deckId,
      packId: this.props.packId,
      reorderedCardIds: sortedIds,
      sortedCardId: sortedCardId,
    });
  }

  triggerSaveChangesRequest = () => {
    EventManager.emitEvent('smart-card:save-changes-request', {});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  initSortable = () => {
    if (this.listElement) {
      const opts = {
        direction: 'vertical',
        onStart:  this.handleSortStart,
        onEnd:    this.handleSortEnd,
        animation: 150,
        ghostClass: "sortable-ghost",  // Class name for the drop placeholder
        chosenClass: "sortable-chosen",  // Class name for the chosen item
        dragClass: "sortable-drag",  // Class name for the dragging item
        draggable: ".deck-card-row",
        delay: 400,
        delayOnTouchOnly: true,
      };

      this.sortable = new Sortable(this.listElement, opts);

      this.setState({
        isSortable: true,
      });
    }
  }

  manageSortability = () => {
    const isSortable = (this.state.isSortable && !this.props.isPaginatingDeckCards);
    this.sortable.option('sort', isSortable);
  }
}

DeckCardList.propTypes = PT;

export default DeckCardList;
