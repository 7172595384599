
import model              from '_core/newModel';
import userLocalStore     from '_models/userLocalStore';


const studyMixSeed = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  postShow(studyMixIds, userId=null) {  // generates show of seed data, but supplies params in body as a POST request
    const path = 'study/mixes/seeds';
    const data = { studyMixIds };

    return model.create(path, data)
      .then(seedData => {
        const augmentedSeedData = {...seedData};

        const packIds = seedData?.packs?.ids;
        const packsH = seedData?.packs?.hash;

        if (packIds && packsH && userId) {
          packIds.forEach(packId => {
            const packH = packsH[packId];
            if (packH) {
              packH.mixType = userLocalStore.getPackStudyMixType(userId, packH);
              augmentedSeedData.packs.hash[packId] = packH;
            }
          });
        }

        return augmentedSeedData;
      })
      .catch(err => {
        console.error(err);
        return Promise.reject(err);
      });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default studyMixSeed;
