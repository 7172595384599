
import LocalStorageHelper from '_utils/LocalStorageHelper';
import TypeHelper         from '_utils/TypeHelper';

import 'url-search-params-polyfill'; // polyfill to support IE and Edge

const userStore = {
  /* a UserStore is an object stored in local storage with its top-level key being either 'userStore-{userId}' or its alias 'userLibrary-{userId} (supports legacy userStores)'

  Underneath the top level key are the following key value pairs:

    bannerViews: {},
    flashMessageViews: {},
    localPrefs: {},
    messageViews: {},
    packDeckSelections: {},
    packLearnerSelections: {},
    packStudyConfigs: {},
    userPackSelections: {},
  */

  /*
  ==================================================
   LOCAL STORAGE CRUD METHODS
  ==================================================
  */

  create(userId) {
    let userStoreData = {};
    userStoreData.bannerViews = {};
    userStoreData.flashMessageViews = {};
    userStoreData.localPrefs = {};
    userStoreData.messageViews = {};
    userStoreData.packDeckSelections = {};
    userStoreData.packLearnerSelections = {};
    userStoreData.packLearnerSorters = {};
    userStoreData.packMetadataStatus = {};
    userStoreData.packStudyConfigs = {};
    userStoreData.userPackSelections = {};

    const userStoreKey = this.getUserStoreKey(userId);

    const result = LocalStorageHelper.setItem(userStoreKey, userStoreData)

    if (result) {
      const userStore = {};
      userStore[userStoreKey] = userStoreData;

      return userStore;
    } 

    return false;
  },

  show(userId) {
    const userStoreKey = this.getUserStoreKey(userId);

    let userStoreData = LocalStorageHelper.getItem(userStoreKey);

    if (!userStoreData) {
      userStoreData = this.checkAndUpdateLegacyData(userId);
    }

    if (userStoreData) {
      userStoreData = this.ensureUserStoreDefaults(userStoreKey, userStoreData);

      const userStore = {};
      userStore[userStoreKey] = userStoreData;

      return userStore;
    } 

    return false;
  },

  update(userId, newStoreData) { // takes fully populated store data object
    const userStoreKey = this.getUserStoreKey(userId);
    let userStoreData = LocalStorageHelper.getItem(userStoreKey);
    userStoreData = this.ensureUserStoreDefaults(userStoreKey, userStoreData);
    userStoreData = {...userStoreData, ...newStoreData};

    LocalStorageHelper.setItem(userStoreKey, userStoreData);

//     const userStore = {};
// 
//     userStore[userStoreKey] = userStoreData;

    return userStoreData;
  },

  destroy(userId) {
    const userStoreKey = this.getUserStoreKey(userId);
    LocalStorageHelper.removeItem(userStoreKey);

    return true;
  },


  /*
  ==================================================
   CONVENIENCE METHODS
  ==================================================
  */

  getKeys(obj) {
    // preserves numeric keys
    return Object.keys(obj).map(key => parseInt(key) || key);
  },

  getOrCreateUserStore(userId) {
    const userStore = this.show(userId);

    if (TypeHelper.isObject(userStore)) {
      return userStore;
    } 
      
    return this.create(userId);
  },

  getMessageViews(userId, messageId) {
    const userStoreData = this.getUserStoreData(userId);
    const messageViews = userStoreData.messageViews || {};
    
    return messageViews[messageId] || {};
  },

  getPackDeckSelections(userId, packId) {
    const userStoreData = this.getUserStoreData(userId);

    let pds = userStoreData.packDeckSelections[packId];
    pds = TypeHelper.isObject(pds) ? pds : {};

    return pds;
  },

  getPackLearnerSelections(userId, packId) {
    const userStoreData = this.getUserStoreData(userId);

    let pls = userStoreData.packLearnerSelections[packId];
    pls = TypeHelper.isObject(pls) ? pls : {};

    return pls;
  },

  getPackLearnerSorters(userId, packId) {
    const userStoreData = this.getUserStoreData(userId);

    let sorters = userStoreData.packLearnerSorters[packId];
    sorters = TypeHelper.isObject(sorters) ? sorters : {};

    return sorters;
  },

  getPackMetadataStatus(userId, packId) {
    const userStoreData = this.getUserStoreData(userId);

    let pms = userStoreData.packMetadataStatus[packId];
    pms = !!pms; // coerces to true or false

    return pms;
  },

  getPackStudyMixType(userId, pack, deckIds=null) {
    if (!deckIds) { deckIds = pack.deckIds; }

    let psc = this.getPackStudyConfigs(userId, pack.packId);

    if (psc.mixType && psc.mixType != '') { return psc.mixType; }

    if (pack.mixType && pack.mixType != '') { return pack.mixType; }

    if (deckIds && deckIds.length == 1) {
      // This is a weird rule requested by Andrew: https://trello.com/c/wBeoTvzj
      return 'progressive';
    }

    return 'progressive';
  },

  getPackStudyConfigs(userId, packId) {
    const userStoreData = this.getUserStoreData(userId);

    let psc = userStoreData.packStudyConfigs[packId];
    psc = TypeHelper.isObject(psc) ? psc : {};

    return psc;
  },

  getSelectedDeckIdsForPack(userId, packId, packDeckIds) {
    const pds     = this.getPackDeckSelections(userId, packId);
    const deckIds = [];

    if (packDeckIds) {
      packDeckIds.forEach((deckId) => {
        if (pds[deckId]) { deckIds.push(deckId); }
      });
    }

    return deckIds;
  },

  getSelectedPackDeckIds(userId, packId) {
    const pds = this.getPackDeckSelections(userId, packId);
    return this.getKeys(pds);
  },

  getSelectedPackLearnerIds(userId, packId) {
    const pls = this.getPackLearnerSelections(userId, packId);
    return this.getKeys(pls);
  },

  getSelectedUserPackIds(userId) {
    const ups = this.getUserPackSelections(userId);
    return this.getKeys(ups);
  },

  getUserLocalPref(userId, prefKey) {
    const localPrefs = this.getUserLocalPrefs(userId);
    return localPrefs[prefKey] || null;
  },

  getUserLocalPrefs(userId) {
    const userStoreData = this.getUserStoreData(userId);

    let localPrefs = userStoreData.localPrefs;
    localPrefs = TypeHelper.isObject(localPrefs) ? localPrefs : {};

    return localPrefs;
  },

  getUserPackSelections(userId) {
    const userStoreData = this.getUserStoreData(userId);

    let ups = userStoreData.userPackSelections[userId];
    ups = TypeHelper.isObject(ups) ? ups : {};

    return ups;
  },

  getUserStoreData(userId) {
    const userStore = this.getOrCreateUserStore(userId);
    const userStoreKey = this.getUserStoreKey(userId);

    return userStore[userStoreKey];
  },

  isPackMixSet(userId, packId) {
    let psc = this.getPackStudyConfigs(userId, packId);
    return psc.isMixSet || false;
  },

  markPackLearnersAsSelected(userId, packId) {
    let psc = this.getPackStudyConfigs(userId, packId);
    psc.isMixSet = true;

    this.updateUserStorePackData(userId, packId, 'packStudyConfigs', psc);
  },

  markPackMixAsSet(userId, packId) {
    let psc = this.getPackStudyConfigs(userId, packId);
    psc.isMixSet = true;

    this.updateUserStorePackData(userId, packId, 'packStudyConfigs', psc);
  },

  removePack(userId, packId) {
    const userStoreData = this.getUserStoreData(userId);

    if (userStoreData.packDeckSelections[packId]) {
      delete userStoreData.packDeckSelections[packId];
    }

    if (userStoreData.packLearnerSelections[packId]) {
      delete userStoreData.packLearnerSelections[packId];
    }

    if (userStoreData.packStudyConfigs[packId]) {
      delete userStoreData.packStudyConfigs[packId];
    }

    this.update(userId, userStoreData);
  },

  resetUserLocalStore(userId) {
    this.destroy(userId);
    this.create(userId);
  },

  selectPackDecks(userId, packId, deckIds) {
    let pds = this.getPackDeckSelections(userId, packId);

    deckIds.forEach((deckId) => {
      pds[deckId] = true;
    });

    this.updateUserStorePackData(userId, packId, 'packDeckSelections', pds);
    this.markPackMixAsSet(userId, packId);
  },

  selectPackLearners(userId, packId, learnerIds) {
    let pls = this.getPackLearnerSelections(userId, packId);

    learnerIds.forEach((learnerId) => {
      pls[learnerId] = true;
    });

    this.updateUserStorePackData(userId, packId, 'packLearnerSelections', pls);
  },

  selectUserPacks(userId, packIds) {
    let ups = this.getUserPackSelections(userId);

    packIds.forEach((packId) => {
      ups[packId] = true;
    });

    const userStoreData = this.getUserStoreData(userId);
    userStoreData.userPackSelections = ups;

    this.update(userId, userStoreData);
  },

  setMessageView(userId, messageId, messageData) {
    const views = {};
    views[messageId] = messageData;

    this.updateMessageViews(userId, views);
  },

  setPackLearnerSorters(userId, packId, packLearnerSorters) {
    this.updateUserStorePackData(userId, packId, 'packLearnerSorters', packLearnerSorters);
  },

  setPackStudyMixType(userId, packId, mixType) {
    let psc = this.getPackStudyConfigs(userId, packId);
    psc.mixType = mixType;

    this.updateUserStorePackData(userId, packId, 'packStudyConfigs', psc);
  },

  setPackMetadataStatus(userId, packId, status=false) {
    this.updateUserStorePackData(userId, packId, 'packMetadataStatus', status);
  },

  setUserLocalPref(userId, prefKey, prefData) {
    this.updateUserLocalPrefs(userId, prefKey, prefData);
  },

  syncPackDecks(pack) {
    const userId = pack.userId;
    const packId = pack.packId;
    const localSelectedDeckIds = this.getSelectedPackDeckIds(userId, packId) || {};
    let syncedDeckSettings = {};

    if (pack.deckIds && pack.deckIds.length > 0) {

      for (let i=0; i < localSelectedDeckIds.length; i++) {
        if (pack.deckIds.indexOf(localSelectedDeckIds[i]) != -1) {
          // because we don't store unselected decks locally, we only need to remove local decks that were previously selected (and hence, set in our local Storage) and have recently been removed. The inverse case, decks that recently been added, will simply show up as unselected in the UI.
          syncedDeckSettings[localSelectedDeckIds[i]] = true;
        };
      }

      this.updateUserStorePackData(userId, packId, 'packDeckSelections', syncedDeckSettings);

    } else {
      this.updateUserStorePackData(userId, packId, 'packDeckSelections', {});
    }
  },

  syncPackLearners(packId, learners) {
    const userId = BSC.userId;
    const packLearnerIds = learners.map((learner, index) => {
      return learner.userId;
    });
    const localSelectedLearners = this.getSelectedPackLearnerIds(userId, packId) || {};
    let syncedLearnerSettings = {};

    if (learners.length > 0) {

      for (let i=0; i < localSelectedLearners.length; i++) {
        if (packLearnerIds.indexOf(localSelectedLearners[i]) != -1) {
          // because we don't store unselected learners locally, we only need to remove local learners that were previously selected (and hence, set in our local Storage) and have recently been removed. The inverse case, learners that recently been added, will simply show up as unselected in the UI.
          syncedLearnerSettings[localSelectedLearners[i]] = true;
        };
      }

      this.updateUserStorePackData(userId, packId, 'packLearnerSelections', syncedLearnerSettings);

    } else {
      this.updateUserStorePackData(userId, packId, 'packLearnerSelections', {});
    }
  },

  updateBannerViews(userId, views) {
    const userStoreData = this.getUserStoreData(userId);

    userStoreData.bannerViews = userStoreData.bannerViews || {};
    userStoreData.bannerViews = views;

    this.update(userId, userStoreData);
  },

  updateFlashMessageViews(userId, views) {
    const userStoreData = this.getUserStoreData(userId);

    userStoreData.flashMessageViews = userStoreData.flashMessageViews || {};
    userStoreData.flashMessageViews = {...views, ...flashMessageViews};

    this.update(userId, userStoreData);
  },

  updateMessageViews(userId, views) {
    const userStoreData = this.getUserStoreData(userId);
    const messageViews = userStoreData.messageViews || {};

    userStoreData.messageViews = {...messageViews, ...views};

    this.update(userId, userStoreData);
  },

  updateUserStorePackData(userId, packId, resourceKey, resourceData) {
    const userStoreData = this.getUserStoreData(userId);
    userStoreData[resourceKey][packId] = resourceData;

    this.update(userId, userStoreData);
  },

  updateUserLocalPrefs(userId, prefKey, prefData) {
    const userStoreData = this.getUserStoreData(userId);

    userStoreData.localPrefs = userStoreData.localPrefs || {};
    userStoreData.localPrefs[prefKey] = prefData;

    this.update(userId, userStoreData);
  },

  unselectPackDecks(userId, packId, deckIds) {
    let pds = this.getPackDeckSelections(userId, packId);

    deckIds.forEach((deckId) => {
      delete pds[deckId];
    });

    this.updateUserStorePackData(userId, packId, 'packDeckSelections', pds);
    this.markPackMixAsSet(userId, packId);
  },

  unselectPackLearners(userId, packId, learnerIds) {
    let pls = this.getPackLearnerSelections(userId, packId);

    learnerIds.forEach((learnerId) => {
      delete pls[learnerId];
    });

    this.updateUserStorePackData(userId, packId, 'packLearnerSelections', pls);
  },

  unselectUserPacks(userId, packIds) {
    let ups = this.getUserPackSelections(userId);

    packIds.forEach((packId) => {
      delete ups[packId];
    });

    this.update(userId, 'userPackSelections', packIds);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  checkAndUpdateLegacyData(userId) {
    const legacyKey = `userLibrary-${userId}`;
    const legacyStore = LocalStorageHelper.getItem(legacyKey);

    if (legacyStore) {
      this.updateUserStoreKey(userId, legacyStore);
      return legacyStore;
    }

    return null;
  },

  ensureUserStoreDefaults(userStoreKey, data) {
    let userStoreData = {};

    if (TypeHelper.isObject(data)) {
      userStoreData = data;
    }

    userStoreData.packDeckSelections = TypeHelper.isObject(userStoreData.packDeckSelections) ? userStoreData.packDeckSelections : {};
    userStoreData.packLearnerSelections = TypeHelper.isObject(userStoreData.packLearnerSelections) ? userStoreData.packLearnerSelections : {};
    userStoreData.packLearnerSorters = TypeHelper.isObject(userStoreData.packLearnerSorters) ? userStoreData.packLearnerSorters : {};
    userStoreData.packMetadataStatus = TypeHelper.isObject(userStoreData.packMetadataStatus) ? userStoreData.packMetadataStatus : {};
    userStoreData.userPackSelections = TypeHelper.isObject(userStoreData.userPackSelections) ? userStoreData.userPackSelections : {};
    userStoreData.packStudyConfigs = TypeHelper.isObject(userStoreData.packStudyConfigs) ? userStoreData.packStudyConfigs : {};
    userStoreData.localPrefs = TypeHelper.isObject(userStoreData.localPrefs) ? userStoreData.localPrefs : {};
    userStoreData.messageViews = TypeHelper.isObject(userStoreData.messageViews) ? userStoreData.messageViews : {};

    LocalStorageHelper.setItem(userStoreKey, userStoreData);

    return userStoreData;
  },

  getUserStoreKey(userId) {
    return `userStore-${userId}`;
  },

  updateUserStoreKey: (userId, userStore) => {
    LocalStorageHelper.setItem(`userStore-${userId}`, userStore);
    LocalStorageHelper.removeItem(`userLibrary-${userId}`)
  },
}

export default userStore;
