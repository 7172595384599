
import {
  EditButton,
}                                     from '_views/shared/IconButton';

import DynamicTooltipIcon                   from '_views/shared/DynamicTooltipIcon';
import PackMetaField                  from '_views/pack-detail/desktop/about/PackMetaField';
import PillButton                     from '_views/shared/PillButton';
import PropTypes                      from 'prop-types';
import React                          from 'react';
import SimpleTextButton               from '_views/shared/SimpleTextButton';
import TextField                      from '_views/shared/TextField';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                          : PropTypes.string,
  isEditingResource                   : PropTypes.bool,
  isEditingSupplemental               : PropTypes.bool,
  isPackMetadataEditable              : PropTypes.bool,
  isPreviewing                        : PropTypes.bool,
  isProcessing                        : PropTypes.bool,
  metadata                            : PropTypes.object,
  onCancelEditSupplementalRequest     : PropTypes.func,
  onEditSupplementalRequest           : PropTypes.func,
  onUpdateSupplementalRequest         : PropTypes.func,
  pack                                : PropTypes.object,
  segmentId                           : PropTypes.node,
};


class PackAboutSupplementalSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alternateNames                  : "",
      hasInvalidInput                 : false,
      isAlternateNamesOverCharLimit    : false,
      isAuthorOverCharLimit           : false,
      isIsbnOverCharLimit             : false,
      isTitleOverCharLimit            : false,
      primaryLanguage                 : "",
      sourceBookAuthor                : "",
      sourceBookIsbn                  : "",
      sourceBookTitle                 : "",
      supplementalData                : {},
    }
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.setResources();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.metadata != this.props.metadata) {
      this.manageResources(prevProps);
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!(this.props.isPackMetadataEditable) || this.props.isPreviewing) {
      return null;
    }

    const isEditingSegmentClass = this.props.isEditingSupplemental ? 'is-editing-segment' : '';
    const hasInvalidInputClass = this.state.hasInvalidInput ? 'has-invalid-input' : '';
    const classes = toClassStr(['segment about-tab-segment pack-about-supplemental-segment', isEditingSegmentClass, hasInvalidInputClass]);

    return (
      <div className={classes}>
        <div className="segment-anchor" id={this.props.segmentId}></div>
        {this.renderSupplementalHeader()}
        {this.renderShowableSupplementalFields()}
        {this.renderEditableSupplementalFields()}
      </div>
    );
  }

  renderSupplementalHeader() {
    return (
      <div className="segment-header about-tab-segment-header supplemental-header">
        <div className="segment-heading about-tab-segment-heading supplemental-heading">Supplemental Info</div>

        <div className="action-buttons">
          {this.renderSupplementalTooltip()}
          {this.renderEditSupplementalButton()}
        </div>

        {this.renderSupplementalEditActionButtons()}
      </div>
    );
  }

  renderSupplementalTooltip() {
    if (!this.props.isPackMetadataEditable || this.props.isPreviewing) {
      return null;
    }

    return (
      <DynamicTooltipIcon 
        actions={null}
        addClasses="supplemental-tooltip"
        body="This supplemental information helps us customize your Brainscape experience, and can help other people find your flashcards (if you choose to keep them public)"
        hasDismissButton={true}
        position="right"
        subHeading={null}
      />
    );
  }

  renderEditSupplementalButton() {
    if (!this.props.isPackMetadataEditable || this.props.isEditingResource || this.props.isPreviewing) {
      return null;
    }

    return (
      <EditButton
        addClasses="edit-supplemental-button"
        onClick={this.handleEditButtonClick}
      />
    );
  }

  renderSupplementalEditActionButtons() {
    if (!(this.props.isPackMetadataEditable && this.props.isEditingSupplemental)) {
      return null;
    }

    const saveButtonLabel = (this.props.isMobileViewportSize) ? 'Save' : 'Save changes';

    return (
      <div className="edit-action-buttons">

        <SimpleTextButton
          addClasses="cancel-button cancel-edit-supplemental-button"
          label="Cancel"
          onClick={this.handleCancelButtonClick}
        />

        <PillButton
          addClasses="save-button save-supplemental-changes-button"
          isProcessing={this.props.isProcessing}
          label={saveButtonLabel}
          onClick={this.handleSaveChangesButtonClick}
        />
      </div>
    );
  }

  renderShowableSupplementalFields() {
    if (this.props.isEditingSupplemental) {
      return null;
    }

    return (
      <div className="supplemental-fields-display">
        {this.renderSupplementalFields()}
      </div>
    );
  }

  renderEditableSupplementalFields() {
    if (!this.props.isEditingSupplemental) {
      return null;
    }

    return (
      <form className="supplemental-fields-form" onSubmit={this.handleFormSubmit}>
        {this.renderSupplementalFields()}
        <input type="submit" className="hidden-submit-button" />
      </form>
    );
  }

  renderSupplementalFields() {
    return (
      <div className="supplemental-fields">

        <div className="segment-sub-group sourcing">

          <div className="heading-and-tooltip">

            <div className="segment-sub-heading">Sourcing</div>

            <DynamicTooltipIcon 
              actions={null}
              addClasses="sourcing-tooltip"
              body="If these flashcards are primarily sourced from one book, enter the Title, Author, and ISBN (if you have it) of the book. This will both help other users find your content, and will help Brainscape prioritize which publishers we should try to partner with more formally :)"
              hasDismissButton={true}
              heading={null}
              position="right"
              subHeading={null}
            />
          </div>

          <div className="sub-group-fields">
            {this.renderSourceBookTitle()}
            {this.renderSourceBookAuthor()}
            {this.renderSourceBookIsbn()}
          </div>
        </div>

        <div className="segment-sub-group primary-language">

          <div className="heading-and-tooltip">

            <div className="segment-sub-heading">Primary Language</div>

            <DynamicTooltipIcon 
              actions={null}
              addClasses="primary-language-tooltip"
              body="e.g. If this class's native speakers are all Brazilian, then we're guessing your content is in Portuguese."
              hasDismissButton={true}
              heading={null}
              position="right"
              subHeading={null}
            />
          </div>

          <div className="sub-group-fields">
            {this.renderPrimaryLanguage()}
          </div>
        </div>

        <div className="segment-sub-group alternate-names">

          <div className="heading-and-tooltip">

            <div className="segment-sub-heading">Alternate Names</div>

            <DynamicTooltipIcon 
              actions={null}
              addClasses="alternate-names-tooltip"
              body='e.g. If your class name is "Greek History" but some users might be searching for "Ancient Greece", then write "Ancient Greece" as an alternate name. You can separate multiple Alternate Names by comma.'
              hasDismissButton={true}
              heading={null}
              position="right"
              subHeading={null}
            />
          </div>

          <div className="sub-group-fields">
            {this.renderAlternateNames()}
          </div>
        </div>
      </div>
    );
  }

  renderSourceBookTitle() {
      const apiData = this.props.metadata.global.source_book_title;
      const metaField = this.state.supplementalData[apiData.field_id]

      if (!metaField) {
        return null;
      }

      return (
        <PackMetaField
          addClasses="source-book-title"
          fieldId={metaField.fieldId}
          fieldKey={metaField.fieldKey}
          fieldType="textField"
          fieldLabel="Book Title"
          fieldValue={metaField.fieldValue}
          isEditing={this.props.isEditingSupplemental}
          key="source-book-title"
          onOverCharLimitChange={this.handleOverCharLimitChange}
          onChange={this.handleFieldChange}
          placeholder={metaField.placeholder}
          tooltip={metaField.tooltip}
        />
      );
  }

  renderSourceBookAuthor() {
      const apiData = this.props.metadata.global.source_book_author;
      const metaField = this.state.supplementalData[apiData.field_id]

      if (!metaField) {
        return null;
      }

      return (
        <PackMetaField
          addClasses="source-book-author"
          fieldId={metaField.fieldId}
          fieldKey={metaField.fieldKey}
          fieldType="textField"
          fieldLabel="Book Author"
          fieldValue={metaField.fieldValue}
          isEditing={this.props.isEditingSupplemental}
          key="source-book-author"
          onOverCharLimitChange={this.handleOverCharLimitChange}
          onChange={this.handleFieldChange}
          placeholder={metaField.placeholder}
          tooltip={metaField.tooltip}
        />
      );
  }

  renderSourceBookIsbn() {
      const apiData = this.props.metadata.global.source_book_isbn;
      const metaField = this.state.supplementalData[apiData.field_id]

      if (!metaField) {
        return null;
      }

      return (
        <PackMetaField
          addClasses="source-book-isbn"
          fieldId={metaField.fieldId}
          fieldKey={metaField.fieldKey}
          fieldType="textField"
          fieldLabel="Book ISBN"
          fieldValue={metaField.fieldValue}
          isEditing={this.props.isEditingSupplemental}
          key="source-book-isbn"
          onOverCharLimitChange={this.handleOverCharLimitChange}
          onChange={this.handleFieldChange}
          placeholder={metaField.placeholder}
          tooltip={metaField.tooltip}
        />
      );
  }

  renderPrimaryLanguage() {
      const apiData = this.props.metadata.global.language;
      const metaField = this.state.supplementalData[apiData.field_id]

      if (!metaField) {
        return null;
      }

      let options = metaField.options.map((option, index) => {
        return {
          id: option,
          label: option,
        };
      });

      options.unshift({
        id: -1,
        label: 'Choose One...',
      });

      return (
        <PackMetaField
          addClasses="primary-language"
          fieldId={metaField.fieldId}
          fieldKey={metaField.fieldKey}
          fieldType="pulldown"
          fieldLabel=""
          fieldValue={metaField.fieldValue}
          isEditing={this.props.isEditingSupplemental}
          key="primary-language"
          onChange={this.handleFieldChange}
          options={options}
          placeholder={metaField.placeholder}
          tooltip={metaField.tooltip}
        />
      );
  }

  renderAlternateNames() {
    if (this.props.isEditingSupplemental) {
      return this.renderEditableAlternateNames();
    }

    const apiData = this.props.metadata.global.alternate_names;
    const metaField = this.state.supplementalData[apiData.field_id]

    if (!metaField) {
      return null;
    }

    return (
      <div className="alternate-names-text">{metaField.fieldValue}</div>
    );
  }

  renderEditableAlternateNames() {
    const apiData = this.props.metadata.global.alternate_names;
    const metaField = this.state.supplementalData[apiData.field_id];
    const fieldId = metaField.fieldId;

    return (
      <TextField
        addClasses="alternate-names-textfield"
        charLimit={280}
        id="alternate-names"
        onOverCharLimitChange={(isOverLimit) => this.handleOverCharLimitChange(fieldId, isOverLimit)}
        onChange={(e) => this.handleFieldChange(fieldId, e.target.value)}
        placeholder="Enter a comma-separated list of alternate names for your class"
        value={metaField.fieldValue || ""}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  handleEditButtonClick = () => {
    if (this.props.onEditSupplementalRequest) {
      this.props.onEditSupplementalRequest();
    }
  }

  handleCancelButtonClick = () => {
    this.setResources();

    if (this.props.onCancelEditSupplementalRequest) {
      this.props.onCancelEditSupplementalRequest();
    }
  }

  handleFieldChange = (fieldId, fieldValue) => {
    let supplementalData = {...this.state.supplementalData};
    const newValue = (fieldValue != -1) ? fieldValue : "";
    supplementalData[fieldId].fieldValue = newValue;

    this.setState({
      hasInvalidInput: false,
      supplementalData: supplementalData,
    })
  }

  handleFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.handleSaveChanges();
  }

  handleOverCharLimitChange = (fieldId, isOverLimit) => {
    let supplementalData = {...this.state.supplementalData};

    if (supplementalData[fieldId]) {
      supplementalData[fieldId].isOverLimit = isOverLimit;

      this.setState({
        supplementalData: supplementalData,
      })
    }
  }

  handleSaveChangesButtonClick = () => {
    this.handleSaveChanges();
  }

  handleSaveChanges = () => {
    // form an array of pack meta fields (metum)
    const supplementalData = this.state.supplementalData;
    const attributeKeys = Object.keys(supplementalData);
    let metum = [];

    for (let i=0; i<attributeKeys.length; i++) {
      const key = attributeKeys[i];

      if (supplementalData[key].isOverLimit) {
        this.setState({
          hasInvalidInput: true,
        });

        return false; // breaks out of loop *and* function, does not submit changes
      }

      metum.push({
        field_id: supplementalData[key].fieldId,
        value: supplementalData[key].fieldValue,
      });
    }

    this.props.onUpdateSupplementalRequest(metum);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  generateMetaField(resourceKey) {
    const apiData = this.props.metadata.global[resourceKey];

    if (!apiData) {
      return null;
    }

    return {
      fieldId           : apiData.field_id,
      fieldKey          : apiData.field_key,
      fieldLabel        : apiData.field_label,
      fieldValue        : apiData.value,
      placeholder       : apiData.placeholder,
      options           : apiData.options,
      tooltip           : apiData.tool_tip,
    };
  }

  hasResource = (resourceKey, testProps) => {
    const props = testProps || this.props;

    return !!(props.metadata && props.metadata.global && props.metadata.global[resourceKey] && props.metadata.global[resourceKey].value);
  }

  hasResourceChanged = (resourceKey, prevProps) => {
    if (!(this.hasResource(resourceKey, prevProps) && this.hasResource(resourceKey, this.props))) {
      return false;
    }

    const prevResourceValue = prevProps.metadata.global[resourceKey].value;
    const resourceValue = this.props.metadata.global[resourceKey].value;

    return (resourceValue != prevResourceValue);
  }

  manageResources = (prevProps) => {
    if (this.hasResourceChanged('alternate_names', prevProps)) {
      this.setResource('alternate_names');
    }

    if (this.hasResourceChanged('language', prevProps)) {
      this.setResource('language');
    }

    if (this.hasResourceChanged('source_book_author', prevProps)) {
      this.setResource('source_book_author');
    }

    if (this.hasResourceChanged('source_book_isbn', prevProps)) {
      this.setResource('source_book_isbn');
    }

    if (this.hasResourceChanged('source_book_title', prevProps)) {
      this.setResource('source_book_title');
    }
  }

  setResource = (resourceKey) => {
    let supplementalData = {...this.state.supplementalData};
    const metaField = this.generateMetaField(resourceKey);

    if (metaField && metaField.fieldId) {
      supplementalData[metaField.fieldId] = metaField;

      this.setState({
        supplementalData: supplementalData,
      });
    }
  }

  setResources = () => {
    let supplementalData = {...this.state.supplementalData};

    const alternateNames = this.generateMetaField('alternate_names');
    const language = this.generateMetaField('language');
    const sourceBookAuthor = this.generateMetaField('source_book_author');
    const sourceBookIsbn = this.generateMetaField('source_book_isbn');
    const sourceBookTitle = this.generateMetaField('source_book_title');

    supplementalData[alternateNames.fieldId] = alternateNames;
    supplementalData[language.fieldId] = language;
    supplementalData[sourceBookAuthor.fieldId] = sourceBookAuthor;
    supplementalData[sourceBookIsbn.fieldId] = sourceBookIsbn;
    supplementalData[sourceBookTitle.fieldId] = sourceBookTitle;

    this.setState({
      hasInvalidInput: false,
      supplementalData: supplementalData,
    });
  }
}

PackAboutSupplementalSegment.propTypes = PT;

export default PackAboutSupplementalSegment;
