
import model from '_core/newModel';


const packMessage = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, messageText) {
    const path = `packs/${packId}/messages`;

    const data = {
      text: messageText,
    };

    return model.create(path, data);
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default packMessage;
