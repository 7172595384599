
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import SimpleTextButton          from '_views/shared/SimpleTextButton';
import StringHelper              from '_utils/StringHelper';
import userIdentity              from '_models/userIdentity';

import {toClassStr}              from '_utils/UiHelper';

class UnlinkIdentityProviderOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWarningModalOpen: false,
      isAcknowledgementModalOpen: false,
      isProcessingUnlink: false,
      postModalCallback: () => {},
      selectedProvider: null,
    };

    /*
      this.props:
        addClasses,
        currentUser,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.props?.currentUser?.profile?.identityProviders) {
      return null;
    }

    const classes = toClassStr(['unlink-identity-provider-options account-section-item', this.props.addClasses]);

    const identityProviderOptions = this.props.currentUser.profile.identityProviders.map(provider => {
      return this.renderIdentityProviderOption(provider);
    });

    return (
      <ul className={classes}>
        {identityProviderOptions}

        {this.renderWarningModal()}
        {this.renderAcknowledgementModal()}
      </ul>
    );
  }

  renderIdentityProviderOption(provider) {
    if (!provider || provider == 'brainscape') {
      return null;
    }

    const optionLabel = StringHelper.toTitleCase(`Unlink ${provider} Login`);

    return (
      <li className="identity-provider-option" key={`provider-${provider}`} onClick={(e) => this.handleIdentityProviderOptionClick(e, provider)}>
        {optionLabel} 
      </li>
    );
  }

  renderWarningModal() {
    if (!this.state.selectedProvider) {
      return null;
    }

    const provider = StringHelper.toTitleCase(this.state.selectedProvider);
    const email = this.props.currentUser.profile.email;

    return (
      <Modal
        addClasses="warning-modal"
        isOpen={this.state.isWarningModalOpen}
        onClosed={() => this.state.postModalCallback()}
        onCloseRequest={() => this.handleCloseWarningModalRequest()}
      >

        <div className="modal-title">Unlink {provider} Login</div>

        <div className="modal-message">Are you sure? This action will unlink your user account from logging in with {provider}.</div>
        <div className="modal-message">If you choose to unlink, you will receive an e-mail at {email} with a link to set up a new password. (You can utilize the "Forgot Password" link on the login screen at any time to generate this e-mail.)</div>
        <div className="modal-actions">

          <PillButton
            addClasses="cancel-modal-button"
            label="Cancel"
            onClick={() => this.handleCancelButtonClick()}
          />
          
          <SimpleTextButton
            addClasses="resolve-modal-text-button"
            isProcessing={this.state.isProcessingReset}
            label="Yes, please unlink my account"
            onClick={() => this.handleConfirmButtonClick()}
          />
        </div>
      </Modal>
    );
  }

  renderAcknowledgementModal() {
    return (
      <Modal
        addClasses="ackowledgement-modal"
        isOpen={this.state.isAcknowledgementModalOpen}
        onCloseRequest={() => this.handleCloseAcknowledgementModalRequest()}
      >

        <div className="modal-title">Login Provider Unlinked</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={() => this.handleAcknowledgementButtonClick()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAcknowledgementButtonClick() {
    this.setState({
      isAcknowledgementModalOpen: false,
    });
  }

  handleCancelButtonClick() {
    this.setState({
      isWarningModalOpen: false,
      postModalCallback: () => {},
    });
  }

  handleCloseAcknowledgementModalRequest() {
    this.setState({
      isAcknowledgementModalOpen: false,
    });
  }

  handleCloseWarningModalRequest() {
    this.setState({
      isWarningModalOpen: false,
    });
  }

  handleConfirmButtonClick() {
    this.setState({
      isProcessingUnlink: true,
    }, () => {
      this.unlinkIdentityProvider();   
    });
  }

  handleOpenAcknowledgementModalRequest() {
    this.setState({
      isAcknowledgementModalOpen: true,
    });
  }

  handleIdentityProviderOptionClick(e, provider) {
    e.stopPropagation();

    this.setState({
      isWarningModalOpen: true,
      selectedProvider: provider,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  unlinkIdentityProvider() {
    userIdentity.destroy(this.props.currentUser.userId, this.state.selectedProvider).then(() => {
      this.setState({
        isProcessingUnlink: false,
        isWarningModalOpen: false,
        postModalCallback: () => this.handleOpenAcknowledgementModalRequest(),
      });
    }).catch(err => {
      console.error('Something went wrong with userIdentity destroy.')
    });
  }
}

export default UnlinkIdentityProviderOptions;
