
import React                     from 'react';
import TextField                 from '_views/shared/TextField';

import {toClassStr} from '_utils/UiHelper';

class CopyToClipboardLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isInClipboard: false,
    };

    /*
      this.props:
        addClasses,
        isDisplayedInInputElem, // true if value to be copied into clipboard is displayed 
                                // in an input elem (e.g. input type text, textarea).
        inputElemSelector,      // this is a querySelector for an input elem holding the 
                                // value to be copied into the clipboard.
        onResetLink,
        shouldResetLink,
        value,                  // Use this prop to enable a string not displayed in an
                                // input elem to be copied into the clipboard.
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.props.value) {
      this.setState({
        isInClipboard: false,
      })
    }

    if (!prevProps.shouldResetLink && this.props.shouldResetLink) {
      this.setState({
        isInClipboard: false,
      }, () => {
        this.props.onResetLink();
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isInClipboardClass = this.state.isInClipboard ? 'is-in-clipboard' : '';
    const classes = toClassStr(['copy-to-clipboard-link', isInClipboardClass]);
    const linkText = this.state.isInClipboard ? 'Copied to Clipboard' : 'Copy to Clipboard';

    return (

      <div className={classes} onClick={(e) => this.handleCopyToClipboardClick(e)}>
        {linkText}
        {this.renderVirtualInputElem()}
      </div>
    );
  }

  renderVirtualInputElem() {
    if (this.props.isDisplayedInInputElem) {
      return null;
    }

    return (
      <TextField 
        addClasses="virtual-input-elem"
        isReadOnly={true}
        value={this.props.value}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCopyToClipboardClick(e) {
    e.stopPropagation();
    const selector = this.props.isDisplayedInInputElem ? this.props.inputElemSelector : '.virtual-input-elem .text-input';

    const linkElem = document.querySelector(selector);
    linkElem.select();
    document.execCommand('copy');

    this.setState({
      isInClipboard: true,
    })
  }
}

export default CopyToClipboardLink;
