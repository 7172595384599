
import EventManager                   from '@brainscape/event-manager';
import React                          from 'react';
import smoothscroll                   from 'smoothscroll-polyfill';

import {toClassStr} from '_utils/UiHelper';

class SegmentNavigator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSegmentId: 0,
    };

    /*
      this.props:
        addClasses,
        scrollElementId,    // element which is actually scrolling 
        segmentsElementId,  // element which contains the segments to track
        segments,
    */

    this.events = new EventManager();

    this.scrollElement = null;
    this.segmentsElement = null;
    this.segmentsScrollTop = 0;
    this.segmentsScrollPosition = 0;
    this.segmentBreakpoints = [];

    this.shouldPauseNavigatorChanges = false;

    this.resumeNavigatorChangesTimeout = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    smoothscroll.polyfill(); // enables smooth scrolling in Safari and Firefox

    this.clearTimeoutsAndIntervals();
    this.initSegmentMonitor();

    this.events.addListener(
      'segment-navigator:select-segment',
      (data) => {
        this.handleSelectSegmentRequest(data);
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (this.haveSegmentsChanged(prevProps.segments, this.props.segments)) {
      this.refreshSegments();
    }
  }

  componentWillUnmount() {
    this.stopScrollMonitor();
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!(this.props.segments && this.props.segments.length && this.props.segments.length > 0)) {
      return null;
    }

    const classes = toClassStr(['segment-navigator', this.props.addClasses]);
    const segmentMarkers = this.props.segments.map((segment, index) => {
      return this.renderSegmentMarker(segment, index);
    });

    return (
      <ul className={classes}>
        {segmentMarkers}
      </ul>
    );
  }

  renderSegmentMarker(segment, index) {
    const selectedClass = (segment.id == this.state.currentSegmentId) ? 'is-selected' : ''
    const classes = toClassStr(['segment-marker', selectedClass]);

    return (
      <li className={classes} onClick={(e) => this.handleSegmentMarkerClick(e, segment.id)} key={"segment-" + index}>
        {segment.label}
      </li>      
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleSelectSegmentRequest = (data) => {
    if (data.shouldRefreshSegments) {
      this.refreshSegments(() => {
        this.selectSegment(data.segmentId);
        if (data.callback) {
          callback();
        }
      });
    } else {
      this.selectSegment(data.segmentId);
    }
  }

  handleSegmentMarkerClick = (e, segmentId) => {
    if (e) {
      e.stopPropagation();
    }

    this.selectSegment(segmentId);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.resumeNavigatorChangesTimeout);
  }

  getCurrentSegmentId(segmentsScrollPosition) {
    const topmostBreakpoint = this.segmentBreakpoints.slice(-1)[0];
    let currentSegmentId = topmostBreakpoint.segmentId;

    for (var i = 0; i < this.segmentBreakpoints.length; i++) {
      const breakpoint = this.segmentBreakpoints[i];
      const offset = this.segmentsScrollTop - segmentsScrollPosition;

      if (offset > breakpoint.position) {
        currentSegmentId = breakpoint.segmentId;
        break;
      }
    }

    return currentSegmentId;
  }

  haveSegmentsChanged(oldSegments, newSegments) {
    const oldString = oldSegments.join(',');
    const newString = newSegments.join(',');

    return (newString != oldString);
  }

  initSegmentMonitor = (callback) => {
    this.setState({
      currentSegmentId: this.props.segments[0].id,
    });

    this.resetSegmentMonitor(callback);
  }

  manageScrollEvents = () => {
    this.segmentsScrollPosition = this.segmentsElement.getBoundingClientRect().top;

    if (this.shouldPauseNavigatorChanges) {
      return false;
    }

    const currentSegmentId = this.getCurrentSegmentId(this.segmentsScrollPosition);

    if (currentSegmentId != this.state.currentSegmentId) {
      this.setState({
        currentSegmentId: currentSegmentId,
      })
    }
  }

  pauseAndResumeMonitor = () => {
    this.clearTimeoutsAndIntervals();

    // give scroll events time to complete, then resume monitoring
    this.shouldPauseNavigatorChanges = true;

    this.resumeNavigatorChangesTimeout = setTimeout(() => {
      this.shouldPauseNavigatorChanges = false;
    }, 1000);
  }

  refreshSegments = (callback) => {
    this.stopScrollMonitor();
    this.clearTimeoutsAndIntervals();
    this.resetSegmentMonitor();

    if (callback) {
      callback();
    }
  }

  resetSegmentMonitor = () => {
    this.scrollElement = document.getElementById(this.props.scrollElementId);
    this.segmentsElement = document.getElementById(this.props.segmentsElementId);
    this.segmentsScrollTop = this.segmentsElement.getBoundingClientRect().top;

    if (this.scrollElement && this.segmentsElement) {
      this.setSegmentBreakpoints();
      this.startScrollMonitor();
    }
  }

  selectSegment = (segmentId) => {
    const segment = document.getElementById(segmentId);

    if (segment) {
      this.pauseAndResumeMonitor();
      segment.scrollIntoView({behavior: 'smooth'});
    }

    this.setState({
      currentSegmentId: segmentId,
    })
  } 

  setSegmentBreakpoints = () => {
    let segmentBreakpoints = [];
    let sortedBreakpoints = [];

    this.props.segments.forEach((segment, index) => {
      const segmentElement = document.getElementById(segment.id);
      if (segmentElement) {
        const segmentScrollTop = segmentElement.getBoundingClientRect().top;
        const breakpointPosition = segmentScrollTop - this.segmentsScrollTop;
        segmentBreakpoints.push({
          position: breakpointPosition,
          segmentId: segment.id,
        })
      }
    });

    if (segmentBreakpoints.length > 1) {
      // reverse sort breakpoints
      segmentBreakpoints.sort((a, b) => {
        return (b.breakpoint > a.breakpoint) ? 1 : -1;
      });
    }

    this.segmentBreakpoints = segmentBreakpoints;
  }

  startScrollMonitor = () => {
    this.scrollPosition = this.segmentsElement.getBoundingClientRect().top;
    this.manageScrollEvents();
    this.scrollElement.addEventListener('scroll', this.manageScrollEvents);
  }


  stopScrollMonitor = () => {
    this.scrollElement.removeEventListener('scroll', this.manageScrollEvents);
  }

}

export default SegmentNavigator;
