
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import TextField                 from '_views/shared/TextField';
import StringHelper              from '_utils/StringHelper';

const PT = {      
  addClasses:                    PropTypes.string,
  currentUser:                   PropTypes.object,
  isOpen:                        PropTypes.bool,
  isProcessing:                  PropTypes.bool,
  onCloseRequest:                PropTypes.func,
  onFormSubmit:                  PropTypes.func,
  onOpened:                      PropTypes.func,
};


class CreatePackModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasValidInput: false,
      newPackName: "",
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.resetFields();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <Modal
        addClasses="create-pack-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Add New Class</div>

        <div className="modal-guidance">A Class is a set of Flashcards, grouped into Decks</div>

        <form
          className="create-pack-form"
          onSubmit={this.handleFormSubmit}
        >

          <TextField 
            addClasses="new-pack-name"
            hasInitialFocus={true}
            id="new-pack-name"
            onChange={(e) => this.handleNameChange(e)}
            placeholder="Class title"
            tooltipContent="e.g. Biology, Mandarin Chinese, Chess Moves, etc."
            tooltipPosition="top"
            value={this.state.newPackName}
          />
        </form>

        <div className="modal-actions">
          <PillButton
            addClasses="pill-button button-secondary button-medium resolve-modal-button"
            isDisabled={!this.state.hasValidInput}
            isProcessing={this.props.isProcessing}
            label="Continue"
            onClick={() => this.handleContinueButtonClick()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleContinueButtonClick = () => {
    if (this.state.hasValidInput && !this.props.isProcessing) {
      this.props.onFormSubmit(this.state.newPackName);
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.hasValidInput && !this.props.isProcessing) {
      this.props.onFormSubmit(this.state.newPackName);
    }
  }

  handleNameChange = (e) => {
    const nameText = e.target.value;
    const hasValidInput = !StringHelper.isWhitespace(nameText);

    this.setState({
      hasValidInput: hasValidInput,
      newPackName: nameText,
    })
  }
  

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  resetFields() {
    this.setState({
      hasValidInput: false,
      newPackName: "",
    });
  }
}

CreatePackModal.propTypes = PT;

export default CreatePackModal;
