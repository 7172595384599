
import EventManager           from '@brainscape/event-manager';
import Modal                  from '_views/shared/Modal';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import PillButton             from '_views/shared/PillButton';
import PropTypes              from 'prop-types';
import React                  from 'react';

import userLocalStore         from '_models/userLocalStore';

import {toClassStr}           from '_utils/UiHelper';


const MENU_OPTIONS = {
  setProgressiveMix: {id: 'setProgressiveMix', tag: 'set-progressive-mix', label: 'Progressive Mix', persistOnClick: true},
  setRandomMix: {id: 'setRandomMix', tag: 'set-random-mix', label: 'Random Mix', persistOnClick: true},
  help: {id: 'help', tag: 'help', label: 'What is this?'},
};

const PT = {
  addClasses:                 PropTypes.string,
  isConfigurable:             PropTypes.bool,
  isDisabled:                 PropTypes.bool,
  isFtue:                     PropTypes.bool,
  isUserPro:                  PropTypes.bool,
  onStudyRequest:             PropTypes.func,
  openPosition:               PropTypes.string,
  pack:                       PropTypes.object,
  tooltipClasses:             PropTypes.string,
  tooltipContent:             PropTypes.string,
  tooltipDelay:               PropTypes.number,
  tooltipPosition:            PropTypes.string,
  userId:                     PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};


class StudyControls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStudyMixTypeHelpModalOpen:  false,
      isProcessingOptionAction:     false,
      menuOptions:                  [],
      shouldPulseStudyButton:       false,
      studyMixType:                 'progressive',   
    }

    this.events = new EventManager();

    this.studyButtonElem = null;
    this.tooltipHover = false;
    this.tooltipTimeout = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.subscribeToEvents()
    this.updateUserOptions();
    this.setupStudyMixType();
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('ftue:pulse-study-button-request',    this.handlePulseStudyButtonRequest);
  } 

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {    
    const isFtueClass = this.props.isFtue ? 'is-ftue' : '';
    const pulseClass = this.state.shouldPulseStudyButton ? 'pulse' : '';
    const isDisabledClass = (this.props.isDisabled) ? 'is-disabled' : '';
    const classes = toClassStr(['study-controls', isFtueClass, pulseClass, isDisabledClass, this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderMixTypeOptionsButton()}

        <div
          className="study-button"
          onClick={this.handleStudyButtonClick}
          onMouseEnter={this.handleStudyButtonMouseEnter}
          onMouseLeave={this.handleStudyButtonMouseLeave}
          ref={(elem) => { this.studyButtonElem = elem }} 
        >
          <i className="study-button-icon" />
          <div className="label">Study</div>
        </div>
      </div>
    );
  }

  renderMixTypeOptionsButton() {
    if (!this.props.isConfigurable) {
      return null;
    }

    if (this.state.menuOptions?.length < 1) {
      return null;
    }

    const studyMixType = this.state.studyMixType;
    const studyMixTypeClass = (studyMixType == 'progressive') ? 'progressive-mix-enabled' : 'random-mix-enabled';
    const classes = toClassStr(['mix-type-options-button', studyMixTypeClass]);
    const openPosition = this.props.openPosition || 'bottomRight';

    return (
      <div className={classes}>

        <OptionsMenuButton
          iconType="gear"
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.isUserPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={this.handleOptionActionRequest}
          openPosition={openPosition}
          shouldButtonTurn={true}
          tooltipContent={"Set Study Mix Type (Currently " + studyMixType + ")"}
          tooltipPosition="top"
        />

        {this.renderStudyMixTypeHelpModal()}
      </div>
    );
  }

  renderStudyMixTypeHelpModal() {
    return (
      <Modal
        addClasses="mix-type-help-modal"
        isOpen={this.state.isStudyMixTypeHelpModalOpen}
        onCloseRequest={this.handleHelpModalCloseRequest}
      >

        <div className="modal-title">About Brainscape Study Mix Types</div>

        <div className="modal-message">You can configure your study session in the following ways:</div>

        <div className="modal-message"><strong>'Progressive'</strong> selects cards from decks in the order they appear. So, you will not see cards from the 2nd deck until you've mostly mastered the 1st deck.  Once you've moved to a later deck, cards from earlier decks can still repeat occasionally.</div>

        <div className="modal-message"><strong>'Random'</strong> will select from a pool of cards across all selected decks.  The repetition priority is still given to the lower-confidence items, but new/un-seen cards may be chosen from any position in any of the selected decks.</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={this.handleHelpOkButtonClick}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionActionRequest = (optionId) => {
    switch (optionId) {
      case 'setProgressiveMix':
        this.updateStudyMixType('progressive');
      break;
      case 'setRandomMix':
        this.updateStudyMixType('random');
      break;
      case 'help':
        this.showHelpAction();
      break;
    }
  }

  handleHelpModalCloseRequest = () => {
    this.setState({
      isStudyMixTypeHelpModalOpen: false,
    });
  }

  handleHelpOkButtonClick = () => {
    this.setState({
      isStudyMixTypeHelpModalOpen: false,
    });
  }

  handlePulseStudyButtonRequest = () => {
    this.setState({
      shouldPulseStudyButton: true,
    });
  }

  handleStudyButtonClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.props.onStudyRequest();
  }

  handleStudyButtonMouseEnter = (e) => {
    if (this.props.tooltipContent) {

      if (this.props.tooltipDelay) {
        this.tooltipHover = true;

        this.tooltipTimeout = setTimeout(() => {
          if (this.tooltipHover) {
            this.triggerTooltipOpen();
          }
        }, this.props.tooltipDelay);

      } else {
        this.triggerTooltipOpen();
      }
    }
  }

  handleStudyButtonMouseLeave = () => {
    if (this.props.tooltipContent) {
      this.tooltipHover = false;
      this.triggerTooltipClose();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    if (!this._isMounted) {
      return false;
    }

    EventManager.emitEvent('tooltip:open', {
      addClasses: this.props.tooltipClasses,
      content: this.props.tooltipContent,
      elem: this.studyButtonElem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    if (!this._isMounted) {
      return false;
    }
    
    EventManager.emitEvent('tooltip:close', {});
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.tooltipTimeout);
  }

  setupStudyMixType = () => {
    const studyMixType = userLocalStore.getPackStudyMixType(this.props.userId, this.props.pack);

    this.setState({
      studyMixType: studyMixType,
    });
  }

  showHelpAction = () => {
    this.setState({
      isStudyMixTypeHelpModalOpen: true,
    });
  }

  updateStudyMixType = (studyMixType) => {
    const newStudyMixType = studyMixType == 'random' ? 'random' : 'progressive';
    userLocalStore.setPackStudyMixType(this.props.userId, this.props.pack.packId, newStudyMixType);

    this.setState({
      studyMixType: newStudyMixType,
    });
  }

  updateUserOptions = () => {
    const pack = this.props.pack;

    let menuOptionsForUser = Object.keys(MENU_OPTIONS).reduce((accumulator, menuOption) => {

      let permitPerEditDeckRights = (MENU_OPTIONS[menuOption].onlyDeckEditor) ? pack.areDecksEditable : true;

      if (permitPerEditDeckRights) {
        accumulator.push(MENU_OPTIONS[menuOption]);
      }

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

StudyControls.propTypes = PT;

export default StudyControls;
