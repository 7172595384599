
import deckCard         from '_models/deckCard';
import model            from '_core/newModel';
import {waitForJob}     from '_core/Api2';

const cardReversalJob = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId, cardIds) {
    return new Promise((resolve, reject) => {
      try {
        const path = `packs/${packId}/decks/${deckId}/cards/reverse`;
        const data = {
          cardIds: cardIds.join(','),
        };

        model.create(path, data).then((jobData) => {

          if (jobData.jobId == -1) {
            // job has already been performed, get new cards, publish and resolve

            this.fetchNewCards(packId, deckId).then((resultData) => {
              model.publish('card-reversal-job:completed', resultData);
              resolve(resultData);
            });

          } else {

            // a job performing the reversals has been started. publish notification of the job start so the UI can be updated
            model.publish('card-reversal-job:started', {
              packId: packId,
              deckId: deckId,
              cardIds: cardIds,
            });

            // monitor the job
            waitForJob(jobData).then(() => {

              this.fetchNewCards(packId, deckId).then((resultData) => {
                model.publish('card-reversal-job:completed', resultData);
                resolve(resultData);
              });
            }).catch(err => {
              console.error(err);
              reject(err);
            });
          }
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  fetchNewCards(packId, deckId) {
    return new Promise((resolve, reject) => {
      try {
        deckCard.index(packId, deckId).then(cardsData => {

          const resultData = {
            packId: packId,
            deckId: deckId,
            cardIds: cardsData.cardIds,
            cards: cardsData.cards,
          };

          resolve(resultData);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },
};

export default cardReversalJob;
