
/* NOTE: This component is being maintained until LEarner Progress Report is reworked

Please use SimpleProgressBar instead

*/


import React from 'react';

import {toClassStr} from '_utils/UiHelper';

class OldLearnersProgressBar extends React.Component {
  constructor(props) {
    super(props);

    /*
      this.props:
        index,
        packID,
        percentages,
        masteryRatio,
        forceRefetch,
        authenticityToken,
        placeholderId,
        hasPlaceholder,
        hasLegacyStyles,
        addClasses
    */

    this.state = {
      index:        this.props.index || -1,
      percentages:  this.props.percentages,
      masteryRatio: this.props.masteryRatio,
      maxTries: 3,
      tries: 0,
      hasPlaceholder: this.props.hasPlaceholder || false,
    }

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    if (null != this.props.packID) {
      var reFetch = (this.props.forceRefetch == false) ? false : true;
      if (reFetch) {
        //console.log("Refetching mastery on progressBar for " + this.props.packID.toString());

        this._fetchMastery();
      } else {
        this.removePlaceholder();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // FIXME: make 2 components, when we refactor this for ES6 w/ WebPacker...
  render() {
    let classes = toClassStr(['progress', this.props.addClasses]);

    if (null != this.props.packID) {
      //console.log("progressBar for p: " + this.props.packID.toString());
      return (
        <div key={this.state.index} className={classes}>
          <div className="progress-bar" role="progressbar" aria-valuenow="{this.state.masteryRatio}" aria-valuemin="0" aria-valuemax="100" style={{width: (this.state.masteryRatio + '%')}}>
            <span className="sr-only">{this.state.masteryRatio}% Complete.</span>
          </div>
        </div>
      );
    } else if ((null != this.props.deckID) && (null != this.state.percentages)) {
      return (
        <div className="progress">
          {this._renderConfidencePercentage(1)}
          {this._renderConfidencePercentage(2)}
          {this._renderConfidencePercentage(3)}
          {this._renderConfidencePercentage(4)}
          {this._renderConfidencePercentage(5)}
        </div>
      );
    } else {
      console.log("progressBar for unknown pack or deck missing mastery &/ percentages: ", JSON.stringify(this.props))
      // BUG:
      return null;
    }
  }

  _renderConfidencePercentage(i) {
    //console.log("this.state.percentages["+i+"]: " + this.state.percentages[i]);
    return (
      <div data-index={i.toString()} className={"progress-bar progress-bar-confidence-" + i} style={{width: (this.state.percentages[i].toString() + '%')}}>
        <span className="sr-only">{this.state.percentages}% rated a {i}.</span>
      </div>
    );
  }

  _fetchMastery() {
    var self = this;

    //console.log("progressBar fetch(ing)Mastery...");
    $.ajax({
      url: "/packs/" + self.props.packID + "/stats",
      dataType: 'json',
      method: 'GET',
      //cache: false,
    })
    .done(function(data, textStatus, jqXHR) {
      //console.log("finished pack-stats try: " + (self.state.tries + 1).toString() + "; got: " + data.mastery_ratio);
      if (self._isMounted && data) {
        self.setState({
          tries: self.state.tries + 1,
          masteryRatio: data.mastery_ratio
        });

        self.removePlaceholder();
      }
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      console.log("pack-stats failed try: " + (self.state.tries + 1).toString() + "; status: " + textStatus);
      self.setState({tries: self.state.tries + 1})
    })
  }

  removePlaceholder() {
    let placeholder = document.getElementById(this.props.placeholderId);

    if (placeholder) {
      placeholder.classList.add('is-fading-out');
      let fadeDelay = setTimeout(() => {
        placeholder.classList.add('is-hidden');
        clearTimeout(fadeDelay);
      }, 750);
    }

    this.setState({
      hasPlaceholder: false
    });
  }
};

export default OldLearnersProgressBar;
