
import deckCard     from '_models/deckCard';
import model        from '_core/newModel';

const deckCardSorting = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId, key, direction) {
    return new Promise((resolve, reject) => {
      try {
        const path = `packs/${packId}/decks/${deckId}/cards/sort`;

        const data = {
          key: key,
          dir: direction,
        };

        model.create(path, data).then(() => {
          deckCard.index(packId, deckId).then(cardsData => { 
            model.publish('deck-cards:sorted', {
              packId: packId,
              deckId: deckId,
              cardIds: cardsData.cardIds,
              cards: cardsData.cards,
            });

            resolve(cardsData);
          }).catch(err => {
            throw err;
          });
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error('something went wrong during deckCard sort create. err:', err);
        reject(err);
      }
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default deckCardSorting;
