
import { PieChart }           from 'react-minimal-pie-chart';
import DynamicTooltipIcon     from '_views/shared/DynamicTooltipIcon';
import EventManager           from '@brainscape/event-manager';
import NumberHelper           from '_utils/NumberHelper';
import React                  from 'react';
import Stat                   from '_views/shared/Stat';
import UiHelper               from '_utils/UiHelper';
        
import {toClassStr}           from '_utils/UiHelper';
        
class MasteryCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMasteryPercentage: 0.0,
      masteryDisplayValue: 0,
      pieChartData: [],
      shouldShowPlaceholder: true,
      shouldRemovePlaceholder: false,
    };

    /*
      this.props:
        addClasses,
        animationDuration,
        background,
        decimalPoints,
        isMobileViewportSize,
        isSpectrum,
        label,
        lineWidth,
        mastery,
        masteryColor,
        postscript,
        ratingLevelCounts, ex. [47, 12, 16, 7, 1, 3]
        shouldCountUpMastery,
        shouldShowMasteryValue,
        shouldShowMasteryValueLabel,
        shouldAnimate,
        tooltipPosition,
    */

    this.defaultColors = {
      '0': '#29A5DC',
      '1': '#AA0080',
      '2': '#FF8A47',
      '3': '#FFDD00',
      '4': '#7FAE2E',
      '5': '#29A5DC',
      '6': '#586b86',
      '7': '#ECEFF1'
    };

    this.ratingLevelLabels = {
      '0': 'Unrated',
      '1': 'Level 1',
      '2': 'Level 2',
      '3': 'Level 3',
      '4': 'Level 4',
      '5': 'Level 5',
      '6': 'Future Progress',
      '7': 'Future Progress', // on light background
    };

    this.transitionPlaceholderDelay = null;
    this.removePlaceholderDelay = null;
    this.countUpMasteryInterval = null;
    this.TRANSITION_PLACEHOLDER_DELAY = 200;
    this.REMOVE_PLACEHOLDER_DELAY = 200;
    this.MASTERY_COUNT_UP_INTERVAL = 50;

    this._isMounted = false;
    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillMount() {
    this.updatePieChartData();
  }

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.managePlaceholder();
    this.displayMastery();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.mastery != prevProps.mastery) || (this.props.masteryColor != prevProps.masteryColor)) {
      this.updatePieChartData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.clearTimeoutsAndIntervals();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const hasTooltipClass = this.props.tooltipContent ? 'has-tooltip' : '';
    const classes = toClassStr(['mastery-circle', hasTooltipClass, this.props.addClasses]);

    if (this.state.pieChartData.length == 0) {
      return null;
    }

    return (
      <div 
        className={classes}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={(elem) => { this.elem = elem }} 
        >

        {this.renderPieChart()}

        {this.renderPlaceholder()}

        <div className="stat-and-postscript">
          {this.renderMasteryStat()}
          {this.renderPostscript()}
        </div>
      </div>
    );
  }

  renderPieChart() {
    if (UiHelper.isInternetExplorer()) {
      return null; // PieChart does not fully support IE as of 4/2019
    }

    return (
      <PieChart
        data={this.state.pieChartData.flat(0)}
        lineWidth={this.props.lineWidth || 15}
        animate={this.props.shouldAnimate}
        animationDuration={this.props.animationDuration || 2500}
        startAngle={-90}
      />
    );
  }

  renderPlaceholder() {
    if (!this.props.shouldAnimate || this.state.shouldRemovePlaceholder) {
      return null;
    }

    const isFadingClass = this.state.shouldShowPlaceholder ? '' : 'is-fading';
    const classes = toClassStr(['circle-placeholder', isFadingClass]);

    return (
      <div className={classes} />
    );
  }

  renderMasteryStat() {
    if (!this.props.shouldShowMasteryValue) {
      return null;
    }

    const mastery = this.props.mastery ? this.props.mastery : '';
    let decimalPoints = this.props.decimalPoints;
    decimalPoints = (decimalPoints === 0) ? 0 : decimalPoints || 1;

    const masteryPercentage = this.props.shouldCountUpMastery ? this.state.displayMasteryPercentage : NumberHelper.percentageStr(mastery, decimalPoints);

    return (

      <Stat
        background={this.props.background || 'light'}
        label="Mastery"
        isMobileViewportSize={this.props.isMobileViewportSize}
        value={`${masteryPercentage}%`}
        tooltipHeading="About the Mastery Circle"
        tooltipBody={this.renderMasteryDialogBody()}
        tooltipPosition={this.props.tooltipPosition || 'left'}
      />
    );
  }

  renderPostscript() {
    if (!this.props.postscript) {
      return null;
    }

    return (
      <h4 className="postscript">{this.props.postscript}</h4>
    );
  }

  renderMasteryDialogBody() {
    return (
      <>
        <p className='body-text'>
          This graph displays the weighted average of your self-assessed
          confidence for all of the cards you have studied in the decks that are
          presently selected. Rating all of the selected cards a 5 (blue) would bring you to 100%.
        </p>
        <p className='body-text'>
          <b>Tip:</b> To see your mastery of the entire class, select all decks.
        </p>
      </>
    );
  }

  renderMasteryCircleLabel() {
    if (!this.props.label) {
      return null;
    }

    return (
      <h4 className="mastery-circle-label">{this.props.label}</h4>
    );
  }


  /*
  ==================================================
   ADDITIONAL STATE MANAGEMENT
  ==================================================
  */

  updatePieChartData() {
    if (this.props.isSpectrum) {
      this.mapRatingLevelsToPie();
    } else {
      this.mapMasteryToPie();
    }
  }

  mapRatingLevelsToPie() {
    let ratingLevelCounts = this.props.ratingLevelCounts;   

    // populate data for arc representing levels 1-5
    let pieChartData = ratingLevelCounts.slice(1).map((ratingLevelCount, index) => {
      return {
        title: this.ratingLevelLabels[index + 1],
        key: index + 1,
        value: ratingLevelCount,
        color: this.defaultColors[index + 1],
      }
    });

    // calculate data for an arc representing headroom between current mastery and total potential mastery
    let cardTotal = 0;

    for (let i=1; i <= 5; i++) { // leave out unstudied cards by starting with 1
      cardTotal = cardTotal + ratingLevelCounts[i];
    }

    const headRoom = (cardTotal / this.props.mastery) - cardTotal;
    const headRoomColor = (this.props.background == 'light') ? this.defaultColors[7] : this.defaultColors[6];

    pieChartData[5] = {
      title: 'Future Progress',
      key: 6,
      value: headRoom,
      color: headRoomColor,
    }

    this.setState({
      pieChartData: pieChartData,
    });
  }

  mapMasteryToPie() {
    let pieChartData = [];
    const mastery = this.props.mastery || 0;
    const masteryColor = this.props.masteryColor || this.defaultColors[5];

    pieChartData[0] = {
      title: 'Overall Mastery',
      key: 1,
      value: mastery,
      color: masteryColor,
    }

    const headRoomColor = (this.props.background == 'light') ? this.defaultColors[7] : this.defaultColors[6];

    pieChartData[5] = {
      title: 'Future Progress',
      key: 2,
      value: 1 - mastery,
      color: headRoomColor,
    }

    this.setState({
      pieChartData: pieChartData,
    });
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  managePlaceholder() {
    if (this.props.shouldAnimate) {
      this.transitionPlaceholder();
    }
  }

  transitionPlaceholder() {
    clearTimeout(this.transitionPlaceholderDelay);
    const animationDuration = this.props.animationDuration || 0;

    this.transitionPlaceholderDelay = setTimeout(() => {
      clearTimeout(this.transitionPlaceholderDelay);
      this.setState({
        shouldShowPlaceholder: false,
      }, () => {
        this.removePlaceholder();
      });
    }, animationDuration + this.TRANSITION_PLACEHOLDER_DELAY);
  }

  removePlaceholder() {
    clearTimeout(this.removePlaceholderDelay);

    this.removePlaceholderDelay = setTimeout(() => {
      clearTimeout(this.removePlaceholderDelay);
      this.setState({
        shouldRemovePlaceholder: true,
      });
    }, this.REMOVE_PLACEHOLDER_DELAY);
  }

  displayMastery() {
    if (!this.props.shouldCountUpMastery) {
      this.setState({
        masteryDisplayValue: this.props.mastery,
      });
      return true;
    }

    this.countUpMastery();
  }

  countUpMastery() {
    // necessary to shift percentages into thousands so we can use integer math
    let base1000Mastery = this.props.mastery * 1000;
    let base1000MasteryPercentageStr = base1000Mastery.toString().split('.')[0];
    let base1000MasteryPercentage = parseInt(base1000MasteryPercentageStr);
    let base1000DisplayMasteryPercentage = 0;
    let increment;

    this.countUpMasteryInterval = setInterval(() => {

      if (base1000DisplayMasteryPercentage >= base1000MasteryPercentage) {
        this.setState({
          displayMasteryPercentage: (base1000MasteryPercentage / 10).toFixed(1),
        });
        clearInterval(this.countUpMasteryInterval);
      } else {
        this.setState({
          displayMasteryPercentage: (base1000DisplayMasteryPercentage / 10).toFixed(1),
        });

        increment = this.getIncrement(base1000DisplayMasteryPercentage, base1000MasteryPercentage);

        base1000DisplayMasteryPercentage = base1000DisplayMasteryPercentage + increment;
      }
    }, this.MASTERY_COUNT_UP_INTERVAL);
  }

  getIncrement(current, target) {
    // both current and target are expected to be integers between 0-1000

    let increment = 0;

    const cOnes = Math.floor(current % 10);
    const cTens = Math.floor(current / 10 % 10);
    const cHundreds = Math.floor(current / 100 % 10);

    const tOnes = Math.floor(target % 10);
    const tTens = Math.floor(target / 10 % 10);

    if (cHundreds >= 1 && cTens >= tTens) {
      return 100;
    }

    if (cTens >= 1 && cOnes >= tOnes) {
      return 10;
    }

    return 1;
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleMouseEnter = (e) => {
    if (this.props.tooltipContent) {
      this.triggerTooltipOpen();
    }
  }

  handleMouseLeave = () => {
    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    EventManager.emitEvent('tooltip:open', {
      addClasses: 'mastery-circle-tooltip',
      content: this.props.tooltipContent,
      elem: this.elem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  };

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.transitionPlaceholderDelay);
    clearTimeout(this.removePlaceholderDelay);
    clearInterval(this.countUpMasteryInterval);
  }
}

export default MasteryCircle;
