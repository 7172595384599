
import model from '_core/newModel';

const studyData = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */


  index(packId, deckId=null) {
    let path;

    if (deckId) {
      path = `study_data/packs/${packId}/decks/${deckId}`;
    } else {
      path = `study_data/packs/${packId}`;
    }

    return model.index(path);
  },

  postIndex(studyMixIds) { // generates an index of study data, but supplies params in body as a POST request
    const path = 'study_data';

    const data = {
      studyMixIds: studyMixIds,
    };

    return model.create(path, data);
  },

  update(packId, deckId, cardId, data) {
    const path = `study_data/packs/${packId}/decks/${deckId}/cards/${cardId}`;

    const userCardData = {
      level: data.level,
      prevLevel: data.prevLevel,
      ratedEpoch: data.ratedEpoch,
      ratedOn: data.ratedOn,
      timeSpent: data.timeSpent,
      uploaded: false,
    };

    return model.update(path, userCardData);
  }



  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default studyData;
