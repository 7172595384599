
import TypeHelper from '_utils/TypeHelper';
import userLocalStore  from '_models/userLocalStore';

const MessagingHelper = {

  /*
  ==================================================
   BANNERS
  ==================================================
  */

  getBannerViews(userId) {
    const localStore = userLocalStore.getOrCreateUserStore(userId);
    let views = localStore.bannerViews;
    views = TypeHelper.isObject(views) ? views : {};

    return views;
  },

  hasClosedBanner(userId, bannerId) {
    let views = this.getBannerViews(userId);
    let bannerView = views[bannerId] || {};
    return !!bannerView.closed; // !! coerces value to boolean
  },

  hasSeenBanner(userId, bannerId) {
    let views = this.getBannerViews(userId);
    let bannerView = views[bannerId] || {};
    return !!bannerView.seen; // !! coerces value to boolean
  },

  markBannerAsClosed(userId, bannerId) {
    let views = this.getBannerViews(userId);
    let bannerView = views[bannerId] || {};
    bannerView.closed = true;

    views[bannerId] = bannerView;

    // this.updateBannerViews(userId, views);
  },

  markBannerAsSeen(userId, bannerId) {
    let views = this.getBannerViews(userId);
    let bannerView = views[bannerId] || {};
    bannerView.seen = true;

    views[bannerId] = bannerView;

    // this.updateBannerViews(userId, views);
  },


  /*
  ==================================================
   FLASH MESSAGES
  ==================================================
  */

  getFlashMessageViews(userId) {
    const localStore = userLocalStore.getOrCreateUserStore(userId);
    let views = localStore.flashMessageViews;
    views = TypeHelper.isObject(views) ? views : {};

    return views;
  },

  hasSeenFlashMessage(userId, messageId) {
    let views = this.getFlashMessageViews(userId);
    let messageView = views[messageId] || {};
    return !!messageView.seen; // !! coerces value to boolean
  },

  hasClosedFlashMessage(userId, messageId) {
    let views = this.getFlashMessageViews(userId);
    let messageView = views[messageId] || {};
    return !!messageView.closed; // !! coerces value to boolean
  },

  markFlashMessageAsSeen(userId, messageId) {
    let views = this.getFlashMessageViews(userId);
    let messageView = views[messageId] || {};
    messageView.seen = true;

    views[messageId] = messageView;

    this.updateFlashMessageViews(userId, views);
  },

  markFlashMessageAsClosed(userId, messageId) {
    let views = this.getFlashMessageViews(userId);
    let messageView = views[messageId] || {};
    messageView.closed = true;

    views[messageId] = messageView;

    this.updateFlashMessageViews(userId, views);
  },
}

export default MessagingHelper;
