
import React            from 'react';
import Modal            from '_views/shared/Modal';
import VideoEmbed       from '_views/shared/VideoEmbed';

import {toClassStr}     from '_utils/UiHelper';

class VideoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }

    /*
      this.props:
        addClasses,
        isOpen,
        onClosed,
        onCloseRequest,
        shouldAutoPlay,
        videoUrl
    */
  }

  render() {
    const classes = toClassStr(['video-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={this.props.onCloseRequest}
        size="large"
      >
        <VideoEmbed 
          shouldAutoPlay={this.props.shouldAutoPlay}
          videoUrl={this.props.videoUrl}
        />
      </Modal>
    );
  }
};

export default VideoModal;
