
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import UiHelper                  from '_utils/UiHelper';

class GroupLicenseRequiredModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    /*
      this.props:
        addClasses,
        isOpen,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <Modal
        addClasses="group-license-required-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Unlock Stats and Reports</div>
        <div className="modal-message">Stats and reports require a group license. Please choose your organization type:</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="School"
            onClick={this.handleSchoolButtonClick}
          />

          <PillButton
            addClasses="resolve-modal-button"
            label="Company"
            onClick={this.handleCompanyButtonClick}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCompanyButtonClick = () => {
    UiHelper.openInNewTab('https://share.hsforms.com/1nTvQA_8oT82t7-1_dbIy_A1fype', 'license');
  }

  handleSchoolButtonClick = () => {
    UiHelper.openInNewTab('https://share.hsforms.com/1qLB8v_qTQS-gNXihGtFriA1fype', 'license');
  }
}

export default GroupLicenseRequiredModal;
