
import DeckModel              from '_legacy-models/DeckModel';
import DynamicTooltipIcon     from '_views/shared/DynamicTooltipIcon';
import Modal                  from '_views/shared/Modal';
import PackModel              from '_legacy-models/PackModel';
import PillButton             from '_views/shared/PillButton';
import Pulldown               from '_views/shared/Pulldown';
import React                  from 'react';
import Spinner                from '_views/shared/Spinner';
import UiHelper               from '_utils/UiHelper';

import {toClassStr} from '_utils/UiHelper';

class CopyAndPlaceDeckModalSet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      packRows                    : null,
      packsPulldownOpen           : false,
      destinationPackId           : null,
      // isConfirmationModalOpen     : false,
      isProcessing                : false,
      // isLoadingPacks              : true,
      // shouldOpenConfirmationModal : false,
    };

    /*
      this.props:
        addClasses,
        deckId,
        packId,
        isOpen,
        onCloseRequest,
        onSubmit,
        userEditablePacks,
    */

    this.Deck = new DeckModel;
    this.Pack = new PackModel;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    // if (this.props.isOpen) {
    //   this.getUserEditablePacks();
    // }
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //   this.getUserEditablePacks();
    // }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const classes = toClassStr(['modal-set', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderInputModal()}
        {/* {this.renderConfirmationModal()} */}
      </div>
    );
  }

  renderInputModal() {
    const isDisabled = (this.state.destinationPackId == null || this.state.destinationPackId == -1);

    return (
      <Modal
        addClasses="copy-and-place-deck-modal"
        isOpen={this.props.isOpen}
        // onClosed={this.handleInputModalClosed}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Copy and Place Deck</div>

        <div className="modal-guidance">You can copy decks into another class in your library.</div>

        {this.renderNoPacksWarning()}
        {this.renderPackSelector()}

        <div className="modal-actions">
          <PillButton
            addClasses="cancel-modal-button"
            label="Cancel"
            onClick={this.handleCancelButtonClick}
          />
          <PillButton
            addClasses="resolve-modal-button"
            isDisabled={isDisabled}
            isProcessing={this.state.isProcessing}
            label="Copy and Place"
            onClick={this.handleCopyAndPlaceButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderNoPacksWarning = () => {
    if (this.props.userEditablePacks?.length > 0) { 
      return null; 
    }

    return (
      <p className="modal-message">
        There are no classes in your library into which you can place this deck.
        Create a new class first, if you wish to continue.
      </p>
    );
  }

  renderPackSelector() {
    if (this.props.userEditablePacks?.length < 1) { 
      return null; 
    }

    return (
      <div className="pack-selector">
        <div className='modal-message'>
          Choose a class from your library into which to place the deck:
        </div>

        <div className="pulldown-and-tooltip">
          {this.renderPacksPulldown()}

          <DynamicTooltipIcon 
            addClasses="copy-place-info-tooltip"
            body={this.renderCopyAndPlaceDialogBody()}
            hasDismissButton={true}
            position="bottom"
          />
        </div>
      </div>
    );
  }

  renderCopyAndPlaceDialogBody() {
    return (
      <>
        <p className="body-text">This is a list of the classes in your library where you have edit access.</p>
        <p className="body-text">If you don't see the class you're looking for, ask the admin to give you edit access to the class, or create your own new class.</p>
      </>
    );
  }

  renderPacksPulldown() {
    if (this.state.isLoadingPacks) {
      return (
        <Spinner />
      );
    }

    return (
      <Pulldown
        addClasses='packs-pulldown'
        isOpen={this.state.packsPulldownOpen}
        options={this.props.userEditablePacks}
        fieldName='pack'
        placeholderText='Select destination class...'
        selectedValue={this.state.destinationPackId || '-1'}
        shouldSuppressNullOption={false}
        onButtonClick={this.handlePacksPulldownButtonClick}
        onOptionClick={this.handlePacksPulldownOptionClick}
        onOutsideClick={this.handlePacksPulldownOutsideClick}
      />
    );
  }

//   renderConfirmationModal() {
//     return (
//       <Modal
//         addClasses="copy-deck-confirmation-modal"
//         isOpen={this.state.isConfirmationModalOpen}
//         onCloseRequest={this.handleCloseConfirmationModalRequest}
//       >
// 
//         <div className="modal-title">Deck Copy and Placement Started</div>
// 
//         <div className="modal-message">This operation can take a while. We will send you an e-mail to notify you when the process is complete. Feel free to refresh this page to check as well.</div>
// 
//         <div className="modal-actions">
// 
//           <PillButton
//             addClasses="resolve-modal-button"
//             label="Ok"
//             onClick={this.handleCloseConfirmationModalRequest}
//           />
//         </div>
//       </Modal>
//     );
//   }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelButtonClick = () => {
    this.props.onCloseRequest();
  }

  // handleCloseConfirmationModalRequest = () => {
  //   this.setState({
  //     isConfirmationModalOpen: false,
  //     shouldOpenConfirmationModal: false,
  //   })
  // }

  handleCopyAndPlaceButtonClick = () => {
    this.setState({
      isProcessing: true,
    });

    this.props.onSubmit(this.state.destinationPackId);

//     this.Deck.copyAndPlace({
//       id: this.props.deckId,
//       dstPackId: this.state.destinationPackId,
//       srcPackId: this.props.packId,
//       done: (data) => {
//         this.setState({
//           isProcessing: false,
//         });
// 
//         this.showConfirmationModal();
//       },
//       fail: (message, responseJSON) => {
//         const failureMessage = responseJSON.message || message;
// 
//         UiHelper.handleError({
//           operation: 'Deck Copy and Place',
//           errorMessage: "Something went wrong: " + failureMessage,
//           errorObject: responseJSON,
//           notifyUser: true,
//         });
//       }
//     });
  }

  handlePacksPulldownButtonClick = () => {
    this.setState({
      packsPulldownOpen: !this.state.packsPulldownOpen,
    });
  }

  handlePacksPulldownOptionClick = (packId) => {
    this.setState({
      packsPulldownOpen: false, 
      destinationPackId: packId,
    });
  }

  handlePacksPulldownOutsideClick = () => {
    this.setState({
      packsPulldownOpen: false,
    });
  }

  // handleInputModalClosed = () => {
  //   if (this.state.shouldOpenConfirmationModal) {
  //     this.setState({
  //       isConfirmationModalOpen: true,
  //     })
  //   }
  // }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  // closeModal = () => {
  //   this.props.onCloseRequest();
  // }

//   getUserEditablePacks = () => {
//     let packRows = [];
// 
//     this.Pack.getEditables({
//       done: (data) => {
//         let destinationPackId = null;
//         packRows = data.packs.map((pack, index) => {
//           if (pack.packID == this.props.packId) {
//             destinationPackId = this.props.packId;
//           }
//           return {id: pack.packID, label: pack.name}
//         });
// 
//         this.setState({
//           isLoadingPacks: false,
//           packRows: packRows,
//           destinationPackId: destinationPackId,
//         });
//       },
//       fail: (message, responseJSON) => {
//         UiHelper.handleError({
//           operation: 'Get Editable Packs',
//           errorMessage: 'Could not read the list of classes',
//           errorObject: responseJSON,
//           notifyUser: true,
//         });
//         this.closeModal();
//       }
//     });
//   }

  // showConfirmationModal() {
  //   this.setState({
  //     shouldOpenConfirmationModal: true,
  //   }, () => {
  //     this.props.onCloseRequest();
  //     // this will start animation of closing of input modal, upon its animation and close, the input modal's onClosed handler will fire. That handler then opens the confirmation modal (because this.state.shouldOpenConfirmationModal is true.)
  //   });
  // }
}

export default CopyAndPlaceDeckModalSet;
