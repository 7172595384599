// substrate and utils
import EventManager               from '@brainscape/event-manager';
import GaHelper                   from '_utils/GaHelper';
import NumberHelper               from '_utils/NumberHelper';
import PropTypes                  from 'prop-types';
import React                      from 'react';
import StudyHelper                from '_utils/StudyHelper';
import UiHelper                   from '_utils/UiHelper';
import { toClassStr }             from '_utils/UiHelper';

// sub-components
import userLocalStore             from '_models/userLocalStore';

// top z-level view controllers
import ImageViewerController      from '_controllers/ImageViewerController';

// sub-components
import DeckBrowseSection          from '_views/deck-detail/desktop/browse/DeckBrowseSection';
import DeckDetailHeader           from '_views/deck-detail/desktop/DeckDetailHeader';
import DeckEditSection            from '_views/deck-detail/desktop/edit/DeckEditSection';
import DeckPreviewSection         from '_views/deck-detail/desktop/preview/DeckPreviewSection';
import LoadingOverlay             from '_views/shared/LoadingOverlay';
import PillButton                 from '_views/shared/PillButton';

const PT = {
  addClasses:                   PropTypes.string,
  cardClipboard:                PropTypes.object,
  currentCardId:                PropTypes.node,
  currentDeck:                  PropTypes.object,
  currentPack:                  PropTypes.object,
  currentTabId:                 PropTypes.string,
  currentUser:                  PropTypes.object,
  isLoadingDeck:                PropTypes.bool,
  isLoadingDeckCards:           PropTypes.bool,
  isLoadingPack:                PropTypes.bool,
  isLoadingTrendingPacks:       PropTypes.bool,
  isLoadingUser:                PropTypes.bool,
  isLoadingUserPacks:           PropTypes.bool,
  isPaginatingDeckCards:        PropTypes.bool,
  isMobileViewportSize:         PropTypes.bool,
  sidebarMode:                  PropTypes.string,
};


class DeckDetailBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageDetailBannerOpen: false,
      isPageDetailFlashMessageOpen: false,
      isPrivateClassOverlayOpen: false,
      packSelectedState: this.getPackSelectedState(),
      shouldShowAddMetadataPrompt: false,
      shouldShowNewLearnerPrompt: false,
    };

    this.events = new EventManager();

    this.tabs = {
      preview: { id: 'preview', label: 'Preview', count: 0},
      edit: { id: 'edit', label: 'Edit', count: 0},
      browse: { id: 'browse', label: 'Browse', count: 0},
    };

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['deck-detail-body', this.props.addClasses,]);
    const pack = this.props.currentPack;

    return (
      <div className={classes}>
        <ImageViewerController />

        <DeckDetailHeader
          currentCardId={this.props.currentCardId}
          currentDeck={this.props.currentDeck}
          currentPack={this.props.currentPack}
          currentTabId={this.props.currentTabId}
          currentUser={this.props.currentUser}
          isMobileViewportSize={this.props.isMobileViewportSize}
          isPaginatingDeckCards={this.props.isPaginatingDeckCards}
          tabs={this.getTabsAndCounts()}
        />

        <div className="detail-sections" id="detail-scroll-element">
          {this.renderDeckPreviewSection()}
          {this.renderDeckEditSection()}
          {this.renderDeckBrowseSection()}
        </div>
      </div>
    );
  }

  renderDeckPreviewSection() {
    if (this.props.currentTabId != 'preview') {
      return null;
    }

    return (
      <DeckPreviewSection
        cardClipboard={this.props.cardClipboard}
        currentCardId={this.props.currentCardId}
        currentDeck={this.props.currentDeck}
        currentPack={this.props.currentPack}
        currentUser={this.props.currentUser}
        isMobileViewportSize={this.props.isMobileViewportSize}
        sidebarMode={this.props.sidebarMode}
      />
    );
  }

  renderDeckEditSection() {
    if (this.props.currentTabId != 'edit') {
      return null;
    }

    return (
      <DeckEditSection
        cardClipboard={this.props.cardClipboard}
        currentCardId={this.props.currentCardId}
        currentDeck={this.props.currentDeck}
        currentPack={this.props.currentPack}
        currentUser={this.props.currentUser}
        isLoadingDeckCards={this.props.isLoadingDeckCards}
        isPaginatingDeckCards={this.props.isPaginatingDeckCards}
        isMobileViewportSize={this.props.isMobileViewportSize}
        sidebarMode={this.props.sidebarMode}
      />
    );
  }

  renderDeckBrowseSection() {
    if (this.props.currentTabId != 'browse') {
      return null;
    }

    const currentDeckCards = this.props.currentDeck.cards;
    const currentDeckCardIds = this.props.currentDeck.cardIds;
    const currentCardIndex = Math.max(currentDeckCardIds.indexOf(this.props.currentCardId), 0);

    const currentCard = currentDeckCards[currentCardIndex];

    return (
      <DeckBrowseSection
        cards={this.props.currentDeck.cards}
        currentCard={currentCard}
        currentCardId={this.props.currentCardId}
        currentUser={this.props.currentUser}
        deck={this.props.currentDeck}
        isMobileViewportSize={this.props.isMobileViewportSize}
        isUserBscAdmin={this.props.currentUser.flags.isBscAdmin}
        isUserPro={this.props.currentUser.flags.isPro}
        pack={this.props.currentPack}
        sidebarMode={this.props.sidebarMode}
        userId={this.props.currentUser.userId}
      />
    );
  }

  renderPrivateClassOverlay() {
    if (!this.state.isPrivateClassOverlayOpen) {
      return null;
    }

    return (
      <LoadingOverlay
        addClasses="private-class-overlay"
        isOpen={this.state.isPrivateClassOverlayOpen}
        message={this.renderPrivateClassMessage()}
        shouldTransitionIn={true}
      />
    );
  }

  renderPrivateClassMessage() {
    return (
      <div className="private-class-message">
        <div className="private-class-heading">Private Class</div>

        {this.renderPrivateClassText()}

        <PillButton
          addClasses="upgrade-to-pro-button"
          label="Upgrade To Pro"
          onClick={this.handleUpgradeButtonClick}
        />
      </div>
    );
  }

  renderPrivateClassText() {
    if (this.props.currentPack.isUserInvited) {
      return (
        <div className="private-class-text">
           <h1>HIT 1</h1>
          You were invited to <strong>{this.props.currentPack.name}</strong>. It is a
          Private Class which requires Pro Access to study.
        </div>
      );
    }

    return (
      <div className="private-class-text">
        <strong>{this.props.currentPack.name}</strong> is a private class.
        <br/>
        To study a private class, you need <strong>BOTH</strong> the creator of this class to give you explicit full study access
        <strong> AND</strong> Brainscape Pro.
      </div>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBannerOpenRequested = (banner) => {
    if (!this._isMounted) {
      return false;
    }

    if (banner.block == 'page-detail') {
      this.setState({
        isBannerOpen: true,
      });
    }
  };

  handleBannerCloseRequested = (banner) => {
    if (!this._isMounted) {
      return false;
    }

    if (banner.block == 'page-detail') {
      this.setState({
        isBannerOpen: false,
      });
    }
  };

  handleFlashMessageOpenRequested = (flashMessage) => {
    if (!this._isMounted) {
      return false;
    }

    if (flashMessage && flashMessage.block == 'page-detail') {
      this.setState({
        isFlashMessageOpen: true,
      });
    }
  };

  handleFlashMessageCloseRequested = (flashMessage) => {
    if (!this._isMounted) {
      return false;
    }

    if (flashMessage && flashMessage.block == 'page-detail') {
      this.setState({
        isFlashMessageOpen: false,
      });
    }
  };

  handleNewLearnerBubbleClosed = () => {
    this.setState(
      {
        shouldShowNewLearnerPrompt: false,
      },
      () => {
        this.setHasSeenNewLearnerBubblePref(true);
      },
    );
  };

  handlePackCheckboxClick() {
    if (this.state.packSelectedState != 'none') {
      this.unselectAllPackDecks();
    } else {
      this.selectAllStudyablePackDecks();
    }

    this.props.onDeckSelectionChange(() => {
      this.setState({
        packSelectedState: this.getPackSelectedState(),
      });
    });
  }

  handlePackDeleted = (callback) => {
    EventManager.emitEvent('page-loading-overlay:open');

    this.props.onPackDeleted(() => {
      if (callback) {
        callback();
      }

      EventManager.emitEvent('page-loading-overlay:close');
    });
  };

  handlePackUpdated = (callback) => {
    EventManager.emitEvent('page-loading-overlay:open');

    this.props.onPackUpdated(() => {
      if (callback) {
        callback();
      }

      EventManager.emitEvent('page-loading-overlay:close');
    });
  };

  handleStudyRequest = (e) => {
    if (e) {
      e.stopPropagation();
    }

    const pack = this.props.currentPack;
    const studyMixType = this.getPackStudyMixType();
    const selectedDeckIds = pack.localDeckTransforms.selections.ids;

    if (pack.transformedStats.deckCount < 1) {
      this.triggerPackHasNoDecksModalOpen();
      return false;
    }

    if (pack.transformedStats.cardCount < 1) {
      this.triggerPackHasNoCardsModalOpen();
      return false;
    }

    if (selectedDeckIds.length < 1) {
      this.triggerInfoModalOpen({
        message:
          'Study Mix is disabled because there are no decks checked below. Check one or more decks (blue circles) to include them in your Study Mix.',
        resolveButtonText: 'Ok',
        shouldSuppressCancelButton: true,
        title: 'Study Mix Disabled',
      });

      return null;
    }

    const userId = this.props.currentUser.userId;
    const packId = this.props.currentPack.packId;
    const packDeckIds = selectedDeckIds;

    if (this.props.onStudyRequest) {
      this.props.onStudyRequest();
    }

    EventManager.emitEvent('page-loading-overlay:open');

    let studyMixUrl = StudyHelper.getPackStudyMixUrl(
      userId,
      this.props.currentPack,
      packDeckIds,
    );

    // TODO: This is a temporary measure only needed while needing to support two different library experiences: the legacy and the new dashboard. Remove this functionality when we are 100% transitioned to the new dashboard
    studyMixUrl = studyMixUrl + '&origin=dashboard';

    GaHelper.fireGaEvent('Study_Mix', studyMixType, packDeckIds.length);
    GaHelper.followUrlDelayed(studyMixUrl);
  };

  handleUpgradeButtonClick = () => {
    this.triggerUpgradeModalOpen('Unlocking a Private Class', 'private_access');
  };


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerPackHasNoDecksModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message:
        'Before you can study this class, you or the Class creator must add at least one deck with at least one card to the class.',
      resolveButtonText: 'Got it',
      title: 'This Class Has No Decks',
    });
  };

  triggerPackHasNoCardsModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message:
        'Before you can study this class, you or the Class creator must add at least one card to the class.',
      resolveButtonText: 'Got it',
      title: 'This Class Has No Cards',
    });
  };

  triggerUpgradeModalOpen(desiredAction, paywall) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall: paywall,
    });
  }

  /*
  ==================================================
   ADDITIONAL STATE MANAGEMENT
  ==================================================
  */

  getPackSelectedState() {
    const currentPack = this.props.currentPack;

    let deckCount = currentPack?.stats?.deckCount;
    let selectCount = currentPack?.localDeckTransforms?.selections.ids?.length;

    if (selectCount == 0) {
      return 'none';
    }

    if (selectCount == deckCount) {
      return 'all';
    }

    return 'mixed';
  }

  getPackStudyMixType() {
    return userLocalStore.getPackStudyMixType(this.props.currentUser.userId, this.props.currentPack);
  }

  getOrderedPackDeckIds() {
    const packDeckElems = document.querySelectorAll(
      '.deck-list .deck-row:not(.placeholder)',
    );
    let packDeckIds = [];

    for (let i = 0; i < packDeckElems.length; ++i) {
      packDeckIds.push(packDeckElems[i].id);
    }

    return packDeckIds;
  }

  selectAllStudyablePackDecks() {
    const pack = this.props.currentPack;
    const userPackDecks = pack.userPackDecks;
    const studyableDeckIds = pack.deckIds.filter((deckId) => {
      return userPackDecks[deckId].isStudyable;
    });

    userLocalStore.selectPackDecks(
      this.props.userId,
      pack.packId,
      studyableDeckIds,
    );
    this.props.onDeckSelectionChange();
  }

  unselectAllPackDecks() {
    const pack = this.props.currentPack;
    userLocalStore.unselectPackDecks(
      this.props.userId,
      pack.packId,
      pack.deckIds,
    );
    this.props.onDeckSelectionChange();
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  canEdit = () => {
    return (['admin', 'edit'].indexOf(this.props.currentPack.permission) != -1);
  }

  managePrivateClassOverlay() {
    const pack = this.props.currentPack;

    if (!pack.isPrivate) {
      return false;
    }
    if (this.props.currentuser.flags.isPro) {
      return false;
    }
    if (pack.isUserBscAdmin) {
      return false;
    }

    this.setState(
      {
        isPrivateClassOverlayOpen: true,
      },
      () => {
        this.disableDeckDetailBodyClickEvents();
      },
    );
  }

  disableDeckDetailBodyClickEvents() {
    document
      .querySelector('.deck-detail-body')
      .addEventListener('click', this.filterForUpgradeButtonClick, true);
  }

  filterForUpgradeButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      e.target
        .closest('.pill-button')
        .classList.contains('upgrade-to-pro-button')
    ) {
      UiHelper.navigate('/pricing?paywall=private_access');
    }
  }

  enableDeckDetailBodyClickEvents() {
    document
      .querySelector('.deck-detail-body')
      .removeEventListener('click', this.filterForUpgradeButtonClick, true);
  }

  getHasSeenNewLearnerBubblePref() {
    return !!userLocalStore.getUserLocalPref(
      this.props.currentPack.userId,
      'hasSeenNewLearnerBubble',
    );
  }

  getTabsAndCounts() {
    if (!this.canEdit()) {
      delete this.tabs.edit;
    }

    if (!this.props.currentDeck) {
      return this.tabs;
    }

    let updatedTabs = this.tabs;
    const currentDeck = this.props.currentDeck;
    const cardCount = (this.props.isPaginatingDeckCards) ? '--' : currentDeck.cards.length;

    updatedTabs.preview.count = NumberHelper.displayNumber(cardCount);
    updatedTabs.browse.count = NumberHelper.displayNumber(cardCount);

    if (updatedTabs.edit) {
      updatedTabs.edit.count = NumberHelper.displayNumber(cardCount);
    }

    return updatedTabs;
  }

  setHasSeenNewLearnerBubblePref(pref) {
    userLocalStore.updateUserLocalPrefs(
      this.props.currentPack.userId,
      'hasSeenNewLearnerBubble',
      pref,
    );
  }

  setupNewLearnerPrompt() {
    const shouldShowNewLearnerPrompt =
      !this.props.currentPack.isMarket &&
      !!this.props.currentPack.learners &&
      this.props.currentPack.learners.length < 2 &&
      !this.getHasSeenNewLearnerBubblePref();

    this.setState({
      shouldShowNewLearnerPrompt: shouldShowNewLearnerPrompt,
    });
  }

  setupAddMetadataPrompt() {
    let shouldShowAddMetadataPrompt = false;
    const pack = this.props.currentPack;
    const metadata = this.props.currentPack.metadata;

    if (pack.isUgs && Object.keys(metadata).length > 0) {
      if (
        pack.permissionLevel == 'admin' &&
        !(this.hasRoleMetadata() && this.hasPurposeMetadata())
      ) {
        shouldShowAddMetadataPrompt = true;
      }

      if (pack.permissionLevel == 'edit' && !this.hasRoleMetadata()) {
        shouldShowAddMetadataPrompt = true;
      }
    }

    this.setState({
      shouldShowAddMetadataPrompt: shouldShowAddMetadataPrompt,
    });
  }

  // TODO: Move these next two methods to PackMetadata model
  hasPurposeMetadata = () => {
    const purposes = this.props.currentPack.metadata.purposes;
    const purposeKeys = Object.keys(purposes);

    for (let i = 0; i < purposeKeys.length; i++) {
      if (purposes[purposeKeys[i]].value) {
        return true;
      }
    }

    return false;
  };

  hasRoleMetadata = () => {
    const global = this.props.currentPack.metadata.global;

    if (!(global && global.role && global.role.value)) {
      return false;
    }

    return !(global.role.value == 'unspecified');
  };
}

DeckDetailBody.propTypes = PT;

export default DeckDetailBody;