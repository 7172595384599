
import EventManager           from '@brainscape/event-manager';
import packLeaderboard         from '_models/packLeaderboard';


const currentPackLeaderboardConcern = {

  // get checks session store for cached objects, publishes immediately to allow display, then refreshes cached copy by conducting fetches against the API
  get(pack) {
    const currentPackId = pack.packId;
    let currentPack = {...pack};

    return new Promise((resolve, reject) => {
      try {
        const currentPackLeaderboard = packLeaderboard.getCachedIndex(currentPackId);

        if (currentPackLeaderboard) {
          currentPack.leaderboard = currentPackLeaderboard;

          const currentPackLeaderboardData = {
            currentPack: currentPack,
          };

          this.publish(currentPackLeaderboardData);
        }

        this.fetch(currentPack).then(currentPackLeaderboardData => {
          resolve(currentPackLeaderboardData);
        }).catch(err => {
          console.error('Problem with packLeaderboard index. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch(pack) {
    const currentPackId = pack.packId;
    let currentPack = {...pack};

    return new Promise((resolve, reject) => {
      try {
        packLeaderboard.index(currentPackId).then(leaderboardData => {
          currentPack.leaderboard = leaderboardData.users;
          currentPack.leaderboardOrder = leaderboardData.order;
          currentPack.metadata = null;
          currentPack.learners = null;
          currentPack.learnerIds = null;
          currentPack.transformedLearnerIds = null;

          packLeaderboard.setCachedIndex(currentPack.packId, currentPack.leaderboard);

          const currentPackLeaderboardData = {
            currentPack: currentPack,
          };

          this.publish(currentPackLeaderboardData);
          resolve(currentPackLeaderboardData);
        }).catch(err => {
          console.error('Problem with packLeaderboard index. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  publish(currentPackLeaderboardData) {
    EventManager.emitEvent('pack-leaderboard:retrieved', currentPackLeaderboardData);
  },
};

export default currentPackLeaderboardConcern;
