
import CopyToClipboardLink       from '_views/shared/CopyToClipboardLink';
import Modal                     from '_views/shared/Modal';
import PermissionsTooltip        from '_views/shared/PermissionsTooltip';
import PillButton                from '_views/shared/PillButton';
import RadioButtonsField         from '_views/shared/RadioButtonsField';
import React                     from 'react';
import TextField                 from '_views/shared/TextField';

class ShareCardByLinkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isInClipboard                   : false,
      // sharingScope                    : (this.props.pack.permission == 'admin' || this.props.pack.flags.isPrivate) ? 'full study' : 'preview',
      shouldResetCopyToClipboardLink  : false,
    };

    /*
      this.props:
        addClasses,
        isOpen,
        isProcessing,
        isSingular,  // not part of a modal set
        onBackRequest,
        onCloseRequest,
        onClosed,
        pack,
    */

    // this.scopeOptions = [
    //   {label: 'Full Study', value: 'full study', className: 'full-study-option'},
    //   {label: 'Preview', value: 'preview', className: 'preview-option'},
    // ];

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const {packId, deckId, cardId} = this.props;
    const origin = window.location?.origin || 'https://www.brainscape.com';
    const shareCardLink = `${origin}/flashcards/${deckId}/packs/${packId}?cardId=${cardId}`;

    return (
      <Modal
        addClasses="share-card-by-link-modal"
        isOpen={this.props.isOpen}
        onClosed={() => this.props.onClosed()}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Share Card By Link</div>

        {/*this.renderGuidance()*/}

        <TextField 
          addClasses="share-card-link"
          isReadOnly={true}
          value={shareCardLink}
        />

        <div className="share-parameters">
          {/*this.renderScopeOptions()*/}

          <CopyToClipboardLink
            isDisplayedInInputElem={true}
            inputElemSelector='.share-card-link .text-input'
            onResetLink={this.handleCopyToClipboardLinkReset}
            shouldResetLink={this.state.shouldResetCopyToClipboardLink}
          />

        </div>

        <div className="modal-aside">See our <a href="/marketing/terms.html" target="_blank" rel="nofollow noopener">terms</a> for information on sharing.</div>

        <div className="modal-actions">

          {/*this.renderBackButton()*/}

          <PillButton
            addClasses="resolve-modal-button"
            label="Done"
            onClick={() => this.handleDoneButtonClick()}
          />
        </div>
      </Modal>    
    );
  }


//   renderGuidance() {
//     const usersLabel = (this.props.pack.flags.isPrivate) ? "Pro Users" : "anyone";
//     const packName = (this.props.pack.flags.isPrivate) ? this.props.pack.name + ' (a private Class)' : this.props.pack.name;
//     const sharingScope = (this.props.pack.flags.isPrivate) ? 'full study' : this.state.sharingScope;
// 
//     return (
//       <div className="modal-guidance">
//         The custom link below will give {usersLabel} {sharingScope} access to <span className="pack-name">{packName}</span>. You can paste it anywhere, including on your website, blog, or in social media.
//       </div>
//     );
//   }

//   renderScopeOptions() {
//     if (this.props.pack.permission != 'admin' || this.props.pack.flags.isPrivate) {
//       return <div className="sharing-scope" />;
//     }
// 
//     return (
//       <div className="permission-options">
//         <RadioButtonsField 
//           addClasses="sharing-scope"
//           name="sharingScope"
//           value={this.state.sharingScope}
//           buttons={this.scopeOptions}
//           isInline={true}
//           onClick={this.handleSharingScopeClick}
//         />
// 
//         <PermissionsTooltip 
//           isPackPrivate={this.props.pack.flags.isPrivate}
//           tooltipPosition="top"
//           isMobileViewportSize={this.props.isMobileViewportSize}
//         />
//       </div>
//     );
//   }
// 
//   renderBackButton() {
//     if (this.props.isSingular) {
//       return null;
//     }
// 
//     return (
//       <div className="cancel-modal-text-button back" onClick={(e) => this.handleBackButtonClick(e)}>Back</div>
//     );
//   }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

//   handleBackButtonClick(e) {
//     e.stopPropagation();
//     
//     this.setState({
//       isInClipboard: false,
//     })
// 
//     this.props.onBackRequest();
//   }

  handleCopyToClipboardLinkReset = () => {
    this.setState({
      shouldResetCopyToClipboardLink: false,
    })
  }

  handleDoneButtonClick() {
    this.setState({
      isInClipboard: false,
    })

    this.props.onCloseRequest();
  }

  // handleSharingScopeClick = (value) => {
  //   this.setState({
  //     sharingScope: value,
  //     shouldResetCopyToClipboardLink: true,
  //   });
  // }
}

export default ShareCardByLinkModal;
