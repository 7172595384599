
import EventManager                from '@brainscape/event-manager';
import ImageViewerController       from '_controllers/ImageViewerController';
import PillButton                  from '_views/shared/PillButton';
import React                       from 'react';
import SmartCard                   from "_views/shared/smart-cards/SmartCard";
import StudyCardBar                from '_study/StudyCardBar';
import ConfidenceButtons           from '_views/shared/smart-cards/ConfidenceButtons';
import RevealCardFaceButton        from '_views/shared/smart-cards/RevealCardFaceButton';
import {BrowseButton, CloseButton} from '_views/shared/IconButton';

import pack                        from '_models/pack';
import packDeck                    from '_models/packDeck';

import {toClassStr} from '_utils/UiHelper';

class StudyCardTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      areCardsDealing:                false,
      areCardsLoaded:                 false,
      areCardsPlaced:                 false,
      baseFontSize:                   30,
      currentCardMode:                'display',
      currentSound:                   null,
      currentStepCard:                null,
      currentStepConfidenceLevel:     0,
      deckCards:                      null,
      imageViewerUrl:                 '',
      isDealingNewCard:               false,
      isDeckEditable:                 false,
      isProcessingPreviewCardsAction: false,
      previousStepCard:               null,
      previousStepConfidenceLevel:    0,
      shouldCaptureKeyboardInput:     true,
      shouldRemoveLoadingOverlay:     false,
      shouldShowLoadingOverlay:       false,
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        currentUser,
        dashboardPath,
        isLegacyAnimation,
        isAnswerShowing,
        isAtCheckpoint,
        isAudioMuted,
        isClosingStudyMix,
        isFtse,
        isMobileViewportSize,
        isUserPro,
        mixName,
        onAnswerCardbarClick,
        onCardFlipRequest,
        onCloseStudyMixRequest,
        onConfidenceRating,
        onEditCardRequest,
        onQuestionCardbarClick,
        shouldPulseAnswerCardbar,
        shouldPulseQuestionCardbar,
        stepCard,
        stepConfidenceLevel,
        stepData,
        stepDeckName,
        stepDeckCardCount,
        stepDeckCardIndex,
    */

    this.events = new EventManager();

    this.dealAnimationTimeout = null;
    this.dealNewCardDelay = null;
    this.DEAL_ANIMATION_DURATION = 400;
    this.DEAL_NEW_CARD_DELAY = 100; // Allows Previous Card to be rendered b4 animation
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.stepCard && (this.props.stepCard.cardID != nextProps.stepCard.cardID)) {
      this.setState({
        isDealingNewCard: true,
      });
    }
  }

  componentDidMount() {
    this.clearTimeouts();

    this.events.addListeners([
      ['smartCardFace:baseFontSize',            this.handleBaseFontSizeUpdated],
      ['card:updated',                          this.handleCardUpdated],
      ['cardSet:baseFontSizeUpdated',           this.handleBaseFontSizeUpdated],
      ['smart-card:display-card-mode-entered',  this.handleSmartCardDisplayCardModeEntered],
      ['smart-card:edit-card-mode-entered',     this.handleSmartCardEditCardModeEntered],
      ['userCard:updated',                      this.handleUserCardUpdated],
    ]);
  }

  componentDidUpdate(prevProps) {
    // are we at a checkpoint?
    if (!prevProps.isAtCheckpoint && this.props.isAtCheckpoint) {
      this.handleIsAtCheckpoint();
    }

    // are we receiving the first card of the study mix?
    if (!prevProps.stepCard && this.props.stepCard) {
      this.handleReceivedFirstCard();
      return;
    }

    // are we receiving a new card to deal to the user?
    if (prevProps.stepCard && (prevProps.stepCard.cardID != this.props.stepCard.cardID)) {
      this.handleReceivedNewCard();
      return;
    }

    // are we receiving an updated confidence level for the current card?
    if (this.props.stepConfidenceLevel != prevProps.stepConfidenceLevel) {
      this.handleReceivedNewConfidenceLevelForCurrentCard();
    }

    // was there a card flip?
    if (this.props.isAnswerShowing != prevProps.isAnswerShowing) {
      this.handleCardFlipPerformed();
    }
  }

  componentWillUnmount() {
    this.events.disable();

    this.clearTimeouts();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const classes = toClassStr(['study-card-table', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderStudyCardTableContents()}
        <ImageViewerController />
      </div>
    );
  };

  renderStudyCardTableContents() {
    if (!this.props.stepCard) { 
      return null; 
    }

    const stepCard = this.props.stepCard;
    const deckCardPath = `${this.props.dashboardPath}/decks/${stepCard.deckId}/cards/${stepCard.cardId}`;

    return (
      <div className="study-card-table-contents">
        <header className="study-card-table-header">
          <div className="deck-and-card-info">
            <span className="deck-label">Deck:&nbsp;</span> <a className="deck-name" href={deckCardPath}>{this.props.stepDeckName}</a> <span className="card-label">Card:&nbsp;</span> <span className="deck-card-index">{this.props.stepDeckCardIndex}</span>/<span className="deck-card-count">{this.props.stepDeckCardCount}</span>
          </div>
          {this.renderPreviewCardsButton()}
        </header>

        <div className="cards-and-bar">
          
          {this.renderSmartCard()}

          <div className="study-controls">
            {this.renderRevealAnswerButton()}
            {this.renderConfidenceButtons()}
          </div>
        </div>
      </div>
    );
  }

  renderPreviewCardsButton() {
    if (this.props.isMobileViewportSize) {
      return (
        <BrowseButton
          addClasses="preview-cards-button compact"
          isProcessing={this.state.isProcessingPreviewCardsAction}
          onClick={this.handlePreviewCardsButtonClick}
          spinnerColor="blue"
          title="See all Cards in this Deck"
        />
      );
    }

    return (
      <PillButton
        addClasses="preview-cards-button full"
        isProcessing={this.state.isProcessingPreviewCardsAction}
        label="See all Cards in this Deck"
        onClick={this.handlePreviewCardsButtonClick}
        spinnerColor="blue"
      />
    );
  }

  renderSmartCard() {
    const card = this.state.currentStepCard;

    if (!card) {
      return null;
    }

    card.stats = card.stats || {};
    card.stats.level = this.state.currentStepConfidenceLevel;

    const deck = this.props.stepData.deck;
    deck.flags = deck.flags || {};
    deck.flags.isEditable = card.canEdit || false;

    const isAnswerFace = (this.state.browseCardFace == 'answer');
    const currentCardFace = this.props.isAnswerShowing ? 'answer' : 'question';

    return (
      <SmartCard
        baseFontSize={this.state.baseFontSize}
        card={card}
        cardMode="display"
        context="study"
        currentCardFace={currentCardFace}
        currentUser={this.props.currentUser}
        deck={deck}
        format="md"
        isAtCheckpoint={this.props.isAtCheckpoint}
        isAudioMuted={this.props.isAudioMuted}
        isBlurred={false}
        isCurrentCard={true}
        isMobileViewportSize={this.props.isMobileViewportSize}
        layout="single-card"
        packId={card.packId}
        skipDirection="forward"
        viewportStyle="desktop"
      />
    );
  }

  renderRevealAnswerButton = () => {
    if (this.props.isAnswerShowing) {
      return null;
    }

    const isDisabled = (this.state.currentCardMode == 'edit');
    const tooltipContent = (this.state.currentCardMode == 'edit') ? 'Save your card or hit the "Resume Study" button to continue' : 'Press SPACEBAR to continue';

    return (
      <RevealCardFaceButton 
        face="question"
        isDisabled={isDisabled}
        isFtse={this.props.isFtse}
        level={this.state.currentStepConfidenceLevel}
        onConfidenceRating={(rating) => this.handleConfidenceRating(this.props.stepCard.cardID, rating)}
        onFtseConfidenceButtonClick={(rating, ftsePostCbrBubbleAction) => this.props.onAnswerCardbarClick(rating, ftsePostCbrBubbleAction)}
        onFtseRevealAnswerButtonClick={this.props.onQuestionCardbarClick}
        onRevealCardFaceRequest={this.handleCardFlipRequest}
        shouldPulse={this.props.shouldPulseQuestionCardbar}
        tooltipContent={tooltipContent}
        tooltipPosition="top"
      />
    );
  }

  renderConfidenceButtons = () => {
    if (!this.props.isAnswerShowing) {
      return null;
    }

    const isDisabled = (this.state.currentCardMode == 'edit');
    const tooltipContent = (this.state.currentCardMode == 'edit') ? 'Save your card or hit the "Resume Study" button to continue' : null;

    return (
      <ConfidenceButtons 
        face="answer"
        isDisabled={isDisabled}
        isFtse={this.props.isFtse}
        level={this.state.currentStepConfidenceLevel}
        onConfidenceRating={(rating) => this.handleConfidenceRating(this.props.stepCard.cardID, rating)}
        onFtseConfidenceButtonClick={(rating, ftsePostCbrBubbleAction) => this.props.onAnswerCardbarClick(rating, ftsePostCbrBubbleAction)}
        onShowQuestionRequest={this.handleCardFlipRequest}
        shouldPulse={this.props.shouldPulseAnswerCardbar}
        tooltipContent={tooltipContent}
        tooltipPosition="top"
      />
    );
  }

  renderSoundPlayer() {
    if (this.state.currentStepCard && (this.state.currentStepCard.contentType == 'md')) {
      return null;
    }

    if (!(this.state.currentSound && this.state.currentSound.soundUrl)) {
      return null;
    }

    let currentSound = (this.state.currentSound && this.state.currentSound.soundUrl) ? this.state.currentSound.soundUrl : null;

    return (
      <audio
        autoPlay={true}
        id="sound-player"
        onEnded={() => this.handleSoundEnded()}
      >
        <source
          src={currentSound}
        />
      </audio>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBaseFontSizeUpdated = (data) => {
    this.setState({
      baseFontSize: data.fontSize,
    });
  };

  // Handles events that indicate that a card has been updated by
  // some other process.
  handleCardUpdated = (data) => {
    const ns = {};
    const nc = data.card;

    const csc = this.state.currentStepCard;
    if (csc && csc.cardId == nc.cardId) {
      ns.currentStepCard = {...csc, ...nc};
    }

    const psc = this.state.previousStepCard;
    if (psc && psc.cardId == nc.cardId) {
      ns.previousStepCard = {...psc, ...nc};
    }

    if (Object.keys(ns).length > 0) { this.setState(ns); }
  };

  handleUserCardUpdated = (data) => {
    const ns  = {};
    const ucl = data.userCard;
    if (!ucl) { return; }

    const csc = this.state.currentStepCard;
    if (csc && csc.cardId == ucl.cardId) {
      ns.currentStepConfidenceLevel = ucl.level;
    }

    const psc = this.state.previousStepCard;
    if (psc && psc.cardId == ucl.cardId) {
      ns.previousStepConfidenceLevel = ucl.level;
    }

    if (Object.keys(ns).length > 0) { this.setState(ns); }
  };

  handlePreviewCardsButtonClick = () => {
    const {deckId, packId} = this.props.stepCard;

    this.setState({isProcessingPreviewCardsAction: true});

    pack.show(packId).then(packData => {
      packDeck.show(packId, deckId).then(packDeckData => {
        const pack = packData;
        const deck = packDeckData.deck;

        this.triggerPreviewModalOpen(pack, deck);

        this.setState({
          isProcessingPreviewCardsAction: false,
        });
      }).catch(err => {
        this.setState({
          isProcessingPreviewCardsAction: false,
        });
      });
    });
  }

  triggerPreviewModalOpen = (pack, deck) => {
    EventManager.emitEvent('deck-preview-modal:open', {
      deck: deck,
      pack: pack,
    });
  }

  handleReceivedFirstCard() {
    const currentSound = this.setupCurrentSound();

    this.setState({
      areCardsPlaced: true,
      currentSound: currentSound,
      currentStepCard: this.props.stepCard,
      currentStepConfidenceLevel: this.props.stepConfidenceLevel,
      shouldShowLoadingOverlay: false
    });
  }

  handleConfidenceRating(stepCardId, rating) {
    this.stopCurrentSound();
    this.props.onConfidenceRating(stepCardId, rating);
  }

  handleCardFlipRequest = () => {    
    this.stopCurrentSound();
    this.props.onCardFlipRequest();
  }

  handleReceivedNewCard() {
    this.invokeCardDealAnimation();
  }

  handleReceivedNewConfidenceLevelForCurrentCard() {
    // this updates the current card before its "previous card" copy is made and dealt away
    this.setState({
      currentStepConfidenceLevel: this.props.stepConfidenceLevel,
    });
  }

  handleCardFlipPerformed() {
    const currentSound = this.setupCurrentSound();

    this.setState({
      currentSound: currentSound,
    }, () => {
      this.playCurrentSound();
    });
  }

  handlePlaySoundRequest() {
    if (this.state.currentSound && this.state.currentSound.isPaused) {
      this.resumeCurrentSound();
    } else {
      this.playCurrentSound();
    }
  }

  handlePauseSoundRequest() {
    this.pauseCurrentSound();
  }

  handleSoundEnded() {
    this.stopCurrentSound();
  }

  handleIsAtCheckpoint() {
    this.stopCurrentSound();
  }

  handleSmartCardDisplayCardModeEntered = (eventData) => {
    if (eventData.cardId != this.state.currentStepCard.cardId) {
      return false;
    }

    this.setState({
      currentCardMode: 'display',
    });
  }

  handleSmartCardEditCardModeEntered = (eventData) => {
    if (eventData.cardId != this.state.currentStepCard.cardId) {
      return false;
    }

    this.setState({
      currentCardMode: 'edit',
    });
  }


  /*
  ==================================================
   ADDITIONAL STATE MANAGEMENT
  ==================================================
  */

  setupCurrentSound() {
    if (this.props.stepCard.contentType == 'md') {
      return false;
    }

    const soundUrlKey = this.props.isAnswerShowing ? 'aSoundURL' : 'qSoundURL';

    if (!(this.props.stepCard && this.props.stepCard[soundUrlKey])) {
      return {
        isPresent: false
      }
    }

    return {
      isPaused: false,
      isPresent: true,
      isPlaying: true,
      isAnswerFace: this.props.isAnswerShowing,
      soundUrl: this.props.stepCard[soundUrlKey],
    }
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  invokeCardDealAnimation() {
    this.clearTimeouts();
    this.setupPreviousCard();
  }

  setupPreviousCard() {
    this.setState({
      previousStepCard: this.state.currentStepCard,
      previousStepConfidenceLevel: this.state.currentStepConfidenceLevel,
    }, () => {
      this.setupCurrentCard();
    });
  }

  setupCurrentCard() {
    const currentSound = this.setupCurrentSound();
    const soundPlayer = document.querySelector('#sound-player');

    this.setState({
      areCardsLoaded: true,
      areCardsDealing: false,
      areCardsPlaced: false,
      currentSound: currentSound,
      currentStepCard: this.props.stepCard,
      currentStepConfidenceLevel: this.props.stepConfidenceLevel,
    }, () => {
      this.dealNewCard();
      this.playCurrentSound();
    });
  }

  dealNewCard() {
    this.setPreviousCardBaseFontSize();
    this.dealNewCardDelay = setTimeout(() => {
      this.setState({
        areCardsLoaded: false,
        areCardsDealing: true,
        areCardsPlaced: false,
      }, () => {
        this.completeDealAnimation();
      });
    }, this.DEAL_NEW_CARD_DELAY);
  }

  setPreviousCardBaseFontSize = () => {    
    const currentCardElem = document.querySelector('.study-cards .study-card.smart-card.current-card');

    if (currentCardElem) {
      const currentCardFace = currentCardElem.querySelector('.study-card-face.smart-card-face.answer-face');

      if (currentCardFace) {
        const baseFontSize = currentCardFace.style.fontSize;

        if (baseFontSize) {
          const previousCardElem = document.querySelector('.study-cards .study-card.smart-card.previous-card');

          if (previousCardElem) {
            const previousCardFace = previousCardElem.querySelector('.study-card-face.smart-card-face.answer-face');

            if (previousCardFace) {
              previousCardFace.style.fontSize = baseFontSize;
            }
          }
        }
      }
    }
  }

  completeDealAnimation() {
    this.dealAnimationTimeout = setTimeout(() => {
      this.setState({
        areCardsLoaded: false,
        areCardsDealing: false,
        areCardsPlaced: true,
        isDealingNewCard: false,
      });
    }, this.DEAL_ANIMATION_DURATION);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  playCurrentSound() {
    if (this.props.stepCard.contentType == 'md') {
      return false;
    }

    const soundPlayer = document.querySelector('#sound-player');

    if (!soundPlayer || !this.state.currentSound || !this.state.currentSound.soundUrl) {
      return null;
    }

    const soundPlayerSource = document.querySelector('#sound-player source');
    soundPlayerSource.src = this.state.currentSound.soundUrl;
    soundPlayer.load();

    const playPromise = soundPlayer.play();
    if (playPromise !== null){
      playPromise.catch(() => {
        soundPlayer.play();
      });
    }

    const currentSound = this.state.currentSound;
    currentSound.isPlaying = true;

    this.setState({
      currentSound: currentSound,
    });
  }

  pauseCurrentSound() {
    if (this.props.stepCard.contentType == 'md') {
      return false;
    }

    const soundPlayer = document.querySelector('#sound-player');

    if (!soundPlayer || !this.state.currentSound || !this.state.currentSound.soundUrl) {
      return null;
    }

    soundPlayer.pause();

    const currentSound = this.state.currentSound;
    currentSound.isPlaying = false;
    currentSound.isPaused = true;

    this.setState({
      currentSound: currentSound,
    });
  }

  resumeCurrentSound() {
    if (this.props.stepCard.contentType == 'md') {
      return false;
    }

    const soundPlayer = document.querySelector('#sound-player');

    if (!soundPlayer || !this.state.currentSound || !this.state.currentSound.soundUrl) {
      return null;
    }

    soundPlayer.play();

    const currentSound = this.state.currentSound;
    currentSound.isPlaying = true;
    currentSound.isPaused = true;

    this.setState({
      currentSound: currentSound,
    });
  }

  stopCurrentSound() {
    if (this.props.stepCard.contentType == 'md') {
      return false;
    }

    const soundPlayer = document.querySelector('#sound-player');

    if (!soundPlayer) {
      return null;
    }

    const soundPlayerSource = document.querySelector('#sound-player source');

    soundPlayer.pause();
    soundPlayerSource.removeAttribute('src');
    soundPlayer.load();

    const currentSound = this.state.currentSound;
    currentSound.isPlaying = false;

    this.setState({
      currentSound: currentSound,
    });
  }

  clearTimeouts() {
    clearTimeout(this.dealNewCardDelay);
    clearTimeout(this.dealAnimationTimeout);
  }
}

export default StudyCardTable;
