
import Bubble                    from '_views/shared/Bubble';
import BubbleDialog              from '_views/shared/BubbleDialog';
import EventManager              from '@brainscape/event-manager';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import Tracker                   from '_utils/Tracker';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                      : PropTypes.string,
  currentPack                     : PropTypes.object,
  currentView                     : PropTypes.string, // 'pack-detail', 'deck-detail'
  isMobileViewportSize            : PropTypes.bool,
};

class FtueStudyMessaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isClassesHelpBubbleOpen             : false,
      shouldCollapseClassesHelpBubble     : false,
      isStudyHelpBubbleOpen               : false,
      shouldCollapseStudyHelpBubble       : false,
      isDeckHelpBubbleOpen                : false,
      shouldCollapseDeckHelpBubble        : false,
      isCardHelpBubbleOpen                : false,
      shouldCollapseCardHelpBubble        : false,
      isEditorHelpBubbleOpen:             false,
      shouldCollapseEditorHelpBubble:     false,
      phase                               : null,
    };

    this._isMounted = false;

    this.events = new EventManager();

    this.pulseAddCardsButtonTimeout = null;
    this.pulseCreateDeckButtonTimeout = null;
    this.pulseStudyButtonTimeout = null;

    this.PRE_PULSE_ADD_CARDS_BUTTON_DELAY = 3000;
    this.PRE_PULSE_CREATE_DECK_BUTTON_DELAY = 3000;
    this.PRE_PULSE_STUDY_BUTTON_DELAY = 3000;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();

    this.events.addListeners([
      ['card:created',                      this.handleCardCreated],
      ['deck:created',                      this.handleDeckCreated],
      ['deck:create-dismissed',             this.handleDeckCreateDismissed],
      ['deck:create-requested',             this.handleDeckCreateRequested],
    ]);

    if (this.props.currentPack) {
      this.invokeFtue();
    }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.currentPack && this.props.currentPack) {
      this.invokeFtue();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this.events.disable();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.props.currentPack) {
      return null;
    }

    let classes = toClassStr(['ftue-study-messaging', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderClassesHelpBubble()}
        {this.renderStudyHelpBubble()}
        {this.renderDeckHelpBubble()}
        {this.renderCardHelpBubble()}
        {this.renderEditorHelpBubble()}
      </div>
    );
  }

  renderClassesHelpBubble() {
    if (!this.state.isClassesHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';

    return (
      <BubbleDialog
        expansionDelay={1000}
        message="This is your list of Flashcard Classes. Click on any Class to begin."
        onClose={this.handleClassesHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseClassesHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Welcome!'
      />
    );
  }

  renderStudyHelpBubble() {
    if (!this.state.isStudyHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';

    return (
      <BubbleDialog
        corner="bottomRight"
        contractionDelay={11500}
        expansionDelay={1000}
        message={this.props.currentPack.welcomeMessage || 'Click the big "STUDY" button, or click on a single deck to study'}
        onClose={this.handleStudyHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseStudyHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title="Welcome"
      />
    );
  }

  renderDeckHelpBubble() {
    if (!this.state.isDeckHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';
    const bubbleCorner = (this.props.isMobileViewportSize) ? 'topRight' : 'bottomRight';

    return (
      <BubbleDialog
        corner={bubbleCorner}
        expansionDelay={1000}
        message="To study, first create a deck of flashcards. Click the orange 'Create New Deck' button to continue."
        onClose={this.handleDeckHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseDeckHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Set Up'
      />
    );
  }

  renderCardHelpBubble() {
    if (!this.state.isCardHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';
    const bubbleCorner = (this.props.isMobileViewportSize) ? 'topRight' : 'bottomLeft';

    return (
      <BubbleDialog
        corner={bubbleCorner}
        expansionDelay={1000}
        message="Click the orange 'Add Cards' button to add Flashcards to Study."
        onClose={this.handleCardHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseCardHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Set Up'
      />
    );
  }

  renderEditorHelpBubble() {
    if (!this.state.isEditorHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';
    const bubbleCorner = (this.props.isMobileViewportSize) ? 'topRight' : 'bottomRight';

    return (
      <BubbleDialog
        corner={bubbleCorner}
        expansionDelay={1000}
        message='Add a Question and Answer for one or more cards, and then you will be able to study by clicking any blue "STUDY" button.'
        onClose={this.handleEditorHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseEditorHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Create Your Cards'
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClassesHelpBubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseClassesHelpBubble) {
      // user dismissed bubble
      this.triggerFtueDismissRequest();
    }
  }
  
  handleStudyHelpBubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseStudyHelpBubble) {
      // user dismissed bubble
      this.triggerFtueDismissRequest();
      // this.props.onDismissFtueRequest();
    }
  }

  handleDeckHelpBubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseDeckHelpBubble) {
      // user dismissed bubble
      this.triggerFtueDismissRequest();
    }
  }

  handleCardHelpBubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseCardHelpBubble) {
      // user dismissed bubble
      this.triggerFtueDismissRequest();
    }
  }

  handleEditorHelpBubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseEditorHelpBubble) {
      // user dismissed bubble
      this.triggerFtueDismissRequest();
    }
  }

  handleDeckCreateRequested = () => {
    this.setState({
      phase: 'createDeck',
      shouldCollapseDeckHelpBubble: true,
    });
  }

  handleDeckCreateDismissed = () => {
    this.setState({
      shouldCollapseDeckHelpBubble: true,
    }, () => {
      this.triggerFtueDismissRequest();
    });
  }

  handleDeckCreated = () => {
    this.setState({
      phase: 'cardHelp',
      isCardHelpBubbleOpen: true,
    });

    this.pulseAddCardsButton();
  }

  handleCardCreated = () => {
    this.setState({
      phase: 'studyHelp',
      shouldCollapseEditorHelpBubble: true,
    }, () => {
      this.triggerFtueDismissRequest();
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerFtueDismissRequest(viewProps) {
    EventManager.emitEvent('ftue:dismiss-request', viewProps);
  }

  triggerFtuePulseAddCardsButtonRequest(viewProps) {
    EventManager.emitEvent('ftue:pulse-add-cards-button-request', viewProps);
  }

  triggerFtuePulseCreateDeckButtonRequest(viewProps) {
    EventManager.emitEvent('ftue:pulse-create-deck-button-request', viewProps);
  }
  
  triggerFtuePulseStudyButtonRequest(viewProps) {
    EventManager.emitEvent('ftue:pulse-study-button-request', viewProps);
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  pulseAddCardsButton() {
    clearTimeout(this.pulseAddCardsButtonTimeout);

    this.pulseAddCardsButtonTimeout = setTimeout(() => {
      this.triggerFtuePulseAddCardsButtonRequest();
    }, this.PRE_PULSE_ADD_CARDS_BUTTON_DELAY);
  }

  pulseCreateDeckButton() {
    clearTimeout(this.pulseCreateDeckButtonTimeout);

    this.pulseCreateDeckButtonTimeout = setTimeout(() => {
      this.triggerFtuePulseCreateDeckButtonRequest();
    }, this.PRE_PULSE_CREATE_DECK_BUTTON_DELAY);
  }

  pulseStudyButton() {
    clearTimeout(this.pulseStudyButtonTimeout);

    this.pulseStudyButtonTimeout = setTimeout(() => {
      this.triggerFtuePulseStudyButtonRequest();
    }, this.PRE_PULSE_STUDY_BUTTON_DELAY);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.pulseAddCardsButtonTimeout);
    clearTimeout(this.pulseCreateDeckButtonTimeout);
    clearTimeout(this.pulseStudyButtonTimeout);
  }

  invokeFtue() {
    let newState = {...this.state};

    if (this.props.isMobileViewportSize && this.props.currentView == 'mobile-class-index') {
      // classesHelp
      newState.phase = 'classesHelp';
      newState.isClassesHelpBubbleOpen = true;
    } else if (this.props.currentPack.stats.deckCount < 1) {
      // deckHelp
      newState.phase = 'deckHelp';
      newState.isDeckHelpBubbleOpen = true;
      this.pulseCreateDeckButton();
    } else if (this.props.currentPack.stats.cardCount < 1 && (this.props.currentView == 'pack-detail' || this.props.currentView == 'mobile-class-index' )) {
      // cardHelp
      newState.phase = 'cardHelp';
      newState.isCardHelpBubbleOpen = true;
      this.pulseAddCardsButton();
    } else if (this.props.currentPack.stats.cardCount < 1 && (this.props.currentView == 'deck-detail')) {
      // cardHelp
      newState.phase = 'editorHelp';
      newState.isEditorHelpBubbleOpen = true;
      // this.pulseAddCardsButton();
    } else {
      // studyHelp
      newState.phase = 'studyHelp';
      newState.isStudyHelpBubbleOpen = true;
      this.pulseStudyButton();
    }

    this.setState(newState, () => {
      Tracker.trackDashboardProgress('ftue_intro_study');
    });
  }
}

export default FtueStudyMessaging;
