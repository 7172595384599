
import {
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPostFile,
  apiPut,
  cardPath,
  deckPath,
  waitForJob,
} from '_core/Api2';

import EventManager         from '@brainscape/event-manager';
import UrlHelper            from '_utils/UrlHelper';
import SessionStorageHelper from '_utils/SessionStorageHelper';

const DEFAULT_PER_PAGE = 15;


const model = {

  /*
  ==================================================
   CRUD METHODS
  ==================================================
  */

  index(path, isPaginated=false, paginationOpts={}, headers={}) {
    const augmentedPath = this.augmentPath(path);

    if (isPaginated) {
      return this.paginatedIndex(augmentedPath, paginationOpts, headers);
    }

    return apiGet(augmentedPath, headers);
  },

  show(path, isStudy=false, headers={}) {
    const augmentedPath = this.augmentPath(path, isStudy);    
    return apiGet(augmentedPath, headers);
  },

  create(path, data, headers={}) {
    const augmentedData = this.augmentData(data);
    return apiPost(path, augmentedData, headers);
  },

  update(path, data, headers={}) {
    const augmentedData = this.augmentData(data);
    return apiPatch(path, augmentedData, headers);
  },

  destroy(path, data={}, headers={}) {
    const augmentedData = this.augmentData(data);
    return apiDelete(path, augmentedData, headers);
  },


  /*
  ==================================================
   PAGINATION
  ==================================================
  */

  paginatedIndex(path, opts) {
    return new Promise((resolve, reject) => {
      try {
        let {page, perPage, collectionKey, eventTag} = opts;
        const paginatedPath = this.getPaginatedPath(path, page, perPage);

        apiGet(paginatedPath).then(rs => {
          const accumulator = [];
          handlePageReceived(rs, path, opts, accumulator, resolve);
        }).catch(err => {
          throw err;
        });

        var handlePageReceived = (rs, path, opts, accumulator) => {
          const {page, perPage, collectionKey, eventTag, totalPages} = opts;

          // publish each page as it comes in to enable display in UI
          this.publish(`${eventTag}:page-received`, rs);

          accumulator = [...accumulator, ...rs[collectionKey]];
          const nextOpts = {...opts, ...{page: opts.page + 1, totalPages: rs.totalPages}};

          fetchRemainingPages(path, nextOpts, accumulator);
        };

        var fetchRemainingPages = (path, opts, accumulator=[]) => {
          const {page, perPage, collectionKey, eventTag, totalPages} = opts;

          if (page > totalPages) {
            const totalPagesData = {
              totalPages: page - 1,
            };

            totalPagesData[collectionKey] = accumulator;

            this.publish(`${eventTag}:pagination-completed`, totalPagesData);

            resolve(totalPagesData);

            return true;
          }

          const paginatedPath = this.getPaginatedPath(path, page, perPage);

          apiGet(paginatedPath).then(rs => {
            handlePageReceived(rs, path, opts, accumulator)
          }).catch(err => {
            throw err;
          });
        };
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  getPaginatedPath(path, page, perPage=DEFAULT_PER_PAGE) {
    let paginatedPath = UrlHelper.appendQueryParam(path, 'per_page', perPage);
    paginatedPath = UrlHelper.appendQueryParam(paginatedPath, 'page', page);

    return paginatedPath;
  },


  /*
  ==================================================
   ADDITIONAL METHODS
  ==================================================
  */

  publish(eventLabel, eventData) {
    if (SessionStorageHelper.getItem('showModelDebug') == true) {
      console.log('in newModel.publish. eventLabel, eventData', eventLabel, eventData);
    }

    EventManager.emitEvent(eventLabel, eventData);
  },

  set(path, data={}) {
    const augmentedData = this.augmentData(data);
    return apiPut(path, augmentedData);
  },

  upload(path, fileData={}, contentType) {
    const augmentedPath = this.augmentPath(path);
    return apiPostFile(augmentedPath, fileData, contentType);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  augmentData(data) {
    return {...data, ...{is_web: true}}; 
  },

  augmentPath(path, isStudy=false) {
    if (isStudy) {
      return path;
    } else {
      return UrlHelper.appendQueryParam(path, 'is_web', true);
    }
  },
};

export default model;

