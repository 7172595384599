
import CautionModal       from '_views/modals/CautionModal';
import EventManager       from '@brainscape/event-manager';
import React              from 'react';

class CautionModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      modalProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('caution-modal:open', this.handleOpenRequest);
    this.events.addListener('caution-modal:close', this.handleCloseRequest);
    this.events.addListener('caution-modal:update', this.handleUpdateRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const modalProps = this.state.modalProps;
    if (!modalProps) { 
      return null; 
    }

    return (
      <CautionModal
        actionText={modalProps.actionText}
        addClasses={modalProps.addClasses}
        resolveButtonText={modalProps.resolveButtonText}
        isOpen={this.state.isModalOpen}
        isProcessing={modalProps.isProcessing}
        message={modalProps.message}
        onCancellation={this.handleCancellationRequest}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        onResolution={this.handleResolutionRequest}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancellationRequest = () => {
    this.setState({
      isModalOpen: false,
      isProcessing: false,
    }, () => {
      if (this.state.modalProps?.onCancellation) {
        this.state.modalProps.onCancellation();
      } else {
        this.handleCloseRequest();
      }
    });
  }

  handleClosed = () => {
    this.setState({
      modalProps: null
    }, () => {
      if (this.state.modalProps?.onClosed) {
        this.state.modalProps.onClosed();
      }
    });
  }

  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
      isProcessing: false,
    }, () => {
      if (this.state.modalProps?.onCloseRequest) {
        this.state.modalProps.onCloseRequest();
      }
    });
  }

  handleOpened = (data) => {
    if (this.state.modalProps?.onOpened) {
      this.state.modalProps.onOpened();
    }
  }

  handleOpenRequest = (data) => {
    this.setState({
      isModalOpen: true,
      isProcessing: false,
      modalProps: data
    }, () => {
      if (this.state.modalProps?.onOpenRequest) {
        this.state.modalProps.onOpenRequest();
      }
    });
  }

  handleResolutionRequest = () => {
    this.setState({
      isModalOpen: false,
      isProcessing: false,
    }, () => {
      if (this.state.modalProps?.onResolution) {
        this.state.modalProps.onResolution();
      } else {
        this.handleCloseRequest();
      }
    });
  }

  handleUpdateRequest = (data) => {
    const modalProps = {...this.state.modalProps, ...data};

    this.setState({
      modalProps: modalProps,
    });
  }
}


export default CautionModalController;
