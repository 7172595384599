
import PropTypes from 'prop-types';

const TypeHelper = {

  isArray(item) {
    return (Array.isArray(item) && item !== null);
  },
  
  isObject(item) {
    return (typeof item === "object" && !Array.isArray(item) && item !== null);
  },

  hasArrayData(resource) {
    return (resource && !this.isEmptyArray(resource));
  },

  hasObjectData(resource) {
    return (resource && !this.isEmptyObject(resource));
  },

  isEmptyArray(arr) {
    return (arr.length == 0);
  },

  isEmptyObject(obj) {
    return (Object.keys(obj).length == 0);
  },
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export {
  TypeHelper as default,
  IdType,
};
