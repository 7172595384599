
import EventManager              from '@brainscape/event-manager';
import PillButton                from '_views/shared/PillButton';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import StripeForm                from '_views/modals/paywall/StripeForm';
import ThankYouPanel             from '_views/modals/paywall/ThankYouPanel';
import FeaturesList              from '_views/modals/paywall/FeaturesList';
import {apiPost}                 from '_core/Api1';
import {apiGet, apiError}        from '_core/Api2';
import TextField                 from '_views/shared/TextField';
import SplitModal                from '_views/shared/SplitModal';
import UiHelper                  from '_utils/UiHelper';
import {toClassStr}              from '_utils/UiHelper';
import EventBus                  from '_utils/EventBus';
import GaHelper                  from '_utils/GaHelper';
import {CSSTransition}           from 'react-transition-group';

const PT = {
  addClasses                          : PropTypes.string,
  isOpen                              : PropTypes.bool,
  onCloseRequest                      : PropTypes.func,
};

class PaywallModal extends React.Component {
  constructor(props) {
    super(props);

    EventBus.publish("action:completed:pricing:index")

    this.fetchPlans();

    this.state = {
      currentActivePlanClassName: 'semester',
      currentActivePlanPriceTotal: '$41.94',
      clientSecret: null,
      panel: 0,
      discountedPlanPriceTotal: false,
      discountPercentage: null,
      isPricingPlanActive: null,
      isOptionalCodeInputFieldVisible: false,
      isDiscountCodeValid: true,
      optionalCode: '',
      setupIntentId: null,
      preDiscountedPriceTotal: '',
      pricingPlans: [],
      codeValidationButtonLabel: 'apply',
      isProcessing: false,
      isDiscountCodeProcessing: false,
      isSidePanelActive: false,
      isSmallViewportSize: false,
      isChevronUp: false,
    };

    this.events                 = new EventManager();
    this._isMounted             = false;
    this.SLIDE_TRANSITION       = 1500;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.checkViewportSize();

    // GA4
    if (window.gtag) {
      gtag('event', 'page_view', {
        page_title: 'paywall_modal',
        page_location: `${window.location.href}?upgrade_paywall=${this.props.paywall}`,
        logged_state: 2,
        paywall: this.props.paywall,
        user_email: window?.userObj?.email
      });
    }

    this.triggerRocketAnimation();
    this.triggerFeaturesObserver();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {

      // GA4
      if (window.gtag) {
        gtag('event', 'page_view', {
          page_title: 'paywall_modal',
          page_location: `${window.location.href}?upgrade_paywall=${this.props.paywall}`,
          logged_state: 2,
          paywall: this.props.paywall,
          user_email: window?.userObj?.email
        });
      }

      this.triggerRocketAnimation();
    }

    this.triggerFeaturesObserver();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isSmallViewportSize = (this.state.isSmallViewportSize) ? 'is-mobile' : '';
    const classes = toClassStr(['paywall-modal', isSmallViewportSize]);
    let currentPanel;

    switch (this.state.panel) {
      case 0 : currentPanel = this.renderPaywallMainPanel(); break;
      case 1 : currentPanel = this.renderCreditCardPanel(); break;
      default: currentPanel = this.renderPaywallMainPanel();
    }

    if (isSmallViewportSize) {
      return (
        <SplitModal
          addClasses={classes}
          isOpen={this.props.isOpen}
          onCloseRequest={() => this.handleOnCloseRequest()}
          isSecondPanelVisible={this.state.panel}
          onBackButtonClick={() => this.handleBackButtonClick()}
        >
          <CSSTransition
            in={this.state.isSidePanelActive}
            timeout={this.SLIDE_TRANSITION}
            classNames={'rocket-takeoff'}
          >
            <div className='mobile-slide-container'>
              <div className={'split-modal-side'}>
                {this.renderMobileHeader()}
              </div>

              <div className='split-modal-main'>
                {currentPanel}
              </div>

              <div className='features-container'>
                <p className='features-title'>With Pro, you can:</p>
                <div className='chevron-icon'></div>
                {this.renderFeaturesList()}
              </div>
            </div>
          </CSSTransition>
        </SplitModal>
      );
    }

    return (
      <SplitModal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.handleOnCloseRequest()}
        isSecondPanelVisible={this.state.panel}
        onBackButtonClick={() => this.handleBackButtonClick()}
      >
        <div className={'split-modal-side'}>
          {this.renderPaywallSidePanel()}
          {this.renderFeaturesListChevron()}
        </div>

        <div className='split-modal-main'>
          {currentPanel}
        </div>
      </SplitModal>
    );
  }

  renderCreditCardPanel() {
    if (!this.state.clientSecret) return;

    if ( this.state.purchaseSuccessful ) {
      return this.renderThankYouPanel()
    } else {
      return (
        <>
          <div className='split-modal-title'>Payment Details</div>
          {this.renderSelectedPlanDetails()}
          <div className='form stripe-form'>
            <StripeForm
              clientSecret={this.state.clientSecret}
              codeValidationButtonLabel={this.state.codeValidationButtonLabel}
              currentActivePlanClassName={this.state.currentActivePlanClassName}
              currentActivePlanPriceTotal={this.state.currentActivePlanPriceTotal}
              handleCodeValidation={this.handleCodeValidation}
              handleCodeValidationReset={this.handleCodeValidationReset}
              handlePurchaseSuccessful={this.handlePurchaseSuccessful}
              isDiscountCodeValid={this.state.isDiscountCodeValid}
              isDiscountCodeProcessing={this.state.isDiscountCodeProcessing}
              paywall={this.props.paywall}
              planId={this.state.isPricingPlanActive}
              optionalCode={this.state.optionalCode}
              setupIntentId={this.state.setupIntentId}
            />
          </div>
        </>
      )
    }


  }

  renderPaywallSidePanel() {
    const isUpdatePlanModal = this.props.desiredAction == "update plan";

    return (
      <div className='paywall-side-panel-content' onScroll={this.handleChevronDirection}>
        <CSSTransition
          in={this.state.isSidePanelActive}
          timeout={this.SLIDE_TRANSITION}
          classNames={'rocket-takeoff'}
        >
          <div className='desktop-slide-container'>
            <div className='rocket-image'></div>
            <h3 className='title'>{isUpdatePlanModal ? 'Brainscape pro' : 'Upgrade to pro'}</h3>
            <p className='text'>Making, sharing, & studying your own cards is FREE. With Pro, you can:</p>
            <div className='features-container'>
              {this.renderFeaturesList()}
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }

  renderMobileHeader() {
    const isUpdatePlanModal = this.props.desiredAction == "update plan";

    return (
      <div className='paywall-side-panel-content'>
        <div className='rocket-image'></div>
        <h3 className='title'>{isUpdatePlanModal ? 'Brainscape pro' : 'Upgrade to pro'}</h3>
        <p className='text'>Making, sharing, & studying your own{'\n'} cards is FREE.</p>
      </div>
    );
  }

  renderThankYouPanel() {
    return (
      <>
        <ThankYouPanel
          currentActivePlanPriceTotal={this.state.currentActivePlanPriceTotal}
          paywall={this.props.paywall}
          term={this.state.currentActivePlanClassName}
        />
      </>
    )
  }

  renderPaywallMainPanel() {
    const isUpdatePlanModal = this.props.desiredAction == "update plan";

    if ( this.state.purchaseSuccessful ) {
      return this.renderThankYouPanel()
    } else {
      return (
        <>
          <div className='split-modal-title'>{isUpdatePlanModal ? 'Update your plan' : 'Choose your plan'}</div>
          {this.renderPricingPlans()}
          <div className='form split-modal-actions'>
            {this.renderOptionalCodeField()}
            {this.state.isPricingPlanActive && this.renderSelectPlanButton()}
            {this.renderSchoolAndCompanyLinks()}
          </div>
        </>
      )
    }
  }

  renderPricingPlans() {
    return (
      <ul className='pricing-plans'>
        {this.state.pricingPlans.map(pricingPlan => (
          <li
            className={'pricing-plan ' + pricingPlan.class + (pricingPlan.id === this.state.isPricingPlanActive ? " selected" : "")}
            onClick={() => this.handlePricingPlanClick(pricingPlan.id)}
            key={pricingPlan.id}
          >
            <div className='pricing-plan-title'>
              <span>{pricingPlan.duration}</span>{pricingPlan.label}
            </div>
            <div className={'pricing-plan-price ' + (!!(pricingPlan.normalized_discount_amount) ? "discounted" : "")}>
              {'$' + pricingPlan.price}
            </div>
            <div className={'discounted-amount ' + ((pricingPlan.normalized_discount_amount != undefined) ? "show" : "hide")}>
              {'$' + pricingPlan.normalized_discount_amount}
            </div>
          </li>
        ))}
      </ul>
    );
  }

  renderSelectedPlanDetails() {
    const isDiscountedPlanMessage = this.state.discountedPlanPriceTotal && this.state.discountPercentage ? true : false;
    const discountedPlanMessageSavings = isDiscountedPlanMessage ? (' ' + '(' + this.state.discountPercentage + '%' + ' ' + 'savings with your discount code.' + ')') : '';
    const discountedPlanMessageStyling = isDiscountedPlanMessage ? 'discounted-plan-styling' : '';
    const classes = toClassStr(['split-modal-subtitle', discountedPlanMessageStyling]);
    const cancelAnytimeText = this.state.currentActivePlanClassName != 'lifetime' ? ". Cancel anytime." : '';

    if (isDiscountedPlanMessage) {
      return (
        <div className={classes}>
          You have selected the <span>{this.state.currentActivePlanClassName}</span> Pricing Plan and will be billed
          <span className='pre-discounted-price-total'>{' ' + this.state.preDiscountedPriceTotal}</span>
          <span>{' ' +  this.state.currentActivePlanPriceTotal}</span>
          <span className='savings-message'>{discountedPlanMessageSavings}</span>
          {cancelAnytimeText}
        </div>
      )
    }

    return (
      <div className={classes}>
        You have selected the <span>{this.state.currentActivePlanClassName}</span> Pricing Plan and will be billed <span>{this.state.currentActivePlanPriceTotal}</span>
        {cancelAnytimeText}
      </div>
    )
  }

  renderSelectPlanButton() {
    return (
      <PillButton
        addClasses='pill-button'
        label={'Enter Payment Details'}
        onClick={() => this.planSelectCta()}
        isProcessing={this.state.isProcessing}
      />
    );
  }

  renderSchoolAndCompanyLinks() {
    const teachersPageLink = <a href='#' onClick={this.handleTeachersLinkClick}>school</a>
    const companiesPageLink = <a href='#' onClick={this.handleCompaniesLinkClick}>companies</a>
    const resellerLink = <a href='#' onClick={this.handleResellerClick}>resellers</a>

    return (
      <div className='split-modal-links'>See {teachersPageLink}, {companiesPageLink} or {resellerLink} pricing</div>
    )
  }

  renderValidateCodeButton() {
    if (this.state.codeValidationButtonLabel == 'apply') {
      return (
        <PillButton
          addClasses='apply-code-button'
          label={this.state.codeValidationButtonLabel}
          isDisabled={this.state.optionalCode == '' || this.state.isDiscountCodeProcessing ? true : false}
          isProcessing={this.state.isDiscountCodeProcessing}
          onClick={() => this.handleCodeValidation()}
        />
      );
    }

    return (
      <PillButton
        addClasses='apply-code-button'
        label={this.state.codeValidationButtonLabel}
        onClick={() => this.handleCodeValidationReset()}
      />
    );
  }

  renderOptionalCodeField() {
    const invalidDiscountCodeMessage = this.state.isDiscountCodeValid ? '' : 'Invalid code!';
    const classes = toClassStr(['optional-code']);

    return (
      <>
        <div
          className='validate-code-link'
          onClick={this.handleToggleOptionalCodeInputField}
        >I have a code</div>

        {this.state.isOptionalCodeInputFieldVisible &&
          <>
            <TextField
              addClasses={classes}
              errorMessage={invalidDiscountCodeMessage ? invalidDiscountCodeMessage : ''}
              isInvalid={!!invalidDiscountCodeMessage}
              buttons={this.renderValidateCodeButton()}
              id="optionalCode"
              name="optionalCode"
              onChange={this.handleAccessOrDiscountCodeChange}
              placeholder="Enter code (optional)"
              type="optionalCode"
              value={this.state.optionalCode}
            />
          </>
        }
      </>
    )
  }

  renderFeaturesList() {
    const featuresListSelection = this.props.featuresList;
    const classes = toClassStr(['features', featuresListSelection]);

    return (
      <ul className={classes}>
        <FeaturesList />
      </ul>
    )
  }

  renderFeaturesListChevron() {
    const chevronIconDirection = this.state.isChevronUp ? 'chevron-up' : 'chevron-down';
    const classes = toClassStr(['chevron-icon', chevronIconDirection]);

    return (
      <div
        className={classes}
        onClick={this.handleChevronClick}
      ></div>
    )
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAccessOrDiscountCodeChange = (e) => {
    e.stopPropagation();

    const ns = {};
    ns[e.target.name] = e.target.value;

    this.setState(ns);

    if (e.target.value.length == 0) {
      this.handleCodeValidationReset();
    }
  }

  handleChevronClick = () => {
    const listTop = document.querySelector('.desktop-slide-container');
    let visibleFeaturesNodeList = document.querySelectorAll('.feature.visible');
    let visibleFeaturesArray = Array.from(visibleFeaturesNodeList);
    let orderDataAttributeArr = [];

    if (this.state.isChevronUp) {
      let lowestOrderVisibleItem;
      let prevFeatureItem;
      let prevFeatureItemOrder;

      visibleFeaturesArray.forEach(elem =>  orderDataAttributeArr.push(Math.min(elem.dataset.order)));
      lowestOrderVisibleItem = Math.min(...orderDataAttributeArr);
      prevFeatureItemOrder = lowestOrderVisibleItem - 3;
      prevFeatureItem = document.querySelector('[data-order="' + prevFeatureItemOrder + '"]');

      if (prevFeatureItemOrder >= 1) {
        prevFeatureItem.scrollIntoView({behavior: 'smooth'})
      } else {
        listTop.scrollIntoView({behavior: 'smooth'});
      }

    } else {
      let highestOrderVisibleItem;
      let nextFeatureItem;
      let nextFeatureItemOrder;

      visibleFeaturesArray.forEach(elem =>  orderDataAttributeArr.push(Math.max(elem.dataset.order)));
      highestOrderVisibleItem = Math.max(...orderDataAttributeArr);
      nextFeatureItemOrder = highestOrderVisibleItem + 1;
      nextFeatureItem = document.querySelector('[data-order="' + nextFeatureItemOrder + '"]');
      nextFeatureItem.scrollIntoView({behavior: 'smooth'});
    }
  }

  handleChevronDirection = (e) => {
    const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) == e.target.clientHeight || e.target.scrollHeight - Math.ceil(e.target.scrollTop) == e.target.clientHeight + 1;
    const top = Math.ceil(e.target.scrollTop) === 0;

    if (bottom) {
      this.setState({
       isChevronUp: true,
      })
    } else if (top) {
      this.setState({
        isChevronUp: false,
      })
    }
  }

  handlePurchaseSuccessful = () => {
    this.setState({purchaseSuccessful: true})
  }

  handleBackButtonClick = () => {
    const panel = (this.state.panel - 1) > -1 ? this.state.panel -= 1 : 0;
    this.setState({panel});
  }

  handleCancelButtonClick = () => {
    this.props.onCloseRequest();
  }

  handleCodeValidation = (newOptionalCode) => {
    const url = 'subscriptions/check_code';

    this.setState({
      isDiscountCodeProcessing: true,
    });

    apiPost(url, {
      stripeCouponCode: this.state.optionalCode || newOptionalCode,
      plan_id: this.state.planId,
    }).then((response) => {
      this.handleUpdateAfterDiscountCode(response);
      this.setState({
        isDiscountCodeProcessing: false,
        codeValidationButtonLabel: 'clear',
      });

    }).catch((err) => {
      this.handleDiscountCodeError();
      this.setState({
        isDiscountCodeProcessing: false,
        codeValidationButtonLabel: 'clear',
      });
      console.log(err)
    })
  }

  handleCodeValidationReset = () => {
    this.setState({
      isDiscountCodeValid: true,
      optionalCode: '',
      codeValidationButtonLabel: 'apply',
      discountedPlanPriceTotal: false,
      discountPercentage: null,
      currentActivePlanPriceTotal: this.state.preDiscountedPriceTotal,
    });
    this.fetchPlans();
  }

  handleTeachersLinkClick = () => {
    UiHelper.navigate('/teachers');
  }

  handleCompaniesLinkClick = () => {
    UiHelper.navigate('/companies');
  }

  handleResellerClick = () => {
    UiHelper.navigate('/reseller')
  }

  handleDiscountCodeError = () => {
    this.setState({
      isDiscountCodeValid: false,
    });
  }

  handleOnCloseRequest = () => {
    if (this.state.panel) {
      this.setState({panel: 0});
    }

    if (this.state.optionalCode.length >= 1) {
      this.setState({
        optionalCode: '',
      });
    }

    if (this.state.isOptionalCodeInputFieldVisible) {
      this.setState({
        isOptionalCodeInputFieldVisible: false,
      })
    }

    if (this.state.discountPercentage != '') {
      this.setState({
        discountPercentage: null,
      })
    }

    if (this.state.isSidePanelActive) {
      this.setState({
        isSidePanelActive: false,
      })
    }

    if (this.state.isChevronUp) {
      this.setState({
        isChevronUp: false,
      })
    }

    this.handleCodeValidationReset();
    this.fetchPlans();
    this.props.onCloseRequest();
  }

  handlePricingPlanClick = id => {
    const plan = this.state.pricingPlans.filter(p => {return p.id == id})[0];
    const total = !!(plan.discount_price_in_dollars) ? plan.discount_price_in_dollars : plan.total;

    this.setState({
      currentActivePlanPriceTotal: total,
      isPricingPlanActive: id,
      currentActivePlanClassName: plan.class,
      preDiscountedPriceTotal: plan.total,
    });
  }

  planSelectCta = () => {
    const url = `payments/subscription_plan_selection`;
    const params = {subscription_plan_id: this.state.isPricingPlanActive};

    this.setState({isProcessing: true});

    apiGet(url, params).then(setup_intent => {
      this.setState({
        panel: 1,
        isProcessing: false,
        clientSecret: setup_intent.client_secret,
        setupIntentId: setup_intent.id,
      });
      this.informPurchaseStarted();
    }).catch((err) => {
      EventBus.publish('upgrade:purchase:failed', {error: err.message}, true);
    });
  }

  informPurchaseStarted = () => {
    EventBus.publish('upgrade:purchase:started', {
      discount_code:  this.state.optionalCode,
      plan_name:      this.state.currentActivePlanClassName,
      plan_price:     this.state.currentActivePlanPriceTotal,
    }, true)
  }

  handleUpdateAfterDiscountCode = (response) => {

    if ((response.code_type == 'access') && (typeof response.did_activate !== 'undefined') && (response.did_activate) && (typeof response.subscription_id !== 'undefined') && (typeof response.subscription_plan_key != 'undefined')) {
      // If Access Code
      EventBus.publish('upgrade:purchase:access_code_redeemed', {}, true);

      this.setState({
        currentActivePlanPriceTotal: 0,
        currentActivePlanClassName: response.term,
      })

      this.handlePurchaseSuccessful()

    } else {
      // Elsif Discount Code
      const existingPlans = this.state.pricingPlans
      let pricingPlans = []

      response.plans.forEach(discount_plan  => {
        const plan = existingPlans.filter(p => { return p.id == discount_plan.id})[0]
        plan.normalized_discount_amount = discount_plan.normalized_discount_amount
        plan.discount_price_in_dollars = discount_plan.discount_price_in_dollars
        plan.discount_percentage = discount_plan.discount_percentage
        plan.dollars = discount_plan.dollars
        pricingPlans.push(plan)
      })

      const selectedPlan = pricingPlans.filter(p => { return p.id == this.state.isPricingPlanActive})[0];
      const newTotal = selectedPlan?.discount_price_in_dollars;
      const discountPercentage = response.discount_percentage;
      // const discountPercentage = selectedPlan?.discount_percentage;
      const preDiscountedPriceTotal = selectedPlan?.dollars;

      this.setState({
        currentActivePlanPriceTotal: newTotal,
        discountedPlanPriceTotal: true,
        discountPercentage,
        preDiscountedPriceTotal,
        pricingPlans,
      })

      EventBus.publish('upgrade:purchase:access_code_redeemed', {}, true);

    }

  }

  handleToggleOptionalCodeInputField = () => {
    this.setState(prevState => ({
      isOptionalCodeInputFieldVisible: !prevState.isOptionalCodeInputFieldVisible,
    }));
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerRocketAnimation = () => {
    setTimeout(() => this.setState({ isSidePanelActive: true}), 100)
  }

  triggerFeaturesObserver = () => {
    const listItems = document.querySelectorAll('.features .feature');

    // TODO: Move this...
    for (let i = 0; i < listItems.length; i++) {
      let computedStyles = window.getComputedStyle(listItems[i]);
      let cssOrder = computedStyles.getPropertyValue('order');
      listItems[i].setAttribute('data-order', cssOrder);
    }

    // Observe all items
    listItems.forEach((listItem) => {
      this.featureObserver(listItem, 'visible');
    })
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getDuration = (name) => {
    switch (name) {
      case "Month": return 1
      case "Semester": return 6
      case "Year": return 12
      case "Lifetime": return null
      case "shortAsPossible": return null
      default: return null
    }
  }

  getLabel = (duration, name) => {
    if (name === 'Lifetime') {
      return 'Lifetime';
    }

    if (name === 'shortAsPossible') {
      return 'shortAsPossible';
    }

    if (duration > 1) {
      return 'months'
    } else {
      return 'month'
    }
  }

  getMedianNum = (arr) => {
    const mid = Math.floor(arr.length / 2);
    const nums = [...arr].sort((a,b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid]) / 2;
  }

  fetchPlans = () => {

    const url = 'payments/plans';

    apiGet(url).then((resp) => {
      const plans = resp.plans.map( plan => {
        const duration = this.getDuration(plan.name);
        const label = this.getLabel(duration, plan.name);

          return {
            id: plan.id,
            class: plan.name.toLowerCase(),
            duration: duration,
            label: label,
            price: plan.normalized_amount,
            total: "$" + plan.dollar_amount,
            normalized_discount_amount: null,
            discount_price_in_dollars: null,
         }
      })

      this.setState({pricingPlans: plans})
    }).catch(apiError)
  }

  checkViewportSize() {
    const isSmallViewportSize = this.detectIfSmallDevice();

    this.setState({
      isSmallViewportSize: isSmallViewportSize,
    })
  }

  detectIfSmallDevice() {
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (viewportWidth <= 768) {
      return true;
    }

    return false;
  }

  featureObserver = (target, className) => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(className)
        } else {
          entry.target.classList.remove(className)
        }
      })
    }, { threshold: 0.8 })
    observer.observe(target)
  }

}

PaywallModal.propTypes = PT;

export default PaywallModal;
