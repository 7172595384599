
import EgressContext  from "_views/shared/smart-cards/animation/EgressContext";
import EventManager   from "@brainscape/event-manager";
import PropTypes      from "prop-types";
import React          from "react";

class CardTransition extends React.Component {
  constructor(props) {
    super(props);

    this.state = { nextName: "in", prevName: null };
    this.value = null;
    this.events = new EventManager();
  }

  render = () => {
    const nextView = React.Children.only(this.props.children);
    const prevView = this.state.prevView;

    return (
      <>
        <div
          className={ctName(this.state.nextName)}
          style={this.props.style}
          key={`${nextView.key}-ct`}
        >
          {React.cloneElement(nextView, { transitionState: this.state.nextName })}
          {/* {nextView} */}
        </div>

        {prevView != null && (
          <EgressContext.Provider value={true}>
            <div
              className={ctName(this.state.prevName)}
              style={this.props.style}
              key={`${prevView.key}-ct`}
            >
              {React.cloneElement(prevView, { transitionState: this.state.prevName })}
              {/* {prevView} */}
            </div>
          </EgressContext.Provider>
        )}
      </>
    );
  };

  componentDidMount = () => {
    this.transitionStates(
      { nextName: "in", prevName: null },
      { nextName: null }
    );
  };

  componentDidUpdate = (prevProps) => {
    const prevView = React.Children.only(prevProps.children);
    const nextView = React.Children.only(this.props.children);

    if (prevView?.key === nextView?.key) return;

    this.transitionStates(
      { prevView: prevView, prevName: null, nextName: "in" },
      { prevName: "out", nextName: null },
      { prevView: null, prevName: null, nextName: null }
    );
  };

  componentWillUnmount = () => this.events.disable();

  transitionStates = (s1, s2 = null, s3 = null) => {    
    this.events.clearTimeout("cssDelay");
    this.events.clearTimeout("prevView");

    this.setState(s1, () => {

      this.events.onTimeout("cssDelay", 10, () => {

        if (s2) {
          this.setState(s2, () => {

            if (s3) {
              this.events.onTimeout("prevView", DELAY, () => {

                this.setState(s3);
              });
            }
          });
        }
      })
    })
  };
}

CardTransition.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const ctName = (addl) => {
  return addl == null ? "card-transition" : `card-transition ${addl}`;
};

const ANIMATION_DURATION = 3000; // Need to update.
const DELAY = ANIMATION_DURATION + 10;

export { CardTransition as default };
