
import { apiPost } from '_core/Api2';  // can't use model because endpoint expects an array as data

const ebEvent = {  // short for "EventBus Event"

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  create(eventsData) {
    const path = 'events';
    const headers = {'X-Brainscape-EventKey': '6d67869e-e3d7-4eff-8c4c-5b38d6dea150'};

    return apiPost(path, eventsData, headers)
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default ebEvent;
