
import model from '_core/newModel';

const categorySubscription = {

  /*
  ==================================================
   CRUD METHODS (returns promises)
  ==================================================
  */

  create(categoryId) {
    return model.create(`categories/${categoryId}/subscribe`).then(category => model.publish('category-subscription:created', {category: category}));
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default categorySubscription;
