
import PropTypes                 from 'prop-types';
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';

import {toClassStr}              from '_utils/UiHelper';

const PT = {      
  addClasses:              PropTypes.string,
  isOpen:                  PropTypes.bool,
  onCloseRequest:          PropTypes.func, 
  resolveButtonText:       PropTypes.string,
  heading:                 PropTypes.string,
  subHeading:              PropTypes.string,
  body:                    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

class DialogModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['dialog-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        {this.renderImage()}

        <div className='modal-inner-content'>
          {this.renderHeading()}
          {this.renderSubHeading()}
          {this.renderBody()}
        </div>

        <div className="modal-actions">

          <PillButton
            addClasses="back-modal-button" 
            label={this.renderResolveButtonLabel()}
            onClick={this.props.onCloseRequest}
          />

        </div>
      </Modal>
    );
  }

  renderImage = () => {
    if (!this.props.imageType) {
      return null;
    }

    const classes = toClassStr(['dialog-modal-image', this.props.imageType]);

    return (
      <div className={classes}></div>
    );
  }

  renderHeading = () => {
    if (!this.props.heading) {
      return null;
    }

    return (
      <div className="dialog-modal-heading">{this.props.heading}</div>
    );
  }

  renderSubHeading = () => {
    if (!this.props.subHeading) {
      return null;
    }

    return (
      <div className="dialog-modal-sub-heading">{this.props.subHeading}</div>
    );
  }

  renderBody = () => {
    if (!this.props.body) {
      return null;
    }

    return (
      <div className="dialog-modal-body">{this.props.body}</div>
    );
  }

  renderResolveButtonLabel() {
    if (this.props.resolveButtonText) {
      return this.props.resolveButtonText;
    }

    return "Ok";
  }
}

DialogModal.propTypes = PT;

export default DialogModal;
