// This is a very simple event event library written for the application
// events.
//
// Note: There is no retry logic.
//
// TODO: Create a hook to make sure we flush the queue before the user
//       leaves the page.  Alternatly, we might use local storage.

let EB_EVENTS       = [];
let EB_EVENT_TIMER  = null;

class EventBus {
  static publish(event, data, flush) {
    var e = {event: event}
    if (data) { e['data'] = data }

    EB_EVENTS.push(e);

    clearTimeout(EB_EVENT_TIMER);
    if (flush) {
      EventBus.flush();
    } else {
      EB_EVENT_TIMER = setTimeout(EventBus.flush, 150); // 150 is arbitrary
    }
  }

  static flush() {
    if (EB_EVENTS.length == 0) { return }

    var events = EB_EVENTS;
    EB_EVENTS = []

    $.ajax({
      url:      '/api/events',
      type:     'post',
      data:     JSON.stringify(events),
      dataType: 'json',

      // Allow event processing when not signed in.
      headers:{'X-Brainscape-EventKey': '6d67869e-e3d7-4eff-8c4c-5b38d6dea150'},
    });
  }
}

export default EventBus;
