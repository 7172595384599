
import EventManager                 from '@brainscape/event-manager';
import PropTypes                    from 'prop-types';
import React                        from 'react';

import {
  DismissButton,
  EditButton,
} from '_views/shared/IconButton';

import {
  apiDelete,
  apiGet,
  apiPatch,
} from '_core/Api2';

import {toClassStr}       from '_utils/UiHelper';

const PT = {
  addClasses:             PropTypes.string,
  cardId:                 PropTypes.node,
  deckId:                 PropTypes.node,
  packId:                 PropTypes.node,
};


class ReviewCardActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingEdit: false,
      isProcessingDelete: false,
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const classes = toClassStr(['review-card-actions', this.props.addClasses]);

    return (
      <div className={classes}>
        <EditButton
          addClasses='action-button edit-button'
          cardId={this.props.cardId}
          deckId={this.props.deckId}
          isProcessing={this.state.isProcessingEdit}
          onClick={this.handleEditButtonClick}
          packId={this.props.packId}
        />

        <DismissButton
          addClasses='action-button delete-button'
          cardId={this.props.cardId}
          deckId={this.props.deckId}
          isProcessing={this.state.isProcessingDelete}
          onClick={this.handleDeleteButtonClick}
          packId={this.props.packId}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCardDeleted = () => {
    location.reload();
  }

  handleCardUpdated = () => {
    location.reload();
  }

  handleDeleteButtonClick = () => {
    const actionText = "remove this card from the user's deck";

    this.triggerCautionModalOpen({
      actionText: actionText,
      resolveButtonText: 'Yes, remove card',
      onResolution: this.removeCard,
    });
  }

  handleEditButtonClick = () => {
    this.setState({
      isProcessingEdit: true,
    });

    const currentUser = {
      flags: {
        isBscAdmin: true,
        isPro: true,
      },
    };
    
    this.getResources().then(data => {
      this.triggerEditCardModalOpen({
        card: data.card,
        currentUser: currentUser,
        deck: data.deck,
        editMode: 'advanced',
        onCardUpdated: this.handleCardUpdated,
        pack: data.pack,
      });

      this.setState({
        isProcessingEdit: false,
      });
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalOpen = (viewProps) => {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerEditCardModalOpen = (viewProps) => {
    EventManager.emitEvent('edit-card-modal:open', viewProps);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getResources = () => {
    const {cardId, deckId, packId} = this.props;
    const deckPath = `packs/${packId}/decks/${deckId}?is_web=true`;
    const cardPath = `packs/${packId}/decks/${deckId}/cards/${cardId}?is_web=true`;

    return new Promise((resolve, reject) => {
      try {
        apiGet(deckPath).then(deckData => {
          apiGet(cardPath).then(card => {
            resolve({
              pack: deckData.pack,
              deck: deckData.deck,
              card: card,
            });
          });
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  }

  removeCard = () => {
    const {packId, deckId, cardId} = this.props;
    const path = `packs/${packId}/decks/${deckId}/cards/${cardId}`;

    this.setState({
      isProcessingDelete: true,
    });

    apiDelete(path).then(() => {
      this.setState({
        isProcessingDelete: false,
      }, this.handleCardDeleted);
    })
  }
}

ReviewCardActions.propTypes = PT;

export default ReviewCardActions;
