
import { AiListParser }                         from '_services/AiListParser';
import { trackEvent }                           from '_services/AnalyticsService';
import EventManager                             from '@brainscape/event-manager';
import packDeckCard                             from '_models/packDeckCard';
import MakeFlashcardsModal                      from '_views/modals/ai/MakeFlashcardsModal';
import ParseCardListService                     from '_services/ParseCardListService';
import React, { useState, useEffect, useRef }   from 'react';
// import * as Sentry                              from '@sentry/browser';


// TODO: Checking for the following error messages is a temporary solution. We should have a more robust way to check for these errors.
const AI_TOKEN_EXCEPTION_FREE_USER_MSG = 'You have exceeded your free AI token quota.';
const AI_TOKEN_EXCEPTION_PRO_USER_MSG = 'You have exceeded your daily AI token quota.';
const AI_PARSABLE_FILE_TYPES = ['csv', 'tsv', 'txt'];
const PROGRESS_BAR_COMPLETION_DELAY = 3500;

let progressBarTimeout = null;


const MakeFlashcardsModalController = ({currentPackId}) => {  
  /*
  ==================================================
   INIT 
  ==================================================
  */

  const eventManager = new EventManager();


  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [cardRows, setCardRows] = useState([]);
  const [currentPanel, setCurrentPanel] = useState(''); // initial, paste, upload, review, loading, freeAiException, proAiException, generalAiException
  const [hasInvalidInput, setHasInvalidInput] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const currentPackIdRef = useRef(currentPackId);
  const errorMessage = useRef('');
  const fileExtension = useRef('');
  const fileName = useRef('');
  const inputArrayBuffer = useRef(null);
  const inputMethod = useRef(null);
  const isAiListParser = useRef(false);
  const isAiProcessComplete = useRef(false);
  const preAiParseCardRowCount = useRef(0);
  

  // on Mount
  useEffect(() => {
    clearTimeout(progressBarTimeout);

    /* SUBSCRIBE TO EVENTS */
    eventManager.addListener('parse-card-list-modal:open', handleOpenModal);
    eventManager.addListener('parse-card-list-modal:close', handleCloseModal);
    eventManager.addListener('parse-card-list-modal:paste-list-request', handlePasteListRequest);
    eventManager.addListener('parse-card-list-modal:upload-file-request', handleUploadFileRequest);
    
    eventManager.addListener('paste-list-panel:input-text-submit', handleInputTextSubmit);
    eventManager.addListener('paste-list-panel:go-back-request', handleGoBackRequest);

    eventManager.addListener('upload-file-panel:input-file-submit', handleInputFileSubmit);
    eventManager.addListener('upload-file-panel:go-back-request', handleGoBackRequest);
    eventManager.addListener('upload-file-panel:file-selected', handleFileSelected);
    eventManager.addListener('upload-file-panel:file-selection-error', handleFileSelectionError);

    eventManager.addListener('review-card-rows-panel:add-cards-request', handleAddCardsRequest);
    eventManager.addListener('review-card-rows-panel:fix-with-ai-request', handleFixWithAiRequest);
    eventManager.addListener('review-card-rows-panel:cancel-import-request', handleCancelImportRequest); 

    // on Unmount
    return () => {
      eventManager.disable();
      clearTimeout(progressBarTimeout);
    };
  }, []);

  useEffect(() => {
    currentPackIdRef.current = currentPackId;
  }, [currentPackId]);  

  useEffect(() => {
    if (isModalOpen) {
      trackEvent('importer', `view/${currentPanel}`);
    }
  }, [currentPanel, isModalOpen]);
    
  useEffect(() => {
    if (currentPanel !== 'generalAiException') {
      errorMessage.current = '';
    }
  }, [currentPanel]);
  

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleAddCardsRequest = (eventData) => {
    addCardsAndDeckToPack(eventData.cardRows);
  }

  const handleAcceptedDataParseError = (err) => {
    console.error(err);
    errorMessage.current = `Could not process the input data: ${err.message}`;
    setHasInvalidInput(true);
    setIsProcessing(false);

    trackEvent('importer', 'choose_file', {error: true});    
  }

  const handleAiListParseCompleted = () => {
    clearTimeout(progressBarTimeout);
    isAiProcessComplete.current = true;

    // let the loading panel animate to 100% before showing the review panel
    progressBarTimeout = setTimeout(() => {
      setCurrentPanel('review');
      isAiProcessComplete.current = false;

      clearTimeout(progressBarTimeout);
    }, PROGRESS_BAR_COMPLETION_DELAY);
  }

  const handleAiListParseError = (err) => {
    const errMessage = err.message;

    if (errMessage.indexOf(AI_TOKEN_EXCEPTION_FREE_USER_MSG) != -1) {
      setCurrentPanel('freeAiException');
      trackEvent('importer', 'ai/quota_exceeded', {isPro: false});
      return true;
    }

    if (errMessage.indexOf(AI_TOKEN_EXCEPTION_PRO_USER_MSG) != -1) {
      setCurrentPanel('proAiException');
      trackEvent('importer', 'ai/quota_exceeded', {isPro: true});
      return true;
    }

    errorMessage.current = errMessage;
    
    setCurrentPanel('generalAiException');
    trackEvent('importer', 'ai/general_ai_exception', {
      err: errorMessage.current,
    });

    // if (Sentry) {
    //   Sentry.captureException(err);
    // }
  }

  const handleCancelImportRequest = () => {
    const initialImportPanel = inputMethod.current === 'file' ? 'upload' : 'paste';
    setCurrentPanel(initialImportPanel);
    trackEvent('importer', 'cancel/re-import');    
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    trackEvent('importer', 'cancel/exit');    
  };

  const handleFileSelected = () => {
    setHasInvalidInput(false);
    errorMessage.current = '';
  }

  const handleFileSelectionError = (eventData) => {
    setHasInvalidInput(true);
    errorMessage.current = eventData.errorMessage;
  }

  const handleFixWithAiRequest = () => {
    ParseCardListService.setParser(AiListParser);
    isAiListParser.current = true;
    setCurrentPanel('loading');
    parseInputData({arrayBuffer: inputArrayBuffer.current, parserName: 'AiListParser'});
  }

  const handleGoBackRequest = () => {
    setCurrentPanel('initial');
  }

  const handleInputFileSubmit = (eventData) => {
    setIsProcessing(true);
    isAiListParser.current = false;
    fileExtension.current = ''; // reset file extension
    fileName.current = ''; // reset file name

    ParseCardListService.acceptFile(eventData.inputFile).then((inputData) => {
      trackEvent('importer', 'choose_file', {
        name: inputData.fileName,
        size: inputData.arrayBuffer.byteLength,
      });

      parseInputData(inputData);
    }).catch((err) => {
      trackEvent('importer', 'choose_file', { error: true });
      handleAcceptedDataParseError(err);
    });
  };

  const handleInputTextSubmit = (eventData) => {
    setIsProcessing(true);

    ParseCardListService.acceptText(eventData.inputText).then((inputData) => {
      parseInputData(inputData);
    }).catch((err) => {
      handleAcceptedDataParseError(err);
    });
  };

  const handleOpenModal = (eventData) => {
    setCurrentPanel('initial');
    isAiListParser.current = false;
    setIsProcessing(false);
    setHasInvalidInput(false);
    setIsModalOpen(true);
  };
  
  const handleParseInputDataError = (err) => {
    if (isAiListParser.current) {
      handleAiListParseError(err);
    }

    errorMessage.current = `Could not parse the input data: ${err.message}.`;
    setIsProcessing(false);
    setHasInvalidInput(true);
  }

  const handlePasteListRequest = () => {
    inputMethod.current = 'text';
    setCurrentPanel('paste');
  }

  const handleUploadFileRequest = () => {
    inputMethod.current = 'file';
    setCurrentPanel('upload');
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  const publishPackDeckCardsCreated = (packId, deckId, cardCount) => {
    EventManager.emitEvent('pack-deck-cards:created', {
      deckId: deckId,
      packId: packId,
      newCardCount: cardCount,
    })
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const addCardsAndDeckToPack = (cardRows) => {
    const deckName = getDeckName();
    const shouldSuppressModelPublish = true;
    setIsProcessing(true);

    packDeckCard.create(currentPackIdRef.current, cardRows, deckName, shouldSuppressModelPublish)
      .then((rs) => {
        trackEvent('importer', 'add', {
          cardCount: cardRows.length,
          packId: currentPackIdRef.current,
          deckId: rs.deckId,
        }); 

        publishPackDeckCardsCreated(currentPackIdRef.current, rs.deckId, cardRows.length);
      })
      .catch(err => {
        console.error(err);
      });
  }

  const getDeckName = () => {
    if (inputMethod.current == 'file') {
      const newFileName = ParseCardListService.getFileName();

      if (!newFileName) {
        return 'Untitled Deck';
      }

      return newFileName.split('.').slice(0, -1).join('.');
    }

    return 'Untitled Deck';
  }

  const getFileExtension = (_fileName) => {
    if (!_fileName) { return null; }

    return _fileName.split('.').pop();
  }

  const isAiParsable = () => {
    if (inputMethod.current === 'text') {
      return true;
    }

    if (fileExtension.current && AI_PARSABLE_FILE_TYPES.includes(fileExtension.current)) {
      return true;
    }

    return false;
  }

  const parseInputData = (inputData) => {
    inputArrayBuffer.current = inputData.arrayBuffer;
    fileName.current = inputData.fileName;
    fileExtension.current = getFileExtension(inputData.fileName);

    if (inputData.parserName === 'AiListParser') {
      trackEvent('importer', 'fix_with_ai');
    } else {
      trackEvent('importer', 'analyze', {name: inputData.fileName});
    }

    ParseCardListService.parseArrayBuffer()
      .then(parsedCardRows => {
        setHasInvalidInput(false); 
        setIsProcessing(false);

        if (inputData.parserName === 'AiListParser') {
          setCardRows(parsedCardRows);
          handleAiListParseCompleted();
          return true;
        }

        preAiParseCardRowCount.current = parsedCardRows.length;
        setCardRows(parsedCardRows);
        setCurrentPanel('review');
      })
      .catch(err => {
        handleParseInputDataError(err);
      });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  
  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  if (isModalOpen) {
    return (
      <MakeFlashcardsModal
        cardRows={cardRows}
        currentPanel={currentPanel}
        errorMessage={errorMessage.current}
        hasInvalidInput={hasInvalidInput}
        isAiListParser={isAiListParser.current}
        isAiParsable={isAiParsable()}
        isProcessing={isProcessing}
        isAiProcessComplete={isAiProcessComplete.current}
      />
    );
  }

  return null;
}

export default MakeFlashcardsModalController;
