
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';

import {toClassStr}              from '_utils/UiHelper';

class InfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessing: false,
    };

    /*
      this.props:
        addClasses,
        isOpen,
        message,
        onCloseRequest,
        resolveButtonText,
        title,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['info--modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        {this.renderTitle()}
        {this.renderMessage()}

        <div className="modal-actions">

          <PillButton
            addClasses="ack-modal-button" 
            label={this.renderResolveButtonLabel()}
            onClick={this.props.onCloseRequest}
          />

        </div>
      </Modal>
    );
  }

  renderTitle() {
    if (this.props.title) {
      return (
        <div className="modal-title">{this.props.title}</div>
      );
    }

    return (
      <div className="modal-title">Info</div>
    );
  }

  renderMessage() {
    if (this.props.message) {
      return (
        <div className="modal-message">{this.props.message}</div>
      );
    }

    return (
      <div className="modal-message">It's All Good.</div>
    );
  }

  renderResolveButtonLabel() {
    if (this.props.resolveButtonText) {
      return this.props.resolveButtonText;
    }

    return "Ok";
  }
}

export default InfoModal;
