
// substrate and utils
import CookieHelper                     from '_utils/CookieHelper';
import EventManager                     from '@brainscape/event-manager';
import PropTypes                        from 'prop-types';
import React                            from 'react';
import SessionStorageHelper             from '_utils/SessionStorageHelper';
import TimeHelper                       from '_utils/TimeHelper';
import UiHelper                         from '_utils/UiHelper';
              
// models
import studyCard                        from '_models/studyCard';
import studyDeckSeed                    from '_models/studyDeckSeed';
import studyMixSeed                     from '_models/studyMixSeed';
import studyPackSeed                    from '_models/studyPackSeed';

import userCard                         from '_models/userCard';
import userLocalStore                   from '_models/userLocalStore';

// concerns
import currentUserConcern               from '_concerns/currentUserConcern';
import estimatedTimeLeftConcern         from '_concerns/estimatedTimeLeftConcern';
import StudySessionConcern              from '_concerns/StudySessionConcern';

// views
import StudyPage                        from '_views/new-study/desktop/StudyPage';
import StudyScreen                      from '_views/new-study/mobile/StudyScreen';
      

const PT = { 
  initialCardId:              PropTypes.node,
  initialDeckId:              PropTypes.node,
  initialPackId:              PropTypes.node,
  roundLength:                PropTypes.number,
  studyMixIds:                PropTypes.array,
  studyMixType:               PropTypes.string,  // 'progressive' or 'random'
  userExperiments:            PropTypes.object,
  userFeatures:               PropTypes.object,
  userFlags:                  PropTypes.object,
};

const INTER_CARD_DELAY = 200;
const TIMER_CLICK = 250;
const DISPLAY_INTERVAL = 1000;
const MAX_CARD_STUDY_DURATION = (60 * 1000) * 10; // ten minutes in ms
const RETURN_TO_DASHBOARD_DELAY = 3000;


class StudyController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkpointType: this.getCheckpointType(),
      currentCard: {},
      currentCardFace: 'question',
      currentCardId: props.initialCardId || null,
      currentCardLevel: 0,
      currentDeck: {},
      currentDeckId: props.initialDeckId || null,
      currentPack: {},
      currentPackId:  props.initialPackId || null,
      currentUser: props.initialUser,
      currentUserId: props.initialUser?.userId,
      currentRoundStats: {
        cardCount: 0,
        cardsRated: 0,
        cardRatings: [],
        cardNumber: 0,
        confidenceGained: 0,
        cardDisplayTime: 0,
        roundLength: this.props.roundLength,
        roundDisplayTime: 0,
        roundNumber: 0,
        levelCounts: [],
      },
      currentSessionStats: {
        masteryPercentage: 0,
        masteryRatio: 0,
        roundsCompleted: 0,
        roundHistory: [],
      },
      estimatedTimeLeft: null,
      isAtCheckpoint: false,
      isLoadingCardDisplayData: false,
      isLoadingUser: true,
      isLoadingStudySession: true,
      isMobileViewportSize: null,
      isNewRound: true,
      isNewStudySession: true,
      isProcessingEstimatedTimeLeft: false,
      scope: null,
      seedData: {},
      studyCards: {},
      studyDecks: {},
      studyMixIds: props.studyMixIds || null,
      studyPacks: {},
      totalTimeStudied: 0,
      userCards: [],
    };

    this.events = new EventManager();
    this.studySession = null;

    this.nextStepTimeout = null;
    this.timerInterval = null;
    this.returnToDashboardTimeout = null;

    this.isHandlingBadData = false;
    this.isTimerRunning = false;

    this.currentCardTime = 0;
    this.currentRoundTime = 0;

    this.currentCardStartTime = 0;
    this.currentRoundStartTime = 0;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.subscribeToEvents();
    this.manageViewport();
    this.initCurrentResources();
    this.startWindowResizeMonitor();
  }

  componentDidUpdate(prevProps) {
  }

  componentWillUnmount() {
    this.stopWindowResizeMonitor();
    this.unsubscribeToEvents();
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }


  /*
  ==================================================
   INITIALIZE CORE RESOURCES
  ==================================================
  */

  initCurrentResources = () => {
    this.initCurrentUserData();
    this.initStudySessionData();
  }

  initCurrentUserData = () => {
    const user = this.props.initialUser;
    currentUserConcern.get(user); // will retrieve more user data and publish for handler to complete
  }

  initStudySessionData = () => {
    const getStudySessionSeedDataPromise = this.getStudySessionSeedData();
    const getUserCardsPromise = userCard.index();

    Promise.all([getStudySessionSeedDataPromise, getUserCardsPromise]).then(results => {
      this.setState({
        scope: results[0].scope,
        seedData: results[0].seedData,
        userCards: results[1],
      }, () => {
        this.initStudySession();
      });
    }).catch(err => {
      console.log('in initStudySessionData. error getting study session data. err:', err);
      this.handleBadStudySessionData(err);
    });
  }

  getStudySessionSeedData = () => {
    return new Promise((resolve, reject) => {
      try {
        const scope = this.getStudySessionScope();
        const packId = this.props.initialPackId;
        const deckId = this.props.initialDeckId;
        const userId = this.state.currentUserId;

        switch (scope) {
          case 'deck':
            studyDeckSeed.show(packId, deckId, userId).then(seedData => {
              resolve({
                scope: scope,
                seedData: seedData,
              });
            }).catch(err => {
              reject(err);
            });
          break;
          case 'pack':
            studyPackSeed.show(packId, userId).then(seedData => {
              resolve({
                scope: scope,
                seedData: seedData,
              });
            }).catch(err => {
              reject(err);
            });
          break;
          case 'study-mix':
            studyMixSeed.postShow(this.props.studyMixIds, userId).then(seedData => {
              resolve({
                scope: scope,
                seedData: seedData,
              });
            }).catch(err => {
              reject(err);
            });
          break;
          case 'smart-study':
            // smart study gets handled here
            resolve(null);
          break;
        };
      } catch(err) {
        console.log('Problem getting StudySessionSeedData. err:', err);
        reject(err);
      }
    });
  }

  getStudySessionScope = () => {
    if (this.props.studyMixIds) {
      return 'study-mix';
    }

    const packId = this.props.initialPackId;
    const deckId = this.props.initialDeckId;

    if (packId && deckId) {
      return 'deck';
    }

    if (packId) {
      return 'pack';
    }

    // TODO: create case for smart study

    return null;
  }

  initStudySession = () => {
    const {currentUser, currentPackId, currentDeckId, currentCardId, scope, seedData, userCards} = this.state;

    try {
      this.studySession = new StudySessionConcern({
        deckId: currentDeckId, 
        initialCardId: currentCardId,
        isFtse: currentUser.flags.isFtse,
        packId: currentPackId, 
        roundLength: this.props.roundLength,
        scope: scope,
        seedData: seedData,
        userCards: userCards,
        userId: currentUser.userId,
      });

      this.studySession.startSession(); 
      // after starting a session, the StudySessionConcern will emit a 'study-session:current-ids-updated' event which will be handled by the handler below in EVENT SUBSCRIPTIONS, invoking the display of a Study Card
    } catch(err) {
      console.log('Problem setting up study session:', err);
      this.handleBadStudySessionData(err);
    }
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('card-confidence:rated',                     this.handleCardConfidenceRated);
    this.events.addListener('card:updated',                              this.handleCardUpdated);
    this.events.addListener('current-user:retrieved',                    this.handleCurrentUserRetrieved);
    this.events.addListener('ftse:end-requested',                        this.handleFtseEndRequested);
    this.events.addListener('study-session:card-level-updated',          this.handleStudySessionCardLevelUpdated);
    this.events.addListener('study-session:current-ids-updated',         this.handleStudySessionCurrentIdsUpdated);
    this.events.addListener('study-session:close-requested',             this.handleCloseStudySessionRequested);
    this.events.addListener('study-session:dismiss-checkpoint-requested',this.handleDismissCheckpointRequested);
    this.events.addListener('study-session:new-round-requested',         this.handleNewRoundRequested);
    this.events.addListener('smart-card:reveal-card-face-requested',     this.handleSmartCardRevealCardFaceRequested); 
    this.events.addListener('user-pack-config:mix-type',                 this.handleUpdateStudyMixTypeRequested);
    this.events.addListener('user-prefs:toggle-continuous-study',        this.handleToggleContinuousStudyRequested);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    // if (this.state.isMobileViewportSize) {
    //   return this.renderMobileScreen();
    // }

    return this.renderDesktopPage();
  }

  renderDesktopPage() {
    return (
      <StudyPage
        checkpointType={this.state.checkpointType}
        currentCard={this.state.currentCard}
        currentCardFace={this.state.currentCardFace}
        currentCardId={this.state.currentCardId}
        currentCardLevel={this.state.currentCardLevel}
        currentDeck={this.state.currentDeck}
        currentDeckId={this.state.currentDeckId}
        currentPack={this.state.currentPack}
        currentPackId={this.state.currentPackId}
        currentRoundStats={this.state.currentRoundStats}
        currentSessionStats={this.state.currentSessionStats}
        currentStepIndex={this.state.currentStepIndex}
        currentUser={this.state.currentUser}
        currentUserId={this.state.currentUserId}
        estimatedTimeLeft={this.state.estimatedTimeLeft}
        isAtCheckpoint={this.state.isAtCheckpoint}
        isFtse={this.state.currentUser.flags?.isFtse}
        isFtue={this.state.currentUser.flags?.isFtue}
        isLoadingCardDisplayData={this.state.isLoadingCardDisplayData}
        isLoadingStudySession={this.state.isLoadingStudySession}
        isMobileViewportSize={this.state.isMobileViewportSize}
        isProcessingEstimatedTimeLeft={this.state.estimatedTimeLeft}
        roundLength={this.props.roundLength}
        totalTimeStudied={this.state.totalTimeStudied}
      />
    );
  }

  renderMobileScreen() {
    return (
      <StudyScreen
        currentCard={this.state.currentCard}
        currentCardId={this.state.currentCardId}
        currentDeck={this.state.currentDeck}
        currentDeckId={this.state.currentDeckId}
        currentPack={this.state.currentPack}
        currentPackId={this.state.currentPackId}
        currentRound={this.state.currentRound}
        currentStepIndex={this.state.currentStepIndex}
        currentUser={this.state.currentUser}
        currentUserId={this.state.currentUserId}
        isAtCheckpoint={this.state.isAtCheckpoint}
        isFtse={this.state.isFtse}
        isFtue={this.state.isFtue}
        isLoadingCardDisplayData={this.state.isLoadingCardDisplayData}
        isLoadingStudySession={this.state.isLoadingStudySession}
        isMobileViewportSize={this.state.isMobileViewportSize}
        roundStepCount={this.props.roundStepCount}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleBadStudySessionData = (err) => {
    console.log('in handleBadStudySessionData. err:', err);

    if (this.isHandlingBadData) {
      return false;
    }

    this.isHandlingBadData = true;
    this.triggerToastOpen('Problem with Study Request. Returning to Dashboard', 'error');

    this.returnToDashboardTimeout =  setTimeout(() => {
      console.log('returning to dashboard');
      UiHelper.navigate('/');
    }, RETURN_TO_DASHBOARD_DELAY);
  }

  handleCardConfidenceRated = (eventData) => { // this is a rating of the card during study
    const currentCardId = this.state.currentCardId;
    const newLevel = eventData.level;

    this.pauseTimer();
    this.studySession.rateCard(this.state.currentCardId, newLevel);
  }

  handleCardUpdated = (eventData) => { // this is an edit update of the card during study
    const {currentPackId, currentDeck, currentDeckId} = this.state;

    if (!(eventData.packId == currentPackId && eventData.deckId == currentDeckId)) {
      return false;
    }

    const updatedCard = eventData.card;
    const updatedCardId = updatedCard?.cardId;
    const studyCards = {...this.state.studyCards};

    if (updatedCardId && studyCards[updatedCardId]) {
      studyCards[updatedCardId] = updatedCard;

      this.setState({
        currentCard: updatedCard,
        studyCards: studyCards,
      }, () => {
        this.triggerToastOpen('Card updated', 'success');
      });
    }
  }

  handleCurrentUserRetrieved = (eventData) => {
    this.setState({
      currentUser: {...this.state.currentUser, ...eventData.currentUser},
      isLoadingUser: false,
    });
  }

  handleCloseStudySessionRequested = (eventData) => {
    if (this.state.currentUser.flags?.isFtse) {
      if (eventData?.endFtse) {
        this.handleFtseEndRequested();
      } else {
        return false; 
      }
    }

    UiHelper.navigate(`/l/dashboard/${this.props.currentPackId}`);
  }

  handleDismissCheckpointRequested = () => {
    this.setState({
      isAtCheckpoint: false,
    });
  }

  handleFtseEndRequested = () => {
    const currentUser = {...this.state.currentUser};
    currentUser.flags = currentUser.flags || {};
    currentUser.flags.isFtse = false;

    this.setState({
      currentUser: currentUser,
    });
  }

  handleNewRoundRequested = () => {
    this.setState({
      isAtCheckpoint: false,
      isNewRound: true,
    }, () => {
      this.studySession.chooseNextCard();
    })
  }

  handleSmartCardRevealCardFaceRequested = (eventData) => {
    this.setState({
      currentCardFace: eventData?.face || 'answer',
      isAtCheckpoint: false,
    }, () => {
      this.triggerTooltipClose();
    });
  }

  handleStudySessionCardLevelUpdated = (eventData) => {
    const prevLevel = this.state.currentCardLevel;
    const timeSpent = this.getCardTimeSpent();
    const ratedCardInfo = this.studySession.getCardInfo(eventData.cardId);
    ratedCardInfo.card.prevLevel = prevLevel;

    this.updateRemoteUserCardData(ratedCardInfo, timeSpent).then(() => {
      if (this.hasReachedCheckpoint()) {
        this.calculateEstimatedTimeLeft();
      }
    });

    this.updateLocalData(ratedCardInfo).then(() => {
      this.advanceStudyRound(INTER_CARD_DELAY);
    }).catch(err => {
      console.error(err);
    });
  }

  handleStudySessionCurrentIdsUpdated = (eventData) => {
    const {packId, deckId, cardId, level} = eventData;

    if (packId != this.state.currentPackId) {
      this.updateLastPackCookies(packId);
    }

    this.getNewCard(packId, deckId, cardId).then(resourceData => {
      if (this.state.isNewStudySession) {
        this.setNewSessionStats();
        this.setNewRoundStats();
      } else if (this.state.isNewRound) {
        this.setNewRoundStats();
      } else {
        this.setNewCardStats();
      }

      const {pack, deck, card, cards} = resourceData;
      this.updateCardDisplayData(pack, deck, card, cards);
      
      const localizedPack = this.localizePack(pack);

      this.setState({
        currentPack: localizedPack,
        currentPackId: pack.packId,
        currentDeck: deck,
        currentDeckId: deck.deckId,
        currentCard: card,
        currentCardFace: 'question',
        currentCardId: card.cardId,
        currentCardLevel: level,
        isLoadingStudySession: false,
        isNewRound: false,
        isNewStudySession: false,
      });
    }).catch(err => {
      console.log('something went wrong getting new resources for new session ids');
    });
  }

  handleToggleContinuousStudyRequested = () => {
    const newContinuousStudy = !this.getContinuousStudyPref();
    const checkpointType = (newContinuousStudy) ? 'sidebar' : 'fullScreen';

    this.updateContinuousStudyPref(newContinuousStudy);

    this.setState({
      checkpointType: checkpointType,
    });
  }

  handleUpdateStudyMixTypeRequested = (eventData) => {
    const {mixType, packId} = eventData;

    this.triggerConfirmModalOpen({
      actionText: `set your mix type preference on the current class, which will begin a new study session. (Your previous ratings are already saved)`,
      resolveButtonText: 'Ok',
      onResolution: () => {
        this.updateStudyMixType(mixType, packId);
      },
    });
  }

  handleWindowResize = (e) => {
    this.manageViewport();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerDropdownCloseAllRequest = () => {
    EventManager.emitEvent('dropdown:close-all-requested', {});
  }

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type, duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }

  triggerTooltipClose = () => {
    EventManager.emitEvent('tooltip:close', {});
  }

  triggerTooltipOpen = (opts) => {
    EventManager.emitEvent('tooltip:open', {
      content: opts.content,
      elem: opts.elem,
      position: opts.position,
    });
  };


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishNewCardPresented = (state) => {
    // we use a cloned copy of a specific instance of state to avoid race conditions
    EventManager.emitEvent('study-session:new-card-presented', {
      cardNumber: state.currentRoundStats.cardNumber,
      currentCardId: state.currentCardId,
      currentCardFace: state.currentCardFace,
      cardRatings: state.currentRoundStats.cardRatings,
      roundLength: state.currentRoundStats.roundlength,
    });
  }

  publishNewRoundStarted = () => {
    EventManager.emitEvent('study-session:new-round-started', {});
  }

  publishNewSessionStarted = () => {
    EventManager.emitEvent('study-session:new-session-started', {});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  advanceStudyRound = (delay=0) => {
    this.nextStepTimeout = setTimeout(() => {
      if (this.hasReachedCheckpoint()) {
        this.invokeCheckpoint();
        this.closeFtse();
        return true;
      }

      this.studySession.chooseNextCard();
    }, delay);
  }

  calculateEstimatedTimeLeft = () => {
    const {currentPackId, currentRoundStats} = this.state;

    this.setState({
      isProcessingEstimatedTimeLeft: true,
    });

    estimatedTimeLeftConcern.get(currentPackId, currentRoundStats.cardCount, currentRoundStats.levelCounts).then(resData => {
      this.setState({
        estimatedTimeLeft: resData.estimatedTimeLeft,
        isProcessingEstimatedTimeLeft: false,
        etlData: resData,
        totalTimeStudied: resData.totalTimeStudied,
      });
    }).catch(err => {
      console.log('something went wrong with estimatedTimeLeftConcern.get. err:', err);
    });
  }

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.nextStepTimeout);
    clearTimeout(this.returnToDashbaordTimeout);
    clearInterval(this.timerInterval);
  }

  closeFtse = () => {
    const currentUser = {...this.state.currentUser};
    currentUser.flags = currentUser.flags || {};
    currentUser.flags.isFtse = false;

    this.setState({
      currentUser: currentUser,
    });
  }

  getCardTimeSpent = () => {
    return Math.min(MAX_CARD_STUDY_DURATION, this.currentCardTime);
  }

  getCheckpointType = () => {
    return (this.getContinuousStudyPref()) ? 'sidebar' : 'fullScreen';
  }

  getContinuousStudyPref() {
    return userLocalStore.getUserLocalPref(
      this.props.initialUser.userId,
      'continuousStudyPref',
    );
  }

  getEpochTime = () => {
    const now = new Date();
    return now.getTime();
  }

  getNewCard = (packId, deckId, cardId) => {
    return new Promise((resolve, reject) => {
      try {
        const card = this.state.studyCards?.[cardId] || null;
        const deck = this.state.studyDecks?.[deckId] || null;
        const pack = this.state.studyPacks?.[packId] || null;

        if (pack && deck && card) {
          resolve({
            card: card,
            deck: deck,
            pack: pack,
          });

          return;
        }

        this.setState({
          isLoadingCardDisplayData: true,
        });

        // since all deck cards are cached in redis and S3, grab pack, deck, and *all* of the cards for the deck to save time on subsequent cards for this deck
        studyCard.index(packId, deckId, cardId).then(cardData => {
          const {packs, decks, cards} = cardData;

          this.setState({
            isLoadingCardDisplayData: false,
          });

          resolve({
            card: cards.hash[cardId],
            cards: cards.hash,
            deck: decks.hash[deckId],
            pack: packs.hash[packId],
          });
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  }

  hasReachedCheckpoint = () => {
    return (this.state.currentRoundStats.cardsRated >= this.state.currentRoundStats.roundLength);
  }

  invokeCheckpoint = () => {
    const currentSessionStats = {...this.state.currentSessionStats};
    currentSessionStats.roundsCompleted = currentSessionStats.roundsCompleted + 1;

    this.setState({
      currentSessionStats: currentSessionStats,
      isAtCheckpoint: true,
      isNewRound: true,
    }, () => {
      if (this.state.checkpointType == 'sidebar') {
        this.studySession.chooseNextCard();
      }
    });
  }

  localizePack = (pack) => {
    // customize pack per user prefs
    const userId = this.state.currentUserId;
    const packId = pack.packId;

    const localMixTypeSetting = userLocalStore.getPackStudyMixType(userId, pack);
    const localizedPack = {...pack};

    if (localMixTypeSetting) {
      localizedPack.mixType = localMixTypeSetting;
    }
    // TODO: Assign pack weight here for Smart Study

    return localizedPack;
  }

  localizePacks = (packs) => {
    const packIds = Object.keys(packs);
    const localizedPacks = {};

    packIds.forEach(packId => {
      const pack = packs[packId];
      const localizedPack = this.localizePack(pack);
      localizedPacks[packId] = localizedPack;
    });

    return localizedPacks;
  }

  manageViewport = () => {
    UiHelper.adjustViewportHeight();
    const isMobileViewportSize = UiHelper.detectIfMobileSize();

    this.setState({
      isMobileViewportSize: isMobileViewportSize,
    });
  }

  pauseTimer = () => {
    this.isTimerPaused = true;
  }

  setNewCardStats = () => {
    const currentSessionStats = {...this.state.currentSessionStats};
    const updatedSessionStats = {
      masteryPercentage: this.studySession.getMasteryPercentage(),
      masteryRatio: this.studySession.getMasteryRatio(),
      roundHistory: this.studySession.getRoundHistory(),
    };

    const currentRoundStats = {...this.state.currentRoundStats};
    const updatedRoundStats = {
      cardNumber: currentRoundStats.cardNumber + 1,
      levelCounts: this.studySession.getLevelCounts(),
    }

    this.setState({
      currentRoundStats: {...currentRoundStats, ...updatedRoundStats},
      currentSessionStats: {...currentSessionStats, ...updatedSessionStats},
    }, () => {
      this.startNewCardTime();
    });
  }

  setNewRoundStats = () => {
    const currentRoundStats = {
      cardCount: this.studySession.getCardCount(),
      cardDisplayTime: 0,
      cardsRated: 0,
      cardRatings: [],
      cardNumber: 1,
      confidenceGained: 0,
      roundLength: this.props.roundLength,
      roundDisplayTime: 0,
      roundNumber: this.state.currentRoundStats.roundNumber + 1,
      levelCounts: this.studySession.getLevelCounts(),
    };

    const shouldPersistCheckpoint = (this.state.checkpointType == 'sidebar' && this.state.isAtCheckpoint);

    this.setState({
      currentRoundStats: currentRoundStats,
      isAtCheckpoint: shouldPersistCheckpoint,
    }, () => {
      this.startNewRoundTime();
      this.calculateEstimatedTimeLeft();
      this.publishNewRoundStarted();
    });
  }

  setNewSessionStats = () => {
    const sessionDeckIds = this.studySession.getDeckIds() || [];

    const currentSessionStats = {
      deckCount: sessionDeckIds.length,
      masteryPercentage: this.studySession.getMasteryPercentage(),
      masteryRatio: this.studySession.getMasteryRatio(),
      roundsCompleted: 0,
      roundHistory: [],
    }

    this.setState({
      currentSessionStats: currentSessionStats,
    }, () => {
      this.publishNewSessionStarted();
    });
  }

  startNewCardTime = () => {
    const now = new Date();
    const startTime = now.getTime();

    this.currentCardTime = 0;
    this.currentCardStartTime = startTime;

    this.startTimer();
  }

  startNewRoundTime = () => {
    const startTime = this.getEpochTime();

    this.currentRoundTime = 0;
    this.currentCardTime = 0;

    this.currentRoundStartTime = startTime;
    this.currentCardStartTime = startTime;

    this.startTimer();
  }

  startNewStudySession = () => {
    const localizedPacks = this.localizePacks(this.state.studyPacks);

    this.setState({
      currentCardId: null,
      isNewStudySession: true,
      studyPacks: localizedPacks || {},
    }, () => {
       this.initStudySession();
       this.triggerDropdownCloseAllRequest();
    });
  }

  startTimer = () => {
    clearInterval(this.timerInterval);
    this.isTimerPaused = false;

    this.timerInterval = setInterval(() => {
      if (this.isTimerPaused) return false;

      const clickTime = this.getEpochTime();

      this.currentCardTime = clickTime - this.currentCardStartTime;
      this.currentRoundTime = clickTime - this.currentRoundStartTime;

      if ((this.currentCardTime % DISPLAY_INTERVAL) <= 10) this.updateTimeDisplays();

      if (this.currentCardTime > MAX_CARD_STUDY_DURATION) {
        this.pauseTimer();
      }

    }, TIMER_CLICK);
  }

  startWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.addEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  stopCurrentCardTimer = () => {
    clearInterval(this.currentCardTimeInterval);
  }

  stopWindowResizeMonitor = () => {
    if (this._isMounted) {
      window.removeEventListener('resize', (e) =>
        UiHelper.debounce(this.handleWindowResize(e), 250),
      );
    }
  };

  updateCardDisplayData = (pack, deck, card, cards=null) => {
     const studyPacks = {...this.state.studyPacks};
     studyPacks[pack.packId] = pack;
    
     const studyDecks = {...this.state.studyDecks};
     studyDecks[deck.deckId] = deck;
    
     let studyCards = {...this.state.studyCards};
     let newCards = {};

     if (cards) {
       newCards = cards;
     } else {
       newCards = {};
       newCards[card.cardId] = card ;
     }

     studyCards = {...studyCards, ...newCards};

    this.setState({
      studyPacks: studyPacks,
      studyDecks: studyDecks,
      studyCards: studyCards,      
    });
  }

  updateContinuousStudyPref(pref) {
    userLocalStore.updateUserLocalPrefs(
      this.state.currentUserId,
      'continuousStudyPref',
      pref,
    );
  }

  updateLastPackCookies(packId) {
    const now = new Date();

    CookieHelper.setCookie('last_pack_id', packId.toString(), 365);
    CookieHelper.setCookie('last_pack_id_date', now.toUTCString(), 365);
  }

  updateLocalData = (ratedCardInfo) => {
    return new Promise((resolve, reject) => {
      try {
        const userCards = {...this.state.userCards};
        const userCard = {...ratedCardInfo};
        userCard.at = TimeHelper.epochToIso(ratedCardInfo.card.ratedEpoch);
        userCards.userCardsH[ratedCardInfo.card.cardId] = userCard;

        const currentSessionStats = {...this.state.currentSessionStats};
        const currentRoundStats = {...this.state.currentRoundStats};
        const cardRatings = [...currentRoundStats.cardRatings];
        const ratedCard = ratedCardInfo.card;
        const cardConfidenceGained = ratedCard.level - ratedCard.prevLevel;

        cardRatings.push({
          cardId: ratedCard.cardId,
          level: ratedCard.level,
          prevLevel: ratedCard.prevLevel,
          confidenceGained: cardConfidenceGained, 
        });

        const updatedSessionStats = {
          masteryPercentage: this.studySession.getMasteryPercentage(),
          masteryRatio: this.studySession.getMasteryRatio(),
        };

        const updatedRoundStats = {
          cardsRated: currentRoundStats.cardsRated + 1,
          cardRatings: cardRatings,
          confidenceGained: currentRoundStats.confidenceGained + cardConfidenceGained,
          levelCounts: this.studySession.getLevelCounts(),
        };

        this.setState({
          currentCardLevel: ratedCard.level,
          currentRoundStats: {...currentRoundStats, ...updatedRoundStats},
          currentSessionStats: {...currentSessionStats, ...updatedSessionStats},
          userCards: userCards,
        }, () => {
          resolve();
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  }

  updateRemoteUserCardData = (ratedCardInfo, timeSpent) => {
    return new Promise((resolve, reject) => {
      try {
        const {currentPackId, currentDeckId, currentCardId} = this.state;

        const ratedCard = ratedCardInfo.card;
        const ratedIsoDateTimeStamp = TimeHelper.epochToIso(ratedCard.ratedEpoch);

        const userCardData = {
          level: ratedCard.level,
          prevLevel: ratedCard.prevLevel,
          ratedEpoch: ratedCard.ratedEpoch,
          ratedOn: ratedIsoDateTimeStamp,
          timeSpent: timeSpent,
        }

        studyCard.update(currentPackId, currentDeckId, currentCardId, userCardData).then(() => {
          resolve();
        }).catch(err => {
          console.log('Problem updating remote user card data. ratedCardInfo, prevLevel, timeSpent, err:', ratedCardInfo, prevLevel, timeSpent, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  }

  updateStudyMixType = (mixType, packId) => {
    const userId = this.state.currentUser.userId;

    const newStudyMixType = mixType == 'random' ? 'random' : 'progressive';
    userLocalStore.setPackStudyMixType(userId, packId, newStudyMixType);

    const seedData = {...this.state.seedData};
    const seedPack = seedData?.packs?.hash?.[packId];

    if (seedPack) {
      seedPack.mixType = newStudyMixType;

      this.setState({
        seedData: seedData,
      }, () => {
        this.startNewStudySession();
      });
    }
  };

  updateTimeDisplays = () => {
    const cardDisplayTime = (this.currentCardTime >= MAX_CARD_STUDY_DURATION) ? -1 : this.currentCardTime;

    const updatedRoundStats = {...this.state.currentRoundStats};
    updatedRoundStats.cardDisplayTime = cardDisplayTime;
    updatedRoundStats.roundDisplayTime = this.currentRoundTime;

    this.setState({
      currentRoundStats: updatedRoundStats,
    });
  }
}

StudyController.propTypes = PT;

export default StudyController;
