
import {CloseButton}  from '_views/shared/IconButton';
import React          from 'react';

import {toClassStr}   from '_utils/UiHelper';

class Bubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isContracting: false,
      isExpanding: false
    };

    /*
      this.props:
        addClasses,
        children,
        contractionDelay,
        corner,             // default: bottom-right
        expansionDelay,
        isEscapeKeyEnabled, // event handler for escape key must be handled upstream
        isScriptedClose,    // clicking "x" button will call a callback
        onClose,            // this callback is called after bubble contracts/closes
        onClosed,           // this callback is called after bubble contracts/closes (same as onClose)
        onCloseRequest,     // this callback will be run upon clicking a scriptedClose
        shouldAutoContract,
        shouldClose,        // setting this to true explicitly closes the bubble
        shouldContract,
        shouldExpand,
        size (small, medium, full),
    */

    this.expansionTimeout = null;
    this.contractionTimeout = null;
    this.contractAndCloseTimeout = null;
    this.INITIAL_TRANSITION_DELAY = 100; // allows for mounting of component before commencement of transition
    this.DEFAULT_CONTRACTION_DELAY = 30000;
    this.IMMEDIATE_CONTRACTION_DELAY = 500; // allows for animation of contraction before being potentially unmounted
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    clearTimeout(this.transitionTimeout);

    if (this.props.shouldExpand) {
      this.invokeExpansion();
    }

    if (this.props.shouldAutoContract) {
      this.invokeContraction();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.shouldClose && !prevProps.shouldClose) {
      this.closeBubble();
    }
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isStatic = (!(this.props.shouldExpand || this.props.shouldContract)) ? 'is-static' : '';
    const displaySize = 'size-' + this.props.size;
    const startSize = (this.props.shouldExpand ? 'size-seed' : displaySize);
    const endSize = (this.props.shouldContract ? 'size-seed' : displaySize);
    let sizeClass = startSize;

    if (this.state.isExpanding) {
      sizeClass = displaySize;
    }

    if (this.state.isContracting) {
      sizeClass = endSize;
    }

    const classes = toClassStr(['bubble', isStatic, sizeClass, this.getCornerClass(), this.props.addClasses]);
    const title = this.props.isEscapeKeyActive ? 'Hit [Esc] to dismiss' : null;

    return (
      <div className={classes}>
    
        <CloseButton 
          addClasses="bubble-close"
          onClick={() => this.handleClose()}
          title={title}
        />

        <div className="bubble-content">
          {this.props.children}
        </div>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClose() {
    if (this.props.isScriptedClose) {
      if (this.props.onCloseRequest) {
        this.props.onCloseRequest();
      }
    } else {
      this.closeBubble();
    }
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  invokeExpansion() {
    this.expansionTimeout = setTimeout(() => {
      this.setState({
        isExpanding: true
      });
      clearTimeout(this.expansionTimeout);
    }, this.props.expansionDelay || this.INITIAL_TRANSITION_DELAY);
  }

  invokeContraction() {
    this.contractionTimeout = setTimeout(() => {
      this.contractAndClose();
      clearTimeout(this.contractionTimeout);
    }, this.props.contractionDelay || this.DEFAULT_CONTRACTION_DELAY);
  }

  contractAndClose() {
    this.setState({
      isContracting: true,
    }, () => {
      this.contractAndCloseTimeout = setTimeout(() => {
        this.clearTimeouts();
        if (this.props.onClose) {
          this.props.onClose();
        }
        if (this.props.onClosed) {
          this.props.onClosed();
        }
      }, this.IMMEDIATE_CONTRACTION_DELAY);
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeouts() {
    clearTimeout(this.contractionTimeout);
    clearTimeout(this.contractAndCloseTimeout);
    clearTimeout(this.expansionTimeout);
  }

  closeBubble() {
    if (this.props.shouldContract) {
      this.contractAndClose();
    } else {
      this.clearTimeouts();
      if (this.props.onClose) {
        this.props.onClose();
      }
      if (this.props.onClosed) {
        this.props.onClosed();
      }
    }
  }

  getCornerClass() {
    switch (this.props.corner) {
      case 'topRight':
        return 'top-right';
      break;
      case 'bottomRight':
        return 'bottom-right';
      break;
      case 'bottomLeft':
        return 'bottom-left';
      break;
      case 'topLeft':
        return 'top-left';
      break;
      default:
        return 'bottom-right';
    }
  }
}

export default Bubble;
