
// substrate and utils
import EventManager         from '@brainscape/event-manager';
import PropTypes            from 'prop-types';
import React                from 'react';
import sanitizeHtml         from 'sanitize-html/dist/sanitize-html.min';
import showdown             from 'showdown';
import {toClassStr}         from '_utils/UiHelper';

// sub-components
import CkEditorTextField    from '_views/shared/smart-cards/CkEditorTextField';
import SimpleMdeTextField   from '_views/shared/smart-cards/SimpleMdeTextField';
import TextField            from '_views/shared/TextField';
  
import {
  InfoButton,
}                              from '_views/shared/IconButton';

const PT = {
  addClasses:             PropTypes.string,
  cardId:                 PropTypes.node,
  charLimit:              PropTypes.number,
  editMode:               PropTypes.string,
  fieldMode:              PropTypes.string,
  format:                 PropTypes.string,
  id:                     PropTypes.node,
  initialFieldMode:       PropTypes.string,
  name:                   PropTypes.string,
  onBlur:                 PropTypes.func,
  onChange:               PropTypes.func,
  onFieldModeChange:      PropTypes.func,
  onFocus:                PropTypes.func,
  onKeyDown:              PropTypes.func,
  placeholder:            PropTypes.string,
  value:                  PropTypes.string,
};

const NAME_TO_SCF_CLASS_MAP = {
  qMdPrompt: 'scf-prompt',
  aMdPrompt: 'scf-prompt',
  qMdBody: 'scf-body',
  aMdBody: 'scf-body',
  qMdClarifier: 'scf-clarifier',
  aMdClarifier: 'scf-clarifier',
  qMdFootnote: 'scf-footnote',
  aMdFootnote: 'scf-footnote',
  qImageCaption: 'scf-image-caption',
  aImageCaption: 'scf-image-caption',
};

const SCF_TOOLTIPS = {
  qMdPrompt: 'Include a prompt to inform the user to perform an action. Ex. "Translate to English"',
  qMdBody: 'Ask a question of the user, or present a topic',
  aMdBody: 'The knowledge answering the question posed or elaborating on the topic',
  qMdClarifier: 'Include a one line clarifier to augment your question. Ex. "Pronounced ah-mee-goe"',
  aMdClarifier: 'Include a one line clarifier to augment your answer. Ex. "Capital city: Mexico City"',
  qMdFootnote: "Include a footnote to provide hints, example sentences, attribution, etc.",
  aMdFootnote: "Include a footnote to provide hints, example sentences, attribution, etc.",
  qImageCaption: "Include an image caption to describe this card face's image",
  aImageCaption: "Include an image caption to describe this card face's image",
  prompt: 'Include a prompt to inform the user to perform an action. Ex. "Translate to English"',
  question: 'Ask a question of the user, or present a topic',
  answer: 'The knowledge answering the question posed or elaborating on the topic',
};

const CHAR_COUNT_LEVELS = {
  activate: 120,
  approaching: 6,
}


class SmartTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      charCount: (this.props.value) ? this.props.value.length : 0,
      fieldMode: this.props.initialFieldMode || 'preview', 
    }

    this.converter = new showdown.Converter();
    this.converter.setOption('simpleLineBreaks', true);

    this.events = new EventManager();

    this.plainTextField = null;
    this.mdeTextField = null;
    this.ckEditorTextField = null;

    this.scrollDelay = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;

    this.clearIntervalsAndTimeouts();

    this.events.addListeners([
      ['card:added', this.handleCardSaved],
      ['card:updated', this.handleCardSaved],
      ['smart-card:discard-changes-request', this.handleCardChangesDiscarded],
    ]);
  }

  componentWillUnmount() {
    this.events.disable();
    this.clearIntervalsAndTimeouts();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    if (this.state.fieldMode != 'writeable') {
      return (this.renderPreviewField());
    }

    const formatClass = `${this.props.format}-format`;
    const textFieldTypeClass = this.getTextFieldTypeClass();
    const classes = toClassStr(['smart-text-field', textFieldTypeClass, 'writeable-mode', formatClass, this.props.addClasses]);

    return (
      <div className={classes} data-field-name={this.props.name} tabIndex="0">
        {this.renderWriteableField()}
        {this.renderCharCounter()}
      </div>
    );
  }

  renderWriteableField = () => {
    switch (this.props.format) {
      case 'md':
        return this.renderWriteableMdField();
      break;

      case 'html': 
        return this.renderWriteableHtmlField();
      break;

      default:
        return this.renderWriteableTextField();
    }
  }

  renderWriteableTextField = () => {
    return (
      <TextField 
        id={this.props.id}
        isDynamic={true}
        key={this.props.name}
        name={this.props.name}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
        placeholder={this.props.placeholder}
        ref={(elem) => this.plainTextField = elem}
        type="textarea"
        value={this.props.value || ''}
      />
    );
  }

  renderWriteableMdField = () => {
    return (
      <SimpleMdeTextField
        id={this.props.id}
        isDynamic={true}
        key={this.props.name}
        name={this.props.name}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
        placeholder={this.props.placeholder}
        ref={(elem) => this.mdeTextField = elem}
        value={this.props.value || ''}
      />
    );
  }

  renderWriteableHtmlField = () => {
    return (
      <CkEditorTextField
        id={this.props.id}
        isDynamic={true}
        key={this.props.name}
        name={this.props.name}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.props.onKeyDown}
        placeholder={this.props.placeholder}
        ref={(elem) => this.ckEditorTextField = elem}
        value={this.props.value || ''}
      />
    );
  }

  renderCharCounter = () => {
    const charLimit = this.props.charLimit;
    const charCount = this.state.charCount;

    if (!(charLimit && charLimit > 0)) {
      return null;
    }

    if (charCount + CHAR_COUNT_LEVELS.activate <= charLimit) {
      return null;
    }

    const isApproachingLimitClass = (charCount + CHAR_COUNT_LEVELS.approaching > charLimit) ? 'is-approaching-limit' : '';
    const isOverCharLimitClass = (this.state.charCount > this.props.charLimit) ? 'is-over-limit' : '';
    const classes = toClassStr(['char-counter', isApproachingLimitClass, isOverCharLimitClass]);

    return (
      <div className={classes}>{charCount} of {charLimit}</div>
    );
  }

  renderPreviewField = () => {
    switch (this.props.format) {
      case 'md':
        return this.renderPreviewMdField();
      break;

      case 'html': 
        return this.renderPreviewHtmlField();
      break;

      default:
        return this.renderPreviewTextField();
    }
  }

  renderPreviewTextField = () => {
    // No need to perform display conversions. Use plain text field for both preview & write
    const classes = toClassStr(['smart-text-field', 'plain-text-field', 'writeable-mode', 'text-format', this.props.addClasses]);

    return (
      <div className={classes} data-field-name={this.props.name} onClick={this.handlePlainTextFieldClick}>
        {this.renderWriteableTextField()}
      </div>
    );
  }

  renderPreviewMdField = () => {
    const value = this.props.value;
    const classes = toClassStr(['smart-text-field', 'preview-mode', 'md-format', this.props.addClasses]);
    const tooltipContent = SCF_TOOLTIPS[this.props.name];

    if (!value) {
      const placeholder = this.props.placeholder || Util.toTitleCase(this.props.name);

      return (
        <div 
          className={classes} 
          data-field-name={this.props.name}
          onClick={this.handlePreviewMdFieldClick} 
        >
          <p className="placeholder-text">{placeholder}</p>

          <InfoButton
            tooltipClasses="smart-text-field-tooltip" 
            tooltipContent={tooltipContent}
            tooltipDelay={500}
            tooltipPosition="top"
          />
        </div>
      );
    }

    const html = this.convertMarkdownToHtml(this.props.value);

    return (
      <div 
        className={classes}
        data-field-name={this.props.name}
        id={this.props.id}
        onClick={this.handlePreviewMdFieldClick}
      >
        <div className="preview-html" dangerouslySetInnerHTML={{__html: html}} />
        <InfoButton
          tooltipClasses="smart-text-field-tooltip" 
          tooltipContent={tooltipContent}
          tooltipDelay={500}
          tooltipPosition="top"
        />
      </div>
    );
  }

  renderPreviewHtmlField = () => {
    const value = this.props.value;
    const classes = toClassStr(['smart-text-field', 'preview-mode', 'html-format', this.props.addClasses]);
    const tooltipContent = SCF_TOOLTIPS[this.props.name];

    if (!value) {
      const placeholder = this.props.placeholder || Util.toTitleCase(this.props.name);

      return (
        <div 
          className={classes} 
          data-field-name={this.props.name}
          onClick={this.handlePreviewHtmlFieldClick} 
        >
          <p className="placeholder-text">{placeholder}</p>

          <InfoButton
            tooltipClasses="smart-text-field-tooltip" 
            tooltipContent={tooltipContent}
            tooltipDelay={500}
            tooltipPosition="top"
          />
        </div>
      );
    }

    return (
      <div 
        className={classes}
        data-field-name={this.props.name}
        id={this.props.id}
        onClick={this.handlePreviewHtmlFieldClick}
      >
        <div className="preview-html" dangerouslySetInnerHTML={{__html: this.props.value}} />

        <InfoButton
          tooltipClasses="smart-text-field-tooltip" 
          tooltipContent={tooltipContent}
          tooltipDelay={500}
          tooltipPosition="top"
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCardChangesDiscarded = (eventData) => {
    if (eventData.currentCardId != this.props.cardId) {
      return false;
    }

    this.setState({
      fieldMode: 'preview',
    });
  }

  handleCardSaved = (eventData) => {
    if (eventData.card?.cardId != this.props.cardId) {
      return false;
    }

    this.setState({
      fieldMode: 'preview',
    });
  }

  handleChange = (e) => {    
    const newCharCount = (e.target.value) ? e.target.value.length : 0;
    const isOverCharLimit = (newCharCount > this.props.charLimit);

    this.setState({
      charCount: newCharCount,
      isOverCharLimit: isOverCharLimit,
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  handleFocus = (e) => {
    this.publishSmartTextFieldFocused();
  }

  handlePreviewHtmlFieldClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      fieldMode: 'writeable',
    }, () => {

      if (this.ckEditorTextField && this.ckEditorTextField.focus) {
        this.ckEditorTextField.focus();
        this.publishSmartTextFieldFocused();
        this.startSmartTextFieldMonitors();
      }

      if (this.props.onFieldModeChange) {
        this.props.onFieldModeChange('writeable');
      }
    });
  }

  handlePlainTextFieldClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      fieldMode: 'writeable',
    }, () => {

      if (this.plainTextField) {
        this.plainTextField.focus();
        this.publishSmartTextFieldFocused();
        this.startSmartTextFieldMonitors();
      }

      if (this.props.onFieldModeChange) {
        this.props.onFieldModeChange('writeable');
      }
    });
  }

  handlePreviewMdFieldClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({
      fieldMode: 'writeable',
    }, () => {

      if (this.mdeTextField && this.mdeTextField.focus) {
        this.mdeTextField.focus();
        this.publishSmartTextFieldFocused();
        this.startSmartTextFieldMonitors();
      }

      if (this.props.onFieldModeChange) {
        this.props.onFieldModeChange('writeable');
      }
    });
  }

  handleSmartCardOutsideClick = () => {    
    this.setState({
      fieldMode: 'preview',
    }, () => {
      if (this.props.onFieldModeChange) {
        this.props.onFieldModeChange('preview');
        this.stopSmartTextFieldMonitors();
      }
    });
  }

  handleSmartTextFieldFocused = (data) => {
    const smartTextFieldId = data.id;

    if (smartTextFieldId == this.props.id) {
      return false;
    }

    this.setState({
      fieldMode: 'preview',
    }, () => {
      if (this.props.onFieldModeChange) {
        this.props.onFieldModeChange('preview');
      }

      this.stopSmartTextFieldMonitors();
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishSmartTextFieldFocused = () => {
    EventManager.emitEvent('smart-text-field:focused', {
      id: this.props.id,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearIntervalsAndTimeouts = () => {
    clearTimeout(this.scrollDelay);
  }

  convertMarkdownToHtml = (markdown) => {
    const convertedHtml = this.converter.makeHtml(markdown);
    const sanitizedHtml = sanitizeHtml(convertedHtml, {
      allowedTags: ['p', 'br', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'code', 'pre'],
    });

    return sanitizedHtml;
  }

  getScfClass = () => {
    const baseClass = NAME_TO_SCF_CLASS_MAP[this.props.name];

    if (!baseClass) {
      return null;
    }
  }

  getTextFieldTypeClass = () => {
    switch (this.props.format) {
      case 'md':
        return 'simple-mde-text-field';
      break;

      case 'html':
        return 'ck-editor-text-field';
      break;

      default:
        return 'plain-text-field';
    }
  }

  startSmartTextFieldMonitors = () => {
    this.events.addListener('smart-text-field:focused', this.handleSmartTextFieldFocused);
    this.events.addListener('smart-card-outside-click', this.handleSmartCardOutsideClick);
  }

  stopSmartTextFieldMonitors = () => {
    this.events.removeListener('smart-text-field:focused', this.handleSmartTextFieldFocused);
    this.events.removeListener('smart-card-outside-click', this.handleSmartCardOutsideClick);
  }
}

SmartTextField.propTypes = PT;

export default SmartTextField;
