
import EventManager                       from '@brainscape/event-manager';
import PersonHelper                       from '_utils/PersonHelper';
import PillButton                         from '_views/shared/PillButton';
import PropTypes                          from 'prop-types';
import React                              from 'react';
import SimpleTextButton                   from '_views/shared/SimpleTextButton';
import Spinner                            from '_views/shared/Spinner';
import StringHelper                       from '_utils/StringHelper';
import TextField                          from '_views/shared/TextField';
import UploadableFile                     from '_views/shared/UploadableFile';

import { toClassStr }                     from '_utils/UiHelper';
import {omit}                             from 'lodash'

const PT = {
  currentUser:                    PropTypes.object,
  editedUserProfile:              PropTypes.object,
  isEditingUserProfile:           PropTypes.bool,
  isLoadingUserInfo:              PropTypes.bool,
  onFieldChange:                  PropTypes.func,
  onFormSubmit:                   PropTypes.func,
  onResetFormRequest:             PropTypes.func,
};

const GENERIC_AVATAR_FILE_NAME = 'avatar_generic_square';


class AccountProfileInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const userProfile = this.props.editedUserProfile;

    return (
      <form className='form account-form' onSubmit={this.handleUserProfileFormSubmit}>

        <div className='section top-left-section'>
          {this.renderUserAvatar()}
        
          <TextField    
            errorMessage={this.state.errors.firstName ? this.state.errors.firstName : ''}
            isInvalid={!!this.state.errors.firstName}
            label='First name'
            onChange={(e) => this.handleFieldChange(e, 'firstName')}     
            placeholder="e.g. Andrea"
            type='input'
            value={userProfile.firstName || ''}
          />  

          <TextField      
            errorMessage={this.state.errors.lastName  ? this.state.errors.lastName  : ''}   
            isInvalid={!!this.state.errors.lastName}
            label='Last name'
            onChange={(e) => this.handleFieldChange(e, 'lastName')}     
            placeholder="e.g. Doe"
            type='input'
            value={userProfile.lastName || ''}
          />  

          <TextField     
            errorMessage={this.state.errors.email  ? this.state.errors.email  : ''}         
            isInvalid={!!this.state.errors.email}
            label='Email'
            onChange={(e) => this.handleFieldChange(e, 'email')}     
            placeholder="e.g. andreadoe@gmail.com"
            type='input'
            value={userProfile.email || ''}
          />  
        </div>

        <div className='section bottom-right-section'>
          <TextField    
            label='School name'   
            onChange={(e) => this.handleFieldChange(e, 'schoolName')}     
            placeholder="e.g. NYU"
            type='input'
            value={userProfile.schoolName || ''}
          />  

          <TextField           
            label='Company name'
            onChange={(e) => this.handleFieldChange(e, 'companyName')}     
            placeholder="e.g. HBO"
            type='input'
            value={userProfile.companyName || ''}
          />  

          <TextField      
            addClasses='bio-field'     
            label='Bio'
            onChange={(e) => this.handleFieldChange(e, 'bio')}     
            placeholder="e.g. My bio"
            type='textarea'
            value={userProfile.bio || ''}
          />  
        </div>

        <div className='section discard-and-save-actions'>
          <PillButton
            addClasses="pill-button-emphasized pill-button-medium"
            isDisabled={!this.props.isEditingUserProfile || !Object.keys(this.state.errors).length <= 0}
            label="Save"
            onClick={this.handleUserProfileFormSubmit}
          />

          {this.renderDiscardButton()}

          <input type='submit' className='hidden-submit-button' />
        </div> 
      </form>
    );
  }

  renderUserAvatar() {
    return (
      <div className='user-avatar-and-label'>
        <div className='label'>Profile avatar</div>
        {this.renderSpinner()}
        {this.renderUploadableFile()}
      </div>
    )
  }

  renderUploadableFile() {
    if (this.props.isLoadingUserInfo) {
      return null;
    }

    const editedUserProfile = this.props.editedUserProfile;

    const avatarUrl = editedUserProfile.avatarUrl;
    let backgroundImageUrl = avatarUrl;
    let emptyImageFlag = null;
    let hasRemoveButton = true;

    const hasSetAvatarImage = (avatarUrl && avatarUrl.indexOf(GENERIC_AVATAR_FILE_NAME) == -1)

    if (!hasSetAvatarImage) {
      backgroundImageUrl = null;
      emptyImageFlag = PersonHelper.getInitials(`${editedUserProfile.firstName} ${editedUserProfile.lastName}`);
      hasRemoveButton = false;
    }

    return (
      <UploadableFile 
        addClasses="user-avatar"
        backgroundImageUrl={backgroundImageUrl}
        emptyImageFlag={emptyImageFlag}
        fileMediaType="image"
        hasEditButton={true}
        hasRemoveButton={hasRemoveButton}
        onFileLoaded={this.handleUserAvatarFileLoaded}
        onFileRemoveRequest={this.handleUserAvatarFileRemoveRequest}
      />
    );
  }

  renderSpinner() {
    if (!this.props.isLoadingUserInfo) {
      return null;
    }

    return <Spinner />;
  }

  renderDiscardButton() {
    if (!this.props.isEditingUserProfile) {
      return null;
    }

    return (
      <SimpleTextButton
        addClasses="discard-button"
        label="Discard Changes"
        onClick={this.props.onResetFormRequest}
      />
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  handleFieldChange = (e, fieldKey) => {
    e.stopPropagation();
    const value = e.target.value;

    if (this.props.onFieldChange) {
      this.props.onFieldChange(fieldKey, value)
      this.validateFields(fieldKey, value)
    }
  }

  handleUserAvatarFileLoaded = (result) => {
    if (this.props.onFieldChange) {
      this.props.onFieldChange('avatarUrl', result);
    }
  }

  handleUserAvatarFileRemoved = (result) => {
    if (this.props.onFieldChange) {
      this.props.onFieldChange('avatarUrl', null);
    }
  }

  handleUserAvatarFileRemoveRequest = () => {
    this.triggerCautionModalOpen({
      actionText: "remove your user avatar image",
      resolveButtonText: 'Yes, remove image',
      onResolution: () => {
        if (this.props.onFieldChange) {
          this.props.onFieldChange('avatarUrl', null);
        }
      },
      onCloseRequest: () => {
      }
    });
  }

  handleUserProfileFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.props.onFormSubmit();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalOpen = (viewProps) => {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  validateFields = (name, value) => {
    switch (name) {
      case 'firstName':
        if (StringHelper.isBlank(value)) {
          this.setState(() => ({
            errors: {
              ...this.state.errors,
              firstName: 'Please enter your first name.'
            }
          }));
        } else if (value.trim().length >= 50) {
          this.setState(() => ({
            errors: {
              ...this.state.errors,
              firstName: 'First name is too long (maximum is 50 characters).'
            }
          }));
        } else {
            let newObj = omit(this.state.errors, 'firstName');
            this.setState({ 
              errors: newObj
            });
        }
      break;

      case 'lastName':
        if (StringHelper.isBlank(value)) {
          this.setState(() => ({
            errors: {
              ...this.state.errors,
              lastName: 'Please enter your last name.'
            }
          }));
        } else if (value.trim().length >= 50) {
          this.setState(() => ({
            errors: {
              ...this.state.errors,
              lastName: 'Last name is too long (maximum is 50 characters).'
            }
          }));
        } else {
            let newObj = omit(this.state.errors, 'lastName');
            this.setState({ 
              errors: newObj
            });
        }
      break;

      case 'email':
        if (!StringHelper.isValidEmail(value)) {
          if (StringHelper.isBlank(value)) {
            this.setState(() => ({
              errors: {
                ...this.state.errors,
                email: 'Please enter an email.'
              }
            }));
          } else {
            this.setState(() => ({
              errors: {
                ...this.state.errors,
                email: 'A valid email is required.'
              }
            }));
          }
        } else {
          let newObj = omit(this.state.errors, 'email');
          this.setState({ 
            errors: newObj
          });
        }
      break;

        default:
          break;
    }
  }
}

AccountProfileInfoForm.propTypes = PT;

export default AccountProfileInfoForm;
