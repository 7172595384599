
import model from '_core/newModel';

const packDeckOptions = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index(packId) {
    const path = `packs/${packId}/decks?is_brief=true`;

    return new Promise((resolve, reject) => {
      try {
        model.index(path).then(res => {
          const options = res.decks.map(deck => {
            return {
              id: deck.deckId,
              label: deck.name,
            };
          });

          resolve(options);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default packDeckOptions;
