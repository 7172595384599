
import Modal                  from '_views/shared/Modal';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import PillButton             from '_views/shared/PillButton';
import React                  from 'react';

import {toClassStr} from '_utils/UiHelper';

class StudyMixTypeOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStudyMixTypeHelpModalOpen        : false,
      isProcessingOptionAction      : false,
      menuOptions                   : [],
    };

    /*
      this.props:
        addClasses,
        onSetStudyMixTypeRequest,
        openPosition,
        studyMixType,
    */

    this.menuOptionHash = {
      setProgressiveMix: {id: 'setProgressiveMix', tag: 'set-progressive-mix', label: 'Progressive Mix', persistOnClick: true},
      setRandomMix: {id: 'setRandomMix', tag: 'set-random-mix', label: 'Random Mix', persistOnClick: true},
      help: {id: 'help', tag: 'help', label: 'What is this?'},
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillMount() {
    this.updateUserOptions();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const studyMixTypeClass = this.props.studyMixType == 'progressive' ? 'progressive-mix-enabled' : 'random-mix-enabled';
    let classes = toClassStr(['mix-type-options-button', studyMixTypeClass, this.props.addClasses]);
    const openPosition = this.props.openPosition || 'bottomRight';

    return (
      <div className={classes}>

        <OptionsMenuButton
          iconType={this.props.iconType}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.isUserPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={(optionId) => this.handleOptionActionRequest(optionId)}
          openPosition={openPosition}
          shouldButtonTurn={false}
          // title={"Set Study Mix Type (Currently " + this.props.studyMixType + ")"}
          tooltipContent={"Set Study Mix Type (Currently " + this.props.studyMixType + ")"}
          tooltipPosition="top"
        />

        {this.renderStudyMixTypeHelpModal()}
      </div>
    );
  }

  renderStudyMixTypeHelpModal() {
    return (
      <Modal
        addClasses="mix-type-help-modal"
        isOpen={this.state.isStudyMixTypeHelpModalOpen}
        onCloseRequest={() => this.handleHelpModalCloseRequest()}
      >

        <div className="modal-title">About Brainscape Study Mix Types</div>

        <div className="modal-message">You can configure your study session in the following ways:</div>

        <div className="modal-message"><strong>'Progressive'</strong> selects cards from decks in the order they appear. So, you will not see cards from the 2nd deck until you've mostly mastered the 1st deck.  Once you've moved to a later deck, cards from earlier decks can still repeat occasionally.</div>

        <div className="modal-message"><strong>'Random'</strong> will select from a pool of cards across all selected decks.  The repetition priority is still given to the lower-confidence items, but new/un-seen cards may be chosen from any position in any of the selected decks.</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={() => this.handleHelpOkButtonClick()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'setProgressiveMix':
        this.performSetProgressiveMixAction();
      break;
      case 'setRandomMix':
        this.performSetRandomMixAction();
      break;
      case 'help':
        this.performShowHelpAction();
      break;
    }
  }

  handleHelpModalCloseRequest() {
    this.setState({
      isStudyMixTypeHelpModalOpen: false,
    });
  }

  handleHelpOkButtonClick() {
    this.setState({
      isStudyMixTypeHelpModalOpen: false,
    });
  }

  performSetProgressiveMixAction() {
    this.props.onSetStudyMixTypeRequest('progressive');
  }

  performSetRandomMixAction() {
    this.props.onSetStudyMixTypeRequest('random');
  }

  performShowHelpAction() {
    this.setState({
      isStudyMixTypeHelpModalOpen: true,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updateUserOptions() {
    const pack = this.props.pack;

    let menuOptionsForUser = Object.keys(this.menuOptionHash).reduce((accumulator, menuOption) => {

      let permitPerEditDeckRights = (this.menuOptionHash[menuOption].onlyDeckEditor) ? pack.areDecksEditable : true;

      if (permitPerEditDeckRights) {
        accumulator.push(this.menuOptionHash[menuOption]);
      }

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

export default StudyMixTypeOptionsButton;
