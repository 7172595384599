
// substrate and utils
import EventManager             from '@brainscape/event-manager';
import PropTypes                from 'prop-types';
import React                    from 'react';
import {toClassStr}             from '_utils/UiHelper';

// models
import cardClipboard            from '_models/cardClipboard';
import userLocalStore           from '_models/userLocalStore';

// sub-components
import DeckEditSectionSidebar   from '_views/deck-detail/desktop/edit/DeckEditSectionSidebar';
import PillButton               from '_views/shared/PillButton';
import QuickTipsOverlay         from '_views/deck-detail/desktop/edit/QuickTipsOverlay';
import SimpleTextButton         from '_views/shared/SimpleTextButton';
import SmartCardList            from '_views/shared/smart-cards/SmartCardList';

import {
  CircledAddButton
} from '_views/shared/IconButton';
  
  
const PT = {  
  addClasses:                   PropTypes.string,
  cardClipboard:                PropTypes.object,
  currentCardId:                PropTypes.node,
  currentDeck:                  PropTypes.object,
  currentPack:                  PropTypes.object,
  currentUser:                  PropTypes.object,
  isMobileViewportSize:         PropTypes.bool,
  isPaginatingDeckCards:        PropTypes.bool,
};


class DeckEditSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardChanges: {},
      cardSelections: {},
      cardsHaveChanges: false,
      editMode: this.getEditModePref(),
      editorSidebarMode: this.getEditorSidebarModePref(),
      isCreatingNewCard: false,
      isInsertingNewCard: false,
      isProcessingPaste: false,
      isProcessingSave: false,
      isSimulating: false,
      isQuickTipsOverlayOpen: false,
      lastSavedAction: null,
      lastSavedTime: null,
      newCardId: null,
      selectionCount: 0,

      allSelected: false,
      isSimulating: false,
      sortEnabled: false,
      foo: 'bar',
    }

    this.events = new EventManager();

    this.newCardTimeout = null;

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.subscribeToEvents();
    this.manageEmptyDeck();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoadingDeckCards && !this.props.isLoadingDeckCards) {
      this.manageEmptyDeck();
      return true;
    }

    if (prevProps.currentDeck?.cards?.length > 0 && this.props.currentDeck?.cards?.length < 1) {
      this.manageEmptyDeck();
    }

    if (prevProps.currentCardId && this.props.currentCardId && (prevProps.currentCardId != this.props.currentCardId)) {
      this.unsetNewCardId();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('card:created', this.handleCardCreated);
    this.events.addListener('card:inserted', this.handleCardInserted);
    this.events.addListener('card-selection:updated', this.handleCardSelectionUpdated);
    this.events.addListener('card-selections:updated', this.handleCardSelectionsUpdated);
    this.events.addListener('card:updated', this.handleCardUpdated);
    this.events.addListener('editor-sidebar:mode-change-request', this.handleEditorSidebarModeChangeRequest);
    this.events.addListener('smart-card:changes-state-updated', this.handleSmartCardChangesStateUpdated);
    this.events.addListener('smart-card:save-action-started', this.handleSmartCardSaveActionStarted);
    this.events.addListener('smart-card:select-advanced-edit-mode', this.handleSelectAdvancedEditMode);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const cards = this.props.currentDeck.cards;
    const hasChangesClass = this.state.cardsHaveChanges ? 'has-changes' : '';
    const isMiniEditorSidebarClass = !this.props.isMobileViewportSize ? (this.state.editorSidebarMode == 'mini' ? 'is-mini-editor-sidebar' : '') : null;
    const isMiniSidebarClass = !this.props.isMobileViewportSize ? (this.props.sidebarMode == 'mini' ? 'is-mini-sidebar' : '') : null;
    const classes = toClassStr(['deck-edit-section', hasChangesClass, isMiniEditorSidebarClass, isMiniSidebarClass, this.props.addClasses]);

    return (
      <div className={classes}>
        <DeckEditSectionSidebar
          cardSelections={this.state.cardSelections}
          cards={cards}
          cardsHaveChanges={this.state.cardsHaveChanges}
          currentCardId={this.props.currentCardId}
          deck={this.props.currentDeck}
          pack={this.props.currentPack}
          currentUser={this.props.currentUser}
          isPaginatingDeckCards={this.props.isPaginatingDeckCards}
          selectionCount={this.state.selectionCount}
          sidebarMode={this.state.editorSidebarMode}
        />

        <div className='multi-cards'>
          <header className="deck-edit-section-header">

            <div className="status-and-view-selector">
              {this.renderEditModeSelector()}
            </div>

            {this.renderCardClipboardControls()}
            {this.renderCardPaginationStatus()}
            {this.renderEditHeaderActions()}
          </header>

          <SmartCardList
            cardMode="edit"
            cards={cards}
            context="editor"
            currentCardId={this.props.currentCardId}
            currentUser={this.props.currentUser}
            deck={this.props.currentDeck}
            editMode={this.state.editMode}
            format="md"
            newCardId={this.state.newCardId}
            pack={this.props.currentPack}
            view={(this.props.isMobileViewportSize) ? 'mobile' : 'desktop'}
          />

          <QuickTipsOverlay 
            format={this.props.currentDeck.contentType}
            isOpen={this.state.isQuickTipsOverlayOpen}
            onCloseRequest={this.handleCloseQuickTipsOverlayRequest}
          />
        </div>
      </div>
    );
  }

  renderEditModeSelector = () => {
    if (this.state.isSimulating) {
      return null;
    }

    const currentUser = this.props.currentUser;

    return (
      <div className="edit-mode-selector">
        {this.renderEditModeSelectorButton('simple', 'Edit Q & A only')}
        {this.renderEditModeSelectorButton('advanced', 'Edit All fields')}
        {this.renderEditModeSelectorButton('source', 'Edit Source Markdown')}
      </div>
    );
  }

  renderEditModeSelectorButton = (mode, tooltipText) => {
    const isSelectedClass = (this.state.editMode == mode) ? 'is-selected' : '';
    const editModeButtonClass = `${mode}-edit-mode-text-button`;
    const formatClass = `${this.props.currentDeck.contentType}-format`;
    const classes = toClassStr(['edit-mode-text-button', editModeButtonClass, formatClass, isSelectedClass]);

    return (
      <SimpleTextButton 
        addClasses={classes}
        label={mode} 
        onClick={() => this.handleEditModeSelectorButtonClick(mode)}
        tooltipContent={tooltipText}
        tooltipPosition='top'
      />
    );
  }

  renderCardClipboardControls = () => {
    if (!this.props.cardClipboard) {
      return null;
    }

    const cardClipboard = this.props.cardClipboard;
    const cardCount = cardClipboard.cardIds.length;
    const cardLabel = (cardCount == 1) ? 'card' : 'cards';
    const status = (this.state.isProcessingPaste) ? 'processing...' : `${cardCount} ${cardLabel} copied`;

    return (
      <div className="card-clipboard-controls">
        <div className="status">{status}</div>

        <PillButton 
          addClasses='paste-button'
          label='Paste Cards into this Deck'
          onClick={this.handleCardClipboardPasteButtonClick}
        />

        <SimpleTextButton 
          addClasses='clear-button'
          label='clear'
          onClick={this.handleCardClipboardClearButtonClick}
        />
      </div>
    );
  }

  renderCardPaginationStatus = () => {
    if (this.props.cardClipboard) {
      return null;
    }

    if (!this.props.isPaginatingDeckCards) {
      return null;
    }

    return (
      <div className="card-pagination-status">
        <div className="status">loading cards...</div>
      </div>
    );
  }

  renderActivityIndicator = () => {
    if (!this.state.isSimulating) {
      return null;
    }

    const device = StringHelper.toTitleCase(this.state.deviceSimulation);
    const activity = `Simulating Card on ${device}`; 

    return (
      <div className="activity-indicator-and-controls">
        <div className="activity-indicator">{activity}</div>
      </div>
    );
  }

  renderEditHeaderActions() {
    if (this.state.isSimulating) {
      return null;
    }

    return (
      <div className="header-actions edit-header-actions" ref={(elem) => { this.headerActionsElem = elem }}>
        {this.renderQuickTipsButton()}
        {this.renderDiscardAndSaveActions()}
        {this.renderAddCardButton()}
      </div>
    );
  }

  renderQuickTipsButton = () => {
    if (this.props.isMobileViewportSize) {
      return;
    }

    return (
      <SimpleTextButton
        addClasses="header-action quick-tips-button"
        label="Quick Tips"
        onClick={this.handleQuickTipsButtonClick}
      />
    );
  }

  renderDiscardAndSaveActions() {
    if (!this.state.cardsHaveChanges) {

      if (this.state.lastSavedTime) {
        return this.renderLastSavedStatus();
      }

      if (this.state.isCreatingNewCard || this.state.isInsertingNewCard) {
        return this.renderDiscardButton();
      }

      return null;
    }

    return (
      <div className="discard-and-save-actions">

        {this.renderDiscardButton()}

        <PillButton
          addClasses="header-action save-button"
          isProcessing={this.state.isProcessingSave}
          label="Save"
          onClick={this.handleSaveButtonClick}
          shouldDeferBlurOnClick={true}
        />
      </div>
    );
  }

  renderDiscardButton() {
    return (
      <SimpleTextButton
        addClasses="header-action discard-button"
        label="Discard Changes"
        onClick={this.handleDiscardButtonClick}
        shouldDeferBlurOnClick={true}
      />
    );
  }

  renderLastSavedStatus() {
    const label = `${this.state.lastSavedAction || 'Saved'} at ${this.state.lastSavedTime}`;

    return (
      <div className="discard-and-save-actions">
        <div className="last-saved-status">{label}</div>
      </div>
    );
  }

  renderAddCardButton() {
    return (
      <CircledAddButton 
        addClasses="add-new-card-button"
        onClick={this.handleAddCardButtonClick}
        tooltipContent="Add New Card"
        tooltipPosition="left"
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddCardButtonClick = () => {
    this.triggerAddNewCardRequest();
  }

  handleCardClipboardClearButtonClick = () => {
    if (this.state.isProcessingPaste) {
      return false;
    }

    cardClipboard.clear();
  }

  handleCardClipboardPasteButtonClick = () => {
    if (this.state.isProcessingPaste) {
      return false;
    }

    this.setState({
      isProcessingPaste: true,
    });
    
    const packId = this.props.currentPack.packId;
    const deckId = this.props.currentDeck.deckId;

    cardClipboard.paste(packId, deckId).then(() => {
      this.setState({
        isProcessingPaste: false,
      });
    });
  }

  handleCardCreated = (eventData) => {
    this.setState({
      isProcessingSave: false,
      lastSavedAction: 'Added',
      lastSavedTime: new Date().toLocaleTimeString(),
      newCardId: eventData?.card?.cardId,
    }, () => {
      this.updateCardChangeStates(eventData.card?.cardId, false);
    });
  }

  handleCardInserted = (eventData) => {
    this.setState({
      isProcessingSave: false,
      lastSavedAction: 'Inserted',
      lastSavedTime: new Date().toLocaleTimeString(),
      newCardId: eventData?.card?.cardId,
      foo: eventData?.card?.cardId,
    }, () => {
      this.updateCardChangeStates(eventData.card?.cardId, false);
    });
  }

  handleCardSelectionUpdated = (eventData) => {
    const {cardId, isSelected} = eventData;

    const cardSelections = {...this.state.cardSelections};
    cardSelections[cardId] = isSelected;


    this.setState({
      cardSelections: cardSelections,
      selectionCount: this.countSelections(cardSelections),
    });
  }

  handleCardSelectionsUpdated = (eventData) => {
    const isSelected = eventData.isSelected;
    const cardIds = this.props.currentDeck.cardIds;

    const cardSelections = {...this.state.cardSelections};

    cardIds.forEach(cardId => {
      cardSelections[cardId] = isSelected;
    });

    this.setState({
      cardSelections: cardSelections,
      selectionCount: this.countSelections(cardSelections),
    });
  }

  handleCardUpdated = (eventData) => {
    this.setState({
      isProcessingSave: false,
      lastSavedAction: 'Saved',
      lastSavedTime: new Date().toLocaleTimeString(),
    }, () => {
      this.updateCardChangeStates(eventData.card?.cardId, false);
    });
  }

  handleCloseQuickTipsOverlayRequest = () => {
    this.setState({
      isQuickTipsOverlayOpen: false,
    });
  }

  handleDiscardButtonClick = () => {
    this.triggerDiscardChangesRequest();

    // if (this.state.cardsHaveChanges) {
    //   this.triggerConfirmModalOpen({
    //     actionText: 'discard your changes to this card',
    //     resolveButtonText: 'Yes, discard changes',
    //     onResolution: () => {
    //       this.triggerDiscardChangesRequest();
    //     },
    //   });
    // }
  }

  handleEditModeSelectorButtonClick = (mode) => {
    this.setEditModePref(mode);
  }

  handleEditorSidebarModeChangeRequest = (eventData) => {
    this.setEditorSidebarModePref(eventData.mode);
  }

  handleOpenQuickTipsOverlayRequest = () => {
    this.setState({
      isQuickTipsOverlayOpen: true,
    });
  }

  handleQuickTipsButtonClick = () => {
    this.setState({
      isQuickTipsOverlayOpen: true,
    });
  }

  handleSaveButtonClick = () => {
    this.setState({
      isProcessingSave: true,
    });

    this.triggerSaveChangesRequest();
  }

  handleSelectAdvancedEditMode = () => {
    this.setEditModePref('advanced');
  }

  handleSmartCardChangesStateUpdated = (eventData) => {
    this.updateCardChangeStates(eventData.cardId, eventData.hasChanges);
  }

  handleSmartCardSaveActionStarted = (eventData) => {
    this.setState({
      isProcessingSave: true,
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerDiscardChangesRequest = () => {
    EventManager.emitEvent('smart-card:discard-changes-request', {
      currentCardId: this.props.currentCardId,
    });
  }

  triggerSaveChangesRequest = () => {
    EventManager.emitEvent('smart-card:save-changes-request', {});
  }

  triggerAddNewCardRequest = () => {
    EventManager.emitEvent('card:add-new-card-request', {});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.newCardTimeout);
  }

  countSelections = (cardSelections) => {
    let selectCount = 0;

    Object.keys(cardSelections).forEach(cardId => {
      selectCount = selectCount + ((cardSelections[cardId] == true) ? 1 : 0);
    });

    return selectCount;
  }

  getEditModePref = () => {
    const editModePref = userLocalStore.getUserLocalPref(this.props.currentUser.userId, 'editModePref');
    return editModePref || 'simple';
  }

  getEditorSidebarModePref = () => {
    const editorSidebarModePref = userLocalStore.getUserLocalPref(this.props.currentUser.userId, 'editorSidebarModePref');
    return editorSidebarModePref || 'mini';
  }

  manageEmptyDeck = () => {
    if (this.props.isLoadingDeckCards) {
      return false;
    }

    if (this.props.currentDeck.cards?.length > 0) {
      return false;
    }

    // wait a beat to allow some state transitions
    this.newCardTimeout = setTimeout(() => {
      this.triggerAddNewCardRequest();
    }, 1000);
  }

  setEditModePref = (pref) => {
    this.setState({
      context: 'editor',
      editMode: pref,
    }, () => {
      userLocalStore.updateUserLocalPrefs(this.props.currentUser.userId, 'editModePref', pref);
    });
  }

  setEditorSidebarModePref = (pref) => {
    this.setState({
      editorSidebarMode: pref,
    }, () => {
      userLocalStore.updateUserLocalPrefs(this.props.currentUser.userId, 'editorSidebarModePref', pref);
    });
  }

  unsetNewCardId = () => {
    this.setState({
      newCardId: null,
    });
  }

  updateCardChangeStates = (cardId, hasChanges) => {
    if (!cardId) {
      return false;
    }

    const cardChanges = {...this.state.cardChanges};
    cardChanges[cardId] = hasChanges;

    const cardsHaveChanges = Object.keys(cardChanges).some(cId => cardChanges[cId] == true);

    this.setState({
      cardChanges: cardChanges,
      cardsHaveChanges: cardsHaveChanges,
    }); 
  }
}

DeckEditSection.propTypes = PT;

export default DeckEditSection;
