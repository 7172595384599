
import EventManager       from '@brainscape/event-manager';
import React              from 'react';
import Spinner            from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

class IconButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  /*
    this.props:
      addClasses,
      buttonClass,
      iconClass,
      isProcessing,
      onClick,
      onMousedown,
      onMouseup,
      shouldAllowClickPropagation,
      title,
      tooltipContent,
      tooltipDelay,
      tooltipClasses,
      tooltipPosition,
  */
    
    this._isMounted = false;

    this.events = new EventManager();
    this.elem = null;
    this.tooltipHover = false;
    this.tooltipTimeout = null;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isDisabledClass = this.props.isDisabled ? 'is-disabled' : '';
    const isProcessingClass = this.props.isProcessing ? 'is-processing' : '';
    const hasTooltipClass = this.props.tooltipContent ? 'has-tooltip' : '';
    const classes = toClassStr(['icon-button', this.props.buttonClass, isDisabledClass, isProcessingClass, hasTooltipClass, this.props.addClasses]); 

    return (
      <div
        className={classes}
        onClick={this.handleButtonClick}
        onMouseDown={this.props.onMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseUp={this.props.onMouseUp}
        ref={(elem) => { this.elem = elem }} 
        title={this.props.title}
      >
        <i className={this.props.iconClass} />
        {this.renderSpinner()}
      </div>
    );
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick = (e) => {
    if (e && !this.props.shouldAllowClickPropagation) {
      e.stopPropagation();
    }

    if (this.props.onClick && !this.props.isDisabled) {
      this.props.onClick(e);
    }

    if (this.props.tooltipContent) {
      this.triggerTooltipClose();
    }
  }

  handleMouseEnter = (e) => {
    if (this.props.tooltipContent) {

      if (this.props.tooltipDelay) {
        this.tooltipHover = true;

        this.tooltipTimeout = setTimeout(() => {
          if (this.tooltipHover) {
            this.triggerTooltipOpen();
          }
        }, this.props.tooltipDelay);

      } else {
        this.triggerTooltipOpen();
      }
    }
  }

  handleMouseLeave = () => {
    if (this.props.tooltipContent) {
      this.tooltipHover = false;
      this.triggerTooltipClose();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    if (!this._isMounted) {
      return false;
    }

    EventManager.emitEvent('tooltip:open', {
      addClasses: this.props.tooltipClasses,
      content: this.props.tooltipContent,
      elem: this.elem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    if (!this._isMounted) {
      return false;
    }
    
    EventManager.emitEvent('tooltip:close', {});
  };

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.tooltipTimeout);
  }
}


/*
==================================================
 PRE-CONFIGURED ICON BUTTONS
==================================================
*/

class AddButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="add-button"
        iconClass="ion-ios-plus-empty"
      />
    );
  }
}
class AcademyButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="academy-button"
        iconClass="academy-icon"
      />
    );
  }
}

class AddSoundButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="add-sound-button"
        iconClass="ion-ios-play"
      />
    );
  }
}

class AddImageButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="add-image-button"
        iconClass="ion-image"
      />
    );
  }
}

class AddButtonCircled extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="add-button"
        iconClass="ion-android-add-circle"
      />
    );
  }
}

class AlertButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="alert-button"
        iconClass="ion-android-alert"
      />
    );
  }
}

class CircledAddButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="add-button"
        iconClass="ion-android-add-circle"
      />
    );
  }
}

class BackButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="back-button"
        iconClass="ion-chevron-left"
      />
    );
  }
}

class BigPlusButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="big-plus-button"
        iconClass="ion-plus"
      />
    );
  }
}

class BrowseButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="browse-button"
        iconClass="ion-ios-albums-outline"
      />
    );
  }
}

class ClearButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="clear-button"
        iconClass="ion-ios-close"
      />
    );
  }
}

class CloseButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="close-button"
        iconClass="ion-ios-close-empty"
      />
    );
  }
}

class ConcealButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="conceal-button"
      />
    );
  }
}

class ContractButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="contract-button"
      />
    );
  }
}

class DiscordButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="discord-button"
        iconClass="discord-icon"
      />
    );
  }
}

class DismissButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="dismiss-button"
        iconClass="ion-ios-close-empty"
      />
    );
  }
}

class DisplayDownButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="display-down-button"
        iconClass="ion-ios-arrow-down"
      />
    );
  }
}

class DragButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="drag-button"
        iconClass="ion-grid"
      />
    );
  }
}

class DropdownButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="dropdown-button"
        iconClass="ion-android-arrow-dropdown"
      />
    );
  }
}

class DropupButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="dropup-button"
        iconClass="ion-android-arrow-dropup"
      />
    );
  }
}

class DuplicateButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="duplicate-button"
        iconClass="ion-ios-copy-outline"
      />
    );
  }
}

class EditButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="edit-button"
        iconClass="ion-edit"
      />
    );
  }
}

class ExpandButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="expand-button"
        iconClass="ion-android-expand"
      />
    );
  }
}

class ExportButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="export-button"
        iconClass="ion-ios-upload-outline"
      />
    );
  }
}

class ExtendButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="extend-button"
      />
    );
  }
}

class ForwardButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="forward-button"
        iconClass="ion-chevron-right"
      />
    );
  }
}

class HelpButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="help-button"
        iconClass="ion-help-circled"
      />
    );
  }
}

class HideButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="hide-button"
        iconClass="ion-eye-disabled"
      />
    );
  }
}

class ImportButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="import-button"
        iconClass="ion-ios-download-outline"
      />
    );
  }
}

class InsertButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="insert-button"
        iconClass="ion-ios-plus-outline"
      />
    );
  }
}

class InfoButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="info-button"
        iconClass="ion-ios-information-outline"
      />
    );
  }
}

class LockButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="lock-button"
        iconClass="ion-ios-locked-filled"
      />
    );
  }
}

class MailButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="mail-button"
        iconClass="ion-ios-email"
      />
    );
  }
}

class MenuButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="menu-button"
        iconClass="ion-arrow-down-b"
      />
    );
  }
}

class MoneyButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="money-button"
        iconClass="money-icon"
      />
    );
  }
}

class PauseButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="pause-button"
        iconClass="ion-ios-pause"
      />
    );
  }
}

class PeopleButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="people-button"
        iconClass="ion-ios-people"
      />
    );
  }
}

class PlayButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="play-button"
        iconClass="ion-ios-play"
      />
    );
  }
}

class PreviewButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="preview-button"
        iconClass="ion-ios-glasses-outline"
      />
    );
  }
}

class PhoneButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="phone-button"
        iconClass="phone-icon"
      />
    );
  }
}

class ReorderButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="reorder-button"
        iconClass="ion-ios-shuffle"
      />
    );
  }
}

class RemoveButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="remove-button"
        iconClass="ion-ios-close-empty"
      />
    );
  }
}

class RemoveSoundButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="add-sound-button"
        iconClass="ion-ios-play"
      />
    );
  }
}

class RetractDownButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="retract-down-button"
        iconClass="ion-ios-arrow-down"
      />
    );
  }
}

class RetractUpButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="retract-up-button"
        iconClass="ion-ios-arrow-up"
      />
    );
  }
}

class ReturnButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="return-button"
        iconClass="ion-arrow-return-left"
      />
    );
  }
}

class SaveButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="save-button"
        iconClass="ion-checkmark"
      />
    );
  }
}

class SearchButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="search-button"
        iconClass="ion-ios-search"
      />
    );
  }
}

class ShareButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="share-button"
        iconClass="ion-android-share-alt"
      />
    );
  }
}

class ShowButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="show-button"
        iconClass="ion-eye"
      />
    );
  }
}

class SoundWaveButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="sound-wave-button"
        iconClass="sound-wave-icon"
      />
    );
  }
}

class StudyButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="study-button"
        iconClass="ion-ios-play"
      />
    );
  }
}

class SuggestionButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="suggestion-button"
        iconClass="ion-ios-medical"
      />
    );
  }
}

class UndoButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="undo-button"
        iconClass="ion-ios-undo"
      />
    );
  }
}

class UnlockButton extends React.Component {
  render() {
    return (
      <IconButton
        {...this.props}
        buttonClass="unlock-button"
        iconClass="ion-ios-unlocked-outline"
      />
    );
  }
}

export {
  IconButton as default,
  AddButton,
  AddButtonCircled,
  AcademyButton,
  AddImageButton,
  AddSoundButton,
  AlertButton,
  BackButton,
  BigPlusButton,
  BrowseButton,
  CircledAddButton,
  ClearButton,
  CloseButton,
  ConcealButton,
  ContractButton,
  DiscordButton,
  DismissButton,
  DragButton,
  DropdownButton,
  DropupButton,
  DuplicateButton,
  EditButton,
  ExpandButton,
  ExtendButton,
  ExportButton,
  ForwardButton,
  HelpButton,
  HideButton,
  ImportButton,
  InfoButton,
  InsertButton,
  LockButton,
  MailButton,
  MenuButton,
  MoneyButton,
  PauseButton,
  PeopleButton,
  PlayButton,
  PhoneButton,
  PreviewButton,
  RemoveButton,
  RemoveSoundButton,
  ReorderButton,
  RetractDownButton,
  RetractUpButton,
  ReturnButton,
  DisplayDownButton,
  SaveButton,
  SearchButton,
  ShareButton,
  ShowButton,
  SoundWaveButton,
  StudyButton,
  SuggestionButton,
  UndoButton,
  UnlockButton,
};
