
// substrate and utils
import EventManager               from '@brainscape/event-manager';
import NumberHelper               from '_utils/NumberHelper';
import PersonHelper               from '_utils/PersonHelper';
import React                      from 'react';
import UiHelper                   from '_utils/UiHelper';
import {toClassStr}               from '_utils/UiHelper';

// models
import packLearner                from '_models/packLearner';

// sub-components
import CopyToClipboardLink        from '_views/shared/CopyToClipboardLink';
import DynamicTooltipIcon               from '_views/shared/DynamicTooltipIcon';
import LoadingOverlay             from '_views/shared/LoadingOverlay';
import Pulldown                   from '_views/shared/Pulldown';


class PackLeaderboardSection extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      isPermissionPulldownOpen: false,
      isProcessingPermissionChange: false,
      isProcessingOptionAction: false,
    };

    /*
      this.props:
        addClasses,
        authenticityToken,
        isLoadingPackLearners,
        leaderboardOrder,
        learners,
        onUpdateLocalLearnerRequest,
        pack,
        shouldRenderAsTiles,
        userId
    */

    this.permissionOptions = [
      {id: 'preview', label: 'Preview'},
      {id: 'study', label: 'Full Study'},
      {id: 'edit', label: 'Edit'},
      {id: 'admin', label: 'Admin'},
    ];


    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.props.learners || this.props.isLoadingPackLeaderboard) {
      return (
        <LoadingOverlay
          addClasses="section-loading-overlay"
          isOpen={true}
          message="Loading Class Leaderboard..."
          shouldTransitionIn={true}
          shouldTransitionOut={true}
        />
      );
    }

    const blockClass = this.props.shouldRenderAsTiles ? 'tile-blocks' : 'row-blocks';
    const classes = toClassStr(['pack-leaderboard-section', blockClass, this.props.addClasses]);

    return (
      <section className={classes}>
        {this.renderHeader()}
        {this.renderLeaderboardList()}
        {this.renderFooter()}
      </section>
    );
  }

  renderHeader() {
    if (this.props.shouldRenderAsTiles) {
      return this.renderTilesHeader();
    }

    return this.renderRowsHeader();
  }

  renderRowsHeader() {
    const isOverall = this.props.leaderboardOrder == 'overall';
    const countHeading = isOverall ? 'Total Cards' : 'Cards this Mo.'

    return (
      <header className="pack-leaderboard-list-header">
        <h5 className="leaderboard-list-heading">Top Learners</h5>
        <div className="name-heading">Learner</div>
        {this.renderPermissionHeading()}
        <div className="stats-headings">
          <div className="card-views-heading">
            <span>{countHeading}</span>

            <DynamicTooltipIcon 
              addClasses="card-views-tooltip"
              body={this.renderCardViewsTooltip(isOverall)}
              hasDismissButton={true}
              iconType="help"
              position="left"
              subHeading={null}
            />
          </div>
        </div>
      </header>
    );
  }

  renderPermissionHeading() {
    const isUserBscAdmin = this.props.currentUser.flags.isBscAdmin;
    const isUserBscEmployee = this.props.currentUser.flags.isBscEmployee;

    if (!(isUserBscAdmin || isUserBscEmployee)) {
      return null;
    }

    return (
      <div className="permissions-heading">Permission</div>
    );
  }

  renderCardViewsTooltip(isOverall) {
    if (isOverall) {
      return (
        <>
          <p className="body-sub-heading">Total Cards</p>
          <p className="body-text">The cumulative number of cards in this class that the user has viewed across all time, counting repeated cards.</p>
        </>
      );
    }

    return (
      <>
        <p className="body-sub-heading">Cards this Month</p>
        <p className="body-text">The cumulative number of cards in this class that the user has viewed over the past 30 days, counting repeated cards.</p>
      </>
    );
  }

  renderTilesHeader() {
    return (
      <header className="pack-leaderboard-list-header">
        <h5 className="leaderboard-list-heading">Top Learners</h5>
      </header>
    );
  }

  renderLeaderboardList() {
    const learnerBlocks = this.props.learners.map((learner, index) => {
      if (this.props.shouldRenderAsTiles) {
        return this.renderLeaderboardTile(learner, index);
      }

      return this.renderLeaderboardRow(learner, index);
    });

    return (
      <ul className="learner-list">
        {learnerBlocks}
      </ul>
    );
  }

  renderLeaderboardRow(learner, index) {
    const isOverall       = (this.props.leaderboardOrder == 'overall');
    const cardCt          = isOverall ? learner.overallStudyCount : learner.rollingStudyCount;
    const isUser          = (learner.userId == this.props.userId);
    const isLastRow       = (index + 1 == 10);
    const userIsLastClass = (isUser && isLastRow) ? 'user-is-last' : '';

    let classes = toClassStr(['leaderboard-block row-block', userIsLastClass]);

    return (
      <li
        className={classes}
        onClick={(e) => this.handleLearnerClick(e, learner)}
        key={index}
      >
        <LearnerRank index={index} />
        <div className='learner-avatar-and-name'>
          <LearnerAvatar learner={learner} />
          <LearnerName learner={learner} pack={this.props.pack}/>
        </div>

        {this.renderPermission(learner)}

        <div className="learner-stats">
          <div className="card-views">{NumberHelper.displayNumber(cardCt)}</div>
        </div>

        <LearnerActions learner={learner} />
      </li>
    );
  }

  renderPermission(learner) {
    const isUserBscAdmin = this.props.currentUser.flags.isBscAdmin;
    const isUserBscEmployee = this.props.currentUser.flags.isBscEmployee;

    if (!(isUserBscAdmin || isUserBscEmployee)) {
      return null;
    }

    if (!learner.permission || learner.permission == "none") {
      return null;
    }

    const {permissionValue, permissionLabel} = packLearner.showPermission(learner);
    const isLearnerCurrentUser = (learner.userId == this.props.userId);

    if (!isLearnerCurrentUser) {
      return <div className="learner-pack-permission">{permissionLabel}</div>;
    }

    return (
      <Pulldown
        addClasses="permission-pulldown"
        isOpen={this.state.isPermissionPulldownOpen}
        isProcessing={this.isProcessingPermissionChange}
        options={this.permissionOptions}
        selectedValue={permissionValue}
        shouldSuppressNullOption={true}
        onButtonClick={this.handlePermissionButtonClick}
        onOptionClick={(optionId) => this.handlePermissionOptionClick(optionId, learner)}
        onOutsideClick={this.handlePermissionOutsideClick}
      />
    );
  }


  renderLeaderboardTile(learner, index) {
    const isOverall       = this.props.leaderboardOrder == 'overall';
    const cardCt          = isOverall ? learner.overallStudyCount : learner.rollingStudyCount;
    const countHeading    = isOverall ? 'Total Cards' : 'Cards this Mo.'
    const isUser          = (learner.userId == this.props.userId);
    const isLastRow       = (index + 1 == 10);
    const userIsLastClass = (isUser && isLastRow) ? 'user-is-last' : '';

    let classes = toClassStr(['leaderboard-block tile-block', userIsLastClass]);

    return (
      <li className={classes} onClick={(e) => this.handleLearnerClick(e, learner)} key={index}>
        <div className="tile-content">
          <LearnerRank index={index} />
          <div className='learner-avatar-and-name'>
            <LearnerAvatar learner={learner} />
            <LearnerName learner={learner} />
          </div>

          <div className="learner-stats">
            <div className="card-views">
              <span className="stat-label">{countHeading}:</span>
              {cardCt}
            </div>
          </div>
        </div>

        <LearnerActions learner={learner} />
      </li>
    );
  }

  renderFooter() {
    const pack = this.props.pack;
    const shareLink = pack.permission == 'admin'
      ? pack.paths.shareLink
      : pack.paths.shareCompactLink;

    return (
      <footer className="pack-leaderboard-list-footer">

        <div className="share-class" title="Copy link to share this Class">
          <div className="share-link">
            {shareLink}
          </div>

          <CopyToClipboardLink
            isDisplayedInInputElem={false}
            inputElemSelector={null}
            value={shareLink}
          />
        </div>
      </footer>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleLearnerClick = (e, learner) => {
    e.stopPropagation();

    if (learner.profileId) { 
      UiHelper.openInNewTab(`/profiles/${learner.profileId}`, 'profile'); 
    }
  }

  handlePermissionButtonClick = () => {
    this.setState({
      isPermissionPulldownOpen: !this.state.isPermissionPulldownOpen,
    })
  }

  handlePermissionOptionClick = (optionId, learner) => {
    this.setState({
      isPermissionPulldownOpen:     false,
      isProcessingPermissionChange: true,
    }, () => {
      this.performPermissionChange(optionId, learner);
    });
  }

  handlePermissionOutsideClick = () => {
    this.setState({
      isPermissionPulldownOpen: false,
    })
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerCautionModalClose(viewProps) {
    EventManager.emitEvent('caution-modal:close', viewProps);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  completePermissionChange = (optionId, learner) => {
    const learnerData = {
      permissionOptionId: optionId,
      discoveryMedium: learner.discoveryMedium,
    };

    packLearner.update(this.props.pack.packId, learner.userId, learnerData).then(() => {
      this.setState({
        isProcessingPermissionChange: false,
      });
    });
  }

  performPermissionChange = (optionId, learner) => {
    const pack = this.props.pack;
    const {permissionValue, permissionLabel} = packLearner.showPermission(learner);
    const currentUser = this.props.currentUser;

    if (learner.userId == currentUser.userId && permissionValue == 'admin' && optionId != 'admin' && !(currentUser.flags.isBscAdmin || currentUser.flags.isBscEmployee)) {

      const message = (
        <div className="modal-message">
          You are about to remove your own Admin rights. After this change takes
          place, you will not be able to reassign yourself Admin rights to this
          Class. Are you sure that you wish to proceed?
        </div>
      );

      this.triggerCautionModalOpen({
        message: message,
        resolveButtonText: 'Yes, I know what I am doing',
        onResolution: () => {
          this.triggerCautionModalClose();
          this.completePermissionChange(optionId, learner);
        },
        onCloseRequest: () => {
          this.setState({
            isProcessingPermissionChange: false,
          });
        }
      });

      return true;
    }

    this.completePermissionChange(optionId, learner);
  }
}


/*
==================================================
 LOCAL SUB COMPONENTS
==================================================
*/

const LearnerActions = (props) => {
  if (!props.learner?.profileId) {
    return null;
  }

  return (
    <div className="learner-actions">
      <i className="forward-icon ion-chevron-right" />
    </div>
  );
}

const LearnerAvatar = (props) => {
  return (
    <div className="learner-avatar">
      <img
        className="learner-avatar-image"
        src={props.learner.avatarUrl}
        title={`See ${props.learner.firstName}'s profile`}
      />
    </div>
  );
}

const LearnerName = (props) => {
  const learnerName = PersonHelper.getProfileName(props.learner, props.pack.permission);
  return <div className="learner-name">{learnerName}</div>;
}

const LearnerRank = (props) => {
  return <div className="learner-rank">{props.index + 1}.</div>;
}

export default PackLeaderboardSection;
