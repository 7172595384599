
import React from 'react';

class AnimatedCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /* 
      this.props: 
    */
  }

  render() {
    return (
      <div className="animated-cards">
        <div className="confidenceanimation">
          <div className="confidenceanimation-container">

            <div className="confidenceanimation-card anim-card-1">
              <div>How do you say "Cheese" in French?</div>
              <div className="confidenceanimation-reveal">REVEAL</div>
            </div>

            <div className="confidenceanimation-card anim-card-2">
              <div>What is the capital of France?</div>
              <div className="confidenceanimation-reveal">REVEAL</div>
            </div>

            <div className="confidenceanimation-card anim-card-3">
              <div>What is the chemical symbol for Water?</div>
              <div className="confidenceanimation-reveal">REVEAL</div>
            </div>

            <div className="confidenceanimation-card anim-card-4">
              <div>Define: Odious</div>
              <div className="confidenceanimation-reveal">REVEAL</div>
            </div>
          </div>

          <div className="confidenceanimation-label">
            <div>How well did you know this?</div>
            <div className="confidenceanimation-label-confidence confidenceanimation-label-1">Not at all</div>
            <div className="confidenceanimation-label-confidence confidenceanimation-label-2">Not really</div>
            <div className="confidenceanimation-label-confidence confidenceanimation-label-3">Well</div>
            <div className="confidenceanimation-label-confidence confidenceanimation-label-4">Very Well</div>
            <div className="confidenceanimation-label-confidence confidenceanimation-label-5">Perfectly</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnimatedCards;
