
import EventManager           from '@brainscape/event-manager';
import Modal                  from '_views/shared/Modal';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import PillButton             from '_views/shared/PillButton';
import React                  from 'react';
import UiHelper               from '_utils/UiHelper';

// models
import packConfidence         from '_models/packConfidence';

import {toClassStr}           from '_utils/UiHelper';

const MENU_OPTIONS_HASH = {
  previewDeckCards: {id: 'previewDeckCards', tag: 'preview-deck-cards', label: 'Preview Cards in Current Deck', onlyMobile: true},
  separator1: {id: '---', label: null, onlyMobile: true},
  thisRound: {id: 'thisRound', tag: 'this-round-progress', label: 'Display "This Round" Progress', onlyMobile: true, persistOnClick: true},
  overall: {id: 'overall', tag: 'overall-progress', label: 'Display "Overall" Progress', onlyMobile: true, persistOnClick: true},
  continuousStudyByPro: {id: 'continuousStudyByPro', tag: 'continuous-study-by-pro', label: 'Enable Continuous Study', onlyPro: true, onlyIfMixIsRestricted: true, paywall: 'enable_continuous_study', persistOnClick: true},
  continuousStudy: {id: 'continuousStudy', tag: 'continuous-study', label: 'Enable Continuous Study', onlyIfMixIsUnrestricted: true, persistOnClick: true},
  separator2: {id: '---', label: null, onlyMultipleDecks: true},
  setProgressiveMix: {id: 'setProgressiveMix', tag: 'set-progressive-mix', label: 'Set as Progressive Mix', persistOnClick: true, onlyMultipleDecks: true},
  setRandomMix: {id: 'setRandomMix', tag: 'set-random-mix', label: 'Set as Random Mix', persistOnClick: true, onlyMultipleDecks: true},
  mixHelp: {id: 'mixHelp', tag: 'mix-help', label: 'About Mix Types', onlyMultipleDecks: true},
  separator3: {id: '---', label: null},
  resetStats: {id: 'resetStats', tag: 'reset-stats', label: 'Reset Class Stats (for ALL Cards)', onlyPro: true,  paywall: 'stats_reset_mix'},
  logOut: {id: 'logOut', tag: 'logout', label: 'Log Out'},
};


class StudyMixOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpts: {},
      isProcessingOptionAction: false,
      isStudyMixTypeHelpModalOpen: false,
      shouldShowDialog: false,
    };

    /*
      this.props:
        addClasses,
        currentCard,
        currentUser,
        deckCount,
        isAtCheckpoint,
        isCheckpointOptionsButton,
        isContinuousStudy,
        isDisabled,
        isMobileViewportSize,
        mixType,
        progressView,  // 'thisRound' or 'overall'
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.updateUserOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser.flags.isPro != prevProps.currentUser.flags.isPro) {
      this.updateUserOptions();
    }

    if (this.props.isMobileViewportSize != prevProps.isMobileViewportSize) {
      this.updateUserOptions();
    }

    if (this.props.deckCount != prevProps.deckCount) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const continuousStudyEnabledClass = this.props.isContinuousStudy ? 'continuous-study-enabled' : '';
    const mixTypeClass = this.props.mixType == 'random' ? 'random-mix' : 'progressive-mix';
    const progressViewClass = this.props.progressView == 'thisRound' ? 'view-this-round-progress' : 'view-overall-progress';
    let classes = toClassStr(['study-mix-options-button', continuousStudyEnabledClass, mixTypeClass, progressViewClass, this.props.addClasses]);
    // const openPosition = this.props.isMobileViewportSize ? "bottomLeft" : "bottomRight";

    return (
      <div className={classes}>
        <OptionsMenuButton
          isDisabled={this.props.isDisabled}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.currentUser.flags.isPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition="bottomLeft"
          shouldButtonTurn={true}
          title={this.props.title}
          tooltipContent={this.props.tooltipContent}
          tooltipPosition={this.props.tooltipPosition}
        />

        {this.renderStudyMixTypeHelpModal()}
      </div>
    );
  }

  renderStudyMixTypeHelpModal() {
    return (
      <Modal
        addClasses="mix-type-help-modal"
        isOpen={this.state.isStudyMixTypeHelpModalOpen}
        onCloseRequest={() => this.handleHelpModalCloseRequest()}
      >

        <div className="modal-title">About Brainscape Study Mix Types</div>

        <div className="modal-message">You can configure your study session in the following ways:</div>

        <div className="modal-message"><strong>'Progressive'</strong> selects cards from decks in the order they appear. So, you will not see cards from the 2nd deck until you've mostly mastered the 1st deck.  Once you've moved to a later deck, cards from earlier decks can still repeat occasionally.</div>

        <div className="modal-message"><strong>'Random'</strong> will select from a pool of cards across all selected decks.  The repetition priority is still given to the lower-confidence items, but new/un-seen cards may be chosen from any position in any of the selected decks.</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Ok"
            onClick={() => this.handleHelpOkButtonClick()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick(optionId) {
    const option = MENU_OPTIONS_HASH[optionId];
    let requiresPro = option.onlyPro;

    if (requiresPro && !this.props.currentUser.flags.isPro) {
      let desiredAction = option.label || "";
      let paywall = option.paywall || "";
      this.triggerUpgradeModalOpen(desiredAction, paywall);
    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleHelpModalCloseRequest() {
    this.setState({
      isStudyMixTypeHelpModalOpen: false,
    });
  }

  handleHelpOkButtonClick() {
    this.setState({
      isStudyMixTypeHelpModalOpen: false,
    });
  }

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'previewDeckCards':
        this.performPreviewDeckCardsAction();
      break;
      case 'continuousStudy':
        this.performEnableContinuousStudyAction();
      break;
      case 'continuousStudyByPro':
        this.performEnableContinuousStudyAction();
      break;
      case 'resetStats':
        this.performResetStatsAction();
      break;
      case 'thisRound':
        this.props.onChangeProgressView('thisRound');
      break;
      case 'overall':
        this.props.onChangeProgressView('overall');
      break;
      case 'setProgressiveMix':
        this.performSetProgressiveMixAction();
      break;
      case 'setRandomMix':
        this.performSetRandomMixAction();
      break;
      case 'mixHelp':
        this.performShowMixHelpAction();
      break;
      case 'logOut':
        this.performLogOutAction();
      break;
    }
  }


  /*
  ==================================================
   EVENT HANDLER HELPERS
  ==================================================
  */

  performPreviewDeckCardsAction() {
    this.triggerDeckPreviewModalOpen();
  }

  performEnableContinuousStudyAction() {
    this.triggerToggleContinuousStudy();

    if (this.props.isAtCheckpoint) {
      this.triggerStartNewRoundRequest();
    }
  }

  performLogOutAction() {
    UiHelper.navigate('/sign_out');
  }

  performResetStatsAction() {
    this.setState({
      isProcessingOptionAction: true
    });

    this.triggerConfirmModalOpen({
      actionText: 'reset Class Stats. This action will reset your confidences for each card in the class and cannot be undone',
      resolveButtonText: 'Yes, Reset Stats',
      onResolution: () => {
        packConfidence.reset(this.props.currentPack.packId).then(() => {
          this.triggerToastOpen('Class stats reset. Restarting study.', 'success');
           setTimeout(() => location.reload(), 1000);
        });

        this.setState({
          isProcessingOptionAction: false,
        });
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performSetProgressiveMixAction() {
    this.triggerSetPackMixTypeRequested('progressive');
  }

  performSetRandomMixAction() {
    this.triggerSetPackMixTypeRequested('random');
  }

  performShowMixHelpAction() {
    this.setState({
      isStudyMixTypeHelpModalOpen: true,
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerDeckPreviewModalOpen = () => {
    EventManager.emitEvent('deck-preview-modal:open', {
      deckId:  this.props.currentDeck.deckId,
      packId:  this.props.currentPack.packId,
    });
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerSetPackMixTypeRequested = (mixType) => {
    EventManager.emitEvent('user-pack-config:mix-type', {
      mixType: mixType,
      packId: this.props.currentPack.packId,
    });
  }

  triggerStartNewRoundRequest = () => {
    EventManager.emitEvent('study-session:new-round-requested', {});
  }

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type, duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }

  triggerToggleContinuousStudy() {
    EventManager.emitEvent('user-prefs:toggle-continuous-study', {});
  }

  triggerUpgradeModalOpen(desiredAction, paywall) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  isMixRestricted = () => {
    const pack = this.props.currentPack;
    const user = this.props.currentUser;

    if (user.flags.isPro) {
      return false;
    }

    if (pack.permission == 'study' && pack.discoveryMedium == 'discovered') {
      return true;
    }

    return false;
  }

  updateUserOptions() {
    let menuOptionsForUser = Object.keys(MENU_OPTIONS_HASH).reduce((accumulator, menuOptionId) => {
      const menuOption = MENU_OPTIONS_HASH[menuOptionId];

      const permitPerMixRestriction = (menuOption.onlyIfMixIsRestricted) ? this.isMixRestricted() : true;
      const permitPerMixUnrestriction = (menuOption.onlyIfMixIsUnrestricted) ? !this.isMixRestricted() : true;
      const permitPerDeviceType = (menuOption.onlyMobile) ? this.props.isMobileViewportSize : true;
      const permitPerMultipleDecks = (menuOption.onlyMultipleDecks) ? (this.props.deckCount > 1) : true;

      if (permitPerMixRestriction && permitPerMixUnrestriction && permitPerDeviceType && permitPerMultipleDecks) {
        accumulator.push(menuOption);
      }

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser,
    });
  }
}

export default StudyMixOptionsButton;
