
import EventManager       from '@brainscape/event-manager';
import PillButton         from '_views/shared/PillButton';
import PropTypes          from 'prop-types';
import React              from 'react';

import {toClassStr}       from '_utils/UiHelper';

const PT = {
  addClasses:                     PropTypes.string,
  face:                           PropTypes.string,
  isDisabled:                     PropTypes.bool,
  isFtse:                         PropTypes.bool,
  level:                          PropTypes.number,
  onFtseRevealAnswerButtonClick:  PropTypes.func,
  onRevealCardFaceRequest:        PropTypes.func,
  shouldPulse:                    PropTypes.bool,
  tooltipContent:                 PropTypes.string,
  tooltipPosition:                PropTypes.string,
};

const PULSATION_DELAY = 500; // allows for mounting of component and entry/exit animation before commencement of pulsation


class RevealCardFaceButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPulsing: false,
    }

    this.pulsationTimeout = null;

    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.startKeydownMonitor();
    this.subscribeToEvents();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFtse && !this.props.isFtse) {
      this.unsubscribeToEvents();
    }
  }

  componentWillUnmount() {
    this.stopKeydownMonitor();
    this.clearTimeoutsAndIntervals();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    if (!this.props.isFtse) {
      return false;
    }

    this.events.addListener('ftse:pulse-reveal-answer-button', this.handleFtsePulseRevealAnswerButton);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const face = this.props.face;
    const buttonLabel = (face == 'answer') ? 'Show Question Only' : 'Reveal Answer';
    const level = this.props.level;
    const levelClass = (level) ? `level-${level}` : 'level-0';
    const pulseClass = this.state.isPulsing ? 'pulse' : '';

    const classes = toClassStr(['reveal-card-face-button', levelClass, pulseClass]);

    return (
      <PillButton
        addClasses={classes}
        isDisabled={this.props.isDisabled}
        key={`${face}-button`}
        label={buttonLabel}
        onClick={this.handleButtonClick}
        title="Press space on your keyboard"
        tooltipContent={this.props.tooltipContent}
        tooltipPosition={this.props.tooltipPosition}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick = () => {
    this.triggerStopAllSoundsRequest();

    this.setState({
      isPulsing: false,
    }, () => {
      if (this.props.isFtse && this.props.face == 'question' && this.props.onFtseRevealAnswerButtonClick) {
        this.props.onFtseRevealAnswerButtonClick();
      }

      if (this.props.onRevealCardFaceRequest) {
        this.props.onRevealCardFaceRequest();
      }

      this.triggerRevealCardFaceRequest();
    });
  }

  handleConfidenceRating = (rating) => {
    this.triggerStopAllSoundsRequest();

    // handle any FTSE messaging, then process rating
    if (this.props.isFtse && this.props.shouldPulse) {
      this.setState({
        isPulsing: false
      }, () => {
        // ftse: dismiss answer bubble, display cbr bubble, and set post cbr bubble action upstream
        const ftsePostCbrBubbleAction = () => {
          if (this.props.onConfidenceRating) {
            this.props.onConfidenceRating(rating);
          }
        }

        this.props.onFtseConfidenceButtonClick(rating, ftsePostCbrBubbleAction);
      });
    } else {
      if (this.props.onConfidenceRating) {
        this.props.onConfidenceRating(rating);
      }
    }
  }

  handleKeyDown = (e) => {
    if (this.props.isDisabled) {
      return false;
    }
    
    // space bar
    if (e.keyCode == 32) {
      e.preventDefault();
      e.stopPropagation();

      this.handleButtonClick();
    }
  };

  handleFtsePulseRevealAnswerButton = () => {
    this.invokePulsation();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerStopAllSoundsRequest() {
    EventManager.emitEvent('sound-player:stop-all-request', {});
  }

  triggerRevealCardFaceRequest() {
    const newFace = (this.props.face == 'answer') ? 'question' : 'answer';

    EventManager.emitEvent('smart-card:reveal-card-face-requested', {
      face: newFace,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.pulsationTimeout);
  }

  invokePulsation() {
    clearTimeout(this.pulsationTimeout);

    this.pulsationTimeout = setTimeout(() => {
      this.setState({
        isPulsing: true,
      });
      clearTimeout(this.pulsationTimeout);
    }, PULSATION_DELAY);
  }

  startKeydownMonitor = () => {
    if (this._isMounted) {
      document.addEventListener('keydown', this.handleKeyDown);
    }
  }

  stopKeydownMonitor = () => {
    if (this._isMounted) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  }
}

RevealCardFaceButton.propTypes = PT;

export default RevealCardFaceButton;
