
import ErrorModal                   from '_views/modals/ErrorModal';
import EventManager                 from '@brainscape/event-manager';
import React                        from 'react';

class ErrorModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {isModalOpen: false, viewProps: null};
    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('error-modal:open',  this.handleRequest);
    this.events.addListener('error-modal:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) {
      return null;
    }

    return (
      <ErrorModal
        addClasses={viewProps.addClasses}
        errorListing={viewProps.errorListing}
        errorMessage={viewProps.errorMessage}
        isOpen={this.state.isModalOpen}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleRequest = (data) => {
    this.setState({isModalOpen: true, viewProps: data});
  };


  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  };

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }
}

export default ErrorModalController;
