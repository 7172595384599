
import DynamicTooltipIcon        from '_views/shared/DynamicTooltipIcon';
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import Pulldown                  from '_views/shared/Pulldown';
import React                     from 'react';
import StringHelper              from '_utils/StringHelper';
import TextField                 from '_views/shared/TextField';

const CONTENT_TYPE_OPTIONS = [
  {id: 'md', label: 'Markdown'},
  {id: 'text', label: 'Text'},
  {id: 'html', label: 'HTML'},
];


class CreateDeckModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deckName: "",
      deckObjective: "",
      hasValidInput: false,
    };

    /*
      this.props:
        addClasses,
        isOpen,
        isProcessing,
        onCloseRequest,
        onFormSubmit,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.resetFields();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <Modal
        addClasses="create-deck-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Create New Deck</div>

        <div className="modal-guidance">A Deck is a subset of Flashcards in a Class, similar to chapters in a book</div>

        <form
          className="create-deck-form"
          onSubmit={(e) => this.handleFormSubmit(e)}
        >

          <TextField 
            addClasses="new-deck-name"
            buttons={this.renderDeckNameInfoIcon()}
            hasInitialFocus={true}
            id="new-deck-name"
            onChange={this.handleNameChange}
            placeholder="Deck title"
            value={this.state.deckName}
          />

          <TextField 
            addClasses="new-deck-objective"
            buttons={this.renderDeckObjectiveInfoIcon()}
            id="new-deck-objective"
            onChange={this.handleObjectiveChange}
            placeholder="Deck objective (optional)"
            type={'adjustable-textarea'}
            value={this.state.deckObjective}
          />

          <input type="submit" className="hidden-submit-button" />
        </form>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            isDisabled={!this.state.hasValidInput}
            isProcessing={this.props.isProcessing}
            label="Continue"
            onClick={this.handleContinueButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderDeckNameInfoIcon() {
    return (
      <DynamicTooltipIcon 
        body="e.g. Chapter 1, Cellular Metabolism, Everyday phrases, etc"
        iconType="info"
        isSimpleTooltip={true}
        position="top"
      />
    );
  }

  renderDeckObjectiveInfoIcon() {
    return (
      <DynamicTooltipIcon 
        body="A short description of the topics covered in this deck"
        iconType="info"
        isSimpleTooltip={true}
        position="top"
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleContinueButtonClick = () => {
    this.handleFormSubmit();
  }

  handleFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.state.hasValidInput && !this.props.isProcessing) {
      this.props.onFormSubmit({
        name: this.state.deckName, 
        desc: this.state.deckObjective, 
      });
    }
  }

  handleNameChange = (e) => {
    const nameText = e.target.value;
    const hasValidInput = !StringHelper.isWhitespace(nameText);

    this.setState({
      deckName: nameText,
      hasValidInput: hasValidInput,
    });
  }

  handleObjectiveChange = (e) => {
    const objectiveText = e.target.value;

    this.setState({
      deckObjective: objectiveText,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  resetFields() {
    this.setState({
      deckName: "",
      deckObjective: "",
      hasValidInput: false,
    });
  }
}

export default CreateDeckModal;
