
import EventManager              from '@brainscape/event-manager';
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import SearchBox                 from '_views/shared/SearchBox';
import SimpleTextButton          from '_views/shared/SimpleTextButton';
import Spinner                   from '_views/shared/Spinner';

import {
  DismissButton,
  RemoveButton,
  UndoButton,
}                                from '_views/shared/IconButton';

import {
  CircledAddIconTextButton,
}                                from '_views/shared/IconTextButton';

import {toClassStr}              from '_utils/UiHelper';

const PT = {
  addClasses:                     PropTypes.string,
  isLoading:                      PropTypes.bool,
  isOpen:                         PropTypes.bool,
  isProcessing:                   PropTypes.bool,
  deck:                           PropTypes.object,
  categoryOptions:                PropTypes.array,
  deckCategories:                 PropTypes.array,
  onUpdateRequest:                PropTypes.func,
  onCloseRequest:                 PropTypes.func,
};


class ManageDeckCategoriesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddingCategory: false,
      isProcessingUpdate: false,
      isProcessingSearch: false,
      pendingAdditions: [],
      pendingRemovals: [],
      searchResults: this.props.categoryOptions,
      searchQueryText: '',
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categoryOptions != this.props.categoryOptions) {
      this.resetSearchResults();
    }

    if (prevProps.isOpen != this.props.isOpen) {
      this.resetAll();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['manage-deck-categories-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={this.props.onCloseRequest}
        onClosed={this.props.onClosed}
      >

        <div className="modal-title">Manage Deck Categories</div>
        <div className="modal-guidance">Add or remove this Deck from Categories (BCC's)</div>

        {this.renderCategoryInfo()}

        <div className="modal-actions">

          <PillButton
            addClasses="resolve-modal-button"
            isProcessing={this.state.isProcessingUpdate}
            label="Update"
            onClick={this.handleUpdateButtonClick}
          />

          <SimpleTextButton
            addClasses="cancel-modal-button"
            label="Cancel"
            onClick={this.handleCancelButtonClick}
          />
        </div>
      </Modal>
    );
  }

  renderCategoryInfo() {
    const classes = 'category-info';

    if (this.props.isLoading) {
      return (
        <div className={classes}>
          <Spinner 
            color="blue"
          />
        </div>
      );
    }

    const currentCategories = this.props.deckCategories.map(deckCategory => {
      return this.renderCategoryRow(deckCategory);
    });

    return (
      <div className={classes}>

        {this.renderCurrentCategoriesHeading()}

        <ul className="current-categories">

          {currentCategories}

          {this.renderPendingCategories()}
          {this.renderAddCategoryRow()}
          {this.renderCategorySearchBox()}
        </ul>
      </div>
    );
  }

  renderCurrentCategoriesHeading() {
    if (this.state.pendingAdditions.length > 0 || this.state.pendingRemovals.length > 0) {
      return (
        <div className="current-categories-heading">The Deck "<strong>{this.props.deck.name}</strong>" will be updated to appear in Categories as detailed below (additions in <span className="green">green</span>, removals in <span className="red">red</span>):</div>
      );
    }

    if (this.props.deckCategories?.length < 1) {
      return (
        <div className="current-categories-heading">The Deck "<strong>{this.props.deck.name}</strong>" is not currently assigned to any Categories</div>
      );
    }

    return (
      <div className="current-categories-heading">The Deck "<strong>{this.props.deck.name}</strong>" is currently assigned to the following Categories:</div>
    );
  }

  renderCategoryRow(category) {
    const isPendingRemovalClass = this.isPendingRemoval(category.id) ? 'is-pending-removal' : '';
    const isPendingAdditionClass = this.isPendingAddition(category.id) ? 'is-pending-addition' : '';
    const classes = toClassStr(['deck-category', isPendingRemovalClass, isPendingAdditionClass]);

    return (
      <li className={classes} key={`category-${category.id}`}>
        <div className="deck-category-label">{category.label}</div>
        {this.renderDeckCategoryControls(category.id)}
      </li>
    );
  }

  renderDeckCategoryControls(categoryId) {
    if (this.isPendingRemoval(categoryId)) {
      return (
        <UndoButton 
          onClick={() => this.handleUndoRemoveDeckFromCategoryButtonClick(categoryId)}
          tooltipContent="Undo Removing this Deck from this Category"
          tooltipPosition="top"
        />
      );
    }

    return (
      <RemoveButton 
        onClick={() => this.handleRemoveDeckFromCategoryButtonClick(categoryId)}
        tooltipContent="Remove this Deck from this Category"
        tooltipPosition="top"
      />
    );
  }

  renderPendingCategories() {
    const pendingAdditions = this.state.pendingAdditions;

    if (pendingAdditions?.length < 1) {
      return null;
    }

    const pendingCategories = pendingAdditions.map(pendingAdditionId => {
      const pendingCategoryOption = this.props.categoryOptions.find(categoryOption => {
        return (categoryOption.id == pendingAdditionId);
      });

      if (!pendingCategoryOption) {
        return null;
      }

      return this.renderCategoryRow(pendingCategoryOption);
    });

    return pendingCategories;
  }

  renderAddCategoryRow() {
    if (this.state.isAddingCategory) {
      return null;
    }

    return (
      <li className="add-category-row">
        <CircledAddIconTextButton 
          addClasses="add-category-button"
          label="Add deck to another category..."
          onClick={this.handleAddCategoryButtonClick}
        />
      </li>
    );
  }

  renderCategorySearchBox() {
    if (!this.state.isAddingCategory) {
      return null;
    }

    return (
      <li className="category-search-box">
        <SearchBox
          isProcessing={this.state.isProcessingSearch}
          onClearQueryRequest={this.handleClearQueryRequest}
          onQueryTextChange={this.handleQueryTextChange}
          onResultClick={this.handleSearchResultClick}
          placeholderText="Find/Select Category..."
          queryText={this.state.searchQueryText}
          results={this.state.searchResults}
        />

        <DismissButton 
          addClasses="dismiss-search-box-button"
          onClick={this.handleDismissSearchBoxButtonClick}
          tooltipContent="Dismiss Add to Category"
          tooltipPosition="top"
        />
      </li>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddCategoryButtonClick = () => {
    this.setState({
      isAddingCategory: true,
    });
  }

  handleCancelButtonClick = () => {
    this.props.onCloseRequest();
  }

  handleClearQueryRequest = () => {
    this.resetSearchResults();
  }

  handleDismissSearchBoxButtonClick = () => {
    this.setState({
      isAddingCategory: false,
    });
  }

  handleQueryTextChange = (newValue) => {
    if (newValue?.length < 1) {
      this.resetSearchResults();
      return false;
    }

    if (this.props.categoryOptions?.length > 0) {
      const matches = this.props.categoryOptions.filter(option => {
        return (option.label.toLowerCase().indexOf(newValue.toLowerCase()) != -1);
      });

      this.setState({
        searchQueryText: newValue,
        searchResults: matches,
      });
    }
  }

  handleAddDeckToCategoryButtonClick = (categoryId) => {
    if (!this.isValidAddition(categoryId)) {
      return false;
    }

    const pendingAdditions = [...this.state.pendingAdditions];

    pendingAdditions.push(categoryId);

    this.setState({
      pendingAdditions: pendingAdditions,
    });
  }

  handleRemoveDeckFromCategoryButtonClick = (categoryId) => {
    const pendingAdditions = [...this.state.pendingAdditions];

    const index = pendingAdditions.indexOf(categoryId);

    if (index != -1) {
      // this is a pendingAddition the user wishes to dismiss, simply remove from pendingAdditions. No API operation needed

      pendingAdditions.splice(index, 1);

      this.setState({
        pendingAdditions: pendingAdditions,
      });

      return true;
    }

    // if we reach here, this is a current assignment which will need to be removed in an API operation
    const pendingRemovals = [...this.state.pendingRemovals];

    pendingRemovals.push(categoryId);

    this.setState({
      pendingRemovals: pendingRemovals,
    });
  }

  handleUndoRemoveDeckFromCategoryButtonClick = (categoryId) => {
    const pendingRemovals = [...this.state.pendingRemovals];

    const index = pendingRemovals.indexOf(categoryId);

    if (index != -1) {
      pendingRemovals.splice(index, 1);

      this.setState({
        pendingRemovals: pendingRemovals,
      });
    }
  }

  handleSearchResultClick = (resultId) => {
    this.handleAddDeckToCategoryButtonClick(resultId);

    this.setState({
      isAddingCategory: false,
    });
  }

  handleUpdateButtonClick = () => {
    const {pendingAdditions, pendingRemovals} = this.state;

    if (pendingRemovals?.length > 0) {
      this.triggerConfirmModalOpen({
        actionText: `remove this deck from ${pendingRemovals.length} categorie(s)`,
        resolveButtonText: 'Yes, please remove',
        onResolution: () => {
          this.setState({
            isProcessingUpdate: true,
          }, () => {
            this.props.onUpdateRequest(pendingAdditions, pendingRemovals);
          });
        },
      });

      return true;
    }

    this.setState({
      isProcessingUpdate: true,
    }, () => {
      this.props.onUpdateRequest(pendingAdditions, pendingRemovals);
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  isPendingAddition = (categoryId) => {
    return (this.state.pendingAdditions.indexOf(categoryId) != -1);
  }

  isPendingRemoval = (categoryId) => {
    return (this.state.pendingRemovals.indexOf(categoryId) != -1);
  }

  isValidAddition = (categoryId) => {
    if (this.state.pendingAdditions.indexOf(categoryId) != -1) {
      return false;
    }

    if (this.state.pendingRemovals.indexOf(categoryId) != -1) {
      return false;
    }

    const categoryIndex = this.props.deckCategories.findIndex(deckCategory => {
      return (deckCategory.id == categoryId);
    })

    if (categoryIndex != -1) {
      return false;
    }

    return true;
  }

  resetAll = () => {
    this.setState({
      isAddingCategory: false,
      isProcessingUpdate: false,
      isProcessingSearch: false,
      pendingAdditions: [],
      pendingRemovals: [],
      searchResults: this.props.categoryOptions,
      searchQueryText: '',
    });
  }

  resetSearchResults = () => {
    this.setState({
      searchQueryText: '',
      searchResults: this.props.categoryOptions,
    });
  }

}

ManageDeckCategoriesModal.propTypes = PT;

export default ManageDeckCategoriesModal;
