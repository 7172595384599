
import {apiGet}     from '../core/Api1';
import {apiGet as apiGetV2}    from '../core/Api2';
import LibraryModel from '_legacy-models/LibraryModel';
import Model        from '_core/Model';
import StudyHelper  from '_utils/StudyHelper';

class PackModel extends Model {
  constructor(props) {
    super(props);

    this.memberName = 'Pack';
    super.generateNomenclature();

    this.schema = {
      name: {key: 'name', serverKey: 'name', type: 'text', label: 'Name', caption: '(will display in your menu of classes)', tag: 'name', isRequired: true},
      fullName: {key: 'fullName', serverKey: 'full_name', type: 'text', label: 'Full Name', caption: '(will display in title bar when class is selected)', tag: 'full-name'},
      desc: {key: 'desc', serverKey: 'desc', type: 'textarea', label: 'Description', tag: 'desc'},
      isPrivate: {key: 'isPrivate', serverKey: 'is_private', type: 'text', label: 'Is Private', tag: 'is-private', onlyPro: true},
      mixType: {key: 'mixType', serverKey: 'mix_type', type: 'radio', label: 'Mix Type', tag: 'mix-type', options: [{label: 'Random', value: 'random'}, {label: 'Progressive', value: 'progressive'}, {label: 'None', value: 'none'}]},
      categoryIds: {key: 'categoryIds', serverKey: 'categoryIds', type: 'text', label: 'Category ID', tag: 'category-ids', onlyAdmin: true}
    }
    this.nameKey = "name";

    this.library = new LibraryModel();
  }

  index(opts) {
    const url = this.buildUrl(this.getResourceUrlBase(), opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
      .done((data, status) => {
        opts.done(data);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  show(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id + '/user_packs', opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
      .done((data, status) => {
        opts.done(data.userPack);
        // TODO: Remove this if block after migrating all users to new dashboard
        if (!(opts.params && opts.params.page)) {
          this.library.syncPackDecks(data.userPack);
        }
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  create(opts) {
    const url = this.buildUrl(this.getResourceUrlBase(), opts.params);

    return $.ajax({
      type: 'POST',
      url: url,
      data: JSON.stringify(opts.reqData),
      contentType: "application/json"
    })
      .done((data, status) => {
        if (opts.done && typeof opts.done == 'function') {
          opts.done(data, status);
          this.library.addPack(data);
        }
      })
      .fail((jqXhr, status) => {
        if (opts.fail && typeof opts.fail == 'function') {
          opts.fail(jqXhr, status);
        }
      });
  }

  share(opts) {
    const url = this.buildUrl('/api/shares/create', opts.params);

    return $.ajax({
      type: 'POST',
      url: url,
      data: JSON.stringify(opts.reqData),
      contentType: "application/json"
    })
      .done((data, status) => {
        if (opts.done && typeof opts.done == 'function') {
          opts.done(data, status);
        }
      })
      .fail((jqXhr, status) => {
        if (opts.fail && typeof opts.fail == 'function') {
          opts.fail(jqXhr, status);
        }
      });
  }

  duplicate(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id + '/duplicate', opts.params);

    return $.ajax({
      type: 'POST',
      url: url
    })
      .done((data, status) => {
        opts.done(data);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  remove(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id + '/remove.json', opts.params);

    return $.ajax({
      type: 'DELETE',
      url: url
    })
      .done((data, status) => {
        opts.done(data);
        // this.library.removePack(opts.id);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  delete(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id, opts.params);

    return $.ajax({
      type: 'DELETE',
      url: url
    })
      .done((data, status) => {
        if (opts.done && typeof opts.done == 'function') {
          opts.done(data, status);
          // this.library.removePack(opts.id);
        }
      })
      .fail((jqXhr, status) => {
        if (opts.fail && typeof opts.fail == 'function') {
          opts.fail(jqXhr, status);
        }
      });
  }

  getStats(opts) {
    const url = this.buildUrl('/api/study/cbr', opts.params);
    const packDeckList = StudyHelper.generatePackDeckList(opts.packId, opts.deckIds);

    $.ajax({
      method:   'PUT',
      url:      url,
      dataType: 'json',
      data:     {classes: packDeckList},
      cache:    false,
    }).done((data, status) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, status);
      }
    }).fail((jqXHR, status) => {
      console.log('Something went wrong in Pack.getStats. jqXHR, status:', jqXHR, status);
    });
  }

  resetStats(opts) {
    const url = '/packs/' + opts.id + '/reset';
    // NON STANDARD ROUTE: uses legacy non-api route

    return $.ajax({
      type: 'GET',
      url: url,
    })
      .done((data, status) => {
        opts.done(data);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  refreshStats(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id + '/refresh_stats', opts.params);

    return $.ajax({
      type: 'PATCH',
      url: url
    })
      .done((data, status) => {
        opts.done(data);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  getEditables(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/get_editables', opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
      .done((data, status) => {
        opts.done(data);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  reorderDecks(opts) {
    const url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id + '/decks/' + opts.deckId + '/position', opts.params);

    return $.ajax({
      type: 'POST',
      url: url
    })
      .done((data, status) => {
        opts.done(data);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  trendingIndex(opts) {
    // TODO: this is just a group of random categories at this time. revise to retrieve actually trending packs/categories
    const url = this.buildUrl('/market/random', opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
      .done((data, status) => {
        const randomCategories = data.data;

        let trendingData = randomCategories.map((category, index) => {
          return {
            name: category.name,
            browsePath: '/learn/' + category.url,
            iconUrl: category.thumb,
            id: category.id,
            marketHeadline: category.market_headline,
          };
        });

        opts.done(trendingData);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  learners = (packId, params) => {    
    if (!packId) { return Promise.reject('no pack ID specified'); }

    return apiGetV2(`packs/${packId}/learners_stats`, params).then((data) => {
      this.library.syncPackLearners(packId, data.learners);
      return data;
    }).catch(err => {
      console.error(err);
    });
  }

  leaderboard = (packId, params) => {
    if (!packId) { return Promise.reject('no pack ID specified'); }

    return apiGet(`packs/${packId}/leaderboard`, params).then((data) => {
      // Transform the leadeboard data into the learner format for
      // easier reuse.
      data.learners = data.users;
      data.learners.forEach((l) => l.medium = l.discoveryMedium);

      this.library.syncPackLearners(packId, data.learners);

      return data;
    }).catch(err => {
      console.error(err);
    });
  }

  sendMessage(opts) {
    const url = this.buildUrl('/packs/' + opts.id + '/suggest.json', opts.params);

    return $.ajax({
      type: 'POST',
      data: JSON.stringify(opts.reqData),
      contentType: "application/json",
      url: url
    })
      .done((data, status) => {
        opts.done(data);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }

  // move this to base class:
  // https://stackoverflow.com/questions/29473426/fetch-reject-promise-with-json-error-object
  fetchStatus = (rs)  => {
    return rs.json().then(json => {
      return rs.ok ? json : Promise.reject(json);
    }).catch(err => {
      console.error(err);
    });
  }

  // move this to base class:
  poll = (fn, isGoodValue, isBadValue, interval, maxAttempts) => {
    let attempts = 0;

    const executePoll = (resolve, reject) => {
      fn().then((result) => {
        attempts++;

        if (isGoodValue(result)) {
          return resolve(result);
        } else if (isBadValue(result)) {
          return reject(new Error(result));
        } else if (maxAttempts && attempts === maxAttempts) {
          return reject(new Error('Exceeded max attempts'));
        } else {
          setTimeout(executePoll, interval(attempts), resolve, reject);
        }
      }).catch(err => {
        console.error(err);
      });
    };

    return new Promise(executePoll);
  }

  iconUploadJobStatus = (jobId) => {
    if (!jobId) {
      return Promise.resolve("");
    }

    const maxAttempts = 10 // 5 minutes
    const url = '/api/jobs.json?id=' + jobId;
    const opts = {
      method: 'GET',
    };
    const fibNums = [0, 1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377];
    const baseTimeoutInterval = 1000; // 1 second

    return this.poll(
      () => {return fetch(url, opts).then(this.fetchStatus)}, // fn
      (data) => {
        let jobStatus = data[jobId];
        if (jobStatus == "fulfilled") {
          return true;
        } else {
          return false;
        }
      }, // isGoodValue
      (data) => {
        let jobStatus = data[jobId];
        if (jobStatus == "rejected") {
          return true;
        } else {
          return false;
        }
      }, // isBadValue
      (numTries) => { return (fibNums[numTries] || fibNums[fibNums.length - 1]) * baseTimeoutInterval; }, // interval
      maxAttempts
    );
  }

  uploadIcon = (packId, iconFile) => {
    const url = '/api/packs/' + packId + '/icon';
    const fd = new FormData();
    fd.append('icon', iconFile);

    const opts = {
      method: 'POST',
      body: fd,
    };

    const checkData = (data) => {
      if ({} == data) { // synchronous processing on the server...
        return new Promise((resolve) => resolve(""));
      } else { // asynchronous processing on the server
        return this.iconUploadJobStatus(data.jobId);
      }
    }

    return fetch(url, opts).then(this.fetchStatus).then(checkData);
  }

  updateIconUrl = (packId, iconFileName, iconPath) => {
    const apiUrl = '/api/packs/' + packId + '/icon';
    const data = {
      icon_file_name: iconFileName,
      icon_path: iconPath,
    };

    const opts = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const checkData = (data) => {
      if (data == {}) { // synchronous processing on the server...
        return new Promise((resolve) => resolve(""));
      } else { // asynchronous processing on the server
        return this.iconUploadJobStatus(data.jobId);
      }
    }

    return fetch(apiUrl, opts).then(this.fetchStatus).then(checkData);
  }

  dummyData(opts) {
    const url = this.buildUrl('/api/packs/' + opts.id + '/dummy_data', opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
      .done((data, status) => {
        opts.done(data);
      })
      .fail((jqXHR, status) => {
        opts.fail(status, jqXHR.responseJSON||{});
      });
  }
}

export default PackModel;
