
import PropTypes              from 'prop-types';
import React                  from 'react';
import UiHelper               from '_utils/UiHelper';

import {toClassStr}           from '_utils/UiHelper';

const PT = {
  addClasses:                 PropTypes.string,
  studyMixUrl:                PropTypes.string,  // as output by StudyHelper.getPackStudyMixUrl
};


class StudyPillButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isDisabledClass = (this.props.isDisabled) ? 'is-disabled' : '';
    const classes = toClassStr(['pill-button study-pill-button', isDisabledClass, this.props.addClasses]);
    const buttonLabel = this.props.label || 'Study';

    return (
      <div className={classes} onClick={this.handleButtonClick}>
        <div className="label">{buttonLabel}</div>
        <i className="study-button-icon"/>
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick = () => {
    if (!this.props.studyMixUrl) {
      return false;
    }

    UiHelper.navigate(this.props.studyMixUrl, 'Launching Study...');
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

StudyPillButton.propTypes = PT;

export default StudyPillButton;
