
import DialogModal        from '_views/modals/DialogModal';
import EventManager       from '@brainscape/event-manager';
import React              from 'react';

class DialogModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener(
      'dialog-modal:open',
      (data) => this.setState({
        isModalOpen: true,
        viewProps: data
      }),
    );

    this.events.addListener(
      'dialog-modal:close',
      () => {
        this.handleCloseRequest();
      }
    );
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;

    if (!viewProps) { 
      return null; 
    }

    return (
      <DialogModal
        addClasses={viewProps.addClasses}
        resolveButtonText={viewProps.resolveButtonText}
        isOpen={this.state.isModalOpen}
        imageType={viewProps.imageType}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        heading={viewProps.heading}    
        subHeading={viewProps.subHeading}       
        body={viewProps.body}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  handleResolutionRequest = () => {
    if (this.state.viewProps && this.state.viewProps.onResolution) {
      this.state.viewProps.onResolution();
    } else {
      this.handleCloseRequest();
    }
  }
}

export default DialogModalController;
