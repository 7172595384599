
import model from '_core/newModel';

const studyCard = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index(packId, deckId) {
    const path = `study/packs/${packId}/decks/${deckId}/cards`;
    return model.index(path);
  },

  show(packId, deckId, cardId) {
    const path = `study/packs/${packId}/decks/${deckId}/cards/${cardId}`;
    return model.show(path);
  },

  update(packId, deckId, cardId, data) {
    const path = `study/packs/${packId}/decks/${deckId}/cards/${cardId}`;

    const userCardData = {
      level: data.level,
      prevLevel: data.prevLevel,
      ratedEpoch: data.ratedEpoch,
      ratedOn: data.ratedOn,
      timeSpent: data.timeSpent,
      uploaded: false,
    };

    return model.update(path, userCardData);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default studyCard;
