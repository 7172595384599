
import EventManager       from '@brainscape/event-manager';
import PillButton         from '_views/shared/PillButton';
import PropTypes          from 'prop-types';
import React              from 'react';
import SimpleTextButton   from '_views/shared/SimpleTextButton';
import UiHelper           from '_utils/UiHelper';

import {toClassStr}       from '_utils/UiHelper';

import emptyBruce         from '_images/shared/empty-bruce.png';

const PT = {
  addClasses                  : PropTypes.string,
};


class EmptyLibraryGuidance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['empty-library-guidance', this.props.addClasses]);
    
    return (
      <div className={classes}>
        {this.renderHeader()}
        {this.renderMain()}
        {this.renderFooter()}
      </div>
    );
  }

  renderHeader() {
    return (
      <header className="message-segment-header">
        <h3 className="message-segment-heading">Your Library is empty.</h3>
      </header>
    );
  }

  renderMain() {
    return (
      <div className="message-segment-main">
        <img className="empty-bruce" src={emptyBruce} />
        <div className="message-segment-message">Add classes to your library...</div>
      </div>
    );
  }

  renderFooter() { 
    return (
      <footer className="message-segment-footer">
        {this.renderCtaButtons()}
      </footer>
    );
  }

  renderCtaButtons() {
    return (
      <div className="message-segment-ctas">
        {this.renderCtaPrompt()}

        <div className="cta-buttons">
          <PillButton
            addClasses="pill-button-primary find-flashcards-button"
            key="browseButton"
            label="Find Flashcards"
            onClick={this.handleFindFlashcardsButtonClick}
          />
          <PillButton
            addClasses="pill-button-emphasized create-class-button"
            key="createButton"
            label="Create a New Class"
            onClick={this.handleCreateClassButtonClick}
          />
        </div>
      </div>
    );
  }

  renderCtaPrompt() {
    return (
      <p className="message-segment-cta-prompt">While some seek this state intentionally, we suggest you browse Brainscape's catalog of flashcard classes covering thousands of subjects, or, create your own class. Namaste.</p>   
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCreateClassButtonClick = () => {
    this.triggerCreatePackRequest();
  }

  handleFindFlashcardsButtonClick = () => {
    UiHelper.navigate('/subjects', 'Loading Knowledge Genome...');
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCreatePackRequest = () => {
    EventManager.emitEvent('create-pack-modal:open', {});
  }
}


EmptyLibraryGuidance.propTypes = PT;

export default EmptyLibraryGuidance;
