
import React from 'react';

import {toClassStr} from '_utils/UiHelper';

class Spinner extends React.Component {
  render() {
    let classes = toClassStr(["spinner", this.props.color, this.props.addClasses]);

    return(
      <div className={classes}>
        <div className="spinner-wheel"></div>
      </div>   
    );
  }
}

export default Spinner;
