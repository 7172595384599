
import PropTypes                 from 'prop-types';
import React                     from 'react';

import { toClassStr } from '_utils/UiHelper';

const PT = {};

// eventually this would come from a DB
const bscLabsFeatures = [
  {
    id: 1,
    featureName: 'New editor 3000',
    featureDesc: 'Brainscape’s New Editor provides a whole new set of options for your editing purposes! Enjoy the ease with which you can preview your cards on mobile and desktop devices with our new Device Preview feature.'
  },
  {
    id: 2,
    featureName: 'Dark mode',
    featureDesc: 'Light-on-dark color scheme —also called black mode, dark mode, dark theme, night mode, or lights-out — is a color scheme that uses light-colored text, icons, and graphical user interface elements on a dark background. '
  },
  {
    id: 3,
    featureName: 'Swearing substitution',
    featureDesc: 'Do you want to give your flashcards an edge? This feature will automatically subtitute every fifth word in your flashcards with a swear word.'
  },
]

class AccountBscLabsFeatures extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const listItems = bscLabsFeatures.map(({id, featureName, featureDesc}) => (
      <li 
        key={id} 
        className='list-item'
        // onClick={() => this.props.updateCurrentScreen({alias})} 
        >
        <div className='list-item-name'>
          {featureName}
        </div>
        <div className='list-item-desc'>
          {featureDesc}
        </div>
        <div className='toggle-button'></div>
      </li>
    ));

    return (
      <div className="bsc-labs-features">
        <ul className='list-items'
        >{listItems}</ul>
      </div>
    );
  }
}

AccountBscLabsFeatures.propTypes = PT;

export default AccountBscLabsFeatures;
