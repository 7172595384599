
import React, {useRef, useEffect}   from 'react'
import GaHelper                     from '_utils/GaHelper';
import PropTypes                    from 'prop-types';
import PillButton                   from '_views/shared/PillButton';
import dateFormat                   from "dateformat";


const ThankYouPanel = ({
  currentActivePlanPriceTotal,
  paywall,
  term
  }) => {

  useEffect(() => { 
    // GA4
    if (window.gtag) {
      gtag('event', 'page_view', {
        page_title: 'upgrade_successful',
        page_location: `${window.location.href}?upgrade_success=true`,
        logged_state: 2,
        paywall: paywall,
        user_email: window?.userObj?.email
      });
    }

  }, []);

  useEffect(() => {
    const mobileSlideContainer = document.querySelector('.mobile-slide-container');
    if (mobileSlideContainer) {
      // mobileSlideContainer.scrollTo({top: 0, behavior: 'smooth'});
      // Works for ios Safari
      mobileSlideContainer.scrollTop = 0;
    }
  }, [])

  // Hook for causing a refresh when clicking anything outside of component.
  const useOutsideAlerter = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          window.location.reload()
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const stopped_at = () => {
    let date = new Date();

    switch (term) {
      case "daily":       return new Date(date.setDate(date.getDate()+1));
      case "month":       return new Date(date.setMonth(date.getMonth()+1));
      case "quarter":     return new Date(date.setMonth(date.getMonth()+3));
      case "quarterly":   return new Date(date.setMonth(date.getMonth()+3));
      case "semester":    return new Date(date.setMonth(date.getMonth()+6));
      case "semi-yearly": return new Date(date.setMonth(date.getMonth()+6));
      case "year":        return new Date(date.setMonth(date.getMonth()+12));
      case "biennial":    return new Date(date.setMonth(date.getMonth()+24));
      case "biannually":  return new Date(date.setMonth(date.getMonth()+24));
      case "lifetime":    return "forever";
    }
  }

  const paidUntil = typeof stopped_at() === 'object' ? dateFormat(stopped_at(), "mmmm dS, yyyy") : "forever";
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef}>
      <div className='split-modal-title'>Pro Subscription Successful</div>
      <p className="split-modal-subtitle">You have been successfully charged {currentActivePlanPriceTotal}</p>
      <p className="split-modal-subtitle">You now have PRO access and are paid up until {paidUntil}. Subscriptions automatically renew.</p>
      <div className='split-modal-actions'>
        <PillButton
          onClick={() => window.location.reload()}
          label='Click to continue!'
        />
      </div>
    </div>
  )
}

export default ThankYouPanel;
