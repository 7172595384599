
import model                  from '_core/newModel';
import SessionStorageHelper   from '_utils/SessionStorageHelper';

const PERMISSION_OPTIONS_MAP = {
  preview: {
    label: 'Preview',
    permission: 'study',
    discoveryMedium: 'discovered',
  },
  study: {
    label: 'Full Study',
    permission: 'study',
    discoveryMedium: 'admin_share',
  },
  edit: {
    label: 'Edit',
    permission: 'edit',
    discoveryMedium: 'admin_share',
  },
  admin: {
    label: 'Admin',
    permission: 'admin',
    discoveryMedium: 'admin_share',
  },
}

const packLearner = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index(packId, currentUserId=null) {
    const path = `packs/${packId}/learners_stats`;
    const isPaginated = true;

    const paginationOpts = {
      collectionKey: 'learners',
      eventTag: 'pack-learners',
      page: 1,
      perPage: 500,
    };

    return new Promise((resolve, reject) => {
      try {
        model.index(path, isPaginated, paginationOpts).then(res => {
          let learners = res.learners;
          let learnerIds = learners.map(learner => learner.userId);

          if (currentUserId) {
            const hoistedLearnerData = this.hoistCurrentUserLearner(currentUserId, learners, learnerIds);
            learners = hoistedLearnerData.learners;
            learnerIds = hoistedLearnerData.learnerIds;
          }

          const learnersData = {
            learners: learners,
            learnerIds: learnerIds,
          };

          resolve(learnersData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  show(packId, learnerId) {
    const path = `packs/${packId}/learners/${learnerId}`;
    return model.show(path);
  },

  update(packId, userId, learnerData) {
    // this is ultimately an update of the pack_config. the only two fields editable by this endpoint are the permission and the discoveryMedium (which in turn is ultimately the pack_config.medium))
    const path = `packs/${packId}/learners/${userId}`;
    let reqData = {};
    let permission = null;
    let discoveryMedium = null;

    const optionId = learnerData.permissionOptionId;

    if (optionId) {
      permission = PERMISSION_OPTIONS_MAP[optionId].permission;
      discoveryMedium = PERMISSION_OPTIONS_MAP[optionId].discoveryMedium;

      if (discoveryMedium) {
        reqData = {
          permission: permission,
          discoveryMedium: discoveryMedium,
        } 
      } else {
        reqData = {
          permission: permission,
        } 
      }
    }

    return model.update(path, reqData).then(() => {
      model.publish('pack-learner:updated', {
        packId: packId,
        userId: userId,
        permission: permission,
        discoveryMedium: discoveryMedium,
      });
    });
  },

  remove(packId, userId) {
    const path = `packs/${packId}/learners/${userId}`;
    return model.destroy(path).then(() => model.publish('pack-learner:removed', {
      packId: packId,
      userId: userId,
    }));
  },


  /*
  ==================================================
   CACHING
  ==================================================
  */

  getCachedIndex(packId) {
    return SessionStorageHelper.getPackItem(packId, 'packLearners') || null;
  },

  setCachedIndex(packId, packLearners) {
    SessionStorageHelper.setPackItem(packId, 'packLearners', packLearners);
  },


  /*
  ==================================================
   LOCAL TRANSFORM METHODS
  ==================================================
  */

  filter(learners, filters, currentUserId=null) {
    if (!(filters && Object.keys(filters)?.length && Object.keys(filters).length > 0)) {
      return learners;
    }

    let filteredLearners = [...learners];
    let resultLearners;

    Object.keys(filters).forEach(filterKey => {
      resultLearners = [];

      filteredLearners.forEach((learner, index) => {
        if (learner[filterKey].toLowerCase().indexOf(filters[filterKey].toLowerCase()) != -1) {
          resultLearners.push(learner);
        }
      });

      filteredLearners = [...resultLearners];
    });

    if (currentUserId) {
      const resultLearnerIds = resultLearners.map(resultLearner => resultLearner.userId);
      const hoistedLearnerData = this.hoistCurrentUserLearner(currentUserId, resultLearners, resultLearnerIds);
      resultLearners = hoistedLearnerData.learners;
    }

    return resultLearners;
  },

  sort(learners, sorters, currentUserId=null) {
    if (!(sorters && Object.keys(sorters)?.length && Object.keys(sorters).length > 0)) {
      return learners;
    }
    
    let sortedLearners = [...learners];
    let resultLearners;

    const sorterKeys = Object.keys(sorters);
    const reversedSorterKeys = sorterKeys.reverse(); // allows multiple sorters to layer correctly

    reversedSorterKeys.forEach(sorterKey => {
      resultLearners = [];

      const compoundKey = sorterKey.split('.');

      if (compoundKey.length == 2) { 
        // conduct a two level object key search (TODO: write to handle more than 2 levels).
        // there are edge scenarios where the first level entity is not present, so wrap in a try

        try {
          resultLearners = sortedLearners.sort((a, b) => {
            if (sorters[sorterKey] == 'asc') {
              return ((a[compoundKey[0]][compoundKey[1]] >= b[compoundKey[0]][compoundKey[1]]) ? 1 : -1);
            } else {
              return ((a[compoundKey[0]][compoundKey[1]] <= b[compoundKey[0]][compoundKey[1]]) ? 1 : -1);
            }
          });
        } catch(err) {
          console.log('Problem with show packLearner sort. err:', err);
          resultLearners = sortedLearners;
        }

      } else {
        resultLearners = sortedLearners.sort((a, b) => {
          if (sorters[sorterKey] == 'asc') {
            return ((a[sorterKey] >= b[sorterKey]) ? 1 : -1);
          } else {
            return ((a[sorterKey] <= b[sorterKey]) ? 1 : -1);
          }
        });
      }

      sortedLearners = [...resultLearners];
    });

    if (currentUserId) {
      const resultLearnerIds = resultLearners.map(resultLearner => resultLearner.userId);
      const hoistedLearnerData = this.hoistCurrentUserLearner(currentUserId, resultLearners, resultLearnerIds);
      resultLearners = hoistedLearnerData.learners;
    }

    return resultLearners;
  },


  /*
  ==================================================
   CONVENIENCE METHODS
  ==================================================
  */

  showPermission(learner) {
    if (learner.discoveryMedium == 'discovered' && learner.permission == 'study') {
      const permissionValue = 'preview';
      const permissionLabel = 'preview';
      return {permissionValue, permissionLabel};
    }

    if (learner.permission == 'study') {
      const permissionValue = 'study';
      const permissionLabel = 'full study';
      return {permissionValue, permissionLabel};
    }

    const permissionValue = learner.permission;
    const permissionLabel = learner.permission;
    
    return {permissionValue, permissionLabel};
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  hoistCurrentUserLearner(currentUserId, learners, learnerIds) {
    const currentUserIndex = learnerIds.findIndex(learnerId => learnerId == currentUserId);

    let shiftedLearnerIds = learnerIds;
    let shiftedLearners = learners;

    if (currentUserIndex != -1) {
      shiftedLearnerIds = learnerIds.unshift(learnerIds.splice(currentUserIndex, 1)[0]);
      shiftedLearners = learners.unshift(learners.splice(currentUserIndex, 1)[0]);
    }

    return {
      learners: learners,
      learnerIds: learnerIds,
    };
  },
};

export default packLearner;
