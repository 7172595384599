
import EventManager                 from '@brainscape/event-manager';
import PropTypes                    from 'prop-types';
import React                        from 'react';
import SuggestEditModalSet          from '_views/modals/SuggestEditModalSet';

import cardEditSuggestion           from '_models/cardEditSuggestion';

class SuggestEditModalSetController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isLoading: false,
      isProcessing: false,
      isModalOpen: false,
      suggestionMetadata: {},
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('suggest-edit-modal-set:open', this.handleOpenRequest);
    this.events.addListener('suggest-edit-modal-set:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;

    if (!viewProps) { 
      return null; 
    }

    return (
      <SuggestEditModalSet
        addClasses={viewProps.addClasses}
        cardContentType={viewProps.cardContentType}
        cardId={viewProps.cardId}
        deckId={viewProps.deckId}
        isOpen={this.state.isModalOpen}
        isUserPro={viewProps.isUserPro}
        isProcessing={viewProps.isProcessing}
        onCloseRequest={this.handleCloseRequest}
        onSubmitSuggestion={this.handleSubmitSuggestion}
        packId={viewProps.packId}
        suggestionMetadata={this.state.suggestionMetadata}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOpenRequest = (data) => {
    this.setState({
      isModalOpen: false,
      isProcessing: false,
      viewProps: data
    }, () => {
      this.getSuggestionMetadata();
    });
  }

  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  };

  handleSubmitSuggestion = (suggestionText) => {
    const {packId, deckId, cardId} = this.state.viewProps;
    cardEditSuggestion.create(packId, deckId, cardId, suggestionText);
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getSuggestionMetadata = () => {
    const {packId, deckId, cardId} = this.state.viewProps;

    if (!(packId && deckId && cardId)) {
      return false;
    }

    this.setState({
      isLoading: true,
    });

    cardEditSuggestion.prescribe(packId, deckId, cardId).then(suggestionMetadata => {
      this.setState({
        isLoading: false,
        isModalOpen: true,
        suggestionMetadata: suggestionMetadata,
      });
    });
  }
}

export default SuggestEditModalSetController;
