
import PillButton             from '_views/shared/PillButton';
import PropTypes              from 'prop-types';
import React                  from 'react';

import {toClassStr}           from '_utils/UiHelper';

const PT = {
  addClasses:                 PropTypes.string,
  onOptionSelected:           PropTypes.func,
  options:                    PropTypes.array,
  selectedOptionId:           PropTypes.node,
};


class LabeledToggleButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['labeled-toggle-button', this.props.addClasses]);
    const optionButtons = this.props.options.map(option => {
      return this.renderOptionButton(option);
    });

    return (
      <div className={classes}>
        {optionButtons}
      </div>
    );
  }

  renderOptionButton(option) {
    const selectedOptionId = this.props.selectedOptionId || this.props.options[0].id;
    const isSelectedClass = (option.id == selectedOptionId) ? 'is-selected' : '';
    const classes = toClassStr(['pill-button-small option-button', isSelectedClass]);

    return (
      <PillButton
        addClasses={classes}
        key={`option-${option.id}`}
        label={option.label}
        onClick={() => this.handleOptionClick(option.id)}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick = (optionId) => {
    this.props.onOptionSelected(optionId);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

LabeledToggleButton.propTypes = PT;

export default LabeledToggleButton;
