
import PropTypes                from 'prop-types';
import React                    from 'react';
import UiHelper                 from '_utils/UiHelper';

import {
  EditButton,
  ForwardButton,
} from '_views/shared/IconButton';

import { toClassStr } from '_utils/UiHelper';

const PT = {
  addClasses:           PropTypes.string,
  currentUser:          PropTypes.object,
  isLoadingUserInfo:    PropTypes.bool,
};

const MENU_OPTIONS = [
  {
    id: 'profile-info', 
    label: 'profile info',
  },
  {
    id: 'account-settings', 
    label: 'account settings', 
  },
  {
    id: 'view-profile', 
    label: 'view public profile', 
  },
];

class MobileAccountMenu extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const listItems = MENU_OPTIONS.map(({id, label}) => {
      const isSelectedClass = (this.props.currentDetailId == id) ? 'is-selected' : '';
      const classes = toClassStr(['list-item', isSelectedClass]);

      return (
        <li 
          className={classes}
          key={id} 
          onClick={() => this.handleScreenChangeRequest(id)} 
          >
          {label}
          <ForwardButton 
            onClick={() => this.handleScreenChangeRequest(id)} 
          />
        </li>
      );
    });

    return (
      <div className="mobile-screen-container mobile-account-menu">
        <h4 className="section-heading">Account overview</h4>
        <ul className='list-items'
        >{listItems}</ul>
      </div>
    );
  }

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleScreenChangeRequest = (detailId) => {
    if (detailId == 'view-profile') {
      UiHelper.openInNewTab(`/profiles/${this.props.currentUser.profile.profileId}`, 'profile');
      return true;
    }

    this.props.onDetailChangeRequest(detailId);
  }

}

MobileAccountMenu.propTypes = PT;

export default MobileAccountMenu;
