
import EventManager     from '@brainscape/event-manager';
import React            from 'react';
import CreatePackModal  from '_views/modals/CreatePackModal';

import pack             from '_models/pack';

class CreatePackModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isProcessing: false,
      modalProps: null,
    };

    /* modalProps (all optional):
      addClasses,
      isOpen,
      onCloseRequest,
      onClosed,
      onOpened,
    */

    this.events = new EventManager();
  }

  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener('create-pack-modal:open', this.handleOpenRequest);
    this.events.addListener('create-pack-modal:close', this.handleCloseRequest);
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!this.state.modalProps) {
      return null;
    }

    return (
      <CreatePackModal
        isOpen={this.state.isModalOpen}
        isProcessing={this.state.isProcessing}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        onFormSubmit={this.handleFormSubmit}
        onOpened={this.handleOpened}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClosed = () => {
    this.setState({
      modalProps: null
    }, () => {
      if (this.state.modalProps?.onClosed) {
        this.state.modalProps.onClosed();
      }
    });
  }

  handleCloseRequest = () => {
    this.publishPackCreateDismissed(); // supports FTUE

    this.setState({
      isModalOpen: false,
      isProcessing: false,
    }, () => {
      if (this.state.modalProps?.onCloseRequest) {
        this.state.modalProps.onCloseRequest();
      }
    });
  }

  handleFormSubmit = (formData) => {
    this.setState({
      isProcessing: true,
    });

    const packData = {
      pack: {
        name: formData,
      },
    };

    pack.create(packData).then(() => {
      this.setState({
        isModalOpen: false,
        isProcessing: false,
      }, () => {
        if (this.state.modalProps?.onResolution) {
          this.state.modalProps.onResolution();
        }
      });
    }).catch(err => {
      console.log('Something went wrong in CreatePackModalController.handleFormSubmit. err, formData:', err, formData);
    });
  }

  handleOpened = (data) => {
    if (this.state.modalProps?.onOpened) {
      this.state.modalProps.onOpened();
    }
  }

  handleOpenRequest = (data) => {
    this.publishPackCreateRequested(); // supports FTUE

    this.setState({
      isModalOpen: true,
      isProcessing: false,
      modalProps: data
    }, () => {
      if (this.state.modalProps?.onOpenRequest) {
        this.state.modalProps.onOpenRequest();
      }
    });
  }



  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishPackCreateDismissed = () => {
    EventManager.emitEvent('pack:create-dismissed', {});
  }

  publishPackCreateRequested = () => {
    EventManager.emitEvent('pack:create-requested', {});
  }
}

export default CreatePackModalController;
