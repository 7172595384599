
import PropTypes              from 'prop-types';
import React                  from 'react';

import {toClassStr}           from '_utils/UiHelper';

const PT = {
  addClasses:                 PropTypes.string,
};


class StudyScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const classes = toClassStr(['mobile-screen pack-detail-screen', this.props.addClasses]);

    return (
      <div className={classes}>
        Study Screen!
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

StudyScreen.propTypes = PT;

export default StudyScreen;
