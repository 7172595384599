
import EventManager           from '@brainscape/event-manager';
import SessionStorageHelper   from '_utils/SessionStorageHelper';

import deckCard               from '_models/deckCard';
import packDeck               from '_models/packDeck';


const currentDeckCardsConcern = {
  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch(packId, deckId, cardId, hasCachedData) {
    return new Promise((resolve, reject) => {
      try {
        deckCard.index(packId, deckId).then(cardsData => {
          // we have the fully paginated set of cards when the promise resolves

          const currentDeckCards = cardsData.cards;
          const currentDeckCardIds = cardsData.cardIds;

          let currentCard = null;
          let currentCardIndex = null;

          if (currentDeckCardIds.length > 0) {
            currentCardIndex = (cardId) ? currentDeckCardIds.indexOf(cardId) : 0;
            currentCardIndex = Math.max(currentCardIndex, 0);
            currentCard = currentDeckCards[currentCardIndex];
          }

          const currentDeckCardsData = {
            cardIds: currentDeckCardIds,
            cards: currentDeckCards,
            currentCardId: currentCard?.cardId || null,
            currentDeckId: deckId,
            isFromCache: false,
            deckId: deckId,
            packId: packId,
          };

          deckCard.setCachedIndex(packId, deckId, currentDeckCards);

          this.publishDeckCardsRetrieved(currentDeckCardsData);

          resolve(currentDeckCardsData);
        }).catch(err => {
          console.error('Problem fetching deck/card data. packId, deckId, cardId, err:', packId, deckId, cardId, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  // get checks session store for cached objects, publishes immediately to allow display, then refreshes cached copy by conducting fetches against the API
  get(packId, deckId, cardId) {
    return new Promise((resolve, reject) => {
      try {
        const currentDeckCards = deckCard.getCachedIndex(packId, deckId);

        if (currentDeckCards) {

          const currentDeckCardIds = currentDeckCards.map(card => card.cardId);

          let currentCard = null;
          let currentCardIndex = null;

          if (currentDeckCardIds.length > 0) {
            currentCardIndex = (cardId) ? currentDeckCardIds.indexOf(cardId) : 0;
            currentCardIndex = Math.max(currentCardIndex, 0);
            currentCard = currentDeckCards[currentCardIndex];
          }

          const currentDeckCardsData = {
            cardIds: currentDeckCardIds,
            cards: currentDeckCards,
            currentCardId: currentCard?.cardId || null,
            currentDeckId: deckId,
            isFromCache: true,
            deckId: deckId,
            packId: packId,
          };

          this.publishDeckCardsRetrieved(currentDeckCardsData);
        }

        this.fetch(packId, deckId, cardId).then(currentDeckData => {
          resolve(currentDeckData);
        }).catch(err => {
          console.error('Problem getting deck/card data. packId, deckId, cardId, err:', packId, deckId, cardId, err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  handleCardClipboardCardsPasted(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;
    updatedDeck.cards = eventData.cards;
    updatedDeck.cardIds = eventData.cardIds;

    const cardCount = updatedDeck.cardIds.length;
    const currentCardId = updatedDeck.cardIds[cardCount - 1];

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      cardClipboard: null,
      currentCardId: currentCardId,
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleCardConfidenceUpdated(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;
    const updatedCardId = eventData.cardId;

    updatedDeck.stats = {...updatedDeck.stats, ...eventData.deckStats};

    const updatedCardIndex = updatedDeck.cardIds.indexOf(eventData.cardId);

    if (updatedCardIndex != -1) {
      updatedDeck.cards[updatedCardIndex].stats.level = eventData.level;
    }

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);

    return {
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    }
  },

  handleCardCreated(packId, currentDeck, currentCardId, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;

    const newCard = eventData.card;

    const updatedDeckCards = [...updatedDeck.cards];
    const updatedDeckCardIds = [...updatedDeck.cardIds];

    const newCardIndex = updatedDeckCardIds.length;

    updatedDeckCards[newCardIndex] = newCard;
    updatedDeckCardIds[newCardIndex] = newCard.cardId;
    updatedDeck.cards = updatedDeckCards;
    updatedDeck.cardIds = updatedDeckCardIds;
    updatedDeck.stats = {...updatedDeck.stats, ...eventData.deckStats};

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentCardId: newCard.cardId,
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleCardDuplicated(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;
    let updatedDeckCards = [...updatedDeck.cards];
    const {duplicatedCard, position} = eventData;

    updatedDeckCards.splice(position, 0, duplicatedCard);

    updatedDeck.cards = updatedDeckCards;
    updatedDeck.cardIds = updatedDeckCards.map(card => card.cardId);

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentCardId: duplicatedCard.cardId,
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleCardInserted(packId, currentDeck, currentCardId, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;

    const newCard = eventData.card;

    const updatedDeckCards = [...updatedDeck.cards];
    const updatedDeckCardIds = eventData.deckCardIds;
    const insertedCardIndex = updatedDeckCardIds.indexOf(newCard.cardId);
    const newCardIndex = (insertedCardIndex != -1) ? insertedCardIndex : updatedDeckCardIds.length;

    updatedDeckCards.splice(newCardIndex, 0, newCard);

    updatedDeck.cards = updatedDeckCards;
    updatedDeck.cardIds = updatedDeckCardIds;
    updatedDeck.stats = {...updatedDeck.stats, ...eventData.deckStats};

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentCardId: newCard.cardId,
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleCardRemoved(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;
    const removedCardId = eventData.cardId;

    const updatedDeckCards = [...updatedDeck.cards];
    const removedCardIndex = updatedDeck.cardIds.indexOf(removedCardId);

    if (removedCardIndex == -1) {
      return false;
    }

    updatedDeck.cardIds.splice(removedCardIndex, 1);
    updatedDeckCards.splice(removedCardIndex, 1);
    updatedDeck.cards = updatedDeckCards;
    updatedDeck.stats = {...updatedDeck.stats, ...eventData.deckStats};

    const newCurrentCardIndex = Math.max(removedCardIndex - 1, 0);
    const newCurrentCardId = updatedDeck.cardIds[newCurrentCardIndex];

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentCardId: newCurrentCardId,
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleCardReversalJobCompleted(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;

    updatedDeck.cards = eventData.cards;
    updatedDeck.cardIds = eventData.cardIds;

    const cardCount = updatedDeck.cardIds.length;
    const currentCardId = updatedDeck.cardIds[cardCount - 1];

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentCardId: currentCardId,
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleCardUpdated(packId, currentDeck, eventData) {    
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;

    const updatedCard = eventData.card;
    const updatedDeckCards = [...updatedDeck.cards];
    const updatedCardIndex = updatedDeck.cardIds.indexOf(updatedCard.cardId);

    if (updatedCardIndex == -1) {
      return false;
    }

    updatedDeckCards[updatedCardIndex] = updatedCard;
    updatedDeck.cards = updatedDeckCards;
    updatedDeck.stats.cardCount = updatedDeckCards.length;

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleDeckCardsRemoved(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;
    const removedCardIds = eventData.cardIds;

    const currentDeckCards = [...currentDeck.cards];
    const currentDeckCardIds = [...currentDeck.cardIds];

    const updatedDeckCards = [];
    const updatedDeckCardIds = [];

    currentDeckCardIds.forEach((cardId, index) => {
      if (removedCardIds.indexOf(cardId) == -1) {
        updatedDeckCards.push(currentDeckCards[index]);
        updatedDeckCardIds.push(currentDeckCardIds[index]);
      }
    });

    updatedDeck.cards = updatedDeckCards;
    updatedDeck.cardIds = updatedDeckCardIds;
    updatedDeck.stats.cardCount = updatedDeckCards.length;

    const newCurrentCardId = updatedDeck.cardIds[0];

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentCardId: newCurrentCardId,
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleDeckCardsReordered(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;

    updatedDeck.cards = eventData.cards;
    updatedDeck.cardIds = eventData.cardIds;

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  handleDeckCardsReorderRequest(packId, currentDeck, eventData) {
    const updatedDeck = {...currentDeck};
    const updatedDeckId = updatedDeck.deckId;

    const updatedDeckCards = [...updatedDeck.cards];
    const updatedDeckCardIds = [...updatedDeck.cardIds];

    const reorderedCardIds = eventData.reorderedCardIds;

    updatedDeckCards.sort((a, b) => reorderedCardIds.indexOf(a.cardId) - reorderedCardIds.indexOf(b.cardId));
    updatedDeckCardIds.sort((a, b) => reorderedCardIds.indexOf(a) - reorderedCardIds.indexOf(b));

    updatedDeck.cards = updatedDeckCards;
    updatedDeck.cardIds = updatedDeckCardIds;

    packDeck.setCachedItem(packId, updatedDeckId, updatedDeck);
    deckCard.setCachedIndex(packId, updatedDeckId, updatedDeck.cards);

    return {
      currentDeck: updatedDeck,
      currentDeckId: updatedDeckId,
    };
  },

  publishDeckCardsRetrieved(currentDeckCardsData) {
    EventManager.emitEvent('deck-cards:retrieved', currentDeckCardsData);
  },
};

export default currentDeckCardsConcern;













