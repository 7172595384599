
import Bubble     from '_views/shared/Bubble';
import PillButton from '_views/shared/PillButton';
import React      from 'react';

import {toClassStr} from '_utils/UiHelper';

class BubbleDialog extends React.Component {

  /*
    this.props:
      addClasses,
      cancelText,
      contractionDelay,
      corner,
      expansionDelay,
      message,
      onCancellation,
      onClose,
      onResolution,
      resolveText,
      size,
      shouldAutoContract,
      shouldClose,
      shouldContract,
      shouldExpand,
      shouldPulseTitle,
      title,
  */


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['bubble-dialog', this.props.addClasses]);

    return (
      <Bubble
        addClasses={classes}
        contractionDelay={this.props.contractionDelay}
        corner={this.props.corner}
        expansionDelay={this.props.expansionDelay}
        shouldAutoContract={this.props.shouldAutoContract}
        shouldClose={this.props.shouldClose}
        shouldContract={this.props.shouldContract}
        shouldExpand={this.props.shouldExpand}
        onClose={this.handleClose}
        size={this.props.size}
      >
        <div className="dialog-content">
          {this.renderBubbleTitle()}
          {this.renderBubbleMessage()}
          <div className="bubble-actions">
            {this.renderResolveBubbleButton()}
            {this.renderCancelBubbleButton()}
          </div>
        </div>
      </Bubble>
    );
  }

  renderBubbleTitle() {
    if (!this.props.title) {
      return null;
    }

    const pulseTitle = this.props.shouldPulseTitle ? 'pulse' : '';
    const classes = toClassStr(['bubble-title', pulseTitle]);

    return (
      <h4 className={classes}>{this.props.title}</h4>
    );
  }

  renderBubbleMessage() {
    if (!this.props.message) {
      return null;
    }

    return (
      <div className="bubble-message">{this.props.message}</div>
    );
  }

  renderResolveBubbleButton() {
    if (!this.props.resolveText) {
      return null;
    }

    return (
      <PillButton
        label={this.props.resolveText}
        onClick={this.props.handleResolveClick}
      />
    );
  }

  renderCancelBubbleButton() {
    if (!this.props.cancelText) {
      return null;
    }

    return (
      <PillButton
        label={this.props.cancelext}
        onClick={this.props.handleCancelClick}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelClick = () => {
    if (this.props.onCancellation) {
      this.props.onCancellation();
    } else {
      this.handleClose();
    }
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }

    if (this.props.onClosed) {
      this.props.onClosed();
    }
  }

  handleResolveClick = () => {
    if (this.props.onResolution) {
      this.props.onResolution();
    } else {
      this.handleClose();
    }
  }
}

export default BubbleDialog;
