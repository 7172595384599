
import model    from '_core/newModel';
import GaHelper from '_utils/GaHelper';

const cardConfidence = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  set(packId, deckId, cardId, level) {

    gtag('event', 'prerate_evt', {  
      value: level,
      send_to: GaHelper.ga_4_id(),
    });

    const path = `me/user_cards/${cardId}/confidences`;
    const data = {
      packId: packId,
      deckId: deckId,
      level: level,
    };

    return model.set(path, data).then(statsData => model.publish('card-confidence:updated', statsData));
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default cardConfidence;
