
import EventManager                     from '@brainscape/event-manager';
import FileDropZoneField                from '_views/shared/FileDropZoneField';
import PillButton                       from '_views/shared/PillButton';
import React, { useState, useEffect }   from 'react';
import SimpleTextButton                 from '_views/shared/SimpleTextButton';

const HELP_ARTICLE_URL = 'https://brainscape.zendesk.com/hc/en-us/articles/115002369931-How-do-I-import-a-csv-file-correctly';

const IMPORTABLE_FILE_TYPES = ['.csv', '.txt', '.xlsx', '.ods'];
const IMPORTABLE_FILE_TYPES_STRING = IMPORTABLE_FILE_TYPES.join(', ');

const UploadFilePanel = ({isProcessing, hasInvalidInput, errorMessage}) => {

  /*
  ==================================================
   HOOKS 
  ==================================================
  */

  const [inputFile, setInputFile] = useState(null);

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleBackButtonClick = () => {
    triggerGoBackRequest();
  }

  const handleFileSelected = (filePointer) => {
    if (filePointer) {
      setInputFile(filePointer);
      publishFileSelected();
    }
  };

  const handleFileSelectionError = (err) => {
    setInputFile(null);
    publishFileSelectionError(err.message);
  }

  const handleUploadFileFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // check to make sure the text is not null and then process text import
    if (validateFileInput()) {
      triggerInputFileSubmit();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerGoBackRequest = () => {
    EventManager.emitEvent('upload-file-panel:go-back-request', {});
  }

  const triggerInputFileSubmit = () => {
    EventManager.emitEvent('upload-file-panel:input-file-submit', {
      inputFile: inputFile,
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  const publishFileSelected = () => {
    EventManager.emitEvent('upload-file-panel:file-selected', {});
  }

  const publishFileSelectionError = (errorMessage) => {
    EventManager.emitEvent('upload-file-panel:file-selection-error', {
      errorMessage: errorMessage,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  const validateFileInput = () => { 
    return true; 
  };


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderErrorMessage = () => {
    if (!errorMessage) return null;
    return <div className='error-message'>{errorMessage}</div>;
  };

  const renderFieldRightCaption = () => {
    return (
      <div className="right-caption">
        <i className="ion-ios-lightbulb"></i>
        <span className="caption-tip">Max. 500 rows at a time.</span>
        <a className="caption-link" href={HELP_ARTICLE_URL} target='_blank' rel="nofollow noopener">Learn more.</a>
      </div>
    )
  }


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  return (
    <div className="upload-file-panel">
      <div className='modal-title'>Upload File</div>

      <form className='import-deck-form' onSubmit={handleUploadFileFormSubmit}>
        <FileDropZoneField
          accepts={IMPORTABLE_FILE_TYPES}
          addClasses='import-csv-drop-zone'
          onFileSelectionError={handleFileSelectionError}
          onFileSelected={handleFileSelected}
          leftCaption={IMPORTABLE_FILE_TYPES_STRING}
          prompt='Drop a file here or click to browse'
          rightCaption={renderFieldRightCaption()}
          shouldSuppressErrorDisplay={true}
        />
      </form>

      {hasInvalidInput && renderErrorMessage()}
  
      <div className='modal-actions'>
        <SimpleTextButton
          addClasses='cancel-modal-text-button'
          label='Back'
          onClick={handleBackButtonClick}
        />
  
        <PillButton
          addClasses='resolve-modal-button'
          isDisabled={!inputFile}
          isProcessing={isProcessing}
          label='Analyze'
          onClick={handleUploadFileFormSubmit}
        />
      </div>
    </div>
  )
}

export default UploadFilePanel;
