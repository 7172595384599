
import CancelSubscriptionModal          from '_account/CancelSubscriptionModal';
import dateFormat                       from "dateformat";
import DynamicTooltipIcon               from '_views/shared/DynamicTooltipIcon';
import EventManager                     from '@brainscape/event-manager';
import PillButton                       from '_views/shared/PillButton';
import PropTypes                        from 'prop-types';
import React                            from 'react';
import SimpleTextButton                 from '_views/shared/SimpleTextButton';
import StripeCheckoutWidget             from '_account/StripeCheckoutWidget'

import { toClassStr }                   from '_utils/UiHelper';


const PT = {
  authenticityToken:                  PropTypes.string,
  currentUser:                        PropTypes.object,
  isMobileViewportSize:               PropTypes.bool,
  stripeKey:                          PropTypes.string,
};

class SubscriptionSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      isCancellationError: false,
      isCancellationSuccess: false,
      isForgotModalOpen: false,
      shouldShowCancelModal: false,
      shouldShowCancellationErrorModal: false,
    };

    this._isMounted = false;


    const subscription = props.currentUser.profile.subscription;

    if (subscription) {
      this.subscriptionObject = {
        subscription: subscription,
        gateway: subscription.gateway,
        canceledAt: subscription.canceledAt,
        billingId: subscription.billingId,
        name: subscription.name,
        startedAt: subscription.startedAt,
        stoppedAt: subscription.stoppedAt,
        realStoppedAt: subscription.realStoppedAt,
        nextRenewal: subscription.nextRenewal,
        isLifetime: subscription.name === "Lifetime",
      };
    }
  
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const planAlias = this.getPlanAlias();

    return (
      <div className='subscription-details'>
        {this.renderPlanName(planAlias)}
        {this.renderStartDate(planAlias)}
        {this.renderEndDate(planAlias)}
        {this.renderRenewalDate(planAlias)}
        {this.renderActionButtons(planAlias)}
      </div>
    );
  }

  renderPlanName(planAlias) {
    const name = this.getPlanDetails(planAlias).planName;

    if (name) {
      return (
        <div className="detail">
          <p className="detail-label">Plan name: </p>
          <span>{name}</span>
        </div>
      )
    }
  }

  renderStartDate(planAlias) {
    let startDate = this.getPlanDetails(planAlias).planStartDate;

    if (startDate) {
      return (
        <div className="detail">
          <p className="detail-label">Start date: </p>
          <span>{startDate}</span>
        </div>
      )
    }
  }

  renderEndDate(planAlias) {
    let endDate = this.getPlanDetails(planAlias).planEndDate;

    if (endDate) {
      return (
        <div className="detail">
          <p className="detail-label">End date: </p>
          <span>{endDate}</span>
          {this.renderGracePeriodTooltip()}
        </div>
      )
    }
  }

  renderRenewalDate(planAlias) {
    let renewalDate = this.getPlanDetails(planAlias).planRenewalDate;

    if (renewalDate) {
      return (
        <div className="detail">
          <p className="detail-label">Renewal date: </p>
          <span>{renewalDate}</span>
        </div>
      )
    }
  }

  renderActionButtons(planAlias) {

    if (this.subscriptionObject) {

      if (this.subscriptionObject.canceledAt) {
        return (
          <>
            <div className='subscription-actions'>
              <div className='text-blurb'>Your Pro subscription was canceled on <span>{this.convertToLongDate(this.subscriptionObject.canceledAt)}</span>. However, you'll continue to enjoy the benefits of the Pro plan until your subscription expires.</div>
            </div>
          </>
        )
      }
  
      if (this.subscriptionObject.gateway == "Apple" || this.subscriptionObject.gateway  == "Google") {
        return (
          <>
            <div className='subscription-actions'>
              <div className='text-blurb'>As a Pro user who made a purchase through the Brainscape app on your mobile device, any management or editing of your purchase must be done through Google or Apple on your mobile device.</div>
            </div>
          </>
        )
      }

      if (planAlias === "paidProAccess") {
        return (
          <>
            <div className='subscription-actions'>
              <PillButton
                addClasses="pill-button-emphasized pill-button-xsmall update"
                label="Update plan"
                onClick={this.handleUpdatePlanButtonClick}
              />
  
              {this.renderUpdatePaymentDetailsButton()}
  
              <SimpleTextButton
                addClasses="cancel-plan-button"
                label="Cancel plan"
                onClick={this.handleCancelPlanClick}
              />
              {this.renderCancelModal()}
            </div>
          </>
        )
      } 

    }

    if (planAlias === "free") {
      return (
        <>
          <div className='subscription-actions'>
            <PillButton
              addClasses="pill-button-emphasized pill-button-xsmall update"
              label="Upgrade to pro"
              onClick={this.handleUpdatePlanButtonClick}
            />
          </div>
        </>
      )
    } 

    if (planAlias === "lifetime") {
      return (
        <>
          <div className='subscription-actions'>
            <SimpleTextButton
              addClasses="cancel-plan-button"
              label="Cancel plan"
              onClick={this.handleCancelPlanClick}
            />
            {this.renderCancelModal()}
          </div>
        </>
      )
    } 

    return (
      <div className='subscription-actions'>
        <PillButton
          addClasses="pill-button-emphasized pill-button-xsmall update"
          label="Update plan"
          onClick={this.handleUpdatePlanButtonClick}
        />

        <SimpleTextButton
          addClasses="cancel-plan-button"
          label="Cancel plan"
          onClick={this.handleCancelPlanClick}
        />
        {this.renderCancelModal()}
      </div>
    )
  }

  renderCancelModal() {
    if (!this.state.shouldShowCancelModal) {
      return null;
    }

    return (
      <CancelSubscriptionModal 
        authenticityToken={this.props.authenticityToken}
        show={this.state.shouldShowCancelModal}
        onClose={() => this.handleCloseModals()}
        onError={() => this.handleCancellationError()}
        onSuccess={() => this.handleCancellationSuccess()}
        subscriptionBillingId={this.props.currentUser.profile.subscription.billingId}
      />
    );
  }

  renderCancellationErrorModal() {
    if (!this.state.shouldShowCancellationErrorModal) {
      return null;
    }
    return (
      <CancelSubscriptionModal 
        show={this.state.shouldShowCancellationErrorModal}
        onClose={() => this.handleCloseModals()}
        initialPhase={3}
      />
    );
  }

  renderGracePeriodTooltip() {

    if (this.isWithinGracePeriod()) {
      return (
        <DynamicTooltipIcon
          addClasses="subscription-info-icon"
          body="The old subscription period may show if you're in the grace period. New data will appear within days after renewing."
          delay={500}
          hasDismissButton={false}
          heading="Grace period"
          iconType="help"
          position="top"
          isMobileViewportSize={this.props.isMobileViewportSize}
        />
      )
    }
  }

  renderUpdatePaymentDetailsButton() {

    if (!this.subscriptionObject.gateway) {
      return null;
    }

    if (this.subscriptionObject.gateway != 'Stripe') {
      return null;
    }

    const classes = toClassStr(['row-item payment-method stripe-payment-method',]);

    return (
      <li className={classes}>
        <StripeCheckoutWidget
          authenticityToken={this.props.authenticityToken}
          buttonLabel="Update Payment Details"
          isButton={true}
          // onError={() => this.handleUpdateError()}
          // onSuccess={() => this.handleUpdateSuccess()}
          stripeKey={this.props.stripeKey}
          subscriptionBillingId={this.subscriptionObject.billingId}
          userEmail={this.props.currentUser.profile.email}
        />
      </li>
    )
  }

  renderDiscardButton() {
    if (!this.props.isEditingAccountSettings) {
      return null;
    }

    return (
      <SimpleTextButton
        addClasses="discard-button"
        label="Discard Changes"
        onClick={this.props.onResetFormRequest}
      />
    );
  }


  /*
  ==================================================
   HANDLERS
  ==================================================
  */

  handleCancelPlanClick = () => {
    this.setState({
      shouldShowCancelModal: true,
    })
  }

  handleUpdatePlanButtonClick = () => {
    this.triggerUpgradeModalOpen();
  }

  handleCloseModals() {
    this.setState({
      shouldShowCancelModal: false,
      shouldShowCancellationErrorModal: false
    });
  }

  handleCancellationError() {
    this.setState({
      isCancellationError: true
    });
  }

  handleCancellationSuccess() {
    this.setState({
      isCancellationSuccess: true
    });
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerUpgradeModalOpen = () => {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: "update plan",
      paywall:       "account_settings_update_plan",
    });
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  convertToLongDate = (date) => {
    return date ? dateFormat(date, "mmmm dd, yyyy") : '';
  }

  getPlanAlias = () => {
    if (!this.subscriptionObject) {
      return "free";
    }
    
    if (this.subscriptionObject.billingId === null) {
      if (this.subscriptionObject.isLifetime) {
        return "lifetime";
      }
      if (this.subscriptionObject.name === "Free Trial") {
        return "freeProTrial";
      }
      return "freeProAccessCode";
    }
    
    return "paidProAccess";
  }

  getPlanDetails = (planAlias) => {
    const planName = this.subscriptionObject ? this.subscriptionObject.name : '';
    const canceledAt = this.subscriptionObject ? this.subscriptionObject.canceledAt : '';
    const startedAt = this.subscriptionObject ? this.convertToLongDate(this.subscriptionObject.startedAt) : '';
    const stoppedAt = this.subscriptionObject ? this.convertToLongDate(this.subscriptionObject.realStoppedAt) : '';
    const nextRenewal = this.subscriptionObject ? this.convertToLongDate(this.subscriptionObject.nextRenewal) : '';

    let planDetails = {
      "free": {
        id: 1,
        planName: "You do not currently have a Brainscape Pro account. Let's get you started!",
      },
      "freeProTrial": {
        id: 2,
        planName: "You have been granted a free Pro Trial account by Brainscape.",
        planStartDate: startedAt,
        planEndDate: stoppedAt,
      },
      "freeProAccessCode": {
        id: 3,
        planName: "You have been granted a free Pro account for a " + planName,
        planStartDate: startedAt,
        planEndDate: stoppedAt,
      },
      "paidProAccess": {
        id: 4,
        planName: planName,
        planStartDate: startedAt,
        planRenewalDate: !canceledAt ? nextRenewal : "Pro subscription canceled",
        planEndDate: stoppedAt,
      },
      "lifetime": {
        id: 5,
        planName: `You have ${planName} access`,
      }
    }

    if (planDetails.hasOwnProperty(planAlias)) {
      const matchingPlan = planDetails[planAlias];
      return matchingPlan;
    } else {
      console.log(`Key ${planAlias} not found in the object.`);
    }
  }

  isWithinGracePeriod = () => {
    const currentDate = new Date();
    const formattedCurrentDate = this.convertToLongDate(currentDate);
    const formattedStoppedAtDate = this.convertToLongDate(this.subscriptionObject.stoppedAt);
    const formattedRealStoppedAtDate = this.convertToLongDate(this.subscriptionObject.realStoppedAt);

    return formattedCurrentDate >= formattedRealStoppedAtDate && formattedCurrentDate <= formattedStoppedAtDate;
  }
}

SubscriptionSection.propTypes = PT;

export default SubscriptionSection;
