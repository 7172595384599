
import model from '_core/newModel';

const cardAttachmentJob = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  create(packId, deckId, cardId, atchData) {
    const path = `packs/${packId}/decks/${deckId}/cards/${cardId}/atchs`;

    return new Promise((resolve, reject) => {
      try {
        model.create(path, atchData).then(jobData => {
          model.publish('card-attachment:job-created', jobData);
          resolve(jobData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default cardAttachmentJob;
