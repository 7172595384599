
import model from '_core/newModel';

const BASE_PATH = 'me/profile';

const KEY_MAP = {
  firstName:              {serverKey: 'first_name', parentKey: 'user'},
  lastName:               {serverKey: 'last_name', parentKey: 'user'},
  email:                  {serverKey: 'email', parentKey: 'user'},
  avatarUrl:              {serverKey: 'avatar', parentKey: 'profile_attributes'},
  bio:                    {serverKey: 'bio', parentKey: 'profile_attributes'},
  schoolName:             {serverKey: 'school_name', parentKey: 'profile_attributes'},
  companyName:            {serverKey: 'company_name', parentKey: 'profile_attributes'},
}

const userProfile = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  show() {
    return model.show(BASE_PATH);
  },

  update(data) {
    const reqData = this.mapInputData(data);
    return model.update(BASE_PATH, reqData);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  mapInputData(data) {
    const clientKeys = Object.keys(data);
    const user = {};
    const profile_attributes = {};

    clientKeys.forEach(key => {
      const field = KEY_MAP[key];

      if (field) {
        switch (field.parentKey) {
          case 'user':
            user[KEY_MAP[key].serverKey] = data[key];
          break;
          case 'profile_attributes':
            profile_attributes[KEY_MAP[key].serverKey] = data[key];
          break;
        }
      }
    });

    user.profile_attributes = profile_attributes;

    return {
      user: user,
    }
  }
};

export default userProfile;
