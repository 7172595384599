
import EventManager              from '@brainscape/event-manager';
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import RoundCheckbox             from '_views/shared/RoundCheckbox';
import UiHelper                  from '_utils/UiHelper';

import {apiPost, apiError}       from '_core/Api1';
import {toClassStr}              from '_utils/UiHelper';

const PT = {  
  addClasses:               PropTypes.string,
  isOpen:                   PropTypes.bool,
  onCloseRequest:           PropTypes.func,
  suggestedPacks:           PropTypes.object,
};

class PackSuggestionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callback: null,
      isProcessing: false,
      packSelections: {},
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.setupPackSelections();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.suggestedPacks && this.props.suggestedPacks) {
      this.setupPackSelections();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!(this.props && this.props.suggestedPacks)) {
      return null;
    }

    return (
      <Modal
        addClasses="pack-suggestions-modal"
        isOpen={this.props.isOpen}
        onClosed={this.handleClosed}
        onCloseRequest={this.props.onCloseRequest}
      >
        <div className="modal-title">Add some popular classes</div>

        <div className="modal-guidance"> Check one or more classes to add to your library</div>

        {this.renderSuggestedPacks()}

        <div className="modal-actions">

          <div className="button-and-descriptor">
            <PillButton
              addClasses="info-modal-button search-market-button"
              label="Search full market"
              onClick={() => this.handleSearchFullMarketButtonClick()}
            />
            <div className="descriptor">&nbsp;</div>
          </div>
          
          <div className="button-and-descriptor">
            <PillButton
              addClasses="resolve-modal-button add-classes-button"
              isDisabled={!this.hasPackSelection()}
              isProcessing={this.state.isProcessing}
              label="Add these classes"
              onClick={() => this.handleAddClassesButtonClick()}
            />
            {this.renderAddClassesStatus()}
          </div>
        </div>
      </Modal>
    );
  }

  renderSuggestedPacks() {
    const suggestedPacks = this.props.suggestedPacks;
    const groupIds = Object.keys(suggestedPacks);

    const packGroups = groupIds.map((groupId, index) => {
      const packGroup = suggestedPacks[groupId];
      return this.renderPackGroup(packGroup);
    });

    return (
      <div className="suggested-packs">
        {packGroups}
      </div>
    );
  }

  renderPackGroup(packGroup) {
    const packs = packGroup.packs;
    const groupId = packGroup.id;
    const groupLabel = packGroup.label;

    const groupPacks = packs.map((pack, groupId) => {
      return this.renderPack(pack);
    });

    return (
      <div className="pack-group" key={groupId}>
        <div className="group-label">{groupLabel}</div>
        {groupPacks}
      </div>
    );
  }

  renderPack(pack) {
    const packId = pack.packId;

    if (!packId) {
      return null;
    }

    return (
      <div className="suggested-pack" key={packId} onClick={(e) => this.handleSuggestedPackClick(e, packId)}>
        <RoundCheckbox 
          addClasses="suggested-pack-option"
          isChecked={this.state.packSelections[packId]}
          name="suggestedPackOption"
          onClick={() => this.handleSuggestedPackOptionClick(packId)}
          value={packId}
        />
        <img className="pack-image" src={pack.activeIconUrl} />
        <div className="pack-name">{pack.name}</div>
      </div>
    ); 
  }

  renderAddClassesStatus() {
    if (this.state.isProcessing) {
      return (
        <div className="descriptor">Adding classes to library...</div>
      );
    }

    return (
      <div className="descriptor">&nbsp;</div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddClassesButtonClick = () => {
    const packSelections = this.state.packSelections;
    const packIds = Object.keys(packSelections);

    const enrollmentPackIds = packIds.filter((packId, index) => {
      const packSelection = packSelections[packId];
      return (packSelection == true);
    })

    if (enrollmentPackIds) {
      this.setState({
        isProcessing: true,
        callback: this.refreshDashboardAndShowConfirmModal,
      }, () => {
        this.enrollInSelectedPacks(enrollmentPackIds)
      })
    }
  }

  handleClosed = () => {
    if (this.state.callback) {
      this.state.callback();
    }

    if (this.props.onClosed) {
      this.props.onClosed();
    }
  }

  handleSearchFullMarketButtonClick = () => {
    UiHelper.openInNewTab('/subjects');
    this.props.onCloseRequest();
  }

  handleSuggestedPackClick = (e, packId) => {
    if (e) {
      e.stopPropagation();
    }

    this.handleSuggestedPackOptionClick(packId);
  }

  handleSuggestedPackOptionClick = (packId) => {
    let packSelections = {...this.state.packSelections};
    packSelections[packId] = !packSelections[packId];

    this.setState({
      packSelections: packSelections,
    })
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerDashboardRefresh = () => {
    EventManager.emitEvent('refresh-pack-detail-section', {});
  }

  triggerPostEnrollmentModalOpen = () => {
    EventManager.emitEvent('info-modal:open', {
      message: "We've populated your Library dashboard with the classes you selected. You can easily remove them by clicking the 'x' by their name.",
      resolveButtonText: 'Got it',
      title: 'Great!',
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  enrollInSelectedPacks(packIds) {
    const params = {
      pack_ids:  packIds,
    };

    apiPost('subscribe', params).then((data) => {
      this.setState({
        isProcessing: false,
      });

      this.props.onCloseRequest();
    }).catch(apiError);
  }

  hasPackSelection = () => {
    if (!this.props.suggestedPacks) {
      return false;
    }

    let hasSelection = false;

    const packSelections = this.state.packSelections;
    const packIds = Object.keys(packSelections);

    for (let i=0; i <= packIds.length; i++) {
      const packId = packIds[i];

      if (packSelections[packId] == true) {
        hasSelection = true;
        break;
      }
    }

    return hasSelection;
  }

  refreshDashboardAndShowConfirmModal = () => {
    this.triggerDashboardRefresh();
    this.triggerPostEnrollmentModalOpen();
  }

  setupPackSelections = () => {
    if (!this.props.suggestedPacks) {
      return false;
    }

    const suggestedPacks = this.props.suggestedPacks;
    const groupIds = Object.keys(this.props.suggestedPacks);
    const packSelections = {};

    groupIds.forEach((groupId, index) => {
      const packGroup = suggestedPacks[groupId];

      packGroup.packs.forEach((pack, index2) => {
        packSelections[pack.packId] = false;
      });
    });

    this.setState({
      packSelections: packSelections,
    })
  }
}

PackSuggestionsModal.propTypes = PT;

export default PackSuggestionsModal;
