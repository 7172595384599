
import Bubble                    from '_views/shared/Bubble';
import BubbleDialog              from '_views/shared/BubbleDialog';
import React                     from 'react';
import Tracker                   from '_utils/Tracker';

import {toClassStr} from '_utils/UiHelper';

class FtueFindMessaging extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isClassesHelpBubbleOpen             : false,
      shouldCollapseClassesHelpBubble     : false,
      isStudyHelp1BubbleOpen              : false,
      shouldCollapseStudyHelp1Bubble      : false,
      isStudyHelp2BubbleOpen              : false,
      shouldCollapseStudyHelp2Bubble      : false,
      isDeckHelpBubbleOpen                : false,
      shouldCollapseDeckHelpBubble        : false,
      isCardHelpBubbleOpen                : false,
      shouldCollapseCardHelpBubble        : false,
    };

    /*
      this.props:
        addClasses,
        isMobileViewportSize,
        onDismissFtueRequest,
        onPulseAddCardsButtonRequest,
        onPulseCreateDeckButtonRequest,
        onPulseStudyButtonRequest,
    */

    this._isMounted = false;

    this.pulseAddCardsButtonTimeout = null;
    this.pulseCreateDeckButtonTimeout = null;
    this.pulseStudyButtonTimeout = null;

    this.PRE_PULSE_ADD_CARDS_BUTTON_DELAY = 3000;
    this.PRE_PULSE_CREATE_DECK_BUTTON_DELAY = 3000;
    this.PRE_PULSE_STUDY_BUTTON_DELAY = 3000;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.invokeFtue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.phase != this.props.phase) {
      this.managePhaseChange();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['ftue-messaging', this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderClassesHelpBubble()}
        {this.renderStudyHelp1Bubble()}
        {this.renderStudyHelp2Bubble()}
        {this.renderDeckHelpBubble()}
        {this.renderCardHelpBubble()}
      </div>
    );
  }

  renderClassesHelpBubble() {
    if (!this.state.isClassesHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';

    return (
      <BubbleDialog
        expansionDelay={1000}
        message="This is your list of Flashcard Classes. Click on any Class to begin."
        onClose={this.handleClassesHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseClassesHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Welcome!'
      />
    );
  }

  renderStudyHelp1Bubble() {
    if (!this.state.isStudyHelp1BubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';

    return (
      <BubbleDialog
        corner="bottomRight"
        contractionDelay={11500}
        expansionDelay={1000}
        message='Click the big blue "Study" button or a single deck.'
        onClose={this.handleStudyHelp1BubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseStudyHelp1Bubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title="Welcome"
      />
    );
  }

  renderStudyHelp2Bubble() {
    if (!this.state.isStudyHelp2BubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';

    return (
      <BubbleDialog
        corner="bottomRight"
        contractionDelay={11500}
        expansionDelay={1000}
        message='Click the big blue "Study" button or a single deck.'
        onClose={this.handleStudyHelp2BubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseStudyHelp2Bubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title="Next"
      />
    );
  }

  renderDeckHelpBubble() {
    if (!this.state.isDeckHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';
    const bubbleCorner = (this.props.isMobileViewportSize) ? 'topRight' : 'bottomRight';

    return (
      <BubbleDialog
        corner={bubbleCorner}
        expansionDelay={1000}
        message="To study, first create a deck of flashcards. Click the orange 'Create New Deck' button to continue."
        onClose={this.handleDeckHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseDeckHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Set Up'
      />
    );
  }

  renderCardHelpBubble() {
    if (!this.state.isCardHelpBubbleOpen) {
      return null;
    }

    const bubbleSize = this.props.isMobileViewportSize ? 'small' : 'medium';
    const bubbleCorner = (this.props.isMobileViewportSize) ? 'topRight' : 'topLeft';

    return (
      <BubbleDialog
        corner={bubbleCorner}
        expansionDelay={1000}
        message="Click the green 'Add Cards' button to add Flashcards to Study."
        onClose={this.handleCardHelpBubbleCollapse}
        size={bubbleSize}
        shouldAutoContract={false}
        shouldClose={this.state.shouldCollapseCardHelpBubble}
        shouldContract={true}
        shouldExpand={true}
        shouldPulseTitle={false}
        title='Set Up'
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleClassesHelpBubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseClassesHelpBubble) {
      // user dismissed bubble
      this.props.onDismissFtueRequest();
    }
  }
  
  handleStudyHelp1BubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseStudyHelp1Bubble) {
      // user dismissed bubble
      this.props.onDismissFtueRequest();
    }
  }

  handleStudyHelp2BubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseStudyHelp2Bubble) {
      // user dismissed bubble
      this.props.onDismissFtueRequest();
    }
  }

  handleDeckHelpBubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseDeckHelpBubble) {
      // user dismissed bubble
      this.props.onDismissFtueRequest();
    }
  }

  handleCardHelpBubbleCollapse = () => {
    // check to see if bubble collapsed due to user continuing, or dismissing
    if (!this.state.shouldCollapseCardHelpBubble) {
      // user dismissed bubble
      this.props.onDismissFtueRequest();
    }
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  pulseAddCardsButton() {
    clearTimeout(this.pulseAddCardsButtonTimeout);

    this.pulseAddCardsButtonTimeout = setTimeout(() => {
      this.props.onPulseAddCardsButtonRequest();
    }, this.PRE_PULSE_ADD_CARDS_BUTTON_DELAY);
  }

  pulseCreateDeckButton() {
    clearTimeout(this.pulseCreateDeckButtonTimeout);

    this.pulseCreateDeckButtonTimeout = setTimeout(() => {
      this.props.onPulseCreateDeckButtonRequest();
    }, this.PRE_PULSE_CREATE_DECK_BUTTON_DELAY);
  }

  pulseStudyButton() {
    clearTimeout(this.pulseStudyButtonTimeout);

    this.pulseStudyButtonTimeout = setTimeout(() => {
      this.props.onPulseStudyButtonRequest();
    }, this.PRE_PULSE_STUDY_BUTTON_DELAY);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.pulseAddCardsButtonTimeout);
    clearTimeout(this.pulseCreateDeckButtonTimeout);
    clearTimeout(this.pulseStudyButtonTimeout);
  }

  invokeFtue() {
    if (this.props.phase == 'classesHelp') {
      this.setState({
        isClassesHelpBubbleOpen: true
      }, () => {
        Tracker.trackDashboardProgress('ftue_intro_study');
      });

      return true;
    }

    if (this.props.phase == 'deckHelp') {
      this.setState({
        isDeckHelpBubbleOpen: true
      }, () => {
        Tracker.trackDashboardProgress('ftue_intro_study');
      });
      this.pulseCreateDeckButton();
      return true;
    }

    if (this.props.phase == 'cardHelp') {
      this.setState({
        isCardHelpBubbleOpen: true
      }, () => {
        Tracker.trackDashboardProgress('ftue_intro_study');
      });
      this.pulseAddCardsButton();
      return true;
    }

    this.setState({
      isStudyHelp1BubbleOpen: true
    }, () => {
      Tracker.trackDashboardProgress('ftue_intro_study');
    });
    this.pulseStudyButton();
  }

  managePhaseChange() {
    switch (this.props.phase) {
      case 'studyHelp2':
        this.setState({
          isStudyHelp2BubbleOpen: true,
          shouldCollapseClassesHelpBubble: true,
        });
        this.pulseStudyButton();
      break;
      case 'deckHelp':
        this.setState({
          isDeckHelpBubbleOpen: true,
          shouldCollapseClassesHelpBubble: true,
          shouldCollapseStudyHelp1Bubble: true,
          shouldCollapseStudyHelp2Bubble: true,
        });
        this.pulseCreateDeckButton();
      break;
      case 'createDeck':
        this.setState({
          shouldCollapseDeckHelpBubble: true,
        });
      break;
      case 'cardHelp':
        this.setState({
          isCardHelpBubbleOpen: true,
          shouldCollapseClassesHelpBubble: true,
        });
        this.pulseAddCardsButton();
      break;
    }
  }  
}

export default FtueFindMessaging;
