
import EventManager           from '@brainscape/event-manager';
import SessionStorageHelper   from '_utils/SessionStorageHelper';

import userProfile            from '_models/userProfile';
import userStat               from '_models/userStat';


const currentUserConcern = {

  // get checks session store for cached objects, publishes immediately to allow display, then refreshes cached copy by conducting fetches against the API
  get(currentUser={}) {
    return new Promise((resolve, reject) => {
      try {
        const currentUser = {...currentUser};
        const currentUserProfile = SessionStorageHelper.getItem('currentUserProfile');
        const currentUserStats = SessionStorageHelper.getItem('currentUserStats');

        if (currentUser && currentUserProfile && currentUserStats) {
          currentUser.profile = currentUserProfile;
          currentUser.stats = currentUserStats;

          const currentUserData = {
            currentUser: currentUser,
          };

          this.publish(currentUserData);
        }

        this.fetch(currentUser).then(currentUserData => {
          resolve(currentUserData);
        }).catch(err => {
          console.error('Problem getting user profile and stats data. err:', err);
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  // fetch conducts fetches against the API, caches objects in session storage, and publishes for display
  fetch(currentUser={}) {
    return new Promise((resolve, reject) => {
      try {
        userProfile.show().then(profileData => {
          const currentUser = {...currentUser};
          currentUser.profile = profileData;

          userStat.index().then(statsData => {
            currentUser.stats = statsData;

            SessionStorageHelper.setItem('currentUserProfile', currentUser.profile);
            SessionStorageHelper.setItem('currentUserStats', currentUser.stats);

            const currentUserData = {
              currentUser: currentUser,
            };

            this.publish(currentUserData);
            resolve(currentUserData);
          });
        }).catch(err => {
          console.error('Problem fetching user profile and stats data. err:', err);
        });

      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  publish(currentUserData) {
    EventManager.emitEvent('current-user:retrieved', currentUserData);
  },
};

export default currentUserConcern;
