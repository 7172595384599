
import EventManager           from '@brainscape/event-manager';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import PropTypes              from 'prop-types';
import React                  from 'react';
import StringHelper           from '_utils/StringHelper';
import Tracker                from '_utils/Tracker';

import deckCard               from '_models/deckCard';
import deckCardSorting        from '_models/deckCardSorting';
import deckCsvExportJob       from '_models/deckCsvExportJob';
import deckCsvImportJob       from '_models/deckCsvImportJob';

import {apiPost}              from '_core/Api2';
import {toClassStr}           from '_utils/UiHelper';
          

const PT = {      
  addClasses:                           PropTypes.string,
  currentUser:                          PropTypes.object,
  deck:                                 PropTypes.object, 
  iconType:                             PropTypes.string,
  isShowingBulkActions:                 PropTypes.bool,
  onChangeBulkActionsDisplayRequest:    PropTypes.func,
  pack:                                 PropTypes.object,
};

const MENU_OPTIONS_HASH = {
  sortAtoZ: {id: 'sortAtoZ', tag: 'sort-a-z', label: 'Sort A-Z'},
  sortZtoA: {id: 'sortZtoA', tag: 'sort-z-a', label: 'Sort Z-A'},
  reverse: {id: 'reverse', tag: 'reverse', label: 'Reverse Cards'},
  randomize: {id: 'randomize', tag: 'randomize', label: 'Randomize Cards'},
  purgeBlankCards: {id: 'purgeBlankCards', tag: 'purge-blank-cards', label: 'Purge Blank Cards'},
  separator1: {id: '---', label: null, notMd: true, onlyAdminOrEmployee: true},
  toMd: {id: 'toMd', tag: 'to-md', label: 'Convert to MD', notMd: true, onlyAdminOrEmployee: true},
  separator2: {id: '---', label: null, isMd: true, onlyAdminOrEmployee: true},
  exportMdCsv: {id: 'exportMdCsv', tag: 'export-md-csv', label: 'Export MD Cards CSV', onlyAdminOrEmployee: true, isMd: true},
  importMdCsv: {id: 'importMdCsv', tag: 'import-md-csv', label: 'Import MD Cards CSV', onlyAdminOrEmployee: true, isMd: true},
  separator3: {id: '---', label: null,},
  showBulkActions: {id: 'showBulkActions', tag: 'show-bulk-actions', label: 'Show Bulk Actions', isNotShowingBulkActions: true},
  hideBulkActions: {id: 'hideBulkActions', tag: 'hide-bulk-actions', label: 'Hide Bulk Actions', isShowingBulkActions: true},
};


class DeckEditSectionOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingOptionAction: false,
      menuOptions: [],
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.updateUserOptions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUser != prevProps.currentUser) {
      this.updateUserOptions();
    }

    if (this.props.deck != prevProps.deck) {
      this.updateUserOptions();
    }

    if (this.props.isBulkActionsBarShowing != prevProps.isBulkActionsBarShowing) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const classes = toClassStr(['deck-editor-options-button', this.props.addClasses]);

    return (
      <div className={classes}>
        <OptionsMenuButton
          addClasses="no-option-icons"
          iconType={this.props.iconType || "horizontal"}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.currentUser.flags.isPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={this.handleOptionClick}
          openPosition="bottomRight"
          shouldButtonTurn={false}
          tooltipContent="Organize Cards"
          tooltipPosition="top"
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick = (optionId) => {
    const requiresPro = MENU_OPTIONS_HASH[optionId].onlyPro;

    if (requiresPro && !this.props.currentUser.flags.isPro) {
      const desiredAction = MENU_OPTIONS_HASH[optionId].label;
      const paywall = MENU_OPTIONS_HASH[optionId].paywall;
      const featuresList = MENU_OPTIONS_HASH[optionId].featuresList;
      
      this.showUpgradeModal(desiredAction, paywall, featuresList);

    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleOptionActionRequest = (optionId) => {
    switch (optionId) {
      case 'sortAtoZ':
        this.performSortAction('question', 'asc', 'sort the cards in your deck alphabetically, by question');
        break;
      case 'sortZtoA':
        this.performSortAction('question', 'desc', 'sort the cards in your deck reverse-alphabetically, by question');
        break;
      case 'reverse':
        this.performSortAction('position', 'reverse', 'reverse the order of cards in your deck')
        break;
      case 'randomize':
        this.performSortAction('position', 'random', 'shuffle the cards in your deck');
        break;
      case 'purgeBlankCards':
        this.performPurgeBlankCardsAction();
        break;
      case 'importMdCsv':
        this.performImportMdCsvAction();
        break;
      case 'exportMdCsv':
        this.performExportMdCsvAction();
        break;
      case 'showBulkActions':
        this.performShowBulkActionsAction();
        break;
      case 'hideBulkActions':
        this.performHideBulkActionsAction();
        break;
    }
  }

  performExportMdCsvAction = () => {
    const packId = this.props.pack.packId;
    const deckId = this.props.deck.deckId;

    deckCsvExportJob.create(packId, deckId);
  }

  performHideBulkActionsAction = () => {
    this.props.onChangeBulkActionsDisplayRequest(false);
  }

  performImportMdCsvAction = () => {
    EventManager.emitEvent('import-cards-modal:open', {
      deck: this.props.deck,
      pack: this.props.pack,
      mode: 'admin',
    });
  }

  performPurgeBlankCardsAction = () => {
    const packId = this.props.pack.packId;
    const deckId = this.props.deck.deckId;
    const cards = this.props.deck.cards;

    const blankCardIds = [];

    cards.forEach(card => {
      if (this.isBlankCard(card)) {
        blankCardIds.push(card.cardId);
      }
    });

    if (blankCardIds?.length < 1) {
      this.triggerInfoModalOpen({
        title: 'No Blank Cards in Deck',
        message: "This deck has no blank cards to purge",
        resolveButtonText: 'Got it',
      });

      return false;
    }

    this.triggerConfirmModalOpen({
      actionText: `remove ${blankCardIds.length} card(s) from your deck (this action can not be undone)`,
      resolveButtonText: 'Yes, purge cards',
      onResolution: () => {
        deckCard.destroyMulti(packId, deckId, blankCardIds);
      },
    });
  }

  performSortAction = (key, direction, actionText) => {
    this.setState({
      isProcessingOptionAction: true,
    });

    this.triggerConfirmModalOpen({
      actionText: actionText,
      resolveButtonText: 'Yes',
      onResolution: () => {
        this.sortCards(key, direction);
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingOptionAction: false,
        });
      }
    });
  }

  performShowBulkActionsAction = () => {
    this.props.onChangeBulkActionsDisplayRequest(true);
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerUpgradeModalOpen(desiredAction, paywall, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
      featuresList:  featuresList,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  isBlankCard = (card) => {
    const isBlank = StringHelper.isBlank;

    const isQuestionBlank = isBlank(card.qMdPrompt) && isBlank(card.qMdBody) && isBlank(card.qMdClarifier) && isBlank(card.qMdFootnote) && isBlank(card.qImageUrl) && isBlank(card.qSoundUrl) && isBlank(card.question);
    const isAnswerBlank = isBlank(card.aMdPrompt) && isBlank(card.aMdBody) && isBlank(card.aMdClarifier) && isBlank(card.aMdFootnote) && isBlank(card.aImageUrl) && isBlank(card.aSoundUrl) && isBlank(card.answer);

    return (isQuestionBlank && isAnswerBlank);
  }

  sortCards = (key, direction) => {
    const packId = this.props.pack.packId;
    const deckId = this.props.deck.deckId;

    deckCardSorting.create(packId, deckId, key, direction).then((res) => {
      this.setState({
        isProcessingOptionAction: false,
      });
    });
  }

  showUpgradeModal(desiredAction, paywall, featuresList) {
    Tracker.trackPaywallWarning(paywall);
    this.triggerUpgradeModalOpen(desiredAction, paywall, featuresList);
  }

  updateUserOptions() {
    const user = this.props.currentUser;

    let menuOptionsForUser = Object.keys(MENU_OPTIONS_HASH).reduce((accumulator, menuOption) => {

      let permitPerBscAdminOrEmployeeRights = (MENU_OPTIONS_HASH[menuOption].onlyAdminOrEmployee) ? (user.flags.isBscAdmin || user.flags.isBscEmployee) : true;
      let permitPerMdContentType = (MENU_OPTIONS_HASH[menuOption].isMd) ? this.props.deck.contentType == 'md' : true;
      let permitPerNotMdContentType = (MENU_OPTIONS_HASH[menuOption].notMd) ? this.props.deck.contentType != 'md' : true;
      let permitPerBulkActionsShowing = (MENU_OPTIONS_HASH[menuOption].isShowingBulkActions) ? this.props.isBulkActionsBarShowing : true;
      let permitPerBulkActionsNotShowing = (MENU_OPTIONS_HASH[menuOption].isNotShowingBulkActions) ? !this.props.isBulkActionsBarShowing : true;

      if (permitPerBscAdminOrEmployeeRights && permitPerMdContentType && permitPerNotMdContentType && permitPerBulkActionsShowing && permitPerBulkActionsNotShowing) {
        accumulator.push(MENU_OPTIONS_HASH[menuOption]);
      }

      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}


DeckEditSectionOptionsButton.propTypes = PT;

export default DeckEditSectionOptionsButton;
