
import userLocalStore from '_models/userLocalStore';

import 'url-search-params-polyfill'; // polyfill to support IE and Edge


const StudyHelper = {

  getMasteryLevel(percentage) {
    return (percentage == 0) ? 0 : Math.floor(percentage/25) + 1
  },

  generatePackDeckList(packId, deckIds) {
    // returns url ready string of pack-deck pairings

    const packDeckArray = deckIds.reduce((result, deckId) => {
      result.push(packId + '-' + deckId);
      return result;
    }, []);

    return packDeckArray.toString();
  },

  getPackStudyMixType(userId, pack, deckIds=null) {
    return userLocalStore.getPackStudyMixType(userId, pack, deckIds);
  },

  setPackStudyMixType(userId, packId, mixType) {
    let psc = userLocalStore.getPackStudyConfigs(userId, packId);
    psc.mixType = mixType;

    userLocalStore.updateUserStorePackData(userId, packId, 'packStudyConfigs', psc);
  },

  isPackMixSet(userId, packId) {
    return userLocalStore.isPackMixSet(userId, packId);
  },

  getPackStudyMixUrl(userId, userPack, selectedDeckIds=null) {
    const params  = new URLSearchParams();

    if (!selectedDeckIds || selectedDeckIds.length == userPack.stats.deckCount) {
      params.append('classes', userPack.packId);
    } else {
      params.append(
        'classes',
        selectedDeckIds.map((deckId) => `${userPack.packId}-${deckId}`).join(','),
      );
    }

    params.append('type', this.getPackStudyMixType(userId, userPack, selectedDeckIds));

    return `/l/study?${params.toString()}`;
  },

  getPackDeckStudyMixUrl(userId, userPack, deckId) {
    const mixType = this.getPackStudyMixType(userId, userPack, [deckId]);

    return `/l/study?classes=${userPack.packId}-${deckId}&type=${mixType}`;
  },
}

export default StudyHelper;
