
import EventManager           from '@brainscape/event-manager';
import PropTypes              from 'prop-types';
import RichContentTooltip          from '_views/shared/RichContentTooltip';
import React                  from 'react';

import {
  InfoButton, 
  HelpButton,
}                             from '_views/shared/IconButton';

import {toClassStr}           from '_utils/UiHelper';

const PT = {
  actions:                    PropTypes.array,
  addClasses:                 PropTypes.string,
  delay:                      PropTypes.number,
  hasDismissButton:           PropTypes.bool,
  iconType:                   PropTypes.string,
  isMobileViewportSize:       PropTypes.bool,
  position:                   PropTypes.string,
  onClick:                    PropTypes.func,
  heading:                    PropTypes.string,
  subHeading:                 PropTypes.string,
  body:                       PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
  ]),
};


class DynamicTooltipIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    let classes;

    switch (this.props.iconType) {
      case 'help':
        classes = toClassStr(['info-tooltip-button', 'help-icon', this.props.addClasses]);

        return (
          <HelpButton
            addClasses={classes}
            onClick={() => this.handleIconClick()}
            shouldAllowClickPropagation={true}
            tooltipContent={this.renderTooltipContent()}
            tooltipDelay={this.props.delay}
            tooltipPosition={this.props.position}
          />
        );
      break;

      default:
        classes = toClassStr(['info-tooltip-button', 'info-icon', this.props.addClasses]);

        return (
          <InfoButton
            addClasses={classes}
            onClick={() => this.handleIconClick()}
            shouldAllowClickPropagation={true}
            tooltipContent={this.renderTooltipContent()}
            tooltipDelay={this.props.delay}
            tooltipPosition={this.props.position}
          />
        );
    }
  }

  renderTooltipContent = () => {
    if (this.props.isMobileViewportSize) {  // if mobile, DialogModal will be rendered on icon click
      return null;
    }

    const classes = toClassStr(['info-tooltip-content', this.props.addClasses]);

    if (this.props.isSimpleTooltip) {
      return this.props.body;
    }

    return (
      <RichContentTooltip
        actions={this.props.actions}
        addClasses={classes} 
        hasDismissButton={this.props.hasDismissButton}
        body={this.props.body}
        heading={this.props.heading}
        subHeading={this.props.subHeading}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleIconClick = () => {
    if (!this.props.isMobileViewportSize) {
      return;
    }
    this.triggerDialogModalOpen();
  }

  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerDialogModalOpen = () => {
    EventManager.emitEvent('dialog-modal:open', {
      heading: this.props.heading,
      subHeading: this.props.subHeading,
      body: this.props.body,
    })
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

DynamicTooltipIcon.propTypes = PT;

export default DynamicTooltipIcon;
