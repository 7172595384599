
// substrate and utils
import EventManager             from '@brainscape/event-manager';
import he                       from 'he';
import PropTypes                from 'prop-types';
import React                    from 'react';
import StringHelper             from '_utils/StringHelper';
import TimeHelper               from '_utils/TimeHelper';
import UiHelper                 from '_utils/UiHelper';
import {toClassStr}             from '_utils/UiHelper';

// models
import cardConfidence           from '_models/cardConfidence';
import deckCard                 from '_models/deckCard';
        
// sub-components
import CardSet                  from "_views/shared/smart-cards/animation/CardSet";
import EditorCardOptionsButton  from '_views/shared/smart-cards/EditorCardOptionsButton';
import Pulldown                 from '_views/shared/Pulldown';
import SmartCardFace            from '_views/shared/smart-cards/SmartCardFace';

import {
  DismissButton,
  EditButton,
  ReturnButton,
  SaveButton,
} from '_views/shared/IconButton';

import {RevealModes}            from "_views/shared/smart-cards/animation/Constants";


const PT = {
  addClasses:                     PropTypes.string,
  card:                           PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  cardCount:                      PropTypes.number,
  cardMode:                       PropTypes.string, // edit or display
  cardPosition:                   PropTypes.number,
  context:                        PropTypes.string, // editor, preview, browse, study 
  currentCardFace:                PropTypes.string,   // 'question' or 'answer'
  currentUser:                    PropTypes.object,
  deck:                           PropTypes.object,
  editMode:                       PropTypes.string,
  editorHasChanges:               PropTypes.bool,
  format:                         PropTypes.string,  // md, text, html
  inlineStyle:                    PropTypes.object, 
  isAtCheckpoint:                 PropTypes.bool, 
  isAudioMuted:                   PropTypes.bool, 
  isBlurred:                      PropTypes.bool,
  isCurrentCard:                  PropTypes.bool, 
  isLoadingCardDisplayData:       PropTypes.bool,
  isMobileViewportSize:           PropTypes.bool,
  isPreviewModal:                 PropTypes.bool,
  isNewCard:                      PropTypes.bool, 
  layout:                         PropTypes.string,  // single-card, list
  parentContext:                  PropTypes.string,
  onCardClick:                    PropTypes.func,
  onCardFieldChange:              PropTypes.func,
  onCardFlipRequest:              PropTypes.func,
  onOutsideClick:                 PropTypes.func,
  onViewLargeImageRequest:        PropTypes.func,
  pack:                           PropTypes.object,
  packId:                         PropTypes.node,
  rowNumber:                      PropTypes.node,
  shouldSuppressEditButton:       PropTypes.bool,
  skipDirection:                  PropTypes.string,  // back or forward (supports Browse nav)
  viewportStyle:                  PropTypes.string,  // desktop, mobile
};

const CONF_LEVEL_OPTS = [
  {id: 0, label: '--', isNullOption: true},
  {id: 1, label: '1'},
  {id: 2, label: '2'},
  {id: 3, label: '3'},
  {id: 4, label: '4'},
  {id: 5, label: '5'}
];

const LEGACY_FIELDS = [
  'aImageCaption',
  'aSoundCaption',
  'answer',
  'name',
  'prompt',
  'qImageCaption',
  'qSoundCaption',
  'question',
];

const MD_FIELDS = [
  'aMdBody',
  'aMdClarifier',
  'aMdFootnote',
  'aImageCaption',
  'qMdPrompt',
  'qMdBody',
  'qMdClarifier',
  'qImageCaption',
  'qMdFootnote',
];

const FILE_FIELDS = [
  'aImageUrl',
  'aSoundUrl',
  'qImageUrl',
  'qSoundUrl',
];

const SOURCE_FIELDS = [
  'answer',
  'question',
];


class SmartCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      card:                         this.props.card,
      cardMode:                     this.props.cardMode || 'display',
      currentCardFace:              this.props.initialCardFace || 'question',
      editMode:                     this.props.editMode || 'advanced',
      hasChanges:                   false,         
      isConfidencePulldownOpen:     false,
      isListeningToOutsideClicks:   false,
      isProcessingCardSave:         false,
      isProcessingConfidenceChange: false,
      isProcessingDeleteAction:     false,
    };

    this.elem = null;
    this._isMounted = false;

    this.events = new EventManager();
  }

  /*
  ==================================================
   LIFE CYCLE METHODS
  ==================================================
  */

  componentDidMount = () => {
    this._isMounted = true;
    this.subscribeToEvents();
    this.manageOutsideClickMonitor();

    if (this.props.isCurrentCard) {
      this.handleCurrentCardFocus();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isCurrentCard && !this.props.isCurrentCard) {
      this.handleCurrentCardBlur();
    }

    if (!prevProps.isCurrentCard && this.props.isCurrentCard) {
      this.handleCurrentCardFocus();
    }

    if (this.props.card != prevProps.card) {
      this.setState({
        card: this.props.card,
        hasChanges: false,
        isProcessingCardSave: false,
      });
    }

    if (this.props.editMode != prevProps.editMode && this.props.isCurrentCard) {
      this.handleCurrentCardFocus();
    }

    if (this.props.editMode != prevProps.editMode) {
      this.setState({
        editMode: this.props.editMode,
      });
    }

    if (this.props.context != prevProps.context) {
      this.manageOutsideClickMonitor();
    }

    if (this.state.cardMode != prevState.cardMode) {
      this.manageOutsideClickMonitor();
    }
  }

  componentWillUnmount = () => {
    this.manageUnsavedChanges();
    this.stopOutsideClickMonitor();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('smart-card:discard-changes-request', this.handleSmartCardDiscardChangesRequest);
    this.events.addListener('smart-card:edit-card-mode-entered', this.handleSmartCardEditCardModeEntered);
    this.events.addListener('smart-card:edit-card-mode-request', this.handleSmartCardEditCardModeRequest);
    this.events.addListener('smart-card:save-changes-request', this.handleSmartCardSaveChangesRequest);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    if ((this.props.context == 'browse' || this.props.context == 'study') && this.state.cardMode == 'display') {
      return this.renderCardSet();
    }

    const card = this.state.card;

    const cardModeClass = `${this.state.cardMode}-card-mode`;
    const contextClass = `${this.props.context}-context`;
    const editModeClass = (this.state.cardMode == 'edit') ? `${this.state.editMode}-edit-mode` : '';
    const layoutClass = `${this.props.layout}-layout`;
    const viewportStyleClass = `${this.props.viewportStyle}-viewport-style`; 
    const formatClass = `${this.props.format}-format`;
    const isCurrentCardClass = (this.props.isCurrentCard) ? 'is-current-card' : '';
    const isNewCardClass = (this.props.isNewCard) ? 'is-new-card' : '';
    const hasChangesClass = (this.state.hasChanges) ? 'has-changes' : '';
    const levelClass = `level-${card.stats.level}`;

    const classes = toClassStr(['smart-card', cardModeClass, contextClass, editModeClass, layoutClass, viewportStyleClass, formatClass, levelClass, isCurrentCardClass, isNewCardClass, this.props.addClasses]);

    const sectionClasses = toClassStr(['smart-card-section', hasChangesClass, cardModeClass, contextClass, editModeClass, layoutClass, viewportStyleClass]);

    const id = (card.cardId) ? `smart-card-${card.cardId}` : 'smart-card-new';
    const inlineStyle = this.props.inlineStyle || null;

    return (
      <div className={sectionClasses}>

        {this.renderCardHeader()}
        
        <div className={classes} id={id} ref={(elem) => this.elem = elem} style={inlineStyle} data-card-id={card.cardId} onClick={this.handleCardClick} onFocus={this.handleCardFocus}>

          {this.renderEditCardModeSingleCardHeader()}

          <SmartCardFace
            card={card}
            cardMode={this.state.cardMode}
            cardPosition={this.props.cardPosition}
            context={this.props.context}
            currentUser={this.props.currentUser}
            deck={this.props.deck}
            editMode={this.state.editMode}
            editorHasChanges={this.props.editorHasChanges}
            face='question'
            format="md"
            isCurrentCard={this.props.isCurrentCard}
            isLoadingCardDisplayData={this.props.isLoadingCardDisplayData}
            key='question'
            layout={this.props.layout}
            onFieldChange={this.handleFieldChange}
            onKeyDown={this.handleKeyDown}
            onViewLargeImageRequest={this.props.onViewLargeImageRequest}
            viewportStyle={this.props.viewportStyle}
          />

          <SmartCardFace 
            card={card}
            cardMode={this.state.cardMode}
            cardPosition={this.props.cardPosition}
            context={this.props.context}
            currentUser={this.props.currentUser}
            deck={this.props.deck}
            editMode={this.state.editMode}
            editorHasChanges={this.props.editorHasChanges}
            face='answer'
            format="md"
            isBlurred={this.props.isBlurred}
            isCurrentCard={this.props.isCurrentCard}
            isLoadingCardDisplayData={this.props.isLoadingCardDisplayData}
            key='answer'
            layout={this.props.layout}
            onFieldChange={this.handleFieldChange}
            onKeyDown={this.handleKeyDown}
            onViewLargeImageRequest={this.props.onViewLargeImageRequest}
            viewportStyle={this.props.viewportStyle}
          />
        </div>

        {this.renderCardFooter()}

      </div>
    );
  };

  renderCardSet() {
    const card = this.state.card;
    const deck = this.props.deck;
    const isAnswerFace = (this.props.currentCardFace == 'answer');

    const format = 'md';
    const formatClass = `${format}-format`;
    const contextClass = `${this.props.context}-context`;
    const layoutClass = `${this.props.layout}-layout`;

    const classes = toClassStr([contextClass, formatClass, layoutClass]);

    const fontSizeCalc = null;

    const inlineCardStyle = {};

    return (
      <CardSet
        baseFontSize={this.state.baseFontSize}
        card={card}
        cardCount={this.props.cardCount}
        cardMode={this.state.cardMode}
        cardPosition={this.props.cardPosition}
        className={classes}
        confidenceLevel={card.stats.level}
        context={this.props.context}
        currentUser={this.props.currentUser}
        deck={this.props.deck}
        fontSizeCalc={fontSizeCalc}
        format={format}
        isAtCheckpoint={this.props.isAtCheckpoint}
        isAudioMuted={this.props.isAudioMuted}
        isBlurred={this.props.isBlurred}
        isLoadingCardDisplayData={this.props.isLoadingCardDisplayData}
        onClick={this.handleCardClick}
        onViewLargeImageRequest={this.handleViewLargeImageRequest}
        pack={this.props.pack}
        packId={this.props.packId}
        revealed={isAnswerFace}
        revealMode={RevealModes.normal}
        skipDirection={this.props.skipDirection}
        style={inlineCardStyle}
        viewportStyle={this.props.viewportStyle}
      />
    );
  }

  renderCardHeader() { // renders on the left of a side by side card
    if ((this.props.context == 'browse' || this.props.context == 'study') && this.state.cardMode == 'display') {
      return null;
    }

    const card = this.props.card;
    const isNewCard = this.props.isNewCard;
    const isNewCardClass = (isNewCard) ? 'is-new-card' : '';
    const classes = toClassStr(['card-header', isNewCardClass]);

    return (
      <div className={classes}>
        <div className="card-number">
          <div className="row-number">{this.props.rowNumber}</div>
          {this.renderNewFlag()}
        </div>
      </div>
    );
  }

  renderEditCardModeSingleCardHeader() {
    if (this.state.cardMode != 'edit') {
      return null;
    }

    if (this.props.layout != 'single-card') {
      return null;
    }

    return (
      <div className="edit-card-mode-single-card-header">
        <div className="left-buttons">
          {this.renderBookmarkButton()}
        </div>

        <div className="center">
          {this.renderCardIndicator()}
        </div>

        <div className="right-buttons">
          {/* {this.renderEditButton()} */}
        </div>
      </div>
    );
  }

  renderBookmarkButton = () => {
    return null;
  }

  renderCardIndicator = () => {
    if (this.props.context == 'browse') {
      const cardPosition = this.props.cardPosition || this.props.card.number;
      const cardCount = this.props.cardCount || null;
      const cardInfo = cardCount ? `Card ${cardPosition} of ${cardCount}` : `Card ${cardPosition}`;
      const cardIndicator = (this.props.cardMode == 'edit') ? `Editing ${cardInfo}` : cardInfo;

      return (
        <div className="card-indicator">{cardIndicator}</div>
      );
    }

    if (this.props.context == 'study' && this.state.cardMode == 'edit') {
      return (
        <div className="card-indicator">Editing Card</div>
      );
    }

    return null;
  }

  renderEditButton() {
    if (this.state.cardMode == 'edit') {
      return null;
    }

    const card = this.props.card;
    const deck = this.props.deck;

    const title = (deck?.flags?.isEditable) ? 'Edit this Card' : 'Suggest Changes to this Card';

    return (
      <EditButton
        addClasses='edit-card-button'
        onClick={this.handleEditButtonClick}
        tooltipContent={title}
        tooltipPosition="bottom"
      />
    );
  }

  renderNewFlag() {
    if (!this.props.isNewCard) {
      return null;
    }

    return (
      <div className="new-flag">(new)</div>
    );
  }

  renderCardFooter = () => { // renders on the right of a side by side card
    if ((this.props.context == 'browse' || this.props.context == 'study') && this.state.cardMode == 'display') {
      return null;
    }

    const card = this.props.card;
    const context = this.props.context;
    const cardMode = this.state.cardMode;

    if (context == 'preview' && this.props.isBlurred) {
      return (
        <div className="card-footer action-buttons">
        </div>
      );
    }

    if (cardMode == 'display') {
      return (
        <div className="card-footer action-buttons">
          {this.renderEditButton()}
          {this.renderConfidencePulldown()}
        </div>
      );
    }

    if (cardMode == 'edit' && context == 'preview') {
      return (
        <div className="card-footer action-buttons">
          {this.renderExitEditCardModeButton()}
          {this.renderSaveCardButton()}
          {this.renderDeleteCardButton()}
        </div>
      );
    }

    if (cardMode == 'edit' && (context == 'browse' || context == 'study')) {
      return (
        <div className="card-footer action-buttons">
          {this.renderExitEditCardModeButton()}
          {this.renderSaveCardButton()}
        </div>
      );
    }

    if (context == 'editor') {
      return (
        <div className="card-footer action-buttons">
          {this.renderDeleteCardButton()}
          {this.renderEditorCardOptionsButton()}
        </div>
      );
    }

    return null;
  }

  renderSaveCardButton() {
    const card = this.props.card;

    const context = this.props.context;
    const cardMode = this.state.cardMode;

    if (context == 'editor' || cardMode != 'edit') {
      return null;
    }

    if (!this.props.deck.flags?.isEditable) {
      return null;
    }

    return (
      <SaveButton
        addClasses="save-card-button"
        isProcessing={this.state.isProcessingCardSave}
        onClick={() => this.handleSaveCardButtonClick(card)}
        tooltipContent="Save your changes"
        tooltipPosition="left"
      />
    );
  }

  renderExitEditCardModeButton() {
    const context = this.props.context;
    const cardMode = this.state.cardMode;

    if (context == 'editor') {
      return null;
    }

    if (cardMode != 'edit') {
      return null;
    }

    if (!this.props.deck.flags?.isEditable) {
      return null;
    }

    const card = this.props.card;
    let tooltipContent = StringHelper.toTitleCase(`Stop Editing and Return to ${context}`);

    return (
      <ReturnButton
        onClick={() => this.handleExitEditCardModeButtonClick(card)}
        tooltipContent={tooltipContent}
        tooltipPosition="left"
      />
    );
  }

  renderDeleteCardButton() {
    const card = this.props.card;
    let tooltipContent = "Delete this card from your Deck"

    if (this.state.cardMode != 'edit') {
      return null;
    }

    if (!this.props.deck.flags.isEditable) {
      return null;
    }

    return (
      <DismissButton
        addClasses="delete-card-button"
        isProcessing={this.state.isProcessingDeleteAction}
        onClick={this.handleDeleteCardButtonClick}
        tooltipContent={tooltipContent}
        tooltipPosition="left"
      />
    );
  }

  renderEditorCardOptionsButton() {
    const card = this.props.card;
    const context = this.props.context;
    const cardMode = this.state.cardMode;

    if (cardMode != 'edit') {
      return null;
    }

    if (context != 'editor') {
      return null;
    }

    return (
      <EditorCardOptionsButton 
        card={card}
        deckId={this.props.deck.deckId}
        editMode={this.props.editMode}
        openPosition="bottomLeft"
        packId={this.props.packId}
        tooltipContent="Insert or Duplicate Card"
        tooltipPosition="left"
      />
    );
  }

  renderEditButton() {
    if (this.props.shouldSuppressEditButton) {
      return false;
    }

    if (this.props.isPreviewModal) {
      return null;
    }

    const card = this.props.card;

    return (
      <EditButton
        addClasses="action-button"
        onClick={() => this.handleEditButtonClick(card)}
        tooltipContent="Edit card or make suggestions"
        tooltipPosition="left"
      />
    );
  }

  renderConfidencePulldown() {
    if (this.props.isPreviewModal) {
      return null;
    }

    if (this.props.deck.flags?.isLocked) {
      return null;
    }

    const card = this.props.card;

    return (
      <div className="action-button pulldown-title-and-control">
        <Pulldown
          addClasses="confidence-pulldown"
          id={`pulldown-${card.cardId}`}
          isOpen={this.state.isConfidencePulldownOpen}
          options={CONF_LEVEL_OPTS}
          fieldName="confidence-level"
          placeholderText="--"
          selectedValue={card.stats?.level}
          shouldSuppressNullOption={true}
          onButtonClick={this.handleConfidenceButtonClick}
          onOptionClick={(level) => this.handleConfidenceOptionClick(level)}
          onOutsideClick={() => this.handleConfidenceOutsideClick(card.cardId)}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCardClick = (e) => {
    if (this.props.context == 'study' && this.state.cardMode == 'display') {
      e.stopPropagation();
      this.triggerRevealCardFaceRequest();

      return true;
    }

    if (e.target.closest('.is-current-card')) {
      return false;
    }

    this.triggerChangeCurrentCardRequest();
  }

  handleCardFocus = (e) => {
    if (e.target.closest('.is-current-card')) {
      return false;
    }

    this.triggerChangeCurrentCardRequest();
  }

  handleCloseModeRequest = () => {

  }

  handleConfidenceButtonClick = () => {
    const isConfidencePulldownOpen = !this.state.isConfidencePulldownOpen;

    this.setState({
      isConfidencePulldownOpen: isConfidencePulldownOpen,
    });
  };

  handleConfidenceOptionClick = (level=1) => {
    this.setState({
      isProcessingConfidenceChange: true,
      isConfidencePulldownOpen: false,
    });

    const cardId   = this.state.card.cardId;
    const deckId   = this.props.deck.deckId;
    const newLevel = this.oneThroughFive(level);
    const packId   = this.props.packId;

    cardConfidence.set(packId, deckId, cardId, newLevel).then(() => {
      this.setState({
        isProcessingConfidenceChange: false,
      });
    });
  };

  handleConfidenceOutsideClick = () => {
    this.setState({
      isConfidencePulldownOpen: false,
    });
  };

  handleCurrentCardBlur = () => {
    if (this.state.cardMode == 'edit' && this.state.hasChanges && !this.state.isProcessingCardSave) {
      this.handleSaveChangesRequest();
    }
  }

  handleCurrentCardFocus = () => {
    if (this.state.cardMode != 'edit') {
      return false;
    }

    if (this.elem.matches(':focus-within')) {
      return false;
    }

    this.focusFirstSmartTextField();
  }

  handleDeleteCardButtonClick = () => {
    const actionText = `remove this flashcard from your deck`;

    this.triggerConfirmModalOpen({
      actionText: actionText,
      resolveButtonText: 'Yes, remove card',
      onResolution: () => {
        deckCard.destroy(this.props.packId, this.props.deck.deckId, this.props.card.cardId);

        this.setState({
          isProcessingDeleteAction: false,
        });
      },
      onCloseRequest: () => {
        this.setState({
          isProcessingDeleteAction: false,
        });
      }
    });
  }

  handleDismissEditCardButtonClick = () => {
    this.handleDiscardChangesRequest();
  }

  handleDiscardChangesRequest = () => {
    if (this.state.cardMode != 'edit') {
      return false;
    }

    this.handleSmartCardDiscardChangesRequest();
  }

  handleEditButtonClick = () => {
    this.handleEditCardModeRequest();
  }

  handleEditCardModeRequest = () => {
    if (!this.props.deck.flags?.isEditable) {
      this.triggerChangeCurrentCardRequest();
      this.triggerSuggestEditModalSetOpen();

      return true;
    }

    if (this.props.isMobileViewportSize) {
      const {packId, deck, card} = this.props;
      UiHelper.navigate(`/l/dashboard/${packId}/decks/${deck.deckId}/cards/${card.cardId}/edit`);

      return true;
    }

    if (this.props.context == 'study') {
      // cached study cards do not include individual md fields. retrieve full version from API. 
      this.retrieveFullyEditableCard().then(() => {
        this.setState({
          cardMode: 'edit',
        });

        this.triggerChangeCurrentCardRequest();
        this.triggerChangeViewportStyleRequest('desktop');
        this.publishEditCardModeEntered();
      }).catch(err => {
        console.log('Something went wrong retrieving Md fields. err:', err);
      });

      return true;
    }

    this.setState({
      cardMode: 'edit',
    });

    this.triggerChangeCurrentCardRequest();
    this.triggerChangeViewportStyleRequest('desktop');
    this.publishEditCardModeEntered();
  }

  handleExitEditCardModeButtonClick = () => {
    if (this.state.cardMode != 'edit') {
      return false;
    }

    this.handleSmartCardDiscardChangesRequest();
  }

  handleFieldChange = (fieldId, value, shouldSave=false) => {
    let newCard = {...this.state.card};

    newCard[fieldId] = value;

    this.setState({
      card: newCard,
    }, () => {
      this.manageHasChanges();
    });
  }

  handleInvokeDisplayCardModeRequest = () => {
    this.setState({
      cardMode: 'display',
    });

    this.publishDisplayCardModeEntered();
  }

  handleOutsideClick = (e) => {
    // NOTE: Does not work inside modals
    if (e.target.closest('.smart-card')) {
      return false;
    }

    if (!this.state.hasChanges) {
      return false;
    }

    if (this.elem && this.elem.contains(e.target)) {
      return false;
    }

    this.handleSaveChangesRequest();
  }

  handleSaveCardButtonClick = () => {
    this.handleSaveChangesRequest();
  }

  handleSaveChangesRequest = (navDirection=null) => {
    this.handleSaveUpdatedCardRequest(navDirection).then(() => {
      if (this.props.context != 'editor') {
        this.handleInvokeDisplayCardModeRequest();
      }
    });
  }

  handleSaveNewCardRequest = (navDirection=null) => {
    return new Promise((resolve, reject) => {
      try {
        this.setState({
          isProcessingCardSave: true,
        }, () => {
          this.publishSaveActionStarted();
        });

        const {packId, deck, editMode} = this.props;
        const {card} = this.state;

        const opts = {
          packId: packId,
          deckId: deck.deckId,
          card: card,
          contentType: 'md',
          editMode: editMode,
        };

        deckCard.create(opts).then(newCardData => {
          this.setState({
            hasChanges: false,
            isProcessingCardSave: false,
          }, () => {

            resolve();
          });
        });

        if (navDirection) {
          this.performCardNavigation(navDirection);
        }
      } catch(err) {
        console.error('something went wrong during SmartCard create. err:', err);
        reject(err);
      }
    });
  }

  handleSaveInsertedCardRequest = (navDirection=null) => {
    return new Promise((resolve, reject) => {
      try {
        this.setState({
          isProcessingCardSave: true,
        }, () => {
          this.publishSaveActionStarted();
        });

        const {packId, deck} = this.props;
        const {card, editMode} = this.state;

        const opts = {
          packId: packId,
          deck: deck,
          card: card,
          editMode: editMode,
        };

        deckCard.insert(opts).then(newCardData => {

          this.setState({
            hasChanges: false,
            isProcessingCardSave: false,
          }, () => {

            resolve();
          });
        });

        if (navDirection) {
          this.performCardNavigation(navDirection);
        }
      } catch(err) {
        console.error('something went wrong during SmartCard insert. err:', err);
        reject(err);
      }
    });
  }

  handleSaveUpdatedCardRequest = (navDirection=null) => {
    return new Promise((resolve, reject) => {
      try {
        if (!this.state.hasChanges) {
          resolve();

          if (navDirection) {
            this.performCardNavigation(navDirection);
          }

          return false;
        }

        this.setState({
          isProcessingCardSave: true,
        }, () => {
          this.publishSaveActionStarted();
        });

        const {packId, deck, editMode} = this.props;
        const {card} = this.state;

        const opts = {
          packId: packId,
          deckId: deck.deckId,
          cardId: card.cardId,
          card: card,
          contentType: 'md',
          editMode: editMode,
        };

        deckCard.update(opts).then(updatedCardData => {
          this.setState({
            card: updatedCardData.card,
            hasChanges: false,
            isProcessingCardSave: false,
          }, () => {
            resolve({
              card: updatedCardData.card,
              cardId: updatedCardData.card.cardId,
            });
          });
        });

        if (navDirection) {
          this.performCardNavigation(navDirection);
        }

      } catch(err) {
        console.error('something went wrong during SmartCard save. err:', err);
        reject(err);
      }
    });
  }

  handleSmartCardDiscardChangesRequest = (eventData) => {

    if (this.state.hasChanges) {

      this.triggerConfirmModalOpen({
        actionText: 'discard your changes to this card',
        resolveButtonText: 'Yes, discard changes',
        onResolution: () => {
          this.setState({
            card: this.props.card,
          }, () => {
            if (['preview', 'browse', 'study'].indexOf(this.props.context) != -1) {
              this.setState({
                cardMode: 'display',
              });

              this.publishDisplayCardModeEntered();
            }

            this.manageHasChanges();
          });
        },
      });

      return true;
    }

    if (['preview', 'browse', 'study'].indexOf(this.props.context) != -1) {
      this.setState({
        cardMode: 'display',
      });

      this.publishDisplayCardModeEntered();
    }
  }

  handleSmartCardEditCardModeEntered = (eventData) => {
    if (eventData.cardId == this.props.card.cardId) {
      return false;
    }

    // some other SmartCard just entered edit card mode

    const context = this.props.context;
    const cardMode = this.state.cardMode;

    if (cardMode != 'edit') {
      return false; // nothing to do
    }

    this.handleSaveChangesRequest();
  }

  handleSmartCardEditCardModeRequest = (eventData) => {
    if (eventData.cardId != this.props.card.cardId) {
      return false;
    }

    this.handleEditCardModeRequest();
  }

  handleSmartCardSaveChangesRequest = (eventData) => {
    this.handleSaveChangesRequest();
  }

  handleViewLargeImageRequest = (url) => {
    this.triggerImageViewerOpen({url: url});
  }


  /*
  ==================================================
   KEYDOWN EVENT HANDLERS AND FIELD NAVIGATION UTILS
  ==================================================
  */

  handleKeyDown = (e) => {
    const id = e.targetId || e.target.id;

    const {cardMode} = this.state;
    const {context} = this.props;

    // Tab
    if (e.keyCode === 9) {
      if (cardMode == 'display' && context == 'browse') {
        e.preventDefault();
        e.stopPropagation();

        this.handleNextCardRequest();

        return true;
      }

      if (cardMode == 'edit') {

        if (this.state.editMode == 'source') {
          this.handleTextTabKeyDown(e);
          return true;
        }

        if (this.state.editMode == 'advanced') {
          this.handleSmartTextFieldTabKeyDown(e);
          return true;
        }

        // simple edit mode
        this.handleMdTabKeyDown(e); 
        return true;
      }
    }

    // Ctrl-Enter
    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {

      if (cardMode == 'edit') {
        e.preventDefault();
        e.stopPropagation();

        this.handleSaveChangesRequest();

        return true;
      }
    }

    // Esc during edit
    if (e.keyCode === 27) {

      if (cardMode == 'edit' && this.state.hasChanges) {
        e.preventDefault();
        e.stopPropagation();

        this.handleDiscardChangesRequest();
        return true;
      }
    }

    // Left Arrow
    if (e.keyCode === 37) {

      if (cardMode == 'display' && context == 'browse') {
        e.preventDefault();
        e.stopPropagation();

        this.handlePreviousCardRequest();

        return true;
      }
    }

    // Right Arrow
    if (e.keyCode === 39) {

      if (cardMode == 'display' && context == 'browse') {
        e.preventDefault();
        e.stopPropagation();

        this.handleNextCardRequest();

        return true;
      }
    }
  }

  handleMdTabKeyDown = (e) => {
    const id = e.targetId || e.target.id;

    if ((id.indexOf('qMdBody') != -1) && e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();

      this.handleSaveChangesRequest('prev');
      return true;
    }

    if ((id.indexOf('aMdBody') != -1) && e.shiftKey) {
      this.selectMdQuestion(e);
      return true;
    }

    if ((id.indexOf('aMdBody') != -1)) {
      e.preventDefault();
      e.stopPropagation();

      this.handleSaveChangesRequest('next');
      return true;
    }
  }

  handleTextTabKeyDown = (e) => {
    const id = e.targetId || e.target.id;

    if ((id.indexOf('question') != -1) && e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();

      this.handleSaveChangesRequest('prev');
      return true;
    }

    if ((id.indexOf('answer') != -1)) {
      e.preventDefault();
      e.stopPropagation();

      this.handleSaveChangesRequest('next');
      return true;
    }
  }

  handleSmartTextFieldTabKeyDown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.shiftKey) {
      this.selectPreviousSmartTextField(e.target);
    } else {
      this.selectNextSmartTextField(e.target);
    }
  }

  selectNextSmartTextField = (elem) => {
    const smartTextField = elem.closest('.smart-text-field');

    if (!smartTextField) {
      return false;
    }

    const fieldName = smartTextField.getAttribute('data-field-name');
    let isSelected;

    switch (fieldName) {

      // html card fields
      case 'prompt':
        isSelected = this.focusSmartTextField(smartTextField, 'question');
        return isSelected;
      break;

      case 'question':
        isSelected = this.focusSmartTextField(smartTextField, 'qImageCaption');

        if (!isSelected) {
          isSelected = this.focusSmartTextField(smartTextField, 'answer');
        }

        return isSelected;
      break;

      case 'answer':
        isSelected = this.focusSmartTextField(smartTextField, 'aImageCaption');

        if (!isSelected) {
          this.saveAndNavigate();
          return true;
        }

        return isSelected;
      break;


      // md card fields
      case 'qMdPrompt':
        isSelected = this.focusSmartTextField(smartTextField, 'qMdBody');
        return isSelected;
      break;

      case 'qMdBody':
        isSelected = this.focusSmartTextField(smartTextField, 'qMdClarifier');
        return isSelected;
      break;

      case 'qMdClarifier':
        isSelected = this.focusSmartTextField(smartTextField, 'qImageCaption');

        if (!isSelected) {
          isSelected = this.focusSmartTextField(smartTextField, 'qMdFootnote');
        }

        return isSelected;
      break;

      case 'qMdFootnote':
        isSelected = this.focusSmartTextField(smartTextField, 'aMdBody');
        return isSelected;
      break;

      case 'aMdBody':
        isSelected = this.focusSmartTextField(smartTextField, 'aMdClarifier');
        return isSelected;
      break;

      case 'aMdClarifier':
        isSelected = this.focusSmartTextField(smartTextField, 'aImageCaption');

        if (!isSelected) {
          isSelected = this.focusSmartTextField(smartTextField, 'aMdFootnote');
        }

        return isSelected;
      break;

      case 'aMdFootnote':
        this.handleSaveChangesRequest('next');
        return true;
      break;

      // in both html and md
      case 'qImageCaption':

        // if (this.props.deck.contentType == 'md') {
        //   isSelected = this.focusSmartTextField(smartTextField, 'qMdFootnote');
        // } else {
        //   isSelected = this.focusSmartTextField(smartTextField, 'answer');
        // }

        isSelected = this.focusSmartTextField(smartTextField, 'qMdFootnote');
        return isSelected;
      break;

      case 'aImageCaption':

        // if (this.props.deck.contentType == 'md') {
        //   isSelected = this.focusSmartTextField(smartTextField, 'aMdFootnote');
        // } else {
        //   this.handleSaveChangesRequest('next');
        //   return true;
        // }

        isSelected = this.focusSmartTextField(smartTextField, 'aMdFootnote');
        return isSelected;
      break;

      default:
        return false;
    }
  } 

  selectPreviousSmartTextField = (elem) => {
    const smartTextField = elem.closest('.smart-text-field');

    if (!smartTextField) {
      return false;
    }

    const fieldName = smartTextField.getAttribute('data-field-name');
    let isSelected;
    let shouldGoToPrevCard;

    switch (fieldName) {

      // html card fields
      case 'prompt':
        this.handleSaveChangesRequest('prev');
        return true;
      break;

      case 'question':
        isSelected = this.focusSmartTextField(smartTextField, 'prompt');
        return isSelected;
      break;

      case 'answer':
        isSelected = this.focusSmartTextField(smartTextField, 'qImageCaption');

        if (!isSelected) {
          isSelected = this.focusSmartTextField(smartTextField, 'question');
        }

        return isSelected;
      break;

      // md card fields
      case 'qMdPrompt':
        this.handleSaveChangesRequest('prev');
        return true;
      break;

      case 'qMdBody':
        isSelected = this.focusSmartTextField(smartTextField, 'qMdPrompt');
        return isSelected;
      break;

      case 'qMdClarifier':
        isSelected = this.focusSmartTextField(smartTextField, 'qMdBody');
        return isSelected;
      break;

      case 'qImageCaption':
        isSelected = this.focusSmartTextField(smartTextField, 'qMdClarifier');
        return isSelected;
      break;

      case 'qMdFootnote':
        isSelected = this.focusSmartTextField(smartTextField, 'qImageCaption');

        if (!isSelected) {
          isSelected = this.focusSmartTextField(smartTextField, 'qMdClarifier');
        }

        return isSelected;
      break;

      case 'aMdBody':
        isSelected = this.focusSmartTextField(smartTextField, 'qMdFootnote');
        return isSelected;
      break;

      case 'aMdClarifier':
        isSelected = this.focusSmartTextField(smartTextField, 'aMdBody');
        return isSelected;
      break;

      case 'aImageCaption':
        isSelected = this.focusSmartTextField(smartTextField, 'aMdClarifier');
        return isSelected;
      break;

      case 'aMdFootnote':
        isSelected = this.focusSmartTextField(smartTextField, 'aImageCaption');

        if (!isSelected) {
          isSelected = this.focusSmartTextField(smartTextField, 'aMdClarifier');
        }

        return isSelected;
      break;

      // in both html and md
      case 'qImageCaption':

        // if (this.props.deck.contentType == 'md') {
        //   isSelected = this.focusSmartTextField(smartTextField, 'qMdBody');
        // } else {
        //   isSelected = this.focusSmartTextField(smartTextField, 'question');
        // }

        isSelected = this.focusSmartTextField(smartTextField, 'qMdBody');
        return isSelected;
      break;

      case 'aImageCaption':

        // if (this.props.deck.contentType == 'md') {
        //   isSelected = this.focusSmartTextField(smartTextField, 'aMdBody');
        // } else {
        //   isSelected = this.focusSmartTextField(smartTextField, 'answer');
        // }

        isSelected = this.focusSmartTextField(smartTextField, 'aMdBody');
        return isSelected;
      break;

      default:
        return false;
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerConfirmModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerChangeCurrentCardRequest = () => {
    EventManager.emitEvent('current-card:change-request', {
      cardId: this.props.card.cardId,
      // tabId: this.props.context,
    });
  }

  triggerDismissNewCardRequest = () => {
    EventManager.emitEvent('card:dismiss-new-card-request', {
      cardId: this.props.card.cardId,
      deckId: this.props.deck.deckId,
      packId: this.props.packId,
    });
  }

  triggerImageViewerOpen = (viewProps) => {
    EventManager.emitEvent('image-viewer:open', viewProps);
  }

  triggerNextCardRequest = () => {
    const cardId = this.props.card.cardId;

    EventManager.emitEvent('current-card:next-card-request', {
      cardId: cardId,
      editMode: this.state.editMode,
    });
  }

  triggerPrevCardRequest = () => {
    const cardId = this.props.card.cardId;

    EventManager.emitEvent('current-card:prev-card-request', {
      cardId: cardId,
    });
  }

  triggerRevealCardFaceRequest() {
    const newFace = (this.props.currentCardFace == 'answer') ? 'question' : 'answer';

    EventManager.emitEvent('smart-card:reveal-card-face-requested', {
      face: newFace,
    });
  }

  triggerSuggestEditModalSetOpen = () => {
    const card = this.props.card;
    const deck = this.props.deck;

    EventManager.emitEvent('suggest-edit-modal-set:open', {
      cardContentType: 'md',
      cardId: card.cardId,
      deckId: deck.deckId,
      isUserPro: this.props.currentUser.flags.isPro,
      packId: this.props.packId,
    });

    this.triggerTooltipClose({shouldCloseImmediately: true});
  };

  triggerTooltipClose = (viewProps) => {
    EventManager.emitEvent('tooltip:close', viewProps);
  };

  triggerChangeViewportStyleRequest = (newStyle) => {
    EventManager.emitEvent('viewport-style-change-request', {newStyle: newStyle});
  };


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */

  publishChangesStateUpdated = (cardId, hasChanges) => {
    EventManager.emitEvent('smart-card:changes-state-updated', {
      cardId: cardId,
      hasChanges: hasChanges,
    });
  }

  publishSaveActionStarted = () => {
    EventManager.emitEvent('smart-card:save-action-started', {
      cardId: this.state.card.cardId,
    });
  }

  publishDisplayCardModeEntered = () => {
    EventManager.emitEvent('smart-card:display-card-mode-entered', {cardId: this.props.card.cardId});
  }

  publishEditCardModeEntered = () => {
    EventManager.emitEvent('smart-card:edit-card-mode-entered', {cardId: this.props.card.cardId});
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  focusFirstSmartTextField = () => {
    let fieldName = 'qMdBody';

    switch (this.state.editMode) {
      case 'simple':
        fieldName = 'qMdBody';
      break;
      case 'advanced':
        fieldName = 'qMdPrompt';
      break;
      case 'source':
        fieldName = 'question';
      break;
    }

    this.focusSmartTextField(this.elem, fieldName)
  }

  focusSmartTextField = (smartTextField, fieldName) => {
    const smartCard = smartTextField.closest('.smart-card');

    if (!smartCard) {
      return false;
    }

    const field = smartCard.querySelector(`.smart-text-field[data-field-name='${fieldName}']`);

    if (field) {

      if (field.classList.contains('text-format')) {
        const fieldInput = field.querySelector('.textarea-input');

        if (fieldInput) {
          fieldInput.focus();
        }
      } else {
        field.click();
      }

      return true;
    }

    return false;
  }

  isBlankCard = (card=this.state.card) => {
    const contentType = 'md';
    const isBlank = StringHelper.isBlank;

    if (contentType == 'md') {
      const isQuestionBlank = isBlank(card.qMdPrompt) && isBlank(card.qMdBody) && isBlank(card.qMdClarifier) && isBlank(card.qMdFootnote) && isBlank(card.qImageUrl) && isBlank(card.qSoundUrl) && isBlank(card.question);
      const isAnswerBlank = isBlank(card.aMdPrompt) && isBlank(card.aMdBody) && isBlank(card.aMdClarifier) && isBlank(card.aMdFootnote) && isBlank(card.aImageUrl) && isBlank(card.aSoundUrl) && isBlank(card.answer);

      return (isQuestionBlank && isAnswerBlank);
    }

    const isQuestionBlank = (isBlank(card.question) && isBlank(card.qImageUrl) && isBlank(card.qSoundUrl));
    const isAnswerBlank = (isBlank(card.answer) && isBlank(card.aImageUrl) && isBlank(card.aSoundUrl));

    return (isQuestionBlank && isAnswerBlank);
  }

  isNormalizedDataEqual(oldData, newData) {
    const oldNormalizedData = (oldData) ? he.decode(oldData) : null;
    const newNormalizedData = (newData) ? he.decode(newData) : null;

    return (oldNormalizedData == newNormalizedData);
  }

  manageHasChanges = () => {
    const originalCard = this.props.card;
    const currentCard = this.state.card;
    const deck = this.props.deck;
    let fields = MD_FIELDS;
    let hasChanges = false;

    // if (deck.contentType != 'md') {
    //   fields = LEGACY_FIELDS;
    // }

    if (this.state.editMode == 'source') {
      fields = SOURCE_FIELDS;
    }

    fields.forEach((key) => {
      if (!this.isNormalizedDataEqual(originalCard[key], currentCard[key])) {
        hasChanges = true;
        return;
      }
    });        

    FILE_FIELDS.forEach((key) => {
      if (!this.isNormalizedDataEqual(originalCard[key], currentCard[key])) {
        hasChanges = true;
        return;
      }
    });

    const newChangesState = (hasChanges != this.state.hasChanges);       

    this.setState({
      hasChanges: hasChanges,
    }, () => {
      if (newChangesState) {
        this.publishChangesStateUpdated(currentCard.cardId, hasChanges);
      }
    });
  }

  manageOutsideClickMonitor = () => {
    if (this.state.cardMode == 'edit') {
      this.startOutsideClickMonitor();
    } else {
      this.stopOutsideClickMonitor();
    }
  }

  manageUnsavedChanges = () => {
    if (this.state.isProcessingCardSave) {
      return false;
    }

    if (!this.state.hasChanges) {
      return false;
    }

    this.handleSaveChangesRequest();
    TimeHelper.wait(500);
  }

  oneThroughFive = (level) => {
    const i = isNaN(parseInt(level, 10)) ? 1 : level;
    return Math.min(5, Math.max(1, i));
  }

  performCardNavigation = (navDirection) => {
    switch (navDirection) {
      case 'next':
        this.triggerNextCardRequest();
      break;
      case 'prev':
        this.triggerPrevCardRequest();
      break;
    }
  }

  retrieveFullyEditableCard = () => {
    return new Promise((resolve, reject) => {
      try {
        const {pack, deck, card} = this.props;

        deckCard.show(pack.packId, deck.deckId, card.cardId).then(data => {
          const augmentedCard = {...card, ...data};

          this.setState({
            card: augmentedCard,
          }, () => {
            resolve();
          });
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  }

  selectMdQuestion = () => {

  }

  startOutsideClickMonitor = () => {
    if (this._isMounted) {
      document.addEventListener('click', this.handleOutsideClick);

      this.setState({
        isListeningToOutsideClicks: true
      });
    }
  }

  stopOutsideClickMonitor = () => {
    if (this._isMounted) {
      document.removeEventListener('click', this.handleOutsideClick);

      this.setState({
        isListeningToOutsideClicks: false,
      });
    }
  }
}

SmartCard.propTypes = PT;

export default SmartCard;
