//
// NOTE: These methods require google analytics (window.gtag) to be loaded and available
// via the window object
//

const GaHelper = {

  fireGaEvent() { // signature: category, action, label, value, isNonInteractive
    var category = arguments[0],
        action   = arguments[1],
        params   = {
          'hitType': 'event',
          'eventCategory': category,
          'eventAction': action
        };

    if (typeof(arguments[2]) != "undefined") {
      params.eventLabel = arguments[2];
    }
    if (typeof(arguments[3]) != "undefined") {
      params.eventValue = arguments[3];
    }
    if (typeof(arguments[4]) != "undefined") {
      params.nonInteraction = arguments[4];
    }
    if (this.isVisitor()) {
      params.anonymizeIp = true
    }

    try {
      // GA4
      gtag('event', params.eventLabel, { 
        event_category: category,
        event_action: action,
        anonymize_ip: params.anonymizeIp,
        event_label: params.eventLabel,
        event_value: params.eventValue,
        non_interaction: params.nonInteraction,
        send_to: this.ga_4_id(),
      });      
    }
    catch (e) {
      console.log("Error firing GA event.");
    }
  },

  fireGaEventNi() { // signature: category, action, label, value
    var category = arguments[0],
        action   = arguments[1],
        params   = {
          'hitType': 'event',
          'eventCategory': category,
          'eventAction': action
        };
        params.nonInteraction = true;

    if (typeof(arguments[2]) != "undefined") {
      params.eventLabel = arguments[2];
    }
    if (typeof(arguments[3]) != "undefined") {
      params.eventValue = arguments[3];
    }
    if (typeof(arguments[4]) != "undefined") {
      params.nonInteraction = arguments[4];
    }
    if (this.isVisitor()) {
      params.anonymizeIp = true
    }

    try {
      // GA4
      gtag('event', params.eventLabel, { 
        event_category: category,
        event_action: action,
        anonymize_ip: params.anonymizeIp,
        event_label: params.eventLabel,
        event_value: params.eventValue,
        non_interaction: params.nonInteraction,
        send_to: this.ga_4_id(),
      });      

      console.log('In GaHelper.fireGaEventNi. Fired event:', {category: category, action: action});
    }
    catch (e) {
      console.log("Error firing GA event.");
    }
  },

  followUrlDelayed(url) {
    setTimeout(() => {
      document.location.href = url;
    }, 100);
  },

  /**
   * Sets GA params for the page, including dimensions and page url,
   * & Sends pageview
   */
  getGaPageParams() {
   if (window.gtag) {
      // GA4
      gtag('event', 'page_view', { 
        page_location: window.location.href, 
        send_to: this.ga_4_id() 
      });

    } else {
      console.log("ga not loaded");
    }
  },

  isVisitor() {
    var isVisitor;

    if (typeof window.data != 'undefined') {
      if (typeof window.data.user_id != 'undefined') {
        isVisitor = false // real user
      } else {
        isVisitor = true // real visitor
      }
    } else {
      isVisitor = true // default cuz we don't know
    }
    return isVisitor;
  },

  setGaDimension(dimension, dimension_value) {
    if (typeof BSC === "undefined" || !BSC) {
      return false;
    }

    BSC.gaDimensions.push({
      "dimension"       : dimension,
      "dimension_value" : dimension_value
    });
  },

  ga_id() {
    const dom = window.location.host.split(".")[0]
    switch(dom) {
      case "www":     return 'UA-10404519-6';
      case "staging": return 'UA-10404519-4';
      default:        return 'UA-10404519-7';
    }
  },  

  ga_4_id() {
    const dom = window.location.host.split(".")[0]
    switch(dom) {
      case "www":     return 'G-L9RY5NGH2P';
      case "staging": return 'G-E3XGXYFMGS';
      default:        return 'G-E3XGXYFMGS';
    }    
  },
}

export default GaHelper;
