
import ForgotForm from './ForgotForm';
import Modal   from '_views/shared/Modal';
import PropTypes  from 'prop-types';
import React      from 'react';

class ForgotModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    return (
      <Modal 
        addClasses="auth-modal forgot-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={this.props.onCloseRequest}
      >
        <div className='modal-title'>Forgot Password</div>

        <div className="modal-message">
          Enter your Brainscape account email address and this will
          reset your password. An email will be sent to that address
          explaining how to sign in using the new password.
        </div>

        <ForgotForm 
          shouldSuppressRedirect={this.props.shouldSuppressRedirect}
          onPasswordReset={this.props.onCloseRequest}
        />
      </Modal>
    );
  }
};

ForgotModal.propTypes = {onCloseRequest:  PropTypes.func.isRequired};

export default ForgotModal;
