
import cardAttachmentJob      from '_models/cardAttachmentJob';
import model                  from '_core/newModel';
import SessionStorageHelper   from '_utils/SessionStorageHelper';

import {waitForJob}           from '_core/Api2';


const ATCH_FIELDS = [
  'aImageUrl',
  'aSoundUrl',
  'qImageUrl',
  'qSoundUrl',
];

const MD_FIELDS = [
  'aMdBody',
  'aMdClarifier',
  'aMdFootnote',
  'aMdPrompt',
  'qMdBody',
  'qMdClarifier',
  'qMdFootnote',
  'qMdPrompt',
];

const MD_SOURCE_FIELDS = [
  'answer',
  'question',
];

const TEXT_FIELDS = [
  'aImageCaption',
  'aSoundCaption',
  'answer',
  'name',
  'prompt',
  'qImageCaption',
  'qSoundCaption',
  'question',
];


const deckCard = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  index(packId, deckId) {
    const path = `packs/${packId}/decks/${deckId}/cards`;
    const isPaginated = true;

    const paginationOpts = {
      collectionKey: 'cards',
      eventTag: 'deck-cards',
      page: 1,
      perPage: 30,
    }

    return new Promise((resolve, reject) => {
      try {
        model.index(path, isPaginated, paginationOpts).then(deckCardsData => {
          const cards = deckCardsData.cards;
          const cardIds = cards.map(card => card.cardId);

          const cardsData = {
            cards: cards,
            cardIds: cardIds,
          };  

          resolve(cardsData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },

  show(packId, deckId, cardId) {
    const path = `packs/${packId}/decks/${deckId}/cards/${cardId}`;
    return model.show(path);
  },

  new(tempId) {
    return {
      cardId: tempId || 'new',
      aMdBody: '',
      aMdClarifier: '',
      aMdFootnote: '',
      aImageCaption: '',
      qMdPrompt: '',
      qMdBody: '',
      qMdClarifier: '',
      qImageCaption: '',
      qMdFootnote: '',
      stats: {
        level: 0,
      },
      tempId: tempId || 'new',
    }
  },

  create(opts) {
    const {deckId, packId, card, editMode, contentType} = opts;
    const {textCard, atchCard} = this.filterCardFields(card, contentType, editMode);
    const textPath = `packs/${packId}/decks/${deckId}/cards`;

    return new Promise((resolve, reject) => {
      try {
        model.create(textPath, textCard).then(newCardData => {
          const newCard = newCardData.card;
          const newCardWithFileData = {...newCard, ...atchCard};

          model.publish('card:created', {
            card: newCardWithFileData,
            deckId: deckId,
            deckStats: newCardData.deckStats,
            packId: packId,
            packStats: newCardData.packStats,
          });

          resolve({
            card: newCardWithFileData,
          });

          // create and process attachment job silently in the background
          if (atchCard && Object.keys(atchCard).length > 0) {
            cardAttachmentJob.create(packId, deckId, newCard.cardId, atchCard).then(jobData => {
              this.monitorFileAttachmentJob({
                jobData: jobData,
                packId: packId,
                deckId: deckId,
                cardId: newCard.cardId,
              });
            });
          }
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error('something went wrong during deckCard create. err:', err);
        reject(err);
      }
    });
  },

  insert(opts) {
    const {packId, deck, card, editMode, prevCardId} = opts;
    const {textCard, atchCard} = this.filterCardFields(card, deck.contentType, editMode);
    const deckId = deck.deckId;

    const textPath = `packs/${packId}/decks/${deckId}/cards`;

    return new Promise((resolve, reject) => {
      try {
        model.create(textPath, textCard).then(newCardData => {
          const newCard = newCardData.card;
          const newCardWithFileData = {...newCard, ...atchCard};

          const newCardId = newCard.cardId;
          const prevCardIndex = deck.cardIds.indexOf(prevCardId);
          const newCardIndex = (prevCardIndex != -1) ? prevCardIndex + 1 : deck.cardIds.length;

          // reorder cards to impart insert order
          const deckCardIds = [...deck.cardIds];
          deckCardIds.splice(newCardIndex, 0, newCardId);

          const reorderPath = `packs/${packId}/decks/${deckId}/cards/reorder`;
          const data = {cardIds: deckCardIds.join(',')};

          // hit reorder endpoint
          model.create(reorderPath, data).then(() => {
            model.publish('card:inserted', {
              card: newCardWithFileData,
              deckCardIds: deckCardIds,
              deckId: deckId,
              deckStats: newCardData.deckStats,
              packId: packId,
              packStats: newCardData.packStats,
            });

            resolve({
              card: newCardWithFileData,
            });
          }).catch(err => {
            throw err;
          });

          // create and process attachment job silently in the background
          if (atchCard && Object.keys(atchCard).length > 0) {
            cardAttachmentJob.create(packId, deckId, newCard.cardId, atchCard).then(jobData => {
              this.monitorFileAttachmentJob({
                jobData: jobData,
                packId: packId,
                deckId: deckId,
                cardId: newCard.cardId,
              });
            });
          }
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error('something went wrong during deckCard create. err:', err);
        reject(err);
      }
    });
  },

  update(opts) {
    const {cardId, deckId, packId, card, editMode, contentType} = opts;
    const {textCard, atchCard} = this.filterCardFields(card, contentType, editMode);

    const textPath = `packs/${packId}/decks/${deckId}/cards/${cardId}`;

    return new Promise((resolve, reject) => {
      try {
        model.update(textPath, textCard).then(updatedCard => {
          const updatedCardWithImageData = {...updatedCard, ...atchCard};

          model.publish('card:updated', {
            card: updatedCardWithImageData,
            deckId: deckId,
            packId: packId,
          });

          resolve({
            card: updatedCardWithImageData,
          });

          // create and process attachment job silently in the background
          if (atchCard && Object.keys(atchCard).length > 0) {
            cardAttachmentJob.create(packId, deckId, cardId, atchCard).then(jobData => {
              this.monitorFileAttachmentJob({
                jobData: jobData,
                packId: packId,
                deckId: deckId,
                cardId: cardId,
              });
            }).catch(err => {
              throw err;
            });
          }
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error('something went wrong during deckCard update. err:', err);
        reject(err);
      }
    });
  },

  destroy(packId, deckId, cardId) {
    const path = `packs/${packId}/decks/${deckId}/cards/${cardId}`;

    return model.destroy(path).then(statsData => model.publish('card:removed', {
      cardId: cardId,
      deckId: deckId,
      packId: packId,
      deckStats: statsData.deckStats,
      packStats: statsData.packStats,
    }));
  },

  destroyMulti(packId, deckId, cardIds) {
    // TODO: set up another process to monitor the destroyMulti job that gets invoked, and upon job completion, update stats    
    const path = `packs/${packId}/decks/${deckId}/cards`;
    const data = {
      cardIds: cardIds.join(','),
    };

    return model.destroy(path, data).then(() => model.publish('deck-cards:removed', {
      packId: packId,
      deckId: deckId,
      cardIds: cardIds,
    }));
  },


  /*
  ==================================================
   CACHING
  ==================================================
  */

  getCachedIndex(packId, deckId) {
    return SessionStorageHelper.getPackDeckItem(packId, deckId, 'deckCards') || null;
  },

  setCachedIndex(packId, deckId, deckCards) {
    const cleanDeckCards = this.stripEmbeddedFileData(deckCards);
    SessionStorageHelper.setPackDeckItem(packId, deckId, 'deckCards', cleanDeckCards);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  filterCardFields(card, contentType, editMode) {
    const atchCard = {};
    let textCard = {};

    TEXT_FIELDS.forEach((key) => textCard[key] = card[key]);

    if (contentType == 'md') {

      if (editMode == 'source') {
        MD_SOURCE_FIELDS.forEach((key) => textCard[key] = card[key]);
        textCard.isMdSource = true;
      } else {
        MD_FIELDS.forEach((key) => textCard[key] = card[key]);
      }
    }

    ATCH_FIELDS.forEach((key) => {
      const value = card[key];

      if (value === 'destroy') {
        textCard[key] = value;
      } else if (value && value.startsWith('data:')) {
        atchCard[key] = value;
      }
    });

    textCard = (contentType == 'md') ? this.replaceBulletsWithAsterisks(textCard) : textCard;

    return {textCard, atchCard};
  },

  getCleanUrlValue(urlValue) {
    return (urlValue.startsWith('data:')) ? '' : urlValue;
  },

  monitorFileAttachmentJob(opts) {
    if (opts.jobData.jobId == -1) {
      // job has already been performed
      this.show(opts.packId, opts.deckId, opts.cardId).then(card => {
        this.publishFileAttachmentJobCompleted(opts.packId, opts.deckId, card);
      });

      return true;
    }

    waitForJob(opts.jobData).then(() => {
      this.show(opts.packId, opts.deckId, opts.cardId).then(card => {
        this.publishFileAttachmentJobCompleted(opts.packId, opts.deckId, card);
      });
    });
  },

  publishFileAttachmentJobCompleted(packId, deckId, card) {
    model.publish('card-attachment:job-completed', {card: card});
    model.publish('card:updated', {
      card: card,
      deckId: deckId,
      packId: packId,
    });
  },

  replaceBulletsWithAsterisks(textCard) {
    Object.keys(textCard).forEach(key => {
      if (textCard[key] && (typeof textCard[key]) == 'string') {
        textCard[key] = textCard[key].replaceAll('•', '*');
      }
    });

    return textCard;
  },

  stripEmbeddedFileData(cards) {
    const cleanCards = cards.map(card => {
      const cleanCard = {...card};

      ATCH_FIELDS.forEach(key => {
        if (cleanCard[key]) {
          cleanCard[key] = this.getCleanUrlValue(cleanCard[key]);
        }
      });

      return cleanCard; 
    });

    return cleanCards;
  },
};

export default deckCard;
