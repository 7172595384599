
import mainApp      from '_core/main-app';

if (document.readyState === 'loading') {
  // The DOM is still loading, set up the event listener
  document.addEventListener("DOMContentLoaded", () => {
    mainApp.init();
  });
} else {
  // The DOM has already loaded, initialize immediately
  mainApp.init();
}
