
import AiExceptionPanel                 from '_views/modals/ai/AiExceptionPanel';
import BruceWaitPanel                   from '_views/modals/ai/BruceWaitPanel';
import EventManager                     from '@brainscape/event-manager';
import Modal                            from '_views/shared/Modal';
import PasteListPanel                   from '_views/modals/ai/PasteListPanel';
import PillButton                       from '_views/shared/PillButton';
import React, { useState, useEffect }   from 'react';
import ReviewCardRowsPanel              from '_views/modals/ai/ReviewCardRowsPanel';
import UploadFilePanel                  from '_views/modals/ai/UploadFilePanel';

import { toClassStr }                   from '_utils/UiHelper';


const MakeFlashcardsModal = ({ currentPanel, cardRows, isAiListParser, isAiParsable, isProcessing, hasInvalidInput, errorMessage, isAiProcessComplete }) => {
  
  /*
  ==================================================
   HOOKS 
  ==================================================
  */
  

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  const handleFileDragEnter = () => {
    triggerUploadFileRequest();
  }

  const handleModalClose = () => {
    triggerModalCloseRequest();
  };

  const handleModalClosed = () => {
    console.log('Modal closed');
  };

  const handlePasteListButtonClick = () => {
    triggerPasteListRequest();
  };

  const handleUploadFileButtonClick = () => {
    triggerUploadFileRequest();
  };


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  const triggerModalCloseRequest = () => {  
    EventManager.emitEvent('parse-card-list-modal:close', {});
  }

  const triggerPasteListRequest = () => {
    EventManager.emitEvent('parse-card-list-modal:paste-list-request', {});
  }

  const triggerUploadFileRequest = () => {
    EventManager.emitEvent('parse-card-list-modal:upload-file-request', {});
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */


  /*
  ==================================================
   SUB RENDERERS
  ==================================================
  */

  const renderInitialScreen = () => {
    return (
      <>
        <div className='modal-title'>Import/Paste Flashcards</div>
          <div className='modal-guidance'>How would you like to import your flashcards?</div>
          <div className='modal-actions'>

            <PillButton
              addClasses='resolve-modal-button'
              extraDetails='(Existing Q&A, Terms, etc.)'
              label='Paste list'
              onClick={handlePasteListButtonClick}
            />

            <PillButton
              addClasses='resolve-modal-button'
              extraDetails='(.csv, .txt, .xlsx, .ods)'
              label='Upload file'
              onClick={handleUploadFileButtonClick}
            />
          </div>
      </>
    );
  };

  const renderModalContent = () => {
    switch (currentPanel) {
      case 'freeAiException':
        return (
          <AiExceptionPanel 
            exceptionType={'freeAiException'}
          />
        );
      case 'initial':
        return (renderInitialScreen());
      case 'loading':
        return (
          <BruceWaitPanel 
            isAiProcessComplete={isAiProcessComplete}
          />
        );
      case 'paste':
        return (
          <PasteListPanel
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isProcessing={isProcessing}
          />
        );
      case 'proAiException':
        return (
          <AiExceptionPanel 
            exceptionType={'proAiException'}
          />
        );
      case 'review':
        return (
          <ReviewCardRowsPanel 
            cardRows={cardRows}
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isAiListParser={isAiListParser}
            isAiParsable={isAiParsable}
            isProcessing={isProcessing}
          />
        );
        case 'generalAiException':
          return (
            <AiExceptionPanel
              errorMessage={errorMessage}
              exceptionType={'generalAiException'}
            />
          );  
      case 'upload':
        return (
          <UploadFilePanel
            errorMessage={errorMessage}
            hasInvalidInput={hasInvalidInput}
            isProcessing={isProcessing}
          />
        );
      default:
        return null;
    }
  };


  /*
  ==================================================
   EXPORTED COMPONENT  
  ==================================================
  */

  const isDroppable = (currentPanel == 'initial');
  const largeModalClass = (currentPanel == 'review') ? 'is-large-modal' : '';
  const classes = toClassStr(['parse-card-list-modal', largeModalClass]);

  return (
    <Modal 
      addClasses={classes}
      isDroppable={isDroppable}
      isOpen={true} 
      onClosed={handleModalClosed}
      onCloseRequest={handleModalClose}
      onFileDragEnter={handleFileDragEnter}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default MakeFlashcardsModal;
