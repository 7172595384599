
import EventManager           from '@brainscape/event-manager';
import SessionStorageHelper   from '_utils/SessionStorageHelper';
import TimeHelper             from '_utils/TimeHelper';
import lodash                 from 'lodash';

import {
  InitData,
  Mastery,
  StudySessionContainer,
  CacheEntry,
} from 'smart-study';

/* 
  props:

  deckId,
  initialCardId,
  packId,
  roundLength,

  studyCards,
  studyPacks,

  scope,
  seedData,

  userCards,
  userId,
*/

class StudySessionConcern {
  constructor(props) {
    this._historyOffset = 0;
    this._repeatThisRound = false;
    this._revealMode = 'normal';
    this._studyInReverse = false;
    this.deckId = props.deckId;
    this.eventKey = 'ssc';
    this.initCardId = props.initialCardId;
    this.initRoundLength = props.roundLength;
    this.packId = props.packId;
    this.scope = props.scope;
    this.seedData = props.seedData;
    this.userCards = props.userCards;

    this.isFtse = props.isFtse;
  }

  startSession = () => {
    let currentIds;

    this.ssc = new StudySessionContainer(this.loadSessionData(), 0);

    if (this.initCardId) {
      const cc = this.ssc.cardCache.get(this.initCardId);
      if (cc) {
        currentIds = [cc.packs[0].packId, cc.deck.deckId, this.initCardId];
      }
    }
 
    this.resetCurrentIds(currentIds || this.ssc.nextCard());
  }

  chooseNextCard = () => {
    if (!this.shouldShowRepeat()) {
      this.resetCurrentIds(this.ssc.nextCard());
      return;
    }

    if (this.ssc.ftse) {
      this.resetCurrentIds(this.ssc.nextCard(true));
      return;
    }

    // After the FTSE, we only want to force a repeat for levels 1, 2, & 3.
    // If there are no cards that match that criteria, then we'll use the
    // normal algorithm.
    const ids = this.ssc.repeatCard(lodash.shuffle([1, 2, 3]));
    // this.currentIds = ids[2] !== null ? ids : this.ssc.nextCard();
    const currentIds = ids[2] !== null ? ids : this.ssc.nextCard();

    this.resetCurrentIds(currentIds);
  };

  choosePrevCard = () => {
    if (!this.getCanGoBack()) {
      return false;
    }

    this._historyOffset += 1;

    const historyRatings = this.ssc.history.ratings;
    const rating = historyRatings[historyRatings.length - this._historyOffset];
    const ce = this.ssc.cardCache.get(rating[0]);

    if (!ce) {
      return false;
    }

    this.setCurrentIds([ce.packs[0].packId, ce.deck.deckId, ce.card.cardId]);

    return true;
  };

  getCardInfo = (cardId) => {
    return this.ssc.cardCache.get(cardId);
  }

  getRoundConfidenceGained = (prevLcs) => {
    return this.totalLevelsSum - getLevelsSum(prevLcs);
  };

  /**
   * Returns true if the study session should show a repeat card.
   *
   * This is done because the studier can get to a point where they are
   * seeing only new cards. Forcing repeat cards (regardless of the cutoff)
   * helps to break up the monotony.
   */
  shouldShowRepeat = () =>
    this.ssc.roundLength > 3 &&
    !this._repeatThisRound &&
    this.ssc.roundIndex === this.ssc.roundLength - 2;

  rateCard = (cardId, level) => {
    this.ssc.updateRating(cardId, level);

    EventManager.emitEvent('study-session:card-level-updated', {
      action: 'rateCard',
      cardId: cardId,
      level: level,
    });
  };


  /****************************************************************************/
  /* Attributes                                                               */
  /****************************************************************************/

  getAnyRated() {
    return this.ssc.history.length > 0;
  }

  getCanGoBack() {
    return (
      this.ssc.history.length > 0 &&
      this._historyOffset < this.ssc.history.length
    );
  }

  getCardCount() {
    return this.ssc.mastery.cardCount;
  }

  getCurrentIds() {
    return this._currentIds;
  }

  getCurrentPackId() {
    return this._currentIds[0];
  }

  getCurrentDeckId() {
    return this._currentIds[1];
  }

  getCurrentCardId() {
    return this._currentIds[2];
  }

  getDeckIds() {
    const ids = [];
    this.ssc.packs.forEach((pack) => {
      pack.decks.forEach((deck) => {
        if (!ids.includes(deck.deckId)) ids.push(deck.deckId);
      });
    });

    return ids;
  }

  getFtse() {
    return this.ssc.ftse;
  }

  getLevelCounts() {
    return this.ssc.mastery.levelCounts();
  }

  getMasteryRatio() {
    const cardCount = this.getCardCount();

    if (cardCount < 1) return 0;

    const levelCounts = this.getLevelCounts();

    const adjSum = 
      levelCounts[1] * 1 +
      levelCounts[2] * 2 +
      levelCounts[3] * 3 +
      levelCounts[4] * 4 +
      levelCounts[5] * 5;
 
    return (adjSum / (cardCount * 5));
  }

  getMasteryPercentage() {
    return Math.round(this.getMasteryRatio() * 100);
  }

  getPackIds() {
    return this.ssc.packs.map((pack) => pack.packId);
  }

  getRepeatThisRound() {
    return this._repeatThisRound;
  }

  getRoundHistory() {
    return this.ssc.roundHistory;
  }

  getRoundIndex() {
    return this.ssc.roundIndex;
  }

  getRoundLength() {
    return this.ssc.roundLength;
  }

//   set roundLength(len) {
//     this.ssc.roundLength = len;
// 
//     EventManager.emitEvent(this.eventKey, {
//       action: 'roundLength',
//       roundLength: len,
//     });
//   }

  getRoundsCompleted() {
    return this.ssc.roundsCompleted;
  }

  getStudyInReverse() {
    return this._studyInReverse;
  }

//   set studyInReverse(value) {
//     this._studyInReverse = value;
// 
//     EventManager.emitEvent(this.eventKey, {
//       action: 'studyInReverse',
//       studyInReverse: value,
//     });
//   }

  getRevealMode() {
    return this._revealMode;
  }

//   set revealMode(value: RevealMode) {
//     this._revealMode = value;
// 
//     EventManager.emitEvent(this.eventKey, {
//       action: 'revealMode',
//       revealMode: value,
//     });
//   }

  getTotalLevelsSum() {
    return getLevelsSum(this.levelCounts);
  }

  /****************************************************************************/
  /* Loading Study Data                                                       */
  /****************************************************************************/

  loadSessionData = () => {
    let sessionData;

    switch (this.scope) {
      case 'deck':
        sessionData = this.loadDeckData();
      break;
      case 'pack':
        sessionData = this.loadPackData();
      break;
      case 'study-mix':
        sessionData = this.loadMixData();
      break;
      default:
        console.log('Unknown scope:', this.scope);
    }

    if (!sessionData) return null;

    return sessionData;
  };

  loadDeckData = () => {
    if (!this.packId) throw new Error('The class ID is missing');
    if (!this.deckId) throw new Error('The deck cannot be found.');

    const deck = this.seedData.packs?.hash[this.packId]?.decks.hash[this.deckId];

    if (!deck) throw new Error('The deck cannot be found in the seedData.');
    // if (deck.cards.ids.length < 1) throw new Error('No cards in Study mix.');

    const sessionCards = this.getSessionCards(deck.cards.ids);

    const sessionDeck = {
      deckId: deck.deckId,
      cards: sessionCards,
      randomNewCard: false,
    };

    const sessionPack = {
      packId: this.packId,
      weight: 1, // Single pack...
      randomNewDeck: false, // Single deck...
      decks: [sessionDeck],
    };

    const sessionData = {
      ftse: this.isFtse,
      packs: [sessionPack],
      roundLength: this.initRoundLength,
    };

    return sessionData;
  };

  loadPackData = () => {
    if (!this.packId) throw new Error('The class ID is missing');

    const pack = this.seedData.packs?.hash?.[this.packId];
    const decks = pack?.decks;
    let cardCount = 0;

    if (!(pack && decks?.ids?.length > 0)) throw new Error('There are no packs or selected decks in the seedData.');

    const sessionDecks = this.getSessionDecks(decks);

    const sessionPack = {
      packId: this.packId,
      weight: 1, // Single pack...
      randomNewDeck: (pack.mixType == 'random'),
      decks: sessionDecks,
    };

    const sessionData = {
      ftse: this.isFtse,
      packs: [sessionPack],
      roundLength: this.initRoundLength,
    };

    return sessionData;
  };

  loadMixData = () => {
    const packs = this.seedData.packs;

    const sessionPacks = packs.ids.map(packId => {
      const pack = packs.hash[packId];
      if (!pack) throw new Error('No Packs found in the seedData.');

      const sessionDecks = this.getSessionDecks(pack.decks);

      return {
        packId: packId,
        weight: 1, // Until we implement Smart Study...
        randomNewDeck: (pack.mixType == 'random'),
        decks: sessionDecks,
      }
    })

    const sessionData = {
      ftse: this.isFtse,
      packs: sessionPacks,
      roundLength: this.initRoundLength,
    };

    return sessionData;
  };

  getSessionCards = (cardIds) => {
    const sessionCards = cardIds.map(cardId => {
      const userCard = this.userCards.userCardsH[cardId];
      const hasUserCard = !!userCard;

      return {
        cardId: cardId,
        level: hasUserCard ? userCard.level || 0 : 0,
        ratedEpoch: hasUserCard && userCard.updatedAt ? TimeHelper.isoToEpoch(userCard.updatedAt) : null,
      };
    });

    return sessionCards;
  }

  getSessionDecks = (decks) => {
    const sessionDecks = decks.ids.map(deckId => {
      const deck = decks.hash[deckId];
      const sessionCards = this.getSessionCards(deck.cards?.ids || []);

      return {
        deckId: deckId,
        cards: sessionCards,
        randomNewCard: false,
      }
    });

    return sessionDecks;
  }


  /****************************************************************************/
  /* Misc                                                                     */
  /****************************************************************************/

  getPackWeight = (userId, packId) => {
    return 1; // TODO: Look this up using packStudyConfig data in local storage
  }

  levelFor = (cardId) => {
    return this.ssc.cardCache.get(cardId)?.card.level;
  }

  setCurrentIds = (ids) => {
    this._currentIds = ids;

    if (this.ssc.roundLength > 0) {
      if (this.ssc.roundIndex == 0) this._repeatThisRound = false;
      if (this.levelFor(ids[2]) > 0) this._repeatThisRound = true;
    }

    EventManager.emitEvent('study-session:current-ids-updated', {
      action: 'currentIds',
      packId: ids[0],
      deckId: ids[1],
      cardId: ids[2],
      level:  this.levelFor(ids[2]),
    });
  };

  resetCurrentIds(ids) {
    this._historyOffset = 0;
    this.setCurrentIds(ids);
  }
}

const getLevelsSum = (levels) => {
  return levels.map((lvl, idx) => lvl * idx).reduce((sum, lvl) => sum + lvl, 0);
};

const NO_CARDS_ERR =
  'No cards selected. Please make sure the deck(s) you want to study have been downloaded.';

export default StudySessionConcern;
