
import EditPackPrivacyModal         from '_views/modals/EditPackPrivacyModal';
import EventManager                 from '@brainscape/event-manager';
import React                        from 'react';

import pack                         from '_models/pack';

class EditPackPrivacyModalController extends React.Component {
  constructor(props) {
    super(props);

    this.state  = {
      isModalOpen: false,
      isProcessing: false,
      viewProps: null,
    };

    this.events = new EventManager();
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.events.addListener(
      'edit-pack-privacy-modal:open',
      (data) => this.setState({
        isModalOpen: true,
        viewProps: data,
      }),
    );

    this.events.addListener(
      'edit-pack-privacy-modal:close',
      () => {
        this.handleCloseRequest();
      }
    );
  }

  componentWillUnmount() {
    this.events.disable();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const viewProps = this.state.viewProps;
    if (!viewProps) { 
      return null; 
    }

    return (
      <EditPackPrivacyModal
        addClasses={viewProps.addClasses}
        isOpen={this.state.isModalOpen}
        isProcessing={this.state.isProcessing}
        newPrivacyState={viewProps.newPrivacyState}
        onClosed={this.handleClosed}
        onCloseRequest={this.handleCloseRequest}
        onPackPrivacyChangeRequest={this.handlePackPrivacyChangeRequest}
        pack={viewProps.pack}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  // invokes modal closing animation. Modal is still in DOM
  handleCloseRequest = () => {
    this.setState({
      isModalOpen: false,
    }, () => {
      if (this.state.viewProps && this.state.viewProps.onCloseRequest) {
        this.state.viewProps.onCloseRequest();
      }
    });
  }

  // invoked after modal close animation is complete. Removes modal from DOM
  handleClosed = () => {
    const onClosed = this.state.viewProps.onClosed;

    this.setState({
      viewProps: null
    }, () => {
      if (onClosed) {
        onClosed();
      }
    });
  }

  handlePackPrivacyChangeRequest = () => {
    this.setState({
      isProcessing: true,
    });

    const packData = {
      pack: {
        isPrivate: this.state.viewProps.newPrivacyState,
      }
    };

    pack.update(this.state.viewProps.pack.packId, packData).then(() => {
      this.setState({
        isProcessing: false,
      })
    });
  }
}

export default EditPackPrivacyModalController;
