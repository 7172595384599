
import model from '_core/newModel';

const cardEditSuggestion = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returnks promises)
  ==================================================
  */

  create(packId, deckId, cardId, suggestionText) {
    const path = `packs/${packId}/decks/${deckId}/cards/${cardId}/edit/suggest`;

    const data = {
      text: suggestionText,
    };

    return model.create(path, data);
  },

  prescribe(packId, deckId, cardId) {
    const path = `packs/${packId}/decks/${deckId}/cards/${cardId}/edit/request`;
    return model.show(path);
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default cardEditSuggestion;
