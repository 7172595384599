
import React from 'react';
import Modal from '_views/shared/Modal';

import { toClassStr } from '_utils/UiHelper';

class AttributionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }

    /*
      this.props:
        addClasses,
        isOpen,
        onClosed,
        onCloseRequest,
        shouldAutoPlay,
        videoUrl
    */
  }

  render() {
    const classes = toClassStr(['attribution-modal', this.props.addClasses]);

    return (
      <Modal
        addClasses={classes}
        isOpen={this.props.isOpen}
        onCloseRequest={this.props.onCloseRequest}
        size="medium"
      >

        <div className="modal-title">Image attributions</div>
        <div className="modal-message">
          <ul className="attribution-list">
            <li className="attribution-item">
              <span>Oprah Winfrey: </span> 
              <a href="https://commons.wikimedia.org/wiki/File:Oprah_Winfrey_in_Str%C3%B8get,_Denmark_on_30_September_2009.jpg" target="_blank" rel="nofollow noopener noreferrer">Photo </a> 
              by <a href="https://en.wikipedia.org/wiki/User:Atomicbre" target="_blank" rel="nofollow noopener noreferrer">Bill Ebbesen </a> 
              is licensed under <a href="https://creativecommons.org/licenses/by/3.0/" target="_blank" rel="nofollow noopener noreferrer">CC BY 3.0</a>
            </li>
            <li className="attribution-item">
              <span>Steve Jobs: </span> 
              <a href="https://www.flickr.com/photos/plasticbag/2196994134/" target="_blank" rel="nofollow noopener noreferrer">"Steve Jobs talks about Leopard" </a> 
              by <a href="https://www.flickr.com/people/12037949715@N01" target="_blank" rel="nofollow noopener noreferrer">Tom Coates from San Francisco, United States </a> 
              is licensed under <a href="https://creativecommons.org/licenses/by/2.0/" target="_blank" rel="nofollow noopener noreferrer">CC BY 2.0</a>
            </li>
            <li className="attribution-item">
              <span>Lady Gaga: </span>
              <a href="https://vimeo.com/159307047" target="_blank" rel="nofollow noopener noreferrer">"SB50 Lady GaGa Interview" </a> 
              by <a href="https://vimeo.com/smpentertainment/about" target="_blank" rel="nofollow noopener noreferrer">SMP Entertainment </a> 
              is licensed under <a href="https://creativecommons.org/licenses/by/3.0/" target="_blank" rel="nofollow noopener noreferrer">CC BY 3.0</a>
            </li>
            <li className="attribution-item">
              <span>Joan Baez: </span> 
              <a href="https://commons.wikimedia.org/wiki/File:Joan_Baez_Ithaca_04.JPG" target="_blank" rel="nofollow noopener noreferrer">Photo </a> 
              by <a href="https://commons.wikimedia.org/wiki/User:Stilfehler" target="_blank" rel="nofollow noopener noreferrer">Stilfehler </a> 
              is licensed under <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" rel="nofollow noopener noreferrer">CC BY-SA 4.0</a>
            </li>
            <li className="attribution-item">
              <span>Albert Einstein: </span> 
              Ferdinand Schmutzer / Public domain
            </li>
            <li className="attribution-item">
              <span>Martin Luther King, Jr.: </span> 
              Trikosko, Marion S. / Public domain
            </li>
            <li className="attribution-item">
              <span>Rihanna: </span>
              Ilikeriri  / CC0
            </li>
            <li className="attribution-item">
              <span>Steven Spielberg: </span>
              <a href="https://commons.wikimedia.org/wiki/File:Steven_Spielberg_by_Gage_Skidmore.jpg" target="_blank" rel="nofollow noopener noreferrer">Photo </a> 
              by Gage Skidmore 
              is licensed under <a href="https://creativecommons.org/licenses/by-sa/3.0/" target="_blank" rel="nofollow noopener noreferrer">CC BY-SA</a>
            </li>
            <li className="attribution-item modification">All images employ some cropping</li>
          </ul>
        </div>

      </Modal>
    );
  }
};

export default AttributionModal;
