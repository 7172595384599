
import React        from 'react';
import VideoModal   from '_views/modals/VideoModal'

import {toClassStr} from '_utils/UiHelper';

class VideoModalPoster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    }

    /*
      this.props:
        addClasses,
        isLink,
        posterImageUrl,
        shouldAutoPlay,
        videoUrl
    */
  }

  render() {
    const classes = toClassStr(['video-modal-poster', this.props.addClasses]);
    const posterImageUrl = this.props.posterImageUrl || "/assets/video-poster-image.jpg"; 
    return (
      <div className={classes}>

        <img className="video-poster-image" src={posterImageUrl} onClick={(e) => this.handleVideoPosterClick(e)} />

        <i className="play-icon ion-ios-play" onClick={(e) => this.handleVideoPosterClick(e)}/>

        <VideoModal
          isOpen={this.state.isOpen}
          onCloseRequest={() => this.handleCloseVideoModalRequest()}
          shouldAutoPlay={this.props.shouldAutoPlay}
          videoUrl={this.props.videoUrl}
        />
      </div>
    );
  }

  handleVideoPosterClick(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.isLink) {
      window.open(this.props.videoUrl, '_blank', 'noopener');
    } else {
      this.setState({isOpen: true});
    }
  }

  handleCloseVideoModalRequest() {
    this.setState({isOpen: false});
  }

};

export default VideoModalPoster;
