
import {DismissButton}           from '_views/shared/IconButton';

import DynamicTooltipIcon              from '_views/shared/DynamicTooltipIcon';
import Pulldown                  from '_views/shared/Pulldown';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import StringHelper              from '_utils/StringHelper';
import TextField                 from '_views/shared/TextField';

import {toClassStr}              from '_utils/UiHelper';

const PT = {
  addClasses                 : PropTypes.string,
  isEditing                  : PropTypes.bool,
  fieldId                    : PropTypes.node,
  fieldKey                   : PropTypes.node,
  fieldLabel                 : PropTypes.string,
  fieldType                  : PropTypes.string, // textField, pulldown, tag
  fieldValue                 : PropTypes.node,
  onChange                   : PropTypes.func,
  onOverCharLimitChange      : PropTypes.func,
  onRemoveTagRequest         : PropTypes.func,
  options                    : PropTypes.array,
  pack                       : PropTypes.object,
  placeholder                : PropTypes.string,
  tooltip                    : PropTypes.string,
}


class PackMetaField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caption: "",
      isPulldownOpen: false,
    };
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const value = this.props.fieldValue || 'Unspecified';

    const isEditingClass = (this.props.isEditing && this.props.fieldType) ? 'is-editing' : '';
    const textFieldClass = (this.props.isEditing && (this.props.fieldType == 'textField')) ? 'text-meta-field' : '';
    const unspecifiedClass = (value == 'Unspecified') ? 'unspecified' : '';
    const languageClass = this.getLanguageClass(value);
    const classes = toClassStr(['pack-meta-field', isEditingClass, textFieldClass, unspecifiedClass, languageClass, this.props.addClasses]);

    if (this.props.isEditing && this.props.fieldType) {
      return (
        <li className={classes}>
          {this.renderLabel()}
          {this.renderInputField()}
        </li>
      );
    }

    return (
      <li className={classes}>
        {this.renderLabel()}
        <div className="meta-field-value">{value}</div>
      </li>
    );
  }

  renderLabel() {
    if (!this.props.fieldLabel) {
      return null;
    }

    return (
      <div className="meta-field-label">
        <div className="label-text">{this.props.fieldLabel}</div>
        {this.renderFieldLabelTooltip()}
      </div>
    );
  }

  renderFieldLabelTooltip() {
    if (!this.props.tooltip) {
      return null;
    }

    const tooltipId = this.props.fieldKey + '_tooltip';

    return (
      <DynamicTooltipIcon 
        actions={null}
        addClasses="field-label-tooltip"
        body={this.props.tooltip}
        hasDismissButton={true}
        heading={null}
        position="right"
        subHeading={null}
      />
    );
  }

  renderInputField() {
    switch (this.props.fieldType) {
      case 'textField':
        return this.renderTextField();
      break;
      case 'pulldown':
        return this.renderPulldown();
      break;
      case 'tag':
        return this.renderTag();
      break;
      default:
        return this.renderTextField();
    }
  }

  renderPulldown() {
    const selectedValue = this.props.fieldValue || -1;

    return (
      <Pulldown
        addClasses="meta-field-pulldown"
        isOpen={this.state.isPulldownOpen}
        options={this.props.options}
        selectedValue={selectedValue}
        shouldSuppressNullOption={false}
        onButtonClick={this.handlePulldownButtonClick}
        onOptionClick={this.handlePulldownOptionClick}
        onOutsideClick={this.handlePulldownOutsideClick}
      />
    );
  }

  renderTextField() {
    return (
      <TextField 
        addClasses="meta-text-field"
        caption={this.state.caption}
        charLimit={100}
        hasInitialFocus={false}
        id={this.props.fieldId}
        onChange={this.handleTextFieldChange}
        onOverCharLimitChange={this.handleOverCharLimitChange}
        placeholder={this.props.placeholder || 'Enter value'}
        value={this.props.fieldValue || ""}
      />
    );
  }

  renderTag() {
    const value = this.props.fieldValue || 'Unspecified';

    return (
      <div className="meta-field-tag">
        <div className="meta-field-value">{value}</div>
        <DismissButton 
          addClasses="remove-meta-field-button"
          onClick={this.handleRemoveTagButtonClick}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOverCharLimitChange = (isOverLimit) => {
    this.setState({
      caption: isOverLimit ? 'Over Limit' : '',
    })

    if (this.props.onOverCharLimitChange) {
      this.props.onOverCharLimitChange(this.props.fieldId, isOverLimit);
    }
  }

  handlePulldownButtonClick = () => {
    const isPulldownOpen = this.state.isPulldownOpen;

    this.setState({
      isPulldownOpen: !isPulldownOpen,
    });
  }

  handlePulldownOptionClick = (value) => {
    this.props.onChange(this.props.fieldId, value);

    this.setState({
      isPulldownOpen: false,
    });
  }

  handlePulldownOutsideClick = () => {
    this.setState({
      isPulldownOpen: false,
    });
  }

  handleRemoveTagButtonClick = () => {
    this.props.onRemoveTagRequest(this.props.fieldId);
  }

  handleTextFieldChange = (e) => {
    this.props.onChange(this.props.fieldId, e.target.value);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  getLanguageClass(value) {
    const fieldKey = this.props.fieldKey;

    if (!(fieldKey && this.props.fieldValue)) {
      return null;
    }

    if (fieldKey == 'foreign_languages') {
      return null;
    }

    if (fieldKey.indexOf('language') != -1) {
      return StringHelper.toKebobCase(value);
    }
  }
}

PackMetaField.propTypes = PT;

export default PackMetaField;
