
import model              from '_core/newModel';
import userLocalStore     from '_models/userLocalStore';


const studyPackSeed = {

  /*
  ==================================================
   PLATFORM API CRUD METHODS (returns promises)
  ==================================================
  */

  show(packId, userId=null) {
    let path = `study/packs/${packId}/seeds`;

    // check to see if a subset of this packs decks are currently selected
    if (userId && userLocalStore.isPackMixSet(userId, packId)) {
      const selectedDeckIds = userLocalStore.getSelectedPackDeckIds(userId, packId);

      path = `${path}?deckIds=${selectedDeckIds.join(',')}`;
    }

    return new Promise((resolve, reject) => {
      try {
        model.show(path).then(seedData => {
          const augmentedSeedData = {...seedData};
          const packH = seedData?.packs?.hash?.[packId];

          if (packH && userId) {
            packH.mixType = userLocalStore.getPackStudyMixType(userId, packH);
            augmentedSeedData.packs.hash[packId] = packH;
          }

          resolve(augmentedSeedData);
        }).catch(err => {
          throw err;
        });
      } catch(err) {
        console.error(err);
        reject(err);
      }
    });
  },


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

};

export default studyPackSeed;
