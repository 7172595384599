
import React from 'react';

import {toClassStr} from '_utils/UiHelper';

class LoadingOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullyClosed: false,
      isFullyOpen: false,
      isInitializing: true,
      isTransitioningIn: false,
      isTransitioningOut: false,
    };

    /*
      this.props:
        addClasses,
        hasBruce,
        isOpaque,
        isOpen,
        message,
        onClosed,
        shouldTransitionIn,
        shouldTransitionOut
    */

    this.transitionInTimeout = null;
    this.postTransitionInTimeout = null;
    this.postTransitionOutTimeout = null;
    this.TRANSITION_IN_DELAY = 100; // allows for mounting of component before commencement of transition
    this.POST_TRANSITION_IN_DELAY = 1000; // should be equal to or greater than the transition time set in the CSS
    this.POST_TRANSITION_OUT_DELAY = 500; // allows for transition before being potentially unmounted
    // Note: No transition out delay is needed because component is already mounted
  }

  componentDidMount() {
    this.clearTimeoutsAndIntervals();

    if (this.props.isOpen && this.props.shouldTransitionIn) {
      this.invokeTransitionIn();
      return;
    }

    if (this.props.isOpen && this.props.shouldTransitionOut) {
      this.invokeTransitionOut();
      return;
    }

    this.setState({
      isInitializing: false
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen && this.props.shouldTransitionIn) {
      this.invokeTransitionIn();
    }

    if (!this.props.isOpen && prevProps.isOpen && this.props.shouldTransitionOut) {
      this.invokeTransitionOut();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
  }

  render() {
    const hideBruceClass = (this.props.shouldShowBruce === false) ? 'hide-bruce' : '';
    const isOpaqueClass = (this.props.isOpaque) ? 'is-opaque' : '';
    const classes = toClassStr(['loading-overlay', this.getTransitionClass(), hideBruceClass, isOpaqueClass, this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderMessage()}
      </div>
    );
  }

  renderMessage() {
    if (!this.props.message) {
      return null;
    }

    return (
      <div className="loading-message">{this.props.message}</div>
    );
  }

  getTransitionClass() {
    if (this.state.isTransitioningIn) {
      return 'is-transitioning-in';
    }

    if (this.state.isTransitioningOut) {
      return 'is-transitioning-out';
    }

    if (this.state.isFullyClosed) {
      return 'is-fully-closed';
    }

    if (this.state.isFullyOpen) {
      return 'is-fully-open';
    }

    if (this.state.isInitializing) {
      return 'is-initializing';
    }

    return 'is-static';
  }

  invokeTransitionIn() {
    this.transitionInTimeout = setTimeout(() => {
      this.setState({
        isFullyClosed: false,
        isFullyOpen: false,
        isInitializing: false,
        isTransitioningIn: true,
        isTransitioningOut: false,
      }, () => {
        this.completeTransitionIn();
      });
      clearTimeout(this.transitionInTimeout);
    }, this.TRANSITION_IN_DELAY);
  }

  completeTransitionIn() {
    this.postTransitionInTimeout = setTimeout(() => {
      this.setState({
        isFullyClosed: false,
        isFullyOpen: true,
        isInitializing: false,
        isTransitioningIn: false,
        isTransitioningOut: false,
      });
      clearTimeout(this.postTransitionInTimeout);
    }, this.POST_TRANSITION_IN_DELAY);
  }

  invokeTransitionOut() {
    this.setState({
      isFullyClosed: false,
      isFullyOpen: false,
      isInitializing: false,
      isTransitioningIn: false,
      isTransitioningOut: true,
    }, () => {
      this.postTransitionOutTimeout = setTimeout(() => {
        this.clearTimeoutsAndIntervals();
        this.completeTransitionOut();
      }, this.POST_TRANSITION_OUT_DELAY);
    });
  }

  completeTransitionOut() {
    this.setState({
      isFullyClosed: true,
      isFullyOpen: false,
      isInitializing: false,
      isTransitioningIn: false,
      isTransitioningOut: false,
    }, () => {
      if (this.props.onClosed) {
        this.props.onClosed();
      }
    });
  }

  clearTimeoutsAndIntervals() {
    clearTimeout(this.transitionInTimeout);
    clearTimeout(this.postTransitionInTimeout);
    clearTimeout(this.postTransitionOutTimeout);
  }
}

export default LoadingOverlay;
