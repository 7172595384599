
import EventManager           from '@brainscape/event-manager';
import OptionsMenuButton      from '_views/shared/OptionsMenuButton';
import React                  from 'react';

import {toClassStr} from '_utils/UiHelper';

class CheckpointOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProcessingOptionAction: false,
    };

    /*
      this.props:
        addClasses,
        isContinuousStudyEnabled,
        isDisabled,
        isMixRestricted,
        isMobileViewportSize,
        isUserPro,
        onToggleContinuousStudy,
    */

    this.menuOptionHash = {
      continuousStudyByPro: {id: 'continuousStudyByPro', tag: 'continuous-study-by-pro', label: 'Enable Continuous Study', onlyPro: true, onlyIfMixIsRestricted: true, paywall: 'enable_continuous_study', persistOnClick: true},
      continuousStudy: {id: 'continuousStudy', tag: 'continuous-study', label: 'Enable Continuous Study', onlyIfMixIsUnrestricted: true, persistOnClick: true},
    };

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  UNSAFE_componentWillMount() {
    this.updateUserOptions();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isMixRestricted != prevProps.isMixRestricted || this.props.isUserPro != prevProps.isUserPro) {
      this.updateUserOptions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const continuousStudyEnabledClass = this.props.isContinuousStudyEnabled ? 'continuous-study-enabled' : '';
    let classes = toClassStr(['checkpoint-options-button', continuousStudyEnabledClass, this.props.addClasses]);
    const openPosition = this.props.isMobileViewportSize ? "bottomLeft" : "bottomRight";

    return (
      <div className={classes}>
        <OptionsMenuButton
          isDisabled={this.props.isDisabled}
          isProcessing={this.state.isProcessingOptionAction}
          isUserPro={this.props.isUserPro}
          menuOptions={this.state.menuOptions}
          onOptionClick={(optionId) => this.handleOptionClick(optionId)}
          openPosition={openPosition}
          shouldButtonTurn={true}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleOptionClick(optionId) {
    let requiresPro = this.menuOptionHash[optionId].onlyPro;

    if (requiresPro && !this.props.isUserPro) {
      let desiredAction = this.menuOptionHash[optionId].label || "";
      let paywall = this.menuOptionHash[optionId].paywall || "";
      this.triggerUpgradeModalOpen(desiredAction, paywall);
    } else {
      this.handleOptionActionRequest(optionId);
    }
  }

  handleOptionActionRequest(optionId) {
    switch (optionId) {
      case 'continuousStudy':
        this.performEnableContinuousStudyAction();
      break;
      case 'continuousStudyByPro':
        this.performEnableContinuousStudyAction();
      break;
    }
  }


  /*
  ==================================================
   EVENT HANDLER HELPERS
  ==================================================
  */

  performEnableContinuousStudyAction() {
    this.triggerToggleContinuousStudy();
    this.triggerStartNewRoundRequest();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerStartNewRoundRequest = () => {
    EventManager.emitEvent('study-session:new-round-requested', {});
  }

  triggerToggleContinuousStudy() {
    EventManager.emitEvent('user-prefs:toggle-continuous-study', {});
  }

  triggerUpgradeModalOpen(desiredAction, paywall) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall:       paywall,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  updateUserOptions() {
    let menuOptionsForUser = Object.keys(this.menuOptionHash).reduce((accumulator, menuOption) => {
      let permitPerMixRestriction = (this.menuOptionHash[menuOption].onlyIfMixIsRestricted) ? this.props.isMixRestricted : true;
      let permitPerMixUnrestriction = (this.menuOptionHash[menuOption].onlyIfMixIsUnrestricted) ? !this.props.isMixRestricted : true;
      let permitPerUseInCheckpoint = (this.menuOptionHash[menuOption].onlyIfNotCheckpointButton) ? !this.props.isCheckpointOptionsButton : true;
      let permitPerDeviceType = (this.menuOptionHash[menuOption].onlyMobile) ? this.props.isMobileViewportSize : true;

      if (permitPerMixRestriction && permitPerMixUnrestriction && permitPerUseInCheckpoint && permitPerDeviceType) {
        accumulator.push(this.menuOptionHash[menuOption]);
      }
      return accumulator;
    }, []);

    this.setState({
      menuOptions: menuOptionsForUser
    });
  }
}

export default CheckpointOptionsButton;
