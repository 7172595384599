
import EventBus       from '_utils/EventBus';
import EventManager   from '@brainscape/event-manager';

const UiHelper = {
  
  adjustViewportHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },

  animateElemsIn(listener, elems) {
    if (this.isInViewport(listener, false)) {
      elems.forEach((elem) => {
        elem.classList.add('animate-in');
      });
    } else {
      elems.forEach((elem) => {
        elem.classList.remove('animate-in');
      });
    }
  },

  closePageLoadingOverlay() {
    EventManager.emitEvent('page-loading-overlay:close', {});
  },

  debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  },

  detectIfMobileSize() {
    // this is a rough approximation

    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    const iPadProMediaQuery = window.matchMedia("(max-device-width: 1024px) and (min-device-height: 1024px) and (orientation: portrait)");

    if (iPadProMediaQuery.matches) {
      return true;
    }

    if (viewportWidth <= 942) {
      return true;
    }

    return false;
  },

  detectScrollEnd: () => {
    // from SO https://stackoverflow.com/questions/4620906/how-do-i-know-when-ive-stopped-scrolling
    let last_changed_frame = 0;
    let last_x = window.scrollX;
    let last_y = window.scrollY;

    return new Promise( resolve => {
      function tick(frames) {
        // We requestAnimationFrame either for 500 frames or until 20 frames with
        // no change have been observed.
        if (frames >= 500 || frames - last_changed_frame > 100) {
          resolve();
        } else {
          if (window.scrollX != last_x || window.scrollY != last_y) {
            last_changed_frame = frames;
            last_x = window.scrollX;
            last_y = window.scrollY;
          }
          requestAnimationFrame(tick.bind(null, frames + 1));
        }
      }

      tick(0);
    });
  },

  getSmartCardBaseFontSize: (smartCardRect) => {
    // the following mirrors the calculations used in the Study Card Demo

    const minFontSize = 11;
    const baseFontSizeFactor = 0.05;

    const fontSize = Math.max(minFontSize, Math.round(Math.min(smartCardRect.width, smartCardRect.height) * baseFontSizeFactor));

    return fontSize;
  },

  handleError(opts) {
    console.log('Something went wrong with ' + opts.operation + '. errorMessage, errorObj:', opts.errorMessage, opts.errorObj);

    const msg = 'Operation: ' + (opts.operation || '') + ', Message: ' + opts.errorMessage || '';

    EventBus.publish('error:msg', {msg: msg}, true);

    if (opts.notifyUser) {
      EventManager.emitEvent('error-modal:open', {
        errorMessage: opts.errorMessage || 'There was an error',
      });
    }
  },

  hideZendeskWidget: () => {
    if (window.zE) {
      window.zE('webWidget', 'hide');
    }
  },

  isInternetExplorer() {
    return (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1))
  },

  isInViewport(element, strict) {
    const rect = element.getBoundingClientRect();
    const height = window.innerHeight || document.documentElement.clientHeight;
    const width = window.innerWidth || document.documentElement.clientWidth;
    const offset = { left: 0, right: 0, top: 0, bottom: 0};

    // All 4 corners should be in viewport
    if (strict) {
      return (
        rect.top >= -offset.top &&
        rect.left >= -offset.left &&
        rect.bottom <= height + offset.bottom &&
        rect.right <= width + offset.right
      );
    }

    // At least 1 corner is in viewport
    return (
      rect.right >= -offset.left &&
      rect.bottom >= -offset.top &&
      rect.left <= width + offset.right &&
      rect.top <= height + offset.bottom
    );
  },

  navigate(url, overlayMessage) {
    if (overlayMessage === false) {
      location.href = url;
      return;
    }

    EventManager.emitEvent('page-loading-overlay:open', {
      message: overlayMessage || '',
    });

    location.href = url;
  },

  onLinger(elem, handler, pauseInterval = 500) {
    let timeout;
    elem.addEventListener("mouseenter", (e) => {
      timeout = setTimeout(() => {
        handler(e);
        clearTimeout(timeout);
      }, pauseInterval)
      elem.addEventListener("mouseleave", (e) => {
        clearTimeout(timeout);
      });
    });
  },

  openInNewTab(url, tabName='_blank', rel='noopener') {
    if (tabName == '_blank') {
      window.open(url, tabName, rel);
    } else {
      window.open(url, tabName);
    }
  },

  openPageLoadingOverlay(overlayMessage) {
    EventManager.emitEvent('page-loading-overlay:open', {
      message: overlayMessage || '',
    });
  },

  scrollToElem(target, offset) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: (target.offsetTop - offset)
    });
  },

  showZendeskWidget() {
    if (window.zE) {
      window.zE('webWidget', 'show');
    }
  },

  sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
  },
};

const toClassStr = (classArray) => {
  return classArray.join(' ').replace(/\s+/g,' ').trim();
};

export {
  UiHelper as default,
  toClassStr,
};
