
import EventManager           from '@brainscape/event-manager';
import PillButton             from '_views/shared/PillButton';
import PropTypes              from 'prop-types';
import React                  from 'react';
import SimpleTextButton       from '_views/shared/SimpleTextButton';
import StringHelper           from '_utils/StringHelper';
import TextField              from '_views/shared/TextField';
import UrlHelper              from '_utils/UrlHelper';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses                  : PropTypes.string,
  children                    : PropTypes.object,
  isClickable                 : PropTypes.bool,
  isEditable                  : PropTypes.bool,
  isEditing                   : PropTypes.bool,
  isMobileViewportSize        : PropTypes.bool,
  isProcessing                : PropTypes.bool,
  message                     : PropTypes.string,
  onCancel                    : PropTypes.func,
  onCaptionChange             : PropTypes.func,
  onPlaceholderClick          : PropTypes.func,
  onSubmit                    : PropTypes.func,
  onUrlChange                 : PropTypes.func,
  shouldShowRemoveButton      : PropTypes.bool,
  videoCaption                : PropTypes.string,
  videoUrl                    : PropTypes.string,
};


class VideoPlaceholder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCaptionOverLimit: false,
      isInvalidUrl: false,
      videoCaptionCaption: "",
      videoUrlCaption: "",
    }

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.resetVideoUrlCaption();
    this.resetVideoCaptionCaption();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const clickableClass = this.props.isClickable ? 'is-clickable' : '';
    const editableClass = this.props.isEditable ? 'is-editable' : '';
    const editingClass = this.props.isEditing ? 'is-editing' : '';
    const invalidCaptionClass = this.state.isInvalidCaption ? 'is-invalid-caption' : '';
    const invalidUrlClass = this.state.isInvalidUrl ? 'is-invalid-url' : '';
    const classes = toClassStr(['video-placeholder', clickableClass, editableClass, editingClass, invalidCaptionClass, invalidUrlClass, this.props.addClasses]);

    return (
      <div className={classes} onClick={this.handlePlaceholderClick}>
        {this.renderTitle()}
        {this.renderMessage()}
        {this.renderEditIcon()}
        {this.renderEditForm()}
        {this.props.children}
      </div>
    );
  }

  renderTitle() {
    if (!this.props.title) {
      return null;
    }

    return (
      <div className="placeholder-heading">{title}</div>
    );
  }

  renderMessage() {
    if (!this.props.message || this.props.isEditing) {
      return null;
    }

    return (
      <div className="message">
        {this.props.message}
      </div>
    );
  }

  renderEditIcon() {
    if (!this.props.isEditable || this.props.isEditing) {
      return null;
    }

    return (
      <i className="edit-icon" />
    );
  }  

  renderEditForm() {
    if (!this.props.isEditing) {
      return null;
    }

    const saveButtonLabel = (this.props.isMobileViewportSize) ? 'Save' : 'Save changes';

    return (
      <form
        className="edit-video-form"
        onSubmit={(e) => this.handleVideoFormSubmit(e)}
      >

        <div className="action-buttons">

          <SimpleTextButton
            addClasses="youtube-help-button"
            label="YouTube URL Help"
            onClick={this.handleYouTubeHelpButtonClick}
          />

          {this.renderRemoveVideoButton()}

          <SimpleTextButton
            addClasses="cancel-edit-video-url-button"
            label="Cancel"
            onClick={this.handleCancelEditVideoUrlButtonClick}
          />

          <PillButton
            addClasses="save-video-url-changes-button"
            isDisabled={StringHelper.isBlank(this.props.videoUrl)}
            isProcessing={this.props.isProcessing}
            label={saveButtonLabel}
            onClick={this.handleVideoFormSubmit}
          />
        </div>

        <TextField 
          addClasses="video-url-field"
          caption={this.state.videoUrlCaption}
          hasInitialFocus={true}
          id="video-url"
          isInvalid={this.state.isInvalidUrl}
          label="YouTube Video URL"
          onChange={this.handleUrlChange}
          placeholder="cut and paste video url here"
          value={this.props.videoUrl}
        />

        <TextField 
          addClasses="video-caption-field"
          caption={this.state.videoCaptionCaption}
          charLimit={280}
          hasInitialFocus={false}
          id="video-caption"
          isInvalid={this.state.isInvalidCaption}
          label="Video Caption"
          onChange={this.handleCaptionChange}
          onOverCharLimitChange={this.handleCaptionOverCharLimitChange}
          placeholder="type text to appear underneath video"
          value={this.props.videoCaption}
        />

        <input type="submit" className="hidden-submit-button" />
      </form>
    );
  }

  renderRemoveVideoButton() {
    if (!this.props.shouldShowRemoveButton) {
      return null;
    }

    return (
      <SimpleTextButton
        addClasses="remove-video-button"
        label="Remove Video"
        onClick={this.handleRemoveVideoButtonClick}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCancelEditVideoUrlButtonClick = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleRemoveVideoButtonClick = () => {
    this.triggerCautionModalOpen({
      actionText                      : 'remove the video from your Class',
      cancelButtonText                : 'No, Keep video',
      onResolution                    : () => {
        this.removeVideo();
        this.triggerCautionModalClose();
      },
      resolveButtonText               : 'Yes, remove',
    });
  }

  handlePlaceholderClick = (e) => {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.isClickable && this.props.onPlaceholderClick) {
      this.props.onPlaceholderClick();
    }
  }

  handleCaptionOverCharLimitChange = (isOverLimit) => {
    const videoCaptionCaption = isOverLimit ? 'Your caption exceeds the character limit' : '';

    this.setState({
      isCaptionOverLimit: isOverLimit,
      videoCaptionCaption: videoCaptionCaption,
    })
  }

  handleVideoFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.state.isCaptionOverLimit) {
      this.setState({
        isInvalidCaption: true,
      });

      return false;
    }

    if (this.props.onSubmit) {
      let videoProps = UrlHelper.parseYouTubeUrl(this.props.videoUrl);

      if (videoProps.isValidUrl) {
        videoProps.caption = this.props.videoCaption;
        this.props.onSubmit(videoProps);
        return true;
      }

      this.setState({
        isInvalidInput: true,
        isInvalidUrl: true,
        videoUrlCaption: "This is an invalid YouTube URL. See help above."
      });

      return false;
    }
  }

  handleYouTubeHelpButtonClick = () => {
    window.open('https://support.google.com/youtube/answer/57741', '_blank', 'noopener');
  }

  handleCaptionChange = (e) => {
    if (this.state.isInvalidCaption) {
      this.resetVideoCaptionCaption();
    }

    if (this.props.onCaptionChange) {
      this.props.onCaptionChange(e.target.value);
    }
  }

  handleUrlChange = (e) => {
    if (this.state.isInvalidUrl) {
      this.resetVideoUrlCaption();
    }

    if (this.props.onUrlChange) {
      this.props.onUrlChange(e.target.value);
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerCautionModalClose(viewProps) {
    EventManager.emitEvent('caution-modal:close', viewProps);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  removeVideo() {
    const videoProps = {
      isValidUrl: true,
      caption: "",
      url: "",
      type: "",
    };

    this.props.onSubmit(videoProps);
    this.resetVideoUrlCaption();
  }

  resetVideoCaptionCaption = () => {
    this.setState({
      isInvalidCaption: false,
      videoCaptionCaption: "",
    });
  }

  resetVideoUrlCaption = () => {
    this.setState({
      isInvalidUrl: false,
      videoUrlCaption: "Embed or watch video url (YouTube only)",
    });
  }
};

export default VideoPlaceholder;
