/* 
  NOTE: DEPRECATED. Individual Models are being refactored to unbind them from the
  deprecated Model.js class.

  These individual Models will import the Api1 or Api2 classes for interactions with
  the server APIs.

  Other modules using Api.js will be refactored to import the Api1 or Api2 modules
  accordingly.
  
  When this refactoring work is complete, this file can be deleted.
*/

import 'url-search-params-polyfill';

class LegacyApiService {
  constructor() {
    this.resourceRoute;  // ex. '/api/widgets'
  }

  getResourceRoute() {
    return this.resourceRoute;
  }

  getResourceUrlBase() {
    // ex. "https://www.brainscape.com" + this.resourceRoute; or if relative, just resourceRoute
    return this.resourceRoute;
  }

  buildUrl(urlBase, params) {
    let url = urlBase;
    let queryString = '';

    if (!params) {
      return url;
    }

    Object.keys(params).forEach((key, index) => {
      let delim = (index == 0) ? '?' : '&';
      queryString += delim + key + '=' + params[key];
    });

    return url + queryString;
  }

  index(opts) {
    let url = this.buildUrl(this.getResourceUrlBase(), opts.params);

    return $.ajax({
      type: 'GET',
      url: url,
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }

  show(opts) {
    let url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id, opts.params);

    return $.ajax({
      type: 'GET',
      url: url
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }

  create(opts) {
    let url = this.buildUrl(this.getResourceUrlBase(), opts.params);

    return $.ajax({
      type: 'POST',
      url: url,
      data: JSON.stringify(opts.reqData),
      contentType: "application/json"
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }

  update(opts) {
    let url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id, opts.params);

    return $.ajax({
      type: 'PUT',
      url: url,
      data: JSON.stringify(opts.reqData),
      contentType: "application/json"
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }

  delete(opts) {
    let url = this.buildUrl(this.getResourceUrlBase() + '/' + opts.id, opts.params);

    return $.ajax({
      type: 'DELETE',
      url: url,
    })
    .done((data, textStatus) => {
      if (opts.done && typeof opts.done == 'function') {
        opts.done(data, textStatus);
      }
    })
    .fail((jqXhr, textStatus) => {
      if (opts.fail && typeof opts.fail == 'function') {
        opts.fail(jqXhr, textStatus);
      }
    });
  }
}

export default LegacyApiService;
