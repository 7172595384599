
import Modal                     from '_views/shared/Modal';
import PillButton                from '_views/shared/PillButton';
import React                     from 'react';
import SimpleTextButton          from '_views/shared/SimpleTextButton';
import UiHelper                  from '_utils/UiHelper';

class EarnMoneyModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        isOpen,
        isProcessing,
        onCloseRequest,
    */

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    return (
      <Modal
        addClasses="earn-money-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Earn Money</div>

        <div className="modal-message">Made some excellent content in Brainscape (or could you)?  Earn money as a Campus Rep or Verified Author!</div>
        <div className="modal-message">Are you a company wanting to provide (or sell) content for study in Brainscape? Check out our reseller / licensing program.</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Learn More"
            onClick={this.handleLearnMoreButtonClick}
          />
          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="No Thanks"
            onClick={() => this.props.onCloseRequest()}
          />
        </div>
      </Modal>
    );
  }

  renderNew() {
    return (
      <Modal
        addClasses="earn-money-modal"
        isOpen={this.props.isOpen}
        onCloseRequest={() => this.props.onCloseRequest()}
      >

        <div className="modal-title">Earn Money from your Flashcards</div>

        <div className="modal-message">You can distribute them through Brainscape's marketplace, or via your own private channels.</div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            label="Learn More"
            onClick={this.handleLearnMoreButtonClick}
          />
          <PillButton
            addClasses="resolve-modal-button pill-button-emphasized"
            label="Apply"
            onClick={this.handleApplyButtonClick}
          />          
          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="No Thanks"
            onClick={() => this.props.onCloseRequest()}
          />
        </div>
      </Modal>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleLearnMoreButtonClick = () => {
    this.props.onCloseRequest();
    UiHelper.openInNewTab("https://brainscape.zendesk.com/hc/en-us/articles/115002370011-Can-I-earn-money-from-my-flashcards-");
  }

  handleApplyButtonClick = () => {
    this.props.onCloseRequest();
    let firstname = userObj.name.split(" ")[0] || '';
    let lastname = userObj.name.split(" ")[1] || '';
    let email = userObj.email || '';
    UiHelper.openInNewTab(`https://share.hsforms.com/1HMsMcLdGQaeqV_cE2tHo4Q1fype?email=${email}&firstname=${firstname}&lastname=${lastname}`);
  }
}

export default EarnMoneyModal;
