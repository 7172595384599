
import EventManager from '@brainscape/event-manager';
import PropTypes    from 'prop-types';
import React        from 'react';
import {EditButton} from '_views/shared/IconButton';

import {toClassStr} from '_utils/UiHelper';

const PT = {
  addClasses: PropTypes.string,
  cardId:     PropTypes.node.isRequired,
  deckId:     PropTypes.node.isRequired,
  packId:     PropTypes.node.isRequired,
};

class EditCardButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {isProcessing: false, suggestionMetadata: null};
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render = () => {
    const classes = toClassStr(['edit-card-button', this.props.addClasses]);

    return (
      <EditButton
        addClasses={classes}
        isProcessing={this.state.isProcessing}
        onClick={this.handleEditButtonClick}
        tooltipContent="Edit card or make suggestions"
        tooltipPosition="top"
      />
    );
  };

  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleEditButtonClick = () => {
    this.setState({
      isProcessing: true
    });

    EventManager.emitEvent('edit-card:request', {
      cardId:     this.props.cardId,
      deckId:     this.props.deckId,
      onComplete: () => this.setState({isProcessing: false}),
      packId:     this.props.packId,
    });
  };
}

EditCardButton.propTypes = PT;

export default EditCardButton;
